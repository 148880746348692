<template>
  <div label-position="top" size="mini" :model="elementProps">
    <div class="d-flex align-items-center justify-content-between py-2">
      <span class="setting-title">Full Width</span>
      <el-switch
        :value="elementProps.full_width"
        :active-color="primaryColor"
        inactive-color="#949494"
        :active-value="true"
        :inactive-value="false"
        @change="updateProps('full_width', $event)"
      />
    </div>
    <div class="mt-1" style="color: #999999; font-size: 12px; font-style: italic;">
      Select stretching options for row and content.
    </div>
  </div>
</template>

<script>
import mixin from '../styleSettings/mixin';
export default {
  mixins: [mixin],
  props: {
    primaryColor: String
  },
  methods: {
    updateProps(name, value) {
      if(value ==  this.elementProps[name]) return;
      this.elementPropsSetter({ [name]: value });
      if (name == 'full_width'){
        this.elementPropsSetter({ "hiddenConfig": value ? ['Dimension (Width)', 'Columns Gap'] : []});
      }
    }
  }
};
</script>
