<template>
  <div label-position="top" size="small" :model="elementProps">
    <div class="d-flex align-items-center justify-content-between py-2">
      <span>Fixed Mode</span>
      <el-switch
        :value="elementProps.fixed"
        :active-color="primaryColor"
        inactive-color="#949494"
        :active-value="true"
        :inactive-value="false"
        @change="updateProps('fixed', $event)"
      />
    </div>
    <div>
      <span class="text-description">
        Display Hero Banner box based on background image dimension.
      </span>
    </div>
    <div v-if="elementProps.fixed">
      <div class="py-2">
        <span class="setting-title">Content Position</span>
        <ToggleButton 
          :primaryColor="primaryColor"
          :value="elementProps.content_position"
          v-bind:changeValue="($event) => updateProps('content_position', $event)"
          :options="[
            { value: 'top', label: 'Top' },
            { value: 'middle', label: 'Middle' },
            { value: 'bottom', label: 'Bottom' },
          ]"
        />
      </div>
      <div class="py-2">
        <span class="setting-title">Alt Tag</span>
        <Input
          class="mt-2"
          :value="elementProps.alt_tag"
          v-bind:changeValue="($event) => updateProps('alt_tag', $event)"
          :primaryColor="primaryColor"
        />
      </div>
      <div>
        <span class="text-description">
          Each image should have an alt text. Not just for SEO purposes but also because blind and visually impaired people otherwise wont know what the image is for.
        </span>
      </div>
    </div>
    <div v-else>
      <div class="py-2">
        <span class="setting-title">
          Height (px)
        </span>
        <Input
          class="mt-2"
          :value="getValue('height',elementProps[screen].height)"
          v-bind:changeValue="($event) => updatePropsScreen('height', $event)"
          :primaryColor="primaryColor"
        />
      </div>
    </div>
  </div>
</template>

<script>
import mixin from "./mixin";
import ToggleButton from "../common/ToggleButton.vue";
import Input from "../common/Input.vue";
export default {
  mixins: [mixin],
  components: {
    ToggleButton, Input
  },
  props: {
    primaryColor: String,
    screen: String,
  },
  methods: {
    updatePropsScreen(name, value) {
      const screen = this.$props.screen;
      const data_screen = Object.assign({}, this.elementProps[screen])
      if(value ==  data_screen[name]) return;
      data_screen[name] = value
      this.elementPropsSetter({ [screen]: data_screen });
    },
    updateProps(name, value) {
      if(value ==  this.elementProps[name]) return;
      this.elementPropsSetter({ [name]: value });
    },
    updateWidth(event) {
      var value = parseInt(event);
      this.setStyle("width", event);
      if (event == "auto") {
        this.updateProps("typeWidth", null);
      } else if (!isNaN(value)) {
        if (!this.elementProps.typeWidth) {
          this.updateProps("typeWidth", "px");
        }
      }
    },
  },
};
</script>

<style scoped>
.text-description {
  font-style: italic;
  font-size: 12px;
  color: #999999;
}
</style>
