<template>
  <el-form label-position="top" size="" :model="elementProps">
    <el-form-item label="QR Image Link">
      <el-input
        type="textarea"
        :rows="4"
        :value="elementProps.qrUrl"
        @input="($event) => updateProps('qrUrl', $event)"
        resize="none"
      />
      <div class="sgDesc mt-2">
        Eg: <a class="reference">https://img.vietqr.io/image/970416-168169888-KjGpSq.jpg</a>
      </div>
    </el-form-item>
  </el-form>
</template>

<script>
import mixin from "../../../elements/styleSettings/mixin";

export default {
  mixins: [mixin],
  components: {},
  methods: {
    updateProps(name, value) {
      if(value ==  this.elementProps[name]) return;
      this.elementPropsSetter({ [name]: value });
    },
  },
};
</script>

<style scoped>
</style>
