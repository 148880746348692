<template>
  <el-form
    label-position="top"
    size="mini"
    :model="elementProps"
  >
    <div class="d-flex align-items-center justify-content-between py-2">
      <span class="sgSubTitle">Enable Lightbox</span>
      <el-switch
        :value="elementProps.lightbox"
        :active-color="primaryColor"
        inactive-color="#949494"
        @change="updateProps('lightbox', $event)"
      />
    </div>

    <div class="d-flex align-items-center justify-content-between py-2">
      <span class="sgSubTitle">Lightbox Icon</span>
      <el-switch
        :value="elementProps.lightbox_icon"
        :active-color="primaryColor"
        inactive-color="#949494"
        @change="updateProps('lightbox_icon', $event)"
      />
    </div>
  </el-form>
</template>
  
  <script>
import mixin from "../../../elements/styleSettings/mixin";
import ToggleButton from "../../../elements/common/ToggleButton.vue";

export default {
  mixins: [mixin],
  components: { ToggleButton },
  props: {
    primaryColor: String
  },
  methods: {
    updateProps(name, value) {
      if(value ==  this.elementProps[name]) return;
      this.elementPropsSetter({
        [name]: value,
        lightbox_icon: value
      });
    },
  },
};
</script>
  
  <style scoped>
</style>
  