<template>
  <el-form label-position="top" size="" :model="elementProps">
    <el-form-item label="Exclusions">
      <el-input
        type="textarea"
        :rows="4"
        :value="elementProps.exclusions"
        @input="($event) => updateProps('exclusions', $event)"
        resize="none"
      />
      <div class="sgDesc mt-2">
        Related Products display products from same collections with your main Product. However, you can exclude some collections you dont want to display products from. For example: best-sellings, new-arrivals.
        <br/><a class="reference">How to get collection handle</a>
      </div>
    </el-form-item>
  </el-form>
</template>

<script>
import mixin from "../../../elements/styleSettings/mixin";

export default {
  mixins: [mixin],
  components: {},
  methods: {
    updateProps(name, value) {
      if(value ==  this.elementProps[name]) return;
      this.elementPropsSetter({ [name]: value });
    },
  },
};
</script>

<style scoped>
</style>
