<template>
  <div label-position="top" size="mini" :model="elementProps">
      <div :class="show == 'default' ? '' : 'd-none'">
        <div class="d-flex flex-column py-2">
          <div class="title-layout">
            <span v-html="icons.desktop" />
            <span class="mx-2">Desktop Layout</span>
          </div>
          <div class="row row-layout">
            <div class="col-4 layout-wrapper">
              <div
                class="row layout-item"
                :class="{ active: elementProps.desktop.layout == '12' }"
                @click="() => updateProps('layout', '12', 'desktop', true)"
              >
                <div class="cell cell-12">12</div>
              </div>
            </div>
            <div class="col-4 layout-wrapper">
              <div
                class="row layout-item"
                :class="{ active: elementProps.desktop.layout == '6_6' }"
                @click="() => updateProps('layout', '6_6', 'desktop', true)"
              >
                <div class="cell cell-6">6</div>
                <div class="cell cell-6">6</div>
              </div>
            </div>
            <div class="col-4 layout-wrapper">
              <div
                class="row layout-item"
                :class="{ active: elementProps.desktop.layout == '4_4_4' }"
                @click="() => updateProps('layout', '4_4_4', 'desktop', true)"
              >
                <div class="cell cell-4">4</div>
                <div class="cell cell-4">4</div>
                <div class="cell cell-4">4</div>
              </div>
            </div>
            <div class="col-4 layout-wrapper">
              <div
                class="row layout-item"
                :class="{ active: elementProps.desktop.layout == '3_3_3_3' }"
                @click="() => updateProps('layout', '3_3_3_3', 'desktop', true)"
              >
                <div class="cell cell-3">3</div>
                <div class="cell cell-3">3</div>
                <div class="cell cell-3">3</div>
                <div class="cell cell-3">3</div>
              </div>
            </div>
            <div class="col-4 layout-wrapper">
              <div
                class="row layout-item"
                :class="{ active: elementProps.desktop.layout == '8_4' }"
                @click="() => updateProps('layout', '8_4', 'desktop', true)"
              >
                <div class="cell cell-8">8</div>
                <div class="cell cell-4">4</div>
              </div>
            </div>
            <div class="col-4 layout-wrapper">
              <div
                class="row layout-item"
                :class="{ active: elementProps.desktop.layout == '4_8' }"
                @click="() => updateProps('layout', '4_8', 'desktop', true)"
              >
                <div class="cell cell-4">4</div>
                <div class="cell cell-8">8</div>
              </div>
            </div>
            <div class="col-4 layout-wrapper">
              <div
                class="row layout-item"
                :class="{ active: elementProps.desktop.layout == '3_9' }"
                @click="() => updateProps('layout', '3_9', 'desktop', true)"
              >
                <div class="cell cell-3">3</div>
                <div class="cell cell-9">9</div>
              </div>
            </div>
            <div class="col-4 layout-wrapper">
              <div
                class="row layout-item"
                :class="{ active: elementProps.desktop.layout == '9_3' }"
                @click="() => updateProps('layout', '9_3', 'desktop', true)"
              >
                <div class="cell cell-9">9</div>
                <div class="cell cell-3">3</div>
              </div>
            </div>
            <div class="col-4 layout-wrapper">
              <div
                class="row layout-item"
                :class="{ active: elementProps.desktop.layout == '6_3_3' }"
                @click="() => updateProps('layout', '6_3_3', 'desktop', true)"
              >
                <div class="cell cell-6">6</div>
                <div class="cell cell-3">3</div>
                <div class="cell cell-3">3</div>
              </div>
            </div>
            <div class="col-4 layout-wrapper">
              <div
                class="row layout-item"
                :class="{ active: elementProps.desktop.layout == '3_3_6' }"
                @click="() => updateProps('layout', '3_3_6', 'desktop', true)"
              >
                <div class="cell cell-3">3</div>
                <div class="cell cell-3">3</div>
                <div class="cell cell-6">6</div>
              </div>
            </div>
            <div class="col-4 layout-wrapper">
              <div
                class="row layout-item"
                :class="{ active: elementProps.desktop.layout == '3_6_3' }"
                @click="() => updateProps('layout', '3_6_3', 'desktop', true)"
              >
                <div class="cell cell-3">3</div>
                <div class="cell cell-6">6</div>
                <div class="cell cell-3">3</div>
              </div>
            </div>
            <div class="col-4 layout-wrapper" v-if="!['12', '6_6', '4_4_4', '3_3_3_3', '8_4', '4_8', '3_9', '9_3', '6_3_3', '3_3_6', '3_6_3'].includes(elementProps.desktop.layout)">
              <div
                class="row layout-item"
                :class="{ active: !['12', '6_6', '4_4_4', '3_3_3_3', '8_4', '4_8', '3_9', '9_3', '6_3_3', '3_3_6', '3_6_3'].includes(elementProps.desktop.layout) }"
              >
                <div class="cell" v-for="(l, i) in elementProps.desktop.layout.split('_')" :class="`cell-${l}`" :key="i">{{ l }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex flex-column py-2">
          <div class="title-layout">
            <span v-html="icons.laptop"> </span>
            <span class="mx-2">Laptop Layout</span>
          </div>
          <div class="row row-layout">
            <div class="col-4 layout-wrapper">
              <div
                class="row layout-item"
                :class="{ active: elementProps.laptop.layout == '12' }"
                @click="() => updateProps('layout', '12', 'laptop')"
              >
                <div class="cell cell-12">12</div>
              </div>
            </div>
            <div
              class="col-4 layout-wrapper"
              v-if="['6_6', '3_3_3_3'].includes(elementProps.desktop.layout) || ['6_6', '3_3_3_3'].includes(elementProps.laptop.layout)"
            >
              <div
                class="row layout-item"
                :class="{ active: elementProps.laptop.layout == '6_6' }"
                @click="() => updateProps('layout', '6_6', 'laptop')"
              >
                <div class="cell cell-6">6</div>
                <div class="cell cell-6">6</div>
              </div>
            </div>
            <div
              class="col-4 layout-wrapper"
              v-if="elementProps.desktop.layout == '4_4_4' || elementProps.laptop.layout == '4_4_4'"
            >
              <div
                class="row layout-item"
                :class="{ active: elementProps.laptop.layout == '4_4_4' }"
                @click="() => updateProps('layout', '4_4_4', 'laptop')"
              >
                <div class="cell cell-4">4</div>
                <div class="cell cell-4">4</div>
                <div class="cell cell-4">4</div>
              </div>
            </div>
            <div
              class="col-4 layout-wrapper"
              v-if="elementProps.desktop.layout == '3_3_3_3' || elementProps.laptop.layout == '3_3_3_3'"
            >
              <div
                class="row layout-item"
                :class="{ active: elementProps.laptop.layout == '3_3_3_3' }"
                @click="() => updateProps('layout', '3_3_3_3', 'laptop')"
              >
                <div class="cell cell-3">3</div>
                <div class="cell cell-3">3</div>
                <div class="cell cell-3">3</div>
                <div class="cell cell-3">3</div>
              </div>
            </div>
            <div
              class="col-4 layout-wrapper"
              v-if="elementProps.desktop.layout == '8_4' || elementProps.laptop.layout == '8_4'"
            >
              <div
                class="row layout-item"
                :class="{ active: elementProps.laptop.layout == '8_4' }"
                @click="() => updateProps('layout', '8_4', 'laptop')"
              >
                <div class="cell cell-8">8</div>
                <div class="cell cell-4">4</div>
              </div>
            </div>
            <div
              class="col-4 layout-wrapper"
              v-if="elementProps.desktop.layout == '4_8' || elementProps.laptop.layout == '4_8'"
            >
              <div
                class="row layout-item"
                :class="{ active: elementProps.laptop.layout == '4_8' }"
                @click="() => updateProps('layout', '4_8', 'laptop')"
              >
                <div class="cell cell-4">4</div>
                <div class="cell cell-8">8</div>
              </div>
            </div>
            <div
              class="col-4 layout-wrapper"
              v-if="elementProps.desktop.layout == '3_9' || elementProps.laptop.layout == '3_9'"
            >
              <div
                class="row layout-item"
                :class="{ active: elementProps.laptop.layout == '3_9' }"
                @click="() => updateProps('layout', '3_9', 'laptop')"
              >
                <div class="cell cell-3">3</div>
                <div class="cell cell-9">9</div>
              </div>
            </div>
            <div
              class="col-4 layout-wrapper"
              v-if="elementProps.desktop.layout == '9_3' || elementProps.laptop.layout == '9_3'"
            >
              <div
                class="row layout-item"
                :class="{ active: elementProps.laptop.layout == '9_3' }"
                @click="() => updateProps('layout', '9_3', 'laptop')"
              >
                <div class="cell cell-9">9</div>
                <div class="cell cell-3">3</div>
              </div>
            </div>
            <div
              class="col-4 layout-wrapper"
              v-if="elementProps.desktop.layout == '6_3_3' || elementProps.laptop.layout == '6_3_3'"
            >
              <div
                class="row layout-item"
                :class="{ active: elementProps.laptop.layout == '6_3_3' }"
                @click="() => updateProps('layout', '6_3_3', 'laptop')"
              >
                <div class="cell cell-6">6</div>
                <div class="cell cell-3">3</div>
                <div class="cell cell-3">3</div>
              </div>
            </div>
            <div
              class="col-4 layout-wrapper"
              v-if="elementProps.desktop.layout == '3_3_6' || elementProps.laptop.layout == '3_3_6'"
            >
              <div
                class="row layout-item"
                :class="{ active: elementProps.laptop.layout == '3_3_6' }"
                @click="() => updateProps('layout', '3_3_6', 'laptop')"
              >
                <div class="cell cell-3">3</div>
                <div class="cell cell-3">3</div>
                <div class="cell cell-6">6</div>
              </div>
            </div>
            <div
              class="col-4 layout-wrapper"
              v-if="elementProps.desktop.layout == '3_6_3' || elementProps.laptop.layout == '3_6_3'"
            >
              <div
                class="row layout-item"
                :class="{ active: elementProps.laptop.layout == '3_6_3' }"
                @click="() => updateProps('layout', '3_6_3', 'laptop')"
              >
                <div class="cell cell-3">3</div>
                <div class="cell cell-6">6</div>
                <div class="cell cell-3">3</div>
              </div>
            </div>
            <div
              class="col-4 layout-wrapper"
              v-if="!['12', '6_6', '4_4_4', '3_3_3_3', '8_4', '4_8', '3_9', '9_3', '6_3_3', '3_3_6', '3_6_3'].includes(elementProps.desktop.layout)"
            >
              <div
                class="row layout-item"
                :class="{ active: current_layout_laptop == '' ? !['12', '6_6', '4_4_4', '3_3_3_3', '8_4', '4_8', '3_9', '9_3', '6_3_3', '3_3_6', '3_6_3'].includes(elementProps.laptop.layout) : elementProps.desktop.layout == current_layout_laptop }"
                @click="() => updateProps('layout', elementProps.desktop.layout, 'laptop')"
              >
                <div class="cell" :key="l" v-for="l in elementProps.desktop.layout.split('_')" :class="`cell-${l}`">{{ l }}</div>
              </div>
            </div>
            <div
              class="col-4 layout-wrapper"
              v-if="current_layout_laptop != '' && !['12', '6_6', '4_4_4', '3_3_3_3', '8_4', '4_8', '3_9', '9_3', '6_3_3', '3_3_6', '3_6_3'].includes(current_layout_laptop)"
            >
              <div
                class="row layout-item"
                :class="{ active: elementProps.laptop.layout == current_layout_laptop }"
                @click="() => updateProps('layout', current_layout_laptop, 'laptop')"
              >
                <div class="cell" :key="l" v-for="l in current_layout_laptop.split('_')" :class="`cell-${l}`">{{ l }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex flex-column py-2">
          <div class="title-layout">
            <span v-html="icons.tablet" />
            <span class="mx-2">Tablet Layout</span>
          </div>
          <div class="row row-layout">
            <div class="col-4 layout-wrapper">
              <div
                class="row layout-item"
                :class="{ active: elementProps.tablet.layout == '12' }"
                @click="() => updateProps('layout', '12', 'tablet')"
              >
                <div class="cell cell-12">12</div>
              </div>
            </div>
            <div
              class="col-4 layout-wrapper"
              v-if="elementProps.desktop.layout== '6_6' || elementProps.tablet.layout == '6_6'"
            >
              <div
                class="row layout-item"
                :class="{ active: elementProps.tablet.layout == '6_6' }"
                @click="() => updateProps('layout', '6_6', 'tablet')"
              >
                <div class="cell cell-6">6</div>
                <div class="cell cell-6">6</div>
              </div>
            </div>
            <div
              class="col-4 layout-wrapper"
              v-if="elementProps.desktop.layout == '4_4_4' || elementProps.tablet.layout == '4_4_4'"
            >
              <div
                class="row layout-item"
                :class="{ active: elementProps.tablet.layout == '4_4_4' }"
                @click="() => updateProps('layout', '4_4_4', 'tablet')"
              >
                <div class="cell cell-4">4</div>
                <div class="cell cell-4">4</div>
                <div class="cell cell-4">4</div>
              </div>
            </div>
            <div
              class="col-4 layout-wrapper"
              v-if="elementProps.desktop.layout == '3_3_3_3' || elementProps.tablet.layout == '3_3_3_3'"
            >
              <div
                class="row layout-item"
                :class="{ active: elementProps.tablet.layout == '3_3_3_3' }"
                @click="() => updateProps('layout', '3_3_3_3', 'tablet')"
              >
                <div class="cell cell-3">3</div>
                <div class="cell cell-3">3</div>
                <div class="cell cell-3">3</div>
                <div class="cell cell-3">3</div>
              </div>
            </div>
            <div
              class="col-4 layout-wrapper"
              v-if="elementProps.desktop.layout == '8_4' || elementProps.tablet.layout == '8_4'"
            >
              <div
                class="row layout-item"
                :class="{ active: elementProps.tablet.layout == '8_4' }"
                @click="() => updateProps('layout', '8_4', 'tablet')"
              >
                <div class="cell cell-8">8</div>
                <div class="cell cell-4">4</div>
              </div>
            </div>
            <div
              class="col-4 layout-wrapper"
              v-if="elementProps.desktop.layout == '4_8' || elementProps.tablet.layout == '4_8'"
            >
              <div
                class="row layout-item"
                :class="{ active: elementProps.tablet.layout == '4_8' }"
                @click="() => updateProps('layout', '4_8', 'tablet')"
              >
                <div class="cell cell-4">4</div>
                <div class="cell cell-8">8</div>
              </div>
            </div>
            <div
              class="col-4 layout-wrapper"
              v-if="elementProps.desktop.layout == '3_9' || elementProps.tablet.layout == '3_9'"
            >
              <div
                class="row layout-item"
                :class="{ active: elementProps.tablet.layout == '3_9' }"
                @click="() => updateProps('layout', '3_9', 'tablet')"
              >
                <div class="cell cell-3">3</div>
                <div class="cell cell-9">9</div>
              </div>
            </div>
            <div
              class="col-4 layout-wrapper"
              v-if="elementProps.desktop.layout == '9_3' || elementProps.tablet.layout == '9_3'"
            >
              <div
                class="row layout-item"
                :class="{ active: elementProps.tablet.layout == '9_3' }"
                @click="() => updateProps('layout', '9_3', 'tablet')"
              >
                <div class="cell cell-9">9</div>
                <div class="cell cell-3">3</div>
              </div>
            </div>
            <div
              class="col-4 layout-wrapper"
              v-if="elementProps.desktop.layout == '6_3_3' || elementProps.tablet.layout == '6_3_3'"
            >
              <div
                class="row layout-item"
                :class="{ active: elementProps.tablet.layout == '6_3_3' }"
                @click="() => updateProps('layout', '6_3_3', 'tablet')"
              >
                <div class="cell cell-6">6</div>
                <div class="cell cell-3">3</div>
                <div class="cell cell-3">3</div>
              </div>
            </div>
            <div
              class="col-4 layout-wrapper"
              v-if="elementProps.desktop.layout == '3_3_6' || elementProps.tablet.layout == '3_3_6'"
            >
              <div
                class="row layout-item"
                :class="{ active: elementProps.tablet.layout == '3_3_6' }"
                @click="() => updateProps('layout', '3_3_6', 'tablet')"
              >
                <div class="cell cell-3">3</div>
                <div class="cell cell-3">3</div>
                <div class="cell cell-6">6</div>
              </div>
            </div>
            <div
              class="col-4 layout-wrapper"
              v-if="elementProps.desktop.layout == '3_6_3' || elementProps.tablet.layout == '3_6_3'"
            >
              <div
                class="row layout-item"
                :class="{ active: elementProps.tablet.layout == '3_6_3' }"
                @click="() => updateProps('layout', '3_6_3', 'tablet')"
              >
                <div class="cell cell-3">3</div>
                <div class="cell cell-6">6</div>
                <div class="cell cell-3">3</div>
              </div>
            </div>
            <div
              class="col-4 layout-wrapper"
              v-if="!['12', '6_6', '4_4_4', '3_3_3_3', '8_4', '4_8', '3_9', '9_3', '6_3_3', '3_3_6', '3_6_3'].includes(elementProps.desktop.layout)"
            >
              <div
                class="row layout-item"
                :class="{ active: current_layout_tablet == '' ? !['12', '6_6', '4_4_4', '3_3_3_3', '8_4', '4_8', '3_9', '9_3', '6_3_3', '3_3_6', '3_6_3'].includes(elementProps.tablet.layout) : elementProps.desktop.layout == current_layout_tablet }"
                @click="() => updateProps('layout', elementProps.desktop.layout, 'tablet')"
              >
                <div class="cell" :key="l" v-for="l in elementProps.desktop.layout.split('_')" :class="`cell-${l}`">{{ l }}</div>
              </div>
            </div>
            <div
              class="col-4 layout-wrapper"
              v-if="current_layout_tablet != '' && !['12', '6_6', '4_4_4', '3_3_3_3', '8_4', '4_8', '3_9', '9_3', '6_3_3', '3_3_6', '3_6_3'].includes(current_layout_tablet)"
            >
              <div
                class="row layout-item"
                :class="{ active: elementProps.tablet.layout == current_layout_tablet }"
                @click="() => updateProps('layout', current_layout_tablet, 'tablet')"
              >
                <div class="cell" :key="l" v-for="l in current_layout_tablet.split('_')" :class="`cell-${l}`">{{ l }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex flex-column py-2">
          <div class="title-layout">
            <span v-html="icons.mobile"></span>
            <span class="mx-2">Mobile Layout</span>
          </div>
          <div class="row row-layout">
            <div class="col-4 layout-wrapper">
              <div
                class="row layout-item"
                :class="{ active: elementProps.mobile.layout == '12' }"
                @click="() => updateProps('layout', '12', 'mobile')"
              >
                <div class="cell cell-12">12</div>
              </div>
            </div>
            <div
              class="col-4 layout-wrapper"
              v-if="['3_3_3_3', '6_6'].includes(elementProps.desktop.layout) || elementProps.mobile.layout == '6_6'"
            >
              <div
                class="row layout-item"
                :class="{ active: elementProps.mobile.layout == '6_6' }"
                @click="() => updateProps('layout', '6_6', 'mobile')"
              >
                <div class="cell cell-6">6</div>
                <div class="cell cell-6">6</div>
              </div>
            </div>
            <div
              class="col-4 layout-wrapper"
              v-if="elementProps.desktop.layout == '4_4_4' || elementProps.mobile.layout == '4_4_4'"
            >
              <div
                class="row layout-item"
                :class="{ active: elementProps.mobile.layout == '4_4_4' }"
                @click="() => updateProps('layout', '4_4_4', 'mobile')"
              >
                <div class="cell cell-4">4</div>
                <div class="cell cell-4">4</div>
                <div class="cell cell-4">4</div>
              </div>
            </div>
            <div
              class="col-4 layout-wrapper"
              v-if="elementProps.desktop.layout == '3_3_3_3' || elementProps.mobile.layout == '3_3_3_3'"
            >
              <div
                class="row layout-item"
                :class="{ active: elementProps.mobile.layout == '3_3_3_3' }"
                @click="() => updateProps('layout', '3_3_3_3', 'mobile')"
              >
                <div class="cell cell-3">3</div>
                <div class="cell cell-3">3</div>
                <div class="cell cell-3">3</div>
                <div class="cell cell-3">3</div>
              </div>
            </div>
            <div
              class="col-4 layout-wrapper"
              v-if="elementProps.desktop.layout == '8_4' || elementProps.mobile.layout == '8_4'"
            >
              <div
                class="row layout-item"
                :class="{ active: elementProps.mobile.layout == '8_4' }"
                @click="() => updateProps('layout', '8_4', 'mobile')"
              >
                <div class="cell cell-8">8</div>
                <div class="cell cell-4">4</div>
              </div>
            </div>
            <div
              class="col-4 layout-wrapper"
              v-if="elementProps.desktop.layout == '4_8' || elementProps.mobile.layout == '4_8'"
            >
              <div
                class="row layout-item"
                :class="{ active: elementProps.mobile.layout == '4_8' }"
                @click="() => updateProps('layout', '4_8', 'mobile')"
              >
                <div class="cell cell-4">4</div>
                <div class="cell cell-8">8</div>
              </div>
            </div>
            <div
              class="col-4 layout-wrapper"
              v-if="elementProps.desktop.layout == '3_9' || elementProps.mobile.layout == '3_9'"
            >
              <div
                class="row layout-item"
                :class="{ active: elementProps.mobile.layout == '3_9' }"
                @click="() => updateProps('layout', '3_9', 'mobile')"
              >
                <div class="cell cell-3">3</div>
                <div class="cell cell-9">9</div>
              </div>
            </div>
            <div
              class="col-4 layout-wrapper"
              v-if="elementProps.desktop.layout == '9_3' || elementProps.mobile.layout == '9_3'"
            >
              <div
                class="row layout-item"
                :class="{ active: elementProps.mobile.layout == '9_3' }"
                @click="() => updateProps('layout', '9_3', 'mobile')"
              >
                <div class="cell cell-9">9</div>
                <div class="cell cell-3">3</div>
              </div>
            </div>
            <div
              class="col-4 layout-wrapper"
              v-if="elementProps.desktop.layout == '6_3_3' || elementProps.mobile.layout == '6_3_3'"
            >
              <div
                class="row layout-item"
                :class="{ active: elementProps.mobile.layout == '6_3_3' }"
                @click="() => updateProps('layout', '6_3_3', 'mobile')"
              >
                <div class="cell cell-6">6</div>
                <div class="cell cell-3">3</div>
                <div class="cell cell-3">3</div>
              </div>
            </div>
            <div
              class="col-4 layout-wrapper"
              v-if="elementProps.desktop.layout == '3_3_6' || elementProps.mobile.layout == '3_3_6'"
            >
              <div
                class="row layout-item"
                :class="{ active: elementProps.mobile.layout == '3_3_6' }"
                @click="() => updateProps('layout', '3_3_6', 'mobile')"
              >
                <div class="cell cell-3">3</div>
                <div class="cell cell-3">3</div>
                <div class="cell cell-6">6</div>
              </div>
            </div>
            <div
              class="col-4 layout-wrapper"
              v-if="elementProps.desktop.layout == '3_6_3' || elementProps.mobile.layout == '3_6_3'"
            >
              <div
                class="row layout-item"
                :class="{ active: elementProps.mobile.layout == '3_6_3' }"
                @click="() => updateProps('layout', '3_6_3', 'mobile')"
              >
                <div class="cell cell-3">3</div>
                <div class="cell cell-6">6</div>
                <div class="cell cell-3">3</div>
              </div>
            </div>
            <div
              class="col-4 layout-wrapper"
              v-if="!['12', '6_6', '4_4_4', '3_3_3_3', '8_4', '4_8', '3_9', '9_3', '6_3_3', '3_3_6', '3_6_3'].includes(elementProps.desktop.layout)"
            >
              <div
                class="row layout-item"
                :class="{ active: current_layout_mobile == '' ? !['12', '6_6', '4_4_4', '3_3_3_3', '8_4', '4_8', '3_9', '9_3', '6_3_3', '3_3_6', '3_6_3'].includes(elementProps.mobile.layout) : elementProps.desktop.layout == current_layout_mobile }"
                @click="() => updateProps('layout', elementProps.desktop.layout, 'mobile')"
              >
                <div class="cell" :key="l" v-for="l in elementProps.desktop.layout.split('_')" :class="`cell-${l}`">{{ l }}</div>
              </div>
            </div>
            <div
              class="col-4 layout-wrapper"
              v-if="current_layout_mobile != '' && !['12', '6_6', '4_4_4', '3_3_3_3', '8_4', '4_8', '3_9', '9_3', '6_3_3', '3_3_6', '3_6_3'].includes(current_layout_mobile)"
            >
              <div
                class="row layout-item"
                :class="{ active: elementProps.mobile.layout == current_layout_mobile }"
                @click="() => updateProps('layout', current_layout_mobile, 'mobile')"
              >
                <div class="cell" :key="l" v-for="l in current_layout_mobile.split('_')" :class="`cell-${l}`">{{ l }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div :class="show == 'custom' ? '' : 'hide-custom'">
        <div class="p-3">
          <div class="d-flex flex-column mb-8 position-relative">
            <div class="d-flex align-items-center gap-2 mb-2">
              <span v-html="icons.desktop" />
              <span>Desktop Layout</span>
            </div>
            <div :class="`${this.getLayoutValue('desktop').length == 0 ? 'column-12' : ''}`" class="slider-column slider-column-customizer-desktop"></div>
            <div
              v-for="(l, i) in this.getLayoutScreen('desktop')"
              class="row-text"
              :id="`row-desktop-${i + 1}`"
              :style="`left: ${getLeftValue('desktop', l, i)}px;`"
              :key="i"
            >{{ l }}</div>
          </div>
          <div class="d-flex flex-column mb-8 position-relative">
            <div class="d-flex align-items-center gap-2 mb-2">
              <span v-html="icons.laptop" />
              <span>Laptop Layout</span>
            </div>
            <div :class="`${this.getLayoutValue('laptop').length == 0 ? 'column-12' : ''}`" class="slider-column slider-column-customizer-laptop"></div>
            <div
              v-for="(l, i) in this.getLayoutScreen('laptop')"
              class="row-text"
              :id="`row-laptop-${i + 1}`"
              :style="`left: ${getLeftValue('laptop', l, i)}px;`"
              :key="i"
            >{{ l }}</div>
          </div>
          <div class="d-flex flex-column mb-8 position-relative">
            <div class="d-flex align-items-center gap-2 mb-2">
              <span v-html="icons.tablet" />
              <span>Tablet Layout</span>
            </div>
            <div :class="`${this.getLayoutValue('tablet').length == 0 ? 'column-12' : ''}`" class="slider-column slider-column-customizer-tablet"></div>
            <div
              v-for="(l, i) in this.getLayoutScreen('tablet')"
              class="row-text"
              :id="`row-tablet-${i + 1}`"
              :style="`left: ${getLeftValue('tablet', l, i)}px;`"
              :key="i"
            >{{ l }}</div>
          </div>
          <div class="d-flex flex-column mb-8 position-relative">
            <div class="d-flex align-items-center gap-2 mb-2">
              <span v-html="icons.mobile" />
              <span>Mobile Layout</span>
            </div>
            <div :class="`${this.getLayoutValue('mobile').length == 0 ? 'column-12' : ''}`" class="slider-column slider-column-customizer-mobile"></div>
            <div
              v-for="(l, i) in this.getLayoutScreen('mobile')"
              class="row-text"
              :id="`row-mobile-${i + 1}`"
              :style="`left: ${getLeftValue('mobile', l, i)}px;`"
              :key="i"
            >{{ l }}</div>
          </div>
        </div>
      </div>
    <div class="more-settings" @click="handleShowCustom">
      <div class="d-flex align-items-center" v-if="show == 'default'">
        <span>More settings</span>
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" stroke-width="1.5" stroke="#ffffff" fill="none" stroke-linecap="round" stroke-linejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <polyline points="9 6 15 12 9 18"></polyline>
        </svg>
      </div>
      <div class="d-flex align-items-center" v-else>
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" stroke-width="1.5" stroke="#ffffff" fill="none" stroke-linecap="round" stroke-linejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <polyline points="15 6 9 12 15 18"></polyline>
        </svg>
        <span>Back</span>
      </div>
    </div>
  </div>
</template>

<script>
import mixin from "../styleSettings/mixin";
import { icons } from "../../../../../constants";
import Node from "@/core/Node";
export default {
  mixins: [mixin],
  data() {
    return {
      icons,
      // show: 'default',
      current_layout_desktop: this.$props.node.props.desktop.layout,
      current_layout_laptop: this.$props.node.props.laptop.layout,
      current_layout_tablet: this.$props.node.props.tablet.layout,
      current_layout_mobile: this.$props.node.props.mobile.layout,
    };
  },
  props: {
    changeScreen: Function,
    node: Node,
    show: String,
    setShow: Function,
  },
  watch: {
    show: function (value) {
      if (value == 'custom') {
          $('.slider-column-customizer-desktop').quinn({
            min: 1,
            max: 11,
            value: this.getLayoutValue('desktop').length > 0 ? this.getLayoutValue('desktop') : 1,
            drawTo: { left: 0, right: 12 },
            step: 1,
            disable: this.getLayoutValue('desktop').length > 0 ? false : true,
            drag: (vals) => {
              this.changeScreen('desktop');
              const data_layout = this.handleDrag(vals);
              this.updateProps('layout', data_layout, 'desktop');
              this.current_layout_desktop = data_layout;
            }
          });
          $('.slider-column-customizer-laptop').quinn({
            min: 1,
            max: 11,
            value: this.getLayoutValue('laptop').length > 0 ? this.getLayoutValue('laptop') : 1,
            drawTo: { left: 0, right: 12 },
            step: 1,
            disable: this.getLayoutValue('laptop').length > 0 ? false : true,
            drag: (vals) => {
              this.changeScreen('laptop');
              const data_layout = this.handleDrag(vals);
              this.updateProps('layout', data_layout, 'laptop');
              this.current_layout_laptop = data_layout;
            }
          });
          $('.slider-column-customizer-tablet').quinn({
            min: 1,
            max: 11,
            value: this.getLayoutValue('tablet').length > 0 ? this.getLayoutValue('tablet') : 1,
            drawTo: { left: 0, right: 12 },
            step: 1,
            disable: this.getLayoutValue('tablet').length > 0 ? false : true,
            drag: (vals) => {
              this.changeScreen('tablet');
              const data_layout = this.handleDrag(vals);
              this.updateProps('layout', data_layout, 'tablet');
              this.current_layout_tablet = data_layout;
            }
          });
          $('.slider-column-customizer-mobile').quinn({
            min: 1,
            max: 11,
            value: this.getLayoutValue('mobile').length > 0 ? this.getLayoutValue('mobile') : 1,
            drawTo: { left: 0, right: 12 },
            step: 1,
            disable: this.getLayoutValue('mobile').length > 0 ? false : true,
            drag: (vals) => {
              this.changeScreen('mobile');
              const data_layout = this.handleDrag(vals);
              this.updateProps('layout', data_layout, 'mobile');
              this.current_layout_mobile = data_layout;
            }
          });
      }
    }
  },
  methods: {
    handleDrag(vals) {
      var values;
      if (typeof vals == 'object') {
        values = vals;
      } else {
        values = [vals]
      }
      const res = [];
      values.map((value, i) => {
        if (i == 0) {
          res.push(String(value))
        } else {
          res.push(values[i] - values[i - 1])
        }
      })
      const last_value = 12 - values.at(-1);
      res.push(String(last_value));
      return res.join('_');
    },
    getLeftValue(screen, l, i) {
      const dataLayout = this.getLayoutScreen(screen).slice(0, i);
      const value = dataLayout.reduce((a, b) => a + b, 0)
      var left = i == 0 ? (l * 10) : (value * 20 + l * 10);
      if (l >= 10) {
        left = left - 7;
      }
      return left;
    },
    getLayoutValue(screen) {
      const layout = this.elementProps[screen].layout
      const dataLayout = layout.split('_').map(e => parseInt(e));
      if (dataLayout.length == 1) {
        return []
      }
      dataLayout.pop();
      const res = [];
      const arrays = Array.from(Array(dataLayout.length).keys());
      arrays.map(i => {
        const arr = dataLayout.slice(0, i + 1);
        const sum = arr.reduce((a, b) => a + b, 0);
        res.push(sum)
      })
      return res;
    },
    getLayoutScreen(screen) {
      const layout = this.elementProps[screen].layout
      const dataLayout = layout.split('_').map(e => parseInt(e));
      return dataLayout;
    },
    handleShowCustom() {
      this.setShow(this.$props.show == 'default' ? 'custom' : 'default');
    },
    updateProps(name, value, screen, depend) {
      this.changeScreen(screen);
      this[`current_layout_${screen}`] = "";
      const {editor} = this.node
      let nodes = editor.export()
      let popups = editor.exportPopup()
      let inversePatches = {nodes,popups}
      const mapScreen = {
        desktop: "dataLayoutLg",
        laptop: "dataLayoutMd",
        tablet: "dataLayoutSm",
        mobile: "dataLayoutXl",
      };
      const children = this.node.children;
      const num_blocks = value.split("_");
      if (children.length == num_blocks.length) {
      } else if (children.length < num_blocks.length && screen == "desktop") {
        const diff = num_blocks.length - children.length;
        const last_node = children[children.length - 1];
        Array.from(Array(diff).keys()).map((e) => {
          const duplicate_note = last_node.duplicateWithoutChildren(this.node.parent);
          this.node.children.push(duplicate_note);
        });
      } else if (children.length > num_blocks.length && screen == "desktop") {
        const diff = children.length - num_blocks.length;
        var arrayAppend = []
        Array.from(Array(diff).keys()).map((e) => {
          const last_node = this.node.children.pop();
          arrayAppend = [...last_node.children,...arrayAppend]
        });
        var last_node1 = this.node.children[this.node.children.length - 1];
        arrayAppend.map(item=>{
          last_node1.children.push(item)
          item.parent = last_node1
        })
      }
      this.node.children.map((e, index) => {
        const attrs = {};
        if (value == "12") {
          if (screen != "desktop") {
            attrs[mapScreen[screen]] = num_blocks[index] || num_blocks[0];
          } else {
            attrs["dataLayoutLg"] = "12";
            attrs["dataLayoutMd"] = "12";
            attrs["dataLayoutSm"] = "12";
            attrs["dataLayoutXl"] = "12";
          }
        } else if (["6_6", "8_4", "4_8", "3_9", "9_3"].includes(value)) {
          if (screen == "desktop") {
            attrs["dataLayoutLg"] = num_blocks[index] || num_blocks[0];
            if (depend) {
              attrs["dataLayoutMd"] = num_blocks[index] || num_blocks[0];
              attrs["dataLayoutSm"] = num_blocks[index] || num_blocks[0];
              attrs["dataLayoutXl"] = "12";
            }
          } else {
            attrs[mapScreen[screen]] = num_blocks[index] || num_blocks[0];
          }
        } else if (["4_4_4", "6_3_3", "3_3_6", "3_6_3"].includes(value)) {
          if (screen == "desktop") {
            attrs["dataLayoutLg"] = num_blocks[index] || num_blocks[0];
            if (depend) {
              attrs["dataLayoutMd"] = num_blocks[index] || num_blocks[0];
              attrs["dataLayoutSm"] = "12";
              attrs["dataLayoutXl"] = "12";
            }
          } else {
            attrs[mapScreen[screen]] = num_blocks[index] || num_blocks[0];
          }
        } else {
          if (screen == "desktop") {
            attrs["dataLayoutLg"] = num_blocks[index] || num_blocks[0];
            if (depend) {
              attrs["dataLayoutMd"] = num_blocks[index] || num_blocks[0];
              attrs["dataLayoutSm"] = "6";
              attrs["dataLayoutXl"] = "12";
            }
          } else {
            attrs[mapScreen[screen]] = num_blocks[index] || num_blocks[0];
          }
        }
        e.setProps(attrs, true);
      });
      const laptop = Object.assign({}, this.elementProps['laptop']);
      const tablet = Object.assign({}, this.elementProps['tablet']);
      const mobile = Object.assign({}, this.elementProps['mobile']);
      const data_screen = Object.assign({}, this.elementProps[screen]);
      data_screen[name] = value;
      var change = {}
      change = { [screen]: data_screen }
      if (screen == "desktop" && depend) {
        if (value == "12") {
          laptop[name] = value;
          tablet[name] = value;
          mobile[name] = value;
          change ={...change,laptop, tablet, mobile }
        } else if (["6_6", "8_4", "4_8", "3_9", "9_3"].includes(value)) {
          laptop[name] = value;
          tablet[name] = value;
          mobile[name] = "12";
          change ={...change,laptop, tablet, mobile }
        } else if (["4_4_4", "6_3_3", "3_3_6", "3_6_3"].includes(value)) {
          laptop[name] = value;
          tablet[name] = "12";
          mobile[name] = "12";
          change ={...change,laptop, tablet, mobile }
        } else {
          laptop[name] = value;
          tablet[name] = "6_6";
          mobile[name] = "12";
          change ={...change,laptop, tablet, mobile }
        }
      }
      this.node.setProps(change,true);
      let patches = {nodes:editor.export(), popups: editor.exportPopup()};
      editor.add(patches, inversePatches)
    },
  },
};
</script>

<style lang="scss">
.row-text {
  position: absolute;
  margin-left: -4px;
  height: 20px;
  top: 32px;
  z-index: 5;
  width: fit-content;
}
.mb-8 {
  margin-bottom: 2rem;
}
.column-12 {
  .handle {
    display: none !important;
  }
}
.slider-column {
  opacity: 1 !important;
  .main, .left, .right {
    background-color: #676767;
    background-image: unset !important;
    background-size: unset !important;
    height: 20px !important;
    cursor: col-resize;
  }
  .left {
    left: -4px;
  }
  .right {
    right: 0px;
  }
  .handle {
    background-image: unset !important;
    background-color: #fdb913;
    height: 20px !important;
    width: 2px !important;
    margin-top: 0px !important;
    cursor: col-resize;
  }
}
.hide-custom {
  height: 0px;
  overflow: hidden;
}

.opacity-0 {
  opacity: 0;
}
.opacity-1 {
  opacity: 1;
}
.more-settings {
  padding: 0.75rem 0px;
  margin-top: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-top: 1px solid #494949;
  font-size: 12px;
  transition: all 0.3s ease;
  &:hover {
    background-color: #494949;
    transition: all 0.3s ease;
  }
}
.title-layout {
  padding: 0 14px;
}
.row-layout {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.layout-wrapper {
  padding: 12px 8px 0px !important;
}

.layout-item {
  height: 28px;
  margin-left: 0px !important;
  margin-right: 0px !important;

  .cell {
    background-color: #848484;
    border-radius: 2px;
    padding: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
  }
  .cell-1 {
    width: calc(100% / 12 - 2px);
    flex: 0 0 calc(100% / 12 - 2px);
    margin: 0 1px;
  }
  .cell-2 {
    width: calc(100% / 6 - 2px);
    flex: 0 0 calc(100% / 6 - 2px);
    margin: 0 1px;
  }
  .cell-3 {
    width: calc(25% - 2px);
    flex: 0 0 calc(25% - 2px);
    margin: 0 1px;
  }
  .cell-4 {
    width: calc(100% / 3 - 2px);
    flex: 0 0 calc(100% / 3 - 2px);
    margin: 0 1px;
  }
  .cell-5 {
    width: calc(500% / 12 - 2px);
    flex: 0 0 calc(500% / 12 - 2px);
    margin: 0 1px;
  }
  .cell-6 {
    width: calc(50% - 2px);
    flex: 0 0 calc(50% - 2px);
    margin: 0 1px;
  }
  .cell-7 {
    width: calc(700% / 12 - 2px);
    flex: 0 0 calc(700% / 12 - 2px);
    margin: 0 1px;
  }
  .cell-8 {
    width: calc(200% / 3 - 2px);
    flex: 0 0 calc(200% / 3 - 2px);
    margin: 0 1px;
  }
  .cell-9 {
    width: calc(75% - 2px);
    flex: 0 0 calc(75% - 2px);
    margin: 0 1px;
  }
  .cell-10 {
    width: calc(1000% / 12 - 2px);
    flex: 0 0 calc(1000% / 12 - 2px);
    margin: 0 1px;
  }
  .cell-11 {
    width: calc(1100% / 12 - 2px);
    flex: 0 0 calc(1100% / 12 - 2px);
    margin: 0 1px;
  }
  .cell-12 {
    width: calc(100% - 2px);
    flex: 0 0 calc(100% - 2px);
    margin: 0 1px;
  }
}

.layout-item.active {
  .cell {
    background-color: #fdb913;
  }
}
</style>
