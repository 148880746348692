var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"label-position":"top","size":"","model":_vm.elementProps}},[_c('div',{staticClass:"d-flex flex-column py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Item Number")]),_c('Select',{staticClass:"mt-2",attrs:{"value":_vm.elementProps.number_of_items,"primaryColor":_vm.primaryColor,"changeValue":($event) => _vm.updateProps('number_of_items', $event),"options":[
        {
          value: '1',
          label: '1',
        },
        {
          value: '2',
          label: '2',
        },
        {
          value: '3',
          label: '3',
        },
        {
          value: '4',
          label: '4',
        }
      ]}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }