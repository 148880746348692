<template>
  <div
    id="md-toolbar-link"
    :class="isOpen ? `show` : ''"
    :style="styles"
    ref="myDiv"
  >
    <div class="md-content" v-if="number >= 0">
      <div class="md-body">
        <div class="ui-dform-group">
          <div class="gfTextEditor_pick_link">
            <input
              v-if="isOpen"
              type="text"
              placeholder="Eg: https://salegate.com"
              v-model="text"
            />
            <div class="button-wrapper">
              <button
                type="button"
                data-action="enter"
                v-on:click.prevent="onSetHref()"
              >
                <svg class="need_a_name" height="24px" viewBox="0 0 24 24">
                  <path
                    d="M19.5,7C19.23,7,19,7.22,19,7.49V11H5.83l3.22-3.23c0.2-0.2,0.19-0.52,0-0.71l-0.7-0.7 c-0.2-0.2-0.52-0.2-0.71,0l-5.29,5.29c-0.2,0.2-0.2,0.52,0,0.71l5.29,5.29c0.2,0.2,0.52,0.2,0.71,0l0.7-0.7 c0.2-0.2,0.2-0.51,0-0.71L5.83,13H20.5c0.28,0,0.5-0.23,0.5-0.49V7.49C21,7.22,20.79,7,20.5,7H19.5z"
                  ></path>
                </svg>
              </button>
              <!-- <button type="button" data-action="settings">
                <svg class="need_a_name" height="24px" viewBox="0 0 24 24">
                  <path
                    d="M19.88,7.65c0.33,0.6,0.59,1.24,0.78,1.89c0.07,0.26-0.07,0.53-0.32,0.61l-0.1,0.04 c-0.74,0.3-1.23,1.04-1.23,1.83c0,0.84,0.53,1.6,1.33,1.88c0.25,0.09,0.39,0.36,0.31,0.61c-0.19,0.66-0.46,1.29-0.79,1.89 c-0.13,0.23-0.41,0.32-0.65,0.21c-0.72-0.34-1.68-0.18-2.25,0.38c-0.59,0.58-0.75,1.49-0.4,2.24c0.11,0.24,0.02,0.52-0.21,0.65 c-0.29,0.16-0.59,0.3-0.89,0.43c-0.32,0.13-0.65,0.25-0.98,0.34c-0.25,0.07-0.52-0.07-0.61-0.32c-0.28-0.8-1.03-1.34-1.87-1.34 c-0.8,0-1.61,0.57-1.88,1.32c-0.09,0.25-0.36,0.39-0.61,0.31c-0.65-0.19-1.28-0.45-1.87-0.79c-0.23-0.13-0.32-0.41-0.21-0.65 c0.35-0.76,0.2-1.66-0.39-2.25c-0.59-0.59-1.47-0.75-2.18-0.43c-0.24,0.1-0.59,0.04-0.71-0.18c-0.33-0.6-0.59-1.24-0.78-1.89 c-0.07-0.25,0.04-0.51,0.31-0.61c0.81-0.34,1.34-1.07,1.34-1.87c0-0.84-0.53-1.6-1.32-1.88C3.42,10,3.28,9.73,3.36,9.48 c0.19-0.65,0.46-1.29,0.79-1.89C4.28,7.37,4.57,7.27,4.8,7.39C5.52,7.72,6.49,7.56,7.06,7c0.59-0.58,0.75-1.49,0.4-2.25 C7.35,4.52,7.44,4.24,7.67,4.11C8.27,3.78,8.9,3.52,9.55,3.34c0.26-0.07,0.52,0.07,0.61,0.32c0.28,0.8,1.03,1.34,1.87,1.34 c0.81,0,1.61-0.57,1.88-1.32c0.09-0.25,0.36-0.39,0.61-0.31c0.64,0.19,1.27,0.45,1.87,0.78c0.23,0.13,0.32,0.41,0.21,0.65 c-0.35,0.76-0.2,1.66,0.39,2.25c0.57,0.57,1.43,0.74,2.19,0.43C19.41,7.37,19.76,7.43,19.88,7.65z M13.15,14.77 c1.53-0.63,2.26-2.39,1.62-3.92c-0.63-1.53-2.39-2.26-3.92-1.62c-1.53,0.63-2.26,2.39-1.62,3.92C9.86,14.68,11.62,15.4,13.15,14.77z "
                  ></path>
                </svg>
              </button> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import Node from "../core/Node";
import { toolbarIcons } from "../constants";
import { v4 as uuidv4 } from "uuid";

export default {
  components: {},
  props: {
    isOpen: Boolean,
    editor: HTMLElement,
    iframe: Object,
    node: Node,
  },
  watch: {
    isOpen() {
      this.styles = this.elementCSS();
      if (!this.isOpen) {
        if (this.text.length == 0) {
          const selection = this.iframe.$el.contentWindow.getSelection();
          const doc = this.iframe.$el.contentWindow.document;
          doc.execCommand("unlink");
          selection.removeAllRanges();
        }
      } else {
        const selection = this.iframe.$el.contentWindow.getSelection();
        if (!selection.rangeCount) return;
        var range = selection.getRangeAt(0);
        var html = range.commonAncestorContainer;
        if (
          html?.parentNode?.nodeName == "A" &&
          html?.parentNode?.getAttribute("data-href")
        ) {
          this.text = html?.parentNode?.getAttribute("data-href");
        } else {
          this.text = "";
        }
        this.oldData = this.node.props.content;
      }
    },
    editor: function (val, oldVal) {
      if (oldVal && oldVal.innerHTML != this.oldData && this.oldData) {
        oldVal.innerHTML = this.oldData;
        this.oldData = null;
      }
      if(val && this.isOpen){
        this.oldData = val.innerHTML
      }
    },
  },
  data() {
    return {
      number: 0,
      styles: {},
      text: "",
      oldData: "",
    };
  },
  mounted() {},
  unmounted() {},
  created() {
    this.$bus.on("showToolbar", (currentRef, value, node) => {
      if (value && this.isOpen) {
        this.$bus.emit("showToolbarLink", false);
      }
    });
  },
  updated() {
    // this.styles = this.elementCSS();
  },
  provide() {},
  computed: {},
  methods: {
    elementCSS: function () {
      const windowContent = this.iframe.$el.contentWindow;
      const style = {};
      const selection = windowContent.getSelection();
      if (!selection.rangeCount) return {};
      var range = selection.getRangeAt(0);
      const left = range?.getBoundingClientRect().left;
      const right = range?.getBoundingClientRect().right;

      const top = range?.getBoundingClientRect().top;
      const height = range?.getBoundingClientRect().height;
      const width = range?.getBoundingClientRect().width;
      const leftIframe = this.iframe?.$el?.getBoundingClientRect().left;
      if (window.innerWidth - 400 <= leftIframe + left) {
        style["right"] = "20px";
      } else {
        style["left"] = `${left + leftIframe + (width - 400) / 2}px`;
      }
      style["top"] = `${top + height + 5 + 60}px`;
      return style;
    },
    onSetHref: function () {
      const doc = this.iframe.$el.contentWindow.document;
      var sText = doc.getSelection();
      if (this.text.length > 0) {
        doc.execCommand(
          "insertHTML",
          false,
          `<a href="javascript:void(0)" data-href="${this.text}" data-target="_blank" class>` + sText + "</a>"
        );
      } else {
        doc.execCommand("unlink");
      }
      sText.removeAllRanges();
      this.oldData = this.editor?.innerHTML;
      this.node.setProps({ content: this.editor?.innerHTML });
      this.$bus.emit("showToolbarLink", false);
    },
  },
};
</script>
<style scoped>
#md-toolbar-link .md-content {
  display: block;
  padding: 0 !important;
  border-radius: 3px;
}

.md-content {
  color: #000;
  background: #fff;
  position: relative;
  margin: 0 auto;
  padding-bottom: 48px;
}

#md-toolbar-link .gfTextEditor_pick_link,
#md-toolbar-link .gfTextEditor_pick_link .button-wrapper {
  display: flex;
}
#md-toolbar-link .gfTextEditor_pick_link input {
  background: transparent;
  border: none;
  padding: 0 1em;
  line-height: 50px;
  width: calc(100% - 46px);
  font-family: Inter, Roboto, Helvetica Neue, sans-serif;
  font-size: 15px;
  color: rgba(10, 10, 58, 0.8);
  letter-spacing: 0.3px;
  margin: 0;
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
}

#md-toolbar-link .gfTextEditor_pick_link,
#md-toolbar-link .gfTextEditor_pick_link .button-wrapper {
  display: flex;
}
#md-toolbar-link .gfTextEditor_pick_link button[data-action="enter"] {
  background: #3c67ff;
  margin-right: 4px;
}

#md-toolbar-link .gfTextEditor_pick_link button {
  background: rgba(224, 229, 238, 0.3);
  border: none;
  width: 42px;
  height: 42px;
  margin: 4px 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
}
#md-toolbar-link .gfTextEditor_pick_link button[data-action="enter"] svg {
  fill: #fff;
}

#md-toolbar-link {
  width: 400px;
  position: absolute;
  z-index: 99999999;
  /* left: 0; */
  top: 0;
  background: #fff;
  box-shadow: 0 0 0 1px #d9e0ec, 0 3px 8px 0 rgb(10 10 58 / 10%);
  border-radius: 3px;
  display: none !important;
  user-select: none;
}

#md-toolbar-link.show {
  display: block !important;
}
input {
  outline: none;
}
</style>
