<template>
  <div label-position="top" size="small" :model="elementProps">
    <div class="d-flex flex-column py-2">
      <span class="setting-title">Label</span>
      <el-input
        class="mt-2"
        :value="elementProps.label"
        @input="($event) => updateProps('label', $event)"
      />
    </div>
  </div>
</template>

<script>
import mixin from "../styleSettings/mixin";

export default {
  mixins: [mixin],
  components: {},
  methods: {
    updateProps(name, value) {
      if(value ==  this.elementProps[name]) return;
      this.elementPropsSetter({ [name]: value });
    },
  },
};
</script>

<style scoped>
</style>
