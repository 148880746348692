<template>
  <div class="banner-description">
    <div>
      <span class="highlight">Attention: </span>
      <span>For the best display, the image uploaded in Hero banner should have dimensions* as below:</span>
    </div>
    <span>Desktop/Laptop: 1920x800</span>
    <span>Mobile: 375x200 or 375x400</span>
    <span>Tablet: 1024x500</span>
    <span>* (width*height in px)</span>
  </div>
</template>

<script>
import mixin from './mixin';
export default {
  mixins: [mixin],
  components: {
  },
};
</script>

<style scoped>
.banner-description {
  font-size: 12px;
  font-style: italic;
  color: #D6D6D6;
  display: flex;
  flex-direction: column;
  padding: 8px 0px;
}
.highlight {
  color: #FDB913;
}
</style>