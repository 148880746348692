<template>
  <div class="mt-2 d-flex w-100 custom-dimensions" :style="cssVars">
    <div class="item" v-for="item in items" :key="item.key">
      <el-input
        :value="data[item.key]"
        @input="($event) => updateValues(item.key, $event)"
        type="number"
        :disabled="item.disabled"
      />
      <div class="title">
        {{ item.title }}
      </div>
    </div>
    <div
      class="item d-flex align-items-center justify-content-center"
      :class="{ active: link == true }"
      @click="() => linkValues(!link)"
    >
      <el-tooltip content="Link values together" placement="bottom">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 24 24"
          stroke-width="2.5"
          stroke="#9e9e9e"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <path
            d="M10 14a3.5 3.5 0 0 0 5 0l4 -4a3.5 3.5 0 0 0 -5 -5l-.5 .5"
          ></path>
          <path
            d="M14 10a3.5 3.5 0 0 0 -5 0l-4 4a3.5 3.5 0 0 0 5 5l.5 -.5"
          ></path>
        </svg>
      </el-tooltip>
    </div>
    <div class="unit">
      <span
        :class="{ active: unit == u }"
        v-for="u in units"
        :key="u"
        @click="() => changeUnit(u)"
      >
        {{ u }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    name: "Dimension",
  },
  data() {
    return {
      link: true,
    };
  },
  props: {
    unit: String,
    units: Array,
    items: Array,
    changeValue: Function,
    changeUnit: Function,
    primaryColor: String,
    data: Object,
    minValue: Number,
    isPositive: Boolean,
    onlyInteger: Boolean
  },
  methods: {
    linkValues(link) {
      this.link = link;
      if (link) {
        const items = this.items.filter((e) => !e.disabled);
        if (items.length > 0) {
          const value = this.data[items[0].key];
          this.items.forEach((e) => {
            if (!e.disabled) {
              this.changeValue(e.key, value);
            }
          });
        }
      }
    },
    updateValues(key, value) {
      if (this.minValue != null && value < this.minValue && this.minValue != null && this.minValue != undefined) {
        value = this.minValue;
      }
      if (this.$props.isPositive && Number.parseInt(value) < 0) {
        value = this.minValue || 0;
      }
      if (this.$props.onlyInteger) {
        value = Math.floor(value)
      }
      if (!this.link) {
        this.changeValue(key, value)
      } else {
        this.items.forEach(e => {
          if (!e.disabled) {
            this.changeValue(e.key, value)
          }
        })
      }
    },
  },
  computed: {
    cssVars() {
      return {
        "--primaryColor": this.primaryColor || "#0060e5",
      };
    },
  },
};
</script>

<style lang="scss">
.custom-dimensions {
  position: relative;
  min-height: 56px;
  .unit {
    display: flex;
    gap: 4px;
    font-size: 10px;
    text-transform: uppercase;
    position: absolute;
    top: -28px;
    right: 0px;
    span {
      cursor: pointer;
    }
    .active {
      color: var(--primaryColor);
      text-decoration: underline;
    }
  }
  .item {
    position: relative;
    border: 1px solid #717171;
    height: 30px;
    flex: 1;
    border-right: none;
    &:nth-last-child(2) {
      border-right: 1px solid #717171;
      border-radius: 0px 4px 4px 0px;
    }
    &:first-child {
      border-radius: 4px 0px 0px 4px;
    }
    .el-input__inner {
      border: none !important;
      height: 28px;
      line-height: 28px;
      text-align: center;
      font-size: 13px;
      padding: 0px;
      background-color: #222222;
      color: #fff;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    .title {
      position: absolute;
      top: 32px;
      left: 50%;
      transform: translateX(-50%);
      font-size: 9px;
      color: #aaaaaa;
      text-transform: uppercase;
      text-align: center;
    }
  }
  .item.active {
    background-color: #666666;
    svg {
      stroke: #fff;
    }
  }
}
</style>