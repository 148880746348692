<template>
  <div label-position="top" size="" :model="elementProps">
    <div class="py-2">
      <span class="setting-title">Color</span>
      <ColorPicker 
        :primaryColor="primaryColor"
        class="mt-2"
        :value="elementProps.overlay_color"
        v-bind:changeColor="($event) => updateProps('overlay_color', $event)"
      />
    </div>
    <div class="py-2">
      <span class="setting-title">Opacity</span>
      <Slider 
        class="mt-2"
        :primaryColor="primaryColor"
        :value="elementProps.overlay_opacity"
        :min="0"
        :max="1"
        :step="0.1"
        v-bind:changeValue="($event) => updateProps('overlay_opacity', $event)"
        unit=""
      />
    </div>
  </div>
</template>

<script>
import mixin from "../styleSettings/mixin";
import Slider from "../common/Slider.vue";
import ColorPicker from "../common/ColorPicker.vue";

export default {
  mixins: [mixin],
  components: {
    Slider, ColorPicker
  },
  props: {
    primaryColor: String,
  },
  methods: {
    updateProps(name, value) {
      if(value ==  this.elementProps[name]) return;
      this.elementPropsSetter({ [name]: value });
    },
  },
};
</script>

<style scoped>
</style>
