<template>
  <div
    data-label="(P) Paypal"
    data-key="(p) paypal"
    data-parent="product"
    class="sg-wrap module"
    :id="`element-${node.uuid}`"
    :data-product-uuid="product.product_node_uuid"
    @mouseover="isHovering = true"
    @mouseout="isHovering = false"
    :data-height="height"
    :data-radius="height/2"
    :data-shape="shape"
    :data-label="label"
    :data-color="color"
  >
    <div
      class="sg-module"
      :class="extra_class"
    >
      <div class="paypal-button"></div>
      <div class="preview-paypal-button">
        <div class="paypal-btn" :class="`color-${color} shape-${shape} label-${label}`">
          <svg xmlns="http://www.w3.org/2000/svg" width="63.13" height="20" viewBox="0 0 101 32" preserveAspectRatio="xMinYMin meet">
            <path fill="#003087" d="M 12.237 2.8 L 4.437 2.8 C 3.937 2.8 3.437 3.2 3.337 3.7 L 0.237 23.7 C 0.137 24.1 0.437 24.4 0.837 24.4 L 4.537 24.4 C 5.037 24.4 5.537 24 5.637 23.5 L 6.437 18.1 C 6.537 17.6 6.937 17.2 7.537 17.2 L 10.037 17.2 C 15.137 17.2 18.137 14.7 18.937 9.8 C 19.237 7.7 18.937 6 17.937 4.8 C 16.837 3.5 14.837 2.8 12.237 2.8 Z M 13.137 10.1 C 12.737 12.9 10.537 12.9 8.537 12.9 L 7.337 12.9 L 8.137 7.7 C 8.137 7.4 8.437 7.2 8.737 7.2 L 9.237 7.2 C 10.637 7.2 11.937 7.2 12.637 8 C 13.137 8.4 13.337 9.1 13.137 10.1 Z"/>
            <path fill="#003087" d="M 35.437 10 L 31.737 10 C 31.437 10 31.137 10.2 31.137 10.5 L 30.937 11.5 L 30.637 11.1 C 29.837 9.9 28.037 9.5 26.237 9.5 C 22.137 9.5 18.637 12.6 17.937 17 C 17.537 19.2 18.037 21.3 19.337 22.7 C 20.437 24 22.137 24.6 24.037 24.6 C 27.337 24.6 29.237 22.5 29.237 22.5 L 29.037 23.5 C 28.937 23.9 29.237 24.3 29.637 24.3 L 33.037 24.3 C 33.537 24.3 34.037 23.9 34.137 23.4 L 36.137 10.6 C 36.237 10.4 35.837 10 35.437 10 Z M 30.337 17.2 C 29.937 19.3 28.337 20.8 26.137 20.8 C 25.037 20.8 24.237 20.5 23.637 19.8 C 23.037 19.1 22.837 18.2 23.037 17.2 C 23.337 15.1 25.137 13.6 27.237 13.6 C 28.337 13.6 29.137 14 29.737 14.6 C 30.237 15.3 30.437 16.2 30.337 17.2 Z"/>
            <path fill="#003087" d="M 55.337 10 L 51.637 10 C 51.237 10 50.937 10.2 50.737 10.5 L 45.537 18.1 L 43.337 10.8 C 43.237 10.3 42.737 10 42.337 10 L 38.637 10 C 38.237 10 37.837 10.4 38.037 10.9 L 42.137 23 L 38.237 28.4 C 37.937 28.8 38.237 29.4 38.737 29.4 L 42.437 29.4 C 42.837 29.4 43.137 29.2 43.337 28.9 L 55.837 10.9 C 56.137 10.6 55.837 10 55.337 10 Z" />
            <path fill="#009cde" d="M 67.737 2.8 L 59.937 2.8 C 59.437 2.8 58.937 3.2 58.837 3.7 L 55.737 23.6 C 55.637 24 55.937 24.3 56.337 24.3 L 60.337 24.3 C 60.737 24.3 61.037 24 61.037 23.7 L 61.937 18 C 62.037 17.5 62.437 17.1 63.037 17.1 L 65.537 17.1 C 70.637 17.1 73.637 14.6 74.437 9.7 C 74.737 7.6 74.437 5.9 73.437 4.7 C 72.237 3.5 70.337 2.8 67.737 2.8 Z M 68.637 10.1 C 68.237 12.9 66.037 12.9 64.037 12.9 L 62.837 12.9 L 63.637 7.7 C 63.637 7.4 63.937 7.2 64.237 7.2 L 64.737 7.2 C 66.137 7.2 67.437 7.2 68.137 8 C 68.637 8.4 68.737 9.1 68.637 10.1 Z"/>
            <path fill="#009cde" d="M 90.937 10 L 87.237 10 C 86.937 10 86.637 10.2 86.637 10.5 L 86.437 11.5 L 86.137 11.1 C 85.337 9.9 83.537 9.5 81.737 9.5 C 77.637 9.5 74.137 12.6 73.437 17 C 73.037 19.2 73.537 21.3 74.837 22.7 C 75.937 24 77.637 24.6 79.537 24.6 C 82.837 24.6 84.737 22.5 84.737 22.5 L 84.537 23.5 C 84.437 23.9 84.737 24.3 85.137 24.3 L 88.537 24.3 C 89.037 24.3 89.537 23.9 89.637 23.4 L 91.637 10.6 C 91.637 10.4 91.337 10 90.937 10 Z M 85.737 17.2 C 85.337 19.3 83.737 20.8 81.537 20.8 C 80.437 20.8 79.637 20.5 79.037 19.8 C 78.437 19.1 78.237 18.2 78.437 17.2 C 78.737 15.1 80.537 13.6 82.637 13.6 C 83.737 13.6 84.537 14 85.137 14.6 C 85.737 15.3 85.937 16.2 85.737 17.2 Z"/>
            <path fill="#009cde" d="M 95.337 3.3 L 92.137 23.6 C 92.037 24 92.337 24.3 92.737 24.3 L 95.937 24.3 C 96.437 24.3 96.937 23.9 97.037 23.4 L 100.237 3.5 C 100.337 3.1 100.037 2.8 99.637 2.8 L 96.037 2.8 C 95.637 2.8 95.437 3 95.337 3.3 Z"/>
          </svg>
          <div class="paypal-label2" v-if="label != 'paypal'">
            {{ getLabel(label) }}
          </div>
        </div>
        <div class="card-btn mt-2" :class="`shape-${shape}`">
          <img src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjRweCIgaGVpZ2h0PSIxOHB4IiB2aWV3Qm94PSIwIDAgMjQgMTgiIHhtbG5zPSJodHRwOiYjeDJGOyYjeDJGO3d3dy53My5vcmcmI3gyRjsyMDAwJiN4MkY7c3ZnIj48ZyBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMy4wMDAwMDAsIC02LjAwMDAwMCkiIGZpbGw9IiNmZmZmZmYiIGZpbGwtcnVsZT0ibm9uemVybyI+PHBhdGggZD0iTTguMjc1MjEzMzgsMTIuNTEyMjY1MyBDNy45MzAwMzU0MiwxMi41MTIyNjUzIDcuNjUwMjEzMzgsMTIuMjMyNDQzMiA3LjY1MDIxMzM4LDExLjg4NzI2NTMgQzcuNjUwMjEzMzgsMTEuNTQyMDg3MyA3LjkzMDAzNTQyLDExLjI2MjI2NTMgOC4yNzUyMTMzOCwxMS4yNjIyNjUzIEwyNC43ODc5MDQyLDExLjI2MjI2NTMgQzI1LjU5NTU5MzksMTEuMjYyMjY1MyAyNi4yNSwxMS45MTc1OTA1IDI2LjI1LDEyLjcyNTUzNjggTDI2LjI1LDIyLjI4NjcyODQgQzI2LjI1LDIzLjA5NDY3NDggMjUuNTk1NTkzOSwyMy43NSAyNC43ODc5MDQyLDIzLjc1IEw1LjIxMjMxMzAyLDIzLjc1IEM0LjQwNDYyMzI1LDIzLjc1IDMuNzUsMjMuMDk0Njc0OCAzLjc1LDIyLjI4NjczOTcgTDMuNzUsNy43MTMyNzE1MiBDMy43NSw2LjkwNTMyNTE4IDQuNDA0NDA2MDgsNi4yNSA1LjIxMjI3MjEyLDYuMjUgTDI0Ljc4ODA2NjQsNi4yNTU1MjE2MyBDMjUuNTk1NjA3OSw2LjI1NTczMTQ3IDI2LjI1LDYuOTEwOTk1MDcgMjYuMjUsNy43MTg3MDM2MiBMMjYuMjUsOS4yMzU3NzE2MSBDMjYuMjUsOS41ODA5NDk1OCAyNS45NzAyNjc1LDkuODYwODExNjggMjUuNjI1MDg5NSw5Ljg2MDg2MTEyIEMyNS4yNzk5MTE1LDkuODYwOTEwNTUgMjUuMDAwMDQ5NCw5LjU4MTEyODYgMjUsOS4yMzU5NTA2MyBMMjQuOTk5NzgyNyw3LjcxODc5MzEzIEMyNC45OTk3ODI3LDcuNjAwODMxODkgMjQuOTA0NjYxMSw3LjUwNTU1MTk3IDI0Ljc4NzcyNzgsNy41MDU1MjE1OCBMNS4yMTIwOTU4Myw3LjQ5OTk5OTk4IEM1LjA5NTE1NTA2LDcuNDk5OTk5OTggNSw3LjU5NTI4ODY4IDUsNy43MTMyNjAyOCBMNS4wMDAyMTcxOCwyMi4yODY3Mjg0IEM1LjAwMDIxNzE4LDIyLjQwNDcxMTMgNS4wOTUzNzIyMywyMi41IDUuMjEyMzEzMDIsMjIuNSBMMjQuNzg3OTA0MiwyMi41IEMyNC45MDQ4NDUsMjIuNSAyNSwyMi40MDQ3MTEzIDI1LDIyLjI4NjcyODQgTDI1LDEyLjcyNTUzNjggQzI1LDEyLjYwNzU1NCAyNC45MDQ4NDQ5LDEyLjUxMjI2NTMgMjQuNzg3OTA0MiwxMi41MTIyNjUzIEw4LjI3NTIxMzM4LDEyLjUxMjI2NTMgWiIgaWQ9IlN0cm9rZS0xIj48L3BhdGg+PC9nPjwvZz48L3N2Zz4" alt="" class="paypal-logo-card paypal-logo-card-">
          <div class="card-label">
            Debit or Credit Card
          </div>
        </div>
      </div>
    </div>

    <ToolBar
      ref="(p)paypal"
      :toolbar_id="`(p)paypal`"
      class="container-toolbar"
      :class="{ 'container-toolbar-show': isHovering, 'is-dragging': dragging == node.uuid }"
    >
    </ToolBar>
    <div v-if="dragging == node.uuid" class="dragging"></div>
    <v-style> 
      {{ custom_css }}

      #element-{{ node.uuid }} .paypal-btn {
        height: {{ height }}px;
      }

      #element-{{ node.uuid }} .paypal-btn.shape-pill {
        border-radius: {{ height / 2 }}px;
      }

      #element-{{ node.uuid }} .card-btn {
        height: {{ height }}px;
      }

      #element-{{ node.uuid }} .card-btn.shape-pill {
        border-radius: {{ height / 2 }}px;
      }
      
      @media (max-width: 767px){ 
        #element-{{ node.uuid }} {
          {{ getDisplayValue('mobile') }}
          {{ getMarginValue('mobile') }}
          {{ getPaddingValue('mobile') }}
          {{ getBackgroundValue('mobile') }}
          {{ getBorderValue('mobile') }}
          {{ getBorderRadiusValue('mobile') }}
          {{ getOpacityValue('mobile') }}
          {{ getShadowValue('mobile') }}
          {{ getOtherStyle('mobile') }}
        }
      } 
      @media (max-width: 991px) and (min-width: 768px){ 
        #element-{{ node.uuid }} {
          {{ getDisplayValue('tablet') }}
          {{ getMarginValue('tablet') }}
          {{ getPaddingValue('tablet') }}
          {{ getBackgroundValue('tablet') }}
          {{ getBorderValue('tablet') }}
          {{ getBorderRadiusValue('tablet') }}
          {{ getOpacityValue('tablet') }}
          {{ getShadowValue('tablet') }}
          {{ getOtherStyle('tablet') }}
        }
      } 
      @media (max-width: 1199px) and (min-width: 992px){ 
        #element-{{ node.uuid }} {
          {{ getDisplayValue('laptop') }}
          {{ getMarginValue('laptop') }}
          {{ getPaddingValue('laptop') }}
          {{ getBackgroundValue('laptop') }}
          {{ getBorderValue('laptop') }}
          {{ getBorderRadiusValue('laptop') }}
          {{ getOpacityValue('laptop') }}
          {{ getShadowValue('laptop') }}
          {{ getOtherStyle('laptop') }}
        }
      } 
      @media (min-width: 1200px){ 
        #element-{{ node.uuid }} {
          {{ getDisplayValue('desktop') }}
          {{ getMarginValue('desktop') }}
          {{ getPaddingValue('desktop') }}
          {{ getBackgroundValue('desktop') }}
          {{ getBorderValue('desktop') }}
          {{ getBorderRadiusValue('desktop') }}
          {{ getOpacityValue('desktop') }}
          {{ getShadowValue('desktop') }}
          {{ getOtherStyle('desktop') }}
        } 
      }
    </v-style>
    
  </div>
</template>

<script>
import ToolBar from "../../elements/utils/ToolBar.vue";

import elementStyleMixin from "../../elements/elementStyleMixin";
import ExtraClass from "../../elements/otherSettings/ExtraClass.vue"; 
import CustomCSS from '../../elements/styleSettings/CustomCSS.vue';
import Style from './settings/Style.vue';
import Visibility from "../../elements/designSettings/Visibility.vue";
import Spacing from "../../elements/designSettings/Spacing.vue";
import Background from "../../elements/designSettings/Background.vue";
import Border from "../../elements/designSettings/Border.vue";
import Corner from "../../elements/designSettings/Corner.vue";
import Shadow from "../../elements/designSettings/Shadow.vue";
import Opacity from "../../elements/designSettings/Opacity.vue";
import { designStyles, otherProps, otherStyles, actionTypes } from "@/constants";
import Animations from '../../elements/designSettings/Animations.vue';

export default {
  components: { ToolBar },
  watch: {
    dragging: function (newValue, oldValue) {
      var $iframe = $("#designEditor").contents();
      var $element = $iframe.find(`#element-${this.node.uuid}`);
      var $content = $element.find(`.sg-module`);
      if (newValue && newValue == this.node.uuid) {
        setTimeout(() => {
        $content.hide();
        },100);
      }else if (oldValue == this.node.uuid){
        $content.show();
      }
    },
  },
  data() {
    return {
      isHovering: false,
      apiUrl: process.env.VUE_APP_API_URL,
      storeUrl: process.env.VUE_APP_STORE_URL,
    };
  },
  inject: ["node","product",'editor'],
  mixins: [elementStyleMixin],
  props: {
    extra_class: String, 
    ...otherProps,
    component: String,
    parent: String,
    desktop: Object,
    laptop: Object,
    mobile: Object,
    tablet: Object,
    shape: String,
    label: String,
    color: String,
    height: Number,
    layout: String,
  },
  methods: {
    getLabel(label) {
      if (label == 'checkout') {
        return 'Checkout'
      } else if (label == 'buynow') {
        return 'Buy Now'
      }
      return 'Pay with'
    }
  },
  computed: {
    dragging: function () {
      return this.editor?.draggedNode?.uuid
    },
  },
  craft: {
    defaultProps: { 
      ...otherStyles,
      parent: "Product",
      extra_class: "",
      shape: 'rect',
      height: 42,
      label: 'paypal',
      color: 'gold',
      layout: 'horizontal',
      desktop: {
        ...designStyles,
        padding_top: 30,
        padding_bottom: 30,
        padding_left: 10,
        padding_right: 10
      },
      laptop: {
        ...designStyles,
      },
      tablet: {
        ...designStyles,
      },
      mobile: {
        ...designStyles,
      },
      elementStyle: {
        
      },
    },
    settings: {
      settings: {
        Style,
        "Extra Class": ExtraClass, 
        "Custom CSS": CustomCSS,
      },
      design: {
        Visibility,
        Spacing: { Spacing, multi_screen: true },
        Background: { Background, multi_screen: true },
        Border: { Border, multi_screen: true },
        Corner: { Corner, multi_screen: true },
        Shadow: { Shadow, multi_screen: true },
        Opacity: { Opacity, multi_screen: true },
      },
      others: {
        Animations: { Animations, actions: actionTypes }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>

<!-- <v-script src="https://www.paypal.com/sdk/js?client-id=[paypal_client_id]&components=buttons&enable-funding=venmo,paylater&currency=[store_currency]"></v-script> -->


<!-- <v-script>
  $(document).ready(function() {
    if ($('.live-production #element-{{ node.uuid }} .paypal-button')) {
      const product_uuid = $('#element-{{ node.uuid }}').data('product-uuid');
      const storeUrl = "{{ storeUrl }}";
      const FUNDING_SOURCES = [
        paypal.FUNDING.PAYPAL,
        paypal.FUNDING.CARD
      ];
      FUNDING_SOURCES.forEach(fundingSource => {
        paypal.Buttons({
          fundingSource,
          createOrder: (data, actions) => {
            $('#loading-wrapper').addClass('show');
            return fetch(`${storeUrl}/api/v1/checkout/payment`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                "x-shop-domain": window.location.host
              },
              body: JSON.stringify({
                product_item_id: $(`#element-${product_uuid} input[name='product_item_id']`).val(),
                quantity: $(`#element-${product_uuid} input[name='quantity']`).val()
              }),
            })
            .then((response) => response.json()).then(res => {
              $('#loading-wrapper').removeClass('show');
              // gtag_add_to_cart
              var token;
              for (link of res.data.links) {
                if (link.rel === 'approval_url') {
                  token = link.href.match(/EC-\w+/)[0];
                }
              }
              return token;
            });
          },
          onApprove: (data, actions) => {
            $('#loading-wrapper').addClass('show');
            const x = $('#toast-msg');
            return fetch(`${storeUrl}/api/v1/checkout/capture`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                "x-shop-domain": window.location.host
              },
              body: JSON.stringify({
                paymentId: data.paymentID,
                PayerID: data.payerID,
                token: data.orderID,
              }),
            }).then((response) => response.json()).then(res => {
              if (res.success) {
                $('#loading-wrapper').removeClass('show');
                $('.modal-paypal').addClass('show');
                $('.modal-paypal .modal-title').text('YOUR ORDER IS CONFIRMED');
                $('.modal-paypal .modal-body').html(`<p><b>Thank you ${res.data.customer_name}!</b></p><p>Wish you have a nice day.</p><p>We're getting your order ready to be shipped asap. This is the Confirmation Checkout confirming your Order ${res.data.order_code} and purchase details.</p><p>If you have a question about switching items, check our FAQs and/or Terms of Service before contacting us in regards to general questions. </p>`);
                var $div = $('<div />').appendTo('body');
                $div.attr('class', 'modal-backdrop fade show');
                // gtag_purchase
              } else {
                $('#loading-wrapper').removeClass('show');
                x.addClass("show msg-error");
                x.text(res.message);
                x.css('background-color', '#DD3333');
                setTimeout(function(){ 
                  x.removeClass("show msg-error");
                  x.text("");
                }, 4000);
              }
            });
          },
          style: {
            layout: 'horizontal',
            shape: '{{ shape }}',
            tagline: false,
            size: 'responsive',
            label: '{{ label }}',
            color: (fundingSource == paypal.FUNDING.CARD) ? 'black' : '{{ color }}',
            height: {{ height }}
          }
        }).render('.live-production #element-{{ node.uuid }} .paypal-button');
      })
    }
  })
</v-script> -->