var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"label-position":"top","size":"mini","model":_vm.elementProps}},[_c('div',{staticClass:"d-flex flex-column py-2"},[_c('span',{staticStyle:{"font-size":"12px","color":"#ccc"}},[_vm._v("Layout")]),_c('Select',{staticClass:"mt-2",attrs:{"primaryColor":_vm.primaryColor,"value":_vm.elementProps.layout,"changeValue":($event) => _vm.updateProps('layout', $event),"options":[
        {
          value: 'simple',
          label: 'Simple',
        },
        {
          value: 'button',
          label: 'Button',
        },
      ]}})],1),(_vm.elementProps.layout=='button')?_c('div',{staticClass:"d-flex flex-column py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Button Shape")]),_c('ToggleButton',{staticClass:"mt-2",attrs:{"value":_vm.elementProps.button_shape,"changeValue":($event) => _vm.updateProps('button_shape', $event),"options":[
        {
          label: 'Square',
          value: 'square',
        },
        {
          label: 'Circle',
          value: 'circle',
        },
      ]}})],1):_vm._e(),(_vm.elementProps.layout=='button')?_c('div',{staticClass:"py-2"},[_c('span',[_vm._v("Button Size")]),_c('Slider',{attrs:{"primaryColor":_vm.primaryColor,"value":_vm.elementProps.button_size,"min":30,"max":50,"changeValue":($event) => _vm.updateProps('button_size', parseInt($event || 0))}})],1):_vm._e(),_c('div',{staticClass:"py-2"},[_c('span',[_vm._v("Spacing")]),_c('Slider',{attrs:{"primaryColor":_vm.primaryColor,"value":_vm.elementProps[_vm.screen].spacing,"min":0,"max":50,"changeValue":($event) => _vm.updateScreenProps('spacing', parseInt($event || 0))}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }