<template>
  <div label-position="top" size="mini" :model="elementProps">
    <div class="py-2">
      <span class="setting-title">Location</span>
      <ToggleButton
        :value="elementProps.location_type"
        v-bind:changeValue="($event) => updateProps('location_type', $event)"
        :options="[
          { value: 'address', label: 'Address' },
          { value: 'coordinate', label: 'Coordinate' },
        ]"
        :primaryColor="primaryColor"
      />
    </div>
    <div class="py-2" v-if="elementProps.location_type == 'address'">
      <span class="setting-title">Address</span>
      <Input 
        class="mt-2"
        :value="elementProps.address"
        v-bind:changeValue="($event) => updateProps('address', $event)"
        type="textarea"
        :rows="2"
        :primaryColor="primaryColor"
      />
    </div>
    <div class="py-2" v-if="elementProps.location_type == 'coordinate'">
      <span class="setting-title">Latitude</span>
      <Input 
        class="mt-2"
        :value="elementProps.latitude"
        v-bind:changeValue="($event) => updateProps('latitude', $event)"
        type="number"
        :primaryColor="primaryColor"
      />
    </div>
    <div class="py-2" v-if="elementProps.location_type == 'coordinate'">
      <span class="setting-title">Longitude</span>
      <Input 
        class="mt-2"
        :value="elementProps.longitude"
        v-bind:changeValue="($event) => updateProps('longitude', $event)"
        type="number"
        :primaryColor="primaryColor"
      />
    </div>
  </div>
</template>

<script>

import mixin from '../../../elements/styleSettings/mixin';
import ToggleButton from '../../../elements/common/ToggleButton.vue';
import Input from '../../../elements/common/Input.vue';

export default {
  mixins: [mixin],
  props: {
    screen: String,
    primaryColor: String,
  },
  components: {
    ToggleButton,
    Input,
  },
  methods: {
    updateProps(name, value) {
      this.elementPropsSetter({ [name]: value });
    },
  },
};
</script>

<style>
</style>
