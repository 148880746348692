<template>
  <div label-position="top" size="small" :model="elementProps">
    <el-form-item label="Width">
      <el-input
        :value="getStyle('width')"
        class="input-with-select"
        @input="($event) => updateWidth($event)"
      >
        <el-select
          slot="append"
          placeholder="-"
          :value="elementProps.typeWidth"
          @change="updateProps('typeWidth', $event)"
          size="medium"
        >
          <el-option label="px" value="px"></el-option>
          <el-option label="%" value="%"></el-option>
        </el-select>
      </el-input>
    </el-form-item>
    <el-form-item label="Alignment">
      <ToggleButton
        :value="getStyle('alignment')"
        v-bind:changeValue="($event) => setStyle('alignment', $event)"
        :primaryColor="primaryColor"
        :options="[
          {
            label: 'Left',
            value: 'left',
          },
          {
            label: 'Center',
            value: 'center',
          },
          {
            label: 'Right',
            value: 'right',
          },
        ]"
      />
    </el-form-item>
  </div>
</template>

<script>
import mixin from "../../../elements/styleSettings/mixin";
import ToggleButton from "../../../elements/common/ToggleButton.vue";

export default {
  mixins: [mixin],
  components: { ToggleButton },
  props: {
    primaryColor: String
  },
  methods: {
    updateProps(name, value) {
      if(value ==  this.elementProps[name]) return;

      this.elementPropsSetter({ [name]: value });
    },
    updateWidth(event) {
      var value = parseInt(event);
      this.setStyle("width", event);
      if (event == "auto") {
        this.updateProps("typeWidth", null);
      } else if (!isNaN(value)) {
        if (!this.elementProps.typeWidth) {
          this.updateProps("typeWidth", "px");
        }
      }
    },
  },
};
</script>

<style scoped>
</style>
