import { render, staticRenderFns } from "./BlogContent.vue?vue&type=template&id=0cd0a980&scoped=true&"
import script from "./BlogContent.vue?vue&type=script&lang=js&"
export * from "./BlogContent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0cd0a980",
  null
  
)

export default component.exports