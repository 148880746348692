var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-form',{attrs:{"label-position":"top","size":"mini","model":_vm.elementProps}},[_c('el-form-item',{attrs:{"label":"Title Tag"}},[_c('ToggleButton',{attrs:{"value":_vm.elementProps.headingType,"changeValue":_vm.updateHeadingType,"options":[
        {
          label: 'H1',
          value: 'h1',
        },
        {
          label: 'H2',
          value: 'h2',
        },
        {
          label: 'H3',
          value: 'h3',
        },
      ]}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }