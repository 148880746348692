<template>
  <div
    data-label="Counter"
    data-key="counter"
    class="sg-wrap module"
    :id="`element-${node.uuid}`"
    @mouseover="isHovering = true"
    @mouseout="isHovering = false"
    :class="{ 'dragging': dragging == node.uuid }"
  >
    <div
      class="sg-module"
      :class="extra_class"
    >
      <span>{{ prefix || "" }}</span>
      <span class="counter" :data-target="number"></span>
      <span>{{ suffix || "" }}</span>
    </div>

    <ToolBar
      ref="counter"
      :toolbar_id="`counter`"
      class="container-toolbar"
      :class="{ 'container-toolbar-show': isHovering, 'is-dragging': dragging == node.uuid }"
    >
    </ToolBar>

    <v-style>
      {{ custom_css }}
      
      @media (max-width: 767px) {

        #element-{{ node.uuid }} .sg-module {
          font-size: {{ getValue('font_size','mobile') }}px;
          line-height: {{ getValue('line_height','mobile') }}em;
          letter-spacing: {{ getValue('letter_spacing','mobile') }}px;
          font-family: {{ getValue('font_family','mobile') }};
          text-align: {{ getValue('alignment', 'mobile') }};
        }

        #element-{{ node.uuid }} {
          {{ getDisplayValue('mobile') }}
          {{ getMarginValue('mobile') }}
          {{ getPaddingValue('mobile') }}
          {{ getBackgroundValue('mobile') }}
          {{ getBorderValue('mobile') }}
          {{ getBorderRadiusValue('mobile') }}
          {{ getOpacityValue('mobile') }}
          {{ getShadowValue('mobile') }}
          {{ getOtherStyle('mobile') }}
        }
      } 
      @media (max-width: 991px) and (min-width: 768px) {

        #element-{{ node.uuid }} .sg-module {
          font-size: {{ getValue('font_size','tablet') }}px;
          line-height: {{ getValue('line_height','tablet') }}em;
          letter-spacing: {{ getValue('letter_spacing','tablet') }}px;
          font-family: {{ getValue('font_family','tablet') }};
          text-align: {{ getValue('alignment', 'tablet') }};
        }

        #element-{{ node.uuid }} {
          {{ getDisplayValue('tablet') }}
          {{ getMarginValue('tablet') }}
          {{ getPaddingValue('tablet') }}
          {{ getBackgroundValue('tablet') }}
          {{ getBorderValue('tablet') }}
          {{ getBorderRadiusValue('tablet') }}
          {{ getOpacityValue('tablet') }}
          {{ getShadowValue('tablet') }}
          {{ getOtherStyle('tablet') }}
        }
      } 
      @media (max-width: 1199px) and (min-width: 992px) {

        #element-{{ node.uuid }} .sg-module {
          font-size: {{ getValue('font_size','laptop') }}px;
          line-height: {{ getValue('line_height','laptop') }}em;
          letter-spacing: {{ getValue('letter_spacing','laptop') }}px;
          font-family: {{ getValue('font_family','laptop') }};
          text-align: {{ getValue('alignment', 'laptop') }};
        }

        #element-{{ node.uuid }} {
          {{ getDisplayValue('laptop') }}
          {{ getMarginValue('laptop') }}
          {{ getPaddingValue('laptop') }}
          {{ getBackgroundValue('laptop') }}
          {{ getBorderValue('laptop') }}
          {{ getBorderRadiusValue('laptop') }}
          {{ getOpacityValue('laptop') }}
          {{ getShadowValue('laptop') }}
          {{ getOtherStyle('laptop') }}
        }
      } 
      @media (min-width: 1200px) { 

        #element-{{ node.uuid }} .sg-module {
          font-size: {{ getValue('font_size','desktop') }}px;
          line-height: {{ getValue('line_height','desktop') }}em;
          letter-spacing: {{ getValue('letter_spacing','desktop') }}px;
          font-family: {{ getValue('font_family','desktop') }};
          text-align: {{ getValue('alignment', 'desktop') }};
        }

        #element-{{ node.uuid }} {
          {{ getDisplayValue('desktop') }}
          {{ getMarginValue('desktop') }}
          {{ getPaddingValue('desktop') }}
          {{ getBackgroundValue('desktop') }}
          {{ getBorderValue('desktop') }}
          {{ getBorderRadiusValue('desktop') }}
          {{ getOpacityValue('desktop') }}
          {{ getShadowValue('desktop') }}
          {{ getOtherStyle('desktop') }}
        }
      }
    </v-style>
  </div>
</template>

<script>
import ToolBar from "../../elements/utils/ToolBar.vue";
import elementStyleMixin from "../../elements/elementStyleMixin";
import ExtraClass from "../../elements/otherSettings/ExtraClass.vue";

import Number from "./settings/Number.vue";
import Typography from "./settings/Typography.vue";

import Visibility from "../../elements/designSettings/Visibility.vue";
import Spacing from "../../elements/designSettings/Spacing.vue";
import Background from "../../elements/designSettings/Background.vue";
import Border from "../../elements/designSettings/Border.vue";
import Corner from "../../elements/designSettings/Corner.vue";
import Shadow from "../../elements/designSettings/Shadow.vue";
import Opacity from "../../elements/designSettings/Opacity.vue";

import { designStyles, otherProps, otherStyles, actionTypes } from '@/constants';
import AlignmentBase from '../../elements/styleSettings/AlignmentBase.vue';
import moment from "moment";
import CustomCSS from '../../elements/styleSettings/CustomCSS.vue';
import Animations from '../../elements/designSettings/Animations.vue';

export default {
  components: { ToolBar },
  computed: {
    dragging: function () {
      return this.editor?.draggedNode?.uuid
    },
  },
  data() {
    return {
      isHovering: false,
    };
  },
  mixins: [elementStyleMixin],
  props: {
    ...otherProps,
    extra_class: String,
    component: String,
    number: String,
    prefix: String,
    suffix: String,
    desktop: Object,
    laptop: Object,
    tablet: Object,
    mobile: Object,
  },
  inject: ["node","editor"],
  mounted() {
    var $iframe = $("#designEditor").contents();
    $iframe.find(`#element-${this.node.uuid} .counter`).each(function(index, counter) {
      const value = $(counter).data('target');
      $(counter).text("0");
      const updateCounter = () => {
        const target = +value;
        const count = +$(counter).text();
        const increment = target / 200;
        if (count < target) {
          $(counter).text(`${Math.ceil(count + increment)}`);
          setTimeout(updateCounter, 1);
        } else $(counter).text(target);
      };
      updateCounter();
    })
  },
  watch:{
    number: function (newValue, oldValue) {
      const value = parseFloat(newValue);
      var $iframe = $("#designEditor").contents();
      $iframe.find(`#element-${this.node.uuid} .counter`).each(function(index, counter) {
        $(counter).text("0");
        const updateCounter = () => {
          const target = +value;
          const count = +$(counter).text();
          const increment = target / 200;
          if (count < target) {
            $(counter).text(`${Math.ceil(count + increment)}`);
            setTimeout(updateCounter, 1);
          } else $(counter).text(target);
        };
        updateCounter();
      })
    },
  },
  craft: {
    defaultProps: {
      ...otherStyles,
      extra_class: "",
      number: "10000",
      prefix: "",
      suffix: "",
      desktop: {
        ...designStyles,
        color: 'rgb(45, 49, 63)',
        font_family: 'inherit',
        font_size: 50,
        line_height: 1.8,
        letter_spacing: 1,
        alignment: 'center',
      },
      laptop: {
        ...designStyles,
      },
      tablet: {
        ...designStyles,
      },
      mobile: {
        ...designStyles,
      },
    },
    settings: {
      settings: {
        Number,
        Typography: {Typography, multi_screen: true},
        "Alignment": {"Alignment": AlignmentBase, multi_screen: true},
        "Extra Class": ExtraClass, "Custom CSS": CustomCSS,
      },
      design: {
        Visibility,
        Spacing: { Spacing, multi_screen: true },
        Background: { Background, multi_screen: true },
        Border: { Border, multi_screen: true },
        Corner: { Corner, multi_screen: true },
        Shadow: { Shadow, multi_screen: true },
        Opacity: { Opacity, multi_screen: true },
      },
      others: {
        Animations: { Animations, actions: actionTypes }
      }
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
