<template>
  <div label-position="top" size="medium" :model="elementProps">
    <div class="d-flex align-items-center justify-content-between py-2">
      <span class="setting-title">Display Swatch Text</span>
      <el-switch
        :value="getValue('show_swatch_text',elementProps[screen].show_swatch_text)"
        :active-color="primaryColor"
        inactive-color="#949494"
        @change="updateProps('show_swatch_text', $event)"
      />
    </div>
    <div class="d-flex flex-column py-2" v-if="getValue('show_swatch_text',elementProps[screen].show_swatch_text)">
      <span class="setting-title">Font Weight</span>
      <Select
        class="mt-2"
        :primaryColor="primaryColor"
        :value="getValue('swatch_font_weight',elementProps[screen].swatch_font_weight)"
        v-bind:changeValue="($event) => updateProps('swatch_font_weight', $event)"
        :options="[
          {
            value: '100',
            label: 'Thin 100',
          },
          {
            value: '200',
            label: 'Semi Thin 200',
          },
          {
            value: '300',
            label: 'Light 300',
          },
          {
            value: '400',
            label: 'Regular 400',
          },
          {
            value: '500',
            label: 'Medium 500',
          },
          {
            value: '600',
            label: 'Semi Bold 600',
          },
          {
            value: '700',
            label: 'Bold 700',
          },
          {
            value: '800',
            label: 'Super Bold 800',
          },
          {
            value: '900',
            label: 'Boldest 900',
          },
          {
            value: 'lighter',
            label: 'Lighter',
          },
          {
            value: 'bolder',
            label: 'Bolder',
          },
        ]"
      />
    </div>
    <div class="d-flex flex-column py-2" v-if="getValue('show_swatch_text',elementProps[screen].show_swatch_text)">
      <span class="setting-title">Font Size</span>
      <Slider 
        class="mt-2"
        :primaryColor="primaryColor"
        :value="Number.parseInt(getValue('swatch_font_size',elementProps[screen].swatch_font_size))"
        :min="0"
        :max="100"
        :step="1"
        v-bind:changeValue="($event) => updateProps('swatch_font_size', $event)"
        unit="px"
        :onlyInteger="true"
      />
    </div>
    <div class="d-flex flex-column py-2" v-if="getValue('show_swatch_text',elementProps[screen].show_swatch_text)">
      <span class="setting-title">Color</span>
      <ColorPicker
        class="mt-2"
        :value="getValue('swatch_color',elementProps[screen].swatch_color)"
        v-bind:changeColor="($event) => updateProps('swatch_color', $event)"
        :primaryColor="primaryColor"
      />
    </div>
    <div class="d-flex flex-column py-2" v-if="getValue('show_swatch_text',elementProps[screen].show_swatch_text)">
      <span class="setting-title">Font</span>
      <Select
        class="mt-2"
        :primaryColor="primaryColor"
        :value="getValue('swatch_font_family',elementProps[screen].swatch_font_family)"
        v-bind:changeValue="($event) => updateProps('swatch_font_family', $event)"
        :hasAction="true"
        v-bind:onAction="handleShowModal"
        actionLabel="Open Font Manager"
        :options="getListFont()"
      />
    </div>
  </div>
</template>

<script>
import mixin from "../../../elements/styleSettings/mixin";
import ColorPicker from "../../../elements/common/ColorPicker.vue";
import Slider from "../../../elements/common/Slider.vue";
import ToggleButton from "../../../elements/common/ToggleButton.vue";
import Select from "../../../elements/common/Select.vue";
import Node from "@/core/Node";
import { makeId } from "@/constants";

export default {
  mixins: [mixin],
  components: {ColorPicker, Slider, ToggleButton, Select},
  props: {
    node: Node,
    screen: String,
    primaryColor: String,
    activeFonts: Array,
  },
  data() {
    return {
      fontKey: makeId(20)
    }
  },
  watch: {
    activeFonts: function (newValue, oldValue) {
      this.fontKey = makeId(20)
    }
  },
  methods: {
    updateProps(name, value) {
      const screen = this.$props.screen
      const data_screen = Object.assign({}, this.elementProps[screen])
      if(value ==  data_screen[name]) return;
      data_screen[name] = value
      if (name == 'width' && (value == 'auto' && isNaN(parseInt(value)))) {
        data_screen['width_type'] = ''
      }
      this.elementPropsSetter({ [screen]: data_screen });
    },
    handleShowModal() {
      this.$bus.emit("showFontManager", this.node);
    },
    getListFont() {
      const listFonts = [
        {
          value: 'inherit',
          label: 'Default',
        }
      ]
      this.node?.editor?.fonts?.map(e => {
        listFonts.push({ value: e, label: e })
      })
      return listFonts;
    },
  },
};
</script>
