var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-form',{attrs:{"label-position":"top","size":"small","model":_vm.elementProps}},[_c('div',{staticClass:"d-flex flex-column py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Variant Type")]),_c('Select',{staticClass:"mt-2",attrs:{"primaryColor":_vm.primaryColor,"value":_vm.elementProps.variant_type,"changeValue":($event) => _vm.updateProps('variant_type', $event),"options":[
        {
          value: 'select',
          label: 'Select',
        },
        {
          value: 'swatch',
          label: 'Swatch',
        }
      ]}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }