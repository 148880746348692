<template>
  <div
    data-label="Youtube"
    data-key="youtube"
    class="sg-wrap module"
    :id="`element-${node.uuid}`"
    @mouseover="isHovering=true"
    @mouseout="isHovering=false"
  >
  {{editor?.dragNode?.uuid}}
    <div :class="`sg-module ${extra_class || ''}`" :data-url="youtube_link">
      <div class="salegate-video">
        <iframe
          :id="`iframe-${node.uuid}`"
          width="560"
          height="315"
          :src="getEmbed"
          frameborder="0"
          :allow="`accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture`"
          allowfullscreen
        >
        </iframe>
      </div>
    </div>

    <ToolBar ref="youtube" 
      :toolbar_id="`youtube`"
     class="container-toolbar" 
     :class="{ 'container-toolbar-show': isHovering, 'is-dragging': dragging == node.uuid }"
     >
     </ToolBar>
     <div class="sg_youtube-overlay text-center" :class="{ 'show': this?.editor?.draggedNode?.uuid &&  this?.editor?.draggedNode?.uuid != node.uuid}">
        <!-- <div class="d-flex align-items-center justify-content-center w-100 h-100 font-weight-bold">
          Video
        </div> -->
    </div>
     <div v-if="dragging == node.uuid" class="dragging"></div>
    <v-style>
      {{ custom_css }}
      
      @media (max-width: 767px) {
        #element-{{ node.uuid }} {
          {{ getDisplayValue('mobile') }}
          {{ getMarginValue('mobile') }}
          {{ getPaddingValue('mobile') }}
          {{ getBackgroundValue('mobile') }}
          {{ getBorderValue('mobile') }}
          {{ getBorderRadiusValue('mobile') }}
          {{ getOpacityValue('mobile') }}
          {{ getShadowValue('mobile') }}
          {{ getOtherStyle('mobile') }}
        }
      }
      @media (max-width: 991px) and (min-width: 768px) {
        #element-{{ node.uuid }} {
          {{ getDisplayValue('tablet') }}
          {{ getMarginValue('tablet') }}
          {{ getPaddingValue('tablet') }}
          {{ getBackgroundValue('tablet') }}
          {{ getBorderValue('tablet') }}
          {{ getBorderRadiusValue('tablet') }}
          {{ getOpacityValue('tablet') }}
          {{ getShadowValue('tablet') }}
          {{ getOtherStyle('tablet') }}
        }
      }
      @media (max-width: 1199px) and (min-width: 992px) {
        #element-{{ node.uuid }} {
          {{ getDisplayValue('laptop') }}
          {{ getMarginValue('laptop') }}
          {{ getPaddingValue('laptop') }}
          {{ getBackgroundValue('laptop') }}
          {{ getBorderValue('laptop') }}
          {{ getBorderRadiusValue('laptop') }}
          {{ getOpacityValue('laptop') }}
          {{ getShadowValue('laptop') }}
          {{ getOtherStyle('laptop') }}
        }
      }
      @media (min-width: 1200px) {
        #element-{{ node.uuid }} {
          {{ getDisplayValue('desktop') }}
          {{ getMarginValue('desktop') }}
          {{ getPaddingValue('desktop') }}
          {{ getBackgroundValue('desktop') }}
          {{ getBorderValue('desktop') }}
          {{ getBorderRadiusValue('desktop') }}
          {{ getOpacityValue('desktop') }}
          {{ getShadowValue('desktop') }}
          {{ getOtherStyle('desktop') }}
        }
      }
    </v-style>
  </div>
</template>

<script>
import ToolBar from '../../elements/utils/ToolBar.vue';

import elementStyleMixin from "../../elements/elementStyleMixin";
import ExtraClass from "../../elements/otherSettings/ExtraClass.vue";
import YoutubeWeblink from "./settings/YoutubeWeblink.vue";
import Options from "./settings/Options.vue";
import ShowYoutubeControl from "./settings/ShowYoutubeControl.vue";
import ShowYoutubeLogo from "./settings/ShowYoutubeLogo.vue";
import StartVideo from "./settings/StartVideo.vue";
import EndVideo from "./settings/EndVideo.vue";

import Visibility from "../../elements/designSettings/Visibility.vue";
import Spacing from "../../elements/designSettings/Spacing.vue";
import Background from "../../elements/designSettings/Background.vue";
import Border from "../../elements/designSettings/Border.vue";
import Corner from '../../elements/designSettings/Corner.vue';
import Shadow from '../../elements/designSettings/Shadow.vue';
import Opacity from '../../elements/designSettings/Opacity.vue';
import { designStyles, otherProps, otherStyles, actionTypes } from '@/constants';
import CustomCSS from '../../elements/styleSettings/CustomCSS.vue';
import Animations from '../../elements/designSettings/Animations.vue';


export default {
  components: {ToolBar},
  watch: {
    dragging: function (newValue, oldValue) {
      var $iframe = $("#designEditor").contents();
      var $element = $iframe.find(`#element-${this.node.uuid}`);
      var $content = $element.find(`.sg-module`);
      if (newValue && newValue == this.node.uuid) {
        setTimeout(() => {
        $content.hide();
        },200);
      }else if (oldValue == this.node.uuid){
        $content.show();
      }
    },
  },
  data() {
    return {
      isHovering: false
    }
  },
  methods:{
  },
  mounted(){
  },
  mixins: [elementStyleMixin],
  props: {
    ...otherProps,
    section: String,
    
    extra_class: String,
    youtube_link: String,
    autoplay:Boolean,
    end_video: String,
    start_video: String,
    loop:Boolean,
    enable_sound:Boolean,
    show_youtube_controls:Boolean,
    show_youtube_logo:Boolean,
    desktop: Object,
    laptop: Object,
    tablet: Object,
    mobile: Object,
  },
  inject: ["node","editor"],
  computed:{
    getEmbed() {
      const regex = /^.*(youtu\.be\/|v\/|u\/\w\/|shorts\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
      var match = this.youtube_link.match(regex);
      const videoId = (match && match[2].length==11) ? match[2] : false;
      // let urlParams = new URL(this.youtube_link).searchParams.get('v')
      // if(this.youtube_link.includes('/shorts/')){
      //   var regex = /\/shorts\/([^/]+)$/;
      //   var match = this.youtube_link.match(regex);
      //   var videoId = match ? match[1] : null;
      //   return `https://www.youtube-nocookie.com/embed/${videoId}?1=1&${this.enable_sound || this.autoplay?"mute=1&":""}${this.autoplay ? "autoplay=1&" : ""}${this.show_youtube_controls?"controls=0&":""}${this.show_youtube_logo?"modestbranding=1&":""}${this.loop?`loop=1&playlist=${videoId}&`:''}${!isNaN(parseInt(this.start_video))?`start=${parseInt(this.start_video)}&`:''}${!isNaN(parseInt(this.end_video))?`end=${parseInt(this.end_video)}&`:''}enablejsapi=1`;
      // }
      return `https://www.youtube-nocookie.com/embed/${videoId}?1=1&${this.enable_sound || this.autoplay?"mute=1&":""}${this.autoplay ? "autoplay=1&" : ""}${this.show_youtube_controls?"controls=0&":""}${this.show_youtube_logo?"modestbranding=1&":""}${this.loop?`loop=1&playlist=${videoId}&`:''}${!isNaN(parseInt(this.start_video))?`start=${parseInt(this.start_video)}&`:''}${!isNaN(parseInt(this.end_video))?`end=${parseInt(this.end_video)}&`:''}enablejsapi=1`
    },
    isDragging(){
      return this?.editor?.draggedNode
    },
    dragging: function () {
      return this.editor?.draggedNode?.uuid
    },
  },
  craft: {
    defaultProps: {
      ...otherStyles,
      section: "module",
      youtube_link: "https://www.youtube.com/watch?v=JIcFP4JcJ3Y",
      extra_class: "",
      autoplay: false,
      show_youtube_controls: true,
      show_youtube_logo: false,
      enable_sound: false,
      loop: false,
      start_video: null,
      end_video: null,
      desktop: {
        ...designStyles
      },
      laptop: {
        ...designStyles
      },
      tablet: {
        ...designStyles
      },
      mobile: {
        ...designStyles
      },
      elementStyle: {
      },
    },
    settings: {
      settings: {
        "Youtube Weblink": YoutubeWeblink,
        "Show Youtube Controls": ShowYoutubeControl,
        "Show Youtube Logo": ShowYoutubeLogo,
        "Start Video at": StartVideo,
        "End Video at": EndVideo,
        Options,
        "Extra Class": ExtraClass, "Custom CSS": CustomCSS,
      },
      design: {
        Visibility,
        Spacing: { Spacing, multi_screen: true },
        Background: { Background, multi_screen: true },
        Border: { Border, multi_screen: true },
        Corner: { Corner, multi_screen: true },
        Shadow: { Shadow, multi_screen: true },
        Opacity: { Opacity, multi_screen: true },
      },
      others: {
        Animations: { Animations, actions: actionTypes }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
