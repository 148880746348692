<template>
    <div class="modal fade modal-paypal" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h3 class="modal-title"></h3>
                    <div class="modal-close-button" style="cursor: pointer;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="#000000" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <line x1="18" y1="6" x2="6" y2="18"></line>
                            <line x1="6" y1="6" x2="18" y2="18"></line>
                        </svg>
                    </div>
                </div>
                <div class="modal-body">
                    <div class="alert alert-success" role="alert">
                        Congrats! You have successfully placed your order!
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "PaypalModal",
    props: {},
    watch: {},
    data () {
        return {};
    },
    methods: {
        
    },
};
</script>
  
  