<template>
  <div
    data-label="(QR) Text"
    data-key="(QR) Text"
    class="sg-wrap module"
    :id="`element-${node.uuid}`"
    @mouseover="isHovering=true" @mouseout="isHovering=false"
    :class="{ 'dragging': dragging == node.uuid }"

  >
    <div
      class="sg-module"
      :class="extra_class"
    >
        <component :is="headingType" class="sg_qr-form-info d-flex" >
          <span class="sg_qr-info-prefix px-1" v-if="show_label">{{label}}</span>
          <span class="sg_qr-info-value flex-fill" :data-content="content" :data-key="keyInfo"
          :name="keyInfo||false"
          >{{content}}{{keyInfo?"&lt;order_id>":""}}</span>
          <i class="fa fa-clone pointer icon-copy" :data-node-uuid="node.uuid" aria-hidden="true" v-if="show_copy">
          </i>
        </component>
        
    </div>

    <ToolBar ref="(QR)text" 
      :toolbar_id="`(qr)text`"
      class="container-toolbar" 
      :class="{ 'container-toolbar-show': isHovering, 'is-dragging': dragging == node.uuid }"
      :isDraggable="false">
      </ToolBar>
      <div :id="`toast-${node.uuid}`" class="sg-snackbar">Some text some message..</div>


    <v-style>
      {{ custom_css }}
      
      @media (max-width: 767px){ 
        #element-{{ node.uuid }} {
          {{ getDisplayValue('mobile') }}
          {{ getMarginValue('mobile') }}
        }
        #element-{{ node.uuid }} .sg_qr-form-info {
          font-weight: {{ getValue('font_weight','mobile') }};
          {{ getValue('font_size', 'mobile') > 0 ? `font-size: ${getValue('font_size','mobile')}px;` : '' }}
          {{ getValue('color', 'mobile') ? `color: ${getValue('color','mobile')};` : '' }}
          line-height: {{ getValue('line_height','mobile') }}em;
          letter-spacing: {{ getValue('letter_spacing','mobile') }}px;
          font-family: {{ getValue('font_family','mobile') }};
          text-transform: {{ getValue('text_transform','mobile') }};
          {{ getPaddingValue('mobile') }}
          {{ getBackgroundValue('mobile') }}
          {{ getBorderValue('mobile') }}
          {{ getBorderRadiusValue('mobile') }}
          {{ getOpacityValue('mobile') }}
          {{ getShadowValue('mobile') }}
          {{ getOtherStyle('mobile') }}
        }
        #element-{{ node.uuid }} .sg-module {
          text-align: {{ getValue('alignment','mobile') }};
        }
      } 
      @media (max-width: 991px) and (min-width: 768px){ 
        #element-{{ node.uuid }} {
          {{ getDisplayValue('tablet') }}
          {{ getMarginValue('tablet') }}
        }
        #element-{{ node.uuid }} .sg_qr-form-info {
          font-weight: {{ getValue('font_weight','tablet') }};
          {{ getValue('font_size', 'tablet') > 0 ? `font-size: ${getValue('font_size','tablet')}px;` : '' }}
          {{ getValue('color', 'tablet') ? `color: ${getValue('color','tablet')};` : '' }}
          line-height: {{ getValue('line_height','tablet') }}em;
          letter-spacing: {{ getValue('letter_spacing','tablet') }}px;
          font-family: {{ getValue('font_family','tablet') }};
          text-transform: {{ getValue('text_transform','tablet') }};
          {{ getPaddingValue('tablet') }}
          {{ getBackgroundValue('tablet') }}
          {{ getBorderValue('tablet') }}
          {{ getBorderRadiusValue('tablet') }}
          {{ getOpacityValue('tablet') }}
          {{ getShadowValue('tablet') }}
          {{ getOtherStyle('tablet') }}
        }
        #element-{{ node.uuid }} .sg-module {
          text-align: {{ getValue('alignment','tablet') }};
        }
      } 
      @media (max-width: 1199px) and (min-width: 992px){ 
        #element-{{ node.uuid }} {
          {{ getDisplayValue('laptop') }}
          {{ getMarginValue('laptop') }}
        }
        #element-{{ node.uuid }} .sg_qr-form-info {
          font-weight: {{ getValue('font_weight','laptop') }};
          {{ getValue('font_size', 'laptop') > 0 ? `font-size: ${getValue('font_size','laptop')}px;` : '' }}
        {{ getValue('color', 'laptop') ? `color: ${getValue('color','laptop')};` : '' }}
          line-height: {{ getValue('line_height','laptop') }}em;
          letter-spacing: {{ getValue('letter_spacing','laptop') }}px;
          font-family: {{ getValue('font_family','laptop') }};
          text-transform: {{ getValue('text_transform','laptop') }};
          {{ getPaddingValue('laptop') }}
          {{ getBackgroundValue('laptop') }}
          {{ getBorderValue('laptop') }}
          {{ getBorderRadiusValue('laptop') }}
          {{ getOpacityValue('laptop') }}
          {{ getShadowValue('laptop') }}
          {{ getOtherStyle('laptop') }}
        }
        #element-{{ node.uuid }} .sg-module {
          text-align: {{ getValue('alignment','laptop') }};
        }
      } 
      @media (min-width: 1200px){ 
        #element-{{ node.uuid }} {
          {{ getDisplayValue('desktop') }}
          {{ getMarginValue('desktop') }}
        }
        #element-{{ node.uuid }} .sg_qr-form-info {
          font-weight: {{ desktop.font_weight }};
          {{ getValue('font_size', 'desktop') > 0 ? `font-size: ${getValue('font_size','desktop')}px;` : '' }}
          {{ getValue('color', 'desktop') ? `color: ${getValue('color','desktop')};` : '' }}
          line-height: {{ desktop.line_height }}em;
          letter-spacing: {{ desktop.letter_spacing }}px;
          font-family: {{ desktop.font_family }};
          text-transform: {{ desktop.text_transform }};
          {{ getPaddingValue('desktop') }}
          {{ getBackgroundValue('desktop') }}
          {{ getBorderValue('desktop') }}
          {{ getBorderRadiusValue('desktop') }}
          {{ getOpacityValue('desktop') }}
          {{ getShadowValue('desktop') }}
          {{ getOtherStyle('desktop') }}
        }
        #element-{{ node.uuid }} .sg-module {
          text-align: {{ desktop.alignment }};
        }
      }
    </v-style>
  </div>
</template>

<script>
import ToolBar from '../../elements/utils/ToolBar.vue';

import elementStyleMixin from "../../elements/elementStyleMixin";
import ExtraClass from "../../elements/otherSettings/ExtraClass.vue";
import TitleTag from "./settings/TitleTag.vue";
import AlignmentBase from "../../elements/styleSettings/AlignmentBase.vue";
import Typography from "../../elements/styleSettings/Typography.vue";
import LabelText from "./settings/LabelText.vue";
import ContentText from "./settings/ContentText.vue";

import Visibility from "../../elements/designSettings/Visibility.vue";
import Spacing from "../../elements/designSettings/Spacing.vue";
import Background from "../../elements/designSettings/Background.vue";
import Border from "../../elements/designSettings/Border.vue";
import Corner from '../../elements/designSettings/Corner.vue';
import Shadow from '../../elements/designSettings/Shadow.vue';
import Opacity from '../../elements/designSettings/Opacity.vue';
import { designStyles, otherProps, otherStyles, actionTypes } from '@/constants';
import CustomCSS from '../../elements/styleSettings/CustomCSS.vue';
import Animations from '../../elements/designSettings/Animations.vue';

export default {
  components: {ToolBar},
  methods: {
  },
  mounted(){
  },
  data() {
    return {
      isHovering: false
    }
  },
  inject: ["node",'editor'],
  computed: {
  dragging: function () {
      return this.editor?.draggedNode?.uuid
    },
  },
  mixins: [elementStyleMixin],
  props: {
    ...otherProps,
    extra_class: String,
    component:String,
    content:String,
    headingType:String,
    parent:String,
    show_label:Boolean,
    show_copy:Boolean,
    label: String,
    sku:String,
    desktop: Object,
    laptop: Object,
    tablet: Object,
    mobile: Object,
    keyInfo:String,
  },
  craft: {
    rules:{
      canDrag: function(){
        return false
      }
    },
    defaultProps: {
      ...otherStyles,
      headingType: "h3",
      backgroundType: "color",
      label: "Label:",
      content:"content",
      show_label:true,
      show_copy:false,
      keyInfo:'',
      typographyActives: [
        'font-weight',
        'font-size',
        'line-height',
        'letter-spacing',
        'color',
        'font-family',
        'text-transform'
      ],
      desktop: {
        ...designStyles,
        font_weight: '600',
        font_size: 17,
        line_height: 1.5,
        letter_spacing: 0,
        color: 'rgb(0, 0, 0)',
        font_family: 'inherit',
        text_transform: 'uppercase',
        alignment: 'left'
      },
      laptop: {
        ...designStyles,
      },
      mobile: {
        ...designStyles,
      },
      tablet: {
        ...designStyles,
      },
      elementStyle: {
      }
    },
    settings: {
      settings: {
        "Title Tag":TitleTag,
        Typography: {Typography, multi_screen: true},
        "Content Text":ContentText,
        "Label Text": LabelText,
        "Alignment": {Alignment: AlignmentBase, multi_screen: true},
        "Extra Class": ExtraClass, "Custom CSS": CustomCSS,
      },
      design: {
        Visibility,
        Spacing: { Spacing, multi_screen: true },
        Background: { Background, multi_screen: true },
        Border: { Border, multi_screen: true },
        Corner: { Corner, multi_screen: true },
        Shadow: { Shadow, multi_screen: true },
        Opacity: { Opacity, multi_screen: true },
      },
      others: {
        Animations: { Animations, actions: actionTypes }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
  