<template>
  <div label-position="top" size="mini" :model="elementProps">
    <div class="d-flex flex-column py-2">
      <span class="setting-title">Margin</span>
      <Dimension
        class="mt-2"
        :units="['px', 'em', 'rem']"
        :unit="elementProps[screen].margin_unit || 'px'"
        :primaryColor="primaryColor"
        :data="elementProps[screen]"
        :items="[
          {
            key: 'margin_top',
            title: 'Top',
            disabled: getValueDisabled('margin_top')
          },
          {
            key: 'margin_right',
            title: 'Right',
            disabled: getValueDisabled('margin_right')
          },
          {
            key: 'margin_bottom',
            title: 'Bottom',
            disabled: getValueDisabled('margin_bottom')
          },
          {
            key: 'margin_left',
            title: 'Left',
            disabled: getValueDisabled('margin_left')
          }
        ]"
        v-bind:changeValue="(name, $event) => updateProps(screen, name, $event)"
        v-bind:changeUnit="($event) => updateProps(screen, 'margin_unit', $event)"
      />
    </div>
    <div class="d-flex flex-column py-2">
      <span class="setting-title">Padding</span>
      <Dimension
        class="mt-2"
        :units="['px', 'em', 'rem']"
        :unit="elementProps[screen].padding_unit || 'px'"
        :primaryColor="primaryColor"
        :data="elementProps[screen]"
        :minValue="0"
        :items="[
          {
            key: 'padding_top',
            title: 'Top',
            disabled: getValueDisabled('padding_top')
          },
          {
            key: 'padding_right',
            title: 'Right',
            disabled: getValueDisabled('padding_right')
          },
          {
            key: 'padding_bottom',
            title: 'Bottom',
            disabled: getValueDisabled('padding_bottom')
          },
          {
            key: 'padding_left',
            title: 'Left',
            disabled: getValueDisabled('padding_left')
          }
        ]"
        v-bind:changeValue="(name, $event) => updateProps(screen, name, $event)"
        v-bind:changeUnit="($event) => updateProps(screen, 'padding_unit', $event)"
      />
    </div>
  </div>
</template>

<script>
import mixin from "../styleSettings/mixin";
import Dimension from "../common/Dimension.vue";
export default {
  components: {
    Dimension
  },
  mixins: [mixin],
  props: {
    screen: String,
    primaryColor: String,
  },
  methods: {
    getValueDisabled(key) {
      const hiddenConfig = this.elementProps.hiddenConfig || []
      return hiddenConfig.includes(key) ? true : false
    },
    updateProps(screen, name, value) {
      const data_screen = Object.assign({}, this.elementProps[screen]);
      data_screen[name] = value;
      this.elementPropsSetter({ [screen]: data_screen });
    },
  },
};
</script>
<style scoped>
</style>
