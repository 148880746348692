var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"label-position":"top","size":"mini","model":_vm.elementProps}},[_c('div',{staticClass:"d-flex flex-column py-2"},[_c('ToggleButton',{attrs:{"value":_vm.link_type,"changeValue":($event) => _vm.updateType($event),"options":[
        { label: 'Normal', value: 'normal' },
        { label: 'Hover', value: 'hover' }
      ],"primaryColor":"#0060e5"}})],1),(_vm.link_type == 'normal')?_c('div',[_c('div',{staticClass:"d-flex flex-column py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Text Color")]),_c('ColorPicker',{staticClass:"mt-2",attrs:{"value":_vm.elementProps.link_text_color,"changeColor":($event) => _vm.updateCommonProps('link_text_color', $event),"primaryColor":"#0060e5"}})],1),_c('div',{staticClass:"d-flex flex-column py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Font Family")]),_c('Select',{key:_vm.fontKey,staticClass:"mt-2",attrs:{"value":_vm.elementProps.link_font_family,"changeValue":($event) => _vm.updateCommonProps('link_font_family', $event),"hasAction":true,"onAction":_vm.handleShowModal,"actionLabel":"Open Font Manager","options":_vm.getListFont(),"primaryColor":"#0060e5"}})],1),_c('div',{staticClass:"d-flex flex-column py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Font Size")]),_c('Slider',{staticClass:"mt-2",attrs:{"step":1,"value":_vm.elementProps.link_font_size,"changeValue":($event) => _vm.updateCommonProps('link_font_size', parseInt($event)),"unit":"px","max":999,"min":1,"primaryColor":"#0060e5","onlyInteger":true}})],1),_c('div',{staticClass:"d-flex flex-column py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Font Weight")]),_c('Select',{staticClass:"mt-2",attrs:{"value":_vm.elementProps.link_font_weight,"primaryColor":"#0060e5","changeValue":($event) => _vm.updateCommonProps('link_font_weight', $event),"options":[
          {
            value: '100',
            label: 'Thin 100',
          },
          {
            value: '200',
            label: 'Semi Thin 200',
          },
          {
            value: '300',
            label: 'Light 300',
          },
          {
            value: '400',
            label: 'Regular 400',
          },
          {
            value: '500',
            label: 'Medium 500',
          },
          {
            value: '600',
            label: 'Semi Bold 600',
          },
          {
            value: '700',
            label: 'Bold 700',
          },
          {
            value: '800',
            label: 'Super Bold 800',
          },
          {
            value: '900',
            label: 'Boldest 900',
          }
        ]}})],1),_c('div',{staticClass:"d-flex flex-column py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Text Decoration")]),_c('Select',{staticClass:"mt-2",attrs:{"primaryColor":"#0060e5","value":_vm.elementProps.link_text_decoration,"changeValue":($event) => _vm.updateCommonProps('link_text_decoration', $event),"options":[
          { value: 'none', label: 'None' },
          { value: 'underline', label: 'Underline' },
          { value: 'overline', label: 'Overline' },
          { value: 'line-through', label: 'Line Through' },
        ]}})],1)]):_vm._e(),(_vm.link_type == 'hover')?_c('div',[_c('div',{staticClass:"d-flex flex-column py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Text Color")]),_c('ColorPicker',{staticClass:"mt-2",attrs:{"value":_vm.elementProps.link_hover_text_color,"changeColor":($event) => _vm.updateCommonProps('link_hover_text_color', $event),"primaryColor":"#0060e5"}})],1),_c('div',{staticClass:"d-flex flex-column py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Font Family")]),_c('Select',{key:_vm.fontKey,staticClass:"mt-2",attrs:{"value":_vm.elementProps.link_hover_font_family,"changeValue":($event) => _vm.updateCommonProps('link_hover_font_family', $event),"hasAction":true,"onAction":_vm.handleShowModal,"actionLabel":"Open Font Manager","options":_vm.getListFont(),"primaryColor":"#0060e5"}})],1),_c('div',{staticClass:"d-flex flex-column py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Font Size")]),_c('Slider',{staticClass:"mt-2",attrs:{"step":1,"value":_vm.elementProps.link_hover_font_size,"changeValue":($event) => _vm.updateCommonProps('link_hover_font_size', parseInt($event)),"unit":"px","max":999,"min":1,"primaryColor":"#0060e5","onlyInteger":true}})],1),_c('div',{staticClass:"d-flex flex-column py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Font Weight")]),_c('Select',{staticClass:"mt-2",attrs:{"value":_vm.elementProps.link_hover_font_weight,"changeValue":($event) => _vm.updateCommonProps('link_hover_font_weight', $event),"options":[
          {
            value: '100',
            label: 'Thin 100',
          },
          {
            value: '200',
            label: 'Semi Thin 200',
          },
          {
            value: '300',
            label: 'Light 300',
          },
          {
            value: '400',
            label: 'Regular 400',
          },
          {
            value: '500',
            label: 'Medium 500',
          },
          {
            value: '600',
            label: 'Semi Bold 600',
          },
          {
            value: '700',
            label: 'Bold 700',
          },
          {
            value: '800',
            label: 'Super Bold 800',
          },
          {
            value: '900',
            label: 'Boldest 900',
          }
        ],"primaryColor":"#0060e5"}})],1),_c('div',{staticClass:"d-flex flex-column py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Text Decoration")]),_c('Select',{staticClass:"mt-2",attrs:{"value":_vm.elementProps.link_hover_text_decoration,"changeValue":($event) => _vm.updateCommonProps('link_hover_text_decoration', $event),"options":[
          { value: 'none', label: 'None' },
          { value: 'underline', label: 'Underline' },
          { value: 'overline', label: 'Overline' },
          { value: 'line-through', label: 'Line Through' },
        ],"primaryColor":"#0060e5"}})],1)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }