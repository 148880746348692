<template>
  <div
    data-label="(P) View More"
    data-key="(p) View More"
    data-parent="product"
    class="sg-wrap module"
    :id="`element-${node.uuid}`"
    @mouseover="isHovering=true" 
    @mouseout="isHovering=false"
    :class="{ 'dragging': dragging == node.uuid }"

  >
    <div
      class="sg-module"
      :class="extra_class"
    >
      
      <button :class="`button btn sg-view-more`" :target="target">
        <img :src="imgUrl" alt="" v-if="imgUrl"/>
        <span class="btn-text" v-if="!imgUrl">{{ text }}</span>
      </button>
    </div>
    <ToolBar ref="(p)viewmore" 
      :toolbar_id="`(p)viewmore`"
     class="container-toolbar" 
     :class="{ 'container-toolbar-show': isHovering, 'is-dragging': dragging == node.uuid }"
     >
     </ToolBar>
    <v-style> {{ custom_css }}
      
      @media (max-width: 767px){
        #element-{{ node.uuid }} {
          {{ getDisplayValue('mobile') }}
          {{ getMarginValue('mobile') }}
        }
        #element-{{ node.uuid }} .sg-view-more {
          {{ getValue('font_weight','mobile') ? `font-weight: ${getValue('font_weight','mobile')};` : '' }}
          {{ getValue('font_size', 'mobile') > 0 ? `font-size: ${getValue('font_size','mobile')}px;` : '' }}
          font-family: {{ getValue('font_family','mobile') }};
          {{ getValue('text_transform','mobile') ? `text-transform: ${getValue('text_transform','mobile')};` : '' }}
          {{ getValue('background_color','mobile') ? `background-color: ${getValue('background_color','mobile')};` : '' }}
          {{ getValue('color','mobile') ? `color: ${getValue('color','mobile')};` : '' }}
          {{ getValue('stretch','mobile') ? `width: 100%;` : ''}}
          {{ !getValue('stretch','mobile') && getValue('width','mobile') ? `width: ${getValue('width','mobile')}${getValue('width_type','mobile') || ''};` : '' }}
          {{ getValue('height','mobile') ? `height: ${getHeight(getValue('height','mobile'))};` : '' }}
          {{ getPaddingValue('mobile') }}
          {{ getBackgroundValue('mobile') }}
          {{ getBorderValue('mobile') }}
          {{ getBorderRadiusValue('mobile') }}
          {{ getOpacityValue('mobile') }}
          {{ getShadowValue('mobile') }}
          {{ getOtherStyle('mobile') }}
        }
        #element-{{ node.uuid }} .sg-view-more:hover { 
          {{ getValue('hover','mobile') ? `background-color: ${getValue('background_color_hover','mobile')}; color: ${getValue('color_hover','mobile')};` : '' }}
        }
        #element-{{ node.uuid }} .sg-module { 
          text-align: {{ getValue('alignment','mobile') }};
        }
      } 
      @media (max-width:991px) and (min-width: 768px){
        #element-{{ node.uuid }} {
          {{ getDisplayValue('tablet') }}
          {{ getMarginValue('tablet') }}
        }
        #element-{{ node.uuid }} .sg-view-more { 
          {{ getValue('font_weight','tablet') ? `font-weight: ${getValue('font_weight','tablet')};` : '' }}
          {{ getValue('font_size', 'tablet') > 0 ? `font-size: ${getValue('font_size','tablet')}px;` : '' }}
          font-family: {{ getValue('font_family','tablet') }};
          {{ getValue('text_transform','tablet') ? `text-transform: ${getValue('text_transform','tablet')};` : '' }}
          {{ getValue('background_color','tablet') ? `background-color: ${getValue('background_color','tablet')};` : '' }}
          {{ getValue('color','tablet') ? `color: ${getValue('color','tablet')};` : '' }}
          {{ getValue('stretch','tablet') ? `width: 100%;` : ''}}
          {{ !getValue('stretch','tablet') && getValue('width','tablet') ? `width: ${getValue('width','tablet')}${getValue('width_type','tablet') || ''};` : '' }}
          {{ getValue('height','tablet') ? `height: ${getHeight(getValue('height','tablet'))};` : '' }}
          {{ getPaddingValue('tablet') }}
          {{ getBackgroundValue('tablet') }}
          {{ getBorderValue('tablet') }}
          {{ getBorderRadiusValue('tablet') }}
          {{ getOpacityValue('tablet') }}
          {{ getShadowValue('tablet') }}
          {{ getOtherStyle('tablet') }}
        }
        #element-{{ node.uuid }} .sg-view-more:hover { 
          {{ getValue('hover','tablet') ? `background-color: ${getValue('background_color_hover','tablet')}; color: ${getValue('color_hover','tablet')};` : '' }}
        }
        #element-{{ node.uuid }} .sg-module { 
          text-align: {{ getValue('alignment','tablet') }};
        }
      } 
      @media (max-width: 1199px) and (min-width: 992px){
        #element-{{ node.uuid }} {
          {{ getDisplayValue('laptop') }}
          {{ getMarginValue('laptop') }}
        }
        #element-{{ node.uuid }} .sg-view-more { 
          {{ getValue('font_weight','laptop') ? `font-weight: ${getValue('font_weight','laptop')};` : '' }}
          {{ getValue('font_size', 'laptop') > 0 ? `font-size: ${getValue('font_size','laptop')}px;` : '' }}
          font-family: {{ getValue('font_family','laptop') }};
          {{ getValue('text_transform','laptop') ? `text-transform: ${getValue('text_transform','laptop')};` : '' }}
          {{ getValue('background_color','laptop') ? `background-color: ${getValue('background_color','laptop')};` : '' }}
          {{ getValue('color','laptop') ? `color: ${getValue('color','laptop')};` : '' }}
          {{ getValue('stretch','laptop') ? `width: 100%;` : ''}}
          {{ !getValue('stretch','laptop') && getValue('width','laptop') ? `width: ${getValue('width','laptop')}${getValue('width_type','laptop') || ''};` : '' }}
          {{ getValue('height','laptop') ? `height: ${getHeight(getValue('height','laptop'))};` : '' }}
          {{ getPaddingValue('laptop') }}
          {{ getBackgroundValue('laptop') }}
          {{ getBorderValue('laptop') }}
          {{ getBorderRadiusValue('laptop') }}
          {{ getOpacityValue('laptop') }}
          {{ getShadowValue('laptop') }}
          {{ getOtherStyle('laptop') }}
        }
        #element-{{ node.uuid }} .sg-view-more:hover { 
          {{ getValue('hover','laptop') ? `background-color: ${getValue('background_color_hover','laptop')}; color: ${getValue('color_hover','laptop')};` : '' }}
        }
        #element-{{ node.uuid }} .sg-module { 
          text-align: {{ getValue('alignment','laptop') }};
        }
      } 
      @media (min-width: 1200px){ 
        #element-{{ node.uuid }} {
          {{ getDisplayValue('desktop') }}
          {{ getMarginValue('desktop') }}
        }
        #element-{{ node.uuid }} .sg-view-more { 
          {{ getValue('font_weight','desktop') ? `font-weight: ${getValue('font_weight','desktop')};` : '' }}
          {{ getValue('font_size', 'desktop') > 0 ? `font-size: ${getValue('font_size','desktop')}px;` : '' }}
          font-family: {{ getValue('font_family','desktop') }};
          {{ getValue('text_transform','desktop') ? `text-transform: ${getValue('text_transform','desktop')};` : '' }}
          {{ getValue('background_color','desktop') ? `background-color: ${getValue('background_color','desktop')};` : '' }}
          {{ getValue('color','desktop') ? `color: ${getValue('color','desktop')};` : '' }}
          {{ getValue('stretch','desktop') ? `width: 100%;` : ''}}
          {{ !getValue('stretch','desktop') && getValue('width','desktop') ? `width: ${getValue('width','desktop')}${getValue('width_type','desktop') || ''};` : '' }}
          {{ getValue('height','desktop') ? `height: ${getHeight(getValue('height','desktop'))};` : '' }}
          {{ getPaddingValue('desktop') }}
          {{ getBackgroundValue('desktop') }}
          {{ getBorderValue('desktop') }}
          {{ getBorderRadiusValue('desktop') }}
          {{ getOpacityValue('desktop') }}
          {{ getShadowValue('desktop') }}
          {{ getOtherStyle('desktop') }}
        }
        #element-{{ node.uuid }} .sg-view-more:hover { 
          {{ desktop.hover ? `background-color: ${desktop.background_color_hover}; color: ${desktop.color_hover};` : '' }}
        }
        #element-{{ node.uuid }} .sg-module { 
          text-align: {{ desktop.alignment }};
        }
      }
    </v-style>
  </div>
</template>

<script>
import elementStyleMixin from "../../elements/elementStyleMixin";
import AlignmentBase from "../../elements/styleSettings/AlignmentBase.vue";
import ExtraClass from "../../elements/otherSettings/ExtraClass.vue"; import CustomCSS from '../../elements/styleSettings/CustomCSS.vue';
import Visibility from "../../elements/designSettings/Visibility.vue";
import Spacing from "../../elements/designSettings/Spacing.vue";
import Background from "../../elements/designSettings/Background.vue";
import Border from "../../elements/designSettings/Border.vue";
import Corner from "../../elements/designSettings/Corner.vue";
import Shadow from "../../elements/designSettings/Shadow.vue";
import Opacity from "../../elements/designSettings/Opacity.vue";
import Typography from "../../elements/styleSettings/Typography.vue";
import ButtonColor from "../../elements/styleSettings/ButtonColor.vue";
import Dimensions from "../../elements/styleSettings/Dimensions.vue";
import ToolBar from '../../elements/utils/ToolBar.vue';
import Image from '../../elements/styleSettings/Image.vue';
import { designStyles, otherProps, otherStyles, actionTypes } from "@/constants";
import Settings from "./settings/Settings.vue";
import Animations from '../../elements/designSettings/Animations.vue';


export default {
  mixins: [elementStyleMixin],
  components: {  ToolBar },
  props: {
    target: String,
    text: String,
    extra_class: String, ...otherProps,
    
    parent:String,
    component:String,
    imgUrl: String,
    desktop: Object,
    laptop: Object,
    mobile: Object,
    tablet: Object,
  },
  data() {
    return {
      isHovering: false,
    }
  },
  methods: {
    getHeight(height) {
      if (height.includes('%') || height.includes('px') || isNaN(parseInt(height))) {
        return height;
      }
      return `${height}px`
    },
  },
  inject: ["node","product",'editor'],
  computed: {
    dragging: function () {
      return this.editor?.draggedNode?.uuid
    },
  },
  craft: {
    defaultProps: { ...otherStyles,
      imgUrl: null,
      text: "View More",
      target: '_self',
      parent:"Product",
      extra_class: "",
      typographyActives: [
        "font-size",
        "font-weight",
        "text-transform",
        "font-family",
      ],
      dimensionActives: [
        "stretch",
        "width",
        "height",
      ],
      desktop: {
        ...designStyles,
        font_weight: '600',
        font_size: 14,
        font_family: 'inherit',
        text_transform: 'none',
        background_color: 'rgb(119, 150, 168)',
        color: 'rgb(255, 255, 255)',
        stretch: false,
        width: 'auto',
        height: 'auto',
        width_type: '%',
        alignment: 'center',
        padding_top: 10,
        padding_bottom: 10,
        padding_left: 20,
        padding_right: 20,
      },
      laptop: {
        ...designStyles,
      },
      tablet: {
        ...designStyles,
      },
      mobile: {
        ...designStyles,
      },
      elementStyle: {
        
      },
    },
    settings: {
      settings: {
        Settings,
        Typography: {Typography, multi_screen: true},
        "Button Color": {"Button Color": ButtonColor, multi_screen: true},
        Dimension: {Dimension: Dimensions, multi_screen: true},
        Alignment: {Alignment: AlignmentBase, multi_screen: true},
        Image,
        "Extra Class": ExtraClass, "Custom CSS": CustomCSS,
      },
      design: {
        Visibility,
        Spacing: { Spacing, multi_screen: true },
        Background: { Background, multi_screen: true },
        Border: { Border, multi_screen: true },
        Corner: { Corner, multi_screen: true },
        Shadow: { Shadow, multi_screen: true },
        Opacity: { Opacity, multi_screen: true },
      },
      others: {
        Animations: { Animations, actions: actionTypes }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
