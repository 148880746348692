<template>
  <div
    :class="`panel-edit-color ${show ? 'show' : ''}`"
    ref="myDiv"
    :style="styles_md"
  >
    <vue-tabz
      v-if="show"
      :data="['Text Color', 'High Light']"
      max-width="700"
      main-color="#fff"
      @clickedTab="handleClick"
    />
    <!-- <div class="panel-content"> -->
    <color-picker
      v-if="show"
      :class="activeIndex == 0 ? 'picker_show' : 'picker_hidden'"
      theme="dark"
      :style="styles"
      :color="textColor"
      :sucker-hide="true"
      @changeColor="changeColor"
      :colorsDefault="colorsDefault"
    />

    <color-picker
      v-if="show"
      :class="activeIndex == 1 ? 'picker_show' : 'picker_hidden'"
      theme="dark"
      :style="styles"
      :color="highLight"
      :sucker-hide="true"
      @changeColor="changeBackColor"
      :colorsDefault="hiliteDefault"
    />

    <div :class="`v-popper__arrow-container ${placement}`">
      <div class="v-popper__arrow-outer"></div>
      <div class="v-popper__arrow-inner"></div>
    </div>
  </div>
</template>

<script>
import colorPicker from "@caohenghu/vue-colorpicker";

export default {
  props: {
    handleOutSite: Function,
    show: Boolean,
    foreColor: Function,
    textColor: String,
    highLight: String,
    toolbar: HTMLElement,
    iframe: Object,
  },
  watch: {
    show() {
      this.activeIndex = 0;
      if (this.show) {
        this.styles_md = this.elementCSS();
        const windowContent = this.iframe.$el.contentWindow;
        const top = this.toolbar?.getBoundingClientRect().top;
        if (windowContent.innerHeight - 417 <= top) {
          this.placement = "bottom";
        } else {
          this.placement = "top";
        }
      }
    },
  },
  components: {
    colorPicker,
  },
  created() {
    this.$bus.on("showToolbar", (currentRef, value, node) => {
      if (value && this.show) {
        this.handleOutSite();
      }
    });
  },
  data() {
    return {
      styles: {
        width: "220px",
        background: "#343b3e",
      },
      placement: "top",
      styles_md: {},
      activeIndex: 0,
      color: "#59c7f9",
      suckerCanvas: null,
      suckerArea: [],
      isSucking: false,
      colorsDefault: [
        "#000000",
        "#FFFFFF",
        "#FF1900",
        "#F47365",
        "#FFB243",
        "#FFE623",
        "#6EFF2A",
        "#1BC7B1",
      ],
      hiliteDefault: [
        "#000000",
        "#FFFFFF",
        "#FF1900",
        "#F47365",
        "#FFB243",
        "#FFE623",
        "#6EFF2A",
        "rgba(0,0,0,0)",
      ],
    };
  },
  methods: {
    handleClick(tab) {
      this.activeIndex = tab.index;
    },
    changeColor(color) {
      const { r, g, b, a } = color.rgba;
      this.foreColor("foreColor", false, color.hex);
    },
    changeBackColor(color) {
      const { r, g, b, a } = color.rgba;
      const { h, s, v } = color.hsv;

      this.color = `rgba(${r}, ${g}, ${b}, ${a})`;
      this.foreColor("backColor", false, this.color);
    },
    elementCSS: function () {
      const windowContent = this.iframe.$el.contentWindow;
      const style = {};
      const top = this.toolbar?.getBoundingClientRect().top;
      const height_md = 417;
      if (windowContent.innerHeight - height_md <= top) {
        style["bottom"] = `calc(100% + 8px)`;
      } else {
        style["top"] = `calc(100% + 8px)`;
      }
      style["left"] = "calc(50% - 110px)";
      return style;
    },
  },
};
</script>


<style scoped>
.panel-edit-color {
  /* width: 100px; */
  height: fit-content;
  position: absolute;
  z-index: 10;
  background: #343b3e;
  box-shadow: 0 2px 2px 0 rgb(67 67 67 / 50%);
  border-radius: 3px;
  visibility: hidden;
  user-select: none;
}
.panel-edit-color.show {
  visibility: visible;
}
.panel-content {
  width: fit-content;
}

.v-popper__arrow-container {
  position: absolute;
  width: 10px;
  height: 10px;
}

.v-popper__arrow-container.top {
  top: -12px;
  left: calc(50% - 6px);
}

.v-popper__arrow-container.bottom {
  bottom: -10px;
  left: calc(50% - 6px);
}

.v-popper__arrow-outer {
  top: -6px;
}
.v-popper__arrow-inner,
.v-popper__arrow-outer {
  border-left-width: 0;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  border-bottom-color: #343b3e !important;
}

.bottom .v-popper__arrow-inner,
.bottom .v-popper__arrow-outer {
  border-left-width: 6px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  border-top-color: #343b3e !important;
}

.v-popper__arrow-outer,
.v-popper__arrow-outer {
  top: -1px;
}
.v-popper__arrow-outer {
  border-color: #343b3e;
}
.v-popper__arrow-outer {
  border-width: 6px;
}
.v-popper__arrow-inner,
.v-popper__arrow-outer {
  border-style: solid;
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
}

.v-popper__arrow-inner {
  top: -4px;
}

.v-popper__arrow-inner,
.v-popper__arrow-inner {
  top: -2px;
}
.v-popper--theme-dropdown .v-popper__arrow-inner {
  visibility: visible;
  border-color: #343b3e;
}
.v-popper__arrow-inner {
  visibility: hidden;
  border-width: 7px;
}
.v-popper__arrow-inner,
.v-popper__arrow-outer {
  border-style: solid;
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
}
.picker_show {
  display: block;
}
.picker_hidden {
  display: none;
}
</style>