import { render, staticRenderFns } from "./ShowYoutubeLogo.vue?vue&type=template&id=e0604fcc&scoped=true&"
import script from "./ShowYoutubeLogo.vue?vue&type=script&lang=js&"
export * from "./ShowYoutubeLogo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e0604fcc",
  null
  
)

export default component.exports