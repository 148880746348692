<template>
  <div label-position="top" size="small" :model="elementProps">
    <div class="d-flex flex-column py-2">
      <span class="setting-title">Color</span>
      <ColorPicker
        class="mt-2"
        :value="getValue('label_color',elementProps[screen].label_color)"
        v-bind:changeColor="($event) => updateProps('label_color', $event)"
        :primaryColor="primaryColor"
      />
    </div>
    <div class="d-flex flex-column py-2">
      <span class="setting-title">Font</span>
      <Select
        class="mt-2"
        :primaryColor="primaryColor"
        :value="getValue('label_font_family',elementProps[screen].label_font_family)"
        v-bind:changeValue="($event) => updateProps('label_font_family', $event)"
        :hasAction="true"
        v-bind:onAction="handleShowModal"
        actionLabel="Open Font Manager"
        :options="getListFont()"
      />
    </div>
    <div class="d-flex flex-column py-2">
      <span class="setting-title">Font Size</span>
      <Slider 
        class="mt-2"
        :primaryColor="primaryColor"
        :value="Number.parseInt(getValue('label_font_size',elementProps[screen].label_font_size))"
        :min="0"
        :max="100"
        :step="1"
        v-bind:changeValue="($event) => updateProps('label_font_size', $event)"
        unit="px"
        :onlyInteger="true"
      />
    </div>
  </div>
</template>

<script>
import mixin from "../../../elements/styleSettings/mixin";
import ColorPicker from "../../../elements/common/ColorPicker.vue";
import Slider from "../../../elements/common/Slider.vue";
import Select from "../../../elements/common/Select.vue";
import Node from "@/core/Node";

export default {
  mixins: [mixin],
  components: { ColorPicker, Slider, Select },
  props: {
    node: Node,
    primaryColor: String,
    screen: String,
    activeFonts: Array,
  },
  methods: {
    updateProps(name, value) {
      const screen = this.$props.screen
      const data_screen = Object.assign({}, this.elementProps[screen])
      if(value ==  data_screen[name]) return;
      data_screen[name] = value
      this.elementPropsSetter({ [screen]: data_screen });
    },
    handleShowModal() {
      this.$bus.emit("showFontManager", this.node);
    },
    getListFont() {
      const listFonts = [
        {
          value: 'inherit',
          label: 'Default',
        }
      ]
      this.node?.editor?.fonts?.map(e => {
        listFonts.push({ value: e, label: e })
      })
      return listFonts;
    },
  },
};
</script>

<style scoped>
</style>
