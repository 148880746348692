<template>
  <div label-position="top" size="mini" :model="elementProps">
    <div class="d-flex flex-column py-2">
      <span class="setting-title">Color Counter</span>
      <ColorPicker
        class="mt-2"
        :value="elementProps.color_counter"
        v-bind:changeColor="($event) => updateProps('color_counter', $event)"
        :primaryColor="primaryColor"
      />
    </div>
    <div class="d-flex flex-column py-2" v-if="styleExist('color')">
      <span class="setting-title">Color</span>
      <ColorPicker
        class="mt-2"
        :value="getStyle('color')"
        v-bind:changeColor="($event) => setStyle('color', $event)"
        :primaryColor="primaryColor"
      />
    </div>
    <div class="d-flex flex-column py-2" v-if="styleExist('font-size')">
      <span class="setting-title">Font Size</span>
      <Slider
        class="mt-2"
        :value="getStyle('font-size')"
        :primaryColor="primaryColor"
        v-bind:changeValue="($event) => setStyle('font-size', $event)"
        unit="px"
      />
    </div>
    <div class="d-flex flex-column py-2" v-if="styleExist('height')">
      <span class="setting-title">Progress Bar Height</span>
      <Slider
        class="mt-2"
        :value="getStyle('height')"
        :primaryColor="primaryColor"
        v-bind:changeValue="($event) => setStyle('height', $event)"
        unit="px"
      />
    </div>

    <div class="d-flex align-items-center justify-content-between py-2">
      <span class="sgSubTitle">Rounded Border</span>
      <el-switch
        :value="elementProps.rounded_border"
        :active-color="primaryColor"
        inactive-color="#949494"
        @change="updateProps('rounded_border', $event)"
      />
    </div>

    <div class="d-flex align-items-center justify-content-between py-2">
      <span class="sgSubTitle">Effect</span>
      <el-switch
        :value="elementProps.animation"
        :active-color="primaryColor"
        inactive-color="#949494"
        @change="updateProps('animation', $event)"
      />
    </div>

    <div class="d-flex align-items-center justify-content-between py-2">
      <span class="sgSubTitle">Show Icon</span>
      <el-switch
        :value="elementProps.show_icon"
        :active-color="primaryColor"
        inactive-color="#949494"
        @change="updateProps('show_icon', $event)"
      />
    </div>

    <div class="add-icon-box" @click="handleShowModal">
      <a href="#" class="selected-icon">
        <i :class="`sg_icon fa ${elementProps.icon}`"></i>
        <!-- <font-awesome-icon icon="fa-solid fa-user-secret" /> -->
      </a>
      <a href="#" class="button-sg sgIconPicker">Choose Icon...</a>
    </div>
  </div>
</template>

<script>
import mixin from "../../../elements/styleSettings/mixin";
import Select from "../../../elements/common/Select.vue";
import ColorPicker from "../../../elements/common/ColorPicker.vue";
import Slider from "../../../elements/common/Slider.vue";
import Node from "@/core/Node";

export default {
  mixins: [mixin],
  components: { Select, ColorPicker, Slider },
  props:{
    node: Node,
    primaryColor: String
  },
  data() {
    return {
      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,
    };
  },
  methods: {
    handleShowModal() {
      this.$bus.emit("showColorPicker", this.node);
    },
    updateProps(name, value) {
      if(value ==  this.elementProps[name]) return;
      this.elementPropsSetter({ [name]: value });
    }
  },
};
</script>

<style scoped>
.add-icon-box {
  margin-bottom: 20px;
  border-radius: 3px;
  background: #252525;
  display: inline-block;
  width: 100%;
}
.add-icon-box a {
  text-align: center;
  width: 100%;
  color: #d6d6d6;
  text-decoration: none;
}
.add-icon-box a.selected-icon {
  float: left;
  display: block;
  text-align: center;
  font-size: 32px;
  padding: 32px 0 16px;
}
.add-icon-box .sgIconPicker {
  padding: 4px 0 32px;
  font-size: 11px;
}

.button-sg,
button-sg {
  display: inline-block;
  line-height: 1;
  padding: 1em 1.3em;
  text-align: center;
  cursor: pointer;
  transition: all 0.45s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
</style>
