var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-form',{attrs:{"label-position":"top","size":"","model":_vm.elementProps}},[_c('el-form-item',{attrs:{"label":"Button Link"}},[_c('el-input',{attrs:{"value":_vm.elementProps.href,"placeholder":"https://your-shop.myshopify.com","size":"medium"},on:{"input":($event) => _vm.updateProps('href', $event)}})],1),_c('el-form-item',{attrs:{"label":"Open link in"}},[_c('Select',{attrs:{"primaryColor":_vm.primaryColor,"value":_vm.elementProps.target,"changeValue":($event) => _vm.updateProps('target', $event),"options":[
        {
          value: '_self',
          label: 'Current Tab',
        },
        {
          value: '_blank',
          label: 'New Tab',
        },
      ]}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }