<template>
  <div label-position="top" size="" :model="elementProps">
    <div class="d-flex flex-column py-2">
      <span class="setting-title">Icon Type</span>
      <Select
        class="mt-2"
        :primaryColor="primaryColor"
        :value="elementProps.icon_type"
        v-bind:changeValue="($event) => updateProps('icon_type', $event)"
        :options="[
          {
            value: 'none',
            label: 'None',
          },
          {
            value: 'chevron',
            label: 'Chevron',
          },
          {
            value: 'plus',
            label: 'Plus',
          }
        ]"
      />
    </div>
    <div class="d-flex flex-column py-2" v-if="elementProps.icon_type != 'none'">
      <span class="setting-title">Icon Position</span>
      <TabIcon
        class="mt-2"
        :value="elementProps.icon_position"
        v-bind:changeValue="($event) => updateProps('icon_position', $event)"
        :options="[
          { value: 'left', icon: 'align-left',tooltip: 'Left' },
          { value: 'right', icon: 'align-right', tooltip: 'Right' },
        ]"
        :primaryColor="primaryColor"
      />
    </div>
    <div class="d-flex flex-column py-2" v-if="elementProps.icon_type != 'none'">
      <span class="setting-title">Icon Color</span>
      <ColorPicker
        class="mt-2"
        :value="elementProps.icon_color"
        v-bind:changeColor="($event) => updateProps('icon_color', $event)"
        :primaryColor="primaryColor"
      />
    </div>
  </div>
</template>

<script>
import mixin from "../../../elements/styleSettings/mixin";
import Select from "../../../elements/common/Select.vue";
import TabIcon from "../../../elements/common/TabIcon.vue";
import ColorPicker from "../../../elements/common/ColorPicker.vue";
export default {
  mixins: [mixin],
  components: { Select, TabIcon, ColorPicker },
  props: {
    primaryColor: String
  },
  methods: {
    updateProps(name, value) {
      if(value ==  this.elementProps[name]) return;
      this.elementPropsSetter({ [name]: value });
    },
  },
};
</script>

<style scoped>
</style>
