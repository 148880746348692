<template>
  <div class="paper" v-if="editor">
    <slot></slot>
  </div>
</template>
<script>
export default {
  inject: ["editor"],
};
</script>
<style lang="scss">
.paper {
  // max-width: 1300px;
  margin: auto;
  border: none;
  box-shadow: 0 14px 24px -6px #474d4730;
  background-color: white;

  .block:hover {
    outline: #42c184 solid 1px;
  }
}

@media only screen and (min-width: 1400px) {
  .paper .container {
    max-width: 100%;
  }
}
</style>
