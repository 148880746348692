<template>
  <div
    data-label="Collection Toolbar"
    data-key="collection-toolbar"
    class="sg-wrap module"
    :id="`element-${node.uuid}`"
    @mouseover="isHovering = true"
    @mouseout="isHovering = false"
    :class="{ dragging: dragging == node.uuid }"
    style="color: #fff"
    :data-show-quantity="show_quantity?1:0" 
    :data-quantity-text="quantity_text"
  >
    <div
      :class="`sg-module sg_module-${desktop.alignment} sg_module-${desktop.alignment}-lg sg_module-${getValue('alignment','laptop')}-md sg_module-${getValue('alignment','tablet')}-sm sg_module-${getValue('alignment','mobile')}-xs`"
    >
      <div class="sg_collection-toolbar-wrapper">
        <div class="sg_collection-tool sg_collection-filter-wrapper sg_col-xs-4 sg_col-sm-4 sg_col-md-4 sg_col-lg-4">
          <select class="sg_collection-filter">
            <option value="blank">{{filter_text}}</option>
          </select>
        </div>

        <div class="sg_collection-tool sg_product-quantity-wrapper">
          <span class="sg_product-quantity" v-if="show_quantity">{{(quantity_text||'').replace('%number%', '1')}}</span>
        </div>

        <div class="sg_collection-tool sg_sort-collection-wrapper sg_col-xs-4 sg_col-sm-4 sg_col-md-4 sg_col-lg-4 justify-content-end">
          <select class="sg_sort-collection">
            <option value="blank">{{sorting_text}}</option>

            <option value="best-selling">Best selling</option>

            <option value="title-ascending">Alphabetically, A-Z</option>

            <option value="title-descending">Alphabetically, Z-A</option>

            <option value="price-ascending">Price, low to high</option>

            <option value="price-descending">Price, high to low</option>

            <option value="created-descending">Date, new to old</option>

            <option value="created-ascending">Date, old to new</option>
          </select>
        </div>
      </div>
    </div>

    <ToolBar
      ref="(c)toolbar"
      :toolbar_id="`(c)toolbar`"
      class="container-toolbar"
      :class="{ 'container-toolbar-show': isHovering, 'is-dragging': dragging == node.uuid }"
    >
    </ToolBar>
    <div v-if="dragging == node.uuid" class="dragging"></div>
    <v-style>
        {{ custom_css }}
        #element-{{ node.uuid }} .sg_collection-toolbar-wrapper {
            display: inline-flex;
            margin: auto;
            padding-left: 20px;
            padding-right: 20px;
            width: 100%;
            height: 50px;
            line-height: 50px;
        }
        #element-{{ node.uuid }} .sg_collection-tool select {
            -moz-appearance: menulist;
            -webkit-appearance: menulist;
            appearance: menulist;
            background-image: none;
            background-color: transparent;
            border: none;
            padding: 3px 5px;
            font-size: 14px;
        }

        #element-{{ node.uuid }} .sg_collection-tool > * {
            display: inline-block;
        }

        #element-{{ node.uuid }} .sg_product-quantity-wrapper {
            flex-grow: 1;
            text-align: center;
            font-size: 16px;
        }

        #element-{{ node.uuid }} .sg_collection-filter-wrapper {
            display: flex;
            align-items: center;
        }

        #element-{{ node.uuid }} .sg_sort-collection-wrapper {
            display: flex;
            align-items: center;
        }

        @media (max-width: 767px){ 
            #element-{{ node.uuid }} .sg_collection-toolbar-wrapper {
                width: {{!isNaN(getValue('width','mobile'))?`${getValue('width','mobile')}${getValue('typeWidth','mobile')||'px'}`:'auto'}}!important;
                height: {{getValue('height','mobile')||0}}px!important;
                line-height: {{getValue('height','mobile')||0}}px!important;
            }
            #element-{{ node.uuid }} .sg_product-quantity-wrapper {
                color: {{getValue('quantity_color','mobile')}}!important;
                font-size: {{getValue('quantity_font_size','mobile')}}px!important;
                font-family: {{getValue('quantity_font_family','mobile')?`${getValue('quantity_font_family','mobile')}!important`:''}};
            }
            #element-{{ node.uuid }} select {
                color: {{getValue('select_color','mobile')}}!important;
                font-size: {{getValue('select_font_size','mobile')}}px!important;
                font-family: {{getValue('select_font_family','mobile')?`${getValue('select_font_family','mobile')}!important`:''}};
            }

            #element-{{ node.uuid }}  {
                
                display: {{ mobile.display == 'none' ? 'none' : 'block' }} !important;
                margin: {{ getValue('margin_top','mobile') }}px {{ getValue('margin_right','mobile') }}px {{ getValue('margin_bottom','mobile') }}px {{ getValue('margin_left','mobile') }}px;
                padding-top: {{`${getValue('padding_top','mobile')}px`}};
                padding-right: {{`${getValue('padding_right','mobile')}px`}};
                padding-bottom: {{`${getValue('padding_bottom','mobile')}px`}};
                padding-left: {{`${getValue('padding_left','mobile')}px`}};
                {{ getValue('background_type','mobile') == 'color' && getValue('background_color','mobile') ? `background-color: ${getValue('background_color','mobile')};` : ''}}
                {{ getValue('background_type','mobile') == 'image' && getValue('background_image','mobile') ? `background-image: url(${getValue('background_image','mobile')});` : ''}}
                {{ getValue('background_type','mobile') == 'image' && getValue('background_size','mobile') ? `background-size: ${getValue('background_size','mobile')};` : ''}}
                {{ getValue('background_type','mobile') == 'image' && getValue('background_position','mobile') ? `background-position: ${getValue('background_position','mobile')};` : ''}}
                {{ getValue('background_type','mobile') == 'image' && getValue('background_repeat','mobile') ? `background-repeat: ${getValue('background_repeat','mobile')};` : ''}}
                {{ getValue('background_type','mobile') == 'image' && getValue('background_attachment','mobile') ? `background-attachment: ${getValue('background_attachment','mobile')};` : ''}}
                {{ getValue('border_style','mobile') ? `border-style: ${getValue('border_style','mobile')};` : '' }}
                {{ getValue('border_color','mobile') ? `border-color: ${getValue('border_color','mobile')};` : '' }}
                {{ getValue('border_width','mobile') ? `border-width: ${getValue('border_width','mobile')}px;` : '' }}
                {{ getValue('border_top_left_radius','mobile') ? `border-top-left-radius: ${getValue('border_top_left_radius','mobile')}px;` : '' }}
                {{ getValue('border_top_right_radius','mobile') ? `border-top-right-radius: ${getValue('border_top_right_radius','mobile')}px;` : '' }}
                {{ getValue('border_bottom_left_radius','mobile') ? `border-bottom-left-radius: ${getValue('border_bottom_left_radius','mobile')}px;` : '' }}
                {{ getValue('border_bottom_right_radius','mobile') ? `border-bottom-right-radius: ${getValue('border_bottom_right_radius','mobile')}px;` : '' }}
                {{ getValue('opacity','mobile') ? `opacity: ${getValue('opacity','mobile')};` : '' }}
                {{ getValue('shadow_style','mobile') == 'drop' ? `filter: drop-shadow(${getValue('shadow_x','mobile') || 0}px ${getValue('shadow_y','mobile') || 0}px ${getValue('shadow_blur','mobile') || 0}px ${getValue('shadow_color','mobile') || 'rgb(255, 255, 255)'});` : ''}}
                {{ getValue('shadow_style','mobile') == 'box' ? `box-shadow: ${getValue('shadow_type','mobile') == 'inset' ? 'inset' : ''} ${getValue('shadow_x','mobile') || 0}px ${getValue('shadow_y','mobile') || 0}px ${getValue('shadow_spread','mobile') || 0}px ${getValue('shadow_blur','mobile') || 0}px ${getValue('shadow_color','mobile') || 'rgb(255, 255, 255)'};` : ''}}
            }
        } 
        @media (max-width: 991px) and (min-width: 768px){ 
            #element-{{ node.uuid }} .sg_collection-toolbar-wrapper {
                width: {{!isNaN(getValue('width','tablet'))?`${getValue('width','tablet')}${getValue('typeWidth','tablet')||'px'}`:'auto'}}!important;
                height: {{getValue('height','tablet')||0}}px!important;
                line-height: {{getValue('height','tablet')||0}}px!important;
            }
            #element-{{ node.uuid }} .sg_product-quantity-wrapper {
                color: {{getValue('quantity_color','tablet')}}!important;
                font-size: {{getValue('quantity_font_size','tablet')}}px!important;
                font-family: {{getValue('quantity_font_family','tablet')?`${getValue('quantity_font_family','tablet')}!important`:''}};
            }
            #element-{{ node.uuid }} select {
                color: {{getValue('select_color','tablet')}}!important;
                font-size: {{getValue('select_font_size','tablet')}}px!important;
                font-family: {{getValue('select_font_family','tablet')?`${getValue('select_font_family','tablet')}!important`:''}};
            }
            

            #element-{{ node.uuid }}  {
                
                display: {{ tablet.display == 'none' ? 'none' : 'block' }} !important;
                margin: {{ getValue('margin_top','tablet') }}px {{ getValue('margin_right','tablet') }}px {{ getValue('margin_bottom','tablet') }}px {{ getValue('margin_left','tablet') }}px;
                padding-top: {{`${getValue('padding_top','tablet')}px`}};
                padding-right: {{`${getValue('padding_right','tablet')}px`}};
                padding-bottom: {{`${getValue('padding_bottom','tablet')}px`}};
                padding-left: {{`${getValue('padding_left','tablet')}px`}};
                {{ getValue('background_type','tablet') == 'color' && getValue('background_color','tablet') ? `background-color: ${getValue('background_color','tablet')};` : ''}}
                {{ getValue('background_type','tablet') == 'image' && getValue('background_image','tablet') ? `background-image: url(${getValue('background_image','tablet')});` : ''}}
                {{ getValue('background_type','tablet') == 'image' && getValue('background_size','tablet') ? `background-size: ${getValue('background_size','tablet')};` : ''}}
                {{ getValue('background_type','tablet') == 'image' && getValue('background_position','tablet') ? `background-position: ${getValue('background_position','tablet')};` : ''}}
                {{ getValue('background_type','tablet') == 'image' && getValue('background_repeat','tablet') ? `background-repeat: ${getValue('background_repeat','tablet')};` : ''}}
                {{ getValue('background_type','tablet') == 'image' && getValue('background_attachment','tablet') ? `background-attachment: ${getValue('background_attachment','tablet')};` : ''}}
                {{ getValue('border_style','tablet') ? `border-style: ${getValue('border_style','tablet')};` : '' }}
                {{ getValue('border_color','tablet') ? `border-color: ${getValue('border_color','tablet')};` : '' }}
                {{ getValue('border_width','tablet') ? `border-width: ${getValue('border_width','tablet')}px;` : '' }}
                {{ getValue('border_top_left_radius','tablet') ? `border-top-left-radius: ${getValue('border_top_left_radius','tablet')}px;` : '' }}
                {{ getValue('border_top_right_radius','tablet') ? `border-top-right-radius: ${getValue('border_top_right_radius','tablet')}px;` : '' }}
                {{ getValue('border_bottom_left_radius','tablet') ? `border-bottom-left-radius: ${getValue('border_bottom_left_radius','tablet')}px;` : '' }}
                {{ getValue('border_bottom_right_radius','tablet') ? `border-bottom-right-radius: ${getValue('border_bottom_right_radius','tablet')}px;` : '' }}
                {{ getValue('opacity','tablet') ? `opacity: ${getValue('opacity','tablet')};` : '' }}
                {{ getValue('shadow_style','tablet') == 'drop' ? `filter: drop-shadow(${getValue('shadow_x','tablet') || 0}px ${getValue('shadow_y','tablet') || 0}px ${getValue('shadow_blur','tablet') || 0}px ${getValue('shadow_color','tablet') || 'rgb(255, 255, 255)'});` : ''}}
                {{ getValue('shadow_style','tablet') == 'box' ? `box-shadow: ${getValue('shadow_type','tablet') == 'inset' ? 'inset' : ''} ${getValue('shadow_x','tablet') || 0}px ${getValue('shadow_y','tablet') || 0}px ${getValue('shadow_spread','tablet') || 0}px ${getValue('shadow_blur','tablet') || 0}px ${getValue('shadow_color','tablet') || 'rgb(255, 255, 255)'};` : ''}}
            }
        } 
        @media (max-width: 1199px) and (min-width: 992px){ 
            #element-{{ node.uuid }} .sg_collection-toolbar-wrapper {
                width: {{!isNaN(getValue('width','laptop'))?`${getValue('width','laptop')}${getValue('typeWidth','laptop')||'px'}`:'auto'}}!important;
                height: {{getValue('height','laptop')||0}}px!important;
                line-height: {{getValue('height','laptop')||0}}px!important;
            }
            #element-{{ node.uuid }} .sg_product-quantity-wrapper {
                color: {{getValue('quantity_color','laptop')}}!important;
                font-size: {{getValue('quantity_font_size','laptop')}}px!important;
                font-family: {{getValue('quantity_font_family','laptop')?`${getValue('quantity_font_family','laptop')}!important`:''}};
            }
            #element-{{ node.uuid }} select {
                color: {{getValue('select_color','laptop')}}!important;
                font-size: {{getValue('select_font_size','laptop')}}px!important;
                font-family: {{getValue('select_font_family','laptop')?`${getValue('select_font_family','laptop')}!important`:''}};
            }


            #element-{{ node.uuid }}  {
                
                display: {{ laptop.display == 'none' ? 'none' : 'block' }} !important;
                margin: {{ getValue('margin_top','laptop') }}px {{ getValue('margin_right','laptop') }}px {{ getValue('margin_bottom','laptop') }}px {{ getValue('margin_left','laptop') }}px;
                padding-top: {{`${getValue('padding_top','laptop')}px`}};
                padding-right: {{`${getValue('padding_right','laptop')}px`}};
                padding-bottom: {{`${getValue('padding_bottom','laptop')}px`}};
                padding-left: {{`${getValue('padding_left','laptop')}px`}};
                {{ getValue('background_type','laptop') == 'color' && getValue('background_color','laptop') ? `background-color: ${getValue('background_color','laptop')};` : ''}}
                {{ getValue('background_type','laptop') == 'image' && getValue('background_image','laptop') ? `background-image: url(${getValue('background_image','laptop')});` : ''}}
                {{ getValue('background_type','laptop') == 'image' && getValue('background_size','laptop') ? `background-size: ${getValue('background_size','laptop')};` : ''}}
                {{ getValue('background_type','laptop') == 'image' && getValue('background_position','laptop') ? `background-position: ${getValue('background_position','laptop')};` : ''}}
                {{ getValue('background_type','laptop') == 'image' && getValue('background_repeat','laptop') ? `background-repeat: ${getValue('background_repeat','laptop')};` : ''}}
                {{ getValue('background_type','laptop') == 'image' && getValue('background_attachment','laptop') ? `background-attachment: ${getValue('background_attachment','laptop')};` : ''}}
                {{ getValue('border_style','laptop') ? `border-style: ${getValue('border_style','laptop')};` : '' }}
                {{ getValue('border_color','laptop') ? `border-color: ${getValue('border_color','laptop')};` : '' }}
                {{ getValue('border_width','laptop') ? `border-width: ${getValue('border_width','laptop')}px;` : '' }}
                {{ getValue('border_top_left_radius','laptop') ? `border-top-left-radius: ${getValue('border_top_left_radius','laptop')}px;` : '' }}
                {{ getValue('border_top_right_radius','laptop') ? `border-top-right-radius: ${getValue('border_top_right_radius','laptop')}px;` : '' }}
                {{ getValue('border_bottom_left_radius','laptop') ? `border-bottom-left-radius: ${getValue('border_bottom_left_radius','laptop')}px;` : '' }}
                {{ getValue('border_bottom_right_radius','laptop') ? `border-bottom-right-radius: ${getValue('border_bottom_right_radius','laptop')}px;` : '' }}
                {{ getValue('opacity','laptop') ? `opacity: ${getValue('opacity','laptop')};` : '' }}
                {{ getValue('shadow_style','laptop') == 'drop' ? `filter: drop-shadow(${getValue('shadow_x','laptop') || 0}px ${getValue('shadow_y','laptop') || 0}px ${getValue('shadow_blur','laptop') || 0}px ${getValue('shadow_color','laptop') || 'rgb(255, 255, 255)'});` : ''}}
                {{ getValue('shadow_style','laptop') == 'box' ? `box-shadow: ${getValue('shadow_type','laptop') == 'inset' ? 'inset' : ''} ${getValue('shadow_x','laptop') || 0}px ${getValue('shadow_y','laptop') || 0}px ${getValue('shadow_spread','laptop') || 0}px ${getValue('shadow_blur','laptop') || 0}px ${getValue('shadow_color','laptop') || 'rgb(255, 255, 255)'};` : ''}}
            }
        } 
        @media (min-width: 1200px){ 
            #element-{{ node.uuid }} .sg_collection-toolbar-wrapper {
                width: {{!isNaN(desktop.width)?`${desktop.width}${desktop.typeWidth||'px'}`:'auto'}}!important;
                height: {{desktop.height||0}}px!important;
                line-height: {{desktop.height||0}}px!important;
            }
            #element-{{ node.uuid }} .sg_product-quantity-wrapper {
                color: {{desktop.quantity_color}}!important;
                font-size: {{desktop.quantity_font_size}}px!important;
                font-family: {{desktop.quantity_font_family?`${desktop.quantity_font_family}!important`:''}};
            }
            #element-{{ node.uuid }} select {
                color: {{desktop.select_color}}!important;
                font-size: {{desktop.select_font_size}}px!important;
                font-family: {{desktop.select_font_family?`${desktop.select_font_family}!important`:''}};
            }

            #element-{{ node.uuid }}  {
                display: {{ desktop.display == 'none' ? 'none' : 'block' }} !important;
                margin: {{ getValue('margin_top','desktop') }}px {{ getValue('margin_right','desktop') }}px {{ getValue('margin_bottom','desktop') }}px {{ getValue('margin_left','desktop') }}px;
                padding-top: {{`${getValue('padding_top','desktop')}px`}};
                padding-right: {{`${getValue('padding_right','desktop')}px`}};
                padding-bottom: {{`${getValue('padding_bottom','desktop')}px`}};
                padding-left: {{`${getValue('padding_left','desktop')}px`}};
                {{ desktop.background_type == 'color' && desktop.background_color ? `background-color: ${desktop.background_color};` : ''}}
                {{ desktop.background_type == 'image' && desktop.background_image ? `background-image: url(${desktop.background_image});` : ''}}
                {{ desktop.background_type == 'image' && desktop.background_size ? `background-size: ${desktop.background_size};` : ''}}
                {{ desktop.background_type == 'image' && desktop.background_position ? `background-position: ${desktop.background_position};` : ''}}
                {{ desktop.background_type == 'image' && desktop.background_repeat ? `background-repeat: ${desktop.background_repeat};` : ''}}
                {{ desktop.background_type == 'image' && desktop.background_attachment ? `background-attachment: ${desktop.background_attachment};` : ''}}
                {{ desktop.border_style ? `border-style: ${desktop.border_style};` : '' }}
                {{ desktop.border_color ? `border-color: ${desktop.border_color};` : '' }}
                {{ desktop.border_width ? `border-width: ${desktop.border_width}px;` : '' }}
                {{ desktop.border_top_left_radius ? `border-top-left-radius: ${desktop.border_top_left_radius}px;` : '' }}
                {{ desktop.border_top_right_radius ? `border-top-right-radius: ${desktop.border_top_right_radius}px;` : '' }}
                {{ desktop.border_bottom_left_radius ? `border-bottom-left-radius: ${desktop.border_bottom_left_radius}px;` : '' }}
                {{ desktop.border_bottom_right_radius ? `border-bottom-right-radius: ${desktop.border_bottom_right_radius}px;` : '' }}
                {{ desktop.opacity ? `opacity: ${desktop.opacity};` : '' }}
                {{ desktop.shadow_style == 'drop' ? `filter: drop-shadow(${desktop.shadow_x || 0}px ${desktop.shadow_y || 0}px ${desktop.shadow_blur || 0}px ${desktop.shadow_color || 'rgb(255, 255, 255)'});` : ''}}
                {{ desktop.shadow_style == 'box' ? `box-shadow: ${desktop.shadow_type == 'inset' ? 'inset' : ''} ${desktop.shadow_x || 0}px ${desktop.shadow_y || 0}px ${desktop.shadow_spread || 0}px ${desktop.shadow_blur || 0}px ${desktop.shadow_color || 'rgb(255, 255, 255)'};` : ''}}
            }
        }
        </v-style>
        <v-script>
            $(document).ready(function () {
              $('#element-{{ node.uuid }} .sg_collection-filter').on('change', function() {
                var value = $(this).val();
                let new_url = "";
                if(value=='blank'){
                  var re2 = new RegExp("variant=\\d+");
                  new_url = window.location.href.replace(re2, '');
                  new_url = new_url.replace('?&','?');
                  new_url = new_url.replace('&&','&');
                  if(new_url.split('?').length>1 && new_url.split('?')[new_url.split('?').length-1]==""){
                    new_url = new_url.substring(0, new_url.length - 1);
                  }
                  if(new_url.split('&').length>1 && new_url.split('&')[new_url.split('&').length-1]==""){
                    new_url = new_url.substring(0, new_url.length - 1);
                  }
                  
                }else if (window.location.search && window.location.search.indexOf('variant=') != -1) {
                  new_url = window.location.href.replace( /variant=\w*\d*/, "variant=" + value);
                }else if (window.location.search) {
                  new_url = window.location.href + "&variant=" + value;
                } else {
                  new_url = window.location.href + "?variant=" + value;
                }
                window.location.href = new_url;
              });

              $('#element-{{ node.uuid }} .sg_sort-collection').on('change', function() {
                var value = $(this).val();
                let new_url = "";
                if(value=='blank'){
                  var re = new RegExp("sort_by=.*");
                  new_url = window.location.href.replace(re, '');
                  new_url = new_url.replace('?&','?');
                  new_url = new_url.replace('&&','&');
                  if(new_url.split('?').length>1 && new_url.split('?')[new_url.split('?').length-1]==""){
                    new_url = new_url.substring(0, new_url.length - 1);
                  }
                  if(new_url.split('&').length>1 && new_url.split('&')[new_url.split('&').length-1]==""){
                    new_url = new_url.substring(0, new_url.length - 1);
                  }
                }else if (window.location.search && window.location.search.indexOf('sort_by=') != -1) {
                  new_url = window.location.href.replace( /sort_by=.*/, "sort_by=" + value);
                }else if (window.location.search) {
                  new_url = window.location.href + "&sort_by=" + value;
                } else {
                  new_url = window.location.href + "?sort_by=" + value;
                }
                window.location.href = new_url;
              });
            })
        </v-script>
  </div>
</template>
  
  <script>
import ToolBar from "../../elements/utils/ToolBar.vue";

import elementStyleMixin from "../../elements/elementStyleMixin";
import ExtraClass from "../../elements/otherSettings/ExtraClass.vue";
import AlignmentBase from "../../elements/styleSettings/AlignmentBase.vue";
import ToolbarTypography from "./settings/ToolbarTypography.vue";
import ToolbarDimension from "./settings/ToolbarDimension.vue";
import ToolbarDisplay from "./settings/ToolbarDisplay.vue";

import Visibility from "../../elements/designSettings/Visibility.vue";
import Spacing from "../../elements/designSettings/Spacing.vue";
import Background from "../../elements/designSettings/Background.vue";
import Border from "../../elements/designSettings/Border.vue";
import Corner from "../../elements/designSettings/Corner.vue";
import Shadow from "../../elements/designSettings/Shadow.vue";
import Opacity from "../../elements/designSettings/Opacity.vue";
import { designStyles, otherProps, otherStyles, actionTypes } from "@/constants";
import CustomCSS from '../../elements/styleSettings/CustomCSS.vue';
import Animations from '../../elements/designSettings/Animations.vue';


export default {
  components: { ToolBar },
  data() {
    return {
      isHovering: false,
    };
  },
  watch: {
    dragging: function (newValue, oldValue) {
      var $iframe = $("#designEditor").contents();
      var $element = $iframe.find(`#element-${this.node.uuid}`);
      var $content = $element.find(`.sg_collection-toolbar-wrapper`);
      if (newValue && newValue == this.node.uuid) {
        setTimeout(() => {
          $element.css("padding", "0px 0px 0px 0px");
          $element.css("margin", "0px 0px 0px 0px");
          $content.hide();
        }, 100);
      } else if (oldValue == this.node.uuid) {
        $element.css("padding", "");
        $element.css("margin", "");
        $content.show();
      }
    },
  },

  inject: ["node", "editor"],
  mixins: [elementStyleMixin],
  props: {
    ...otherProps,
    extra_class: String,
    component: String,
    desktop: Object,
    laptop: Object,
    mobile: Object,
    tablet: Object,
    notice: String,
    quantity_text: String,
    filter_text: String,
    sorting_text: String,
    show_quantity:Boolean
  },
  computed: {
    dragging: function () {
      return this.editor?.draggedNode?.uuid
    },
  },
  craft: {
    defaultProps: {
      ...otherStyles,
      notice:
        "This element will not show correct information until a collection is picked in a Product List.",
      component: "(C) Toolbar",
      filter_text:"Filter",
      sorting_text:"Sorting",
      show_quantity:true,
      quantity_text:'%number% products',
      desktop: {
        ...designStyles,
        typeWidth: "%",
        width: 100,
        height:50,
        quantity_color:'rgb(61,66,70)',
        quantity_font_size:16,
        select_color:'rgb(61,66,70)',
        select_font_size:16,
        alignment:'center'
      },
      laptop: {
        ...designStyles,

      },
      tablet: {
        ...designStyles,

      },
      mobile: {
        ...designStyles,

      },
      elementStyle: {},
    },
    settings: {
      settings: {
        "Display":{"Display":ToolbarDisplay, multi_screen: true},
        "Dimension": { "Dimension":ToolbarDimension, multi_screen: true },
        Typography: { "Typography":ToolbarTypography, multi_screen: true },
        Alignment: { Alignment: AlignmentBase, multi_screen: true },
        "Extra Class": ExtraClass, "Custom CSS": CustomCSS,
      },
      design: {
        Visibility,
        Spacing: { Spacing, multi_screen: true },
        Background: { Background, multi_screen: true },
        Border: { Border, multi_screen: true },
        Corner: { Corner, multi_screen: true },
        Shadow: { Shadow, multi_screen: true },
        Opacity: { Opacity, multi_screen: true },
      },
      others: {
        Animations: { Animations, actions: actionTypes }
      }
    },
  },
};
</script>
  
<style lang="scss" scoped>
</style>
  