<template>
  <div
    class="sg-icon-list-item"
    :id="`element-item-${node.uuid}`"
  >
    <slot />
  </div>
</template>

<script>
import elementStyleMixin from '../../elements/elementStyleMixin';
import ToolBar from '../../elements/utils/ToolBar.vue';

export default {
  components: {ToolBar},
  mixins: [elementStyleMixin],
  inject: [
    'node',
    'editor'
  ],
  props: {
    component:String,
    
    item: Object,
  },
  data() {
    return {
      isHovering: false,
    }
  },
  craft: {
    defaultProps: {
      elementStyle: {

      },
    },
    settings: {
      settings: {
      },
      design: {
      }
    },
  },
};
</script>

<style lang="scss" scoped>

.dragging{
  position:absolute;
  top:0;
  bottom: 0;
  left: 0;
  right:0;
  background: greenyellow;
  z-index: 10;
}

</style>
