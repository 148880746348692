<template>
  <div label-position="top" size="" :model="elementProps">
    <div class="d-flex flex-column py-2">
      <span style="color: #AAAAAA; font-size: 12px;">Icon Spacing</span>
      <Slider 
        class="mt-2"
        :primaryColor="primaryColor"
        unit="px"
        :min="0"
        :max="150"
        :step="1"
        v-bind:changeValue="($event) => updateProps('icon_spacing', $event)"
        :value="Number.parseInt(elementProps.icon_spacing)"
        :onlyInteger="true"
      />
    </div>
  </div>
</template>

<script>
import mixin from "../../../elements/styleSettings/mixin";
import Slider from "../../../elements/common/Slider.vue";
export default {
  mixins: [mixin],
  components: { Slider },
  props: {
    primaryColor: String
  },
  methods: {
    updateProps(name, value) {
      if(value ==  this.elementProps[name]) return;

      this.elementPropsSetter({ [name]: value });
    },
  },
};
</script>

<style scoped>
</style>
