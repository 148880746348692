<template>
  <div label-position="top" size="mini" :model="elementProps">
    <div class="d-flex flex-column py-2">
      <span class="setting-title">Style</span>
      <Select
        class="mt-2"
        :value="elementProps.display_style"
        :primaryColor="primaryColor"
        v-bind:changeValue="($event) => updateProps2('display_style', $event)"
        :options="[
          {
            value: 'default',
            label: 'Default',
          },
          {
            value: 'slider',
            label: 'Slider',
          },
        ]"
      />
    </div>
    <div class="d-flex flex-column py-2" v-if="elementProps.display_style == 'default'">
      <span class="setting-title">Image Width</span>
      <el-input
        :value="getValue('width',elementProps[screen].width)"
        class="input-with-select"
        @input="($event) => updateProps('width', $event)"
      >
        <el-select
          slot="append"
          placeholder="-"
          :value="getValue('width_type',elementProps[screen].width_type)"
          @change="updateProps('width_type', $event)"
          size="medium"
        >
          <el-option label="px" value="px"></el-option>
          <el-option label="%" value="%"></el-option>
        </el-select>
      </el-input>
    </div>
    <div class="d-flex flex-column py-2" v-if="elementProps.display_style == 'default'">
      <span class="setting-title">Image Height</span>
      <el-input
        :value="getValue('height',elementProps[screen].height)"
        @input="($event) => updateProps('height', $event)"
        class="mt-2"
        size="medium"
      />
    </div>
    <div class="d-flex flex-column py-2" v-if="elementProps.display_style == 'default'">
      <span class="setting-title">Image Spacing</span>
      <Slider
        class="mt-2"
        :value="getValue('image_spacing',elementProps[screen].image_spacing)"
        :primaryColor="primaryColor"
        v-bind:changeValue="
          ($event) => updateProps3('image_spacing', $event)
        "
        unit="px"
        :max="15"
      />
    </div>
    <div class="d-flex flex-column py-2">
      <span class="setting-title">Columns</span>
      <Slider
        class="mt-2"
        :value="getValue('columns',elementProps[screen].columns)"
        :primaryColor="primaryColor"
        v-bind:changeValue="($event) => updateProps('columns', $event)"
        :max="6"
      />
    </div>
    <div class="d-flex align-items-center justify-content-between py-2" v-if="elementProps.display_style == 'slider'">
      <span class="sgSubTitle">Dots Navigation</span>
      <el-switch
        :value="getValue('show_dots',elementProps[screen].show_dots)"
        :active-color="primaryColor"
        inactive-color="#949494"
        @change="updateProps('show_dots', $event)"
      />
    </div>
    <div class="d-flex align-items-center justify-content-between py-2" v-if="elementProps.display_style == 'slider'">
      <span class="sgSubTitle">Next/Prev Buttons</span>
      <el-switch
        :value="getValue('show_pre_next',elementProps[screen].show_pre_next)"
        :active-color="primaryColor"
        inactive-color="#949494"
        @change="updateProps('show_pre_next', $event)"
      />
    </div>
    <!-- <div class="py-2" v-if="elementProps.display_style == 'slider'">
      <div class="d-flex align-items-center justify-content-between">
        <span class="sgSubTitle">Loop</span>
        <el-switch
          :value="elementProps.loop"
          :active-color="primaryColor"
          inactive-color="#949494"
          @change="updateProps2('loop', $event)"
        />
      </div>
      <div class="sgDesc mt-2">*Works on live page*</div>
    </div> -->
    <div class="d-flex flex-column py-2" v-if="elementProps.display_style == 'slider'">
      <span class="setting-title">Spacing</span>
      <Slider
        class="mt-2"
        :value="getValue('spacing',elementProps[screen].spacing)"
        :primaryColor="primaryColor"
        v-bind:changeValue="($event) => updateProps('spacing', $event)"
        unit="px"
        :max="15"
      />
    </div>
  </div>
</template>

<script>
import mixin from "../../../elements/styleSettings/mixin";
import Select from "../../../elements/common/Select.vue";
import Slider from "../../../elements/common/Slider.vue";

export default {
  mixins: [mixin],
  components: { Select, Slider },
  props: {
    screen: String,
    primaryColor: String,
  },
  methods: {
    updateProps(name, value) {
      const screen = this.$props.screen
      const data_screen = Object.assign({}, this.elementProps[screen])
      if(value == data_screen[name]) return;
      data_screen[name] = value      
      this.elementPropsSetter({ [screen]: data_screen });
    },
    updateProps2(name, value) {
      if(value ==  this.elementProps[name]) return;
      this.elementPropsSetter({ [name]: value });
    },
    updateProps3(name, value) {
      const screen = this.$props.screen
      const data_screen = Object.assign({}, this.elementProps[screen])
      if(value == data_screen[name]) return;
      data_screen[name] = value     
      data_screen["margin_right"] = value      
      data_screen['margin_bottom'] = value      
      this.elementPropsSetter({ [screen]: data_screen });
    },
  },
};
</script>

<style scoped>
</style>
