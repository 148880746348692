<template>
  <div label-position="top" size="mini" :model="elementProps">
    <div class="py-2">
      <span class="setting-title">Height</span>
      <Input 
        class="mt-2"
        :value="getValue('height', elementProps[screen].height)"
        v-bind:changeValue="($event) => updateProps('height', $event)"
        type="number"
        :min="0"
        :primaryColor="primaryColor"
      />
    </div>
    <div class="py-2">
      <span class="setting-title">Zoom</span>
      <Slider
        class="mt-2"
        :step="1"
        :primaryColor="primaryColor"
        :value="getValue('zoom', elementProps[screen].zoom)"
        v-bind:changeValue="($event) => updateProps('zoom', parseFloat($event))"
        unit=""
        :max="25"
        :min="0"
        :onlyInteger="true"
      />
    </div>
  </div>
</template>

<script>

import mixin from '../../../elements/styleSettings/mixin';
import Slider from '../../../elements/common/Slider.vue';
import Input from '../../../elements/common/Input.vue';

export default {
  mixins: [mixin],
  props: {
    screen: String,
    primaryColor: String,
  },
  components: {
    Slider,
    Input,
  },
  methods: {
    updateProps(name, value) {
      const screen = this.$props.screen;
      const data_screen = Object.assign({}, this.elementProps[screen]);
      if (value == data_screen[name]) return;
      data_screen[name] = value;
      var obj = {
        [screen]: data_screen,
      };
      this.elementPropsSetter(obj);
    },
  },
};
</script>

<style>
</style>
