import cloneDeep from 'lodash/cloneDeep';
import { v4 as uuidv4 } from 'uuid';

class Node {
  constructor(componentName, props = {}, parent = null, children = [], rules = {}, addition = {}, editor) {
    this.componentName = componentName;
    this.props = props;
    this.parent = parent;
    this.children = children;
    this.rules = rules;
    this.addition = addition;
    this.uuid = uuidv4();
    this.editor = editor;
  }

  setProps(change, unSave) {
    var isChange = this.checkChange(change)
    var keys = Object.keys(change)
    for(var i =0; i< keys.length; i++){
      var key = keys[i];
      if(change[key] != this.props[key]){
        isChange = true
        break
      }
    }
    let nodes = this.editor.export()
    let popups = this.editor.exportPopup()
    let inversePatches = {nodes,popups}
    this.props = { ...this.props, ...change };
    if(isChange && !unSave){
      var productNode = this.checkChildProductList(this);
      if(productNode){
        var currentList = productNode.parent;
        for (var i = 0; i < currentList.children.length; i++) {
          const product = currentList.children[i]
          if (product.uuid == productNode.uuid) continue;
          const node2 = productNode.duplicate()
          product.children = node2.children;
        }
      }
      this.changeProductListItem();
      let patches = {nodes:this.editor.export(), popups: this.editor.exportPopup()};
      this.editor.add(patches, inversePatches)
    }
  }

  checkChildProductList(node) {
    var parent = node.parent
    if (parent) {
      if (["ProductList", "RelatedProduct"].includes(parent.componentName)) {
        return node
      } else {
        return this.checkChildProductList(parent)
      }
    } else {
      return null
    }
  }

  changeProductListItem(change){
    if(['ProductList','RelatedProduct'].includes(this?.parent?.parent?.componentName)){
      var changePossition = this.parent.children.findIndex((x) => x.uuid == this.uuid);
      for (var i = 0; i < this?.parent?.parent?.children?.length; i++) {
        const product = this?.parent?.parent.children[i];
        const changeNode = product.children[changePossition]
        changeNode.props = {...this.props, ...change}
      }
    }
  }

  checkChange(change){
    var keys = Object.keys(change||{})
    

    for(var i =0; i< keys.length; i++){
      
      var key = keys[i];
      // console.log(change[key])
      // console.log(this.props[key],'name')
      // console.log(change[key] != this.props[key])

      if(typeof change[key] == 'object'){
        var isChange = this.checkChange(change[key])
        if(isChange){
          return true
        }
      }else if(change[key] != this.props[key]){
        return true
      }
    }
    return false
  }

  makeOrphan() {
    const {editor} = this
    const node = editor?.findNode(this.uuid) || this;
    const parent = node?.parent ? editor?.findNode(node?.parent?.uuid): null;
    if(["Popup","FloatingButton"].includes(node.componentName)){
      const index = editor.popups.indexOf(this);
      editor.popups.splice(index, 1);
    }else{
      if (!parent) {
        return;
      }
      const index = parent.children.indexOf(node);
      parent.children.splice(index, 1);
      node.parent = null;
    }
  }

  setParent(parent) {
    if (!parent.isDroppable(this)) {
      throw new Error('Parent node is not droppable.');
    }

    this.makeOrphan();

    parent.children.push(this);
    this.parent = parent;
  }

  inCanvas() {
    let curentParent = this.parent;

    while (curentParent) {
      if (curentParent.isCanvas()) {
        return true;
      }
      curentParent = curentParent.parent;
    }

    return false;
  }

  isDraggable() {
    if (!this.inCanvas()) {
      return false;
    }

    if (this.rules.canDrag) {
      return this.rules.canDrag(this);
    }

    return true;
  }

  isAncestor(node) {
    let curentParent = this.parent;

    while (curentParent) {
      if (curentParent === node) {
        return true;
      }
      curentParent = curentParent.parent;
    }

    return false;
  }

  isDroppable(incommingNode) {
    if (this.props.component == 'Container') {
      if (incommingNode.props.component == 'ContainerChild') {
        return true
      }
      return false;
    }
    if (this.props.component == 'Row') {
      if (incommingNode.props.component == 'Column') {
        return true
      }
      return false;
    }
    if (!this.isCanvas()) {
      return false;
    }

    if (incommingNode === this) {
      return false;
    }

    if (incommingNode === null) {
      return;
    }

    if (incommingNode.parent
      && incommingNode.parent.rules.canMoveOut
      && !incommingNode.parent.rules.canMoveOut(incommingNode, incommingNode.parent)) {
      return false;
    }

    if (this.isAncestor(incommingNode)) {
      return false;
    }

    if (this.rules.canMoveIn) {
      return this.rules.canMoveIn(incommingNode, this);
    }

    return true;
  }

  isCanvas() {
    if (["Canvas","Product", "Banner", "Parallax", "PricingItem", "AccordionItem",
          "Popup", "CarouselItem","Article","ContactForm", "HeaderButton",
          "Newsletter",'ContactQRForm','QRForm','IconHozItem', "TabContent",
        "CollectionBanner"].includes(this.componentName)) {
      return true;
    }

    return false;
  }


  append(incommingNode, unSave) {
    if (!this.isDroppable(incommingNode)) {
      throw new Error(`${this.componentName} is not droppable with the incommingNode - ${incommingNode.componentName}.`);
    }

    let nodes = this.editor.export()
    let popups = this.editor.exportPopup()
    let inversePatches = {nodes,popups}
    incommingNode.makeOrphan();
    this.children.push(incommingNode);
    // eslint-disable-next-line no-param-reassign
    incommingNode.parent = this;
    
    let patches = {nodes:this.editor.export(), popups: this.editor.exportPopup()};
    if(!unSave){
      this.editor.add(patches, inversePatches)
    }else{
      this.editor.setLevelNodes(this.editor.nodes);
      this.editor.setLevelNodes(this.editor.popups);
    }
  }

  prepend(incommingNode, unSave) {
    if (!this.isDroppable(incommingNode)) {
      throw new Error(`${this.componentName} is not droppable with the incommingNode - ${incommingNode.componentName}.`);
    }

    let nodes = this.editor.export()
    let popups = this.editor.exportPopup()
    let inversePatches = {nodes,popups}
    incommingNode.makeOrphan();
    this.children.splice(0, 0, incommingNode);
    // eslint-disable-next-line no-param-reassign
    incommingNode.parent = this;
    let patches = {nodes:this.editor.export(), popups: this.editor.exportPopup()};
    if(!unSave){
      this.editor.add(patches, inversePatches)
    }else{
      this.editor.setLevelNodes(this.editor.nodes);
      this.editor.setLevelNodes(this.editor.popups);
    }
  }

  canBeSibling(targetNode) {
    if (targetNode === this) {
      return false;
    }

    if (!targetNode.parent) {
      return false;
    }
    return targetNode.parent.isDroppable(this);
  }

  insertBefore(targetNode, unSave) {
    if (!this.canBeSibling(targetNode)) {
      throw new Error('Can not be the sibling of the target node.');
    }
    let nodes = this.editor.export()
    let popups = this.editor.exportPopup()
    let inversePatches = {nodes,popups}
    this.makeOrphan();
    const parentOfTargetNode = targetNode.parent;
    const indexOfTargetNode = parentOfTargetNode.children.indexOf(targetNode);
    parentOfTargetNode.children.splice(indexOfTargetNode, 0, this);
    this.parent = parentOfTargetNode;
    let patches = {nodes:this.editor.export(), popups: this.editor.exportPopup()};
    if(!unSave){
      this.editor.add(patches, inversePatches)
    }else{
      this.editor.setLevelNodes(this.editor.nodes);
      this.editor.setLevelNodes(this.editor.popups);
    }
  }

  insertAfter(targetNode, unSave) {
    if (!this.canBeSibling(targetNode)) {
      throw new Error('Can not be the sibling of the target node.');
    }
    let nodes = this.editor.export()
    let popups = this.editor.exportPopup()
    let inversePatches = {nodes,popups}
    this.makeOrphan();
    const parentOfTargetNode = targetNode.parent;
    const indexOfTargetNode = parentOfTargetNode.children.indexOf(targetNode);
    parentOfTargetNode.children.splice(indexOfTargetNode + 1, 0, this);
    this.parent = parentOfTargetNode;
    let patches = {nodes:this.editor.export(), popups: this.editor.exportPopup()};
    if(!unSave){
      this.editor.add(patches, inversePatches)
    }else{
      this.editor.setLevelNodes(this.editor.nodes);
      this.editor.setLevelNodes(this.editor.popups);
    }
  }

  serialize() {
    var content_editor = {}
    if (['Heading','Paragraph'].includes(this.componentName)){
      var $iframe = $("#designEditor").contents();
      var currenJquery = $iframe.find(`#element-${this.uuid}`);
      var children = currenJquery.children();
      if(children.length>0){
        content_editor = {content: children[0].innerHTML}
      }
    }
    return {
      componentName: this.componentName,
      props: {...this.props,...content_editor},
      children: this.children.map((node) => node.serialize()),
      addition: this.addition,
      uuid: this.uuid
    };
  }

  duplicate(parent) {
    var nodeClone = new Node(
      this.componentName,
      cloneDeep(this.props),
      parent?parent:this.parent,
      [],
      this.rules,
      cloneDeep(this.addition),
      this.editor
    );
    nodeClone.children = this.children.map((node) => node.duplicate(nodeClone));
    return nodeClone
  }

  duplicateWithoutChildren(parent) {
    return new Node(
      this.componentName,
      cloneDeep(this.props),
      parent?parent:this.parent,
      [],
      this.rules,
      cloneDeep(this.addition),
      this.editor
    );
  }

  // duplicateWithoutChildren(props) {
  //   return new Node(
  //     this.componentName,
  //     cloneDeep(props),
  //     null,
  //     [],
  //     this.rules,
  //     cloneDeep(this.addition),
  //     this.editor
  //   )
  // }
  
}

Node.unserialize = (editor, nodeData, parent = null) => {
  const node = new Node();
  Object.assign(node, nodeData);

  const craftConfig = editor.getCraftConfig(node);
  if (craftConfig.rules) {
    node.rules = craftConfig.rules;
  }
  if(node.uuid=='reset'){
    node.uuid = uuidv4();
  }
  node.parent = parent;
  node.children = nodeData.children.map((data) => Node.unserialize(editor, data, node));
  node.editor = editor;
  return node;
};

Node.unserialize_reset_id = (editor, nodeData, parent = null) => {
  const node = new Node();
  Object.assign(node, nodeData);

  const craftConfig = editor.getCraftConfig(node);
  if (craftConfig.rules) {
    node.rules = craftConfig.rules;
  }
  node.uuid = uuidv4();
  node.parent = parent;
  node.children = nodeData.children.map((data) => Node.unserialize_reset_id(editor, data, node));
  node.editor = editor;
  return node;
};

export default Node;
