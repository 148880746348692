<template>
  <el-form label-position="top" size="" :model="elementProps">
   <div
      class="d-flex align-items-center justify-content-between py-2"
      
    >
      <span class="sgSubTitle">Enable Sound</span>
      <el-switch
        :value="elementProps.enable_sound"
        :active-color="primaryColor"
        inactive-color="#949494"
        @change="updateProps('enable_sound', $event)"
      />
    </div>
    <div
      class="d-flex align-items-center justify-content-between py-2"
      
    >
      <span class="sgSubTitle">Autoplay</span>
      <el-switch
        :value="elementProps.autoplay"
        :active-color="primaryColor"
        inactive-color="#949494"
        @change="updateProps('autoplay', $event)"
      />
    </div>
    <div
      class="d-flex align-items-center justify-content-between py-2"
      
    >
      <span class="sgSubTitle">Loop</span>
      <el-switch
        :value="elementProps.loop"
        :active-color="primaryColor"
        inactive-color="#949494"
        @change="updateProps('loop', $event)"
      />
    </div>
    
  </el-form>
</template>

<script>
import mixin from "../../../elements/styleSettings/mixin";

export default {
  mixins: [mixin],
  components: {},
  methods: {
    updateProps(name, value) {
      if(value ==  this.elementProps[name]) return;
      this.elementPropsSetter({ [name]: value });
    },
  },
  props: {
    primaryColor: String
  }
};
</script>

<style scoped>
</style>
