<template>
  <div label-position="top" size="small" :model="elementProps">
    <div class="d-flex flex-column py-2">
      <span class="setting-title">Columns</span>
      <Slider
        :value="getValue('columns',elementProps[screen].columns)"
        :primaryColor="primaryColor"
        v-bind:changeValue="($event) => updateProps('columns', $event)"
        :max="6"
        :min="1"
        class="mt-2"
        :onlyInteger="true"
      />
    </div>
    <div class="d-flex flex-column py-2">
      <span class="setting-title">Spacing</span>
      <Slider
        :value="getValue('spacing', elementProps[screen].spacing)"
        :primaryColor="primaryColor"
        v-bind:changeValue="($event) => updateProps('spacing', $event)"
        unit="px"
        :max="50"
        :min="0"
        class="mt-2"
        :onlyInteger="true"
      />
    </div>
    <div class="d-flex flex-column py-2">
      <span class="setting-title">Stage Padding</span>
      <Slider
        :value="getValue('stage_padding', elementProps[screen].stage_padding)"
        :primaryColor="primaryColor"
        v-bind:changeValue="($event) => updateProps('stage_padding', $event)"
        :max="500"
        :min="0"
        unit="px"
        class="mt-2"
        :onlyInteger="true"
      />
      <div class="sgDesc mt-2">Part of the next slide can be seen</div>
    </div>
  </div>
</template>

<script>
import mixin from "../../../elements/styleSettings/mixin";
import Slider from "../../../elements/common/Slider.vue";
import ToggleButton from "../../../elements/common/ToggleButton.vue";

export default {
  mixins: [mixin],
  components: { Slider, ToggleButton },
  props: {
    screen: String,
    primaryColor: String,
  },
  methods: {
    updateProps(name, value) {
      const screen = this.$props.screen
      const data_screen = Object.assign({}, this.elementProps[screen])
      if(value ==  data_screen[name]) return;
      data_screen[name] = value
      this.elementPropsSetter({ [screen]: data_screen });
    },
  },
};
</script>

<style scoped>
</style>
