<template>
  <div class="item-wrapper">
    <component
      :is="component"
      v-bind="$attrs"
      :style="{ position: 'absolute', zindex:1, top:'5px', bottom:'5px', left:'5px', right:'5px', display: editor.draggedNode != null ? 'block' : 'none' }"
      @mouseup.native="handleMouseUp"
      @mousemove.native="handleMouseMove"
      @mouseup="handleMouseUp"
      @mousemove="handleMouseMove"
    >
    </component>
    <component
      :is="component"
      v-bind="$attrs"
      :draggable="true"
      :style="{ visibility: editor.draggedNode != null ? 'hidden' : 'visible' }"
      @dragstart="handleDragStart"
      @dragstart.native="handleDragStart"
      
    >
      <slot></slot>
    </component>
    
  </div>
</template>

<script>
import createNodeFromVNode from "../utils/createNodeFromVNode";

export default {
  props: {
    component: [Object, String],
  },
  inject: ["editor"],
  created() {
    if (!this.editor) {
      throw new Error("<Blueprint/> must be wrapped with <Editor/>.");
    }
  },
  mounted() {
    if (!this.$slots.blueprint) {
      throw new Error("v-slot:blueprint is required.");
    }
    if (this.$slots.blueprint.length !== 1) {
      throw new Error("v-slot:blueprint must to have only one root element.");
    }
    if (!createNodeFromVNode(this.editor, this.$slots.blueprint[0])) {
      throw new Error(
        "The element in v-slot:blueprint is not a valid vue component."
      );
    }
  },
  methods: {
    handleDragStart(event) {
      event.stopPropagation();
      const node = createNodeFromVNode(this.editor, this.$slots.blueprint[0]);
      this.editor.dragNode(node);
    },
    handleDragEnd(event) {
      // event.stopPropagation();
      // this.editor.dragNode(null);
      // this.editor.indicator.hide();
    },
    handleDragOver(event) {
      event.stopPropagation();
      let imageContainer = document.querySelector("#preview-drag");
      imageContainer.style.left = event.clientX + "px";
      imageContainer.style.top = event.clientY + "px";
      imageContainer.classList.add("show");

    },
    handleDragExit(event){
      let imageContainer = document.querySelector("#preview-drag");
      // imageContainer.style.display = 'none'
    },
    handleMouseMove(event) {
      if (this?.editor?.draggedNode) {
        event.stopPropagation();
        let imageContainer = document.querySelector("#preview-drag");
        imageContainer.style.left = event.clientX + "px";
        imageContainer.style.top = event.clientY + "px";
        imageContainer.classList.add("show");
      }
      event.stopPropagation();
    },
    handleMouseUp(event) {
      event.stopPropagation();
      event.preventDefault();
      const { editor } = this;
      if (editor?.draggedNode) {
        editor.dragNode(null);
        editor.indicator.hide();
      }
    },
  },
};
</script>
<style scoped>
.item-wrapper {
    cursor: move;
		padding: 0px;
		width: calc(50% - 5px);
		float: left;
		position: relative;
}
</style>
