<template>
  <div label-position="top" size="mini" :model="elementProps">
    <div class="d-flex flex-column py-2">
      <span class="setting-title">Image</span>
      <Upload
        :value="multi_screen ? getValue('imgUrl',elementProps[screen].imgUrl) : elementProps.imgUrl"
        v-bind:changeValue="($event) => updateProps('imgUrl', $event)"
      />
    </div>
    <div class="d-flex flex-column py-2">
      <span class="setting-title">Image Source</span>
      <Input
        class="mt-2"
        :value="multi_screen ? getValue('imgUrl',elementProps[screen].imgUrl) : elementProps.imgUrl"
        v-bind:changeValue="($event) => updateProps('imgUrl', $event)"
        :primaryColor="primaryColor"
        placeholder="Link"
      />
    </div>
  </div>
</template>

<script>
import mixin from './mixin';
import Upload from '../common/Upload.vue';
import Input from '../common/Input.vue';
export default {
  mixins: [mixin],
  props: {
    screen: String,
    multi_screen: Boolean,
    primaryColor: String,
  },
  components: {
    Upload, Input
  },
  methods: {
    updateProps(name, value) {
      if (this.$props.multi_screen) {
        const screen = this.$props.screen;
        const {desktop, laptop, tablet, mobile} = this.elementProps;
        const data_screen = Object.assign({}, this.elementProps[screen]);
        if(value ==  data_screen[name]) return;
        
        data_screen[name] = value;
        var obj = {
          [screen]: data_screen
        }
        if(screen=='desktop'){
            if(!Object.keys(laptop).includes(name)){
              laptop[name] = value
              obj['laptop'] = laptop;
            }
            if(!Object.keys(tablet).includes(name)){
              tablet[name] = value
              obj['tablet'] = tablet;
            }
            if(!Object.keys(mobile).includes(name)){
              mobile[name] = value
              obj['mobile'] = mobile;
            }
        }
        this.elementPropsSetter(obj);
      } else {
        this.elementPropsSetter({ [name]: value });
      }
    }
  }
};
</script>
