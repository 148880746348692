var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"label-position":"top","size":"mini","model":_vm.elementProps}},[_c('div',{staticClass:"d-flex flex-column py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Add your own custom CSS here")]),_c('AceEditor',{staticClass:"my-editor",attrs:{"lang":"css","theme":"monokai","width":"100%","height":"300px","options":{
          enableBasicAutocompletion: true,
          enableLiveAutocompletion: true,
          fontSize: 12,
          fontFamily: 'monospace',
          highlightActiveLine: true,
          enableSnippets: true,
          showLineNumbers: true,
          tabSize: 2,
          showPrintMargin: false,
          showGutter: true,
      }},on:{"init":_vm.editorInit},model:{value:(_vm.elementProps.custom_css),callback:function ($$v) {_vm.$set(_vm.elementProps, "custom_css", $$v)},expression:"elementProps.custom_css"}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }