<template>
  <div class="banner-description">
    <div>
      <span class="highlight">Notice: </span>
      <span>{{elementProps.notice}}</span>
    </div>
  </div>
</template>

<script>
import mixin from "../../../elements/styleSettings/mixin";
export default {
  mixins: [mixin],
  components: {
  },
};
</script>

<style scoped>
.banner-description {
  font-size: 12px;
  font-style: italic;
  color: #D6D6D6;
  display: flex;
  flex-direction: column;
  padding: 8px 0px;
}
.highlight {
  color: #FDB913;
}
</style>