var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"label-position":"top","size":"mini","model":_vm.elementProps}},[_c('div',{staticClass:"d-flex flex-column py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Position")]),_c('ToggleButton',{staticClass:"mt-2",attrs:{"primaryColor":_vm.primaryColor,"value":_vm.elementProps.position,"changeValue":($event) => _vm.updateProps('position', $event),"options":[
        {
          label: 'Sticky',
          value: 'sticky',
        },
        {
          label: 'Normal',
          value: 'relative',
        },
      ]}})],1),_c('div',{staticClass:"sgDesc"},[_vm._v("Position sticky will work on preview or live page.")])])
}
var staticRenderFns = []

export { render, staticRenderFns }