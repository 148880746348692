<template>
  <div label-position="top" size="" :model="elementProps">
    <div class="d-flex flex-column py-2">
      <span class="setting-title">Scroll Speed</span>
      <el-input
        class="mt-2"
        :value="elementProps[screen].scroll_speed"
        @input="($event) => updateProps('scroll_speed', $event)"
      />
      <div class="sgDesc mt-2">
       Transition speed. in miliseconds. Eg: 500
      </div>
    </div>
  </div>
</template>

<script>
import mixin from "../styleSettings/mixin";

export default {
  mixins: [mixin],
  components: {},
  props: {
    screen: String,
  },
  methods: {
    updateProps(name, value) {
      const screen = this.$props.screen
      const data_screen = Object.assign({}, this.elementProps[screen])
      if(value ==  data_screen[name]) return;
      data_screen[name] = value
      this.elementPropsSetter({ [screen]: data_screen });
    },
  },
};
</script>

<style scoped>
</style>
