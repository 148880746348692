var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"label-position":"top","size":"small","model":_vm.elementProps}},[_c('el-form-item',{attrs:{"label":"Width"}},[_c('el-input',{staticClass:"input-with-select",attrs:{"value":_vm.getStyle('width')},on:{"input":($event) => _vm.updateWidth($event)}},[_c('el-select',{attrs:{"slot":"append","placeholder":"-","value":_vm.elementProps.typeWidth,"size":"medium"},on:{"change":function($event){return _vm.updateProps('typeWidth', $event)}},slot:"append"},[_c('el-option',{attrs:{"label":"px","value":"px"}}),_c('el-option',{attrs:{"label":"%","value":"%"}})],1)],1)],1),_c('el-form-item',{attrs:{"label":"Alignment"}},[_c('ToggleButton',{attrs:{"value":_vm.getStyle('alignment'),"changeValue":($event) => _vm.setStyle('alignment', $event),"primaryColor":_vm.primaryColor,"options":[
        {
          label: 'Left',
          value: 'left',
        },
        {
          label: 'Center',
          value: 'center',
        },
        {
          label: 'Right',
          value: 'right',
        },
      ]}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }