<template>
  <div
    data-label="Related Product"
    data-key="related product"
    class="sg-wrap module"
    :id="`element-${node.uuid}`"
    @mouseover="isHovering = true"
    @mouseout="isHovering = false"
    :data-pid="product_id"

  >
    <div :class="`sg-module sg-related-product`">
      <div v-if="isLoading">
          Related Product Loading...
      </div>
      <div v-else-if="errors.no_product">
        Product not found. Have you uploaded products to your store?
      </div>
      <div v-else-if="errors.not_found">
        Nothing related to this product.
      </div>
      <div v-else class="sg-item-content">
        <div
          class="sg_row sg_row-no-width sg_row_no_tools sg_row-no-padding"
          :id="`r-${node.uuid}`"
          data-label="Row"
          data-icon="gpicon-row"
          data-id="1660620240932"
        >
          <div
            v-for="product in editor.findNode(node.uuid)?.children.slice(0, number_of_products)"
            :key="product.uuid"
            :class="`
              sg_column
              sg_column_related_product
              sg_col_no_tools
              sg_col-xs-${cols[mobile.products_per_row]}
              sg_col-sm-${cols[tablet.products_per_row]}
              sg_col-md-${cols[laptop.products_per_row]}
              sg_col-lg-${cols[desktop.products_per_row]}
              `
            "
            :style="{'padding': `${spacing}px !important`}"
            id="c-1660620240996"
            data-id="1660620240996"
          >
            <Node :node="product" :screen="screen"/>
            <span class="sg_add-content"
              ><span class="sg_add-content-span"></span
            ></span>
          </div>
        </div>
      </div>
    </div>

    <ToolBar
      ref="related_product"
      :toolbar_id="`related_product`"
      class="container-toolbar"
      :class="{ 'container-toolbar-show': isHovering, 'is-dragging': dragging == node.uuid }"
    >
    </ToolBar>

    <div v-if="dragging == node.uuid" class="dragging"></div>

    <v-style> {{ custom_css }}
      #element-{{ node.uuid }} .sg-module > a >.img-holder {
          height: {{image_same_height?image_height:''}}px!important;
      }

      #element-{{ node.uuid }} .sg_product-title {
          height: {{title_same_height?title_height:''}}px!important;
      }

      @media (max-width: 767px){ 
        #element-{{ node.uuid }} {
          {{ getDisplayValue('mobile') }}
          {{ getMarginValue('mobile') }}
          {{ getPaddingValue('mobile') }}
          {{ getBackgroundValue('mobile') }}
          {{ getBorderValue('mobile') }}
          {{ getBorderRadiusValue('mobile') }}
          {{ getOpacityValue('mobile') }}
          {{ getShadowValue('mobile') }}
          {{ getOtherStyle('mobile') }}
        } 
      } 
      @media (max-width: 991px) and (min-width: 768px){ 
        #element-{{ node.uuid }} {
          {{ getDisplayValue('tablet') }}
          {{ getMarginValue('tablet') }}
          {{ getPaddingValue('tablet') }}
          {{ getBackgroundValue('tablet') }}
          {{ getBorderValue('tablet') }}
          {{ getBorderRadiusValue('tablet') }}
          {{ getOpacityValue('tablet') }}
          {{ getShadowValue('tablet') }}
          {{ getOtherStyle('tablet') }}
        } 
      } 
      @media (max-width: 1199px) and (min-width: 992px){ 
        #element-{{ node.uuid }} {
          {{ getDisplayValue('laptop') }}
          {{ getMarginValue('laptop') }}
          {{ getPaddingValue('laptop') }}
          {{ getBackgroundValue('laptop') }}
          {{ getBorderValue('laptop') }}
          {{ getBorderRadiusValue('laptop') }}
          {{ getOpacityValue('laptop') }}
          {{ getShadowValue('laptop') }}
          {{ getOtherStyle('laptop') }}
        }
      } 
      @media (min-width: 1200px){ 
        #element-{{ node.uuid }} {
          {{ getDisplayValue('desktop') }}
          {{ getMarginValue('desktop') }}
          {{ getPaddingValue('desktop') }}
          {{ getBackgroundValue('desktop') }}
          {{ getBorderValue('desktop') }}
          {{ getBorderRadiusValue('desktop') }}
          {{ getOpacityValue('desktop') }}
          {{ getShadowValue('desktop') }}
          {{ getOtherStyle('desktop') }}
        }
      }
    </v-style>
  </div>
</template>

<script>
import ToolBar from "../../elements/utils/ToolBar.vue";

import elementStyleMixin from "../../elements/elementStyleMixin";
import ExtraClass from "../../elements/otherSettings/ExtraClass.vue"; import CustomCSS from '../../elements/styleSettings/CustomCSS.vue';
import SpacingProductList from "./settings/SpacingProductList.vue";
import SameHeight from "./settings/SameHeight.vue";
import ProductsPerRow from "./settings/ProductsPerRow.vue";
import NumberOfProducts from "./settings/NumberOfProducts.vue";
import OrderOfProducts from "./settings/OrderOfProducts.vue";
import Exclusions from "./settings/Exclusions.vue";
import SelectProduct from './settings/Product.vue';

import Visibility from "../../elements/designSettings/Visibility.vue";
import Spacing from "../../elements/designSettings/Spacing.vue";
import Background from "../../elements/designSettings/Background.vue";
import Border from "../../elements/designSettings/Border.vue";
import Corner from "../../elements/designSettings/Corner.vue";
import Shadow from "../../elements/designSettings/Shadow.vue";
import Opacity from "../../elements/designSettings/Opacity.vue";

import Node from "../../../../../components/Node.vue";
import Product from "./Product.vue";
import ProductImage from "./ProductImage.vue";
import ProductTitle from "./ProductTitle.vue";
import ProductPrice from "./ProductPrice.vue";
// import ProductCartButton from "./ProductCartButton.vue";
import createNodeFromVNode from "../../../../../utils/createNodeFromVNode";
import { request } from "../../../../../services";
import { designStyles, otherProps, otherStyles, actionTypes } from "@/constants";
import Cookies from 'js-cookie';
import Vue from "vue";
import Animations from '../../elements/designSettings/Animations.vue';

export default {
  components: { ToolBar, Node, Product },
  computed: {
    clonedDataChange: function () {
      return JSON.parse(
        JSON.stringify({
          number_of_products: this.number_of_products,
          desktop: {
            products_per_row: this.desktop.products_per_row
          },
          laptop: {
            products_per_row: this.laptop.products_per_row
          },
          tablet: {
            products_per_row: this.tablet.products_per_row
          },
          mobile: {
            products_per_row: this.mobile.products_per_row
          },
        })
      );
    },
        dragging: function () {
        return this.editor?.draggedNode?.uuid
        },
  },
  watch: {
    clonedDataChange: {
      handler: function (val, oldVal) {
        this.addClearDiv()
      },
      deep: true,
    },
    product_id: function(newValue, oldValue) {
      if(((!oldValue && this.node.children.length == 0) || oldValue) && (oldValue != newValue)){
        this.getProducts(newValue);
      }
    },
    dragging: function (newValue, oldValue) {
      var $iframe = $("#designEditor").contents();
      var $element = $iframe.find(`#element-${this.node.uuid}`);
      var $content = $element.find(`.sg-related-product`);
      if (newValue && newValue == this.node.uuid) {
        setTimeout(() => {
        $content.hide();
        },100);
      }else if (oldValue == this.node.uuid){
        $content.show();
      }
    },
  },
  created() {
    const product_id = this.$props.product_id;
    this.getProducts(product_id, this.node.children.length);
  },
  data() {
    return {
      isHovering: false,
      isLoading: true,
      products:[],
      "cols":{
        "1":"12",
        "2":"6",
        "3":"4",
        "4":"3",
        "6":"2",
      },
      objectScreen: [],
      user: JSON.parse(Cookies.get('user_onepage') || "{}"),
      errors: {
        no_product: false,
        not_found: false
      }
    };
  },
  methods: {
    removeAllClear(){
      const iframe = document.getElementById("designEditor");
      const productList = iframe.contentWindow.document.getElementById(
        `r-${this.node.uuid}`
      );
      const childrens = Array.from(productList.querySelectorAll('.sg_clearfix'));
      var arrRemove = []
      for(var i = 0; i<childrens.length; i++){
        arrRemove.push(childrens[i])
      }
      arrRemove.forEach(e=>e.remove())
    },
    addClearDiv(){
      setTimeout(()=>{
      this.removeAllClear();
      },200)
      setTimeout(()=>{
        const {desktop, laptop, tablet, mobile} = this;
        const iframe = document.getElementById("designEditor");
        const productList = iframe.contentWindow.document.getElementById(
          `r-${this.node.uuid}`
        );

        const childrens = Array.from(productList.querySelectorAll('.sg_column.sg_column_related_product'));
        for(var i = 0; i<childrens.length; i++){
            if (i == 0) continue;
            if (i % parseInt(desktop.products_per_row||1) == 0){
              const el = document.createElement("DIV");
              const div_clear = new Vue({
                  render(h) {
                    return h(
                      "div",
                      {class: "sg_clearfix visible-lg" });
                  },
              });
              childrens[i].parentNode.insertBefore(el, childrens[i]);
              div_clear.$mount(el);
            }

            if (i % parseInt(laptop.products_per_row||1) == 0){
              const el = document.createElement("DIV");
              const div_clear = new Vue({
                  render(h) {
                    return h(
                      "div",
                      {class: "sg_clearfix visible-md" });
                  },
              });
              childrens[i].parentNode.insertBefore(el, childrens[i]);
              div_clear.$mount(el);
            }

            if (i % parseInt(tablet.products_per_row||1) == 0){
              const el = document.createElement("DIV");
              const div_clear = new Vue({
                  render(h) {
                    return h(
                      "div",
                      {class: "sg_clearfix visible-sm" });
                  },
              });
              childrens[i].parentNode.insertBefore(el, childrens[i]);
              div_clear.$mount(el);
            }

            if (i % parseInt(mobile.products_per_row||1) == 0){
              const el = document.createElement("DIV");
              const div_clear = new Vue({
                  render(h) {
                    return h(
                      "div",
                      {class: "sg_clearfix visible-xs" });
                  },
              });
              childrens[i].parentNode.insertBefore(el, childrens[i]);
              div_clear.$mount(el);
            }
        }
      },500);
    },
    getProducts(product_id, isImport) {
      this.errors.no_product = false;
      const children = this.node.children;
      const length = children.length;
      request.get(`/api/v1/related_products`,
       {
          limit: this.$props.number_of_products,
          product_id:product_id?product_id:0,
          random_product:product_id?0:1
        }).then((res) => {
        this.isLoading = false;
        if (res.data.success) {
          const products = [];
          if (res.data.data.items?.length == 0) {
            this.errors.not_found = true;
          } else {
            this.errors.not_found = false;
          }
          res.data.data?.items?.map((product, index) => {
            if(isImport && index<=length-1){
                  children[index].setProps({
                      product_id : String(product.product_id)
                  }, true)
                  products.push(children[index]);
              }else{
                const vnode = (
                  <Product component="Product" tag="Product" product_id={String(product.product_id)}>
                    <ProductImage component="(P) Image" tag="ProductImage" />
                    <ProductTitle component="(P) Title" tag="ProductTitle" />
                    <ProductPrice component="(P) Price" tag="ProductPrice" />
                    {/* <ProductCartButton
                      component="(P) Cart Button"
                      tag="ProductCartButton"
                    /> */}
                  </Product>
                );
                const nodes = createNodeFromVNode(this.editor, vnode);
                nodes.parent = this.node;
                products.push(nodes);
              }
          });
          this.node.children = products;
          this.editor.setTopLevelNodes(this.editor.nodes);
          if(!this.product_id){
            this.node.setProps({ product_id: String(res.data.data.random_product.product_id), product_name: res.data.data.random_product.title},true)
          }
          this.addClearDiv()
        }else{
          this.errors.not_found = true;
        }
      }, (err) => {
        this.$toastr.e(err?.response?.data?.message || 'Error')
        this.errors.not_found = true;
        this.isLoading = false;
      })
    }
  },
  inject: ["node", "editor"],
  mixins: [elementStyleMixin],
  props: {
    section: String,
    component: String,
    
    extra_class: String, ...otherProps,
    products_per_row: String,
    image_same_height:Boolean,
    title_same_height:Boolean,
    image_height:Number,
    title_height:Number,
    spacing:Number,
    exclusions: String,
    randomize:Boolean,
    number_of_products: Number,
    desktop: Object,
    laptop: Object,
    tablet: Object,
    mobile: Object,
    screen: String,
    product_id: String,
    product_name: String,
  },
  updated() {
    switch(this.screen) {
      case "laptop":
        this.objectScreen = this.laptop;
        break;
      case "tablet":
        this.objectScreen = this.tablet;
        break;
      case "mobile":
        this.objectScreen = this.mobile;
        break;
      default:
        this.objectScreen = this.desktop;
    }
  },
  craft: {
    defaultProps: { ...otherStyles,
        type: 'related',
        product_id: "",
        product_name: "",
        section: "module",
        image_same_height:false,
        title_same_height:false,
        randomize: false,
        image_height: 200,
        title_height: 72,
        spacing: 20,
        extra_class: "",
        number_of_products: 8,
        exclusions:"best-sellings, new-arrivals",
        desktop: {
          ...designStyles,
          products_per_row: "2",
        },
        laptop: {
          ...designStyles,
          products_per_row: "2",
        },
        tablet: {
          ...designStyles,
          products_per_row: "2",
        },
        mobile: {
          ...designStyles,
          products_per_row: "2",
        },
        elementStyle: {
          
        },
    },
    settings: {
      settings: {
        "Select Product": SelectProduct,
        "Order of products": OrderOfProducts,
        "Products per Row": { "Products per Row": ProductsPerRow, multi_screen: true },
        "Number of Products": NumberOfProducts,
        "Exclusions": Exclusions,
        "Spacing": SpacingProductList,
        "Same Height":SameHeight,
        "Extra Class": ExtraClass, "Custom CSS": CustomCSS,
      },
      design: {
        Visibility,
        Spacing: { Spacing, multi_screen: true },
        Background: { Background, multi_screen: true },
        Border: { Border, multi_screen: true },
        Corner: { Corner, multi_screen: true },
        Shadow: { Shadow, multi_screen: true },
        Opacity: { Opacity, multi_screen: true },
      },
      others: {
        Animations: { Animations, actions: actionTypes.filter(e => ['scroll_into_view', 'after_page_finish_loading'].includes(e.value)) }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
