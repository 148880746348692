<template>
  <div label-position="top" size="medium" :model="elementProps">
    <div class="d-flex flex-column py-2">
      <span class="setting-title">Close Button</span>
      <ToggleButton
        :value="elementProps.close_btn"
        :primaryColor="primaryColor"
        class="mt-2"
        v-bind:changeValue="($event) => updateProps('close_btn', $event)"
        :options="[
          {
            label: 'Inside',
            value: 'inside',
          },
          {
            label: 'Outside',
            value: 'outside',
          },
        ]"
      />
    </div>
    <div class="d-flex flex-column py-2">
      <span class="setting-title">Background</span>
      <ColorPicker
        class="mt-2"
        :value="elementProps.close_btn_background"
        v-bind:changeColor="($event) => updateProps('close_btn_background', $event)"
        :primaryColor="primaryColor"
      />
    </div>
    <div class="d-flex flex-column py-2">
      <span class="setting-title">Color</span>
      <ColorPicker
        class="mt-2"
        :value="elementProps.close_btn_color"
        v-bind:changeColor="($event) => updateProps('close_btn_color', $event)"
        :primaryColor="primaryColor"
      />
    </div>
  </div>
</template>

<script>
import mixin from "../../../elements/styleSettings/mixin";
import ColorPicker from "../../../elements/common/ColorPicker.vue";
import ToggleButton from "../../../elements/common/ToggleButton.vue";

export default {
  mixins: [mixin],
  components: { ColorPicker, ToggleButton },
  props: {
    primaryColor: String,
  },
  methods: {
    updateProps(name, value) {
      if (value == this.elementProps[name]) return;

      this.elementPropsSetter({ [name]: value });
    },
  },
};
</script>

<style scoped>
</style>
