<template>
  <div label-position="top" size="small" :model="elementProps">
    <div
      class="d-flex align-items-center justify-content-between py-2"
      v-if="activeStyles('stretch')"
    >
      <span class="setting-title">Stretch Button</span>
      <el-switch
        :value="getValue('stretch',elementProps[screen].stretch)"
        :active-color="primaryColor"
        inactive-color="#949494"
        :active-value="true"
        :inactive-value="false"
        @change="updateProps('stretch', $event)"
      />
    </div>
    <div class="d-flex flex-column py-2" v-if="activeStyles('width')">
      <span class="setting-title">Width</span>
      <InputWithSelect
        class="mt-2"
        type="number"
        :value="getValue('width',elementProps[screen].width)"
        :primaryColor="primaryColor"
        :options="['px', '%']"
        v-bind:changeValue="($event) => updateProps('width', $event)"
        v-bind:changeUnit="($event) => updateProps('width_type', $event)"
        :unit="getValue('width_type',elementProps[screen].width_type)"
      />
    </div>
    <div class="d-flex flex-column py-2" v-if="activeStyles('height')">
      <span class="setting-title">Height</span>
      <el-input
        class="mt-2"
        :value="getValue('height',elementProps[screen].height)"
        @input="updateProps('height', $event)"
        size="medium"
      />
    </div>
    <div class="d-flex flex-column py-2" v-if="activeStyles('border-width')">
      <span class="setting-title">Border width</span>
      <el-input
        type="number"
        class="mt-2"
        :value="getValue('border_width',elementProps[screen].border_width) || 0"
        @input="updateProps('border_width', parseInt($event || 0))"
        :max="999"
      />
    </div>
    <div class="d-flex flex-column py-2" v-if="activeStyles('select_margin_bottom')">
      <span class="setting-title">Margin Bottom</span>
      <Slider
        class="mt-2"
        :value="elementProps.select_margin_bottom"
        :primaryColor="primaryColor"
        v-bind:changeValue="
          ($event) => updateProps('select_margin_bottom', $event)
        "
        unit="px"
      />
    </div>

    <div class="d-flex flex-column py-2" v-if="activeStyles('stretch') && getValue('stretch',elementProps[screen].stretch)">
      <span class="setting-title">Text Align</span>
      <ToggleButton
        class="mt-2"
        :primaryColor="primaryColor"
        :value="getValue('text_align',elementProps[screen].text_align)"
        v-bind:changeValue="
          ($event) => updateProps('text_align', $event)
        "
        :options="[
          {
            label: 'Left',
            value: 'left',
          },
          {
            label: 'Center',
            value: 'center',
          },
          {
            label: 'Right',
            value: 'right',
          },
        ]"
      />
    </div>
  </div>
</template>

<script>
import mixin from "./mixin";
import Slider from "../common/Slider.vue";
import ToggleButton from "../common/ToggleButton.vue";
import InputWithSelect from "../common/InputWithSelect.vue";
import Node from '@/core/Node';

export default {
  mixins: [mixin],
  components: { Slider, ToggleButton, InputWithSelect },
  props: {
    screen: String,
    primaryColor: String,
    remake: Boolean,
    node: Node
  },
  mounted(){
    if(this.$props.remake){
      let resolver;
      resolver = this.$props.node.editor.findResolver(this.$props.node.componentName);
      let craft = resolver.craft;
      this.elementPropsSetter({
        "dimensionActives":craft['defaultProps']['dimensionActives']
      })
    }
  },
  methods: {
    activeStyles(key) {
      return this.elementProps.dimensionActives?.includes(key);
    },
    updateProps(name, value) {
      const screen = this.$props.screen;
      const data_screen = Object.assign({}, this.elementProps[screen]);
      var vl = value;
      if(name == 'border-width' && vl > 999){
        vl = 999
      }
      if (vl == data_screen[name]) return;
      data_screen[name] = vl;
      if (name == "width" && vl == "auto" && isNaN(parseInt(vl))) {
        data_screen["width_type"] = "";
      }
      this.elementPropsSetter({ [screen]: data_screen });
    },
  },
};
</script>

<style scoped>
</style>
