<template>
  <div label-position="top" size="mini" :model="elementProps">
    <div class="d-flex flex-column py-2">
      <span style="font-size: 12px; color: #ccc">Text Color</span>
      <ColorPicker
        class="mt-2"
        :value="getValue('color', elementProps[screen].color)"
        v-bind:changeColor="($event) => updateProps('color', $event)"
        :primaryColor="primaryColor"
      />
    </div>
    <div class="d-flex flex-column py-2">
      <span style="font-size: 12px; color: #ccc">Font</span>
      <Select
        class="mt-2"
        :primaryColor="primaryColor"
        :value="getValue('font_family', elementProps[screen].font_family)"
        v-bind:changeValue="($event) => updateProps('font_family', $event)"
        :hasAction="true"
        v-bind:onAction="handleShowModal"
        actionLabel="Open Font Manager"
        :options="getListFont()"
        :key="fontKey"
      />
    </div>
  </div>
</template>
  
  <script>
import mixin from "../../../elements/styleSettings/mixin";
import Select from "../../../elements/common/Select.vue";
import ColorPicker from "../../../elements/common/ColorPicker.vue";
import Node from "@/core/Node";
import { makeId } from '@/constants';

export default {
  mixins: [mixin],
  components: {
    ColorPicker,
    Select,
  },
  props: {
    node: Node,
    activeFonts: Array,
    screen: String,
    primaryColor: String,
  },
  data() {
    return {
      fontKey: makeId(20),
    };
  },
  watch: {
    activeFonts: function (newValue, oldValue) {
      this.fontKey = makeId(20);
    },
  },
  methods: {
    activeStyles(key) {
      return this.elementProps.typographyActives?.includes(key);
    },
    handleShowModal() {
      this.$bus.emit("showFontManager", this.node);
    },
    getListFont() {
      const listFonts = [
        {
          value: "inherit",
          label: "Default",
        },
      ];
      this.node?.editor?.fonts?.map((e) => {
        listFonts.push({ value: e, label: e });
      });
      return listFonts;
    },
    updateProps(name, value) {
      const screen = this.$props.screen;
      const { desktop, laptop, tablet, mobile } = this.elementProps;
      const data_screen = Object.assign({}, this.elementProps[screen]);
      if (value == data_screen[name]) return;
      data_screen[name] = value;
      var obj = {
        [screen]: data_screen,
      };
      this.elementPropsSetter(obj);
    },
  },
};
</script>
  