<template>
  <el-form label-position="top" size="mini" :model="elementProps" v-if="elementProps.group_by == 'separately'">
    <div class="d-flex align-items-center justify-content-between py-2">
      <span class="sgSubTitle">First Options</span>
      <el-switch
        :value="elementProps.show_option_1"
        :active-color="primaryColor"
        inactive-color="#949494"
        @change="updateProps('show_option_1', $event)"
      />
    </div>

    <div class="d-flex align-items-center justify-content-between py-2">
      <span class="sgSubTitle">Second Options</span>
      <el-switch
        :value="elementProps.show_option_2"
        :active-color="primaryColor"
        inactive-color="#949494"
        @change="updateProps('show_option_2', $event)"
      />
    </div>

    <div class="d-flex align-items-center justify-content-between py-2">
      <span class="sgSubTitle">Third Options</span>
      <el-switch
        :value="elementProps.show_option_3"
        :active-color="primaryColor"
        inactive-color="#949494"
        @change="updateProps('show_option_3', $event)"
      />
    </div>

  </el-form>
</template>

<script>
import mixin from "../../../elements/styleSettings/mixin";
import ColorPicker from "../../../elements/common/ColorPicker.vue";
import Slider from "../../../elements/common/Slider.vue";
import ToggleButton from "../../../elements/common/ToggleButton.vue";

export default {
  mixins: [mixin],
  computed:{
    hideSetting() {
      return elementProps.group_by != 'separately';
    },
  },
  props: {
    primaryColor: String
  },
  components: {ColorPicker, Slider, ToggleButton},
  methods: {
    updateProps(name, value) {
      if(value ==  this.elementProps[name]) return;
      this.elementPropsSetter({ [name]: value });
    }
  },
};
</script>

<style scoped>
</style>
