<template>
  <div label-position="top" size="mini" :model="elementProps">
    <div class="d-flex flex-column py-2">
      <span style="color: #AAAAAA; font-size: 12px;">Color</span>
      <ColorPicker
        class="mt-2"
        :value="getValue('border_color',elementProps[screen].border_color)"
        v-bind:changeColor="($event) => updateProps('border_color', $event)"
        :primaryColor="primaryColor"
      />
    </div>
  </div>
</template>

<script>
import mixin from "./mixin";
import ColorPicker from "../common/ColorPicker.vue";
export default {
  mixins: [mixin],
  components: {
    ColorPicker,
  },
  props: {
    screen: String,
    primaryColor: String
  },
  methods: {
    updateProps(name, value) {
      const screen = this.$props.screen;
      const data_screen = Object.assign({}, this.elementProps[screen])
      if(value ==  data_screen[name]) return;
      data_screen[name] = value
      this.elementPropsSetter({ [screen]: data_screen });
    },
  },
};
</script>

<style scoped>
</style>
