<template>
  <div
    class="sg-wrap module sg-accordion"
    @mouseover="isHovering=true" 
    @mouseout="isHovering=false"
    :id="`element-${node.uuid}`"
    :style="elementCSS"
  >
    <div class="sg-accordion-content tabs" :class="extra_class">
      <slot/>
    </div>
    <ToolBar ref="accordion_toolbar" :toolbar_id="`accordion`" class="container-toolbar" 
    :class="{ 'container-toolbar-show': isHovering, 'is-dragging': dragging == node.uuid }"
    >
  </ToolBar>
    <div v-if="dragging == node.uuid" class="dragging"></div>
    <v-style>
      {{ custom_css }}
      
      #element-{{node.uuid}} .tab {
        border-bottom: {{ border_width }}px solid {{ border_color }};
      }
      
      #element-{{ node.uuid }} .one, #element-{{ node.uuid }} .two {
        background: {{ icon_color }};
      }
      
      #element-{{node.uuid}} input:checked ~ .tab-content {
        border-top: {{ border_width }}px solid {{ border_color }};
      }
      @media (max-width: 767px) {
        #element-{{ node.uuid }} {
          {{ getDisplayValue('mobile') }}
          {{ getMarginValue('mobile') }}
          {{ getPaddingValue('mobile') }}
          {{ getBackgroundValue('mobile') }}
          {{ getBorderValue('mobile') }}
          {{ getBorderRadiusValue('mobile') }}
          {{ getOpacityValue('mobile') }}
          {{ getShadowValue('mobile') }}
          {{ getOtherStyle('mobile') }}
        }

        #element-{{ node.uuid }} .tab-label {
          {{ getValue('color', 'mobile') ? `color: ${getValue('color','mobile')};` : '' }}
          font-family: {{getValue('font_family','mobile')?`${getValue('font_family','mobile')}!important`:''}};
        }
      }
      @media (max-width: 991px) and (min-width: 768px) {
        #element-{{ node.uuid }} {
          {{ getDisplayValue('tablet') }}
          {{ getMarginValue('tablet') }}
          {{ getPaddingValue('tablet') }}
          {{ getBackgroundValue('tablet') }}
          {{ getBorderValue('tablet') }}
          {{ getBorderRadiusValue('tablet') }}
          {{ getOpacityValue('tablet') }}
          {{ getShadowValue('tablet') }}
          {{ getOtherStyle('tablet') }}
        }

        #element-{{ node.uuid }} .tab-label {
          {{ getValue('color', 'tablet') ? `color: ${getValue('color','tablet')};` : '' }}
          font-family: {{getValue('font_family','tablet')?`${getValue('font_family','tablet')}!important`:''}};
        }

      }
      @media (max-width: 1199px) and (min-width: 992px) {
        #element-{{ node.uuid }} {
          {{ getDisplayValue('laptop') }}
          {{ getMarginValue('laptop') }}
          {{ getPaddingValue('laptop') }}
          {{ getBackgroundValue('laptop') }}
          {{ getBorderValue('laptop') }}
          {{ getBorderRadiusValue('laptop') }}
          {{ getOpacityValue('laptop') }}
          {{ getShadowValue('laptop') }}
          {{ getOtherStyle('laptop') }}
        }

        #element-{{ node.uuid }} .tab-label {
          {{ getValue('color', 'laptop') ? `color: ${getValue('color','laptop')};` : '' }}
          font-family: {{getValue('font_family','laptop')?`${getValue('font_family','laptop')}!important`:''}};
        }
      }
      @media (min-width: 1200px) {
        #element-{{ node.uuid }} {
          {{ getDisplayValue('desktop') }}
          {{ getMarginValue('desktop') }}
          {{ getPaddingValue('desktop') }}
          {{ getBackgroundValue('desktop') }}
          {{ getBorderValue('desktop') }}
          {{ getBorderRadiusValue('desktop') }}
          {{ getOpacityValue('desktop') }}
          {{ getShadowValue('desktop') }}
          {{ getOtherStyle('desktop') }}
        }

        #element-{{ node.uuid }} .tab-label {
          {{ getValue('color', 'desktop') ? `color: ${getValue('color','desktop')};` : '' }}
          font-family: {{desktop.font_family? `${desktop.font_family} !important`:''}};
        }
      }
    </v-style>
  </div>
  
</template>

<script>
import elementStyleMixin from '../../elements/elementStyleMixin';
import ToolBar from '../../elements/utils/ToolBar.vue';
import Visibility from '../../elements/designSettings/Visibility.vue';
import Spacing from '../../elements/designSettings/Spacing.vue';
import BackgroundDesign from '../../elements/designSettings/Background.vue';
import Border from '../../elements/designSettings/Border.vue';
import Corner from '../../elements/designSettings/Corner.vue';
import Shadow from '../../elements/designSettings/Shadow.vue';
import Opacity from '../../elements/designSettings/Opacity.vue';
import ExtraClass from '../../elements/otherSettings/ExtraClass.vue';
import AccordionItem from './AccordionItem.vue';
import AccordionType from './settings/AccordionType.vue';
import IconSettings from './settings/IconSettings.vue';
import Title from './settings/Title.vue';
import OpenTab from './settings/OpenTab.vue';
import AccordionBorder from './settings/Border.vue';
import Items from './settings/Items.vue';
import { makeId, designStyles, otherProps, otherStyles, actionTypes } from '@/constants';
import CustomCSS from '../../elements/styleSettings/CustomCSS.vue';
import Animations from '../../elements/designSettings/Animations.vue';

export default {
  components: { ToolBar, AccordionItem },
  mixins: [elementStyleMixin],
  inject: [
    'node',
    'editor'
  ],
  props: {
    ...otherProps,
    component: String,
    
    extra_class: String,
    items: Array,
    accordion_type: String,
    icon_type: String,
    icon_position: String,
    icon_color: String,
    open_tab: Boolean,
    tab_index: String,
    border_width: Number,
    border_color: String,
    desktop: Object,
    laptop: Object,
    mobile: Object,
    tablet: Object,
  },watch: {
    dragging: function (newValue, oldValue) {
      var $iframe = $("#designEditor").contents();
      var $element = $iframe.find(`#element-${this.node.uuid}`);
      var $content = $element.find(`.sg-accordion-content`);
      if (newValue && newValue == this.node.uuid) {
        setTimeout(() => {
          $element.css("padding","0px 0px 0px 0px");
          $element.css("margin","0px 0px 0px 0px");
          $content.hide();
        },100);
      }else if (oldValue == this.node.uuid){
        $element.css("padding","");
        $element.css("margin","");
        $content.show();
      }
    },
  },computed: {
    dragging: function () {
      return this.editor?.draggedNode?.uuid
    },
  },
  data() {
    return {
      isHovering: false,
      accordion_mode: '1',
      non_accordion_mode: ['1']
    }
  },
  craft: {
    defaultProps: {
      ...otherStyles,
      desktop: {
        ...designStyles,
        padding_top: 30,
        padding_bottom: 30,
        color:'rgb(0, 0, 0)',
      },
      laptop: {
        ...designStyles,
      },
      tablet: {
        ...designStyles,
      },
      mobile: {
        ...designStyles,
      },
      extra_class: "",
      items: [
        {
          id: makeId(20),
          name: 'Tab 1',
          color: null,
          index: '1'
        },
        {
          id: makeId(20),
          name: 'Tab 2',
          color: null,
          index: '2'
        },
        {
          id: makeId(20),
          name: 'Tab 3',
          color: null,
          index: '3'
        },
      ],
      accordion_type: 'multiple',
      icon_type: 'plus',
      icon_position: 'right',
      icon_color: 'rgb(0, 0, 0)',
      open_tab: true,
      tab_index: '1',
      border_width: 1,
      border_color: 'rgb(206, 206, 206)',
      elementStyle: {

      },
    },
    settings: {
      settings: {
        Items,
        "Accordion Type": AccordionType,
        "Title Setting": Title,
        "Icon Settings": IconSettings,
        "Open Tab": OpenTab,
        "Border": AccordionBorder,
        "Extra Class": ExtraClass, "Custom CSS": CustomCSS,
      },
      design: {
        Visibility,
        Spacing: { Spacing, multi_screen: true },
        Background: { "Background": BackgroundDesign, multi_screen: true },
        Border: { Border, multi_screen: true },
        Corner: { Corner, multi_screen: true },
        Shadow: { Shadow, multi_screen: true },
        Opacity: { Opacity, multi_screen: true },
      },
      others: {
        Animations: { Animations, actions: actionTypes.filter(e => ['scroll_into_view', 'after_page_finish_loading'].includes(e.value)) }
      }
    },
  },
};
</script>

<style lang="scss" scoped>

.dragging{
  position:absolute;
  top:0;
  bottom: 0;
  left: 0;
  right:0;
  background: greenyellow;
  z-index: 10;
}

</style>
