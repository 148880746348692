<template>
  <div label-position="top" size="medium" :model="elementProps">
    <div
      class="d-flex align-items-center justify-content-between py-2"
    >
      <span class="sgSubTitle">Enable or disable border.</span>
      <el-switch
        :value="elementProps.borderactive"
        :active-color="primaryColor"
        inactive-color="#949494"
        @change="updateProps('borderactive', $event)"
      />
    </div>
    <div class="d-flex flex-column py-2">
      <span class="setting-title">Border Color</span>
      <ColorPicker
        class="mt-2"
        :value="elementProps.border_color"
        v-bind:changeColor="($event) => updateProps('border_color', $event)"
        :primaryColor="primaryColor"
      />
    </div>
    <div class="d-flex flex-column py-2">
      <span class="setting-title">Border Width</span>
      <Slider
        class="mt-2"
        :primaryColor="primaryColor"
        :value="elementProps.border_width"
        :min="0"
        :max="5"
        :step="1"
        v-bind:changeValue="($event) => updateProps('border_width', parseInt($event))"
        unit="px"
        :onlyInteger="true"
      />
    </div>
  </div>
</template>

<script>
import mixin from "../../../elements/styleSettings/mixin";
import Slider from "../../../elements/common/Slider.vue";
import ColorPicker from "../../../elements/common/ColorPicker.vue";

export default {
  mixins: [mixin],
  components: {Slider, ColorPicker},
  props: {
    primaryColor: String
  },
  methods: {
    updateProps(name, value) {
      if(value ==  this.elementProps[name]) return;

      this.elementPropsSetter({ [name]: value });
    },
    updateBoxShadow(key, value) {
      const imageStyle = this.getStyle("image-style")
      const boxShadow = this.getStyle("image-style")['box-shadow'];
      this.setStyle("image-style", {
        ...imageStyle,
        ["box-shadow"]: {
            ...boxShadow,
            [key]:value
        },
      });
    },
  },
};
</script>

<style scoped>
</style>
