import { render, staticRenderFns } from "./DragHandle.vue?vue&type=template&id=310a2b37&scoped=true&"
import script from "./DragHandle.vue?vue&type=script&lang=js&"
export * from "./DragHandle.vue?vue&type=script&lang=js&"
import style0 from "./DragHandle.vue?vue&type=style&index=0&id=310a2b37&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "310a2b37",
  null
  
)

export default component.exports