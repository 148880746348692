var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"sg-liquid-manager"}},[_c('el-dialog',{attrs:{"visible":_vm.show,"width":"60%","top":"8vh","before-close":_vm.handleClose,"close-on-click-modal":false},on:{"update:visible":function($event){_vm.show=$event}}},[_c('div',{staticClass:"title-dialog",attrs:{"slot":"title"},slot:"title"},[_c('span',{staticClass:"text-title"},[_vm._v("Code Editor - Liquid")]),_c('span',{staticClass:"node-id"},[_vm._v("#element-"+_vm._s(_vm.node?.uuid))])]),_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"d-flex items-center"},[_c('div',{staticClass:"button-lang",class:_vm.lang == 'html' ? 'active' : '',on:{"click":() => _vm.switchLang('html')}},[_vm._v("HTML")]),_c('div',{staticClass:"button-lang",class:_vm.lang == 'css' ? 'active' : '',on:{"click":() => _vm.switchLang('css')}},[_vm._v("CSS")]),_c('div',{staticClass:"button-lang",class:_vm.lang == 'javascript' ? 'active' : '',on:{"click":() => _vm.switchLang('javascript')}},[_vm._v("Script")])]),_c('div',{staticClass:"editor"},[(_vm.lang == 'html')?_c('AceEditor',{attrs:{"lang":"html","theme":"monokai","width":"100%","height":"400px","options":{
              enableBasicAutocompletion: true,
              enableLiveAutocompletion: true,
              fontSize: 12,
              fontFamily: 'monospace',
              highlightActiveLine: true,
              enableSnippets: true,
              showLineNumbers: true,
              tabSize: 2,
              showPrintMargin: false,
              showGutter: true,
          }},on:{"init":_vm.editorInit},model:{value:(_vm.template),callback:function ($$v) {_vm.template=$$v},expression:"template"}}):_vm._e(),(_vm.lang == 'css')?_c('AceEditor',{attrs:{"lang":"css","theme":"monokai","width":"100%","height":"400px","options":{
              enableBasicAutocompletion: true,
              enableLiveAutocompletion: true,
              fontSize: 12,
              fontFamily: 'monospace',
              highlightActiveLine: true,
              enableSnippets: true,
              showLineNumbers: true,
              tabSize: 2,
              showPrintMargin: false,
              showGutter: true,
          }},on:{"init":_vm.editorInit},model:{value:(_vm.css),callback:function ($$v) {_vm.css=$$v},expression:"css"}}):_vm._e(),(_vm.lang == 'javascript')?_c('AceEditor',{attrs:{"lang":"javascript","theme":"monokai","width":"100%","height":"400px","options":{
              enableBasicAutocompletion: true,
              enableLiveAutocompletion: true,
              fontSize: 12,
              fontFamily: 'monospace',
              highlightActiveLine: true,
              enableSnippets: true,
              showLineNumbers: true,
              tabSize: 2,
              showPrintMargin: false,
              showGutter: true,
          }},on:{"init":_vm.editorInit},model:{value:(_vm.javascript),callback:function ($$v) {_vm.javascript=$$v},expression:"javascript"}}):_vm._e()],1)]),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":_vm.handleClose}},[_vm._v("Cancel")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":() => _vm.handleSave({ template: _vm.template, css: _vm.css, javascript: _vm.javascript })}},[_vm._v("Save")])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }