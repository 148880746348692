<template>
  <div class="custom-input" :style="cssVars">
    <el-input
      v-if="type != 'number'"
      :value="value"
      @input="changeValue($event)"
      size="medium"
      :placeholder="placeholder || ''"
      :type="type || 'text'"
      :disabled="disabled"
      :rows="rows || 1"
    />
    <el-input
      v-if="type == 'number'"
      :value="value"
      @input="changeValue($event)"
      size="medium"
      :placeholder="placeholder || ''"
      type="number"
      :disabled="disabled"
      :min="min"
      :max="max"
    />
  </div>
</template>

<script>

export default {
  components: {
    name: 'Input'
  },
  props: {
    value: String,
    changeValue: Function,
    primaryColor: String,
    placeholder: String,
    type: String,
    disabled: Boolean,
    rows: Number,
    min: Number,
    max: Number,
  },
  computed: {
    cssVars() {
      return {
        '--primary-color': this.primaryColor || '#0060e5'
      }
    }
  }
};
</script>

<style>
  .custom-input .el-input__inner:focus {
    border-color: var(--primary-color) !important;
  }
  .custom-input input::-webkit-outer-spin-button, .custom-input input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .custom-input .el-input.is-disabled .el-input__inner {
    color: #444444;
  }
</style>