var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"label-position":"top","size":"mini","model":_vm.elementProps}},[_c('div',{staticClass:"d-flex flex-column py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Border Radius")]),_c('Dimension',{attrs:{"units":['px', '%'],"unit":_vm.elementProps[_vm.screen].border_radius_unit || 'px',"primaryColor":_vm.primaryColor,"data":_vm.elementProps[_vm.screen],"isPositive":true,"onlyInteger":true,"items":[
        {
          key: 'border_top_left_radius',
          title: 'Top Left',
        },
        {
          key: 'border_top_right_radius',
          title: 'Top Right'
        },
        {
          key: 'border_bottom_right_radius',
          title: 'Bottom Right'
        },
        {
          key: 'border_bottom_left_radius',
          title: 'Bottom Left'
        }
      ],"changeValue":(name, $event) => _vm.updateProps(_vm.screen, name, $event),"changeUnit":($event) => _vm.updateProps(_vm.screen, 'border_radius_unit', $event)}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }