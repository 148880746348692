<template>
  <div label-position="top" size="mini" :model="elementProps">
    <div class="d-flex align-items-center justify-content-between py-2">
      <span class="setting-title">Hide Button</span>
      <el-switch
        :value="elementProps.link_button"
        :active-color="primaryColor"
        inactive-color="#949494"
        @change="updateProps('link_button', $event)"
      />
    </div>
    <div class="sgDesc mt-0">*Works on live or preview page*</div>
    <!-- <div class="d-flex align-items-center justify-content-between py-2">
      <span class="setting-title">Link Button</span>
      <el-switch
        :value="elementProps.link_button"
        :active-color="primaryColor"
        inactive-color="#949494"
        @change="updateProps('link_button', $event)"
      />
    </div>
    <div class="d-flex flex-column py-2" v-if="elementProps.link_button">
      <span class="setting-title">Select Button</span>
      <Select
        class="mt-2"
        :primaryColor="primaryColor"
        :value="elementProps.button_id"
        v-bind:changeValue="($event) => updateProps('button_id', $event)"
        :options="listButtons()"
      />
    </div> -->
    <div class="d-flex flex-column py-2">
      <span class="setting-title">Name</span>
      <Input
        class="mt-2"
        :value="elementProps.name"
        v-bind:changeValue="($event) => updateProps('name', $event)"
        type="text"
        size="medium"
        :primaryColor="primaryColor"
      />
    </div>
  </div>
</template>

<script>
import mixin from "../../../elements/styleSettings/mixin";
import Select from "../../../elements/common/Select.vue";
import Slider from "../../../elements/common/Slider.vue";
import Input from "../../../elements/common/Input.vue";
import Node from "@/core/Node";

export default {
  mixins: [mixin],
  components: { Select, Slider, Input },
  props: {
    node: Node,
    primaryColor: String,
  },
  data() {
    return {
      buttons: []
    }  
  },
  methods: {
    updateProps(name, value) {
      // if (name == 'button_id') {
      //   const nodeMaps = Object.values(this.node?.editor?.nodeMap)
      //   const old_button = nodeMaps.find(node => node.props.modal_id == this.node.uuid)
      //   if (old_button) {
      //     old_button.setProps({ modal_id: '', link_modal: false }, true)
      //   }
      //   const buttonNode = this.node?.editor?.findNode(value);
      //   buttonNode.setProps({ modal_id: this.node.uuid, link_modal: true }, true)
      // }
      if(value ==  this.elementProps[name]) return;
      this.elementPropsSetter({ [name]: value });
    },
    updateMixStyle(key, value, object) {
      const style = this.getStyle(object);
      this.setStyle(object, {
        ...style,
        [key]: value,
      });
    },
    listButtons() {
      const nodeMaps = Object.values(this.node?.editor?.nodeMap)
      // console.log(nodeMaps, 'nodemaps')
      const buttons = nodeMaps.filter(e => e.componentName == 'Button')
      const res = []
      buttons.map(button => {
        res.push({ value: button.uuid, label: button.props.text, disabled: button.props.modal_id ? true : false })
      })
      return res
    }
  },
};
</script>

<style scoped>
</style>
