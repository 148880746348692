var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"label-position":"top","size":"mini","model":_vm.elementProps}},[_c('div',{staticClass:"typography"},[_c('div',{staticClass:"d-flex flex-column py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Font Family")]),_c('Select',{key:_vm.fontKey,staticClass:"mt-2",attrs:{"value":_vm.elementProps.button_font_family,"changeValue":($event) => _vm.updateCommonProps('button_font_family', $event),"hasAction":true,"onAction":_vm.handleShowModal,"actionLabel":"Open Font Manager","options":_vm.getListFont()}})],1),_c('div',{staticClass:"d-flex flex-column py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Font Size")]),_c('Slider',{attrs:{"step":1,"value":_vm.elementProps.button_font_size,"changeValue":($event) => _vm.updateCommonProps('button_font_size', parseInt($event)),"unit":"px","max":999,"min":1,"onlyInteger":true}})],1),_c('div',{staticClass:"d-flex flex-column py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Font Weight")]),_c('Select',{staticClass:"mt-2",attrs:{"value":_vm.elementProps.button_font_weight,"changeValue":($event) => _vm.updateCommonProps('button_font_weight', $event),"options":[
          {
            value: '100',
            label: 'Thin 100',
          },
          {
            value: '200',
            label: 'Semi Thin 200',
          },
          {
            value: '300',
            label: 'Light 300',
          },
          {
            value: '400',
            label: 'Regular 400',
          },
          {
            value: '500',
            label: 'Medium 500',
          },
          {
            value: '600',
            label: 'Semi Bold 600',
          },
          {
            value: '700',
            label: 'Bold 700',
          },
          {
            value: '800',
            label: 'Super Bold 800',
          },
          {
            value: '900',
            label: 'Boldest 900',
          }
        ]}})],1),_c('div',{staticClass:"d-flex flex-column py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Text Transform")]),_c('Select',{staticClass:"mt-2",attrs:{"primaryColor":"#0060e5","value":_vm.elementProps.button_text_transform,"changeValue":($event) => _vm.updateCommonProps('button_text_transform', $event),"options":[
          {
            value: 'none',
            label: 'None',
          },
          {
            value: 'uppercase',
            label: 'Uppercase',
          },
          {
            value: 'lowercase',
            label: 'Lowercase',
          },
          {
            value: 'capitalize',
            label: 'Capitalize',
          },
        ]}})],1)]),_c('div',{staticClass:"d-flex flex-column py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Padding")]),_c('Dimension',{attrs:{"units":['px', '%'],"unit":_vm.elementProps.button_padding_unit || 'px',"primaryColor":"#0060e5","data":_vm.elementProps,"items":[
        {
          key: 'button_padding_top',
          title: 'Top',
        },
        {
          key: 'button_padding_right',
          title: 'Right'
        },
        {
          key: 'button_padding_bottom',
          title: 'Bottom'
        },
        {
          key: 'button_padding_left',
          title: 'Left'
        }
      ],"changeValue":(name, $event) => _vm.updateCommonProps(name, parseInt($event)),"changeUnit":($event) => _vm.updateCommonProps('button_padding_unit', $event)}})],1),_c('div',{staticClass:"button-type"},[_c('div',{staticClass:"d-flex flex-column py-2"},[_c('ToggleButton',{attrs:{"primaryColor":"#0060e5","value":_vm.button_type,"changeValue":($event) => _vm.updateType($event),"options":[
          { label: 'Normal', value: 'normal' },
          { label: 'Hover', value: 'hover' }
        ]}})],1),(_vm.button_type == 'normal')?_c('div',[_c('div',{staticClass:"d-flex flex-column py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Text Color")]),_c('ColorPicker',{staticClass:"mt-2",attrs:{"primaryColor":"#0060e5","value":_vm.elementProps.button_text_color,"changeColor":($event) => _vm.updateCommonProps('button_text_color', $event)}})],1),_c('div',{staticClass:"d-flex flex-column py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Background Color")]),_c('ColorPicker',{staticClass:"mt-2",attrs:{"primaryColor":"#0060e5","value":_vm.elementProps.button_background_color,"changeColor":($event) => _vm.updateCommonProps('button_background_color', $event)}})],1),_c('div',{staticClass:"d-flex flex-column py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Border Type")]),_c('Select',{staticClass:"mt-2",attrs:{"primaryColor":"#0060e5","value":_vm.elementProps.button_border_type,"changeValue":($event) => _vm.updateCommonProps('button_border_type', $event),"options":[
            { value: 'none', label: 'None' },
            { value: 'solid', label: 'Solid' },
            { value: 'dotted', label: 'Dotted' },
            { value: 'dashed', label: 'Dashed' },
          ]}})],1),(_vm.elementProps.button_border_type != 'none')?_c('div',{staticClass:"d-flex flex-column py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Border Width")]),_c('Dimension',{attrs:{"units":['px'],"unit":_vm.elementProps.button_border_width_unit || 'px',"primaryColor":"#0060e5","data":_vm.elementProps,"items":[
            {
              key: 'button_border_top_width',
              title: 'Top',
            },
            {
              key: 'button_border_right_width',
              title: 'Right'
            },
            {
              key: 'button_border_bottom_width',
              title: 'Bottom'
            },
            {
              key: 'button_border_left_width',
              title: 'Left'
            }
          ],"changeValue":(name, $event) => _vm.updateCommonProps(name, $event),"changeUnit":($event) => _vm.updateCommonProps('button_border_width_unit', $event)}})],1):_vm._e(),(_vm.elementProps.button_border_type != 'none')?_c('div',{staticClass:"d-flex flex-column py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Border Color")]),_c('ColorPicker',{staticClass:"mt-2",attrs:{"primaryColor":"#0060e5","value":_vm.elementProps.button_border_color,"changeColor":($event) => _vm.updateCommonProps('button_border_color', $event)}})],1):_vm._e(),_c('div',{staticClass:"d-flex flex-column py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Border Radius")]),_c('Dimension',{attrs:{"units":['px', '%'],"unit":_vm.elementProps.button_border_radius_unit || 'px',"primaryColor":"#0060e5","data":_vm.elementProps,"items":[
            {
              key: 'button_border_top_left_radius',
              title: 'Top',
            },
            {
              key: 'button_border_top_right_radius',
              title: 'Right'
            },
            {
              key: 'button_border_bottom_right_radius',
              title: 'Bottom'
            },
            {
              key: 'button_border_bottom_left_radius',
              title: 'Left'
            }
          ],"changeValue":(name, $event) => _vm.updateCommonProps(name, $event),"changeUnit":($event) => _vm.updateCommonProps('button_border_radius_unit', $event)}})],1)]):_vm._e(),(_vm.button_type == 'hover')?_c('div',[_c('div',{staticClass:"d-flex flex-column py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Text Color")]),_c('ColorPicker',{staticClass:"mt-2",attrs:{"primaryColor":"#0060e5","value":_vm.elementProps.button_hover_text_color,"changeColor":($event) => _vm.updateCommonProps('button_hover_text_color', $event)}})],1),_c('div',{staticClass:"d-flex flex-column py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Background Color")]),_c('ColorPicker',{staticClass:"mt-2",attrs:{"primaryColor":"#0060e5","value":_vm.elementProps.button_hover_background_color,"changeColor":($event) => _vm.updateCommonProps('button_hover_background_color', $event)}})],1),_c('div',{staticClass:"d-flex flex-column py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Border Type")]),_c('Select',{staticClass:"mt-2",attrs:{"primaryColor":"#0060e5","value":_vm.elementProps.button_hover_border_type,"changeValue":($event) => _vm.updateCommonProps('button_hover_border_type', $event),"options":[
            { value: 'none', label: 'None' },
            { value: 'solid', label: 'Solid' },
            { value: 'dotted', label: 'Dotted' },
            { value: 'dashed', label: 'Dashed' },
          ]}})],1),(_vm.elementProps.button_hover_border_type != 'none')?_c('div',{staticClass:"d-flex flex-column py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Border Width")]),_c('Dimension',{attrs:{"units":['px', '%'],"unit":_vm.elementProps.button_hover_border_width_unit || 'px',"primaryColor":"#0060e5","data":_vm.elementProps,"items":[
            {
              key: 'button_hover_border_top_width',
              title: 'Top',
            },
            {
              key: 'button_hover_border_right_width',
              title: 'Right'
            },
            {
              key: 'button_hover_border_bottom_width',
              title: 'Bottom'
            },
            {
              key: 'button_hover_border_left_width',
              title: 'Left'
            }
          ],"changeValue":(name, $event) => _vm.updateCommonProps(name, $event),"changeUnit":($event) => _vm.updateCommonProps('button_hover_border_width_unit', $event)}})],1):_vm._e(),(_vm.elementProps.button_hover_border_type != 'none')?_c('div',{staticClass:"d-flex flex-column py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Border Color")]),_c('ColorPicker',{staticClass:"mt-2",attrs:{"primaryColor":"#0060e5","value":_vm.elementProps.button_hover_border_color,"changeColor":($event) => _vm.updateCommonProps('button_hover_border_color', $event)}})],1):_vm._e(),_c('div',{staticClass:"d-flex flex-column py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Border Radius")]),_c('Dimension',{attrs:{"units":['px', '%'],"unit":_vm.elementProps.button_hover_border_radius_unit || 'px',"primaryColor":"#0060e5","data":_vm.elementProps,"items":[
            {
              key: 'button_hover_border_top_left_radius',
              title: 'Top',
            },
            {
              key: 'button_hover_border_top_right_radius',
              title: 'Right'
            },
            {
              key: 'button_hover_border_bottom_right_radius',
              title: 'Bottom'
            },
            {
              key: 'button_hover_border_bottom_left_radius',
              title: 'Left'
            }
          ],"changeValue":(name, $event) => _vm.updateCommonProps(name, $event),"changeUnit":($event) => _vm.updateCommonProps('button_hover_border_radius_unit', $event)}})],1)]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }