<template>
  <div
    data-label="Article List"
    data-key="article list"
    class="sg-wrap module"
    :id="`element-${node.uuid}`"
    @mouseover="isHovering = true"
    @mouseout="isHovering = false"
  >
    <div :class="`sg-module sg-article-list`">
      <div class="sg-item-content">
        <div
          class="sg_row sg_row-no-width sg_row_no_tools sg_row-no-padding"
          :id="`r-${node.uuid}`"
          data-label="Row"
          data-icon="gpicon-row"
        >
        <div class="error" v-if="articles.length==0">
          Choose a blog from left sidebar to show articles.
        </div>
          <div
            v-for="article in articles.slice(0,number_of_articles)"
            :key="article.uuid"
            :class="`
              sg_column
              sg_col_no_tools
              sg_col-xs-${cols[mobile.articles_per_row]}
              sg_col-sm-${cols[tablet.articles_per_row]}
              sg_col-md-${cols[laptop.articles_per_row]}
              sg_col-lg-${cols[desktop.articles_per_row]}
              `"
            id="c-1660620240996"
            data-id="1660620240996"
          >
            <Node :node="article" />
            <span class="sg_add-content"
              ><span class="sg_add-content-span"></span
            ></span>
          </div>
        </div>
      </div>
    </div>

    <ToolBar
      ref="article_list"
      :toolbar_id="`article_list`"
      class="container-toolbar"
      :class="{ 'container-toolbar-show': isHovering, 'is-dragging': dragging == node.uuid }"
    >
    </ToolBar>
    <div v-if="dragging == node.uuid" class="dragging"></div>

    <v-style> 
      {{ custom_css }}
      #element-{{ node.uuid }} [data-label="Product"] {
          padding: 0 !important;
      }
      #element-{{ node.uuid }} {
          width: 100%;
      }

      #r-{{ node.uuid }} > .sg_column {
          min-height: 1px;
      }

      #element-{{ node.uuid }} form { 
          z-index: 1!important; 
      } 


      #element-{{ node.uuid }} .sg_product-title {
          overflow: hidden;
      }


      #element-{{ node.uuid }} .sg_product-title {
          overflow: hidden;
      }

      #element-{{ node.uuid }} [data-label="(P) Image"]>.sg-module>.img-holder {
          overflow: hidden;
      }

      @media (max-width: 767px){ 
        #element-{{ node.uuid }} {
          {{ getDisplayValue('mobile') }}
          {{ getMarginValue('mobile') }}
          {{ getPaddingValue('mobile') }}
          {{ getBackgroundValue('mobile') }}
          {{ getBorderValue('mobile') }}
          {{ getBorderRadiusValue('mobile') }}
          {{ getOpacityValue('mobile') }}
          {{ getShadowValue('mobile') }}
          {{ getOtherStyle('mobile') }}
        } 
      } 
      @media (max-width: 991px) and (min-width: 768px){ 
        #element-{{ node.uuid }} {
          {{ getDisplayValue('tablet') }}
          {{ getMarginValue('tablet') }}
          {{ getPaddingValue('tablet') }}
          {{ getBackgroundValue('tablet') }}
          {{ getBorderValue('tablet') }}
          {{ getBorderRadiusValue('tablet') }}
          {{ getOpacityValue('tablet') }}
          {{ getShadowValue('tablet') }}
          {{ getOtherStyle('tablet') }}
        } 
      } 
      @media (max-width: 1199px) and (min-width: 992px){ 
        #element-{{ node.uuid }} {
          {{ getDisplayValue('laptop') }}
          {{ getMarginValue('laptop') }}
          {{ getPaddingValue('laptop') }}
          {{ getBackgroundValue('laptop') }}
          {{ getBorderValue('laptop') }}
          {{ getBorderRadiusValue('laptop') }}
          {{ getOpacityValue('laptop') }}
          {{ getShadowValue('laptop') }}
          {{ getOtherStyle('laptop') }}
        }
      } 
      @media (min-width: 1200px){ 
        #element-{{ node.uuid }} {
          {{ getDisplayValue('desktop') }}
          {{ getMarginValue('desktop') }}
          {{ getPaddingValue('desktop') }}
          {{ getBackgroundValue('desktop') }}
          {{ getBorderValue('desktop') }}
          {{ getBorderRadiusValue('desktop') }}
          {{ getOpacityValue('desktop') }}
          {{ getShadowValue('desktop') }}
          {{ getOtherStyle('desktop') }}
        }
      }
    </v-style>
  </div>
</template>

<script>
import ToolBar from "../../elements/utils/ToolBar.vue";

import elementStyleMixin from "../../elements/elementStyleMixin";
import PickBlog from "./settings/PickBlog.vue";
import ArticlesPerRow from "./settings/ArticlesPerRow.vue";
import NumberOfArticles from "./settings/NumberOfArticles.vue";

// import ProductsPerRow from "./settings/ProductsPerRow.vue";
// import NumberOfProducts from "./settings/NumberOfProducts.vue";
// import Collection from "./settings/Collection.vue";

import Visibility from "../../elements/designSettings/Visibility.vue";
import Spacing from "../../elements/designSettings/Spacing.vue";
import Background from "../../elements/designSettings/Background.vue";
import Border from "../../elements/designSettings/Border.vue";
import Corner from "../../elements/designSettings/Corner.vue";
import Shadow from "../../elements/designSettings/Shadow.vue";
import Opacity from "../../elements/designSettings/Opacity.vue";

import Node from "../../../../../components/Node.vue";
import Article from "./Article.vue";
import ArticleImage from "./ArticleImage.vue";
import ArticleTitle from "./ArticleTitle.vue";
import ArticleContent from "./ArticleContent.vue";
import createNodeFromVNode from "../../../../../utils/createNodeFromVNode";
import { request } from "../../../../../services";
import { designStyles, otherProps, otherStyles, actionTypes } from "@/constants";
import Cookies from 'js-cookie';
import CustomCSS from '../../elements/styleSettings/CustomCSS.vue';
import Animations from '../../elements/designSettings/Animations.vue';

export default {
  components: { ToolBar, Node },
  watch: {
    blog_id: function(newValue, oldValue) {
      this.getBlogs(newValue);
    }
  },
  created() {
    this.getBlogs();
  },
  watch: {
    dragging: function (newValue, oldValue) {
      var $iframe = $("#designEditor").contents();
      var $element = $iframe.find(`#element-${this.node.uuid}`);
      var $content = $element.find(`.sg-article-list`);
      if (newValue && newValue == this.node.uuid) {
        setTimeout(() => {
          $element.css("padding","0px 0px 0px 0px");
          $element.css("margin","0px 0px 0px 0px");
          $content.hide();
        },100);
      }else if (oldValue == this.node.uuid){
        $element.css("padding","");
        $element.css("margin","");
        $content.show();
      }
    },
  },
  methods: {
    getBlogs() {
      if(!this.blog_id) return;
      request.get(`${this.apiUrl}/v2/admin/blogs/${this.blog_id}`, {limit: 30}).then((res) => {
        if (res.status == 200) {
          const articles = [];
          res.data.blogs.articles.map((article) => {
                const vnode = (
                <Article component="Article" tag="Article" data_article={JSON.stringify(article)}>
                    <ArticleImage tag="ArticleImage"/>
                    <ArticleTitle component="ArticleTitle" tag="ArticleTitle"/>
                    <ArticleContent component="ArticleContent" tag="ArticleContent"/>
                </Article>
                );
                const nodes = createNodeFromVNode(this.editor, vnode);
                articles.push(nodes);
            
          });
          this.articles = articles;
        }
      }, (err) => this.$toastr.e(err?.response?.data?.message || 'Error'))
    }
  },
  data() {
    return {
      isHovering: false,
      articles: [],
      cols: {
        1: "12",
        2: "6",
        3: "4",
        4: "3",
        6: "2",
      },
      objectScreen: [],
      user: JSON.parse(Cookies.get('user_onepage') || "{}"),
      apiUrl: process.env.VUE_APP_API_URL

    };
  },
  inject: ["node", "editor"],
  mixins: [elementStyleMixin],
  props: {
    ...otherProps,
    section: String,
    component: String,
    
    articles_per_row: String,
    number_of_articles: Number,
    desktop: Object,
    laptop: Object,
    mobile: Object,
    tablet: Object,
    screen: String,
    title:String,
    blog_id:Number
  },
  updated() {
    switch(this.screen) {
      case "laptop":
        this.objectScreen = this.laptop;
        break;
      case "tablet":
        this.objectScreen = this.tablet;
        break;
      case "mobile":
        this.objectScreen = this.mobile;
        break;
      default:
        this.objectScreen = this.desktop;
    }
  },
  computed: {
    dragging: function () {
      return this.editor?.draggedNode?.uuid
    },
  },
  craft: {
    defaultProps: {
      ...otherStyles,
      section: "module",
      number_of_articles: 8,
      title:"",
      blog_id:null,
      desktop: {
        ...designStyles,
        articles_per_row: "3",
      },
      laptop: {
        ...designStyles,
        articles_per_row: "3",
      },
      mobile: {
        ...designStyles,
        articles_per_row: "1",
      },
      tablet: {
        ...designStyles,
        articles_per_row: "3",
      },
      elementStyle: {
        
      },
    },
    settings: {
      settings: {
        "Blog":PickBlog,
        "Articles per Row": { "Articles per Row": ArticlesPerRow, multi_screen: true },
        "Number of Articles": NumberOfArticles,
      },
      design: {
        Visibility,
        Spacing: { Spacing, multi_screen: true },
        Background: { Background, multi_screen: true },
        Border: { Border, multi_screen: true },
        Corner: { Corner, multi_screen: true },
        Shadow: { Shadow, multi_screen: true },
        Opacity: { Opacity, multi_screen: true },
      },
      others: {
        Animations: { Animations, actions: actionTypes.filter(e => ['scroll_into_view', 'after_page_finish_loading'].includes(e.value)) }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
