<template>
  <div
    data-label="(QR) Image"
    data-key="(QR) image"
    class="sg-wrap module"
    :id="`element-${node.uuid}`"
    @mouseover="isHovering = true"
    @mouseout="isHovering = false"
  >
    <div
      class="sg-module"
      :class="extra_class"
    >
      <div class="img-holder">
        <img class="sg_qr-form-image sg_featured-image" 
        :data-money="money"
        data-content=""
        :data-url="qrUrl"
        :src="`${qrUrl}?amount=${money}&addInfo=${getContent()}`" />
      </div>
    </div>

    <ToolBar
      ref="(QR)image"
      :toolbar_id="`(qr)image`"
      class="container-toolbar"
      :class="{ 'container-toolbar-show': isHovering, 'is-dragging': dragging == node.uuid }"
      :isDraggable="false"
    >
    </ToolBar>
    <div v-if="dragging == node.uuid" class="dragging"></div>

    <v-style> 
      {{ custom_css }}
      #element-{{ node.uuid }} .sg_qr-form-image { 
        width:{{ elementStyle["width"] }}{{ typeWidth || "" }}!important; 
        height:{{ elementStyle["height"]}}{{ elementStyle["height"] == "auto" ? "" : "px" }}!important;
      }

      @media (max-width: 767px){ 
        #element-{{ node.uuid }} .sg_qr-form-image {
          width: {{ getValue('width','mobile') }}{{ getValue('width_type','mobile') }}; 
          height: {{ getHeight(getValue('height','mobile')) }};
        } 
        #element-{{ node.uuid }} .sg-module {
          text-align: {{ getValue('alignment','mobile') }};
        }
        #element-{{ node.uuid }} img {
          {{ getDisplayValue('mobile') }}
          {{ getMarginValue('mobile') }}
          {{ getPaddingValue('mobile') }}
          {{ getBackgroundValue('mobile') }}
          {{ getBorderValue('mobile') }}
          {{ getBorderRadiusValue('mobile') }}
          {{ getOpacityValue('mobile') }}
          {{ getShadowValue('mobile') }}
          {{ getOtherStyle('mobile') }}
        }
      } 
      @media (max-width: 991px) and (min-width: 768px){ 
        #element-{{ node.uuid }} .sg_qr-form-image {
          width: {{ getValue('width','tablet') }}{{ getValue('width_type','tablet') }}; 
          height: {{ getHeight(getValue('height','tablet')) }};
        } 
        #element-{{ node.uuid }} .sg-module {
          text-align: {{ getValue('alignment','tablet') }};
        }
        #element-{{ node.uuid }} img {
          {{ getDisplayValue('tablet') }}
          {{ getMarginValue('tablet') }}
          {{ getPaddingValue('tablet') }}
          {{ getBackgroundValue('tablet') }}
          {{ getBorderValue('tablet') }}
          {{ getBorderRadiusValue('tablet') }}
          {{ getOpacityValue('tablet') }}
          {{ getShadowValue('tablet') }}
          {{ getOtherStyle('tablet') }}
        }
      } 
      @media (max-width: 1199px) and (min-width: 992px){ 
        #element-{{ node.uuid }} .sg_qr-form-image {
          width: {{ getValue('width','laptop') }}{{ getValue('width_type','laptop') }}; 
          height: {{ getHeight(getValue('height','laptop')) }};
        } 
        #element-{{ node.uuid }} .sg-module {
          text-align: {{ getValue('alignment','laptop') }};
        }
        #element-{{ node.uuid }} img {
          {{ getDisplayValue('laptop') }}
          {{ getMarginValue('laptop') }}
          {{ getPaddingValue('laptop') }}
          {{ getBackgroundValue('laptop') }}
          {{ getBorderValue('laptop') }}
          {{ getBorderRadiusValue('laptop') }}
          {{ getOpacityValue('laptop') }}
          {{ getShadowValue('laptop') }}
          {{ getOtherStyle('laptop') }}
        }
      } 
      @media (min-width: 1200px){ 
        #element-{{ node.uuid }} .sg_qr-form-image {
          width: {{ desktop.width }}{{ desktop.width_type }}; 
          height: {{ getHeight(desktop.height) }};
        } 
        #element-{{ node.uuid }} .sg-module {
          text-align: {{ desktop.alignment }};
        }
        #element-{{ node.uuid }} img {
          {{ getDisplayValue('desktop') }}
          {{ getMarginValue('desktop') }}
          {{ getPaddingValue('desktop') }}
          {{ getBackgroundValue('desktop') }}
          {{ getBorderValue('desktop') }}
          {{ getBorderRadiusValue('desktop') }}
          {{ getOpacityValue('desktop') }}
          {{ getShadowValue('desktop') }}
          {{ getOtherStyle('desktop') }}
        }
      }
    </v-style>
  </div>
</template>

<script>
import ToolBar from "../../elements/utils/ToolBar.vue";

import elementStyleMixin from "../../elements/elementStyleMixin";
import ExtraClass from "../../elements/otherSettings/ExtraClass.vue";
import Dimensions from "../../elements/styleSettings/Dimensions.vue";
import AlignmentBase from "../../elements/styleSettings/AlignmentBase.vue";
import MoneyQR from "./settings/MoneyQR.vue";
import QRLink from "./settings/QRLink.vue";

import Visibility from "../../elements/designSettings/Visibility.vue";
import Spacing from "../../elements/designSettings/Spacing.vue";
import Background from "../../elements/designSettings/Background.vue";
import Border from "../../elements/designSettings/Border.vue";
import Corner from "../../elements/designSettings/Corner.vue";
import Shadow from "../../elements/designSettings/Shadow.vue";
import Opacity from "../../elements/designSettings/Opacity.vue";
import { designStyles, otherProps, otherStyles, actionTypes } from "@/constants";
import CustomCSS from '../../elements/styleSettings/CustomCSS.vue';
import Animations from '../../elements/designSettings/Animations.vue';

export default {
  components: { ToolBar },
  mounted(){
      var $iframe = $("#designEditor").contents();
      var nodeForm = this?.node?.parent?.parent?.parent
      if(nodeForm.componentName == "ContactQRForm"){
        const $form = $iframe.find(`#element-${nodeForm.uuid}`).find('#qr_form')
        const $vm = this
        $form.on( 'input', function() {
          $vm.$forceUpdate();
        }); 
      }
      
  },
  watch: {
    dragging: function (newValue, oldValue) {
      var $iframe = $("#designEditor").contents();
      var $element = $iframe.find(`#element-${this.node.uuid}`);
      var $content = $element.find(`.sg-module`);
      if (newValue && newValue == this.node.uuid) {
        setTimeout(() => {
        $content.hide();
        },100);
      }else if (oldValue == this.node.uuid){
        $content.show();
      }
    },
  },
  data() {
    return {
      isHovering: false,
    };
  },
  inject: ["node",'editor'],
  mixins: [elementStyleMixin],
  props: {
    ...otherProps,
    extra_class: String,
    imageUrl: String,
    component: String,
    typeWidth: String,
    desktop: Object,
    laptop: Object,
    mobile: Object,
    tablet: Object,
    money: Number,
    qrUrl:String
  },
  methods: {
  getContent(){
          var text = ''
          var $iframe = $("#designEditor").contents();
          var nodeForm = this?.node?.parent?.parent?.parent
          if(nodeForm.componentName == "ContactQRForm"){
              const $form = $iframe.find(`#element-${nodeForm.uuid}`).find('#qr_form')
              const tranfer = $form.find(`[data-key='tranfer_content']`)
              text = encodeURIComponent(tranfer.text());
          }
          return text
    },
    getHeight(height) {
      if (height.includes('%') || height.includes('px') || isNaN(parseInt(height))) {
        return height;
      }
      return `${height}px`
    }
  },
  computed: {
      dragging: function () {
      return this.editor?.draggedNode?.uuid
      },
  },
  craft: {
      rules:{
      canDrag: function(){
        return false
      }
    },
    defaultProps: {
      ...otherStyles,
      qrUrl:'https://img.vietqr.io/image/970416-168169888-KjGpSq.jpg',
      extra_class: "",
      typeWidth: "%",
      money:200000,
      dimensionActives: [
        "width",
        "height",
      ],
      desktop: {
        ...designStyles,
        width: "100",
        width_type: '%',
        height: 'auto',
        alignment: 'center',
        padding_top: 30,
        padding_bottom: 30,
        padding_left: 10,
        padding_right: 10
      },
      laptop: {
        ...designStyles,
      },
      tablet: {
        ...designStyles,
      },
      mobile: {
        ...designStyles,
      },
      elementStyle: {
        
      },
    },
    settings: {
      settings: {
          "QR Link":QRLink,
          'Money':MoneyQR,
        Dimension: {Dimension: Dimensions, multi_screen: true},
        Alignment: {Alignment: AlignmentBase, multi_screen: true},
        "Extra Class": ExtraClass, "Custom CSS": CustomCSS,
      },
      design: {
        Visibility,
        Spacing: { Spacing, multi_screen: true },
        Background: { Background, multi_screen: true },
        Border: { Border, multi_screen: true },
        Corner: { Corner, multi_screen: true },
        Shadow: { Shadow, multi_screen: true },
        Opacity: { Opacity, multi_screen: true },
      },
      others: {
        Animations: { Animations, actions: actionTypes }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
  