<template>
  <div id="sg-modal-edit-menu">
    <el-dialog
      title="Customize Menu"
      :visible.sync="dialogVisible"
      width="40%"
      top="5vh"
      :before-close="() => closeModal()"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div class="product-body p-4">
        <div class="d-flex align-items-center gap-2">
          <el-input
            placeholder="Enter menu name"
            :value="input"
            @input="handleChange($event)"
            class="flex-1"
          />
          <el-button plain type="primary" @click="handleAddMenu(input)">Add</el-button>
        </div>
        <div class="mt-2 d-flex flex-column gap-1">
          <div 
            v-for="item in items"
            class="menu-item d-flex gap-2"
            :key="item.id"
          >
            {{ item.label }}
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">Cancel</el-button>
        <el-button type="primary" @click="handleSave(items)">Save</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Node from '@/core/Node';
import { request } from '@/services';
import { makeId } from "@/constants";
import Input from '@/views/landing/components/elements/common/Input.vue';
import Select from '@/views/landing/components/elements/common/Select.vue';

export default {
  props: {
    dialogVisible: Boolean,
    handleClose: Function,
    handleSave: Function,
    node: Node,
    primaryColor: String,
  },
  components: {
    Input, Select
  },
  data() {
    return {
      items: [],
      input: ""
    };
  },
  computed: {
    cssVars() {
      return {
        '--primaryColor': this.primaryColor || '#0060e5'
      }
    }
  },
  watch: {
    node: function (nodeValue) {
      this.items = nodeValue.props.items
    },
  },
  methods: {
    handleChange(value) {
      this.input = value;
    },
    handleAddMenu(menu_name) {
      const _items = Object.assign([], this.items)
      _items.push({
        id: makeId(6),
        label: menu_name,
        href: '#'
      })
      this.items = _items;
    },
    closeModal() {
      this.$props.handleClose()
    },
    updateProps(key, value) {
      const data = Object.assign({}, this.dataButton)
      data[key] = value
      this.dataButton = data
    },
  },
};
</script>

<style lang="scss">
#sg-modal-edit-menu {
  .el-dialog__header {
    padding: 16px;
  }

  .el-dialog__body {
    padding: 0px;
  }

  .el-dialog__footer {
    padding: 16px;
  }

  .product-body {
    background-color: #E4E4E4;
    border-top: 1px solid #E7E7E7;
    border-bottom: 1px solid #E7E7E7;
  }

  .custom-select.link-picker .el-input__inner {
    background-color: #FFFFFF;
    border: 1px solid #DCDFE6;
    color: #606266;
  }

  .custom-select.link-picker .el-input.is-disabled .el-input__inner {
    color: #444444;
    background-color: #F5F7FA;
    border-color: #E4E7ED;
  }

  .menu-item {
    padding: 8px 16px;
    background-color: #fff;
  }

}

.line-clamp-1 {
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  overflow: hidden;
  display: -webkit-box;
  word-wrap: break-word;
}
</style>