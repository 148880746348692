<template>
  <el-form label-position="top" size="" :model="elementProps">
    <el-form-item label="Truncate">
      <el-input
        :value="elementProps.truncate"
        @input="($event) => updateProps('truncate', $event)"
      />
      <div class="sgDesc mt-2">
        Truncates a string down to the number of characters passed as the first parameter. An ellipsis (...) is appended to the truncated string and is included in the character count.
      </div>
    </el-form-item>
  </el-form>
</template>

<script>
import mixin from "../../../elements/styleSettings/mixin";

export default {
  mixins: [mixin],
  components: {},
  methods: {
    updateProps(name, value) {
      if(value ==  this.elementProps[name]) return;

      this.elementPropsSetter({ [name]: value });
    },
  },
};
</script>

<style scoped>
</style>
