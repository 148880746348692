<template>
  <div class="row custom-toggle-button mt-2" :style="cssVars">
    <div 
      v-for="item in options"
      class="item"
      :key="item.value"
      :style="{width: getWidth(), flex: `0 0 ${getWidth()}`}"
      :class="{ active: isMulti ? values.includes(item.value): item.value == value  }"
      @click="() => updateValue(item.value)"
    >
      {{ item.label }}
    </div>
  </div>
</template>

<script>

export default {
  components: {
    name: 'ToggleButton'
  },
  props: {
    value: String,
    options: Array,
    changeValue: Function,
    canRemove: Boolean,
    primaryColor: String,
    isMulti: Boolean,
    values: Array
  },
  methods: {
    getWidth() {
      return 100/this.$props.options.length + '%'
    },
    updateValue(value) {
      this.$props.changeValue(value)
      if (this.$props.canRemove) {
        if (value == this.$props.value) {
          this.$props.changeValue(null)
        } else {
          this.$props.changeValue(value)
        }
      }
    }
  },
  computed: {
    cssVars() {
      return {
        '--primary-color': this.primaryColor || '#0060e5',
        '--color': this.primaryColor ? '#FFF' : '#000'
      }
    }
  }
};
</script>

<style lang="scss">

  .custom-toggle-button {
    margin-left: 0px !important;
    margin-right: 0px !important;
    .item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border: 1px solid var(--primary-color, #717171);
      color: #fff;
      font-size: 13px;
      background-color: transparent;
      padding: 4px 0px;
      &:not(:first-child) {
        border-left: none;
      }
      &:first-child {
        border-radius: 4px 0px 0px 4px;
      }
      &:last-child {
        border-radius: 0px 4px 4px 0px;
      }
    }
    .item.active {
      background-color: var(--primary-color, #717171);
      color: #fff;
    }
  }
</style>