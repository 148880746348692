<template>
    <el-form label-position="top" size="small" :model="elementProps">
      <el-form-item label="Width" >
        <el-input
          :value="getValue('width',elementProps[screen].width)"
          class="input-with-select"
          @input="updateProps('width', $event)"
          size="medium"
        >
          <el-select
            slot="append"
            placeholder="-"
            :value="getValue('typeWidth',elementProps[screen].typeWidth)"
            @change="updateProps('typeWidth', $event)"
          >
            <el-option label="px" value="px"></el-option>
            <el-option label="%" value="%"></el-option>
          </el-select>
        </el-input>
      </el-form-item>
  
      <el-form-item label="Height" >
        <el-input
          :value="getValue('height',elementProps[screen].height)"
          @input="updateProps('height', $event)"
          size="medium"
        />
      </el-form-item>
    </el-form>
  </template>
  
  <script>
import mixin from "../../../elements/styleSettings/mixin";
  import Slider from "../../../elements/common/Slider.vue";
  
  export default {
    mixins: [mixin],
    components: {Slider},
    props: {
      screen: String,
      primaryColor: String,
    },
    methods: {
      updateProps(name, value) {
        const screen = this.$props.screen
        const data_screen = Object.assign({}, this.elementProps[screen])
        if(value ==  data_screen[name]) return;
        data_screen[name] = value
        if (name == 'width' && (value == 'auto' || isNaN(parseInt(value)))) {
            data_screen[name] = 'auto'
        }
        this.elementPropsSetter({ [screen]: data_screen });
      },
    },
  };
  </script>
  
  <style scoped>
  </style>
  