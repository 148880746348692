<template>
  <div label-position="top" size="" :model="elementProps">
    <div class="d-flex flex-column py-2">
      <span class="setting-title">Extra Class</span>
      <Input
        class="mt-2"
        :value="elementProps.extra_class"
        v-bind:changeValue="($event) => updateProps('extra_class', $event)"
        :primaryColor="primaryColor"
      />
      <div class="sgDesc mt-2">
        If you wish to style particular content element differently, then use
        this field to add a class name and then refer to it in your css file.
      </div>
    </div>
  </div>
</template>

<script>
import mixin from "../styleSettings/mixin";
import Input from "../common/Input.vue";

export default {
  mixins: [mixin],
  components: {
    Input
  },
  props: {
    primaryColor: String,
  },
  methods: {
    updateProps(name, value) {
      if(value ==  this.elementProps[name]) return;
      this.elementPropsSetter({ [name]: value });
    },
  },
};
</script>

<style scoped>
</style>
