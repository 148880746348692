<template>
  <component
    :is="editor.findResolver(node.componentName)"
    v-bind="node.props"
    :class="{ 'cf-node-selected': isSelected }"
    @dragstart.native="handleDragStart"
    @click.native="selectNode"
    :isSelected="isSelected"
    :screen="screen"
    @mousemove.native="handleMouseMove"
    @dragover.native="handleDragOver"
    @drop.native="handleDrop"
    @mouseup.native="handleMouseUp"
  >
    <Node
      v-for="node in node.children"
      :key="node.uuid"
      :node="node"
      :screen="screen"
    />
  </component>
</template>

<script>
import Node from "../core/Node";
import NodeService from "../core/services/NodeService";
import DragHandle from "../views/landing/components/elements/DragHandle.vue";

export default {
  name: "Node",
  props: {
    node: Node,
    screen: String,
  },
  mounted() {
    const { $bus, node } = this;
    var $iframe = $("#designEditor").contents();
    var $node = $iframe.find(`#element-${this.node.uuid}`);
    $node[0]?.addEventListener(
      "contextmenu",
      function (event) {
        let iframe = document.querySelector("#designEditor");
        var clientRect = iframe.getBoundingClientRect();
        var clientX = clientRect.left;
        // alert("You've tried to open context menu"); //here you draw your own menu
        $bus.emit("showContextMenu", node, {
          left: `${event.clientX + clientX}px`,
          top: `${event.clientY}px`,
        });
        event.preventDefault();
        event.stopPropagation();
      },
      false
    );
  },
  inject: ["editor"],
  data(props) {
    return {
      nodeService: new NodeService(this),
      editor: this.editor || props.$vnode.data.editor,
    };
  },
  computed: {
    isSelected() {
      return this.node === this.editor.selectedNode;
    },
    isDraggable() {
      return this.editor.enabled && this.node.isDraggable();
    },
  },
  provide() {
    return {
      node: this.node,
      refs: this.$refs,
      editor: this.editor,
      bus: this.$bus,
    };
  },
  methods: {
    cancelDefault(event) {
      event.stopPropagation();
      event.preventDefault();
    },
    handleDragStart(event) {
      event.stopPropagation();
      if (!this.editor.enabled) {
        event.preventDefault();
        return;
      }
      let imageContainer = document.querySelector("#preview-drag");
      let iframe = document.querySelector("#designEditor");
      var clientRect = iframe.getBoundingClientRect();
      var clientX = clientRect.left;
      imageContainer.style.left = event.clientX + clientX + "px";
      imageContainer.style.top = event.clientY + 60 + "px";
      this.editor.dragNode(this.node);
      this.editor.isDraggable = true;
    },
    handleDragOver(event) {
      this.cancelDefault(event);
      let imageContainer = document.querySelector("#preview-drag");
      let iframe = document.querySelector("#designEditor");
      var clientRect = iframe.getBoundingClientRect();
      var clientX = clientRect.left;
      imageContainer.style.left = event.clientX + clientX + "px";
      imageContainer.style.top = event.clientY + 60 + "px";
      this.nodeService.handleDragOver({
        clientX: event.clientX,
        clientY: event.clientY,
      });
      imageContainer.classList.add("show");
    },
    handleMouseMove(event) {
      if (this.editor.draggedNode) {
        let imageContainer = document.querySelector("#preview-drag");
        let iframe = document.querySelector("#designEditor");
        var clientRect = iframe.getBoundingClientRect();
        var clientX = clientRect.left;
        imageContainer.style.left = event.clientX + clientX + "px";
        imageContainer.style.top = event.clientY + 60 + "px";
        this.nodeService.handleDragOver({
          clientX: event.clientX,
          clientY: event.clientY,
        });
        event.preventDefault();
      }
      event.stopPropagation();
    },
    handleMouseUp(event) {
      event.stopPropagation();
      event.preventDefault();
      const { editor } = this;
      if (editor?.draggedNode) {
        this.cancelDefault(event);
        this.nodeService.handleDrop({
          clientX: event.clientX,
          clientY: event.clientY,
        });
        // this.editor.dragNode(null);
        // this.editor.indicator.hide();
        // this.editor.isDraggable = false;
      }
    },
    handleDrop(event) {
      this.cancelDefault(event);
      this.nodeService.handleDrop({
        clientX: event.clientX,
        clientY: event.clientY,
      });
    },
    handleDragEnd(event) {
      // event.stopPropagation();
      // this.editor.dragNode(null);
      // this.dragging = null;
      // this.editor.indicator.hide();
    },
    // handleDragExit(event) {
    //   let imageContainer = document.querySelector("#preview-drag");
    //   imageContainer.style.display = "none";
    // },
    selectNode(event) {
      event.stopPropagation();
      const { target } = event;
      if (!this.editor.enabled || this.editor.draggedNode) {
        return;
      }
      if (["Heading", "Paragraph"].indexOf(this.node.componentName) == -1) {
        this.$bus.emit("showToolbar", this.$refs, false);
      }
      this.$bus.emit("selectNode");
      this.$bus.emit("showContextMenu", null, null);
      this.editor.selectNode(this.node);
    },
  },
  components: { DragHandle },
};
</script>

<style scoped>
.node-block {
  position: relative;
}
</style>
