<template>
  <div
    data-label="Carousel"
    data-key="carousel"
    section="carousel"
    :id="`element-${node.uuid}`"
    class="sg-wrap module"
    @mouseover="isHovering = true"
    @mouseout="isHovering = false"
  >
    <div
      :id="`slide-${node.uuid}`"
      :class="`sg-module main-slider owl-carousel owl-theme  ${extra_class || ''}`"
      ref="carousel_or_anything"
      :data-navspeed="navSpeed"
      :data-autoplaytimeout="autoplayTimeout"
      :data-autoplayhoverpause="hover_pause ? 1 : 0"
      :data-loop="loop ? 1 : 0"
      :data-autoplay="auto_play ? 1 : 0"
      :data-navlg="desktop.show_pre_next ? 1 : 0"
      :data-navmd="laptop.show_pre_next ? 1 : 0"
      :data-navsm="tablet.show_pre_next ? 1 : 0"
      :data-navxs="mobile.show_pre_next ? 1 : 0"
      :data-collg="desktop.columns"
      :data-colmd="laptop.columns"
      :data-colsm="tablet.columns"
      :data-colxs="mobile.columns"
      :data-stage-paddinglg="getValue('stage_padding', 'desktop', 0)"
      :data-stage-paddingmd="getValue('stage_padding', 'laptop', 0)"
      :data-stage-paddingsm="getValue('stage_padding', 'tablet', 0)"
      :data-stage-paddingxs="getValue('stage_padding', 'mobile', 0)"
      :data-stage-positionlg="getValue('stage_padding_position', 'desktop', '')"
      :data-stage-positionmd="getValue('stage_padding_position', 'laptop', '')"
      :data-stage-positionsm="getValue('stage_padding_position', 'tablet', '')"
      :data-stage-positionxs="getValue('stage_padding_position', 'mobile', '')"
      :data-dotslg="desktop.show_dots ? 1 : 0"
      :data-dotsmd="laptop.show_dots ? 1 : 0"
      :data-dotssm="tablet.show_dots ? 1 : 0"
      :data-dotsxs="mobile.show_dots ? 1 : 0"
      :data-marginlg="desktop.spacing"
      :data-marginmd="laptop.spacing"
      :data-marginsm="tablet.spacing"
      :data-marginxs="mobile.spacing"
    >
      <slot />
    </div>
    <ToolBar
      ref="carousel"
      :toolbar_id="`carousel`"
      class="container-toolbar"
      :class="{ 'container-toolbar-show': isHovering, 'is-dragging': dragging == node.uuid }"
      :isDraggable="false"
    >
    </ToolBar>
    <div v-if="dragging == node.uuid" class="dragging"></div>

    <v-style>
      {{ custom_css }}
      #element-{{ node.uuid }} {
        padding_top: {{desktop["padding_top"]}}px !important;
      }

      @media (max-width: 767px) {

        #element-{{ node.uuid }} .owl-carousel .owl-stage {
          {{ getValue('stage_padding', 'mobile', 0) > 0 ? `padding-left: 0 !important;` : ''}}
        }

        #element-{{ node.uuid }} {
          {{ getDisplayValue('mobile') }}
          {{ getMarginValue('mobile') }}
          {{ getPaddingValue('mobile') }}
          {{ getBackgroundValue('mobile') }}
          {{ getBorderValue('mobile') }}
          {{ getBorderRadiusValue('mobile') }}
          {{ getOpacityValue('mobile') }}
          {{ getShadowValue('mobile') }}
          {{ getOtherStyle('mobile') }}
        }
      }
      @media (max-width: 991px) and (min-width: 768px) {
        #element-{{ node.uuid }} .owl-carousel .owl-stage {
          {{ getValue('stage_padding', 'tablet', 0) > 0 ? `padding-left: 0 !important;` : ''}}
        }

        #element-{{ node.uuid }} {
          {{ getDisplayValue('tablet') }}
          {{ getMarginValue('tablet') }}
          {{ getPaddingValue('tablet') }}
          {{ getBackgroundValue('tablet') }}
          {{ getBorderValue('tablet') }}
          {{ getBorderRadiusValue('tablet') }}
          {{ getOpacityValue('tablet') }}
          {{ getShadowValue('tablet') }}
          {{ getOtherStyle('tablet') }}
        }
      }
      @media (max-width: 1199px) and (min-width: 992px) {
        #element-{{ node.uuid }} .owl-carousel .owl-stage {
          {{ getValue('stage_padding', 'laptop', 0) > 0 ? `padding-left: 0 !important;` : ''}}
        }

        #element-{{ node.uuid }} {
          {{ getDisplayValue('laptop') }}
          {{ getMarginValue('laptop') }}
          {{ getPaddingValue('laptop') }}
          {{ getBackgroundValue('laptop') }}
          {{ getBorderValue('laptop') }}
          {{ getBorderRadiusValue('laptop') }}
          {{ getOpacityValue('laptop') }}
          {{ getShadowValue('laptop') }}
          {{ getOtherStyle('laptop') }}
        }
      }
      @media (min-width: 1200px) {
        #element-{{ node.uuid }} .owl-carousel .owl-stage {
          {{ getValue('stage_padding', 'desktop', 0) > 0 ? `padding-left: 0 !important;` : ''}}
        }

        #element-{{ node.uuid }} {
          {{ getDisplayValue('desktop') }}
          {{ getMarginValue('desktop') }}
          {{ getPaddingValue('desktop') }}
          {{ getBackgroundValue('desktop') }}
          {{ getBorderValue('desktop') }}
          {{ getBorderRadiusValue('desktop') }}
          {{ getOpacityValue('desktop') }}
          {{ getShadowValue('desktop') }}
          {{ getOtherStyle('desktop') }}
        }
      }
      #element-{{ node.uuid }}.sg-carousel-loaded {
        min-height: 0;
        min-width: 0;
        transform: scale(1);
        transition: all 0.6s ease;
      }
    </v-style>
  </div>
</template>

<script>
import Autoplay from "./settings/Autoplay.vue";
import Loop from "./settings/Loop.vue";
import Navigation from "./settings/Navigation.vue";
import Slides from "./settings/Slides.vue";
import ExtraClass from "../../elements/otherSettings/ExtraClass.vue";
import Items from "./settings/Items.vue";

import Visibility from "../../elements/designSettings/Visibility.vue";
import Spacing from "../../elements/designSettings/Spacing.vue";
import Background from "../../elements/designSettings/Background.vue";
import Border from "../../elements/designSettings/Border.vue";
import Corner from "../../elements/designSettings/Corner.vue";
import Shadow from "../../elements/designSettings/Shadow.vue";
import Opacity from "../../elements/designSettings/Opacity.vue";
import elementStyleMixin from "../../elements/elementStyleMixin";
// import defaultImageUrl from "./assets/default_carousel_image.jpg";
// import defaultImageUrl2 from "./assets/default_carousel_image2.jpg";
import { makeId, designStyles, otherProps, otherStyles, actionTypes } from "@/constants";
import ToolBar from "../../elements/utils/ToolBar.vue";
import CarouselItem from "./CarouselItem.vue";
import Vue from "vue";
import Node from "../../../../../components/Node.vue";
import CustomCSS from '../../elements/styleSettings/CustomCSS.vue';
import Animations from '../../elements/designSettings/Animations.vue';


export default {
  mixins: [elementStyleMixin],
  components: { ToolBar, Node, CarouselItem },
  data() {
    return {
      isHovering: false,
    };
  },
  computed: {
    clonedItems: function () {
      const items = []
      this.node.children.map((item)=>{
        items.push(item.uuid)
      })
      return JSON.parse(
        JSON.stringify({
          ...this.$props,
          screen: this.screen,
          children: this.node.children.length,
          isUndo: this.editor.isUndo,
          isRedo: this.editor.isRedo,
          listUuid: JSON.stringify(items)
        })
      );
    },
    dragging: function () {
      return this.editor?.draggedNode?.uuid
    },
  },
  mounted() {
    var $iframe = $("#designEditor").contents();
    var $owl = $iframe.find(`#slide-${this.node.uuid}`);
    var navspeed = $owl.data("navspeed"),
          autoplaytimeout = $owl.data("autoplaytimeout"),
          autoplayhoverpause = $owl.data("autoplayhoverpause"),
          navlg = parseInt($owl.data("navlg")),
          navmd = parseInt($owl.data("navmd")),
          navsm = parseInt($owl.data("navsm")),
          navxs = parseInt($owl.data("navxs")),
          collg = Math.floor($owl.data("collg")),
          colmd = Math.floor($owl.data("colmd")),
          colsm = Math.floor($owl.data("colsm")),
          colxs = Math.floor($owl.data("colxs")),
          dotslg = parseInt($owl.data("dotslg")),
          dotsmd = parseInt($owl.data("dotsmd")),
          dotssm = parseInt($owl.data("dotssm")),
          dotsxs = parseInt($owl.data("dotsxs")),
          marginlg = parseInt($owl.data("marginlg")),
          marginmd = parseInt($owl.data("marginmd")),
          marginsm = parseInt($owl.data("marginsm")),
          marginxs = parseInt($owl.data("marginxs")),
          stagePaddinglg = parseInt($owl.data("stage-paddinglg")),
          stagePaddingmd = parseInt($owl.data("stage-paddingmd")),
          stagePaddingsm = parseInt($owl.data("stage-paddingsm")),
          stagePaddingxs = parseInt($owl.data("stage-paddingxs")),
          marginmd = parseInt($owl.data("marginmd")),
          marginsm = parseInt($owl.data("marginsm")),
          marginxs = parseInt($owl.data("marginxs")),
          autoplay = 0,
          autoRefresh = 0,
          loop = 0;
    $owl.trigger("destroy.owl.carousel");
    $owl.removeClass("owl-center owl-loaded owl-text-select-on");
    $owl.empty();
    const childrens = this.node.children;

    const el = document.createElement("DIV"); // we will mount or nested app to this element
    const iframe = document.getElementById("designEditor");
    const carousel = iframe.contentWindow.document.getElementById(
      `slide-${this.node.uuid}`
    );
    const editor = this.editor;
    for (var i = 0; i < childrens.length; i++) {
      carousel.appendChild(el);
      const item = new Vue({
        name: "CarouselItem",
        //freezing to prevent unnessessary Reactifiation of vNodes
        data: {},
        render(createElement) {
          return createElement(Node, {
            props: {
              node: childrens[i],
            },
            editor: editor,
          });
        },
      });
      item.$mount(el);
    }
    $owl.owlCarousel({
      mouseDrag: false,
      autoplayHoverPause: autoplayhoverpause,
      autoplay: autoplay,
      autoRefresh: autoRefresh,
      autoplaySpeed: navspeed,
      autoplayTimeout: autoplaytimeout,
      loop: loop,
      navSpeed: navspeed,
      autoWidth: !1,
      responsiveClass: true,
      responsive: {
        0: {
          items: colxs,
          nav: navxs > 0 ? true : false,
          dots: dotsxs > 0 ? true : false,
          margin: marginxs,
          stagePadding: stagePaddingxs
        },
        768: {
          items: colsm,
          nav: navsm > 0 ? true : false,
          dots: dotssm > 0 ? true : false,
          margin: marginsm,
          stagePadding: stagePaddingsm
        },
        992: {
          items: colmd,
          nav: navmd > 0 ? true : false,
          dots: dotsmd > 0 ? true : false,
          margin: marginmd,
          stagePadding: stagePaddingmd
        },
        1200: {
          items: collg,
          nav: navlg > 0 ? true : false,
          dots: dotslg > 0 ? true : false,
          margin: marginlg,
          stagePadding: stagePaddinglg
        },
      }
    });
  },
  props: {
    ...otherProps,
    height: Number,
    interval: Number,
    slides: Array,
    auto_play: Boolean,
    loop: Boolean,
    hover_pause: Boolean,
    autoplayTimeout: Number,
    extra_class: String,
    desktop: Object,
    laptop: Object,
    tablet: Object,
    mobile: Object,
    screen: String,
    
    items: Array,
    navSpeed: Number
  },
  watch: {
    dragging: function (newValue, oldValue) {
      var $iframe = $("#designEditor").contents();
      var $element = $iframe.find(`#element-${this.node.uuid}`);
      var $content = $element.find(`.sg-module`);
      if (newValue && newValue == this.node.uuid) {
        setTimeout(() => {
        $element.css("padding","0px 0px 0px 0px");
        $element.css("margin","0px 0px 0px 0px");
        $content.hide();
        },100);
      }else if (oldValue == this.node.uuid){
        $element.css("padding","");
        $element.css("margin","");
        $content.show();
        var $owl = $iframe.find(`#slide-${this.node.uuid}`);
        $owl.owlCarousel('refresh');
      }
    },
    clonedItems: {
      handler: function (val, oldVal) {
        var check = this.listenChange(val, oldVal);
        console.log(check,'check')
        if (check) {
          var $iframe = $("#designEditor").contents();
          var $owl = $iframe.find(`#slide-${this.node.uuid}`);
          const {navSpeed, autoplayTimeout,hover_pause,desktop, laptop,tablet,mobile, screen} = this;
          var navspeed = navSpeed,
          autoplaytimeout = autoplayTimeout,
          autoplayhoverpause = hover_pause,
          navlg = desktop.show_pre_next,
          navmd = laptop.show_pre_next,
          navsm = tablet.show_pre_next,
          navxs = mobile.show_pre_next,
          collg = desktop.columns,
          colmd = laptop.columns,
          colsm = tablet.columns,
          colxs = mobile.columns,
          dotslg = desktop.show_dots,
          dotsmd = laptop.show_dots,
          dotssm = tablet.show_dots,
          dotsxs = mobile.show_dots,
          marginlg = parseInt(desktop.spacing),
          marginmd = parseInt(laptop.spacing),
          marginsm = parseInt(tablet.spacing),
          marginxs = parseInt(mobile.spacing),
          stagePaddinglg = parseInt(desktop.stage_padding || 0),
          stagePaddingmd = parseInt(laptop.stage_padding || 0),
          stagePaddingsm = parseInt(tablet.stage_padding || 0),
          stagePaddingxs = parseInt(mobile.stage_padding || 0),
          autoplay = 0,
          autoRefresh = 0,
          loop = 0;
          $owl.trigger("destroy.owl.carousel");
          $owl.removeClass("owl-center owl-loaded owl-text-select-on");
          $owl.empty();

          switch (screen) {
            case "laptop":
              collg =  laptop.columns
              navlg =  laptop.show_pre_next
              dotslg =  laptop.show_dots
              marginlg =  parseInt(laptop.spacing)
              stagePaddinglg = parseInt(laptop.stage_padding)
              break;
            case "tablet":
              collg =  tablet.columns
              navlg =  tablet.show_pre_next
              dotslg =  tablet.show_dots
              marginlg =  parseInt(tablet.spacing)
              stagePaddinglg = parseInt(tablet.stage_padding)
              break;
            case "mobile":
              collg =  mobile.columns
              navlg =  mobile.show_pre_next
              dotslg =  mobile.show_dots
              marginlg =  parseInt(mobile.spacing)
              stagePaddinglg = parseInt(mobile.stage_padding)
              break;
          
            default:
              break;
          }
          if (["children","listUuid"].includes(check)) {
            setTimeout(() => {
              var $iframe = $("#designEditor").contents();
              var $owl = $iframe.find(`#slide-${this.node.uuid}`);
              const childrens = this.node.children;
              const el = document.createElement("DIV"); // we will mount or nested app to this element
              const iframe = document.getElementById("designEditor");
              const carousel = iframe.contentWindow.document.getElementById(
                `slide-${this.node.uuid}`
              );
              $owl.empty();

              const editor = this.editor;
              for (var i = 0; i < childrens.length; i++) {
                carousel.appendChild(el);
                const item = new Vue({
                  name: "CarouselItem",
                  //freezing to prevent unnessessary Reactifiation of vNodes
                  data: {},
                  render(createElement) {
                    return createElement(Node, {
                      props: {
                        node: editor.findNode(childrens[i].uuid),
                      },
                      editor: editor,
                    });
                  },
                });
                item.$mount(el);
              }
              setTimeout(() => {
                $owl.owlCarousel({
                  mouseDrag: false,
                  autoplayHoverPause: autoplayhoverpause,
                  autoplay: autoplay,
                  autoRefresh: autoRefresh,
                  autoplaySpeed: navspeed,
                  autoplayTimeout: autoplaytimeout,
                  loop: loop,
                  navSpeed: navspeed,
                  autoWidth: !1,
                  responsiveClass: true,
                  responsive: {
                    0: {
                      items: colxs,
                      nav: navxs,
                      dots: dotsxs,
                      margin: marginxs,
                      stagePadding: stagePaddingxs
                    },
                    768: {
                      items: colsm,
                      nav: navsm,
                      dots: dotssm,
                      margin: marginsm,
                      stagePadding: stagePaddingsm
                    },
                    992: {
                      items: colmd,
                      nav: navmd,
                      dots: dotsmd,
                      margin: marginmd,
                      stagePadding: stagePaddingmd
                    },
                    1200: {
                      items: collg,
                      nav: navlg,
                      dots: dotslg,
                      margin: marginlg,
                      stagePadding: stagePaddinglg
                    },
                  }
                });
              },300)
            }, 200);
          } else {
            const childrens = this.node.children;
            const iframe = document.getElementById("designEditor");
            const carousel = iframe.contentWindow.document.getElementById(
              `slide-${this.node.uuid}`
            );
            const editor = this.editor;
            for (var i = 0; i < childrens.length; i++) {
              const el = document.createElement("DIV"); // we will mount or nested app to this element
              carousel.appendChild(el);
              const item = new Vue({
                name: "CarouselItem",
                //freezing to prevent unnessessary Reactifiation of vNodes
                data: {},
                render(createElement) {
                  return createElement(Node, {
                    props: {
                      node: editor.findNode(childrens[i].uuid),
                    },
                    editor: editor,
                  });
                },
              });
              item.$mount(el);
            }
            $owl.owlCarousel({
              mouseDrag: false,
              autoplayHoverPause: autoplayhoverpause,
              autoplay: autoplay,
              autoRefresh: autoRefresh,
              autoplaySpeed: navspeed,
              autoplayTimeout: autoplaytimeout,
              loop: loop,
              navSpeed: navspeed,
              autoWidth: !1,
              responsiveClass: true,
              responsive: {
                0: {
                  items: colxs,
                  nav: navxs,
                  dots: dotsxs,
                  margin: marginxs,
                  stagePadding: stagePaddingxs
                },
                768: {
                  items: colsm,
                  nav: navsm,
                  dots: dotssm,
                  margin: marginsm,
                  stagePadding: stagePaddingsm
                },
                992: {
                  items: colmd,
                  nav: navmd,
                  dots: dotsmd,
                  margin: marginmd,
                  stagePadding: stagePaddingmd
                },
                1200: {
                  items: collg,
                  nav: navlg,
                  dots: dotslg,
                  margin: marginlg,
                  stagePadding: stagePaddinglg
                },
              }
            });
          }
        }
      },
      deep: true,
    },
  },
  inject: ["node", "editor"],
  craft: {
    defaultProps: {
      ...otherStyles,
      height: 300,
      interval: 3000,
      auto_play: false,
      loop: false,
      hover_pause: true,
      autoplayTimeout: 5000,
      extra_class: "",
      navSpeed: 1200,
      slides: [
        {
          title: "Lorem Ipsum Dolor Sit Amet",
          subtitle: "Curabitur varius lorem nisl, non egestas leo feugiat at.",
          // background: defaultImageUrl,
        },
        {
          title: "Lorem Ipsum Dolor Sit Amet",
          subtitle: "Curabitur varius lorem nisl, non egestas leo feugiat at.",
          // background: defaultImageUrl2,
        },
      ],
      desktop: {
        ...designStyles,
        columns: 3,
        spacing: 5,
        show_dots: true,
        show_pre_next: false,
        padding_top: 30,
        stage_padding: 0
      },
      laptop: {
        ...designStyles,
        columns: 3,
        spacing: 5,
        show_dots: true,
        show_pre_next: false,
        stage_padding: 0
      },
      tablet: {
        ...designStyles,
        columns: 3,
        spacing: 5,
        show_dots: true,
        show_pre_next: false,
        stage_padding: 0
      },
      mobile: {
        ...designStyles,
        columns: 2,
        spacing: 5,
        show_dots: true,
        show_pre_next: false,
        stage_padding: 0
      },
      elementStyle: {
        // "margin-top": 0,
        // "margin-left": 0,
        // "margin-bottom": 0,
        // "margin-right": 0,
        // "border-radius": 0,
        // "box-shadow": {
        //   x: 0,
        //   y: 0,
        //   blur: 0,
        //   spread: 0,
        //   color: "rgba(0,0,0,0)",
        // },
      },
      items: [
        {
          id: makeId(20),
          name: "Tab 1",
          color: "rgb(255, 255, 255)",
          index: "1",
        },
        {
          id: makeId(20),
          name: "Tab 2",
          color: "rgb(255, 255, 255)",
          index: "2",
        },
        {
          id: makeId(20),
          name: "Tab 3",
          color: "rgb(255, 255, 255)",
          index: "3",
        },
        {
          id: makeId(20),
          name: "Tab 4",
          color: "rgb(255, 255, 255)",
          index: "4",
        },
      ],
    },
    settings: {
      settings: {
        Items,
        Slides: { Slides, multi_screen: true },
        Navigation: { Navigation, multi_screen: true },
        Loop,
        Autoplay,
        "Extra Class": ExtraClass, "Custom CSS": CustomCSS,
      },
      design: {
        Visibility,
        Spacing: { Spacing, multi_screen: true },
        Background: { Background, multi_screen: true },
        Border: { Border, multi_screen: true },
        Corner: { Corner, multi_screen: true },
        Shadow: { Shadow, multi_screen: true },
        Opacity: { Opacity, multi_screen: true },
      },
      others: {
        Animations: { Animations, actions: actionTypes.filter(e => ['scroll_into_view', 'after_page_finish_loading'].includes(e.value)) }
      }
    },
  },
  methods: {
    listenChange(val, oldVal) {
      var childs = [
        "items",
        "columns",
        "spacing",
        "show_pre_next",
        "navSpeed",
        "show_dots",
        "stage_padding",
        "stage_padding_position"
      ];
      var parents = [
        "children",
        "screen",
        "desktop",
        "laptop",
        "tablet",
        "mobile",
        "isUndo",
        "isRedo",
        "listUuid"
      ];
      for (let i = 0; i < parents.length; i++) {
        if (typeof val[parents[i]] == "object") {
          for (let j = 0; j < childs.length; j++) {
            if (val[parents[i]][childs[j]] != oldVal[parents[i]][childs[j]]) {
              return `${childs[j]} (${parents[i]})`;
              break;
            }
          }
        } else {
          if (val[parents[i]] != oldVal[parents[i]]) {
            return parents[i];
            break;
          }
        }
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scope>
.el-carousel {
  width: 100%;

  .el-carousel__item {
    background-size: cover;
    background-position: center;
  }

  .content {
    position: absolute;
    bottom: 1.5em;
    left: 2em;
    padding: 20px;
    background: #16191982;
    .title {
      margin: 0;
      font-size: 24px;
      color: white;
      text-shadow: 2px 1px 2px rgba(0, 0, 0, 0.65);
    }
    .subtitle {
      margin: 12px 0 0 0;
      color: rgba(255, 255, 255, 0.88);
      text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.65);
    }
  }
}
</style>
