<template>
  <div label-position="top" size="small" :model="elementProps">
    <div class="d-flex flex-column py-2">
      <span class="setting-title">Horizontal Gap</span>
      <Slider
        :value="getValue('horizontal_gap',elementProps[screen].horizontal_gap)"
        :primaryColor="primaryColor"
        v-bind:changeValue="($event) => updateProps('horizontal_gap', $event)"
        unit="px"
      />
    </div>
    <div class="d-flex flex-column py-2">
      <span class="setting-title">Vertical Gap</span>
      <Slider
        :value="getValue('vertical_gap',elementProps[screen].vertical_gap)"
        :primaryColor="primaryColor"
        v-bind:changeValue="($event) => updateProps('vertical_gap', $event)"
        unit="px"
      />
    </div>
  </div>
</template>

<script>
import mixin from "../../../elements/styleSettings/mixin";
import ColorPicker from "../../../elements/common/ColorPicker.vue";
import Slider from "../../../elements/common/Slider.vue";

export default {
  mixins: [mixin],
  components: { ColorPicker, Slider },
  props: {
    primaryColor: String,
    screen: String
  },
  methods: {
    updateProps(name, value) {
      const screen = this.$props.screen
      const data_screen = Object.assign({}, this.elementProps[screen])
      if(value ==  data_screen[name]) return;
      data_screen[name] = value
      this.elementPropsSetter({ [screen]: data_screen });
    }
  },
};
</script>

<style scoped>
</style>
