import { render, staticRenderFns } from "./Padding.vue?vue&type=template&id=4f51412c&scoped=true&"
import script from "./Padding.vue?vue&type=script&lang=js&"
export * from "./Padding.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f51412c",
  null
  
)

export default component.exports