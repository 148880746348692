<template>
  <el-form label-position="top" size="" :model="elementProps">
    <div class="d-flex flex-column py-2">
      <span class="setting-title">Tab Style</span>
      <Select
        class="mt-2"
        :primaryColor="primaryColor"
        :value="elementProps.tab_style"
        v-bind:changeValue="($event) => updateProps('tab_style', $event)"
        :options="[
          {
            value: 'default',
            label: 'Default',
          },
          {
            value: 'underline',
            label: 'Underline',
          }
        ]"
      />
    </div>
  </el-form>
</template>

<script>
import mixin from "../../../elements/styleSettings/mixin";
import Select from "../../../elements/common/Select.vue";
export default {
  mixins: [mixin],
  components: { Select },
  props: {
    primaryColor: String
  },
  methods: {
    updateProps(name, value) {
      this.elementPropsSetter({ [name]: value });
    },
  },
};
</script>
