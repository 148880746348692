<template>
  <el-form label-position="top" size="medium" :model="elementProps">
    <el-form-item label="Button Text">
      <el-input
        :value="elementProps.text"
        @input="($event) => updateProps('text', $event)"
      />
    </el-form-item>
    <div class="d-flex align-items-center justify-content-between py-2">
      <span class="sgSubTitle">Open In New Tab</span>
      <el-switch
        :value="elementProps.target"
        :active-color="primaryColor"
        inactive-color="#949494"
        active-value="_blank"
        inactive-value="_self"
        @change="updateProps('target', $event)"
      />
    </div>
  </el-form>
</template>

<script>
import mixin from "../../../elements/styleSettings/mixin";

export default {
  mixins: [mixin],
  props: {
    primaryColor: String
  },
  methods: {
    updateProps(name, value) {
      if(value ==  this.elementProps[name]) return;
      this.elementPropsSetter({ [name]: value });
    },
  },
};
</script>

<style scoped>
</style>
