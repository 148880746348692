import { settingMixin } from '@';

export default {
  mixins: [settingMixin],
  methods: {
    styleExist(name) {
      if (this?.$attrs?.name == "Typography" && this.elementProps.typographyActives){
        return this.elementProps.elementStyle[name] !== undefined && this.elementProps.typographyActives.includes(name);
      }
      return this.elementProps.elementStyle[name] !== undefined;
    },
    getStyle(name) {
      return this.elementProps.elementStyle[name];
    },
    setStyle(name, value) {
      this.elementPropsSetter({
        elementStyle: {
          ...this.elementProps.elementStyle,
          [name]: value,
        },
      });
    },
    getValue(name, value, defaultValue) {
      if (["padding_top", "padding_bottom", "padding_left", "padding_right",
        "margin_top", "margin_bottom", "margin_left", "margin_right",].includes(name)) {
        defaultValue = 0
      } else if (name == "font_style") {
        defaultValue = []
      }
      return (value == undefined)? (this.elementProps['desktop'][name] || (defaultValue!=undefined?defaultValue:this.elementProps['desktop'][name])): value;
    },
    labelStyleExist(name) {
      return this.elementProps.elementStyle['label-style'][name] !== undefined;
    },
    mixStyleExist(object,name) {
      return this.elementProps.elementStyle[object][name] !== undefined;
    },
    getMixStyle(object,name) {
      return this.elementProps.elementStyle[object][name];
    },
    getValueLinked(name, value, defaultValue) {
      return (value == undefined)? (this.elementProps['desktop'][name] || (defaultValue!=undefined?defaultValue:this.elementProps['desktop'][name])): value;
    },
  },
  
};
