<template>
  <div label-position="top" size="medium" :model="elementProps">
    <div class="d-flex align-items-center justify-content-between py-2">
      <span class="sgSubTitle">Full Screen</span>
      <el-switch
        :value="getValue('full_screen',elementProps[screen].full_screen)"
        :active-color="primaryColor"
        inactive-color="#949494"
        @change="updateProps('full_screen', $event)"
      />
    </div>
    <div class="d-flex flex-column py-2">
      <span class="setting-title">Width</span>
      <InputWithSelect
        class="mt-2"
        type="number"
        :value="getValue('width',elementProps[screen].width)"
        :primaryColor="primaryColor"
        :options="['px', '%']"
        v-bind:changeValue="($event) => updateProps('width', $event)"
        v-bind:changeUnit="($event) => updateProps('width_type', $event)"
        :unit="getValue('width_type',elementProps[screen].width_type)"
      />
    </div>
    <div class="d-flex flex-column py-2">
      <span class="setting-title">Height</span>
      <InputWithSelect
        class="mt-2"
        type="number"
        :value="getValue('height',elementProps[screen].height)"
        :primaryColor="primaryColor"
        :options="['px', '%']"
        v-bind:changeValue="($event) => updateProps('height', $event)"
        v-bind:changeUnit="($event) => updateProps('height_type', $event)"
        :unit="getValue('height_type',elementProps[screen].height_type)"
      />
    </div>
  </div>
</template>

<script>
import mixin from "../../../elements/styleSettings/mixin";
import Slider from "../../../elements/common/Slider.vue";
import InputWithSelect from "../../../elements/common/InputWithSelect.vue";

export default {
  mixins: [mixin],
  components: { Slider, InputWithSelect },
  props: {
    screen: String,
    primaryColor: String,
  },
  methods: {
    updateProps(name, value) {
      const screen = this.$props.screen;
      const screen_data = Object.assign({}, this.elementProps[screen])
      if(value ==  screen_data[name]) return;
      screen_data[name] = value
      if (name == "full_screen") {
        screen_data['width_type'] = value ? '%' : null
        screen_data['height_type'] = value ? '%' : null
        screen_data['height'] = value ? "100" : null
        screen_data['width'] = value ? "100" : null
      }
      this.elementPropsSetter({[screen]: screen_data});
    },
  },
};
</script>

<style scoped>
</style>
