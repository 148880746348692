var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"label-position":"top","size":"medium","model":_vm.elementProps}},[_c('div',{staticClass:"d-flex align-items-center justify-content-between py-2"},[_vm._m(0),_c('el-switch',{attrs:{"value":_vm.elementProps.show_compare_price,"active-color":_vm.primaryColor,"inactive-color":"#949494"},on:{"change":function($event){return _vm.updatePropsGeneral('show_compare_price', $event)}}})],1),(_vm.elementProps.show_compare_price)?_c('div',{staticClass:"d-flex align-items-center justify-content-between py-2"},[_vm._m(1),_c('el-switch',{attrs:{"value":_vm.elementProps.compare_first,"active-color":_vm.primaryColor,"inactive-color":"#949494"},on:{"change":function($event){return _vm.updatePropsGeneral('compare_first', $event)}}})],1):_vm._e(),(_vm.elementProps.show_compare_price)?_c('div',{staticClass:"d-flex flex-column py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Font Weight")]),_c('Select',{staticClass:"mt-2",attrs:{"primaryColor":_vm.primaryColor,"value":_vm.elementProps[_vm.screen].compare_font_weight,"changeValue":($event) => _vm.updateProps('compare_font_weight', $event),"options":[
        {
          value: '100',
          label: 'Thin 100',
        },
        {
          value: '200',
          label: 'Semi Thin 200',
        },
        {
          value: '300',
          label: 'Light 300',
        },
        {
          value: '400',
          label: 'Regular 400',
        },
        {
          value: '500',
          label: 'Medium 500',
        },
        {
          value: '600',
          label: 'Semi Bold 600',
        },
        {
          value: '700',
          label: 'Bold 700',
        },
        {
          value: '800',
          label: 'Super Bold 800',
        },
        {
          value: '900',
          label: 'Boldest 900',
        },
        {
          value: 'lighter',
          label: 'Lighter',
        },
        {
          value: 'bolder',
          label: 'Bolder',
        },
      ]}})],1):_vm._e(),(_vm.elementProps.show_compare_price)?_c('div',{staticClass:"d-flex flex-column py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Font Size")]),_c('Slider',{staticClass:"mt-2",attrs:{"primaryColor":_vm.primaryColor,"value":Number.parseInt(_vm.elementProps[_vm.screen].compare_font_size),"min":0,"max":999,"step":1,"changeValue":($event) => _vm.updateProps('compare_font_size', $event),"unit":"px","onlyInteger":true}})],1):_vm._e(),(_vm.elementProps.show_compare_price)?_c('div',{staticClass:"d-flex flex-column py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Color")]),_c('ColorPicker',{staticClass:"mt-2",attrs:{"value":_vm.elementProps[_vm.screen].compare_color,"changeColor":($event) => _vm.updateProps('compare_color', $event),"primaryColor":_vm.primaryColor}})],1):_vm._e(),(_vm.elementProps.show_compare_price)?_c('div',{staticClass:"d-flex flex-column py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Price Gaps")]),_c('Slider',{staticClass:"mt-2",attrs:{"primaryColor":_vm.primaryColor,"value":Number.parseInt(_vm.elementProps.compare_gaps),"min":0,"max":44,"step":1,"changeValue":($event) => _vm.updatePropsGeneral('compare_gaps', $event),"unit":"px","onlyInteger":true}})],1):_vm._e(),(_vm.elementProps.show_compare_price)?_c('div',[_c('div',{staticClass:"py-2"},[_c('span',[_vm._v("Font Style")]),_c('ToggleButton',{attrs:{"primaryColor":_vm.primaryColor,"value":_vm.elementProps[_vm.screen].compare_font_style,"changeValue":($event) => _vm.updateProps('compare_font_style', $event),"canRemove":true,"options":[
          { value: 'bold', label: 'Bold' },
          { value: 'italic', label: 'Italic' },
        ]}})],1)]):_vm._e(),(_vm.elementProps.show_compare_price)?_c('div',{staticClass:"d-flex flex-column py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Font")]),_c('Select',{staticClass:"mt-2",attrs:{"primaryColor":_vm.primaryColor,"value":_vm.elementProps.compare_font_family,"changeValue":($event) => _vm.updatePropsGeneral('compare_font_family', $event),"hasAction":true,"onAction":_vm.handleShowModal,"actionLabel":"Open Font Manager","options":_vm.getListFont()}})],1):_vm._e(),(_vm.elementProps.show_compare_price)?_c('div',{staticClass:"d-flex align-items-center justify-content-between py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Line Through")]),_c('el-switch',{attrs:{"value":_vm.elementProps[_vm.screen].show_line_through,"active-color":_vm.primaryColor,"inactive-color":"#949494"},on:{"change":function($event){return _vm.updateProps('show_line_through', $event)}}})],1):_vm._e(),(_vm.elementProps.show_compare_price && _vm.elementProps[_vm.screen].show_line_through)?_c('div',{staticClass:"d-flex flex-column py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Line Through Color")]),_c('ColorPicker',{staticClass:"mt-2",attrs:{"value":_vm.elementProps[_vm.screen].line_through_color,"changeColor":($event) => _vm.updateProps('line_through_color', $event),"primaryColor":_vm.primaryColor}})],1):_vm._e(),(_vm.elementProps.show_compare_price && _vm.elementProps[_vm.screen].show_line_through)?_c('div',{staticClass:"d-flex flex-column py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Line Through Position")]),_c('Slider',{staticClass:"mt-2",attrs:{"primaryColor":_vm.primaryColor,"value":Number.parseInt(_vm.elementProps[_vm.screen].line_through_position),"min":-10,"max":10,"step":1,"changeValue":($event) => _vm.updateProps('line_through_position', $event),"unit":"px"}})],1):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('span',{staticClass:"setting-title"},[_vm._v("Compare Price")]),_c('div',{staticClass:"sgDesc mt-2"},[_vm._v(" Show or hide compare price if current product is on sale. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('span',{staticClass:"setting-title"},[_vm._v("Compare First")]),_c('div',{staticClass:"sgDesc mt-2"},[_vm._v(" Display compare price before or after regular price. ")])])
}]

export { render, staticRenderFns }