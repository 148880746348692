<template>
  <div
    data-label="Breadcrumbs"
    data-key="breadcrumbs"
    class="sg-wrap module"
    :id="`element-${node.uuid}`"
    @mouseover="isHovering = true"
    @mouseout="isHovering = false"
    :class="{ 'dragging': dragging == node.uuid }"

  >

    <div
      :class="extra_class"
      class="breadcrumb_wrapper"
    >
        <nav
          class="sg_breadcrumbs sg_gs-text-paragraph-2"
          role="navigation"
          aria-label="breadcrumbs"
        >
          <a class="sg_breadcrumbs-link" href="">Home</a>
          <span class="sg_breadcrumbs-symbol">/</span>
          <a
            class="sg_current-page sg_gs-text-paragraph-2"
            title="Sample page 101"
            href="javascript:void(0)"
          >
            Sample page 101
          </a>
        </nav>
      </div>

    <ToolBar
      ref="breadcrumbs"
      :toolbar_id="`breadcrumbs`"
      class="container-toolbar"
      :class="{ 'container-toolbar-show': isHovering, 'is-dragging': dragging == node.uuid }"
    >
    </ToolBar>

    <v-style> 
      {{ custom_css }}
      
      @media (max-width: 767px){ 
        #element-{{ node.uuid }} .sg_breadcrumbs {
          text-align: {{ getValue('alignment','mobile') }};
        }
        #element-{{ node.uuid }} .sg_breadcrumbs-symbol {
          padding: 0 {{ getValue('spacing_symbol','mobile') }}px;
          {{ getValue('color', 'mobile') ? `color: ${getValue('color','mobile')};` : '' }}
        }
        #element-{{ node.uuid }} .sg_breadcrumbs-link {
          font-weight: {{ getValue('font_weight','mobile') }};
          {{ getValue('font_size', 'mobile') > 0 ? `font-size: ${getValue('font_size','mobile')}px;` : '' }}
          font-family: {{ getValue('font_family','mobile') }};
          {{ getValue('color', 'mobile') ? `color: ${getValue('color','mobile')};` : '' }}
          text-transform: {{ getValue('text_transform','mobile') }};
        } 
        #element-{{ node.uuid }} .sg_current-page {
          font-weight: {{ getValue('cp_font_weight','mobile') }};
          {{ getValue('cp_font_size', 'mobile') > 0 ? `font-size: ${getValue('cp_font_size','mobile')}px;` : '' }}
          font-family: {{ getValue('font_family','mobile') }};
          {{ getValue('cp_color', 'mobile') ? `color: ${getValue('cp_color','mobile')};` : '' }}
          text-transform: {{ getValue('text_transform','mobile') }};
        }
        #element-{{ node.uuid }} {
          {{ getDisplayValue('mobile') }}
          {{ getMarginValue('mobile') }}
          {{ getPaddingValue('mobile') }}
          {{ getBackgroundValue('mobile') }}
          {{ getBorderValue('mobile') }}
          {{ getBorderRadiusValue('mobile') }}
          {{ getOpacityValue('mobile') }}
          {{ getShadowValue('mobile') }}
          {{ getOtherStyle('mobile') }}
        } 
      } 
      @media (max-width: 991px) and (min-width: 768px){ 
        #element-{{ node.uuid }} .sg_breadcrumbs {
          text-align: {{ getValue('alignment','tablet') }};
        }
        #element-{{ node.uuid }} .sg_breadcrumbs-symbol {
          padding: 0 {{ getValue('spacing_symbol','tablet') }}px;
          {{ getValue('color', 'tablet') ? `color: ${getValue('color','tablet')};` : '' }}
        }
        #element-{{ node.uuid }} .sg_breadcrumbs-link {
          font-weight: {{ getValue('font_weight','tablet') }};
          {{ getValue('font_size', 'tablet') > 0 ? `font-size: ${getValue('font_size','tablet')}px;` : '' }}
          font-family: {{ getValue('font_family','tablet') }};
          {{ getValue('color', 'tablet') ? `color: ${getValue('color','tablet')};` : '' }}
          text-transform: {{ getValue('text_transform','tablet') }};
        } 
        #element-{{ node.uuid }} .sg_current-page {
          font-weight: {{ getValue('cp_font_weight','tablet') }};
          {{ getValue('cp_font_size', 'tablet') > 0 ? `font-size: ${getValue('cp_font_size','tablet')}px;` : '' }}
          font-family: {{ getValue('font_family','tablet') }};
          {{ getValue('cp_color', 'tablet') ? `color: ${getValue('cp_color','tablet')};` : '' }}
          text-transform: {{ getValue('text_transform','tablet') }};
        }
        #element-{{ node.uuid }} {
          {{ getDisplayValue('tablet') }}
          {{ getMarginValue('tablet') }}
          {{ getPaddingValue('tablet') }}
          {{ getBackgroundValue('tablet') }}
          {{ getBorderValue('tablet') }}
          {{ getBorderRadiusValue('tablet') }}
          {{ getOpacityValue('tablet') }}
          {{ getShadowValue('tablet') }}
          {{ getOtherStyle('tablet') }}
        }
      } 
      @media (max-width: 1199px) and (min-width: 992px){ 
        #element-{{ node.uuid }} .sg_breadcrumbs {
          text-align: {{ getValue('alignment','laptop') }};
        }
        #element-{{ node.uuid }} .sg_breadcrumbs-symbol {
          padding: 0 {{ getValue('spacing_symbol','laptop') }}px;
          {{ getValue('color', 'laptop') ? `color: ${getValue('color','laptop')};` : '' }}
        }
        #element-{{ node.uuid }} .sg_breadcrumbs-link {
          font-weight: {{ getValue('font_weight','laptop') }};
          font-family: {{ getValue('font_family','laptop') }};
          {{ getValue('font_size', 'laptop') > 0 ? `font-size: ${getValue('font_size','laptop')}px;` : '' }}
          {{ getValue('color', 'laptop') ? `color: ${getValue('color','laptop')};` : '' }}
          text-transform: {{ getValue('text_transform','laptop') }};
        } 
        #element-{{ node.uuid }} .sg_current-page {
          font-weight: {{ getValue('cp_font_weight','laptop') }};
          font-size: {{ getValue('cp_font_size','laptop') }}px;
          font-family: {{ getValue('font_family','laptop') }};
          {{ getValue('cp_font_size', 'laptop') > 0 ? `font-size: ${getValue('cp_font_size','laptop')}px;` : '' }}
          {{ getValue('cp_color', 'laptop') ? `color: ${getValue('cp_color','laptop')};` : '' }}
          text-transform: {{ getValue('text_transform','laptop') }};
        }
        #element-{{ node.uuid }} {
          {{ getDisplayValue('laptop') }}
          {{ getMarginValue('laptop') }}
          {{ getPaddingValue('laptop') }}
          {{ getBackgroundValue('laptop') }}
          {{ getBorderValue('laptop') }}
          {{ getBorderRadiusValue('laptop') }}
          {{ getOpacityValue('laptop') }}
          {{ getShadowValue('laptop') }}
          {{ getOtherStyle('laptop') }}
        }
      } 
      @media (min-width: 1200px) {
        #element-{{ node.uuid }} .sg_breadcrumbs {
          text-align: {{ desktop.alignment }};
        }
        #element-{{ node.uuid }} .sg_breadcrumbs-symbol {
          padding: 0 {{ desktop.spacing_symbol }}px;
          {{ getValue('color', 'desktop') ? `color: ${getValue('color','desktop')};` : '' }}
        }
        #element-{{ node.uuid }} .sg_breadcrumbs-link {
          font-weight: {{ desktop.font_weight }};
          font-family: {{ desktop.font_family }};
          {{ getValue('font_size', 'desktop') > 0 ? `font-size: ${getValue('font_size','desktop')}px;` : '' }}
          {{ getValue('color', 'desktop') ? `color: ${getValue('color','desktop')};` : '' }}
          text-transform: {{ desktop.text_transform }};
        } 
        #element-{{ node.uuid }} .sg_current-page {
          font-weight: {{ desktop.cp_font_weight }};
          font-family: {{ desktop.font_family }};
          {{ getValue('cp_font_size', 'desktop') > 0 ? `font-size: ${getValue('cp_font_size','desktop')}px;` : '' }}
          {{ getValue('cp_color', 'desktop') ? `color: ${getValue('cp_color','desktop')};` : '' }}
          text-transform: {{ desktop.text_transform }};
        }
        #element-{{ node.uuid }} {
          {{ getDisplayValue('desktop') }}
          {{ getMarginValue('desktop') }}
          {{ getPaddingValue('desktop') }}
          {{ getBackgroundValue('desktop') }}
          {{ getBorderValue('desktop') }}
          {{ getBorderRadiusValue('desktop') }}
          {{ getOpacityValue('desktop') }}
          {{ getShadowValue('desktop') }}
          {{ getOtherStyle('desktop') }}
        }
      }
    </v-style>
  </div>
</template>

<script>
import ToolBar from "../../elements/utils/ToolBar.vue";

import elementStyleMixin from "../../elements/elementStyleMixin";
import ExtraClass from "../../elements/otherSettings/ExtraClass.vue";
import AlignmentBase from "../../elements/styleSettings/AlignmentBase.vue";
import Typography from "../../elements/styleSettings/Typography.vue";
import CurrentPage from "./settings/CurrentPage.vue";
import SpacingSymbol from "./settings/SpacingSymbol.vue";

import Visibility from "../../elements/designSettings/Visibility.vue";
import Spacing from "../../elements/designSettings/Spacing.vue";
import Background from "../../elements/designSettings/Background.vue";
import Border from "../../elements/designSettings/Border.vue";
import Corner from "../../elements/designSettings/Corner.vue";
import Shadow from "../../elements/designSettings/Shadow.vue";
import Opacity from "../../elements/designSettings/Opacity.vue";
import { designStyles, otherProps, otherStyles, actionTypes } from "@/constants";
import CustomCSS from '../../elements/styleSettings/CustomCSS.vue';
import Animations from '../../elements/designSettings/Animations.vue';

export default {
  components: { ToolBar },
  data() {
    return {
      isHovering: false,
    };
  },
  inject: ["node",'editor'],
  mixins: [elementStyleMixin],
  props: {
    ...otherProps,
    extra_class: String,
    component: String,
    desktop: Object,
    laptop: Object,
    mobile: Object,
    tablet: Object,
  },
  computed: {
    dragging: function () {
      return this.editor?.draggedNode?.uuid
    },
  },
  craft: {
    defaultProps: {
      ...otherStyles,
      typographyActives: ['font-weight', 'font-size', 'font-family', 'color', 'text-transform'],
      extra_class: "",
      desktop: {
        ...designStyles,
        padding_top: 12,
        padding_bottom: 12,
        font_weight: '400',
        font_size: 16,
        font_family: 'inherit',
        color: 'rgb(0, 0, 0)',
        text_transform: 'none',
        spacing_symbol: 4,
        cp_font_weight: '400',
        cp_font_size: 16,
        cp_color: 'rgb(160, 160, 160)',
        alignment: 'left'
      },
      laptop: {
        ...designStyles,
      },
      tablet: {
        ...designStyles,
      },
      mobile: {
        ...designStyles,
      },
      elementStyle: {
      },
    },
    settings: {
      settings: {
        Typography: {Typography, multi_screen: true},
        "Spacing with Symbol": {"Spacing with Symbol": SpacingSymbol, multi_screen: true},
        "Current Page": {"Current Page": CurrentPage, multi_screen: true},
        Alignment: {Alignment: AlignmentBase, multi_screen: true},
        "Extra Class": ExtraClass, "Custom CSS": CustomCSS,
      },
      design: {
        Visibility,
        Spacing: { Spacing, multi_screen: true },
        Background: { Background, multi_screen: true },
        Border: { Border, multi_screen: true },
        Corner: { Corner, multi_screen: true },
        Shadow: { Shadow, multi_screen: true },
        Opacity: { Opacity, multi_screen: true },
      },
      others: {
        Animations: { Animations, actions: actionTypes }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
