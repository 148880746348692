<template>
  <div label-position="top" size="mini" :model="elementProps">
    <div class="py-2">
      <span class="setting-title">Columns Gap</span>
      <Slider 
        :primaryColor="primaryColor"
        :value="Number.parseInt(elementProps.column_gap)"
        :min="0"
        :max="150"
        :step="1"
        v-bind:changeValue="($event) => updateProps('column_gap', $event)"
        unit="px"
        :onlyInteger="true"
      />
    </div>
  </div>
</template>

<script>
import mixin from '../styleSettings/mixin';
import Slider from '../common/Slider.vue';
export default {
  mixins: [mixin],
  components: {
    Slider
  },
  props: {
    primaryColor: String,
  },
  methods: {
    updateProps(name, value) {
      if(String(value) ==  this.elementProps[name]) return;
      this.elementPropsSetter({ [name]: String(value) });
    }
  }
};
</script>

<style>
</style>
