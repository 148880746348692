<template>
  <div id="app_landing_page">
    <ToolbarEditor
      :isOpen="show"
      :editor="anchorEl ? anchorEl.editor : null"
      placement="top"
      :node="node"
      :refLink="$refs?.link?.$el"
      :iframe="$refs.refIframe"
      :screen="state.screen"
    />
    <ToolbarLink
      :isOpen="show && showToolbarLink"
      ref="link"
      :iframe="$refs.refIframe"
      :node="node"
      :editor="anchorEl ? anchorEl.editor : null"
    />
    <ModalLiquid
      :node="liquidNode"
      :show="showLiquidModal"
      :handleClose="handleCloseLiquid"
      :handleSave="saveLiquidProps"
    />
    <ModalPickLink
      :node="buttonNode"
      :dialogVisible="showButtonModal"
      :handleClose="handleCloseButtonModal"
      :handleSave="saveButtonProps"
      :primaryColor="buttonNode ? (this.checkNodeDynamic(buttonNode) ? '#9900FF' : sections.find(e => e.title == (buttonNode.props?.component || buttonNode.componentName))?.color) : '#04aa6d'"
    />
    <ModalEditMenu
      :node="headerNode"
      :dialogVisible="showMenuModal"
      :handleClose="actionHideMenuModal"
      :handleSave="actionHandleEditMenu"
    />
    <ModalLibrary
      :config="modalLibaryData"
      :show="showModalLibrary"
      :editor="editor"
    />
    <ContextMenu :config="contextMenuData" :show="showContextMenu" />
    <ModalIconPicker
      :dialogVisible="showColorPicker"
      :handleClose="hideColorPicker"
      :handleSave="setIconForNode"
    />
    <ModalFontManager
      :dialogVisible="showFontManager"
      :handleClose="hideModelFontManager"
      :handleSave="handleSaveFont"
      :fonts="fonts"
      :editor="editor"
      v-if="editor?.fonts"
    />

    <ModalProductPicker
      :dialogVisible="showProductPicker"
      :handleClose="hideModalProductPicker"
      :handleSave="handlePickProduct"
    />

    <Loader :visible="visibleLoader" />

    <ModalBlogPicker
      :dialogVisible="showBlogPicker"
      :handleClose="hideModalBlogPicker"
      :handleSave="handlePickBlog"
    />

    <ModalCollectionPicker
      :dialogVisible="showCollectionPicker"
      :handleClose="hideModalCollectionPicker"
      :handleSave="handlePickCollection"
    />

    <Editor
      component="div"
      :resolverMap="resolverMap"
      :import="dataImport"
      v-if="dataImport"
      :importPopUp="dataPopup"
      :fonts="dataFonts"
      :animations="dataAnimations"
      ref="editor"
    >
      <DragPreview />
      <Navbar
        :screen="state.screen"
        v-bind:changeScreen="onChangeScreen"
        :iframe="$refs.refIframe"
        :template="detail_template"
        v-bind:setTemplate="setTemplate"
      />
      <div class="d-flex">
        <ElementSidebar
          :screen="state.screen"
          v-bind:changeScreen="onChangeScreen"
          :activeFonts="editor?.fonts || []"
          :product="product"
          :collection="collection"
          :blog="blog"
          :showLayout="showLayout"
          :setShowLayout="setShowLayout"
          :showOtherSetting="showOtherSetting"
          :setOtherSetting="setOtherSetting"
        />
        <div class="preview" v-scroll="onScroll" ref="divPreview">
          <i-frame id="designEditor" :class="state.screen" ref="refIframe">
            <Preview :screen="state.screen" />
            <!-- <PopupLayer component="div" :screen="state.screen" />
            <FrameFloating component="div" :screen="state.screen" /> -->
          </i-frame>
        </div>
      </div>
    </Editor>

    <div class="overlay-min-resize">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="80"
        height="80"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="#04AA6D"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
        <polyline points="16 4 20 4 20 8"></polyline>
        <line x1="14" y1="10" x2="20" y2="4"></line>
        <polyline points="8 20 4 20 4 16"></polyline>
        <line x1="4" y1="20" x2="10" y2="14"></line>
        <polyline points="16 20 20 20 20 16"></polyline>
        <line x1="14" y1="14" x2="20" y2="20"></line>
        <polyline points="8 4 4 4 4 8"></polyline>
        <line x1="4" y1="4" x2="10" y2="10"></line>
      </svg>
      <h3 style="color: #fff">Your browser is too small</h3>
      <span class="mt-2 text-center" style="color: #ccc"
        >Resize your browser to be at least 1024px wide to get back into design
        mode.</span
      >
    </div>
  </div>
</template>

<script>
import { Editor, Canvas } from "@";
import PopupLayer from "../../components/PopupLayer.vue";
import FrameFloating from "../../components/FrameFloating.vue";

import SettingSidebar from "./components/SettingSidebar.vue";
import ElementSidebar from "./components/ElementSidebar.vue";
import Navbar from "./components/Navbar.vue";
import DragPreview from "../../components/DragPreview.vue";
import Preview from "./components/Preview.vue";
import WrapperContainer from "./components/elements/WrapperContainer.vue";
import Container from "./components/elements/Container.vue";
import ContainerChild from "./components/elements/ContainerChild.vue";
import Paragraph from "./components/elements/Paragraph.vue";
import Heading from "./components/elements/Heading.vue";
import Picture from "./components/elements/Picture.vue";
import Carousel from "./components/modules/stack/Carousel.vue";
import CarouselItem from "./components/modules/stack/CarouselItem.vue";
import Header from "./components/modules/header/Header.vue";
import HeaderButton from "./components/modules/header/HeaderButton.vue";
import ToolbarEditor from "../../components/ToolbarEditor.vue";
import ToolbarLink from "../../components/ToolbarLink.vue";
import Iframe from "./components/Iframe/Iframe.vue";
import Icon from "./components/elements/Icon.vue";
import Button from "./components/elements/Button.vue";
import Liquid from "./components/modules/liquid/Liquid.vue";
import Separator from "./components/elements/Separator.vue";
import Youtube from "./components/modules/video/Youtube.vue";
import Product from "./components/modules/product/Product.vue";
import ProductImage from "./components/modules/product/ProductImage.vue";
import ProductTitle from "./components/modules/product/ProductTitle.vue";
import ProductPrice from "./components/modules/product/ProductPrice.vue";
import ProductCartButton from "./components/modules/product/ProductCartButton.vue";
import ProductSKU from "./components/modules/product/ProductSKU.vue";
import ProductVendor from "./components/modules/product/ProductVendor.vue";
import Banner from "./components/modules/banner/Banner.vue";
import Parallax from "./components/elements/Parallax.vue";
import ProductDescription from "./components/modules/product/ProductDescription.vue";
import ProductViewMore from "./components/modules/product/ProductViewMore.vue";
import ProductVariants from "./components/modules/product/ProductVariants.vue";
import Paypal from "./components/modules/product/Paypal.vue";
import ProductStockCounter from "./components/modules/product/ProductStockCounter.vue";
import ProductQuantity from "./components/modules/product/ProductQuantity.vue";
import ProductImageList from "./components/modules/product/ProductImageList.vue";
import Newsletter from "./components/modules/formField/Newsletter.vue";
import ContactForm from "./components/modules/formField/ContactForm.vue";
import QRForm from "./components/modules/formField/QRForm.vue";
import QRInfo from "./components/modules/formField/QRInfo.vue";
import QRImage from "./components/modules/formField/QRImage.vue";
import ContactQRForm from "./components/modules/formField/ContactQRForm.vue";
import Progress from "./components/modules/progress/Progress.vue";
import TextField from "./components/elements/TextField.vue";
import CheckboxField from "./components/elements/CheckboxField.vue";
import SelectField from "./components/elements/SelectField.vue";
import EmailField from "./components/elements/EmailField.vue";
import SubmitButton from "./components/elements/SubmitButton.vue";
import TextArea from "./components/elements/TextArea.vue";
import Counter from "./components/modules/counter/Counter.vue";
import CountdownTimer from "./components/modules/booster/CountdownTimer.vue";
import IconListHoz from "./components/modules/IconListHoz/IconListHoz.vue";
import IconHozItem from "./components/modules/IconListHoz/IconHozItem.vue";

import IconList from "./components/modules/IconList/IconList.vue";
import IconListItem from "./components/modules/IconList/IconListItem.vue";

import ShippingAddress from "./components/elements/checkout/ShippingAddress.vue";
import BillingAddress from "./components/elements/checkout/BillingAddress.vue";
import Payment from "./components/elements/checkout/Payment.vue";
import PaymentButton from "./components/elements/checkout/PaymentButton.vue";
import OrderSummary from "./components/elements/checkout/OrderSummary.vue";

import Pricing from "./components/modules/pricing/Pricing.vue";
import PricingItem from "./components/modules/pricing/PricingItem.vue";
import GoogleMaps from "./components/modules/GoogleMaps/GoogleMaps.vue";
import Accordion from "./components/modules/accordion/Accordion.vue";
import AccordionItem from "./components/modules/accordion/AccordionItem.vue";
import ModalIconPicker from "../../components/ModalIconPicker.vue";
import ModalFontManager from "@/components/ModalFontManager.vue";
import ModalProductPicker from "@/components/ModalProductPicker.vue";
import ModalBlogPicker from "@/components/ModalBlogPicker.vue";
import ModalCollectionPicker from "@/components/ModalCollectionPicker.vue";
import ModalLiquid from '@/components/ModalLiquid.vue';
import ModalPickLink from "@/components/ModalPickLink.vue";
import ModalEditMenu from "@/components/ModalEditMenu.vue";
import Loader from "@/components/Loader.vue";

import Popup from "./components/modules/popup/Popup.vue";

import Breadcrumbs from "./components/modules/breadcrumbs/Breadcrumbs.vue";
import ProductList from "./components/modules/product/ProductList.vue";
import RelatedProduct from "./components/modules/product/RelatedProduct.vue";

import { reactive } from "vue";
import {
  GOOGLE_FONT_API_KEY,
  GOOGLE_FONT_URL,
  productData,
  collectionData,
  homeData,
  checkoutData
} from "@/constants";
import { request } from "../../services";
import axios from "axios";

import ArticleList from "./components/modules/article/ArticleList.vue";
import Article from "./components/modules/article/Article.vue";
import ArticleImage from "./components/modules/article/ArticleImage.vue";
import ArticleContent from "./components/modules/article/ArticleContent.vue";
import ArticleTitle from "./components/modules/article/ArticleTitle.vue";
import ArticleDate from "./components/modules/article/ArticleDate.vue";
import BlogTitle from "./components/modules/article/BlogTitle.vue";
import BlogContent from "./components/modules/article/BlogContent.vue";

import ModalLibrary from "../../components/ModalLibrary.vue";

import ContextMenu from "../../components/ContextMenu.vue";

import Row from "./components/elements/Row.vue";
import Column from "./components/elements/Column.vue";

import Tabs from "./components/modules/tabs/Tabs.vue";
import TabContent from "./components/modules/tabs/TabContent.vue";

import CollectionBanner from "./components/modules/collections/CollectionBanner.vue";
import CollectionTitle from "./components/modules/collections/CollectionTitle.vue";
import CollectionDescription from "./components/modules/collections/CollectionDescription.vue";
import CollectionToolbar from "./components/modules/collections/CollectionToolbar.vue";
import Paginator from "./components/modules/collections/Paginator.vue";
import NodeService from "../../core/services/NodeService";
import FloatingButton from "./components/modules/popup/FloatingButton.vue";
import moment from "moment";
import Cookies from "js-cookie";
import { toolbarType } from "@/constants";

export default {
  name: "App",
  watch: {
    show() {
      if (!this.show && this.showToolbarLink) {
        this.showToolbarLink = false;
      }
    },
  },
  components: {
    DragPreview,
    Editor,
    PopupLayer,
    SettingSidebar,
    ElementSidebar,
    Navbar,
    Preview,
    ToolbarEditor,
    ToolbarLink,
    ModalIconPicker,
    ModalLiquid,
    ModalPickLink,
    ModalEditMenu,
    ModalFontManager,
    "i-frame": Iframe,
    ModalProductPicker,
    ModalCollectionPicker,
    Loader,
    ModalBlogPicker,
    ModalLibrary,
    ContextMenu,
    FrameFloating,
  },
  setup(props) {
    const state = reactive({
      screen: "desktop",
    });

    const onChangeScreen = (screen) => {
      state.screen = screen;
    };

    return {
      state,
      onChangeScreen,
    };
  },
  data() {
    return {
      resolverMap: {
        Canvas,
        WrapperContainer,
        Container,
        ContainerChild,
        Paragraph,
        Heading,
        Header,
        Liquid,
        HeaderButton,
        Picture,
        Carousel,
        CarouselItem,
        Icon,
        Button,
        Separator,
        Progress,
        Youtube,
        Product,
        Paypal,
        ProductImage,
        ProductTitle,
        ProductPrice,
        ProductCartButton,
        ProductSKU,
        ProductVendor,
        Banner,
        Parallax,
        ProductDescription,
        ProductViewMore,
        ProductVariants,
        Pricing,
        PricingItem,
        Accordion,
        AccordionItem,
        Tabs,
        TabContent,
        GoogleMaps,
        ProductStockCounter,
        ProductQuantity,
        ProductImageList,
        Newsletter,
        TextField,
        CheckboxField,
        SelectField,
        EmailField,
        SubmitButton,
        ContactForm,
        TextArea,
        CountdownTimer,
        Counter,
        IconListHoz,
        IconHozItem,
        IconList,
        IconListItem,
        Popup,
        Breadcrumbs,
        ProductList,
        RelatedProduct,
        ArticleList,
        Article,
        ArticleImage,
        ArticleContent,
        ArticleTitle,
        ArticleDate,
        BlogTitle,
        BlogContent,
        Row,
        Column,
        QRForm,
        QRInfo,
        QRImage,
        ContactQRForm,
        CollectionBanner,
        CollectionTitle,
        CollectionDescription,
        CollectionToolbar,
        Paginator,
        FloatingButton,
        ShippingAddress,
        BillingAddress,
        Payment,
        PaymentButton,
        OrderSummary,
      },
      homeData,
      productData,
      collectionData,
      checkoutData,
      show: false,
      showToolbarLink: false,
      anchorEl: null,
      node: null,
      headerNode: null,
      showColorPicker: false,
      showFontManager: false,
      showProductPicker: false,
      showBlogPicker: false,
      showCollectionPicker: false,
      showMenuModal: false,
      node2: null,
      node3: null,
      fonts: [],
      apiUrl: process.env.VUE_APP_API_URL,
      dataImport: null,
      product: {},
      blog: {},
      collection: {},
      sections: Object.values(toolbarType),
      dataPopup: null,
      contextMenuData: null,
      showContextMenu: false,
      showModalLibrary: false,
      modalLibaryData: null,
      visibleLoader: false,
      editor: null,
      dataFonts: null,
      dataAnimations: [],
      user: JSON.parse(Cookies.get("user_onepage") || "{}"),
      showLayout: "default",
      showOtherSetting: false,
      detail_template: {},
      liquidNode: null,
      showLiquidModal: false,
      buttonNode: null,
      showButtonModal: false,
    };
  },
  methods: {
    showPopper() {
      this.show = !this.show;
    },
    hidePopper() {
      this.show = false;
    },
    onScroll(e) {},
    setOtherSetting(value) {
      this.showOtherSetting = value;
    },
    setShowLayout(value) {
      this.showLayout = value;
    },
    showModelColorPicker() {
      this.showColorPicker = !this.showColorPicker;
    },
    setTemplate(template) {
      this.detail_template = template;
    },



    showLiquidModalAction() {
      this.showLiquidModal = true;
    },
    saveLiquidProps(data) {
      this.liquidNode.setProps(data);
      this.showLiquidModal = false;
    },
    handleCloseLiquid() {
      this.showLiquidModal = false;
    },



    showButtonModalAction() {
      this.showButtonModal = true;
    },
    saveButtonProps(data) {
      this.buttonNode.setProps(data);
      this.showButtonModal = false;
    },
    handleCloseButtonModal() {
      this.showButtonModal = false;
    },




    hideColorPicker() {
      this.showColorPicker = false;
    },
    setIconForNode(icon) {
      this.node2.setProps({ icon: icon });
      this.showColorPicker = false;
    },

    showModelFontManager() {
      this.showFontManager = !this.showFontManager;
    },
    hideModelFontManager() {
      this.showFontManager = false;
    },
    handleSaveFont(fonts) {
      this.editor.fonts = fonts;
      this.showFontManager = false;
      localStorage.setItem(
        `listFonts_${this.user.store?.domain}`,
        JSON.stringify(fonts)
      );
      this.$bus.emit('reloadListFont');
    },

    actionShowMenuModal() {
      this.showMenuModal = true;
    },
    actionHideMenuModal() {
      this.showMenuModal = false;
    },
    actionHandleEditMenu(items) {
      this.showMenuModal = false;
      this.headerNode.setProps({ items })
    },

    showModalProductPicker() {
      this.showProductPicker = !this.showProductPicker;
    },
    hideModalProductPicker() {
      this.showProductPicker = false;
    },
    handlePickProduct(product) {
      this.showProductPicker = false;
      this.product = product;
    },
    showModalBlogPicker() {
      this.showBlogPicker = !this.showBlogPicker;
    },
    hideModalBlogPicker() {
      this.showProductPicker = false;
    },
    handlePickBlog(blog) {
      this.showBlogPicker = false;
      this.blog = blog;
    },

    showModalCollectionPicker() {
      this.showCollectionPicker = !this.showCollectionPicker;
    },
    hideModalCollectionPicker() {
      this.showCollectionPicker = false;
    },
    handlePickCollection(collection) {
      this.showCollectionPicker = false;
      this.collection = collection;
    },
    handleMouseMove(event) {
      const { editor } = this?.$refs?.editor || {};
      if (editor?.draggedNode) {
        let imageContainer = document.querySelector("#preview-drag");
        let iframe = document.querySelector("#designEditor");
        var clientRect = iframe.getBoundingClientRect();
        var clientX = clientRect.left;
        var clientY = 60;
        if (!editor.findNode(editor?.draggedNode.uuid)) {
          clientX = 0;
          clientY = 0;
        }
        imageContainer.style.left = event.clientX + clientX + "px";
        imageContainer.style.top = event.clientY + clientY + "px";
      }
      event.stopPropagation();
    },
    handleMouseUp(event) {
      const { editor } = this?.$refs?.editor || {};
      if (editor?.draggedNode) {
        editor.dragNode(null);
        editor.indicator.hide();
      }
      event.stopPropagation();
    },
    getAncestor(node, result) {
      if (!result) {
        result = [];
      }
      if (node.parent) {
        const product_dynamic =
          node.parent.componentName == "Product" && node.parent.props.dynamic
            ? true
            : false;
        result.push(product_dynamic);
        return this.getAncestor(node.parent, result);
      } else {
        return result;
      }
    },
    checkNodeDynamic(node) {
      const ancestors = this.getAncestor(node);
      if ((ancestors.includes(true) && node.props.parent == "Product") || node.props.dynamic) {
        return true;
      }
      return false;
    },
  },
  created() {
    let urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has("id")) {
      this.visibleLoader = true;
      request.get(`/api/v1/template/${urlParams.get("id")}`).then(
        (res) => {
          if (res.data.success) {
            if (res.data.data.system_default) {
              window.location.href = "/homepages";
            } else {
              this.detail_template = res.data.data;
              this.visibleLoader = false;
              this.dataImport = JSON.stringify(res.data.data.json_data);
              this.dataPopup = JSON.stringify(res.data.data.popups);
              // this.dataFonts = JSON.stringify(res.data.data.fonts || []);
              this.dataAnimations = JSON.stringify(
                res.data.data.animations || []
              );
              const data_fonts = res.data.data.fonts || [];
              const listFonts = JSON.parse(localStorage.getItem(`listFonts_${this.user.store?.domain}`) || "[]")
              listFonts.map(font => {
                if (!data_fonts.includes(font)) {
                  data_fonts.push(font)
                }
              })
              localStorage.setItem(`listFonts_${this.user.store?.domain}`, JSON.stringify(data_fonts));
              this.dataFonts = JSON.stringify(data_fonts);
            }
          }
        },
        (err) => {
          this.visibleLoader = false;
          this.$toastr.e(err?.response?.data?.message || 'Error');
        }
      );
    } else {
      this.visibleLoader = true;
      var json_data = '{}'
      const type =  urlParams.get("type");
      switch(type) {
        case 'productpages':
          json_data = this.productData
          break;
        case 'checkoutpages':
          json_data = this.checkoutData
          break;
        default:
          json_data = this.homeData
          break;
      }
      const query = {
        json_data,
        title: `Untitled ${moment().format("MMM DD, HH:mm:ss")}`,
        page_type: urlParams.get('type') || 'productpages'
      };
      request.post(`/api/v1/templates`, query).then(
        (res) => {
          this.visibleLoader = false;
          this.detail_template = res.data.data;
          this.$router.push({
            path: this.$route.path,
            query: {
              id: res.data.data.template_id,
              type: res.data.data.page_type,
              page: res.data.data.slug,
              shop: res.data.data.shop,
            },
          });
          this.dataImport = JSON.stringify(res.data.data.json_data);
          this.dataFonts = JSON.stringify(res.data.data.fonts || []);
          this.dataAnimations = JSON.stringify(res.data.data.animations || []);
        },
        (err) => {
          this.visibleLoader = false;
          this.$toastr.e(err?.response?.data?.message);
        }
      );
    }
    this.$bus.on("showToolbar", (currentRef, value, node) => {
      this.anchorEl = currentRef;
      this.show = value;
      this.node = node;
    });
    this.$bus.on("showToolbarLink", (value) => {
      this.showToolbarLink = value;
    });

    this.$bus.on("showColorPicker", (node) => {
      this.showModelColorPicker();
      this.node2 = node;
    });

    this.$bus.on("showLiquidModal", (node) => {
      this.showLiquidModalAction();
      this.liquidNode = node;
      this.showContextMenu = false;
    });

    this.$bus.on("showButtonModal", (node) => {
      this.showButtonModalAction();
      this.buttonNode = node;
    });

    this.$bus.on("showFontManager", (node) => {
      this.showModelFontManager();
      this.node3 = node;
      this.editor = node.editor;
    });

    this.$bus.on("showProductPicker", (node) => {
      this.showModalProductPicker();
    });

    this.$bus.on("showMenuModal", (node) => {
      this.actionShowMenuModal();
      this.headerNode = node;
    });

    this.$bus.on("showBlogPicker", (node) => {
      this.showModalBlogPicker();
    });

    this.$bus.on("showCollectionPicker", (node) => {
      this.showModalCollectionPicker();
    });
    window.addEventListener("mousemove", this.handleMouseMove);
    window.addEventListener("mouseup", this.handleMouseUp);

    // document.onmousemove = function(event) {
    //   console.log(event);
    //   this.handleMouseMove(event)
    // };
    // document.ondragleave = function(event) {
    //   let imageContainer = document.querySelector("#preview-drag");
    //   imageContainer.classList.remove("show");
    // };
    this.$bus.on("selectNode", () => {
      this.showLayout = "default";
      this.showOtherSetting = false;
    });

    this.$bus.on("showContextMenu", (node, position, nodeStyleClone) => {
      this.showContextMenu = node ? true : false;
      if (nodeStyleClone) {
        this.contextMenuData = {
          ...this.contextMenuData,
          node,
          position,
          nodeStyleClone,
        };
      } else {
        this.contextMenuData = { ...this.contextMenuData, node, position };
      }
    });

    this.$bus.on("showModalLibrary", (config, show, editor) => {
      this.showModalLibrary = show;
      this.modalLibaryData = config;
      if (editor) {
        this.editor = editor;
      }
    });
  },
  mounted() {
    axios({
      method: "GET",
      url: `${GOOGLE_FONT_URL}/?key=${GOOGLE_FONT_API_KEY}`,
      headers: {},
    })
      .then((res) => {
        if (res.status == 200) {
          this.fonts = res.data.items;
        }
      })
      .catch((err) => this.$toastr.e(err?.response?.data?.message || 'Error'));
    
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css";
@import "../../assets/style.css";
@import "./app.scss";
@import "./app.css";

html {
  overflow: hidden;
}

body {
  background: #eee;
  margin: 0;
}

#designEditor.desktop {
  width: 100%;
}

#designEditor.laptop {
  width: 1005px;
  margin: auto;
}
#designEditor.tablet {
  width: 780px;
  margin: auto;
}
#designEditor.mobile {
  width: 480px;
  margin: auto;
}

.overlay-min-resize {
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(28, 28, 37, 0.9);
  top: 0;
  left: 0;
  z-index: 10000;
  user-select: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 1023px) {
  .overlay-min-resize {
    display: flex;
  }
}
#prevew_builder ::-moz-selection {
  /* Code for Firefox */
  color: #3d4246;
  background: #d3e0a1;
}

#prevew_builder ::selection {
  color: #3d4246;
  background: #d3e0a1;
}

#prevew_builder a.editor_link_selection::selection {
  background: #d3e0a1;
  color: #fff;
}

iframe {
  width: 100%;
  height: 100%;
}

.preview {
  position: absolute;
  top: $navbar-height;
  left: $sidebar-width;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  // padding: 2em 3em;
  overflow: auto;
  display: flex;
  justify-content: center;
  transition: 0.2s left, 0.2s right;
  transition-timing-function: ease-in-out;
  &.disable {
    left: 0;
    right: 0;
  }

  // @include scrollbar();

  .desktop {
    width: 100%;
  }

  .laptop {
    width: 1005px;
    margin: auto;
  }

  .tablet {
    width: 780px;
    margin: auto;
  }

  .mobile {
    width: 480px;
    margin: auto;
  }
}

@media only screen and (max-width: 1023px) {
  .preview {
    left: 0;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1300px) {
  .preview {
    left: $sm-sidebar-width;
  }
}
</style>
