<template>
  <div label-position="top" size="mini" :model="elementProps">
    <div class="d-flex flex-column py-2" >
      <span style="font-size: 12px; color: #ccc">Color</span>
      <ColorPicker
        class="mt-2"
        :value="getValue('color',elementProps[screen].color)"
        v-bind:changeColor="($event) => updateProps('color', $event)"
        :primaryColor="primaryColor"
      />
    </div>
    <div class="d-flex flex-column py-2" >
      <span style="font-size: 12px; color: #ccc">Active Color</span>
      <ColorPicker
        class="mt-2"
        :value="getValue('active_color',elementProps[screen].active_color)"
        v-bind:changeColor="($event) => updateProps('active_color', $event)"
        :primaryColor="primaryColor"
      />
    </div>
    
    <div class="d-flex flex-column py-2" >
      <span style="color: #aaaaaa; font-size: 12px">Font Size</span>
      <Slider
        class="mt-2"
        :step="1"
        :primaryColor="primaryColor"
        :value="getValue('font_size',elementProps[screen].font_size)"
        v-bind:changeValue="
          ($event) => updateProps('font_size', parseInt($event))
        "
        unit="px"
        :max="48"
        :min="8"
        :onlyInteger="true"
      />
    </div>
    <div class="d-flex flex-column py-2">
      <span>Font Style</span>
      <ToggleButton
        :isMulti="true"
        :values="getValue('font_style',elementProps[screen].font_style) || []"
        v-bind:changeValue="($event) => updateArrayProps('font_style', $event)"
        :options="[
          { value: 'bold', label: 'Bold' },
          { value: 'italic', label: 'Italic' },
          { value: 'underline', label: 'Underline' },
        ]"
      />
    </div>
  </div>
</template>
    
    <script>
import mixin from "../../../elements/styleSettings/mixin";
import ColorPicker from "../../../elements/common/ColorPicker.vue";
import Slider from "../../../elements/common/Slider.vue";
import Select from "../../../elements/common/Select.vue";
import ToggleButton from "../../../elements/common/ToggleButton.vue";
import Node from "@/core/Node";
import { makeId } from "@/constants";

export default {
  mixins: [mixin],
  components: {
    ColorPicker,
    Slider,
    Select,
    ToggleButton,
  },
  props: {
    node: Node,
    screen: String,
    primaryColor: String,
  },
  data() {
    return {
      fontKey: makeId(20),
      element: "quantity",
    };
  },
  watch: {
  },
  methods: {
    updateProps(name, value) {
      const screen = this.$props.screen;
      const data_screen = Object.assign({}, this.elementProps[screen]);
      if (value == data_screen[name]) return;
      data_screen[name] = value;
      this.elementPropsSetter({ [screen]: data_screen });
    },
    updateArrayProps(name, value) {
      const screen = this.$props.screen;
      const data_screen = Object.assign({}, this.elementProps[screen]);
      data_screen[name] = Object.assign([], data_screen[name]);
      if (data_screen[name].includes(value)) {
        var index = data_screen[name].findIndex((x) => x == value);
        data_screen[name].splice(index, 1);
      } else {
        data_screen[name].push(value);
      }
      this.elementPropsSetter({ [screen]: data_screen });
    },
  },
};
</script>
    