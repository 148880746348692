<template>
  <div  class="col-lg-12 sticky-head">
    <header  class="row pb-2">
      <div  class="col-12 col-sm-8 col-lg-7 col-xl-8">
        <div  class="row">
          <div  class="shop-info">
            <h2 class="flex-v-center heading-h2-24-semi text-light-high shop-name">
              {{ title }}
            </h2>
            <p class="paragraph-14-16-regular text-light-low mt-sm-1">
              {{ subTitle }}
            </p>
          </div>
        </div>
      </div>
      <div
        class="col-12 col-sm-4 col-lg-5 col-xl-4 d-flex flex-column-reverse"
        v-if="page"
      >
        <div  class="row vue-portal-target">
          <div  class="create-buttons">
            <div  class="btn-create">
              <router-link :to="`/editor?type=${page}`">
                <button class="btn btn-primary d-flex align-items-center" style="padding: 12px 20px; border-radius: 12px;">
                  <div style="width: 16px; height: 16px" class="mr-2">
                    <span class="icon material-symbols-outlined" style="font-size: 16px">
                      add
                    </span>
                  </div>
                  Create New Template
                </button>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </header>
    <div class="col-12 pt-2">
      <div class="divider"></div>
    </div>
  </div>
</template>

<script>
export default {
  inject: [],
  props: {
    title: String,
    subTitle: String,
    page: String,
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>


<style>
.text-light-high {
  color: #212121;
}
.heading-h2-24-semi {
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0;
  font-weight: 600;
}

.flex-v-center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.shop-name {
  line-height: 30px;
  margin-bottom: 8px;
}

.mt-sm-1 {
  margin-top: 8px !important;
}

.text-light-low {
  color: #666;
}
.paragraph-14-16-regular {
  line-height: 16px;
}

.paragraph-14-16-regular,
.paragraph-14-regular {
  font-size: 14px;
  letter-spacing: 0;
  font-weight: 400;
  text-align: start;
  margin-bottom: 0;
}

.create-buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  width: 100%;
}

.clickable {
  cursor: pointer;
}
.create-buttons .create-group {
  padding: 10px;
  min-height: 40px;
  max-height: 40px;
  min-width: 40px;
  max-width: 40px;
  border-radius: 3px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: #fff;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.btn-create {
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.btn-primary {
  background: #3c67ff;
  color: #fff;
}
.mr-2 {
  margin-right: 8px;
}

.btn-create i {
  font-size: 10px;
  font-weight: 700;
}

.setting-page .divider {
  border-bottom: 1.03px solid #e2e2e2;
}

.setting-page .sticky-head {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: #eee;
    border-bottom: 1px solid #e2e2e2;
    padding-top: 40px;
}
</style>
