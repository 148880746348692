<template>
  <el-form label-position="top" size="" :model="elementProps">
    <draggable
      tag="el-collapse"
      v-model="myList"
      :component-data="collapseComponentData()"
      class="list-items"
    >
      <el-collapse-item
        v-for="item in node.children"
        :key="item.props.item.id"
        :name="item.props.item.id"
      >
        <template slot="title">
          <div class="d-flex align-items-center justify-content-between w-100">
            <span>{{ item.props.item.name }}</span>
            <div class="d-flex">
              <div
                class="tab-action"
                @click="duplicate(item.props.item.id, $event)"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  stroke-width="1"
                  stroke="#919191"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <rect x="8" y="8" width="12" height="12" rx="2"></rect>
                  <path
                    d="M16 8v-2a2 2 0 0 0 -2 -2h-8a2 2 0 0 0 -2 2v8a2 2 0 0 0 2 2h2"
                  ></path>
                </svg>
              </div>
              <div
                class="tab-action"
                @click="remove(item.props.item.id, $event)"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  stroke-width="1"
                  stroke="#919191"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <line x1="4" y1="7" x2="20" y2="7"></line>
                  <line x1="10" y1="11" x2="10" y2="17"></line>
                  <line x1="14" y1="11" x2="14" y2="17"></line>
                  <path
                    d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"
                  ></path>
                  <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"></path>
                </svg>
              </div>
            </div>
          </div>
        </template>
        <!-- <el-form-item label="Name">
          <el-input
            :value="getItemData('name', item.props.item.id)"
            @input="setItemData('name', item.props.item.id, $event)"
          />
        </el-form-item> -->
      </el-collapse-item>
    </draggable>
  </el-form>
</template>

<script>
import mixin from "../../../elements/styleSettings/mixin";
import ColorPicker from "../../../elements/common/ColorPicker.vue";
import draggable from "vuedraggable";
import { makeId } from "@/constants";
export default {
  mixins: [mixin],
  components: {
    draggable,
    ColorPicker,
  },
  computed: {
    myList: {
      get() {
        return this.node.children;
      },
      set(value) {
        const { editor } = this.node;
        let inversePatches = {
          nodes: editor.export(),
          popups: editor.exportPopup(),
        };
        this.node.children = value;
        let patches = { nodes: editor.export(), popups: editor.exportPopup() };
        editor.add(patches, inversePatches);
      },
    },
  },
  data() {
    return {
      activeNames: [],
    };
  },
  methods: {
    getItemData(key, id) {
      const child = this.node.children.find((e) => e.props?.item.id == id);
      return child.props.item[key];
    },
    setItemData(key, id, value) {
      const child = this.node.children.find((e) => e.props?.item.id == id);
      if (child) {
        const item = Object.assign(
          JSON.parse(JSON.stringify(child.props.item))
        );
        item[key] = value;
        child.setProps({ item });
      }
    },
    collapseComponentData() {
      return {
        on: {
          input: this.inputChanged,
        },
        props: {
          value: this.activeNames,
        },
      };
    },
    inputChanged(val) {
      this.activeNames = val;
    },
    updateProps(name, value) {
      if (value == this.elementProps[name]) return;
      this.elementPropsSetter({ [name]: value });
    },
    duplicate(id, event) {
      event.stopPropagation();
      const { editor } = this.node;
      let inversePatches = {
        nodes: editor.export(),
        popups: editor.exportPopup(),
      };
      const item_child = this.node.children.find((e) => e.props.item.id == id);
      const index = this.node.children.findIndex((e) => e.props.item.id == id);
      const new_node = item_child.duplicate();
      const item = Object.assign(
        {},
        JSON.parse(JSON.stringify(new_node.props.item))
      );
      item.id = makeId(20);
      item.name = `Tab ${this.node.children.length + 1}`;
      new_node.setProps({ item }, true);
      this.node.children.splice(index + 1, 0, new_node);
      let patches = { nodes: editor.export(), popups: editor.exportPopup() };
      editor.add(patches, inversePatches);
      // const items = []
      // this.node.children.map((el, ii) => {
      //   const item = Object.assign({}, JSON.parse(JSON.stringify(el.props.item)))
      //   item['index'] = String(ii + 1)
      //   el.setProps({ item })
      //   items.push(item)
      // })
      // this.node.setProps({ items })
    },
    remove(id, event) {
      event.stopPropagation();
      if (this.node.children.length == 1) {
        this.$toastr.e("You cannot delete all items");
        return;
      }
      const { editor } = this.node;
      let inversePatches = {
        nodes: editor.export(),
        popups: editor.exportPopup(),
      };
      const index = this.node.children.findIndex((e) => e.props.item.id == id);
      this.node.children.splice(index, 1);
      let patches = { nodes: editor.export(), popups: editor.exportPopup() };
      editor.add(patches, inversePatches);
      // const items = []
      // this.node.children.map((el, ii) => {
      //   const item = Object.assign({}, JSON.parse(JSON.stringify(el.props.item)))
      //   item['index'] = String(ii + 1)
      //   el.setProps({ item })
      //   items.push(item)
      // })
      // this.node.setProps({ items })
    },
    listMoved() {
      const items = [];
      this.node.children.map((el, ii) => {
        const item = Object.assign(
          {},
          JSON.parse(JSON.stringify(el.props.item))
        );
        item["index"] = String(ii + 1);
        el.setProps({ item }, true);
        items.push(item);
      });
      this.node.setProps({ items }, true);
    }
  },
};
</script>

<style lang="scss" scoped>
.list-items {
  .el-collapse-item {
    background-color: #444444 !important;
    margin-bottom: 8px;
  }
  .tab-action {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background: #333333;
    }
  }
}
</style>
