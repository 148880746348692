<template>
  <div
    class="sg-column"
    :id="`c-${node.uuid}`"
    :data-layout-xl="`data-layout-xl`"
    :data-layout-lg="`data-layout-lg`"
    :data-layout-md="`data-layout-md`"
    :data-layout-sm="`data-layout-sm`"
  >
    <div v-if="node.children.length == 0" class="text-center row-dropper">
      <span>Add element</span>
    </div>
    <slot />
  </div>
  
</template>

<script>
import elementStyleMixin from './elementStyleMixin';

export default {
  mixins: [elementStyleMixin],
  inject: [
    'editor',
    'node'
  ],
  props: {
    child: String,
    
    "data-layout-xl": String,
    "data-layout-lg": String,
    "data-layout-md": String,
    "data-layout-sm": String,
    full_width: Boolean
  },
  craft: {
    defaultProps: {
      "data-layout-xl": "12",
      "data-layout-lg": "12",
      "data-layout-md": "12",
      "data-layout-sm": "12",
      elementStyle: {
      },
    },
    settings: {
      settings: {
      },
      design: {
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
