<template>
  <div label-position="top" size="small" :model="elementProps">
    <div class="d-flex flex-column py-2" v-if="elementProps.variant_type == 'swatch'">
      <span class="setting-title">Margin Bottom</span>
      <Slider
        class="mt-2"
        :primaryColor="primaryColor"
        :value="Number.parseInt(elementProps.swatch_margin_bottom)"
        :min="0"
        :max="50"
        :step="1"
        v-bind:changeValue="($event) => updateProps('swatch_margin_bottom', $event)"
        unit="px"
        :onlyInteger="true"
      />
    </div>
    <div class="d-flex flex-column py-2" v-if="elementProps.variant_type == 'swatch'">
      <span class="setting-title">Distance</span>
      <Slider
        class="mt-2"
        :primaryColor="primaryColor"
        :value="Number.parseInt(elementProps.swatch_distance)"
        :min="0"
        :max="50"
        :step="1"
        v-bind:changeValue="($event) => updateProps('swatch_distance', $event)"
        unit="px"
        :onlyInteger="true"
      />
    </div>
  </div>
</template>

<script>
import mixin from "../../../elements/styleSettings/mixin";
import Slider from "../../../elements/common/Slider.vue";

export default {
  mixins: [mixin],
  components: {Slider},
  methods: {
    updateProps(name, value) {
      if(value ==  this.elementProps[name]) return;

      this.elementPropsSetter({ [name]: value });
    },
  },
  props: {
    primaryColor: String
  }
};
</script>

<style scoped>
</style>
