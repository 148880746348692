<template>
  <el-form label-position="top" size="small" :model="elementProps">
    <div class="d-flex justify-content-between py-2">
      <span class="setting-title">Day</span>
      <el-switch
        :value="elementProps.show_day"
        :active-color="primaryColor"
        inactive-color="#949494"
        :active-value="true"
        :inactive-value="false"
        @change="updateProps('show_day', $event)"
      />
    </div>
    <div class="d-flex flex-column py-2" v-if="elementProps.show_day">
      <span class="setting-title">Label</span>
      <el-input
        size="medium"
        class="mt-2"
        :value="elementProps.day_label"
        @input="($event) => updateProps('day_label', $event)"
      />
    </div>
    <div class="d-flex justify-content-between py-2">
      <span class="setting-title">Hour</span>
      <el-switch
        :value="elementProps.show_hour"
        :active-color="primaryColor"
        inactive-color="#949494"
        :active-value="true"
        :inactive-value="false"
        @change="updateProps('show_hour', $event)"
      />
    </div>
    <div class="d-flex flex-column py-2" v-if="elementProps.show_hour">
      <span class="setting-title">Label</span>
      <el-input
        size="medium"
        class="mt-2"
        :value="elementProps.hour_label"
        @input="($event) => updateProps('hour_label', $event)"
      />
    </div>
    <div class="d-flex justify-content-between py-2">
      <span class="setting-title">Minute</span>
      <el-switch
        :value="elementProps.show_minute"
        :active-color="primaryColor"
        inactive-color="#949494"
        :active-value="true"
        :inactive-value="false"
        @change="updateProps('show_minute', $event)"
      />
    </div>
    <div class="d-flex flex-column py-2" v-if="elementProps.show_minute">
      <span class="setting-title">Label</span>
      <el-input
        size="medium"
        class="mt-2"
        :value="elementProps.minute_label"
        @input="($event) => updateProps('minute_label', $event)"
      />
    </div>
    <div class="d-flex justify-content-between py-2">
      <span class="setting-title">Second</span>
      <el-switch
        :value="elementProps.show_second"
        :active-color="primaryColor"
        inactive-color="#949494"
        :active-value="true"
        :inactive-value="false"
        @change="updateProps('show_second', $event)"
      />
    </div>
    <div class="d-flex flex-column py-2" v-if="elementProps.show_second">
      <span class="setting-title">Label</span>
      <el-input
        size="medium"
        class="mt-2"
        :value="elementProps.second_label"
        @input="($event) => updateProps('second_label', $event)"
      />
    </div>
    <div class="d-flex justify-content-between py-2">
      <span class="setting-title">Week</span>
      <el-switch
        :value="elementProps.show_week"
        :active-color="primaryColor"
        inactive-color="#949494"
        :active-value="true"
        :inactive-value="false"
        @change="updateProps('show_week', $event)"
      />
    </div>
    <div class="d-flex flex-column py-2" v-if="elementProps.show_week">
      <span class="setting-title">Label</span>
      <el-input
        size="medium"
        class="mt-2"
        :value="elementProps.week_label"
        @input="($event) => updateProps('week_label', $event)"
      />
    </div>
  </el-form>
</template>

<script>
import mixin from "../../../elements/styleSettings/mixin";

export default {
  mixins: [mixin],
  components: { },
  props: {
    primaryColor: String
  },
  methods: {
    updateProps(name, value) {
      if(value ==  this.elementProps[name]) return;

      this.elementPropsSetter({ [name]: value });
    }
  },
};
</script>

<style scoped>
</style>
