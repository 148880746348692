<template>
  <div label-position="top" size="mini" :model="elementProps">
    <div class="d-flex flex-column py-2">
      <span class="setting-title">Image</span>
      <Upload
        :value="elementProps.imageUrl"
        v-bind:changeValue="($event, upload_id) => updateImageUrl($event, upload_id)"
        :node_id="this.getNode.uuid"
      />
    </div>
    <div class="d-flex flex-column py-2">
      <span class="setting-title">Image Src</span>
      <el-input
        class="mt-2"
        placeholder="Image source"
        :value="elementProps.imageUrl"
        @input="updateImageUrl($event)"
        size="medium"
      />
    </div>
  </div>
</template>

<script>
import { settingMixin } from "@";
import Upload from "./common/Upload.vue";
export default {
  mixins: [settingMixin],
  components: { Upload },
  methods: {
    updateImageUrl(imageUrl, node_id) {
      if (node_id) {
        const node = this?.getNode?.editor?.findNode(node_id);
        if (node) {
          node.setProps({
            imageUrl: imageUrl,
          });
        }
      } else {
        this.elementPropsSetter({ imageUrl });
      }
      console.log();
    },
  },
};
</script>
