<template>
  <div label-position="top" size="mini" :model="elementProps" :style="cssVars">
    <div class="setting-header">
      <div class="setting-header-title">{{ name }}</div>
      <el-popover
        placement="bottom-end"
        width="200"
        trigger="click"
        popper-class="animation-popover"
        :visible-arrow="false"
        v-model="visibleMenu"
      >
        <ul class="animation-list">
          <li 
            v-for="action in actions"
            :key="action.value"
            @click="() => onSelectAnimation(action)"
            :class="{disabled: checkDisabledAction(action, node.uuid)}"
          >
            {{ action.label }}
          </li>
        </ul>
        <div slot="reference" class="setting-add">
          <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" stroke-width="2" stroke="#ffffff" fill="none" stroke-linecap="round" stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <line x1="12" y1="5" x2="12" y2="19"></line>
            <line x1="5" y1="12" x2="19" y2="12"></line>
          </svg>
        </div>
      </el-popover>
    </div>
    <div class="animation-items">
      <div 
        class="animation-item position-relative"
        v-for="animation in getListAnimations(node.uuid)"
        :key="animation.id"
        :class="animation_delete && animation_delete.id == animation.id ? 'animation-delete' : ''"
      >
        <div class="delete-animation" v-if="animation_delete && animation_delete.id == animation.id">
          <p class="f-13 text-white">This animation will be removed.</p>
          <div class="d-flex mt-2 gap-1 justify-content-center align-items-center">
            <button class="btn btn-danger" @click="() => handleDelete(animation_delete)">Delete</button>
            <button class="btn btn-light" @click="() => handleCancelDelete()">Cancel</button>
          </div>
        </div>
        <input 
          type="checkbox"
          class="d-none"
          v-model="tab_index"
          :value="animation.id"
          :id="`animation_${animation.id}`"
        />
        <div class="d-flex gap-1 label-container">
          <label 
            :for="`animation_${animation.id}`"
            class="animation-title"
          >
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.5 6.08594C7.8236 5.76233 8.34827 5.76233 8.67187 6.08594L12.7324 10.1464C12.9276 10.3417 12.9276 10.6583 12.7324 10.8536L8.67187 14.9141C8.34827 15.2377 7.8236 15.2377 7.5 14.9141C7.1764 14.5905 7.1764 14.0658 7.5 13.7422L10.7422 10.5L7.5 7.25781C7.1764 6.93421 7.1764 6.40954 7.5 6.08594Z" fill="#F8F8F8"></path>
            </svg>
            <div class="d-flex flex-column flex-1">
              <span class="f-14 fw-bold text-white">{{ getInfoAnimation(animation)?.label }}</span>
              <span class="f-12 text-gray">{{ getInfoAnimation(animation)?.description }}</span>
            </div>
          </label>
          <div class="delete-btn" @click="() => deleteAnimation(animation)">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" stroke-width="1.5" stroke="#ff2825" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <line x1="4" y1="7" x2="20" y2="7"></line>
              <line x1="10" y1="11" x2="10" y2="17"></line>
              <line x1="14" y1="11" x2="14" y2="17"></line>
              <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"></path>
              <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"></path>
            </svg>
          </div>
        </div>
        <div class="animation-content">
          <div class="d-flex flex-column py-2">
            <span class="setting-title">Animation</span>
            <Select 
              class="mt-2"
              :primaryColor="primaryColor"
              :value="animation.animation"
              v-bind:changeValue="($event) => updateValue('animation', $event, animation.id)"
              :options="animationTypes"
            />
          </div>
          <div class="d-flex flex-column py-2" v-if="animationTypes.find(e => e.value == animation.animation)?.directions?.length > 0">
            <span class="setting-title">Direction</span>
            <Select 
              class="mt-2"
              :primaryColor="primaryColor"
              :value="animation.direction"
              v-bind:changeValue="($event) => updateValue('direction', $event, animation.id)"
              :options="animationTypes.find(e => e.value == animation.animation)?.directions"
            />
          </div>
          <div class="d-flex flex-column py-2" v-if="animation.animation != 'none'">
            <span class="setting-title">Delay</span>
            <Slider 
              class="mt-2"
              :value="animation.delay"
              :primaryColor="primaryColor"
              :min="0"
              :max="2000"
              :step="100"
              unit="ms"
              v-bind:changeValue="($event) => updateValue('delay', $event, animation.id)"
              :onlyInteger="true"
            />
          </div>
          <div class="d-flex flex-column py-2" v-if="animation.animation != 'none'">
            <span class="setting-title">Duration</span>
            <Slider 
              class="mt-2"
              :value="animation.duration"
              :primaryColor="primaryColor"
              :min="50"
              :max="2000"
              :step="50"
              unit="ms"
              v-bind:changeValue="($event) => updateValue('duration', $event, animation.id)"
              :onlyInteger="true"
            />
          </div>
          <div class="d-flex flex-column py-2" v-if="animation.animation != 'none'">
            <el-checkbox 
              :value="animation.infinite" 
              @change="($event) => updateValue('infinite', $event, animation.id)" 
              class="infinite-checkbox"
              label="Infinite loop"
            ></el-checkbox>
          </div>
          <div class="d-flex flex-column py-2" v-if="animation.animation != 'none'">
            <span class="setting-title">Trigger on</span>
            <el-checkbox-group v-model="animation.trigger" class="trigger-screen">
              <el-checkbox name="screen" class="item" label="desktop"></el-checkbox>
              <el-checkbox name="screen" class="item" label="laptop"></el-checkbox>
              <el-checkbox name="screen" class="item" label="tablet"></el-checkbox>
              <el-checkbox name="screen" class="item" label="mobile"></el-checkbox>
            </el-checkbox-group>
          </div>
          <div class="py-2" v-if="animation.animation != 'none'">
            <button class="view-animation" :class="{disabled: loadingBtn}" @click="() => loadAnimation(animation, true)">
              <svg v-if="!loadingBtn" width="20" height="20" viewBox="0 0 24 24" tabindex="-1" title="PlayArrow"><path d="M8 5v14l11-7z"></path></svg>
              <svg v-else width="20" height="20" viewBox="0 0 24 24" tabindex="-1" title="Pause"><path d="M6 19h4V5H6v14zm8-14v14h4V5h-4z"></path></svg>
              <span>View Animation</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mixin from "../styleSettings/mixin";
import Node from '@/core/Node';
import { makeId, animationTypes, capitalizeStr, actionTypes } from '@/constants';
import Select from "../common/Select.vue";
import Slider from "../common/Slider.vue";

export default {
  mixins: [mixin],
  inject: ['editor'],
  props: {
    name: String,
    node: Node,
    primaryColor: String,
    screen: String,
    actions: Array,
  },
  data() {
    return {
      tab_index: [],
      visibleMenu: false,
      animationTypes,
      animation_delete: null,
      loadingBtn: false,
    }
  },
  components: {
    Select, Slider
  },
  computed: {
    cssVars() {
      return {
        '--primary-color': this.primaryColor || '#0060e5'
      }
    }
  },
  methods: {
    getListAnimations(uuid) {
      const animations = this.editor.animations?.filter(e => e.uuid == uuid) || []
      return animations
    },
    handleDelete(animation) {
      const animations = Object.assign([], this.editor.animations);
      const res = animations.filter(e => e.id != animation.id)
      this.animation_delete = null;
      this.editor.animations = res;
    },
    handleCancelDelete() {
      this.animation_delete = null;
    },
    deleteAnimation(animation) {
      this.animation_delete = animation
    },
    getInfoAnimation(animation) {
      const item = actionTypes.find(e => e.value == animation.type) || {}
      return item
    },
    getDirectionOptions(type) {
      const directions = animationTypes.find(e => e.value == type)?.directions || []
      return directions
    },
    onSelectAnimation(action) {
      this.visibleMenu = false;
      const animations = Object.assign([], this.editor.animations);
      const animation_id = makeId(10);
      animations.push({
        id: animation_id,
        uuid: this.node.uuid,
        type: action.value,
        animation: 'none',
        delay: 0,
        duration: 500,
        trigger: ['desktop', 'laptop', 'tablet', 'mobile']
      })
      this.editor.animations = animations;
      this.tab_index.push(animation_id);
    },
    checkDisabledAction(action, uuid) {
      const animations = Object.assign([], this.editor.animations);
      const item = animations.find(e => e.type == action.value && e.uuid == uuid)
      if (item) {
        return true;
      }
      return false;
    },
    updateValue(name, value, id) {
      const animations = Object.assign([], this.editor.animations);
      const item = animations.find(e => e.id == id)
      if (item) {
        item[name] = value;
        if (name == 'animation') {
          if (value == 'none') {
            item['direction'] = "";
          } else {
            const type = animationTypes.find(e => e.value == value)
            item['direction'] = type.directions?.length > 0 ? type.directions[0].value : ""
          }
        }
        this.loadAnimation(item)
      }
      this.editor.animations = animations;
    },
    loadAnimation(item, clickButton=false) {
      const delay = item.delay;
      const animation = item.animation;
      const direction = item.direction;
      if (animation != 'none' && item.trigger.includes(this.$props.screen)) {
        let $iframe = $("#designEditor").contents();
        if (clickButton) {
          this.loadingBtn = true;
        }
        setTimeout(() => {
          const $element = $iframe.find(`#element-${item.uuid}`);
          $element.addClass(`sg-animated sg-animated-${animation}${direction}`);
          $element.css("animation-duration", `${item.duration}ms`);

          setTimeout(() => {
            $element.removeClass(function (index, className) {
              return (className.match (/(^|\s)sg-animated\S+/g) || []).join(' ');
            });
            this.loadingBtn = false;
          }, 1000);
        }, delay);
      }
    }
  }
};
</script>

<style>
  .trigger-screen .item .el-checkbox__inner::after {
    border: 2px solid var(--primary-color);
    border-left: 0;
    border-top: 0;
  }

  .infinite-checkbox .el-checkbox__inner::after {
    border: 2px solid var(--primary-color);
    border-left: 0;
    border-top: 0;
  }
</style>

