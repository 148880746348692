<template>
  <div id='prevew_builder' class="preview" :class="[{ disable: !this.editor.enabled },
  {editor_dragging:this.editor?.draggedNode}]"
  >
    <Paper v-if="this.editor">
      <Frame component='div' :screen="screen">
        <Canvas component='Container' />
      </Frame>
    </Paper>
  </div>
</template>

<script>
import { Frame, Canvas } from "@";
import Paper from "./Paper.vue";


export default {
  components: {
    Frame,
    Canvas,
    Paper,
  },
  mounted(){
    let iframe = document.querySelector("#designEditor");
    iframe.contentWindow.document.addEventListener('mousemove', this.handleMouseMove);
    iframe.contentWindow.document.addEventListener('mouseup', this.handleMouseUp);

    // document.ondragleave = function(event) {
    //   let imageContainer = document.querySelector("#preview-drag");
    //   imageContainer.classList.remove("show");
    // };
  },
  // inject: ["editor"],
  data() {
    return {
      editor: this.$parent.editor,
      bus: this.$parent.bus
    };
  },
  provide() {
    // use function syntax so that we can access `this`
    return {
      editor: this.editor
    }
  },
  methods: {
    handleMouseMove(event){
      const { editor}  = this
      if(editor?.draggedNode){
        let imageContainer = document.querySelector("#preview-drag");
        let iframe = document.querySelector("#designEditor");
        var clientRect = iframe.getBoundingClientRect();
        var clientX = clientRect.left;
        var clientY = 60;
        imageContainer.style.left = event.clientX + clientX + "px";
        imageContainer.style.top = event.clientY + clientY + "px";
        
      }
      event.stopPropagation();
    },
    handleMouseUp(event) {
      const { editor}  = this
      if (editor?.draggedNode) {
        editor.dragNode(null);
        editor.indicator.hide();
      }
      event.stopPropagation();

    },
    showPopper() {
      this.show = !this.show;
    },
    hidePopper() {
      this.show = false;
    },
    onScroll(e) {
    },
  },
  props: {
    screen: String,
  },
};
</script>

<style lang="scss" scoped>
@import "../app.scss";

.preview {
  position: absolute;
  top: $navbar-height;
  left: $sidebar-width;
  right: 0;
  bottom: 0;
  background-color: #CCC;
  // padding: 2em 3em;
  overflow: auto;
  
  transition: 0.2s left, 0.2s right;
  transition-timing-function: ease-in-out;
  &.disable {
    left: 0;
    right: 0;
  }

  // @include scrollbar();

}

@media only screen and (max-width: 1023px) {
  .preview {
    left: 0
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1300px) {
  .preview {
    left: $sm-sidebar-width;
  }
}

</style>
