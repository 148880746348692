<template>
  <el-form label-position="top" size="" :model="elementProps">
    <div class="d-flex align-items-center justify-content-between py-2">
      <span class="sgSubTitle">Set Redirect</span>
      <el-switch
        :value="elementProps.isProductLink"
        :active-color="primaryColor"
        inactive-color="#949494"
        @change="updateProps('isProductLink', $event)"
      />
    </div>
    <el-form-item label="URL redirect" v-if="elementProps.isProductLink">
      <el-input
        type="textarea"
        :rows="4"
        :value="elementProps.redirect_link"
        @input="($event) => updateProps('redirect_link', $event)"
        resize="none"
      />
    </el-form-item>
  </el-form>
</template>

<script>
import mixin from "../../../elements/styleSettings/mixin";

export default {
  mixins: [mixin],
  components: {},
  props: {
    primaryColor: String,
  },
  methods: {
    updateProps(name, value) {
      if (value == this.elementProps[name]) return;
      this.elementPropsSetter({ [name]: value });
    },
  },
};
</script>

<style scoped>
</style>
