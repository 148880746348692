<template>
  <div label-position="top" size="mini" :model="elementProps">
    <div class="py-2">
      <span class="setting-title">Shadow Style</span>
      <ToggleButton
        :value="getValue('shadow_style',elementProps[screen].shadow_style)"
        v-bind:changeValue="
          ($event) => updateProps(screen, 'shadow_style', $event)
        "
        :options="[
          { value: 'drop', label: 'Drop Shadow' },
          { value: 'box', label: 'Box Shadow' },
        ]"
        :canRemove="true"
        :primaryColor="primaryColor"
      />
    </div>
    <div class="py-2" v-if="getValue('shadow_style',elementProps[screen].shadow_style)">
      <span class="setting-title">Shadow Color</span>
      <ColorPicker
        :primaryColor="primaryColor"
        class="mt-2"
        :value="getValue('shadow_color',elementProps[screen].shadow_color) || 'rgb(255, 255, 255)'"
        v-bind:changeColor="
          ($event) => updateProps(screen, 'shadow_color', $event)
        "
      />
    </div>
    <div class="py-2" v-if="getValue('shadow_style',elementProps[screen].shadow_style) == 'box'">
      <span class="setting-title">Shadow Type</span>
      <ToggleButton
        :value="getValue('shadow_type',elementProps[screen].shadow_type)"
        v-bind:changeValue="
          ($event) => updateProps(screen, 'shadow_type', $event)
        "
        :options="[
          { value: 'inset', label: 'INSET' },
          { value: 'outset', label: 'OUTSET' },
        ]"
        :primaryColor="primaryColor"
      />
    </div>
    <div class="py-2" v-if="getValue('shadow_style',elementProps[screen].shadow_style)">
      <span class="setting-title">Horizontal offset</span>
      <Slider
        :primaryColor="primaryColor"
        :value="getValue('shadow_x',elementProps[screen].shadow_x)"
        :min="0"
        :max="20"
        v-bind:changeValue="($event) => updateProps(screen, 'shadow_x', $event)"
        unit="px"
        :onlyInteger="true"
      />
    </div>
    <div class="py-2" v-if="getValue('shadow_style',elementProps[screen].shadow_style)">
      <span class="setting-title">Vertical offset</span>
      <Slider
        :primaryColor="primaryColor"
        :value="getValue('shadow_y',elementProps[screen].shadow_y)"
        :min="0"
        :max="20"
        v-bind:changeValue="($event) => updateProps(screen, 'shadow_y', $event)"
        unit="px"
        :onlyInteger="true"
      />
    </div>
    <div class="py-2" v-if="getValue('shadow_style',elementProps[screen].shadow_style)">
      <span class="setting-title">Blur radius</span>
      <Slider
        :primaryColor="primaryColor"
        :value="getValue('shadow_blur',elementProps[screen].shadow_blur)"
        :min="0"
        :max="20"
        v-bind:changeValue="
          ($event) => updateProps(screen, 'shadow_blur', $event)
        "
        unit="px"
        :onlyInteger="true"
      />
    </div>
    <div class="py-2" v-if="getValue('shadow_style',elementProps[screen].shadow_style) == 'box'">
      <span class="setting-title">Spread radius</span>
      <Slider
        :primaryColor="primaryColor"
        :value="getValue('shadow_spread',elementProps[screen].shadow_spread)"
        :min="0"
        :max="20"
        v-bind:changeValue="
          ($event) => updateProps(screen, 'shadow_spread', $event)
        "
        unit="px"
        :onlyInteger="true"
      />
    </div>
  </div>
</template>

<script>
import mixin from "../styleSettings/mixin";
import { allIcons } from "../../../../../constants";
import ToggleButton from "../common/ToggleButton.vue";
import ColorPicker from "../common/ColorPicker.vue";
import Slider from "../common/Slider.vue";
export default {
  mixins: [mixin],
  data() {
    return {
      allIcons,
    };
  },
  props: {
    screen: String,
    primaryColor: String,
  },
  components: {
    ToggleButton,
    ColorPicker,
    Slider,
  },
  methods: {
    updateProps(screen, name, value) {
      const data_screen = Object.assign({}, this.elementProps[screen]);
      if (value == data_screen[name]) return;
      data_screen[name] = value;
      this.elementPropsSetter({ [screen]: data_screen });
    },
  },
};
</script>

<style>
</style>
