var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"label-position":"top","size":"","model":_vm.elementProps}},[_c('div',{staticClass:"d-flex flex-column py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Icon Type")]),_c('Select',{staticClass:"mt-2",attrs:{"primaryColor":_vm.primaryColor,"value":_vm.elementProps.icon_type,"changeValue":($event) => _vm.updateProps('icon_type', $event),"options":[
        {
          value: 'none',
          label: 'None',
        },
        {
          value: 'chevron',
          label: 'Chevron',
        },
        {
          value: 'plus',
          label: 'Plus',
        }
      ]}})],1),(_vm.elementProps.icon_type != 'none')?_c('div',{staticClass:"d-flex flex-column py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Icon Position")]),_c('TabIcon',{staticClass:"mt-2",attrs:{"value":_vm.elementProps.icon_position,"changeValue":($event) => _vm.updateProps('icon_position', $event),"options":[
        { value: 'left', icon: 'align-left',tooltip: 'Left' },
        { value: 'right', icon: 'align-right', tooltip: 'Right' },
      ],"primaryColor":_vm.primaryColor}})],1):_vm._e(),(_vm.elementProps.icon_type != 'none')?_c('div',{staticClass:"d-flex flex-column py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Icon Color")]),_c('ColorPicker',{staticClass:"mt-2",attrs:{"value":_vm.elementProps.icon_color,"changeColor":($event) => _vm.updateProps('icon_color', $event),"primaryColor":_vm.primaryColor}})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }