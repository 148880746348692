<template>
  <div id="sg-icon-picker">
    <el-dialog
      title="Choose Icon"
      :visible.sync="dialogVisible"
      width="50%"
      :before-close="handleClose"
      :show-close="false"
    >
      <el-input
        placeholder="Search Icon"
        prefix-icon="el-icon-search"
        v-model="input"
      >
      </el-input>
      <div class="sg-icon-list optiscroll">
        <div class="sg-content-icons">
          <div
            v-for="name in awsIcons.filter(x=> x.toLowerCase().includes(input.toLowerCase()))"
            :class="`sgicon ${name == selectIcon ? 'selected' : ''}`"
            :data-icon="name"
            :key="name"
            @click="selectIcon = name"
          >
            <div class="sg_icon-wrap">
              <i :class="`sg_icon fa ${name}`"></i>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">Cancel</el-button>
        <el-button type="primary" @click="handleSave(selectIcon)">Save</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { awsIcons } from "./icons";

export default {
  watch: {
    dialogVisible: function (newValue, oldValue) {
      if (newValue) {
        this.input = ""
      }
    }
  },
  props: {
    dialogVisible: Boolean,
    handleClose: Function,
    handleSave: Function
  },
  data() {
    return {
      input: "",
      awsIcons: awsIcons,
      selectIcon: null,
    };
  },
  methods: {
    // handleClose(done) {},
  },
};
</script>

<style scoped>
.optiscroll {
  position: relative;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.optiscroll.is-enabled {
  overflow: hidden;
}

#sg-icon-picker .el-dialog__wrapper {
  z-index: 10000 !important;
}

#sg-icon-picker .sg-icon-list {
  display: block;
  height: 300px;
  overflow: hidden;
  margin-top: 15px;
  margin-bottom: 10px;
}

#sg-icon-picker .sgicon {
  width: 67px;
  height: 50px;
  line-height: 50px;
  margin: 5px;
  font-size: 28px;
  text-align: center;
  float: left;
  cursor: pointer;
  color: #2f2f2f;
}

.optiscroll .sg-content-icons {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
}
.sgicon:hover {
  background: #e9e9e9;
}

#sg-icon-picker .sgicon.selected {
  background: #305288;
  color: #fff;
}
</style>