<template>
  <div label-position="top" size="mini" :model="elementProps">
    <div class="d-flex align-items-center justify-content-between py-2">
      <span class="sgSubTitle">Required</span>
      <el-switch
        :value="elementProps.required"
        :active-color="primaryColor"
        inactive-color="#949494"
        @change="updateProps('required', $event)"
        :disabled="elementProps.disabled"
      />
    </div>
    <div class="sgDesc mt-2" v-if="elementProps.disabled">
      <svg
        fill="#FFC439"
        height="14px"
        viewBox="0 0 24 24"
        width="14px"
        style="position: relative;"
      >
        <path d="M0 0h24v24H0z" fill="none"></path>
        <path
          d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z"
        ></path>
      </svg>
      You cannot change this field.
    </div>
    <div v-else class="sgDesc mt-2">Make this field required.</div>
  </div>
</template>
<script>
import mixin from "../styleSettings/mixin";

export default {
  mixins: [mixin],
  computed: {},
  components: {},
  props: {
    primaryColor: String,
  },
  methods: {
    updateProps(name, value) {
      if(value ==  this.elementProps[name]) return;
      this.elementPropsSetter({ [name]: value });
    },
  },
};
</script>

<style scoped>
</style>
