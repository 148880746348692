<template>
    <div
      data-label="Collection Banner"
      data-key="collection-banner"
      class="sg-wrap collection-banner module"
      :class="hover_effect!='none'?hover_effect:''"
      @mouseover="isHovering = true"
      @mouseout="isHovering = false"
      :id="`element-${node.uuid}`"
      :style="elementCSS"
    >
      <div class="sg-module">
        <span
          class="item-content"
        >
          <div
            v-if="editor?.findNode(node?.uuid)?.children?.length == 0"
            class="text-center drop-element"
            :class="{ dragging_space: dragging == node.uuid }"
          >
            <span>Add element</span>
          </div>
          <slot />
        </span>
      </div>
      <div class="sg_hero-bg-wrap"
      :style="hover_effect=='effect-zoom'?{
            'background-image': 'inherit',
            'background-size': 'inherit',
            'background-position': 'inherit',
            'transition': `transform ${hover_effect_time}s ease-in-out 0s`
         }:{}"
      >
            <div class="sg_hero-bg" 
            :style="hover_effect=='effect-zoom'?{
                'background-image': 'inherit',
                'background-size': 'inherit',
                'background-position': 'inherit',
                'transition': `transform ${hover_effect_time}s ease-in-out 0s`
            }:{}">
            </div>
      </div>
      <div v-if="parseFloat(overlay_opacity) > 0" class="banner-overlay" />
      <ToolBar
        ref="(c)banner"
        :toolbar_id="`(c)banner`"
        class="container-toolbar"
        :class="{ 'container-toolbar-show': isHovering, 'is-dragging': dragging == node.uuid }"
      ></ToolBar>
      <div v-if="dragging == node.uuid" class="dragging"></div>
  
      <v-style>
        {{ custom_css }}
        #element-{{ node.uuid }} .banner-overlay {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background-color: {{ overlay_color }};
          opacity: {{ overlay_opacity }}
        }
  
        .drop-element {
          background-color: #F2F3F4;
          position: relative;
          height: 50px;
          overflow: hidden;
          cursor: pointer;
          transition: all 0.3s ease;
        }
        .drop-element:hover {
          background-color: #e5e6e8;
          transition: all 0.3s ease;
        }
        .drop-element span {
          position: absolute;
          font-size: 13px;
          top: 50%;
          left: 50%;
          color: #a0a0a0;
          transform: translate(-50%, -50%);
        }
  
        #element-{{ node.uuid }} {
            padding-top: 200px;
            padding-bottom: 200px;
            background-color: lightgrey;
            background-image: {{ imgUrl?`url(${imgUrl}) !important`:'none' }};
            background-size: cover;
            background-position: center center;
            height: auto !important;
            background-attachment: {{hover_effect=='effect-parallax'?'fixed':'initial'}};
        }
  
        #element-{{ node.uuid }} > .sg-module > .item-content {
            display: block;
            position: relative;
        }
        #element-{{ node.uuid }} > .sg_hero-bg-wrap {
            position: absolute;
            left: 0px;
            top: 0px;
            width: 100%;
            height: 100%;
            overflow: hidden;
        }

        #element-{{ node.uuid }}.effect-zoom > .sg_hero-bg-wrap > .sg_hero-bg {
            display: block;
            width: 100%;
            height: 100%;
        }

        #element-{{ node.uuid }}.effect-zoom:hover > .sg_hero-bg-wrap > .sg_hero-bg {
            transform: scale(1.2);
        }
  
       
        #element-{{ node.uuid }} > .sg_hero-bg-wrap img {
            width: 100%;
        }

        #element-{{ node.uuid }} > .sg-module {
            position: relative;
            z-index: 11;
        }

        #element-{{ node.uuid }}.effect-zoom {
            cursor: pointer;
        }
  

  
        @media (max-width: 767px) {  
          #element-{{ node.uuid }}.collection-banner {
            display: {{ mobile.display }};
            margin: {{ getValue('margin_top','mobile') }}px {{ getValue('margin_right','mobile') }}px {{ getValue('margin_bottom','mobile') }}px {{ getValue('margin_left','mobile') }}px;
            padding-top: {{`${getValue('padding_top','mobile')}px`}};
            padding-right: {{`${getValue('padding_right','mobile')}px`}};
            padding-bottom: {{`${getValue('padding_bottom','mobile')}px`}};
            padding-left: {{`${getValue('padding_left','mobile')}px`}};
            {{ getValue('border_style','mobile') ? `border-style: ${getValue('border_style','mobile')};` : '' }}
            {{ getValue('border_color','mobile') ? `border-color: ${getValue('border_color','mobile')};` : '' }}
            {{ getValue('border_width','mobile') ? `border-width: ${getValue('border_width','mobile')}px;` : '' }}
            {{ getValue('border_top_left_radius','mobile') ? `border-top-left-radius: ${getValue('border_top_left_radius','mobile')}px;` : '' }}
            {{ getValue('border_top_right_radius','mobile') ? `border-top-right-radius: ${getValue('border_top_right_radius','mobile')}px;` : '' }}
            {{ getValue('border_bottom_left_radius','mobile') ? `border-bottom-left-radius: ${getValue('border_bottom_left_radius','mobile')}px;` : '' }}
            {{ getValue('border_bottom_right_radius','mobile') ? `border-bottom-right-radius: ${getValue('border_bottom_right_radius','mobile')}px;` : '' }}
            {{ getValue('opacity','mobile') ? `opacity: ${getValue('opacity','mobile')};` : '' }}
            {{ getValue('shadow_style','mobile') == 'drop' ? `filter: drop-shadow(${getValue('shadow_x','mobile') || 0}px ${getValue('shadow_y','mobile') || 0}px ${getValue('shadow_blur','mobile') || 0}px ${getValue('shadow_color','mobile') || 'rgb(255, 255, 255)'});` : ''}}
            {{ getValue('shadow_style','mobile') == 'box' ? `box-shadow: ${getValue('shadow_type','mobile') == 'inset' ? 'inset' : ''} ${getValue('shadow_x','mobile') || 0}px ${getValue('shadow_y','mobile') || 0}px ${getValue('shadow_spread','mobile') || 0}px ${getValue('shadow_blur','mobile') || 0}px ${getValue('shadow_color','mobile') || 'rgb(255, 255, 255)'};` : ''}}
          }
        }
        @media (max-width: 991px) and (min-width: 768px) {
          #element-{{ node.uuid }}.collection-banner {
            display: {{ tablet.display }};
            margin: {{ getValue('margin_top','tablet') }}px {{ getValue('margin_right','tablet') }}px {{ getValue('margin_bottom','tablet') }}px {{ getValue('margin_left','tablet') }}px;
            padding-top: {{`${getValue('padding_top','tablet')}px`}};
            padding-right: {{`${getValue('padding_right','tablet')}px`}};
            padding-bottom: {{`${getValue('padding_bottom','tablet')}px`}};
            padding-left: {{`${getValue('padding_left','tablet')}px`}};
            {{ getValue('border_style','tablet') ? `border-style: ${getValue('border_style','tablet')};` : '' }}
            {{ getValue('border_color','tablet') ? `border-color: ${getValue('border_color','tablet')};` : '' }}
            {{ getValue('border_width','tablet') ? `border-width: ${getValue('border_width','tablet')}px;` : '' }}
            {{ getValue('border_top_left_radius','tablet') ? `border-top-left-radius: ${getValue('border_top_left_radius','tablet')}px;` : '' }}
            {{ getValue('border_top_right_radius','tablet') ? `border-top-right-radius: ${getValue('border_top_right_radius','tablet')}px;` : '' }}
            {{ getValue('border_bottom_left_radius','tablet') ? `border-bottom-left-radius: ${getValue('border_bottom_left_radius','tablet')}px;` : '' }}
            {{ getValue('border_bottom_right_radius','tablet') ? `border-bottom-right-radius: ${getValue('border_bottom_right_radius','tablet')}px;` : '' }}
            {{ getValue('opacity','tablet') ? `opacity: ${getValue('opacity','tablet')};` : '' }}
            {{ getValue('shadow_style','tablet') == 'drop' ? `filter: drop-shadow(${getValue('shadow_x','tablet') || 0}px ${getValue('shadow_y','tablet') || 0}px ${getValue('shadow_blur','tablet') || 0}px ${getValue('shadow_color','tablet') || 'rgb(255, 255, 255)'});` : ''}}
            {{ getValue('shadow_style','tablet') == 'box' ? `box-shadow: ${getValue('shadow_type','tablet') == 'inset' ? 'inset' : ''} ${getValue('shadow_x','tablet') || 0}px ${getValue('shadow_y','tablet') || 0}px ${getValue('shadow_spread','tablet') || 0}px ${getValue('shadow_blur','tablet') || 0}px ${getValue('shadow_color','tablet') || 'rgb(255, 255, 255)'};` : ''}}
          }
        }
        @media (max-width: 1199px) and (min-width: 992px) {
          #element-{{ node.uuid }}.collection-banner {
            display: {{ laptop.display }};
            margin: {{ getValue('margin_top','laptop') }}px {{ getValue('margin_right','laptop') }}px {{ getValue('margin_bottom','laptop') }}px {{ getValue('margin_left','laptop') }}px;
            padding-top: {{`${getValue('padding_top','laptop')}px`}};
            padding-right: {{`${getValue('padding_right','laptop')}px`}};
            padding-bottom: {{`${getValue('padding_bottom','laptop')}px`}};
            padding-left: {{`${getValue('padding_left','laptop')}px`}};
            {{ getValue('border_style','laptop') ? `border-style: ${getValue('border_style','laptop')};` : '' }}
            {{ getValue('border_color','laptop') ? `border-color: ${getValue('border_color','laptop')};` : '' }}
            {{ getValue('border_width','laptop') ? `border-width: ${getValue('border_width','laptop')}px;` : '' }}
            {{ getValue('border_top_left_radius','laptop') ? `border-top-left-radius: ${getValue('border_top_left_radius','laptop')}px;` : '' }}
            {{ getValue('border_top_right_radius','laptop') ? `border-top-right-radius: ${getValue('border_top_right_radius','laptop')}px;` : '' }}
            {{ getValue('border_bottom_left_radius','laptop') ? `border-bottom-left-radius: ${getValue('border_bottom_left_radius','laptop')}px;` : '' }}
            {{ getValue('border_bottom_right_radius','laptop') ? `border-bottom-right-radius: ${getValue('border_bottom_right_radius','laptop')}px;` : '' }}
            {{ getValue('opacity','laptop') ? `opacity: ${getValue('opacity','laptop')};` : '' }}
            {{ getValue('shadow_style','laptop') == 'drop' ? `filter: drop-shadow(${getValue('shadow_x','laptop') || 0}px ${getValue('shadow_y','laptop') || 0}px ${getValue('shadow_blur','laptop') || 0}px ${getValue('shadow_color','laptop') || 'rgb(255, 255, 255)'});` : ''}}
            {{ getValue('shadow_style','laptop') == 'box' ? `box-shadow: ${getValue('shadow_type','laptop') == 'inset' ? 'inset' : ''} ${getValue('shadow_x','laptop') || 0}px ${getValue('shadow_y','laptop') || 0}px ${getValue('shadow_spread','laptop') || 0}px ${getValue('shadow_blur','laptop') || 0}px ${getValue('shadow_color','laptop') || 'rgb(255, 255, 255)'};` : ''}}
          }
        }
        @media (min-width: 1200px) {
          #element-{{ node.uuid }}.collection-banner {
            display: {{ desktop.display }};
            margin: {{ getValue('margin_top','desktop') }}px {{ getValue('margin_right','desktop') }}px {{ getValue('margin_bottom','desktop') }}px {{ getValue('margin_left','desktop') }}px;
            padding-top: {{`${getValue('padding_top','desktop')}px`}};
            padding-right: {{`${getValue('padding_right','desktop')}px`}};
            padding-bottom: {{`${getValue('padding_bottom','desktop')}px`}};
            padding-left: {{`${getValue('padding_left','desktop')}px`}};
            {{ desktop.border_style ? `border-style: ${desktop.border_style};` : '' }}
            {{ desktop.border_color ? `border-color: ${desktop.border_color};` : '' }}
            {{ desktop.border_width ? `border-width: ${desktop.border_width}px;` : '' }}
            {{ desktop.border_top_left_radius ? `border-top-left-radius: ${desktop.border_top_left_radius}px;` : '' }}
            {{ desktop.border_top_right_radius ? `border-top-right-radius: ${desktop.border_top_right_radius}px;` : '' }}
            {{ desktop.border_bottom_left_radius ? `border-bottom-left-radius: ${desktop.border_bottom_left_radius}px;` : '' }}
            {{ desktop.border_bottom_right_radius ? `border-bottom-right-radius: ${desktop.border_bottom_right_radius}px;` : '' }}
            {{ desktop.opacity ? `opacity: ${desktop.opacity};` : '' }}
            {{ desktop.shadow_style == 'drop' ? `filter: drop-shadow(${desktop.shadow_x || 0}px ${desktop.shadow_y || 0}px ${desktop.shadow_blur || 0}px ${desktop.shadow_color || 'rgb(255, 255, 255)'});` : ''}}
            {{ desktop.shadow_style == 'box' ? `box-shadow: ${desktop.shadow_type == 'inset' ? 'inset' : ''} ${desktop.shadow_x || 0}px ${desktop.shadow_y || 0}px ${desktop.shadow_spread || 0}px ${desktop.shadow_blur || 0}px ${desktop.shadow_color || 'rgb(255, 255, 255)'};` : ''}}
          }
        }
  
        
      </v-style>
    </div>
  </template>
  
  <script>
import ToolBar from "../../elements/utils/ToolBar.vue";
import elementStyleMixin from "../../elements/elementStyleMixin";
  
import Visibility from "../../elements/designSettings/Visibility.vue";
import Spacing from "../../elements/designSettings/Spacing.vue";
import Background from "../../elements/designSettings/Background.vue";
import Border from "../../elements/designSettings/Border.vue";
import Corner from "../../elements/designSettings/Corner.vue";
import Shadow from "../../elements/designSettings/Shadow.vue";
import Opacity from "../../elements/designSettings/Opacity.vue";
import CustomCSS from '../../elements/styleSettings/CustomCSS.vue';
import BannerNotice from "./settings/BannerNotice.vue";
import CollectionImage from "./settings/CollectionImage.vue";
import ExtraClass from "../../elements/otherSettings/ExtraClass.vue";
import Overlay from "../../elements/otherSettings/Overlay.vue";
import { designStyles, otherProps, otherStyles, actionTypes } from "@/constants";
import Animations from '../../elements/designSettings/Animations.vue';

export default {
  components: { ToolBar },
  mixins: [elementStyleMixin],
  inject: ["node", "editor"],
  computed: {
      dragging: function () {
      return this.editor?.draggedNode?.uuid
      },
  },
  watch: {
    dragging: function (newValue, oldValue) {
      var $iframe = $("#designEditor").contents();
      var $element = $iframe.find(`#element-${this.node.uuid}`);
      var $content = $element.find(`.sg-module`);
      var $content2 = $element.find(`.sg_hero-bg-wrap`);

      if (newValue && newValue == this.node.uuid) {
        setTimeout(() => {
        $element.css("padding","0px 0px 0px 0px");
        $element.css("margin","0px 0px 0px 0px");
        $content.hide();
        $content2.hide();
        },100);
      }else if (oldValue == this.node.uuid){
        $element.css("padding","");
        $element.css("margin","");
        $content.show();
        $content2.show();
      }
    }
  },
  props: {
    ...otherProps,
    screen: String,
    component: String,
    
    overlay_opacity: Number,
    overlay_color: String,
    desktop: Object,
    laptop: Object,
    tablet: Object,
    mobile: Object,
    hover_effect_time: Number,
    quality: String,
    imgUrl: String,
    hover_effect: String,
    image_size: String,
    notice:String

  },
  data() {
    return {
      isHovering: false,
    };
  },
  craft: {
    defaultProps: {
      ...otherStyles,
      notice:'This element will not show correct information until a collection is picked in a dynamic Product List.',
      overlay_color: "rgb(255, 255, 255)",
      overlay_opacity: 0,
      quality:'lighter',
      imgUrl:'https://cdn.shopify.com/s/files/1/0657/0813/files/slide-girl_2048x.jpg',
      hover_effect:'none',
      hover_effect_time: 1.6,
      image_size:'1024x1024',
      desktop: {
        ...designStyles,
        padding_top: 200,
        padding_bottom: 200,
      },
      laptop: {
        ...designStyles,

      },
      tablet: {
        ...designStyles,

      },
      mobile: {
        ...designStyles,
      },
      elementStyle: {},
    },
    settings: {
      settings: {
        "": BannerNotice,
        "Collection Image": { "Collection Image":CollectionImage, multi_screen: true },
        Overlay,
        "Extra Class": ExtraClass, "Custom CSS": CustomCSS,
      },
      design: {
        Visibility,
        Spacing: { Spacing, multi_screen: true },
        Background: { Background, multi_screen: true },
        Border: { Border, multi_screen: true },
        Corner: { Corner, multi_screen: true },
        Shadow: { Shadow, multi_screen: true },
        Opacity: { Opacity, multi_screen: true },
      },
      others: {
        Animations: { Animations, actions: actionTypes }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dragging {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: greenyellow;
  z-index: 10;
}
</style>
  