<template>
  <el-form label-position="top" size="" :model="elementProps">
    <div label-position="top" size="mini" :model="elementProps">
      <div class="d-flex flex-column py-2">
        <span class="setting-title">Background Type</span>
        <ToggleButton
          :primaryColor="primaryColor"
          :value="getValueLinked('background_type', elementProps[screen].background_type, 'image')"
          v-bind:changeValue="
            ($event) => updateProps(screen, 'background_type', $event)
          "
          :options="[
            {
              label: 'Image',
              value: 'image',
            },
            {
              label: 'Video',
              value: 'video',
            },
          ]"
        />
      </div>
      <div v-if="(getValueLinked('background_type', elementProps[screen].background_type, 'image')) == 'video'">
        <el-form-item label="Weblink">
          <el-input
            type="textarea"
            :rows="4"
            :value="getValueLinked('weblink', elementProps[screen].weblink)"
            @input="($event) => updateProps(screen, 'weblink', $event)"
            resize="none"
          />
        </el-form-item>
        <div class="sgDesc mt-2">
          Eg:
          <a class="reference">https://www.youtube.com/watch?v=OQBlWco72c4</a>
        </div>
      </div>
      <div v-if="(getValueLinked('background_type', elementProps[screen].background_type, 'image')) != 'video'">
        <div class="d-flex flex-column py-2">
          <span class="setting-title">Background Image</span>
          <Upload
            :value="getValueLinked('imgUrl', elementProps[screen].imgUrl)"
            v-bind:changeValue="
              ($event) => updateProps(screen, 'imgUrl', $event)
            "
          />
        </div>
        <div class="d-flex flex-column py-2">
          <span class="setting-title">Image Source</span>
          <el-input
            class="mt-2"
            placeholder="Link"
            :value="getValueLinked('imgUrl', elementProps[screen].imgUrl)"
            @input="updateProps(screen, 'imgUrl', $event)"
            size="medium"
          />
        </div>
      </div>
    </div>
  </el-form>
</template>
  
  <script>
import mixin from "../../../elements/styleSettings/mixin";
import ToggleButton from "../../../elements/common/ToggleButton.vue";
import ColorPicker from "../../../elements/common/ColorPicker.vue";
import Upload from "../../../elements/common/Upload.vue";
import Select from "../../../elements/common/Select.vue";
import Slider from "../../../elements/common/Slider.vue";

export default {
  mixins: [mixin],
  components: {
    ToggleButton,
    ColorPicker,
    Upload,
    Select,
    Slider,
  },
  props: {
    screen: String,
    primaryColor: String,
  },
  methods: {
    updateProps(screen, name, value) {
      const { desktop, laptop, tablet, mobile } = this.elementProps;
      const data_screen = Object.assign({}, this.elementProps[screen]);
      if (value == data_screen[name]) return;

      data_screen[name] = value;
      var obj = {
        [screen]: data_screen,
      };
      this.elementPropsSetter(obj);
    },
  },
};
</script>
  
  <style lang="scss">
.square-toggle {
  width: 60%;
  margin-left: 0px !important;
  margin-right: 0px !important;
  .item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 30%;
    flex: 0 0 30%;
    aspect-ratio: 1/1;
    margin: 1px;
    background-color: #666666;
  }
  .item.active {
    background-color: transparent;
  }
}
</style>
  