<template>
  <div label-position="top" size="small" :model="elementProps">
    <div class="d-flex flex-column py-2" v-if="elementProps.variant_type == 'select'">
      <span class="setting-title">Width</span>
      <el-input
        :value="getValue('select_width',elementProps[screen].select_width)"
        class="input-with-select mt-2"
        @input="updateProps('select_width', $event)"
        size="medium"
      >
        <el-select
          slot="append"
          placeholder="-"
          :value="getValue('select_width_type',elementProps[screen].select_width_type)"
          @change="updateProps('select_width_type', $event)"
        >
          <el-option label="px" value="px"></el-option>
          <el-option label="%" value="%"></el-option>
        </el-select>
      </el-input>
    </div>

    <div class="d-flex flex-column py-2" v-if="elementProps.variant_type == 'select'">
      <span class="setting-title">Height</span>
      <el-input
        class="mt-2"
        :value="getValue('select_height',elementProps[screen].select_height)"
        @input="updateProps('select_height', $event)"
        size="medium"
      />
    </div>
    <div class="d-flex flex-column py-2" v-if="elementProps.variant_type == 'select'">
      <span class="setting-title">Margin Bottom</span>
      <Slider
        class="mt-2"
        :primaryColor="primaryColor"
        :value="Number.parseInt(getValue('select_margin_bottom',elementProps[screen].select_margin_bottom))"
        :min="0"
        :max="100"
        :step="1"
        v-bind:changeValue="($event) => updateProps('select_margin_bottom', $event)"
        unit="px"
        :onlyInteger="true"
      />
    </div>


    <div class="d-flex flex-column py-2" v-if="elementProps.variant_type == 'swatch'">
      <span class="setting-title">Swatch Width</span>
      <el-input
        :value="getValue('swatch_width',elementProps[screen].swatch_width)"
        class="input-with-select mt-2"
        @input="updateProps('swatch_width', $event)"
        size="medium"
      >
        <el-select
          slot="append"
          placeholder="-"
          :value="getValue('swatch_width_type',elementProps[screen].swatch_width_type)"
          @change="updateProps('swatch_width_type', $event)"
        >
          <el-option label="px" value="px"></el-option>
          <el-option label="%" value="%"></el-option>
        </el-select>
      </el-input>
    </div>

    <div class="d-flex flex-column py-2" v-if="elementProps.variant_type == 'swatch'">
      <span class="setting-title">Swatch Height</span>
      <el-input
        class="mt-2"
        :value="getValue('swatch_height',elementProps[screen].swatch_height)"
        @input="updateProps('swatch_height', $event)"
        size="medium"
      />
    </div>
  </div>
</template>

<script>
import mixin from "../../../elements/styleSettings/mixin";
import Slider from "../../../elements/common/Slider.vue";

export default {
  mixins: [mixin],
  components: {Slider},
  props: {
    screen: String,
    primaryColor: String,
  },
  methods: {
    updateProps(name, value) {
      const screen = this.$props.screen
      const data_screen = Object.assign({}, this.elementProps[screen])
      if(value ==  data_screen[name]) return;
      data_screen[name] = value
      if (name == 'width' && (value == 'auto' && isNaN(parseInt(value)))) {
        data_screen['width_type'] = ''
      }
      this.elementPropsSetter({ [screen]: data_screen });
    },
  },
};
</script>

<style scoped>
</style>
