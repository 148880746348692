<template>
  <div
    data-label="Popup"
    data-key="Popup"
    :class="`sg-wrap module ${showPopup ? 'showPopup' : ''}`"
    :id="`element-${node.uuid}`"
    @mouseover="isHovering = true"
    @mouseout="isHovering = false"
    :data-position="position"
    :data-effects="effect"
    :data-link-button="link_button ? 1 : 0"
  >
    <div
      :class="`sg-module ${extra_class || ''}`"
      :data-name="name"
      :data-overlay="overlay"
      data-overlaycolor="#000000"
      data-overlayopacity="0.7"
      data-trigger="none"
      :data-overlayclose="overlay_close"
      data-fullscreen-xs="0"
      data-fullscreen-sm="0"
      data-fullscreen-md="0"
      data-fullscreen-lg="0"
      data-closepostion="inside"
      :data-position="position"
      data-time="10"
      data-smartexit="1"
      data-scrollpercent="10"
      data-exitmobile="none"
      data-exittimer="10"
      data-displayfrequency="3"
      :data-effects="effect"
      data-cookie=""
    >
      <div data-index="1" class="item-content">
        <div
          v-if="(editor?.findNode(node?.uuid)?.children?.length||0) == 0"
          class="text-center drop-element"
          :class="{ dragging_space: dragging == node.uuid }"
        >
          <span>Add element</span>
        </div>
        <slot> </slot>
      </div>
    </div>
    <div
      class="sg-closePopup"
      :data-node-uuid="node.uuid"
      @click="
        ($event) => {
          updateProps('showPopup', false);
        }
      "
    >
      <svg viewBox="0 0 12 12">
        <path
          d="M7.1,6l4.5,4.5l-1.1,1.1L6,7.1l-4.5,4.5l-1.1-1.1L4.9,6L0.5,1.5l1.1-1.1L6,4.9l4.5-4.5l1.1,1.1L7.1,6z"
        ></path>
      </svg>
    </div>
    <ToolBar
      ref="popup"
      :toolbar_id="`popup`"
      class="container-toolbar"
      :class="{ 'container-toolbar-show': isHovering, 'is-dragging': dragging == node.uuid }"
      :isDraggable="false"
    >
    </ToolBar>
    <div v-if="dragging == node.uuid" class="dragging"></div>
    <v-style>
      {{ custom_css }}
      #element-{{ node.uuid }} {
        {{ effect != 'none' ? `animation: ${effect} 0.5s;` : ''}}
      }
      
      #element-{{ node.uuid }} .sg-closePopup {
        top: {{ close_btn == 'inside' ? '4px' : '-10px'}};
        right: {{ close_btn == 'inside' ? '4px' : '-10px'}};
        background-color: {{ close_btn_background }};
      }
      #element-{{ node.uuid }} .sg-closePopup svg {
        fill: {{ close_btn_color }};
      }
      
      @media (max-width: 767px){
        #element-{{ node.uuid }} .sg-module { 
          padding: {{ getValue('padding', 'mobile') }}px !important; 
        } 
        #element-{{ node.uuid }} { 
          width: {{ getModelSize(getValue('width', 'mobile'), getValue('width_type', 'mobile')) }};
          height: {{ getModelSize(getValue('height', 'mobile'), getValue('height_type', 'mobile')) }};
          max-width: {{ getModelSize(getValue('width', 'mobile'), getValue('width_type', 'mobile')) }} !important;
          max-height: {{ getModelSize(getValue('height', 'mobile'), getValue('height_type', 'mobile')) }} !important;
          {{ getDisplayValue('mobile') }}
          {{ getMarginValue('mobile') }}
          {{ getPaddingValue('mobile') }}
          {{ getBackgroundValue('mobile') }}
          {{ getBorderValue('mobile') }}
          {{ getBorderRadiusValue('mobile') }}
          {{ getOpacityValue('mobile') }}
          {{ getShadowValue('mobile') }}
          {{ getOtherStyle('mobile') }}
        }
      } 
      @media (max-width: 991px) and (min-width: 768px){
        #element-{{ node.uuid }} .sg-module { 
          padding: {{ getValue('padding', 'tablet') }}px !important; 
        } 
        #element-{{ node.uuid }} { 
          width: {{ getModelSize(getValue('width', 'tablet'), getValue('width_type', 'tablet')) }};
          height: {{ getModelSize(getValue('height', 'tablet'), getValue('height_type', 'tablet')) }};
          {{ getDisplayValue('tablet') }}
          {{ getMarginValue('tablet') }}
          {{ getPaddingValue('tablet') }}
          {{ getBackgroundValue('tablet') }}
          {{ getBorderValue('tablet') }}
          {{ getBorderRadiusValue('tablet') }}
          {{ getOpacityValue('tablet') }}
          {{ getShadowValue('tablet') }}
          {{ getOtherStyle('tablet') }}
        }
      } 
      @media (max-width:1199px) and (min-width: 992px){ 
        #element-{{ node.uuid }} .sg-module { 
          padding: {{ getValue('padding', 'laptop') }}px !important; 
        } 
        #element-{{ node.uuid }} { 
          width: {{ getModelSize(getValue('width', 'laptop'), getValue('width_type', 'laptop')) }};
          height: {{ getModelSize(getValue('height', 'laptop'), getValue('height_type', 'laptop')) }};
          {{ getDisplayValue('laptop') }}
          {{ getMarginValue('laptop') }}
          {{ getPaddingValue('laptop') }}
          {{ getBackgroundValue('laptop') }}
          {{ getBorderValue('laptop') }}
          {{ getBorderRadiusValue('laptop') }}
          {{ getOpacityValue('laptop') }}
          {{ getShadowValue('laptop') }}
          {{ getOtherStyle('laptop') }}
        }
      } 
      @media (min-width: 1200px){ 
        #element-{{ node.uuid }} .sg-module { 
          padding: {{ getValue('padding', 'desktop') }}px !important; 
        } 
        #element-{{ node.uuid }} { 
          width: {{ getModelSize(getValue('width', 'desktop'), getValue('width_type', 'desktop')) }};
          height: {{ getModelSize(getValue('height', 'desktop'), getValue('height_type', 'desktop')) }};
          {{ getDisplayValue('desktop') }}
          {{ getMarginValue('desktop') }}
          {{ getPaddingValue('desktop') }}
          {{ getBackgroundValue('desktop') }}
          {{ getBorderValue('desktop') }}
          {{ getBorderRadiusValue('desktop') }}
          {{ getOpacityValue('desktop') }}
          {{ getShadowValue('desktop') }}
          {{ getOtherStyle('desktop') }}
        }
      }
    </v-style>
  </div>
</template>



<script>
import ToolBar from "../../elements/utils/ToolBar.vue";

import elementStyleMixin from "../../elements/elementStyleMixin";
import mixin from "../../elements/styleSettings/mixin";
import Position from "./settings/Position.vue";
import ExtraClass from "../../elements/otherSettings/ExtraClass.vue";
import Dimension from "./settings/Dimension.vue";
import Display from "./settings/Display.vue";
import Padding from "./settings/Padding.vue";
import Overlay from "./settings/Overlay.vue";
import CloseButton from "./settings/CloseButton.vue";
import Effect from "./settings/Effect.vue";
import Visibility from "../../elements/designSettings/Visibility.vue";
import Spacing from "../../elements/designSettings/Spacing.vue";
import Background from "../../elements/designSettings/Background.vue";
import Border from "../../elements/designSettings/Border.vue";
import Corner from "../../elements/designSettings/Corner.vue";
import Shadow from "../../elements/designSettings/Shadow.vue";
import Opacity from "../../elements/designSettings/Opacity.vue";
import Indicator from "../../../../../components/Indicator.vue";
import { designStyles, otherProps, otherStyles, actionTypes } from "@/constants";
import CustomCSS from '../../elements/styleSettings/CustomCSS.vue';
import Animations from '../../elements/designSettings/Animations.vue';

export default {
  components: { ToolBar, Indicator },
  mounted(){
  },
  data() {
    return {
      isHovering: false,
    };
  },
  inject: ["node", "editor"],
  mixins: [elementStyleMixin],
  props: {
    ...otherProps,
    link_button: Boolean,
    button_id: String,
    component: String,
    showPopup: Boolean,
    name: String,
    close_btn: String,
    close_btn_background: String,
    close_btn_color: String,
    extra_class: String,
    overlay: Boolean,
    overlay_color: String,
    overlay_close: Boolean,
    overlay_opacity: Number,
    position: String,
    effect: String,
    desktop: Object,
    laptop: Object,
    tablet: Object,
    mobile: Object,
  },
  methods: {
    updateProps(name, value) {
      const { editor } = this;
      for (var i = 0; i < editor.popups.length; i++) {
        if (editor.popups[i].uuid == this.node.uuid) {
          editor.popups[i].setProps({ showPopup: false }, true);
        }
      }
      // editor.selectNode(null);
    },
    getModelSize(size, type) {
      return `${size}${type}`;
    },
  },
  computed: {
    dragging: function () {
      return this.editor?.draggedNode?.uuid
    },
  },
  craft: {
    defaultProps: {
      ...otherStyles,
      link_button: false,
      button_id: "",
      name: "New Popup",
      close_btn: "inside",
      close_btn_background: "rgb(255, 255, 255)",
      close_btn_color: "rgb(0, 0, 0)",
      extra_class: "",
      showPopup: true,
      overlay: true,
      overlay_color: "rgb(0, 0, 0)",
      overlay_close: true,
      overlay_opacity: 0.7,
      position: "center-center",
      effect: "fade",
      desktop: {
        ...designStyles,
        padding: 30,
        full_screen: false,
        width: "600",
        height: "400",
        width_type: "px",
        height_type: "px",
      },
      laptop: {
        ...designStyles,
        padding: 30,
        full_screen: false,
        width: "600",
        height: "400",
        width_type: "px",
        height_type: "px",
      },
      tablet: {
        ...designStyles,
        padding: 30,
        full_screen: false,
        width: "600",
        height: "400",
        width_type: "px",
        height_type: "px",
      },
      mobile: {
        ...designStyles,
        padding: 30,
        full_screen: false,
        width: "600",
        height: "400",
        width_type: "px",
        height_type: "px",
      },
      elementStyle: {},
    },
    settings: {
      settings: {
        Display,
        Padding: { Padding, multi_screen: true },
        "Close Button": CloseButton,
        Dimension: { Dimension, multi_screen: true },
        Overlay,
        Position,
        Effect,
        "Extra Class": ExtraClass, "Custom CSS": CustomCSS,
      },
      design: {
        Visibility,
        Spacing: { Spacing, multi_screen: true },
        Background: { Background, multi_screen: true },
        Border: { Border, multi_screen: true },
        Corner: { Corner, multi_screen: true },
        Shadow: { Shadow, multi_screen: true },
        Opacity: { Opacity, multi_screen: true },
      },
      others: {
        Animations: { Animations, actions: actionTypes.filter(e => ['scroll_into_view', 'after_page_finish_loading'].includes(e.value)) }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>



