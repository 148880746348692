<template>
  <div
    data-label="CheckboxField"
    data-key="checkboxfield"
    class="sg-wrap block"
    :id="`element-${node.uuid}`"
    @mouseover="isHovering=true" 
    @mouseout="isHovering=false"
    :class="{ 'dragging': dragging == node.uuid }"
  >
    <div 
      class="bl"
      :class="extra_class"
    >
      <input
        :id="`input-check-${node.uuid}`"
        type="checkbox"
        class="sg_checkboxfield sg_gs-text-paragraph-1"
        :name="name"
        :checked="default_checked"
      />
      <label :for="`input-check-${node.uuid}`">
        {{ label }}
      </label>
    </div>
    <ToolBar
      ref="checkboxfield"
      :toolbar_id="`checkboxfield`"
      class="container-toolbar"
      :class="{ 'container-toolbar-show': isHovering, 'is-dragging': dragging == node.uuid }"
    ></ToolBar>

    <v-style>
      {{ custom_css }}

      @media (max-width: 767px){ 
        #element-{{ node.uuid }} .bl {
          {{ getPaddingValue('mobile') }}
          {{ getBackgroundValue('mobile') }}
          {{ getBorderValue('mobile') }}
          {{ getBorderRadiusValue('mobile') }}
          {{ getOpacityValue('mobile') }}
          {{ getShadowValue('mobile') }}
          {{ getOtherStyle('mobile') }}
        }
        #element-{{ node.uuid }} label {
          font-family: {{ getValue('font_family','mobile') }};
          font-weight: {{ getValue('font_weight','mobile') }};
          {{ getValue('font_size', 'mobile') > 0 ? `font-size: ${getValue('font_size','mobile')}px;` : '' }}
          {{ getValue('color', 'mobile') ? `color: ${getValue('color','mobile')};` : '' }}
        }
        #element-{{ node.uuid }} {
          {{ getDisplayValue('mobile') }}
          {{ getMarginValue('mobile') }}
        }
      } 
      @media (max-width: 991px) and (min-width: 768px){ 
        #element-{{ node.uuid }} .bl {
          {{ getPaddingValue('tablet') }}
          {{ getBackgroundValue('tablet') }}
          {{ getBorderValue('tablet') }}
          {{ getBorderRadiusValue('tablet') }}
          {{ getOpacityValue('tablet') }}
          {{ getShadowValue('tablet') }}
          {{ getOtherStyle('tablet') }}
        }
        #element-{{ node.uuid }} label {
          font-family: {{ getValue('font_family','tablet') }};
          font-weight: {{ getValue('font_weight','tablet') }};
          {{ getValue('font_size', 'tablet') > 0 ? `font-size: ${getValue('font_size','tablet')}px;` : '' }}
          {{ getValue('color', 'tablet') ? `color: ${getValue('color','tablet')};` : '' }}
        }
        #element-{{ node.uuid }} {
          {{ getDisplayValue('tablet') }}
          {{ getMarginValue('tablet') }}
        }
      } 
      @media (max-width: 1199px) and (min-width: 992px){ 
        #element-{{ node.uuid }} .bl {
          {{ getPaddingValue('laptop') }}
          {{ getBackgroundValue('laptop') }}
          {{ getBorderValue('laptop') }}
          {{ getBorderRadiusValue('laptop') }}
          {{ getOpacityValue('laptop') }}
          {{ getShadowValue('laptop') }}
          {{ getOtherStyle('laptop') }}
        }
        #element-{{ node.uuid }} label {
          font-family: {{ getValue('font_family','laptop') }};
          font-weight: {{ getValue('font_weight','laptop') }};
          {{ getValue('font_size', 'laptop') > 0 ? `font-size: ${getValue('font_size','laptop')}px;` : '' }}
          {{ getValue('color', 'laptop') ? `color: ${getValue('color','laptop')};` : '' }}
        }
        #element-{{ node.uuid }} {
          {{ getDisplayValue('laptop') }}
          {{ getMarginValue('laptop') }}
        }
      } 
      @media (min-width: 1200px){ 
        #element-{{ node.uuid }} .bl {
          {{ getPaddingValue('desktop') }}
          {{ getBackgroundValue('desktop') }}
          {{ getBorderValue('desktop') }}
          {{ getBorderRadiusValue('desktop') }}
          {{ getOpacityValue('desktop') }}
          {{ getShadowValue('desktop') }}
          {{ getOtherStyle('desktop') }}
        }
        #element-{{ node.uuid }} label {
          font-family: {{ getValue('font_family','desktop') }};
          font-weight: {{ getValue('font_weight','desktop') }};
          {{ getValue('font_size', 'desktop') > 0 ? `font-size: ${getValue('font_size','desktop')}px;` : '' }}
          {{ getValue('color', 'desktop') ? `color: ${getValue('color','desktop')};` : '' }}
        }
        #element-{{ node.uuid }} {
          {{ getDisplayValue('desktop') }}
          {{ getMarginValue('desktop') }}
        }
      }

    </v-style>
  </div>

</template>

<script>
import Typography from "./styleSettings/Typography.vue";
import ExtraClass from "./otherSettings/ExtraClass.vue";
import Name from "./otherSettings/Name.vue";
import Label from "./otherSettings/Label.vue";

import elementStyleMixin from "./elementStyleMixin";
import ToolBar from "./utils/ToolBar.vue";
import Visibility from "./designSettings/Visibility.vue";
import Spacing from "./designSettings/Spacing.vue";
import Background from "./designSettings/Background.vue";
import Border from "./designSettings/Border.vue";
import Corner from "./designSettings/Corner.vue";
import Shadow from "./designSettings/Shadow.vue";
import Opacity from "./designSettings/Opacity.vue";
import { designStyles, otherProps, otherStyles, actionTypes } from "@/constants";
import CustomCSS from "./styleSettings/CustomCSS.vue";
import Animations from "./designSettings/Animations.vue";

export default {
  mixins: [elementStyleMixin],
  components: { ToolBar },
  props: {
    ...otherProps,
    component: String,
    label: String,
    extra_class:String,
    name: String,
    desktop: Object,
    laptop: Object,
    mobile: Object,
    tablet: Object,
    read_only_name: Boolean,
    required: Boolean,
    disabled: Boolean,
  },
  data() {
    return {
      isHovering: false,
    };
  },
  inject: ["node",'editor'],
  computed: {
    dragging: function () {
      return this.editor?.draggedNode?.uuid
    },
  },
  methods: {
    getHeight(height) {
      if (height.includes('%') || height.includes('px') || isNaN(parseInt(height))) {
        return height;
      }
      return `${height}px`
    }
  },
  craft: {
    defaultProps: {
      ...otherStyles,
      disabled: false,
      label: "Default option",
      extra_class:"",
      name:"contact[option]",
      required:true,
      read_only_name:false,
      desktop: {
        ...designStyles,
        font_weight: '400',
        font_size: 16,
        font_family: 'inherit',
        color: 'rgb(0, 0, 0)',
        alignment: 'center',
        padding_top: 10,
        padding_bottom: 10,
        padding_left: 18,
        padding_right: 18,
      },
      laptop: {
        ...designStyles,
      },
      mobile: {
        ...designStyles,
      },
      tablet: {
        ...designStyles,
      },
      typographyActives: [
        "font-size",
        "font-weight",
        "font-family",
        "color"
      ],
      elementStyle: {
        
      },
    },
    settings: {
      settings: {
        Name,
        Label,
        Typography: { Typography, multi_screen: true},
        "Extra Class": ExtraClass,
        "Custom CSS": CustomCSS,
      },
      design: {
        Visibility,
        Spacing: { Spacing, multi_screen: true },
        Background: { Background, multi_screen: true },
        Border: { Border, multi_screen: true },
        Corner: { Corner, multi_screen: true },
        Shadow: { Shadow, multi_screen: true },
        Opacity: { Opacity, multi_screen: true },
      },
      others: {
        Animations: { Animations, actions: actionTypes }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  width: 100%;
  box-sizing: border-box;
  white-space: pre-wrap;
}
</style>
