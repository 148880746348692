<template>
  <div class="cart-button" id="button-show-cart">
    <div class="cart-button-container">
      <span class="cart-number">0</span>
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" stroke-width="2" stroke="#fff" fill="none" stroke-linecap="round" stroke-linejoin="round">
        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
        <path d="M6 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path>
        <path d="M17 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path>
        <path d="M17 17h-11v-14h-2"></path>
        <path d="M6 5l14 1l-1 7h-13"></path>
      </svg>
    </div>
  </div>
  
</template>
<script>
export default {
  name: "CartButton",
  props: {},
  watch: {},
  data () {
    return {};
  },
  methods: {
      
  },
};
</script>