<template>
  <div label-position="top" size="mini" :model="elementProps">
    <div class="d-flex flex-column py-2" >
      <span>Element</span>
      <ToggleButton
        :value="element"
        v-bind:changeValue="($event) => {(element = $event)}"
        :options="[
          { value: 'quantity', label: 'Quantity' },
          { value: 'selector', label: 'Selector' },
        ]"
        :primaryColor="primaryColor"
      />
    </div>
    <div class="d-flex flex-column py-2">
      <span style="font-size: 12px; color: #ccc">Color</span>
      <ColorPicker
        class="mt-2"
        :value="
          element == 'quantity'
            ? getValue('quantity_color',elementProps[screen].quantity_color)
            : getValue('select_color',elementProps[screen].select_color)
        "
        v-bind:changeColor="
          ($event) =>
            updateProps(
              element == 'quantity' ? 'quantity_color' : 'select_color',
              $event
            )
        "
        :primaryColor="primaryColor"
      />
    </div>
    <div class="d-flex flex-column py-2">
      <span style="color: #aaaaaa; font-size: 12px">Font Size</span>
      <Slider
        class="mt-2"
        :step="1"
        :primaryColor="primaryColor"
        :value="
          element == 'quantity'
            ? getValue('quantity_font_size',elementProps[screen].quantity_font_size)
            : getValue('select_font_size',elementProps[screen].select_font_size)
        "
        v-bind:changeValue="
          ($event) =>
            updateProps(
              element == 'quantity' ? 'quantity_font_size' : 'select_font_size',
              parseInt($event)
            )
        "
        unit="px"
        :max="100"
        :min="1"
        :onlyInteger="true"
      />
    </div>

    <div class="d-flex flex-column py-2" >
      <span style="font-size: 12px; color: #ccc">Font</span>
      <Select
        class="mt-2"
        :primaryColor="primaryColor"
        :value="
          element == 'quantity'
            ? getValue('quantity_font_family',elementProps[screen].quantity_font_family)
            : getValue('select_font_family',elementProps[screen].select_font_family)
        "
        v-bind:changeValue="
          ($event) =>
            updateProps(
              element == 'quantity'
                ? 'quantity_font_family'
                : 'select_font_family',
              $event
            )
        "
        :hasAction="true"
        v-bind:onAction="handleShowModal"
        actionLabel="Open Font Manager"
        :options="getListFont()"
        :key="fontKey"
      />
    </div>
  </div>
</template>
  
  <script>
import mixin from "../../../elements/styleSettings/mixin";
import ColorPicker from "../../../elements/common/ColorPicker.vue";
import Slider from "../../../elements/common/Slider.vue";
import Select from "../../../elements/common/Select.vue";
import ToggleButton from "../../../elements/common/ToggleButton.vue";
import Node from "@/core/Node";
import { makeId } from "@/constants";

export default {
  mixins: [mixin],
  components: {
    ColorPicker,
    Slider,
    Select,
    ToggleButton,
  },
  props: {
    node: Node,
    activeFonts: Array,
    screen: String,
    primaryColor: String,
  },
  data() {
    return {
      fontKey: makeId(20),
      element: "quantity",
    };
  },
  watch: {
    activeFonts: function (newValue, oldValue) {
      this.fontKey = makeId(20);
    },
  },
  methods: {
    updateTextShadow(key, value) {
      const textShadow = this.getStyle("text-shadow");
      this.setStyle("text-shadow", {
        ...textShadow,
        [key]: value,
      });
    },
    handleShowModal() {
      this.$bus.emit("showFontManager", this.node);
    },
    getListFont() {
      const listFonts = [
        {
          value: "inherit",
          label: "Default",
        },
      ];
      this.node?.editor?.fonts?.map((e) => {
        listFonts.push({ value: e, label: e });
      });
      return listFonts;
    },
    updateProps(name, value) {
      const screen = this.$props.screen;
      const data_screen = Object.assign({}, this.elementProps[screen]);
      if (value == data_screen[name]) return;
      data_screen[name] = value;
      this.elementPropsSetter({ [screen]: data_screen });
    },
    updateArrayProps(name, value) {
      const screen = this.$props.screen;
      const data_screen = Object.assign({}, this.elementProps[screen]);
      data_screen[name] = Object.assign([], data_screen[name]);
      if (data_screen[name].includes(value)) {
        var index = data_screen[name].findIndex((x) => x == value);
        data_screen[name].splice(index, 1);
      } else {
        data_screen[name].push(value);
      }
      this.elementPropsSetter({ [screen]: data_screen });
    },
  },
};
</script>
  