<template>
  <div label-position="top" size="mini" :model="elementProps">
    <div class="d-flex flex-column py-2">
      <span class="setting-title">Name</span>
      <Input
        class="mt-2"
        :value="elementProps.name"
        :primaryColor="primaryColor"
        v-bind:changeValue="($event) => updateProps('name', $event)"
      />
    </div>
  </div>
</template>

<script>
import mixin from "../../../elements/styleSettings/mixin";
import Input from '../../../elements/common/Input.vue';

export default {
  mixins: [mixin],
  components: {
    Input,
  },
  methods: {
    updateProps(name, value) {
      this.elementPropsSetter({ [name]: value });
    },
  },
  props: {
    primaryColor: String,
  }
};
</script>
