<template>
  <div id="app">
    <div id="mainBody" class="main-wrapper" v-if="!['Editor','Builder'].includes(currentRouteName)">
      <TopBar />
      <div
        
        class="
          container
          px-1 px-xs-2 px-sm-4 px-md-5 px-xl-6 px-lg-2 px-mlg-2
          main-main-body
        "
      >
        <router-view />
      </div>
      <Loader :visible="visibleLoader" />
    </div>
    <SideBar v-if="!['Editor','Builder'].includes(currentRouteName)"></SideBar>
    <router-view v-if="['Editor','Builder'].includes(currentRouteName)"/>
    <!-- <router-link to="/editor">Home</router-link>  -->
    <!-- <router-view/> -->
  </div>
</template>

<script>
import SideBar from "./layouts/SideBar.vue";
import TopBar from "./layouts/TopBar.vue";
import axios from 'axios';
import Loader from './components/Loader.vue';
import { request } from './services';
import Cookies from 'js-cookie';

export default {
  name: "App",
  components: {
    TopBar,
    SideBar,
    Loader,
  },
  data() {
    return {
      visibleLoader: false,
      apiUrl: process.env.VUE_APP_API_URL,
      loginUrl: process.env.VUE_APP_API_LOGIN_URL
    }
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
  created() {
    // const user = JSON.parse(Cookies.get('user_onepage') || "{}")
    let token = null;
    if (this.$route.query?.token) {
      token = this.$route.query?.token
    }
    if (token) {
      const data = {
        sid: token
      }
      request.post(`${process.env.VUE_APP_API_LOGIN_URL}/auth/quicklogin`, data).then(res => {
        if (res.data.success) {
          const data = JSON.stringify(res.data.data)
          Cookies.set('user_onepage', data, {domain: process.env.VUE_APP_DOMAIN, expires: 30 })
          const pathname = window.location.pathname
          if (pathname == '/') {
            window.location.href = '/homepages'
          }
        } else {
          Cookies.remove('user_onepage', { domain: process.env.VUE_APP_DOMAIN })
          window.location.href = process.env.VUE_APP_LOGIN_URL
        }
      }).catch(err => {
        this.$toastr.e(err?.response?.data?.message || 'Error')
        Cookies.remove('user_onepage', { domain: process.env.VUE_APP_DOMAIN })
        window.location.href = process.env.VUE_APP_LOGIN_URL
      })
    } else {
      const pathname = window.location.pathname
      if (pathname == '/') {
        window.location.href = '/homepages'
      }
    }

    this.$bus.on("showLoader", () => {
      this.showLoader();
    });

    this.$bus.on("hideLoader", () => {
      this.hideLoader();
    });
  },
  methods: {
    showLoader() {
      this.visibleLoader = true;
    },
    hideLoader() {
      this.visibleLoader = false;
    }
  }
};
</script>

<style>
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
  /* margin-top: 60px; */
}
.main-wrapper {
  margin-top: 60px;
  -webkit-box-orient: horizontal;
  -ms-flex-direction: row;
  flex-direction: row;
  overflow-x: hidden;
  overflow-y: overlay;
  -ms-flex: 1;
  flex: 1;
  margin-left: 280px;
}

.main-wrapper,
.main-wrapper .main-main-body {
  -webkit-box-direction: normal;
  -webkit-box-flex: 1;
  max-height: calc(100vh - 60px);
}

.main-wrapper .main-main-body {
  position: relative;
  height: 100vh;
  padding: 40px 88px 60px 88px !important;
  -webkit-box-orient: vertical;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex: 1;
  flex: 1;
  margin: auto;
}
</style>