<template>
  <div id="sg-liquid-manager">
    <el-dialog
      :visible.sync="show"
      width="60%"
      top="8vh"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <div slot="title" class="title-dialog">
        <span class="text-title">Code Editor - Liquid</span>
        <span class="node-id">#element-{{ node?.uuid }}</span>
      </div>
      <div class="d-flex flex-column">
        <div class="d-flex items-center">
          <div class="button-lang" :class="lang == 'html' ? 'active' : ''" @click="() => switchLang('html')">HTML</div>
          <div class="button-lang" :class="lang == 'css' ? 'active' : ''" @click="() => switchLang('css')">CSS</div>
          <div class="button-lang" :class="lang == 'javascript' ? 'active' : ''" @click="() => switchLang('javascript')">Script</div>
        </div>
        <div class="editor">
          <AceEditor 
            v-if="lang == 'html'"
            v-model="template" 
            @init="editorInit" 
            lang="html" 
            theme="monokai" 
            width="100%" 
            height="400px"
            :options="{
                enableBasicAutocompletion: true,
                enableLiveAutocompletion: true,
                fontSize: 12,
                fontFamily: 'monospace',
                highlightActiveLine: true,
                enableSnippets: true,
                showLineNumbers: true,
                tabSize: 2,
                showPrintMargin: false,
                showGutter: true,
            }"
          />
          <AceEditor 
            v-if="lang == 'css'"
            v-model="css" 
            @init="editorInit" 
            lang="css" 
            theme="monokai" 
            width="100%" 
            height="400px"
            :options="{
                enableBasicAutocompletion: true,
                enableLiveAutocompletion: true,
                fontSize: 12,
                fontFamily: 'monospace',
                highlightActiveLine: true,
                enableSnippets: true,
                showLineNumbers: true,
                tabSize: 2,
                showPrintMargin: false,
                showGutter: true,
            }"
          />
          <AceEditor 
            v-if="lang == 'javascript'"
            v-model="javascript" 
            @init="editorInit" 
            lang="javascript" 
            theme="monokai" 
            width="100%" 
            height="400px"
            :options="{
                enableBasicAutocompletion: true,
                enableLiveAutocompletion: true,
                fontSize: 12,
                fontFamily: 'monospace',
                highlightActiveLine: true,
                enableSnippets: true,
                showLineNumbers: true,
                tabSize: 2,
                showPrintMargin: false,
                showGutter: true,
            }"
          />
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">Cancel</el-button>
        <el-button type="primary" @click="() => handleSave({ template, css, javascript })">Save</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Node from '@/core/Node';
import AceEditor from 'vuejs-ace-editor';
export default {
  props: {
    show: Boolean,
    handleClose: Function,
    handleSave: Function,
    node: Node
  },
  components: {
    AceEditor
  },
  data() {
    return {
      lang: "html",
      template: "",
      css: "",
      javascript: "",
    };
  },
  watch: {
    node: function(nodeValue) {
      this.template = nodeValue.props.template;
      this.css = nodeValue.props.css || `#element-${nodeValue.uuid} {
  
}`;
      this.javascript = nodeValue.props.javascript;
    }
  },
  methods: {
    switchLang(lang) {
      this.lang = lang;
    },
    editorInit: function () {
      require('brace/ext/language_tools') //language extension prerequsite...
      require('brace/mode/html')                
      require('brace/mode/javascript')    //language
      require('brace/mode/less')
      require('brace/mode/css')
      require('brace/theme/monokai')
      require('brace/snippets/javascript') //snippet
    },
  }
};
</script>

<style lang="scss">
#sg-liquid-manager {
  .title-dialog {
    display: flex;
    gap: 8px;
    align-items: center;
  }

  .text-title {
    font-size: 18px;
  }

  .node-id {
    background-color: #D6D6D6;
    border-radius: 6px;
    padding: 2px 6px;
  }

  .button-lang {
    padding: 12px;
    font-size: 14px;
    background-color: #585858;
    color: #fff;
    cursor: pointer;
    width: 90px;
    text-align: center;
    &:hover {
      background-color: #222222;
    }
    &.active {
      background-color: #222222;
    }
  }

}
</style>