<template>
  <div label-position="top" size="mini" :model="elementProps">
    <div class="py-2">
      <span class="setting-title">Progress</span>
      <Slider
        class="mt-2"
        :step="1"
        :primaryColor="primaryColor"
        :value="elementProps.progress"
        v-bind:changeValue="($event) => updateProps('progress', parseInt($event))"
        unit="%"
        :max="100"
        :min="0"
        :onlyInteger="true"
      />
    </div>
    <div class="py-2">
      <span class="setting-title">Progress Background</span>
      <ColorPicker
        class="mt-2"
        :value="elementProps.progress_background"
        v-bind:changeColor="($event) => updateProps('progress_background', $event)"
        :primaryColor="primaryColor"
      />
    </div>
  </div>
</template>

<script>
import mixin from '../../../elements/styleSettings/mixin';
import Slider from '../../../elements/common/Slider.vue';
import ColorPicker from '../../../elements/common/ColorPicker.vue';

export default {
  mixins: [mixin],
  components: {
    Slider, ColorPicker
  },
  props: {
    primaryColor: String,
  },
  methods: {
    updateProps (name, value) {
      if(value ==  this.elementProps[name]) return;
      this.elementPropsSetter({ [name]: value });
    }
  },
};
</script>