<template>
  <div
    data-label="(P) Stock Counter"
    data-key="(p) stock_counter"
    data-parent="product"
    class="sg-wrap module"
    :id="`element-${node.uuid}`"
    @mouseover="isHovering = true"
    @mouseout="isHovering = false"
    style="overflow:hidden;"
    :class="{ 'dragging': dragging == node.uuid }"
    :data-product-uuid="product.product_node_uuid"
  >
    <div
      :class="`sg-module sg_stock_counter-${
        elementStyle['alignment']
      }-xs ${extra_class || ''}`"
      class="
        sg_stock_counter-center-lg
        sg_stock_counter--md sg_stock_counter--sm sg_stock_counter--xs
      "
      data-quantity-source="auto"
      data-remaining-percentage="18"
      data-variant-id="43407970992351"
      data-fix-auto-remaining-quantity="-1"
      data-remaining-quantity="5"
      data-template="<mark>HURRY!</mark> ONLY %number% LEFT"
      data-out-of-stock="OUT OF STOCK"
      data-no-track-quantity="<mark>HURRY!</mark> LET BUY NOW"
      data-continue-out-of-stock="Restock soon, PREORDER NOW!"
      data-always-show-up="0"
      data-scarcity-thresold="20"
      data-show-icon="1"
      data-choose-icon="PGkgY2xhc3M9ImdmX2ljb24gZmEgZmEtZmxhc2giPjwvaT4="
      data-color="#e84879"
      data-rounded-border="1"
      data-animation="1"
      data-font-size="16px"
      data-progress-bar-height="10px"
    >
      <!-- <div class="sg-variants-quantity no-render">
          <div class="sg-variant-43407970992351" data-available="false">0</div>

          <div class="sg-variant-43407971025119" data-available="false">0</div>

          <div class="sg-variant-43407971057887" data-available="false">0</div>

          <div class="sg-variant-43407971090655" data-available="false">0</div>
        </div> -->
      <div class="sg_scarcity-status" :style="{'font-size': `${elementStyle['font-size']}px`}">
        <i :class="`sg_icon fa ${icon}`" :style="{'color': `${elementStyle['color']}`}" v-if="show_icon"></i>
        {{quantity_source == 'auto'?out_of_stock:''}}
        <span v-html="getMark" v-if="quantity_source == 'manual'"></span>
      </div>
      <div class="sg_scarcity-progress-bar">
        <div
          :class="`sg_progress-bar ${rounded_border?'rounded-border':''} ${animation?'animation':''}`"
          :style="{
            'height': `${elementStyle['height']}px`,
            'background-color': `${elementStyle['color']}`,
            'background-position': `${remaining_percentage}% center`
          }
          "
        ></div>
      </div>
    </div>

    <ToolBar
      ref="(p)stock_counter"
      :toolbar_id="`(p)stock_counter`"
      class="container-toolbar"
      :class="{ 'container-toolbar-show': isHovering, 'is-dragging': dragging == node.uuid }"
    >
    </ToolBar>

    <v-style> 
      {{ custom_css }}

      #element-{{ node.uuid }} .sg_scarcity-status {
          color: {{color_counter|| 'black'}};
      }
      #element-{{ node.uuid }} .sg-module.sg_stock_counter-center-xs {
          width: {{elementStyle['width']}}%!important;
      }
      @media (max-width: 767px){ 
        #element-{{ node.uuid }} {
          {{ getDisplayValue('mobile') }}
          {{ getMarginValue('mobile') }}
          {{ getPaddingValue('mobile') }}
          {{ getBackgroundValue('mobile') }}
          {{ getBorderValue('mobile') }}
          {{ getBorderRadiusValue('mobile') }}
          {{ getOpacityValue('mobile') }}
          {{ getShadowValue('mobile') }}
          {{ getOtherStyle('mobile') }}
        }
      } 
      @media (max-width: 991px) and (min-width: 768px){ 
        #element-{{ node.uuid }} {
          {{ getDisplayValue('tablet') }}
          {{ getMarginValue('tablet') }}
          {{ getPaddingValue('tablet') }}
          {{ getBackgroundValue('tablet') }}
          {{ getBorderValue('tablet') }}
          {{ getBorderRadiusValue('tablet') }}
          {{ getOpacityValue('tablet') }}
          {{ getShadowValue('tablet') }}
          {{ getOtherStyle('tablet') }}
        }
      } 
      @media (max-width: 1199px) and (min-width: 992px){ 
        #element-{{ node.uuid }} {
          {{ getDisplayValue('laptop') }}
          {{ getMarginValue('laptop') }}
          {{ getPaddingValue('laptop') }}
          {{ getBackgroundValue('laptop') }}
          {{ getBorderValue('laptop') }}
          {{ getBorderRadiusValue('laptop') }}
          {{ getOpacityValue('laptop') }}
          {{ getShadowValue('laptop') }}
          {{ getOtherStyle('laptop') }}
        }
      } 
      @media (min-width: 1200px){ 
        #element-{{ node.uuid }} {
          {{ getDisplayValue('desktop') }}
          {{ getMarginValue('desktop') }}
          {{ getPaddingValue('desktop') }}
          {{ getBackgroundValue('desktop') }}
          {{ getBorderValue('desktop') }}
          {{ getBorderRadiusValue('desktop') }}
          {{ getOpacityValue('desktop') }}
          {{ getShadowValue('desktop') }}
          {{ getOtherStyle('desktop') }}
        } 
      }
    </v-style>
  </div>
</template>

<script>
import ToolBar from "../../elements/utils/ToolBar.vue";

import elementStyleMixin from "../../elements/elementStyleMixin";
import ExtraClass from "../../elements/otherSettings/ExtraClass.vue";
import CustomCSS from '../../elements/styleSettings/CustomCSS.vue';
import DimensionsStock from "./settings/DimensionsStock.vue";
import DisplayStock from "./settings/DisplayStock.vue";
import Message from "./settings/Message.vue";
import StockQuantity from "./settings/StockQuantity.vue";

import Visibility from "../../elements/designSettings/Visibility.vue";
import Spacing from "../../elements/designSettings/Spacing.vue";
import Background from "../../elements/designSettings/Background.vue";
import Border from "../../elements/designSettings/Border.vue";
import Corner from "../../elements/designSettings/Corner.vue";
import Shadow from "../../elements/designSettings/Shadow.vue";
import Opacity from "../../elements/designSettings/Opacity.vue";
import { designStyles, otherProps, otherStyles, actionTypes } from "@/constants";
import Animations from '../../elements/designSettings/Animations.vue';


export default {
  components: { ToolBar },
  data() {
    return {
      isHovering: false,
    };
  },
  inject: ["node","product",'editor'],
  mixins: [elementStyleMixin],
  props: {
    
    extra_class: String, ...otherProps,
    component: String,
    parent: String,
    show_icon: Boolean,
    icon: String,
    rounded_border: Boolean,
    animation: Boolean,
    remaining_percentage: Number,
    typeWidth: String,
    remaining_quantity: String,
    quantity_source:String,
    template:String,
    out_of_stock:String,
    no_track_quantity:String,
    continue_out_of_stock:String,
    desktop: Object,
    laptop: Object,
    tablet: Object,
    mobile: Object,
    color_counter:String
  },
  computed: {
    getMark() {
      if(this.quantity_source=="manual"){
        var html = this.template
        html = html.replace("%number%",`<span class="remaining-number" style="color:${this.elementStyle['color']}">${this.remaining_quantity||0}</span>`)
        html = html.replace("<mark", `<mark style="background-color: ${this.elementStyle['color']};" `)
        return html;
      }
      return "";
    },
    dragging: function () {
      return this.editor?.draggedNode?.uuid
    },
  },
  craft: {
    defaultProps: { ...otherStyles,
        parent: "Product",
        "remaining_quantity":"20",
        "quantity_source":"auto",
        "remaining_percentage":18,
        "remaining_quantity":"5",
        "template":"<mark>HURRY!</mark> ONLY %number% LEFT",
        "out_of_stock":"OUT OF STOCK",
        "no_track_quantity":"<mark>HURRY!</mark> LET BUY NOW",
        "continue_out_of_stock":"Restock soon, PREORDER NOW!",
        "always_show_up":0,
        "scarcity_thresold":20,
        "rounded_border": true,
        "show_icon":true,
        "icon":'fa-flash',
        "animation":true,
        "typeWidth":'%',
        "color_counter": "rgba(0, 0, 0,1)",
        desktop: {
          ...designStyles,
        },
        laptop: {
          ...designStyles,
        },
        tablet: {
          ...designStyles,
        },
        mobile: {
          ...designStyles,
        },
        elementStyle: {
            display: "block",
            "height":10,
            "width":100,
            "font-size": 16,
            "text-transform": "none",
            "font-weight": "bold",
            "alignment": "center",
            "line-height": "1.5",
            "color": "rgb(232, 72, 121)",
            "margin-top": 0,
            "margin-left": 0,
            "margin-bottom": 0,
            "margin-right": 0,
            "padding-top": 0,
            "padding-left": 0,
            "padding-bottom": 0,
            "padding-right": 0,
            "text-shadow": {
                x: 0,
                y: 0,
                blur: 0,
                color: "rgba(0,0,0,0)",
            },
            desktop: {},
            laptop: {},
            tablet: {},
            mobile: {},
        },
    },
    settings: {
      settings: {
        "Stock Quantity":StockQuantity,
        "Message":Message,
        "Dimensions": DimensionsStock,
        "Display":DisplayStock,
        "Extra Class": ExtraClass, "Custom CSS": CustomCSS,
      },
      design: {
        Visibility,
        Spacing: { Spacing, multi_screen: true },
        Background: { Background, multi_screen: true },
        Border: { Border, multi_screen: true },
        Corner: { Corner, multi_screen: true },
        Shadow: { Shadow, multi_screen: true },
        Opacity: { Opacity, multi_screen: true },
      },
      others: {
        Animations: { Animations, actions: actionTypes }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
