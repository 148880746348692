<template>
  <el-form label-position="top" size="" :model="elementProps">
    <div class="d-flex flex-column py-2">
      <span class="setting-title">Tab Background</span>
      <ColorPicker
        class="mt-2"
        :value="elementProps.tab_background"
        v-bind:changeColor="($event) => updateProps('tab_background', $event)"
        :primaryColor="primaryColor"
      />
    </div>
    <div class="d-flex flex-column py-2">
      <span class="setting-title">Text Color</span>
      <ColorPicker
        class="mt-2"
        :value="elementProps.text_color"
        v-bind:changeColor="($event) => updateProps('text_color', $event)"
        :primaryColor="primaryColor"
      />
    </div>
    <div class="d-flex flex-column py-2">
      <span class="setting-title">Default Tab</span>
      <Input 
        class="mt-2"
        :value="elementProps.default_tab"
        v-bind:changeValue="($event) => updateProps('default_tab', $event)"
        :primaryColor="primaryColor"
        type="number"
      />
    </div>
    <div class="sgDesc mt-2">
      The index of tab you want to display as default. Eg: 1, 2 or 6
    </div>
  </el-form>
</template>

<script>
import mixin from "../../../elements/styleSettings/mixin";
import ColorPicker from "../../../elements/common/ColorPicker.vue";
import Input from "../../../elements/common/Input.vue";
export default {
  mixins: [mixin],
  components: { ColorPicker, Input },
  props: {
    primaryColor: String
  },
  data() {
    return {
      timer: null,
    }
  },
  methods: {
    updateProps(name, value) {
      this.elementPropsSetter({ [name]: value });
      if (name == 'default_tab') {
        if (Number.parseInt(value) > this.node.props.items?.length) {
          clearTimeout(this.timer);
          this.timer = setTimeout(() => {
            this.elementPropsSetter({ [name]: this.node.props.items.length })
            this.activeTabs(this.node.props.items.length)
          }, 1000);
        } else if (Number.parseInt(value) < 0) {
          clearTimeout(this.timer);
          this.timer = setTimeout(() => {
            this.elementPropsSetter({ [name]: 1 })
            this.activeTabs(1)
          }, 1000);
        } else {
          this.activeTabs(value)
        }
      }
    },
    activeTabs(value) {
      let $iframe = $("#designEditor").contents();
      const $tabs = $iframe.find(`#element-${this.node.uuid}`);
      const default_tab = value || '1';
      $tabs.find('.tab-link').removeClass('current');
      $tabs.find('.tab-content').removeClass('current');
      const $tab_items = $tabs.find('.tab-link');
      $tab_items.each(function (index) {
        if (Number.parseInt(default_tab) == index + 1) {
          $(this).addClass('current');
        }
      })
      const $tab_contents = $tabs.find('.tab-content');
      $tab_contents.each(function (index) {
        if (Number.parseInt(default_tab) == index + 1) {
          $(this).addClass('current');
        }
      })
    }
  },
};
</script>

<style scoped>
</style>
