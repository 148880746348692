<template>
  <div class="custom-input-select" :style="cssVars">
    <el-input
      :value="value"
      @input="changeValue($event)"
      size="medium"
      :placeholder="placeholder || ''"
      :type="type || 'text'"
      :disabled="disabled"
    />
    <div 
      class="unit" 
      @focus="showUnit" 
      @focusout="hideUnit"
      tabindex="-1"
    >
      {{ unit }}
      <ul class="list-unit" :class="{active: active}">
        <li
          v-for="option in options"
          :key="option"
          @click="() => clickUnit(option)"
        >
          {{ option }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>

export default {
  components: {
    name: 'InputWithSelect'
  },
  data() {
    return {
      active: false
    }
  },
  props: {
    value: String,
    changeValue: Function,
    primaryColor: String,
    placeholder: String,
    type: String,
    disabled: Boolean,
    unit: String,
    changeUnit: Function,
    options: Array
  },
  computed: {
    cssVars() {
      return {
        '--primary-color': this.primaryColor || '#0060e5'
      }
    }
  },
  methods: {
    showUnit() {
      this.active = true;
    },
    hideUnit() {
      this.active = false;
    },
    clickUnit(option) {
      this.changeUnit(option)
      this.active = false;
      document.getElementsByClassName('.custom-input-select .unit').focusout();
    }
  }
};
</script>

<style>
  .custom-input-select {
    position: relative;
  }
  .custom-input-select .el-input__inner:focus {
    border-color: var(--primary-color) !important;
  }

  .custom-input-select input::-webkit-outer-spin-button,
  .custom-input-select input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .custom-input-select .unit {
    position: absolute;
    top: 50%;
    right: 2px;
    transform: translateY(-50%);
    padding: 5px 4px;
    background-color: #444444;
    font-size: 12px;
    border-radius: 4px;
    z-index: 10;
  }
  
  .custom-input-select .list-unit {
    display: none;
  }

  .custom-input-select .list-unit.active {
    display: block;
    position: absolute;
    top: 35px;
    right: 1px;
  }

  .custom-input-select .list-unit li {
    list-style: none;
    font-size: 12px;
    background-color: #444444;
    padding: 0px 4px;
    transition: all 0.3s ease;
  }

  .custom-input-select .list-unit li:hover {
    background: #666666;
  }
</style>