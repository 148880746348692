<template>
  <div label-position="top" size="" :model="elementProps">
    <div class="d-flex flex-column py-2">
      <span class="setting-title">Item Number</span>
      <Select
        class="mt-2"
        :value="elementProps.number_of_items"
        :primaryColor="primaryColor"
        v-bind:changeValue="($event) => updateProps('number_of_items', $event)"
        :options="[
          {
            value: '1',
            label: '1',
          },
          {
            value: '2',
            label: '2',
          },
          {
            value: '3',
            label: '3',
          },
          {
            value: '4',
            label: '4',
          }
        ]"
      />
    </div>
  </div>
</template>

<script>
import mixin from "../styleSettings/mixin";
import Select from "../common/Select.vue";
export default {
  mixins: [mixin],
  components: { Select },
  methods: {
    updateProps(name, value) {
      if(value ==  this.elementProps[name]) return;
      this.elementPropsSetter({ [name]: value });
      const number_of_items = Number.parseInt(value)
      if (this.node.children.length == number_of_items) {

      } else if (this.node.children.length > number_of_items) {
        const diff = this.node.children.length - number_of_items;
        Array.from(Array(diff).keys()).map(e => {
          this.node.children.pop()
        })
      } else {
        const ids = this.elementProps.items.map(e => e.id)
        const children = []
        ids.slice(0, number_of_items).map(id => {
          const item_child = this.node.children.find(e => e.props.item.id == id)
          if (item_child) {
            children.push(item_child)
          } else {
            const item = JSON.parse(JSON.stringify(this.elementProps.items)).find(e => e.id == id)
            const new_child = this.node.children[0].duplicate()
            new_child.setProps({item})
            new_child.parent = this.node
            new_child.children = []
            children.push(new_child)
          }
        })
        this.node.children = children
      }
    },
  },
  props: {
    primaryColor: String
  }
};
</script>

<style scoped>
</style>
