var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"label-position":"top","size":"mini","model":_vm.elementProps}},[_c('div',{staticClass:"py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Type")]),_c('Select',{staticClass:"mt-2",attrs:{"primaryColor":_vm.primaryColor,"value":_vm.getValue('border_style',_vm.elementProps[_vm.screen].border_style),"changeValue":($event) => _vm.updateProps('border_style', $event),"options":[
        {
          value: 'solid',
          label: 'Solid',
        },
        {
          value: 'dashed',
          label: 'Dashed',
        },
        {
          value: 'dotted',
          label: 'Dotted',
        }
      ]}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }