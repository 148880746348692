import axios from 'axios';
import { authHeader } from './auth-header'
const apiUrl = process.env.VUE_APP_API_URL
const loginUrl = process.env.VUE_APP_API_LOGIN_URL
import Cookies from 'js-cookie';
export const request = {
  post,
  get,
  put,
  delete: _delete,
};

export function logoutGlobal() {
  Cookies.remove('user_onepage',{ domain: process.env.VUE_APP_DOMAIN, expires: 30 })
  window.location.href = process.env.VUE_APP_LOGIN_URL
}

function post(path, data) {
  return axios({
    method: 'POST',
    url: `${path.includes('https://') ? '' : apiUrl}${path}`,
    data,
    headers: authHeader()
  }).then((res) => res).catch((err) => {
    try {
      if (err.response != null && err.response.status == 401) {
        logoutGlobal();
      }
    } catch (error) {
      
    }
    throw err
  });
}

function get(path, data) {
  const searchParams = Object.keys(data || {}).map((key) => {
    return encodeURIComponent(key) + '=' + encodeURIComponent((data || {})[key]);
  }).join('&');
  return axios({
    method: 'GET',
    url: `${path.includes('https://') ? '' : apiUrl}${path}?` + searchParams,
    headers: authHeader()
  }).then(res => res).catch(err => {
    try {
      if (err.response != null && err.response.status == 401) {
        logoutGlobal();
      }
    } catch (error) {
    }
    throw err
  })
}

function put(path, data) {
  return axios({
    method: 'PUT',
    url: `${path.includes('https://') ? '' : apiUrl}${path}`,
    headers: authHeader(),
    data
  }).then((res) => res).catch((err) => {
    try {
      if (err.response != null && err.response.status == 401) {
        logoutGlobal();
      }
    } catch (error) {
    }
    throw err
  });
}

function _delete(path, data) {
  return axios({
    method: 'DELETE',
    url: `${path.includes('https://') ? '' : apiUrl}${path}`,
    headers: authHeader(),
    data,
  }).then((res) => res).catch((err) => {
    try {
      if (err.response != null && err.response.status == 401) {
        logoutGlobal();
      }
    } catch (error) {
    }
    throw err
  });
}
