var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.onClickOutside),expression:"onClickOutside"}],ref:"myDiv",class:_vm.isOpen ? `show` : '',style:(_vm.elementCSS),attrs:{"id":"md-text-editor"}},[(_vm.number >= 0)?_c('ul',[_c('li',[_c('a',{class:`gfButton ${
          _vm.getArrayTag()?.indexOf('B') > -1 ? 'active' : ''
        }`,attrs:{"hhref":"javascript:void(0)","d-tooltips":"'Bold'"},domProps:{"innerHTML":_vm._s(_vm.icons.bold)},on:{"click":function($event){$event.preventDefault();return _vm.stylize('bold')}}})]),_c('li',[_c('a',{class:`gfButton ${
          _vm.getArrayTag()?.indexOf('I') > -1 ? 'active' : ''
        }`,attrs:{"href":"javascript:void(0)","data-action":"formatStyle","d-tooltips":"'Italic'"},domProps:{"innerHTML":_vm._s(_vm.icons.italic)},on:{"click":function($event){$event.preventDefault();return _vm.stylize('italic')}}})]),_c('li',[_c('a',{class:`gfButton ${
          _vm.getArrayTag()?.indexOf('U') > -1 ? 'active' : ''
        }`,attrs:{"href":"javascript:void(0)","data-action":"formatStyle","d-tooltips":"'Underline'"},domProps:{"innerHTML":_vm._s(_vm.icons.underline)},on:{"click":function($event){$event.preventDefault();return _vm.stylize('underline')}}})]),_c('li',[_c('a',{attrs:{"href":"javascript:void(0)","d-tooltips":"'Pick link'"},domProps:{"innerHTML":_vm._s(_vm.icons.link)},on:{"click":function($event){$event.preventDefault();return _vm.onClickShowToolbarLink()}}})]),_c('li',[_c('a',{attrs:{"slot":"reference","href":"javascript:void(0)","d-tooltips":"'Font color'","id":"edit_color"},on:{"click":_vm.showPopper},slot:"reference"},[_c('svg',{staticClass:"need_a_name",attrs:{"height":"24px","viewBox":"0 0 24 24"}},[_c('path',{attrs:{"d":"M11.58,4h1.67l4.58,11.67h-1.88l-0.93-2.5H9.81l-0.93,2.5H7L11.58,4z M10.43,11.5h3.97l-1.98-5.28 L10.43,11.5z"}}),_c('path',{staticClass:"colorActive",style:({
              fill: `${
                _vm.getArrayTag()?.indexOf('FONT') > -1
                  ? _vm.getArrayTag()[_vm.getArrayTag()?.indexOf('FONT') + 1]
                  : _vm.colorSelect
              }`,
            }),attrs:{"d":"M3,18h18v3H3V18z"}})])]),_c('PopupColorPicker',{attrs:{"show":_vm.show,"handleOutSite":_vm.hidePopper,"foreColor":_vm.stylize,"textColor":_vm.getArrayTag()?.indexOf('FONT') > -1
            ? _vm.getArrayTag()[_vm.getArrayTag()?.indexOf('FONT') + 1]
            : _vm.colorSelect,"highLight":_vm.getArrayTag()?.indexOf('BG') > -1
            ? _vm.getArrayTag()[_vm.getArrayTag()?.indexOf('BG') + 1]
            : 'rgba(0,0,0,1)',"toolbar":_vm.$el,"iframe":_vm.iframe}})],1),_c('li',[_c('a',{attrs:{"href":"javascript:void(0)","d-tooltips":"'Increase size'"},domProps:{"innerHTML":_vm._s(_vm.icons.size_plus)},on:{"click":function($event){$event.preventDefault();return _vm.fonSizeStyle(2)}}})]),_c('li',[_c('a',{attrs:{"href":"javascript:void(0)","d-tooltips":"'Decrease size'"},domProps:{"innerHTML":_vm._s(_vm.icons.size_minus)},on:{"click":function($event){$event.preventDefault();return _vm.fonSizeStyle(-2)}}})]),_c('li',[_c('a',{staticClass:"formatList",class:`gfButton ${
          _vm.getArrayTag()?.indexOf('OL') > -1 ? 'active' : ''
        }`,attrs:{"href":"javascript:void(0)","data-cmd":"insertOrderedList","d-tooltips":"'Numbers'"},domProps:{"innerHTML":_vm._s(_vm.icons.list_number)},on:{"click":function($event){$event.preventDefault();return _vm.stylize('insertOrderedList')}}})]),_c('li',[_c('a',{staticClass:"formatList",class:`gfButton ${
          _vm.getArrayTag()?.indexOf('UL') > -1 ? 'active' : ''
        }`,attrs:{"href":"javascript:void(0)","d-tooltips":"'UI'"},domProps:{"innerHTML":_vm._s(_vm.icons.list_dot)},on:{"click":function($event){$event.preventDefault();return _vm.stylize('insertUnorderedList')}}})])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }