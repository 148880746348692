<template>
  <div label-position="top" size="mini" :model="elementProps">
    <div class="py-2" v-if="!multi_screen">
      <span class="setting-title">Alignment</span>
      <ToggleButton
        class="mt-2"
        :primaryColor="primaryColor"
        :value="getStyle('text-align')"
        v-bind:changeValue="($event) => setStyle('text-align', $event)"
        :options="[
          {
            label: 'Left',
            value: 'left',
          },
          {
            label: 'Center',
            value: 'center',
          },
          {
            label: 'Right',
            value: 'right',
          },
        ]"
      />
    </div>
    <div class="py-2" v-else>
      <span class="setting-title">Alignment</span>
      <TabIcon
        class="mt-2"
        :primaryColor="primaryColor"
        :value="getValue('alignment',elementProps[screen].alignment)"
        v-bind:changeValue="($event) => updateProps('alignment', $event)"
        :options="[
          { value: 'left', icon: 'align-left',tooltip: 'Left' },
          { value: 'center', icon: 'align-center',tooltip: 'Center' },
          { value: 'right', icon: 'align-right', tooltip: 'Right' },
        ]"
      />
    </div>
  </div>
</template>

<script>
import mixin from "./mixin";
import ToggleButton from "../common/ToggleButton.vue";
import TabIcon from "../common/TabIcon.vue";
export default {
  name: "Alignment",
  mixins: [mixin],
  props: {
    multi_screen: Boolean,
    screen: String,
    primaryColor: String,
  },
  components: {
    ToggleButton,
    TabIcon
  },
  methods: {
    updateProps(name, value) {
      const screen = this.$props.screen
      const data_screen = Object.assign({}, this.elementProps[screen])
      if(value ==  data_screen[name]) return;
      data_screen[name] = value
      this.elementPropsSetter({ [screen]: data_screen });
    },
  }
};
</script>
