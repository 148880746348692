<template>
  <div label-position="top" size="mini" :model="elementProps">
    <div class="d-flex flex-column py-2">
      <span class="setting-title">Height</span>
      <el-input 
        class="mt-2 custom-input-height" 
        placeholder="Height" 
        :value="getValue('height', elementProps[screen].height)"
        @input="updateProps('height', parseInt($event || 0))"
        size="medium"
        type="number"
        :min="0"
      />
    </div>
    <div class="d-flex flex-column py-2">
      <span class="setting-title">Items Spacing</span>
      <Slider 
        class="mt-2"
        :primaryColor="primaryColor"
        :value="Number.parseInt(getValue('gap', elementProps[screen].gap))"
        :min="0"
        :max="100"
        :step="1"
        v-bind:changeValue="($event) => updateProps('gap', $event)"
        unit="px"
        :onlyInteger="true"
      />
    </div>
  </div>
</template>

<script>
import mixin from '../../../elements/styleSettings/mixin';
import Slider from '../../../elements/common/Slider.vue';
export default {
  mixins: [mixin],
  props: {
    screen: String,
    primaryColor: String
  },
  components: {
    Slider
  },
  methods: {
    updateProps(name, value) {
      const screen = this.$props.screen
      const data_screen = Object.assign({}, this.elementProps[screen])
      if(value ==  data_screen[name]) return;
      data_screen[name] = value
      this.elementPropsSetter({ [screen]: data_screen });
    },
  }
};
</script>

<style>
  .custom-input-height input::-webkit-outer-spin-button, .custom-input-height input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
</style>
