
import Node from "../../components/Node.vue";
import Vue from "vue";
import OverlayPopup from "../../views/landing/components/Iframe/OverlayPopup.vue";
import { request } from "@/services";
import Cookies from 'js-cookie';
import _ from 'lodash';

class NodeService {
  constructor(vm) {
    this.vm = vm;
    this.iframe = document.getElementById("designEditor");
    this.isScroll = false
  }

  getElement() {
    return this.vm.$el;
  }

  getElementBoundingClientRect() {
    return this.vm.$el.getBoundingClientRect();
  }

  getEditor() {
    return this.vm.editor;
  }

  getCurrentNode() {
    return this.vm.node;
  }

  onLeftHalf({ clientX }) {
    const { left, width } = this.getElementBoundingClientRect();

    if (clientX < (left + (width / 2))) {
      return true;
    }

    return false;
  }

  onTopHalf({ clientY }) {
    const { top, height } = this.getElementBoundingClientRect();
    if (clientY < (top + (height / 2))) {
      return true;
    }

    return false;
  }

  onEdge({ clientX, clientY }, edgeThickness = 8) {
    const {
      top, left, width, height,
    } = this.getElementBoundingClientRect();

    if (
      clientX < (left + width - edgeThickness)
      && clientX > (left + edgeThickness)
      && clientY > (top + edgeThickness)
      && clientY < (top + height - edgeThickness)
    ) {
      return false;
    }

    return true;
  }

  handleElementDragOver(cursor) {
    const editor = this.getEditor();
    if (!editor.draggedNode) return;
    editor.indicator.setIsForbidden(!editor.draggedNode.canBeSibling(this.getCurrentNode()));
    if (this.onTopHalf(cursor)) {
      editor.indicator.pointBefore(this.getElement());
    } else {
      editor.indicator.pointAfter(this.getElement());
    }
  }

  handleCanvasDragOver(cursor) {
    const editor = this.getEditor();
    if (!editor.draggedNode) return;
    const { clientX, clientY } = cursor;
    if (this.onEdge({ clientX, clientY })) {
      this.handleElementDragOver(cursor);
      return;
    }
    editor.indicator.setIsForbidden(!this.getCurrentNode().isDroppable(editor.draggedNode));
    if (this.onTopHalf(cursor)) {
      editor.indicator.pointInsideTop(this.getElement());
    } else {
      editor.indicator.pointInside(this.getElement());
    }
  }

  handleDragOver(cursor) {
    if (!this.isScroll) {
      this.isScroll = true
      const _this = this
      var mouseY = cursor.clientY;
      var windowHeight = this.iframe.contentWindow.innerHeight;
      var wrapperContainer = this.iframe.contentDocument.querySelector('[data-label="Wrapper Container"]');
      if (mouseY >= windowHeight - 100) {
        wrapperContainer.scrollBy(0, 350);
        setTimeout(() => {
          _this.isScroll = false
        }, 300)
        return
      }
      else if (mouseY <= 100) {
        wrapperContainer.scrollBy(0, -350);
        setTimeout(() => {
          _this.isScroll = false
        }, 300)
        return
      }
      else {
        _this.isScroll = false
      }
    }
    if (this.getCurrentNode()?.isCanvas()) {
      this.handleCanvasDragOver(cursor);
    } else {
      this.handleElementDragOver(cursor);
    }




  }

  handleElementDrop(cursor) {
    const currentNode = this.getCurrentNode();
    const { draggedNode } = this.getEditor();
    if (!draggedNode) return;
    if (!draggedNode?.canBeSibling(currentNode)) {
      return;
    }
    if (this.onTopHalf(cursor)) {
      draggedNode.insertBefore(currentNode);
    } else {
      draggedNode.insertAfter(currentNode);
    }
  }


  handleCanvasDrop(cursor) {
    const currentNode = this.getCurrentNode();
    const { draggedNode } = this.getEditor();
    if (!draggedNode) return;
    if (this.onEdge(cursor)) {
      if (this.onTopHalf(cursor)) {
        draggedNode.insertBefore(currentNode);
      } else {
        draggedNode.insertAfter(currentNode);
      }
      return;
    }

    if (!currentNode.isDroppable(draggedNode)) {
      return;
    }
    if (this.onTopHalf(cursor)) {
      currentNode.prepend(draggedNode);
    } else {
      currentNode.append(draggedNode);
    }
  }

  handleDrop(cursor) {
    const currentNode = this.getCurrentNode();
    const editor = this.getEditor();
    if (!editor.draggedNode) return;
    
    if (!this.checkAcceptDropModuleCheckout(currentNode)) {
      return;
    }
    if (!this.checkAcceptDropModuleProduct(cursor)) {
      return;
    }
    

    if (currentNode.isCanvas()) {
      this.handleCanvasDrop(cursor);
    } else {
      this.handleElementDrop(cursor);
    }
    // console.log(editor.draggedNode, 'draggedNode')
    // console.log(currentNode, 'currentNode')
    if (editor.draggedNode.componentName == 'Row' && currentNode.parent?.props?.component == 'WrapperContainer') {
      const wrapperNode = currentNode.parent;
      const rowNodes = wrapperNode.children.filter(e => e.componentName == 'Row' || e.props?.component == 'Row')
      for (let i = 0; i < rowNodes.length; i++) {
        rowNodes[i].setProps({name: `Row ${i + 1}`})
      }
    }
    if (editor.draggedNode.componentName == 'Row' && currentNode.props?.component == 'WrapperContainer') {
      const rowNodes = currentNode.children.filter(e => e.componentName == 'Row' || e.props?.component == 'Row')
      for (let i = 0; i < rowNodes.length; i++) {
        rowNodes[i].setProps({name: `Row ${i + 1}`})
      }
    }
    editor.dragNode(null);
    editor.indicator.hide();
  }

  checkAccessCollectionPage() {
    const editor = this.getEditor();
    if (!editor.draggedNode) return;
    const { draggedNode } = this.getEditor();
    let urlParams = new URLSearchParams(window.location.search);
    const type = urlParams.get("type");
    if (["Paginator",
      "CollectionToolbar",
      "CollectionTitle",
      "CollectionDescription",
      'CollectionBanner'].includes(draggedNode?.componentName)
      && type != 'collectionpages'
    ) {
      this.vm.$toastr.e(`ERROR DROP: ${draggedNode?.props?.component || draggedNode?.componentName} can only be used inside a Collection Page`)
      editor.dragNode(null);
      editor.indicator.hide();
      return false;
    }
    return true;
  }

  getAncestor(node, result) {
    if (!result) {
      result = [];
    }
    if (node.parent) {
      result.push(node.parent.componentName)
      return this.getAncestor(node.parent, result)
    } else {
      return result
    }
  }

  getChildren(node) {
    let result = []
    for (let child of node.children) {
      result.push(child.componentName)
      
      if (child.children) {
        result = result.concat(this.getChildren(child));
      }
    }
    return result
  }

  checkAcceptDropModuleCheckout(currentNode) {
    const ancestors = this.getAncestor(currentNode)
    const editor = this.getEditor();
    if (ancestors.includes('ShippingAddress') || ancestors.includes('BillingAddress') || ancestors.includes('Payment')) {
      this.vm.$toastr.e("ERROR DROP: Cannot drop element to Checkout sections.")
      editor.dragNode(null);
      editor.indicator.hide();
      return false;
    }
    return true
  }

  checkAcceptDropModuleProduct(cursor) {
    const currentNode = this.getCurrentNode();
    var $iframe = $("#designEditor").contents();
    var currenJquery = $iframe.find(`#element-${currentNode.uuid}`);
    
    const editor = this.getEditor();
    if (!editor.draggedNode) return;
    const { draggedNode } = this.getEditor();
    if (draggedNode?.uuid == currentNode?.uuid) {
      editor.dragNode(null);
      editor.indicator.hide();
      return false;
    }
    if (draggedNode.componentName == "FloatingButton") {
      editor.dragNode(null);
      editor.indicator.hide();
      editor.popups.push(draggedNode)
      return false;
    }
    else if (draggedNode.componentName == "Popup") {
      for (var i = 0; i < editor.popups.length; i++) {
        const nodePop = editor.popups[i];
        nodePop.setProps({ showPopup: false });
      }
      editor.dragNode(null);
      editor.indicator.hide();
      editor.popups.push(draggedNode)
      return false;
    }
    // else if (draggedNode.props.section == 'module' && currentNode.props.section == "module") {
    //   this.vm.$toastr.e(`ERROR DROP: Cannot put a ${draggedNode.componentName} inside a ${currentNode?.componentName}`)
    //   editor.dragNode(null);
    //   editor.indicator.hide();
    //   return false;
    // }
    // else if (draggedNode?.props?.parent == "Article" && (currentNode?.componentName != "Article" && currentNode?.parent?.componentName != "Article")) {
    //   this.vm.$toastr.e("ERROR DROP: This field is dropped to Article Module only")
    //   editor.dragNode(null);
    //   editor.indicator.hide();
    //   return false;
    // }
    else if (draggedNode.componentName == 'Product' && this.getAncestor(currentNode).includes('Product')) {
      this.vm.$toastr.e("ERROR DROP: Product Module cannot contain another Product.")
      editor.dragNode(null);
      editor.indicator.hide();
      return false;
    }
    else if (['ProductList', 'RelatedProduct'].includes(draggedNode.componentName) && this.getAncestor(currentNode).includes('Product')) {
      this.vm.$toastr.e(`ERROR DROP: Cannot put a ${draggedNode.componentName} inside a Product.`)
      editor.dragNode(null);
      editor.indicator.hide();
      return false;
    }
    else if (['ProductList', 'RelatedProduct'].includes(draggedNode.componentName) && _.intersection(['ProductList', 'RelatedProduct'], this.getAncestor(currentNode)).length > 0) {
      this.vm.$toastr.e(`ERROR DROP: Cannot put a ${draggedNode.componentName} inside a ${currentNode.componentName}.`)
      editor.dragNode(null);
      editor.indicator.hide();
      return false;
    }
    else if (draggedNode?.props?.parent == "Article" && !this.getAncestor(currentNode).includes('Article')) {
      this.vm.$toastr.e("ERROR DROP: This field is dropped to Article Module only")
      editor.dragNode(null);
      editor.indicator.hide();
      return false;
    }
    else if (this.getChildren(draggedNode).includes('Product') && this.getAncestor(currentNode).includes('Product')) {
      this.vm.$toastr.e("ERROR DROP: Product Module cannot contain another Product.")
      editor.dragNode(null);
      editor.indicator.hide();
      return false;
    }
    else if (this.getChildren(draggedNode).includes('ProductList') && this.getAncestor(currentNode).includes('Product')) {
      this.vm.$toastr.e("ERROR DROP: Product Module cannot contain Product List.")
      editor.dragNode(null);
      editor.indicator.hide();
      return false;
    }
    else if (this.getChildren(draggedNode).includes('RelatedProduct') && this.getAncestor(currentNode).includes('Product')) {
      this.vm.$toastr.e("ERROR DROP: Product Module cannot contain Related Product.")
      editor.dragNode(null);
      editor.indicator.hide();
      return false;
    }
    // else if (draggedNode?.props?.parent == "Product" && currenJquery.closest('[data-label="Product"]').length<=0) {
    //   this.vm.$toastr.e("ERROR DROP: This field is dropped to Product Module only")
    //   editor.dragNode(null);
    //   editor.indicator.hide();
    //   return false;
    // }
    // currenJquery.closest('[data-label="Product"]')
    else if (draggedNode?.props?.parent == "Product" && !this.getAncestor(currentNode).includes('Product')) {
      this.vm.$toastr.e("ERROR DROP: This field is dropped to Product Module only")
      editor.dragNode(null);
      editor.indicator.hide();
      return false;
    }
    else if (["Product", "ArticleList", "ProductList", "RelatedProduct"].includes(draggedNode?.componentName) && (["Product", "ArticleList", "Article", "ProductList", "RelatedProduct"].includes(currentNode?.componentName) || ["Product", "ArticleList", "Article", "ProductList"].includes(currentNode?.parent?.componentName))) {
      this.vm.$toastr.e(`ERROR DROP: ${draggedNode?.componentName} cannot contain other Module`)
      editor.dragNode(null);
      editor.indicator.hide();
      return false;
    } else {
      var draggedProduct = this.checkChildProductList(draggedNode)
      var currentProduct = this.checkChildProductList(currentNode)
      var onTop = this.onTopHalf(cursor);
      let nodes = editor.export()
      let popups = editor.exportPopup()
      let inversePatches = { nodes, popups }
      var checkDrop = false
      if (draggedProduct && currentProduct) {
        var draggedList = draggedProduct.parent
        var currentList = currentProduct.parent
        if (draggedProduct?.parent?.uuid != currentProduct?.parent?.uuid) {
          if (currentNode.isCanvas()) {
            checkDrop = this.handleCanvasDropProductList(currentNode, draggedNode, cursor);
          } else {
            checkDrop = this.handleElementDropProductList(currentNode, draggedNode, cursor);
          }
          if (!checkDrop) {
            editor.dragNode(null);
            editor.indicator.hide();
            return false
          }
          for (var i = 0; i < currentList.children.length; i++) {
            const product = currentList.children[i]
            if (product.uuid == currentProduct.uuid) continue;
            const node2 = currentProduct.duplicate()
            product.children = node2.children;
          }
          for (var i = 0; i < draggedList.children.length; i++) {
            const product = draggedList.children[i]
            if (product.uuid == draggedProduct.uuid) continue;
            const node2 = draggedProduct.duplicate()
            product.children = node2.children;
          }
        } else {
          var draggedPossition = draggedProduct.children.findIndex((x) => x.uuid == draggedNode.uuid);
          var currentPossition = currentProduct.children.findIndex((x) => x.uuid == currentNode.uuid);
          if (draggedPossition == currentPossition && draggedPossition != -1) {
            editor.dragNode(null);
            editor.indicator.hide();
            return false
          } else if (draggedPossition != -1 && currentPossition != -1) {
            if (draggedProduct.uuid == currentProduct.uuid) {
              if (currentNode.isCanvas()) {
                checkDrop = this.handleCanvasDropProductList(currentNode, draggedNode, cursor);
              } else {
                checkDrop = this.handleElementDropProductList(currentNode, draggedNode, cursor);
              }
              if (!checkDrop) {
                editor.dragNode(null);
                editor.indicator.hide();
                return false
              }
              for (var i = 0; i < currentList.children.length; i++) {
                const product = currentList.children[i]
                if (product.uuid == currentProduct.uuid) continue;
                const node2 = currentProduct.duplicate()
                product.children = node2.children;
              }

            } else {
              for (var i = 0; i < draggedList.children.length; i++) {
                const product = draggedList.children[i]
                const node1 = product.children[draggedPossition]
                const node2 = product.children[currentPossition]
                this.handleElementDropProductList(node2, node1, cursor);
              }
            }

          } else if (draggedPossition != -1) {
            const node1 = currentProduct.children[draggedPossition]
            var draggedList = draggedProduct.parent
            if (currentNode.isCanvas()) {
              checkDrop = this.handleCanvasDropProductList(currentNode, node1, cursor);
            } else {
              checkDrop = this.handleElementDropProductList(currentNode, node1, cursor);
            }
            if (!checkDrop) {
              editor.dragNode(null);
              editor.indicator.hide();
              return false
            }

            for (var i = 0; i < currentList.children.length; i++) {
              const product = currentList.children[i]
              if (product.uuid == currentProduct.uuid) continue;
              const node2 = currentProduct.duplicate()
              product.children = node2.children;
            }
          } else {
            var draggedList = draggedProduct.parent
            if (currentNode.isCanvas()) {
              checkDrop = this.handleCanvasDropProductList(currentNode, draggedNode, cursor);
            } else {
              checkDrop = this.handleElementDropProductList(currentNode, draggedNode, cursor);
            }
            if (!checkDrop) {
              editor.dragNode(null);
              editor.indicator.hide();
              return false
            }
            for (var i = 0; i < currentList.children.length; i++) {
              const product = currentList.children[i]
              if (product.uuid == currentProduct.uuid) continue;
              const node2 = currentProduct.duplicate()
              product.children = node2.children;
            }
          }
        }
        let patches = { nodes: editor.export(), popups: editor.exportPopup() };
        editor.add(patches, inversePatches)
        editor.dragNode(null);
        editor.indicator.hide();
        return false
      } else if (draggedProduct) {
        var draggedList = draggedProduct.parent
        if (currentNode.isCanvas()) {
          checkDrop = this.handleCanvasDropProductList(currentNode, draggedNode, cursor);
        } else {
          checkDrop = this.handleElementDropProductList(currentNode, draggedNode, cursor);
        }
        if (!checkDrop) {
          editor.dragNode(null);
          editor.indicator.hide();
          return false
        }
        for (var i = 0; i < draggedList.children.length; i++) {
          const product = draggedList.children[i]
          if (product.uuid == draggedProduct.uuid) continue;
          const node2 = draggedProduct.duplicate()
          product.children = node2.children;
        }
        let patches = { nodes: editor.export(), popups: editor.exportPopup() };
        editor.add(patches, inversePatches)
        editor.dragNode(null);
        editor.indicator.hide();
        return false
      } else if (currentProduct) {
        var currentList = currentProduct.parent
        if (currentNode.isCanvas()) {
          checkDrop = this.handleCanvasDropProductList(currentNode, draggedNode, cursor);
        } else {
          checkDrop = this.handleElementDropProductList(currentNode, draggedNode, cursor);
        }
        if (!checkDrop) {
          editor.dragNode(null);
          editor.indicator.hide();
          return false
        }

        for (var i = 0; i < currentList.children.length; i++) {
          const product = currentList.children[i]
          if (product.uuid == currentProduct.uuid) continue;
          const node2 = currentProduct.duplicate()
          product.children = node2.children;
        }
        let patches = { nodes: editor.export(), popups: editor.exportPopup() };
        editor.add(patches, inversePatches)
        editor.dragNode(null);
        editor.indicator.hide();
        return false
      }
      // if (['RelatedProduct', 'ProductList'].includes(draggedNode?.parent?.parent?.componentName)
      //   && ['RelatedProduct', 'ProductList'].includes(currentNode?.parent?.parent?.componentName)
      // ) {
      //   var onTop = this.onTopHalf(cursor);
      //   let nodes = editor.export()
      //   let popups = editor.exportPopup()
      //   let inversePatches = { nodes, popups }
      //   var draggedPossition = draggedNode.parent.children.findIndex((x) => x.uuid == draggedNode.uuid);
      //   var currentPossition = currentNode.parent.children.findIndex((x) => x.uuid == currentNode.uuid);
      //   if (draggedNode?.parent?.parent?.uuid != currentNode?.parent?.parent?.uuid) {
      //     for (var i = 0; i < draggedNode?.parent?.parent.children.length; i++) {
      //       const product = draggedNode?.parent?.parent.children[i]
      //       if (product.uuid == draggedNode.parent.uuid) continue;
      //       const node2 = editor.findNode(product.children[draggedPossition].uuid)
      //       node2?.makeOrphan()
      //     }

      //     for (var i = 0; i < currentNode?.parent?.parent.children.length; i++) {
      //       const product = currentNode?.parent?.parent.children[i]
      //       if (product.uuid == currentNode.parent.uuid) continue;
      //       const node2 = product.children[currentPossition]
      //       const node1 = draggedNode.duplicate()
      //       node1.parent = null;
      //       if (onTop) {
      //         node1.insertBefore(node2, true)
      //       } else {
      //         node1.insertAfter(node2, true)
      //       }
      //     }
      //   } else {
      //     if (draggedPossition == currentPossition) {
      //       editor.dragNode(null);
      //       editor.indicator.hide();
      //       return false
      //     }
      //     for (var i = 0; i < draggedNode?.parent?.parent.children.length; i++) {
      //       const product = draggedNode?.parent?.parent.children[i]
      //       const node1 = product.children[draggedPossition]
      //       const node2 = product.children[currentPossition]
      //       if (onTop) {
      //         node1.insertBefore(node2, true)
      //       } else {
      //         node1.insertAfter(node2, true)
      //       }
      //     }
      //   }
      //   let patches = { nodes: editor.export(), popups: editor.exportPopup() };
      //   editor.add(patches, inversePatches)
      //   editor.dragNode(null);
      //   editor.indicator.hide();
      //   return false
      // } else if (['RelatedProduct', 'ProductList'].includes(currentNode?.parent?.parent?.componentName)) {
      //   var currentPossition = currentNode.parent.children.findIndex((x) => x.uuid == currentNode.uuid);
      //   var onTop = this.onTopHalf(cursor);
      //   let nodes = editor.export()
      //   let popups = editor.exportPopup()
      //   let inversePatches = { nodes, popups }

      //   if (onTop) {
      //     draggedNode.insertBefore(currentNode, true)
      //   } else {
      //     draggedNode.insertAfter(currentNode, true)
      //   }

      //   for (var i = 0; i < currentNode?.parent?.parent.children.length; i++) {
      //     const product = currentNode?.parent?.parent.children[i]
      //     if (product.uuid == currentNode.parent.uuid) continue;
      //     const node1 = draggedNode.duplicate()
      //     node1.parent = null;
      //     const node2 = product.children[currentPossition]
      //     if (onTop) {
      //       node1.insertBefore(node2, true)
      //     } else {
      //       node1.insertAfter(node2, true)
      //     }
      //   }
      //   let patches = { nodes: editor.export(), popups: editor.exportPopup() };
      //   editor.add(patches, inversePatches)
      //   editor.dragNode(null);
      //   editor.indicator.hide();
      //   return false
      // } else if (['RelatedProduct', 'ProductList'].includes(draggedNode?.parent?.parent?.componentName)) {
      //   var draggedPossition = draggedNode.parent.children.findIndex((x) => x.uuid == draggedNode.uuid);
      //   var onTop = this.onTopHalf(cursor);
      //   let nodes = editor.export()
      //   let popups = editor.exportPopup()
      //   let inversePatches = { nodes, popups }
      //   for (var i = 0; i < draggedNode?.parent?.parent.children.length; i++) {
      //     const product = draggedNode?.parent?.parent.children[i]
      //     if (product.uuid == draggedNode.parent.uuid) continue;
      //     const node2 = editor.findNode(product.children[draggedPossition].uuid)
      //     node2?.makeOrphan()
      //   }
      //   if (onTop) {
      //     draggedNode.insertBefore(currentNode, true)
      //   } else {
      //     draggedNode.insertAfter(currentNode, true)
      //   }
      //   let patches = { nodes: editor.export(), popups: editor.exportPopup() };
      //   editor.add(patches, inversePatches)
      //   editor.dragNode(null);
      //   editor.indicator.hide();
      //   return false
      // }
    }

    return true;
  }

  handleCanvasDropProductList(currentNode, draggedNode, cursor) {
    if (!draggedNode) return false;
    if (this.onEdge(cursor)) {
      if (this.onTopHalf(cursor)) {
        draggedNode.insertBefore(currentNode, true);
      } else {
        draggedNode.insertAfter(currentNode, true);
      }
    }
    if (!currentNode.isDroppable(draggedNode)) {
      return false;
    }
    if (this.onTopHalf(cursor)) {
      currentNode.prepend(draggedNode, true);
    } else {
      currentNode.append(draggedNode, true);
    }
    return true
  }

  handleElementDropProductList(currentNode, draggedNode, cursor) {
    if (!draggedNode) return false;
    if (!draggedNode?.canBeSibling(currentNode)) {
      return false;
    }
    if (this.onTopHalf(cursor)) {
      draggedNode.insertBefore(currentNode, true);
    } else {
      draggedNode.insertAfter(currentNode, true);
    }
    return true
  }

  // handleDropProduct(cursor, draggedNode, currentNode) {
  //   let user = JSON.parse(Cookies.get('user_onepage') || "{}")
  //   request.get(`https://${user?.store?.domain || user?.store?.sub_domain}/api/v2/get_random_products`, { limit: 1 }).then((res) => {
  //     if (res.status == 200) {
  //       const product = res.data.data.length > 0 ? res.data.data[0] : null;
  //       if (product) {
  //         draggedNode.setProps({
  //           product_id: product.product_id,
  //           product_name: product.title
  //         })
  //       }
  //       if (this.onEdge(cursor)) {
  //         if (this.onTopHalf(cursor)) {
  //           draggedNode.insertBefore(currentNode);
  //         } else {
  //           draggedNode.insertAfter(currentNode);
  //         }
  //         return;
  //       }

  //       if (!currentNode.isDroppable(draggedNode)) {
  //         return;
  //       }
  //       if (this.onTopHalf(cursor)) {
  //         currentNode.prepend(draggedNode);
  //       } else {
  //         currentNode.append(draggedNode);
  //       }
  //     }
  //   }, err => console.log(err))
  // }

  // handleDropProductList(cursor, draggedNode, currentNode) {
  //   let user = JSON.parse(Cookies.get('user_onepage') || "{}")
  //   request.get(`https://${user?.store?.domain || user?.store?.sub_domain}/api/v2/last_collection`).then((res) => {
  //     if (res.status == 200) {
  //       const collection = res.data.data.collection;
  //       if (collection) {
  //         draggedNode.setProps({
  //           collection_id: collection.collection_id,
  //           collection_name: collection.title
  //         })
  //       }
  //       if (this.onEdge(cursor)) {
  //         if (this.onTopHalf(cursor)) {
  //           draggedNode.insertBefore(currentNode);
  //         } else {
  //           draggedNode.insertAfter(currentNode);
  //         }
  //         return;
  //       }

  //       if (!currentNode.isDroppable(draggedNode)) {
  //         return;
  //       }
  //       if (this.onTopHalf(cursor)) {
  //         currentNode.prepend(draggedNode);
  //       } else {
  //         currentNode.append(draggedNode);
  //       }
  //     }
  //   }, err => console.log(err))
  // }

  checkChildProductList(node) {
    const parent = node.parent
    if (parent) {
      if (["ProductList", "RelatedProduct"].includes(parent.componentName)) {
        return node
      } else {
        return this.checkChildProductList(parent)
      }
    } else {
      return null
    }
  }

}

export default NodeService;
