<template>
  <el-form label-position="top" size="mini" :model="elementProps">
    <div class="banner-description py-2">
      <div>
        <span class="highlight">Notice: </span>
        <span>In Preview Mode, dummy information will be displayed in the editor. Required for this module.</span>
      </div>
    </div>

    <el-form-item label="Page Quantity">
      <el-input
        :value="elementProps.page_quantity"
        @input="updateNumberProps('page_quantity', $event)"
        size="medium"
      />
    </el-form-item>
  </el-form>
</template>
  
  <script>
import mixin from "../../../elements/styleSettings/mixin";
import Node from "@/core/Node";

export default {
  mixins: [mixin],
  components: {

  },
  props: {
    node: Node,
    screen: String,
    primaryColor: String,
  },
  data() {
    return {};
  },
  watch: {},
  methods: {
    updateNumberProps(name, value) {
      const val = (isNaN(value) && (value!='-')) ? (value.length > 0 ? 0 : "") : value;
      this.elementPropsSetter({ [name]: val });
    },
    updateProps(name, value) {
      if (value == this.elementProps[name]) return;
      this.elementPropsSetter({ [name]: value });
    },
  },
};
</script>
  
  <style scoped>
.banner-description {
  font-size: 12px;
  font-style: italic;
  color: #d6d6d6;
  display: flex;
  flex-direction: column;
  padding: 8px 0px;
}
.highlight {
  color: #fdb913;
}
</style>