<template>
  <div v-if="this.indicator.show" class="indicator" :class="{forbidden: this.indicator.isForbidden}"
    :style="getIndicatorStyle()" />
</template>

<script>
import Indicator from '../core/Indicator';

export default {
  props: {
    indicator: Indicator,
  },
  methods: {
    getIndicatorStyle() {
      const {top,left}=this.indicator.position;
      const {width,height}=this.indicator.size;

      const style={
        top: `${top}px`,
        left: `${left}px`,
        height: `${height}px`,
        width: `${width}px`,
      };

      return style;
    },
  },
};
</script>

<style lang="scss" scoped>
.indicator {
  position: fixed;
  background: repeating-linear-gradient(-45deg, #ccc, #ccc 1px, #f3ebb8 2px, #f3ebb8 5px);
  transition: all 0.3s;
  z-index: 1000;

  &.forbidden {
    display: none;
    // background-color: rgb(255, 24, 93);
  }
}
</style>
