<template>
  <div label-position="top" size="mini" :model="elementProps">
    <div class="d-flex align-items-center justify-content-between py-2">
      <span class="sgSubTitle">The email field is mandatory. This feature eliminates the need to enter email to reduce time and efficiency.</span>
      <el-switch
        :value="elementProps.hide_on_client"
        :active-color="primaryColor"
        inactive-color="#949494"
        @change="updateProps('hide_on_client', $event)"
      />
    </div>
    <div class="d-flex flex-column py-2">
      <span class="setting-title">Currency</span>
      <el-input
        class="mt-2"
        :value="elementProps.default_value"
        @input="($event) => updateProps('default_value', $event)"
        size="medium"
      />
    </div>
  </div>
</template>
<script>
import mixin from "../styleSettings/mixin";

export default {
  mixins: [mixin],
  computed: {},
  components: {},
  props: {
    primaryColor: String,
  },
  methods: {
    updateProps(name, value) {
      if(value ==  this.elementProps[name]) return;
      this.elementPropsSetter({ [name]: value });
    },
  },
};
</script>

<style scoped>
</style>
