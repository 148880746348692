<template>
  <div class="sgDesc">
    Liquid module allows us to add custom code into your page. Right click on it, and then choose Edit Code to start writing your own custom HTML, CSS, Javascript code.
  </div>
</template>

<script>
import mixin from "../../../elements/styleSettings/mixin";

export default {
  mixins: [mixin],
  components: {
  },
  methods: {
  },
  props: {
    primaryColor: String,
    screen: String,
  }
};
</script>
