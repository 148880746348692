<template>
  <div
    data-label="Collection Title"
    data-key="collection-title"
    class="sg-wrap module"
    :id="`element-${node.uuid}`"
    @mouseover="isHovering=true" @mouseout="isHovering=false"
    :class="{ 'dragging': dragging == node.uuid }"
    style="color: #fff"
  >
    <div
      :class="`sg-module sg_module-${desktop.alignment} sg_module-${desktop.alignment}-lg sg_module-${getValue('alignment','laptop')}-md sg_module-${getValue('alignment','tablet')}-sm sg_module-${getValue('alignment','mobile')}-xs`"
    >
          <div class="sg_collection-title-wrapper">
              <div class="sg_collection-banner-title">
                  <h1 class="sg_gs-text-heading-2">Collection title</h1></div>
          </div>
    </div>

    <ToolBar ref="(c)title" 
      :toolbar_id="`(c)title`"
      class="container-toolbar" 
      :class="{ 'container-toolbar-show': isHovering, 'is-dragging': dragging == node.uuid }"
      >
      </ToolBar>
      <div v-if="dragging == node.uuid" class="dragging"></div>

    <v-style>
      {{ custom_css }}
      #element-{{ node.uuid }} .sg_collection-banner-title {
          font-size: 48px;
          line-height: 80px;
          letter-spacing: 1px;
          color: inherit;
      }

      @media (max-width: 767px){ 
          #element-{{ node.uuid }} .sg_collection-banner-title h1{
              font-size: {{ getValue('font_size','mobile') }}px;
              font-weight: {{ getValue('font_style','mobile')?.includes('bold')?'bold':getValue('font_weight','mobile') }};
              line-height: {{ getValue('line_height','mobile') }}em;
              color: {{ getValue('color','mobile') }};
              letter-spacing: {{ getValue('letter_spacing','mobile') }}px;
              font-family: {{ getValue('font_family','mobile') }};
              text-transform: {{ getValue('text_transform','mobile') }};
              font-style: {{getValue('font_style','mobile')?.includes('italic')?'italic':''}}!important;
              text-decoration: {{getValue('font_style','mobile')?.includes('underline')?'underline':''}}!important;
          }
        #element-{{ node.uuid }}  {
          display: {{ mobile.display == 'none' ? 'none' : 'block' }} !important;
          margin: {{ getValue('margin_top','mobile') }}px {{ getValue('margin_right','mobile') }}px {{ getValue('margin_bottom','mobile') }}px {{ getValue('margin_left','mobile') }}px;
          padding-top: {{`${getValue('padding_top','mobile')}px`}};
          padding-right: {{`${getValue('padding_right','mobile')}px`}};
          padding-bottom: {{`${getValue('padding_bottom','mobile')}px`}};
          padding-left: {{`${getValue('padding_left','mobile')}px`}};
          {{ getValue('background_type','mobile') == 'color' && getValue('background_color','mobile') ? `background-color: ${getValue('background_color','mobile')};` : ''}}
          {{ getValue('background_type','mobile') == 'image' && getValue('background_image','mobile') ? `background-image: url(${getValue('background_image','mobile')});` : ''}}
          {{ getValue('background_type','mobile') == 'image' && getValue('background_size','mobile') ? `background-size: ${getValue('background_size','mobile')};` : ''}}
          {{ getValue('background_type','mobile') == 'image' && getValue('background_position','mobile') ? `background-position: ${getValue('background_position','mobile')};` : ''}}
          {{ getValue('background_type','mobile') == 'image' && getValue('background_repeat','mobile') ? `background-repeat: ${getValue('background_repeat','mobile')};` : ''}}
          {{ getValue('background_type','mobile') == 'image' && getValue('background_attachment','mobile') ? `background-attachment: ${getValue('background_attachment','mobile')};` : ''}}
          {{ getValue('border_style','mobile') ? `border-style: ${getValue('border_style','mobile')};` : '' }}
          {{ getValue('border_color','mobile') ? `border-color: ${getValue('border_color','mobile')};` : '' }}
          {{ getValue('border_width','mobile') ? `border-width: ${getValue('border_width','mobile')}px;` : '' }}
          {{ getValue('border_top_left_radius','mobile') ? `border-top-left-radius: ${getValue('border_top_left_radius','mobile')}px;` : '' }}
          {{ getValue('border_top_right_radius','mobile') ? `border-top-right-radius: ${getValue('border_top_right_radius','mobile')}px;` : '' }}
          {{ getValue('border_bottom_left_radius','mobile') ? `border-bottom-left-radius: ${getValue('border_bottom_left_radius','mobile')}px;` : '' }}
          {{ getValue('border_bottom_right_radius','mobile') ? `border-bottom-right-radius: ${getValue('border_bottom_right_radius','mobile')}px;` : '' }}
          {{ getValue('opacity','mobile') ? `opacity: ${getValue('opacity','mobile')};` : '' }}
          {{ getValue('shadow_style','mobile') == 'drop' ? `filter: drop-shadow(${getValue('shadow_x','mobile') || 0}px ${getValue('shadow_y','mobile') || 0}px ${getValue('shadow_blur','mobile') || 0}px ${getValue('shadow_color','mobile') || 'rgb(255, 255, 255)'});` : ''}}
          {{ getValue('shadow_style','mobile') == 'box' ? `box-shadow: ${getValue('shadow_type','mobile') == 'inset' ? 'inset' : ''} ${getValue('shadow_x','mobile') || 0}px ${getValue('shadow_y','mobile') || 0}px ${getValue('shadow_spread','mobile') || 0}px ${getValue('shadow_blur','mobile') || 0}px ${getValue('shadow_color','mobile') || 'rgb(255, 255, 255)'};` : ''}}
        }
      } 
      @media (max-width: 991px) and (min-width: 768px){ 
          #element-{{ node.uuid }} .sg_collection-banner-title h1{
              font-size: {{ getValue('font_size','tablet') }}px;
              font-weight: {{ getValue('font_style','tablet')?.includes('bold')?'bold':getValue('font_weight','tablet') }};
              line-height: {{ getValue('line_height','tablet') }}em;
              color: {{ getValue('color','tablet') }};
              letter-spacing: {{ getValue('letter_spacing','tablet') }}px;
              font-family: {{ getValue('font_family','tablet') }};
              text-transform: {{ getValue('text_transform','tablet') }};
              font-style: {{getValue('font_style','tablet')?.includes('italic')?'italic':''}}!important;
              text-decoration: {{getValue('font_style','tablet')?.includes('underline')?'underline':''}}!important;
          }
        #element-{{ node.uuid }}  {
          
          display: {{ tablet.display == 'none' ? 'none' : 'block' }} !important;
          margin: {{ getValue('margin_top','tablet') }}px {{ getValue('margin_right','tablet') }}px {{ getValue('margin_bottom','tablet') }}px {{ getValue('margin_left','tablet') }}px;
          padding-top: {{`${getValue('padding_top','tablet')}px`}};
          padding-right: {{`${getValue('padding_right','tablet')}px`}};
          padding-bottom: {{`${getValue('padding_bottom','tablet')}px`}};
          padding-left: {{`${getValue('padding_left','tablet')}px`}};
          {{ getValue('background_type','tablet') == 'color' && getValue('background_color','tablet') ? `background-color: ${getValue('background_color','tablet')};` : ''}}
          {{ getValue('background_type','tablet') == 'image' && getValue('background_image','tablet') ? `background-image: url(${getValue('background_image','tablet')});` : ''}}
          {{ getValue('background_type','tablet') == 'image' && getValue('background_size','tablet') ? `background-size: ${getValue('background_size','tablet')};` : ''}}
          {{ getValue('background_type','tablet') == 'image' && getValue('background_position','tablet') ? `background-position: ${getValue('background_position','tablet')};` : ''}}
          {{ getValue('background_type','tablet') == 'image' && getValue('background_repeat','tablet') ? `background-repeat: ${getValue('background_repeat','tablet')};` : ''}}
          {{ getValue('background_type','tablet') == 'image' && getValue('background_attachment','tablet') ? `background-attachment: ${getValue('background_attachment','tablet')};` : ''}}
          {{ getValue('border_style','tablet') ? `border-style: ${getValue('border_style','tablet')};` : '' }}
          {{ getValue('border_color','tablet') ? `border-color: ${getValue('border_color','tablet')};` : '' }}
          {{ getValue('border_width','tablet') ? `border-width: ${getValue('border_width','tablet')}px;` : '' }}
          {{ getValue('border_top_left_radius','tablet') ? `border-top-left-radius: ${getValue('border_top_left_radius','tablet')}px;` : '' }}
          {{ getValue('border_top_right_radius','tablet') ? `border-top-right-radius: ${getValue('border_top_right_radius','tablet')}px;` : '' }}
          {{ getValue('border_bottom_left_radius','tablet') ? `border-bottom-left-radius: ${getValue('border_bottom_left_radius','tablet')}px;` : '' }}
          {{ getValue('border_bottom_right_radius','tablet') ? `border-bottom-right-radius: ${getValue('border_bottom_right_radius','tablet')}px;` : '' }}
          {{ getValue('opacity','tablet') ? `opacity: ${getValue('opacity','tablet')};` : '' }}
          {{ getValue('shadow_style','tablet') == 'drop' ? `filter: drop-shadow(${getValue('shadow_x','tablet') || 0}px ${getValue('shadow_y','tablet') || 0}px ${getValue('shadow_blur','tablet') || 0}px ${getValue('shadow_color','tablet') || 'rgb(255, 255, 255)'});` : ''}}
          {{ getValue('shadow_style','tablet') == 'box' ? `box-shadow: ${getValue('shadow_type','tablet') == 'inset' ? 'inset' : ''} ${getValue('shadow_x','tablet') || 0}px ${getValue('shadow_y','tablet') || 0}px ${getValue('shadow_spread','tablet') || 0}px ${getValue('shadow_blur','tablet') || 0}px ${getValue('shadow_color','tablet') || 'rgb(255, 255, 255)'};` : ''}}
        }
      } 
      @media (max-width: 1199px) and (min-width: 992px){ 

          #element-{{ node.uuid }} .sg_collection-banner-title h1{
              font-size: {{ getValue('font_size','laptop') }}px;
              font-weight: {{ getValue('font_style','laptop')?.includes('bold')?'bold':getValue('font_weight','laptop') }};
              line-height: {{ getValue('line_height','laptop') }}em;
              color: {{ getValue('color','laptop') }};
              letter-spacing: {{ getValue('letter_spacing','laptop') }}px;
              font-family: {{ getValue('font_family','laptop') }};
              text-transform: {{ getValue('text_transform','laptop') }};
              font-style: {{getValue('font_style','laptop')?.includes('italic')?'italic':''}}!important;
              text-decoration: {{getValue('font_style','laptop')?.includes('underline')?'underline':''}}!important;
          }
        #element-{{ node.uuid }}  {
          
          display: {{ laptop.display == 'none' ? 'none' : 'block' }} !important;
          margin: {{ getValue('margin_top','laptop') }}px {{ getValue('margin_right','laptop') }}px {{ getValue('margin_bottom','laptop') }}px {{ getValue('margin_left','laptop') }}px;
          padding-top: {{`${getValue('padding_top','laptop')}px`}};
          padding-right: {{`${getValue('padding_right','laptop')}px`}};
          padding-bottom: {{`${getValue('padding_bottom','laptop')}px`}};
          padding-left: {{`${getValue('padding_left','laptop')}px`}};
          {{ getValue('background_type','laptop') == 'color' && getValue('background_color','laptop') ? `background-color: ${getValue('background_color','laptop')};` : ''}}
          {{ getValue('background_type','laptop') == 'image' && getValue('background_image','laptop') ? `background-image: url(${getValue('background_image','laptop')});` : ''}}
          {{ getValue('background_type','laptop') == 'image' && getValue('background_size','laptop') ? `background-size: ${getValue('background_size','laptop')};` : ''}}
          {{ getValue('background_type','laptop') == 'image' && getValue('background_position','laptop') ? `background-position: ${getValue('background_position','laptop')};` : ''}}
          {{ getValue('background_type','laptop') == 'image' && getValue('background_repeat','laptop') ? `background-repeat: ${getValue('background_repeat','laptop')};` : ''}}
          {{ getValue('background_type','laptop') == 'image' && getValue('background_attachment','laptop') ? `background-attachment: ${getValue('background_attachment','laptop')};` : ''}}
          {{ getValue('border_style','laptop') ? `border-style: ${getValue('border_style','laptop')};` : '' }}
          {{ getValue('border_color','laptop') ? `border-color: ${getValue('border_color','laptop')};` : '' }}
          {{ getValue('border_width','laptop') ? `border-width: ${getValue('border_width','laptop')}px;` : '' }}
          {{ getValue('border_top_left_radius','laptop') ? `border-top-left-radius: ${getValue('border_top_left_radius','laptop')}px;` : '' }}
          {{ getValue('border_top_right_radius','laptop') ? `border-top-right-radius: ${getValue('border_top_right_radius','laptop')}px;` : '' }}
          {{ getValue('border_bottom_left_radius','laptop') ? `border-bottom-left-radius: ${getValue('border_bottom_left_radius','laptop')}px;` : '' }}
          {{ getValue('border_bottom_right_radius','laptop') ? `border-bottom-right-radius: ${getValue('border_bottom_right_radius','laptop')}px;` : '' }}
          {{ getValue('opacity','laptop') ? `opacity: ${getValue('opacity','laptop')};` : '' }}
          {{ getValue('shadow_style','laptop') == 'drop' ? `filter: drop-shadow(${getValue('shadow_x','laptop') || 0}px ${getValue('shadow_y','laptop') || 0}px ${getValue('shadow_blur','laptop') || 0}px ${getValue('shadow_color','laptop') || 'rgb(255, 255, 255)'});` : ''}}
          {{ getValue('shadow_style','laptop') == 'box' ? `box-shadow: ${getValue('shadow_type','laptop') == 'inset' ? 'inset' : ''} ${getValue('shadow_x','laptop') || 0}px ${getValue('shadow_y','laptop') || 0}px ${getValue('shadow_spread','laptop') || 0}px ${getValue('shadow_blur','laptop') || 0}px ${getValue('shadow_color','laptop') || 'rgb(255, 255, 255)'};` : ''}}
        }
      } 
      @media (min-width: 1200px){ 
          #element-{{ node.uuid }} .sg_collection-banner-title h1{
              font-size: {{ desktop.font_size }}px;
              font-weight: {{ desktop?.font_style?.includes('bold')?'bold':desktop.font_weight }};
              line-height: {{ desktop.line_height }}em;
              color: {{ desktop.color }};
              letter-spacing: {{ desktop.letter_spacing }}px;
              font-family: {{ desktop.font_family }};
              text-transform: {{ desktop.text_transform }};
              font-style: {{desktop?.font_style?.includes('italic')?'italic':''}}!important;
              text-decoration: {{desktop?.font_style?.includes('underline')?'underline':''}}!important;
          }
        #element-{{ node.uuid }}  {
          display: {{ desktop.display == 'none' ? 'none' : 'block' }} !important;
          margin: {{ getValue('margin_top','desktop') }}px {{ getValue('margin_right','desktop') }}px {{ getValue('margin_bottom','desktop') }}px {{ getValue('margin_left','desktop') }}px;
          padding-top: {{`${getValue('padding_top','desktop')}px`}};
          padding-right: {{`${getValue('padding_right','desktop')}px`}};
          padding-bottom: {{`${getValue('padding_bottom','desktop')}px`}};
          padding-left: {{`${getValue('padding_left','desktop')}px`}};
          {{ desktop.background_type == 'color' && desktop.background_color ? `background-color: ${desktop.background_color};` : ''}}
          {{ desktop.background_type == 'image' && desktop.background_image ? `background-image: url(${desktop.background_image});` : ''}}
          {{ desktop.background_type == 'image' && desktop.background_size ? `background-size: ${desktop.background_size};` : ''}}
          {{ desktop.background_type == 'image' && desktop.background_position ? `background-position: ${desktop.background_position};` : ''}}
          {{ desktop.background_type == 'image' && desktop.background_repeat ? `background-repeat: ${desktop.background_repeat};` : ''}}
          {{ desktop.background_type == 'image' && desktop.background_attachment ? `background-attachment: ${desktop.background_attachment};` : ''}}
          {{ desktop.border_style ? `border-style: ${desktop.border_style};` : '' }}
          {{ desktop.border_color ? `border-color: ${desktop.border_color};` : '' }}
          {{ desktop.border_width ? `border-width: ${desktop.border_width}px;` : '' }}
          {{ desktop.border_top_left_radius ? `border-top-left-radius: ${desktop.border_top_left_radius}px;` : '' }}
          {{ desktop.border_top_right_radius ? `border-top-right-radius: ${desktop.border_top_right_radius}px;` : '' }}
          {{ desktop.border_bottom_left_radius ? `border-bottom-left-radius: ${desktop.border_bottom_left_radius}px;` : '' }}
          {{ desktop.border_bottom_right_radius ? `border-bottom-right-radius: ${desktop.border_bottom_right_radius}px;` : '' }}
          {{ desktop.opacity ? `opacity: ${desktop.opacity};` : '' }}
          {{ desktop.shadow_style == 'drop' ? `filter: drop-shadow(${desktop.shadow_x || 0}px ${desktop.shadow_y || 0}px ${desktop.shadow_blur || 0}px ${desktop.shadow_color || 'rgb(255, 255, 255)'});` : ''}}
          {{ desktop.shadow_style == 'box' ? `box-shadow: ${desktop.shadow_type == 'inset' ? 'inset' : ''} ${desktop.shadow_x || 0}px ${desktop.shadow_y || 0}px ${desktop.shadow_spread || 0}px ${desktop.shadow_blur || 0}px ${desktop.shadow_color || 'rgb(255, 255, 255)'};` : ''}}
        }
      }
    </v-style>
  </div>
</template>

<script>
import ToolBar from '../../elements/utils/ToolBar.vue';

import elementStyleMixin from "../../elements/elementStyleMixin";
import ExtraClass from "../../elements/otherSettings/ExtraClass.vue";
import AlignmentBase from "../../elements/styleSettings/AlignmentBase.vue";
import Typography from "../../elements/styleSettings/Typography.vue";
import BannerNotice from "./settings/BannerNotice.vue";

import Visibility from "../../elements/designSettings/Visibility.vue";
import Spacing from "../../elements/designSettings/Spacing.vue";
import Background from "../../elements/designSettings/Background.vue";
import Border from "../../elements/designSettings/Border.vue";
import Corner from '../../elements/designSettings/Corner.vue';
import Shadow from '../../elements/designSettings/Shadow.vue';
import Opacity from '../../elements/designSettings/Opacity.vue';
import { designStyles, otherProps, otherStyles, actionTypes } from '@/constants';
import CustomCSS from '../../elements/styleSettings/CustomCSS.vue';
import Animations from '../../elements/designSettings/Animations.vue';

export default {
  components: {ToolBar},
  data() {
    return {
      isHovering: false
    }
  },
  watch: {
    dragging: function (newValue, oldValue) {
      var $iframe = $("#designEditor").contents();
      var $element = $iframe.find(`#element-${this.node.uuid}`);
      var $content = $element.find(`.sg_collection-title-wrapper`);
      if (newValue && newValue == this.node.uuid) {
        setTimeout(() => {
        $element.css("padding","0px 0px 0px 0px");
        $element.css("margin","0px 0px 0px 0px");
        $content.hide();
        },100);
      }else if (oldValue == this.node.uuid){
        $element.css("padding","");
        $element.css("margin","");
        $content.show();
      }
    }
  },
  inject: ["node","editor"],
  mixins: [elementStyleMixin],
  props: {
    ...otherProps,
    extra_class: String,
    component:String,
    title:String,
    desktop: Object,
    laptop: Object,
    mobile: Object,
    tablet: Object,
    notice: String,
  },
  computed: {
      dragging: function () {
      return this.editor?.draggedNode?.uuid
      },
  },
  craft: {
    defaultProps: {
      ...otherStyles,
      notice:'This element will not show correct information until a collection is picked in a Product List.',
      component:"(C) Title",
      title:"",
      typographyActives: [
        'font-weight',
        'font-size',
        'line-height',
        'color',
        'font-family',
        'text-transform',
        'font_style'
      ],
      desktop: {
        ...designStyles,
        font_weight: '600',
        font_size: 105,
        line_height: 1.2,
        letter_spacing: 0,
        color: 'rgb(51, 51, 51)',
        font_family: 'inherit',
        text_transform: 'none',
        alignment: 'center',
        padding_top: 10,
        padding_bottom: 10,
      },
      laptop: {
        ...designStyles,
      },
      tablet: {
        ...designStyles,
      },
      mobile: {
        ...designStyles,
        font_size: 96,
      },
      elementStyle: {
      }
    },
    settings: {
      settings: {
        "":  BannerNotice,
        Typography: {Typography, multi_screen: true},
        "Alignment": {Alignment: AlignmentBase, multi_screen: true},
        "Extra Class": ExtraClass, "Custom CSS": CustomCSS,
      },
      design: {
        Visibility,
        Spacing: { Spacing, multi_screen: true },
        Background: { Background, multi_screen: true },
        Border: { Border, multi_screen: true },
        Corner: { Corner, multi_screen: true },
        Shadow: { Shadow, multi_screen: true },
        Opacity: { Opacity, multi_screen: true },
      },
      others: {
        Animations: { Animations, actions: actionTypes }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
  