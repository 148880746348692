<template>
  <ul :class="`gf-tool gf-e-tool gf-tool-wrap gf-e-tool-wrap gf-tool-show`">
    <li class="t-handle" :draggable="true">
      <a :class="`e-handle ${dragging? 'hidden':''}`">
        <span>
          <svg class="editor move" width="14" viewBox="0 0 14 14">
            <path d="M11,10V8H8v3h2l-3,3l-3-3h2V8H3v2L0,7l3-3v2h3V3H4l3-3l3,3H8v3h3V4l3,3L11,10z"></path>
          </svg></span>
        <span class="gf-tool-label">Heading</span></a>
      <div :class="`preview ${dragging? '':'hidden'}`">
      </div>
    </li>
    <li class="e-edit">
      <a class="e-edit"><svg class="need_a_name" height="14px" viewBox="0 0 14 14">
          <path
            d="M12.81,3.69c0.26-0.26,0.26-0.68,0-0.94l-1.56-1.56c-0.26-0.26-0.68-0.26-0.94,0L9.09,2.41l2.5,2.5L12.81,3.69zM1,10.5V13h2.5l7.37-7.37l-2.5-2.5L1,10.5z">
          </path>
        </svg></a>
    </li>
    <li class="t-wrap e-wrap">
      <a class="e-export" title="Save element"><svg class="editor save" width="14" height="14px" viewBox="0 0 14 14">
          <path
            d="M12.2499 9.78559V12.4106H1.74999V9.78559H0V12.4106C0 13.3731 0.787495 14.1606 1.74999 14.1606H12.2499C13.2124 14.1606 13.9999 13.3731 13.9999 12.4106V9.78559H12.2499ZM11.3749 6.28561L10.1412 5.05187L7.87495 7.30935V0.160645H6.12496V7.30935L3.85873 5.05187L2.62498 6.28561L6.99996 10.6606L11.3749 6.28561Z">
          </path>
        </svg></a><a class="e-clone" title="Clone Element"><svg class="editor duplicate" width="14" viewBox="0 0 14 14">
          <path d="M10,10H0V0h10V10z M12,4v8H4v2h10V4H12z"></path>
        </svg></a><a class="e-remove" title="Delete Element"><svg class="editor delete" width="14" viewBox="0 0 14 14">
          <path
            d="M7,0C3.1,0,0,3.1,0,7c0,3.9,3.1,7,7,7s7-3.1,7-7C14,3.1,10.9,0,7,0z M7,8.4l-2.8,2.8c-0.6-0.4-1-0.9-1.4-1.4 L5.6,7L2.8,4.2c0.4-0.6,0.9-1,1.4-1.4L7,5.6l2.8-2.8c0.6,0.4,1,0.9,1.4,1.4L8.4,7l2.8,2.8c-0.4,0.6-0.9,1-1.4,1.4L7,8.4z">
          </path>
        </svg></a>
    </li>
    <li class="t-collapse">
      <a title="Expand / Collapse"><svg class="editor expand" width="8" viewBox="0 0 8 14">
          <path d="M1,3l4,4l-4,4V3z"></path>
        </svg></a>
    </li>
  </ul>
</template>
<script>

export default {
  props: {
    dragging: Boolean,
  },
  setup() {

  },
}
</script>
<style scoped>
ul.gf-tool {
  margin-bottom: 0;
  overflow: visible;

}

.gf-tool.gf-tool-show {
  visibility: visible;
  opacity: 1;

}

.gf-tool.hidden {
  visibility: hidden;
}

.t-handle {
  cursor: move;
}

.t-handle .preview {
  width: 100px;
  height: 100px;
  background: red;
  z-index: 2;
  position: absolute;
}

.t-handle .hidden {
  visibility: hidden;
}

.gf-tool.gf-e-tool,
.gf-tool.gf-e-tool li a {
  background: #42c184;
}

.gryffeditor ul {
  margin: 0 !important;
  padding: 0 !important;
  list-style: none !important;
}

.gf-tool {
  display: none;
}

.gf-tool,
.gf-tool li {
  float: left;
  display: flex !important;
  flex-wrap: nowrap;
  height: 30px;
}

.gf-tool {
  background: red;
  left: 50%;
  top: 50%;
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  user-select: none !important;
  visibility: hidden;
  opacity: 0;
  overflow: hidden;
  border-radius: 3px;
  transform: translate(-50%, -50%) scale(.8);
}

.gf-delete,
.gf-tool {
  position: absolute;
  z-index: 11;
}

.gf-tool.gf-e-tool,
.gf-tool.gf-e-tool li a {
  background: #42c184;
}

.gf-tool li.t-handle a {
  width: auto;
  display: flex !important;
  flex-wrap: nowrap;
}

.gf-tool li a {
  display: block;
  float: left;
  text-decoration: none;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  width: 26px;
  height: 30px;
  line-height: 30px;
  color: #fff !important;
  background: red;
  position: relative;
  cursor: pointer;
}

.gf-tool li {
  margin: 0 !important;
  padding: 0 !important;
  list-style: none !important;
  white-space: nowrap;
}

.gf-tool,
.gf-tool li {
  float: left;
  display: flex !important;
  flex-wrap: nowrap;
  height: 30px;
}

.gf-tool li.t-handle span {
  display: inline-block;
  float: left;
  line-height: 30px;
  width: 26px;
  height: 30px;
  position: relative;
  cursor: move;
}

.gf-tool li.t-handle span svg {
  left: 6px;
  transform: translateY(-50%);
}

.gf-tool li a svg {
  fill: #fff !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0 !important;
  padding: 0 !important;
  width: 14px !important;
  height: 15px !important;
  vertical-align: initial !important;
}

.gf-tool li a svg path {
  fill: #fff;
}

.gf-tool li a svg * {
  stroke: transparent;
}

.gf-tool li.t-handle span.gf-tool-label {
  display: flex !important;
  flex-wrap: nowrap;
  width: auto;
  height: 30px;
  line-height: 30px;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 14px !important;
  font-family: Inter, Roboto, Helvetica Neue, sans-serif;
  font-weight: 700;
  letter-spacing: .3px;
  cursor: pointer;
  color: #fff;
}

.gf-tool li.t-handle span {
  display: inline-block;
  float: left;
  line-height: 30px;
  width: 26px;
  height: 30px;
  position: relative;
  cursor: move;
}
</style>
