<template>
  <div
    data-label="(P) Price"
    data-key="(p) price"
    data-parent="product"
    class="sg-wrap module"
    :id="`element-${node.uuid}`"
    @mouseover="isHovering = true"
    @mouseout="isHovering = false"
    :class="{ 'dragging': dragging == node.uuid }"
  >
    <div
      class="sg-module"
      :class="extra_class"
    >
      <div class="sg_product-prices" data-oldformat="" data-oldcurrency="">
        <span
          v-if="(product?.compare_price > 0 && product?.compare_price > product?.price) && compare_first && show_compare_price"
          :class="((product?.compare_price > 0 && product?.compare_price > product?.price) && compare_first && show_compare_price)?'':'hide'"
          class="sg_product-compare-price money sg_gs-text-paragraph-1"
          :style="{'margin-right': `${compare_gaps||''}px!important`}"
          itemprop="price"
          data-price-compare-at=""
          >{{ product.compare_price_format || (product?.compare_price || 0).toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 }) }}</span>
        <span
          class="sg_product-price money sg_gs-text-paragraph-1"
          itemprop="price"
          data-price="23"
          >{{ product.price_format || (product?.price || 0).toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 }) }}</span
        >
        <span
          v-if="(product?.compare_price > 0 && product?.compare_price > product?.price) && !compare_first && show_compare_price"
          class="sg_product-compare-price money sg_gs-text-paragraph-1"
          :class="((product?.compare_price > 0 && product?.compare_price > product?.price) && !compare_first && show_compare_price)? '':'hide'"
          :style="{'margin-left': `${compare_gaps||''}px!important`}"
          itemprop="price"
          data-price-compare-at=""
          >{{ product.compare_price_format || (product?.compare_price || 0).toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 }) }}</span>
      </div>
    </div>

    <ToolBar
      ref="(p)price"
      :toolbar_id="`(p)price`"
      class="container-toolbar"
      :class="{ 'container-toolbar-show': isHovering, 'is-dragging': dragging == node.uuid }"
    >
    </ToolBar>

    <v-style> 
      {{ custom_css }}
      
      @media (max-width: 767px){ 
        #element-{{ node.uuid }} .sg_product-compare-price:after {
          transform: translateY({{ getValue('line_through_position','mobile') || '0' }}px)!important;
          height: {{ getValue('show_line_through','mobile') ? 1 : 0 }}px!important;
          font-weight: 400!important;
          background: {{getValue('line_through_color','mobile')||""}}!important;
        }

        #element-{{ node.uuid }} .sg_product-compare-price {
          {{ getValue('compare_font_size', 'mobile') > 0 ? `font-size: ${getValue('compare_font_size','mobile')}px;` : '' }}
          {{ getValue('compare_font_style', 'mobile') == 'bold' ? 'font-weight: bold;' : `font-weight: ${getValue('compare_font_weight','mobile')};` }}
          {{ getValue('compare_font_style', 'mobile') == 'italic' ? 'font-style: italic !important;' : '' }}
          {{ getValue('compare_color', 'mobile') ? `color: ${getValue('compare_color','mobile')};` : '' }}
          {{ compare_font_family ? `font-family: ${compare_font_family} !important;` : '' }}
        }

        #element-{{ node.uuid }} .sg_product-price {
          font-weight: {{ getValue('font_weight','mobile') }};
          {{ getValue('font_size', 'mobile') > 0 ? `font-size: ${getValue('font_size','mobile')}px;` : '' }}
          {{ getValue('color', 'mobile') ? `color: ${getValue('color','mobile')};` : '' }}
          font-family: {{ getValue('font_family','mobile') }};
          {{ getDisplayValue('mobile') }}
          {{ getMarginValue('mobile') }}
          {{ getPaddingValue('mobile') }}
          {{ getBackgroundValue('mobile') }}
          {{ getBorderValue('mobile') }}
          {{ getBorderRadiusValue('mobile') }}
          {{ getOpacityValue('mobile') }}
          {{ getShadowValue('mobile') }}
          {{ getOtherStyle('mobile') }}
        } 
        #element-{{ node.uuid }} .sg-module {
          text-align: {{ getValue('alignment','mobile') }};
        } 
      } 
      @media (max-width: 991px) and (min-width: 768px){ 
        #element-{{ node.uuid }} .sg_product-compare-price:after {
          transform: translateY({{ getValue('line_through_position','tablet') || '0' }}px)!important;
          height: {{ getValue('show_line_through','tablet') ? 1 : 0 }}px!important;
          font-weight: 400!important;
          background: {{getValue('line_through_color','tablet')||""}}!important;
        }

        #element-{{ node.uuid }} .sg_product-compare-price {
          {{ getValue('compare_font_size', 'tablet') > 0 ? `font-size: ${getValue('compare_font_size','tablet')}px;` : '' }}
          {{ getValue('compare_font_style', 'tablet') == 'bold' ? 'font-weight: bold;' : `font-weight: ${getValue('compare_font_weight','tablet')};` }}
          {{ getValue('compare_font_style', 'tablet') == 'italic' ? 'font-style: italic !important;' : '' }}
          {{ getValue('compare_color', 'tablet') ? `color: ${getValue('compare_color','tablet')};` : '' }}
          {{ compare_font_family ? `font-family: ${compare_font_family} !important;` : '' }}
        }


        #element-{{ node.uuid }} .sg_product-price {
          font-weight: {{ getValue('font_weight','tablet') }};
          {{ getValue('font_size', 'tablet') > 0 ? `font-size: ${getValue('font_size','tablet')}px;` : '' }}
          {{ getValue('color', 'tablet') ? `color: ${getValue('color','tablet')};` : '' }}
          font-family: {{ getValue('font_family','tablet') }};
          {{ getDisplayValue('tablet') }}
          {{ getMarginValue('tablet') }}
          {{ getPaddingValue('tablet') }}
          {{ getBackgroundValue('tablet') }}
          {{ getBorderValue('tablet') }}
          {{ getBorderRadiusValue('tablet') }}
          {{ getOpacityValue('tablet') }}
          {{ getShadowValue('tablet') }}
          {{ getOtherStyle('tablet') }}
        } 
        #element-{{ node.uuid }} .sg-module {
          text-align: {{ getValue('alignment','tablet') }};
        }
      } 
      @media (max-width: 1199px) and (min-width: 992px){ 
        #element-{{ node.uuid }} .sg_product-compare-price:after {
          transform: translateY({{ getValue('line_through_position','laptop') || '0' }}px)!important;
          height: {{ getValue('show_line_through','laptop') ? 1 : 0 }}px!important;
          font-weight: 400!important;
          background: {{getValue('line_through_color','laptop')||""}}!important;
        }

        #element-{{ node.uuid }} .sg_product-compare-price {
          {{ getValue('compare_font_size', 'laptop') > 0 ? `font-size: ${getValue('compare_font_size','laptop')}px;` : '' }}
          {{ getValue('compare_font_style', 'laptop') == 'bold' ? 'font-weight: bold;' : `font-weight: ${getValue('compare_font_weight','laptop')};` }}
          {{ getValue('compare_font_style', 'laptop') == 'italic' ? 'font-style: italic !important;' : '' }}
          {{ getValue('compare_color', 'laptop') ? `color: ${getValue('compare_color','laptop')};` : '' }}
          {{ compare_font_family ? `font-family: ${compare_font_family} !important;` : '' }}

        }

        #element-{{ node.uuid }} .sg_product-price {
          font-weight: {{ getValue('font_weight','laptop') }};
          {{ getValue('font_size', 'laptop') > 0 ? `font-size: ${getValue('font_size','laptop')}px;` : '' }}
          {{ getValue('color', 'laptop') ? `color: ${getValue('color','laptop')};` : '' }}
          font-family: {{ getValue('font_family','laptop') }};
          {{ getDisplayValue('laptop') }}
          {{ getMarginValue('laptop') }}
          {{ getPaddingValue('laptop') }}
          {{ getBackgroundValue('laptop') }}
          {{ getBorderValue('laptop') }}
          {{ getBorderRadiusValue('laptop') }}
          {{ getOpacityValue('laptop') }}
          {{ getShadowValue('laptop') }}
          {{ getOtherStyle('laptop') }}
        } 
        #element-{{ node.uuid }} .sg-module {
          text-align: {{ getValue('alignment','laptop') }};
        }
      } 
      @media (min-width: 1200px){ 
        #element-{{ node.uuid }} .sg_product-compare-price:after {
          transform: translateY({{ getValue('line_through_position','desktop') || '0' }}px)!important;
          height: {{ getValue('show_line_through','desktop') ? 1 : 0 }}px!important;
          font-weight: 400!important;
          background: {{getValue('line_through_color','desktop')||""}}!important;
        }

        #element-{{ node.uuid }} .sg_product-compare-price {
          {{ getValue('compare_font_size', 'desktop') > 0 ? `font-size: ${getValue('compare_font_size','desktop', 12)}px;` : '' }}
          {{ getValue('compare_font_style', 'desktop') == 'bold' ? 'font-weight: bold;' : `font-weight: ${getValue('compare_font_weight','desktop')};` }}
          {{ getValue('compare_font_style', 'desktop') == 'italic' ? 'font-style: italic !important;' : '' }}
          {{ getValue('compare_color', 'desktop') ? `color: ${getValue('compare_color','desktop')};` : '' }}
          {{ compare_font_family ? `font-family: ${compare_font_family} !important;` : '' }}
        }
        #element-{{ node.uuid }} .sg_product-price {
          font-weight: {{ desktop?.font_weight }};
          {{ getValue('font_size', 'desktop') > 0 ? `font-size: ${getValue('font_size','desktop')}px;` : '' }}
          {{ getValue('color', 'desktop') ? `color: ${getValue('color','desktop')};` : '' }}
          font-family: {{ desktop?.font_family }};
          {{ getDisplayValue('desktop') }}
          {{ getMarginValue('desktop') }}
          {{ getPaddingValue('desktop') }}
          {{ getBackgroundValue('desktop') }}
          {{ getBorderValue('desktop') }}
          {{ getBorderRadiusValue('desktop') }}
          {{ getOpacityValue('desktop') }}
          {{ getShadowValue('desktop') }}
          {{ getOtherStyle('desktop') }}
        } 
        #element-{{ node.uuid }} .sg-module {
          text-align: {{ desktop?.alignment }};
        }
        #element-{{ node.uuid }} .sg_product-prices >.hide{
          display:none;
        }
      }
    </v-style>
  </div>
</template>

<script>
import ToolBar from "../../elements/utils/ToolBar.vue";

import elementStyleMixin from "../../elements/elementStyleMixin";
import ExtraClass from "../../elements/otherSettings/ExtraClass.vue"; import CustomCSS from '../../elements/styleSettings/CustomCSS.vue';
import AlignmentBase from "../../elements/styleSettings/AlignmentBase.vue";
import Typography from "../../elements/styleSettings/Typography.vue";
import ComparePrice from "./settings/ComparePrice.vue";

import Visibility from "../../elements/designSettings/Visibility.vue";
import Spacing from "../../elements/designSettings/Spacing.vue";
import Background from "../../elements/designSettings/Background.vue";
import Border from "../../elements/designSettings/Border.vue";
import Corner from "../../elements/designSettings/Corner.vue";
import Shadow from "../../elements/designSettings/Shadow.vue";
import Opacity from "../../elements/designSettings/Opacity.vue";
import { designStyles, otherProps, otherStyles, actionTypes } from "@/constants";
import Animations from '../../elements/designSettings/Animations.vue';

export default {
  components: { ToolBar },
  data() {
    return {
      isHovering: false,
    };
  },
  inject: ["node","product",'editor'],
  mixins: [elementStyleMixin],
  props: {
    extra_class: String, ...otherProps,
    component: String,
    content: String,
    parent: String,
    price: String,
    compare: String,
    desktop: Object,
    laptop: Object,
    tablet: Object,
    mobile: Object,
    compare_first: Boolean,
    show_compare_price: Boolean,
    compare_gaps: Number,
    compare_font_family: String,
  },
  computed: {
    dragging: function () {
      return this.editor?.draggedNode?.uuid
    },
  },
  craft: {
    defaultProps: { ...otherStyles,
      price: "20.99",
      compare: "32.99",
      parent: "Product",
      compare_first: true,
      show_compare_price: true,
      compare_gaps:5,
      typographyActives: [
        "font-size",
        "font-weight",
        "color",
        "font-family",
      ],
      desktop: {
        ...designStyles,
        font_weight: '400',
        font_size: 16,
        color: 'rgb(51, 51, 51)',
        font_family: 'inherit',
        alignment: 'center',
        compare_first: true,
        compare_font_weight: '400',
        compare_font_size: 16,
        compare_color:'rgb(51, 51, 51)',
        show_line_through:true,
        line_through_color: 'rgb(51, 51, 51)',
        line_through_position:0
      },
      laptop: {
        ...designStyles,
      },
      tablet: {
        ...designStyles,
      },
      mobile: {
        ...designStyles,
      },
      elementStyle: {
      },
    },
    settings: {
      settings: {
        "Compare Price":{"Compare Price": ComparePrice, multi_screen: true},
        Typography: {Typography, multi_screen: true},
        Alignment: { Alignment: AlignmentBase, multi_screen: true},
        "Extra Class": ExtraClass, "Custom CSS": CustomCSS,
      },
      design: {
        Visibility,
        Spacing: { Spacing, multi_screen: true },
        Background: { Background, multi_screen: true },
        Border: { Border, multi_screen: true },
        Corner: { Corner, multi_screen: true },
        Shadow: { Shadow, multi_screen: true },
        Opacity: { Opacity, multi_screen: true },
      },
      others: {
        Animations: { Animations, actions: actionTypes }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
