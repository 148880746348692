<template>
  <div label-position="top" size="" :model="elementProps">
    <div class="d-flex flex-column py-2">
      <span class="setting-title">Inactive Tab Background</span>
      <ColorPicker
        class="mt-2"
        :value="elementProps.inactive_tab_background"
        v-bind:changeColor="($event) => updateProps('inactive_tab_background', $event)"
        :primaryColor="primaryColor"
      />
    </div>
    <div class="d-flex flex-column py-2">
      <span class="setting-title">Inactive Tab Text Color</span>
      <ColorPicker
        class="mt-2"
        :value="elementProps.inactive_tab_text_color"
        v-bind:changeColor="($event) => updateProps('inactive_tab_text_color', $event)"
        :primaryColor="primaryColor"
      />
    </div>
  </div>
</template>

<script>
import mixin from "../../../elements/styleSettings/mixin";
import ColorPicker from "../../../elements/common/ColorPicker.vue";
export default {
  mixins: [mixin],
  components: { ColorPicker },
  props: {
    primaryColor: String
  },
  methods: {
    updateProps(name, value) {
      this.elementPropsSetter({ [name]: value });
    },
  },
};
</script>

<style scoped>
</style>
