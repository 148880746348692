<template>
  <div class="cart-modal">
    <div class="cart-modal-container" tabIndex="-1">
      <section class='cart-header'>
        <h5>
          <span>Cart</span>
        </h5>
        <div class='cart-close-btn'>
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" stroke-width="2" stroke="#000" fill="none" stroke-linecap="round" stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M18 6l-12 12"></path>
            <path d="M6 6l12 12"></path>
          </svg>
        </div>
      </section>
      <section class='cart-body'>
        <span class="empty-cart">There are no items in your cart.</span>
        <div class="cart-info-wrapper">
          
        </div>
      </section>
    </div>
  </div>
</template>
<script>
export default {
  name: "CartModal",
  props: {},
  watch: {},
  data () {
    return {
      carts: JSON.parse(localStorage.getItem('carts') || "[]")
    };
  },
  methods: {
      
  },
};
</script>
