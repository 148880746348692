<template>
  <div label-position="top" size="mini" :model="elementProps">
    <div class="d-flex flex-column py-2">
      <ToggleButton
        :value="image_type"
        v-bind:changeValue="($event) => updateType($event)"
        :options="[
          { label: 'Normal', value: 'normal' },
          { label: 'Hover', value: 'hover' }
        ]"
        primaryColor="#0060e5"
      />
    </div>
    <div v-if="image_type == 'normal'">
      <div class="d-flex flex-column py-2">
        <span class="setting-title">Border Type</span>
        <Select
          class="mt-2"
          :value="elementProps.image_border_type"
          v-bind:changeValue="($event) => updateCommonProps('image_border_type', $event)"
          :options="[
            { value: 'none', label: 'None' },
            { value: 'solid', label: 'Solid' },
            { value: 'dotted', label: 'Dotted' },
            { value: 'dashed', label: 'Dashed' },
          ]"
          primaryColor="#0060e5"
        />
      </div>
      <div class="d-flex flex-column py-2" v-if="elementProps.image_border_type != 'none'">
        <span class="setting-title">Border Width</span>
        <Dimension
          :units="['px']"
          :unit="elementProps.image_border_width_unit || 'px'"
          primaryColor="#0060e5"
          :data="elementProps"
          :items="[
            {
              key: 'image_border_top_width',
              title: 'Top',
            },
            {
              key: 'image_border_right_width',
              title: 'Right'
            },
            {
              key: 'image_border_bottom_width',
              title: 'Bottom'
            },
            {
              key: 'image_border_left_width',
              title: 'Left'
            }
          ]"
          v-bind:changeValue="(name, $event) => updateCommonProps(name, $event)"
          v-bind:changeUnit="($event) => updateCommonProps('image_border_width_unit', $event)"
        />
      </div>
      <div class="d-flex flex-column py-2" v-if="elementProps.image_border_type != 'none'">
        <span class="setting-title">Border Color</span>
        <ColorPicker 
          primaryColor="#0060e5"
          class="mt-2"
          :value="elementProps.image_border_color"
          v-bind:changeColor="($event) => updateCommonProps('image_border_color', $event)"
        />
      </div>
      <div class="d-flex flex-column py-2">
        <span class="setting-title">Border Radius</span>
        <Dimension
          :units="['px', '%']"
          :unit="elementProps.image_border_radius_unit || 'px'"
          primaryColor="#0060e5"
          :data="elementProps"
          :items="[
            {
              key: 'image_border_top_left_radius',
              title: 'Top',
            },
            {
              key: 'image_border_top_right_radius',
              title: 'Right'
            },
            {
              key: 'image_border_bottom_right_radius',
              title: 'Bottom'
            },
            {
              key: 'image_border_bottom_left_radius',
              title: 'Left'
            }
          ]"
          v-bind:changeValue="(name, $event) => updateCommonProps(name, $event)"
          v-bind:changeUnit="($event) => updateCommonProps('image_border_radius_unit', $event)"
        />
      </div>
      <div class="d-flex flex-column py-2">
        <span class="setting-title">Opacity</span>
        <Slider
          class="mt-2"
          :step="0.1"
          :value="elementProps.image_opacity"
          v-bind:changeValue="($event) => updateCommonProps('image_opacity', parseFloat($event))"
          unit=""
          :max="1"
          :min="0"
          primaryColor="#0060e5"
        />
      </div>
    </div>
    <div v-if="image_type == 'hover'">
      <div class="d-flex flex-column py-2">
        <span class="setting-title">Border Type</span>
        <Select
          class="mt-2"
          :value="elementProps.image_hover_border_type"
          v-bind:changeValue="($event) => updateCommonProps('image_hover_border_type', $event)"
          :options="[
            { value: 'none', label: 'None' },
            { value: 'solid', label: 'Solid' },
            { value: 'dotted', label: 'Dotted' },
            { value: 'dashed', label: 'Dashed' },
          ]"
          primaryColor="#0060e5"
        />
      </div>
      <div class="d-flex flex-column py-2" v-if="elementProps.image_hover_border_type != 'none'">
        <span class="setting-title">Border Width</span>
        <Dimension
          :units="['px']"
          :unit="elementProps.image_hover_border_width_unit || 'px'"
          primaryColor="#0060e5"
          :data="elementProps"
          :items="[
            {
              key: 'image_hover_border_top_width',
              title: 'Top',
            },
            {
              key: 'image_hover_border_right_width',
              title: 'Right'
            },
            {
              key: 'image_hover_border_bottom_width',
              title: 'Bottom'
            },
            {
              key: 'image_hover_border_left_width',
              title: 'Left'
            }
          ]"
          v-bind:changeValue="(name, $event) => updateCommonProps(name, $event)"
          v-bind:changeUnit="($event) => updateCommonProps('image_hover_border_width_unit', $event)"
        />
      </div>
      <div class="d-flex flex-column py-2" v-if="elementProps.image_hover_border_type != 'none'">
        <span class="setting-title">Border Color</span>
        <ColorPicker 
          primaryColor="#0060e5"
          class="mt-2"
          :value="elementProps.image_hover_border_color"
          v-bind:changeColor="($event) => updateCommonProps('image_hover_border_color', $event)"
        />
      </div>
      <div class="d-flex flex-column py-2">
        <span class="setting-title">Border Radius</span>
        <Dimension
          :units="['px', '%']"
          :unit="elementProps.image_hover_border_radius_unit || 'px'"
          primaryColor="#0060e5"
          :data="elementProps"
          :items="[
            {
              key: 'image_hover_border_top_left_radius',
              title: 'Top',
            },
            {
              key: 'image_hover_border_top_right_radius',
              title: 'Right'
            },
            {
              key: 'image_hover_border_bottom_right_radius',
              title: 'Bottom'
            },
            {
              key: 'image_hover_border_bottom_left_radius',
              title: 'Left'
            }
          ]"
          v-bind:changeValue="(name, $event) => updateCommonProps(name, $event)"
          v-bind:changeUnit="($event) => updateCommonProps('image_hover_border_radius_unit', $event)"
        />
      </div>
      <div class="d-flex flex-column py-2">
        <span class="setting-title">Opacity</span>
        <Slider
          class="mt-2"
          :step="0.1"
          :value="elementProps.image_hover_opacity"
          v-bind:changeValue="($event) => updateCommonProps('image_hover_opacity', parseFloat($event))"
          unit=""
          :max="1"
          :min="0"
          primaryColor="#0060e5"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Node from '@/core/Node';
import { makeId, icons } from "@/constants";
import mixin from '../styleSettings/mixin';
import Slider from '../common/Slider.vue';
import ColorPicker from '../common/ColorPicker.vue';
import Select from '../common/Select.vue';
import ToggleButton from '../common/ToggleButton.vue';
import Dimension from '../common/Dimension.vue';
export default {
  mixins: [mixin],
  components: {
    ColorPicker, Slider, Select, ToggleButton, Dimension
  },
  props: {
    screen: String,
    node: Node,
    activeFonts: Array,
  },
  data() {
    return {
      fontKey: makeId(20),
      icons,
      image_type: 'normal',
      box_index: 0
    };
  },
  watch: {
    activeFonts: function (newValue, oldValue) {
      this.fontKey = makeId(20);
    },
  },
  methods: {
    setBoxIndex(value) {
      this.box_index = value
    },
    updateType(value) {
      this.image_type = value
    },
    updateCommonProps(name, value) {
      this.elementPropsSetter({ [name]: value });
    },
    updateProps(name, value) {
      const screen = this.$props.screen
      const data_screen = Object.assign({}, this.elementProps[screen])
      data_screen[name] = value
      this.elementPropsSetter({ [screen]: data_screen });
    },
    handleShowModal() {
      this.$bus.emit("showFontManager", this.node);
    },
    getListFont() {
      const listFonts = [
        {
          value: "inherit",
          label: "Default",
        },
      ];
      this.node?.editor?.fonts?.map((e) => {
        listFonts.push({ value: e, label: e });
      });
      return listFonts;
    },
  }
};
</script>
