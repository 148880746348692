<template>
  <el-form label-position="top" size="" :model="elementProps">
    <el-form-item label="Button Link">
      <el-input
        :value="elementProps.href"
        @input="($event) => updateProps('href', $event)"
        placeholder="https://your-shop.myshopify.com"
        size="medium"
      />
    </el-form-item>

    <el-form-item label="Open link in">
      <Select
        :primaryColor="primaryColor"
        :value="elementProps.target"
        v-bind:changeValue="($event) => updateProps('target', $event)"
        :options="[
          {
            value: '_self',
            label: 'Current Tab',
          },
          {
            value: '_blank',
            label: 'New Tab',
          },
        ]"
      />
    </el-form-item>
  </el-form>
</template>

<script>
import mixin from "../../../elements/styleSettings/mixin";
import Select from "../../../elements/common/Select.vue";

export default {
  mixins: [mixin],
  components: {Select},
  methods: {
    updateProps(name, value) {
      if(value ==  this.elementProps[name]) return;
      this.elementPropsSetter({ [name]: value });
    },
  },
};
</script>

<style scoped>
</style>
