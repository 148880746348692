var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"label-position":"top","size":"mini","model":_vm.elementProps}},[_c('div',{staticClass:"d-flex flex-column py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Tracking event name")]),_c('Input',{staticClass:"mt-2",attrs:{"value":_vm.elementProps.tracking_event_name,"changeValue":($event) => _vm.updateProps('tracking_event_name', $event),"primaryColor":_vm.primaryColor,"placeholder":"ShareDiscount, AddToCart, ..."}})],1),_c('div',{staticClass:"d-flex flex-column py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Tracking event data")]),_c('AceEditor',{staticClass:"my-editor",attrs:{"lang":"json","theme":"monokai","width":"100%","height":"200px","options":{
          enableBasicAutocompletion: true,
          enableLiveAutocompletion: true,
          fontSize: 11,
          fontFamily: 'monospace',
          highlightActiveLine: true,
          enableSnippets: true,
          showLineNumbers: true,
          tabSize: 2,
          showPrintMargin: false,
          showGutter: true,
      }},on:{"init":_vm.editorInit},model:{value:(_vm.elementProps.tracking_event_data),callback:function ($$v) {_vm.$set(_vm.elementProps, "tracking_event_data", $$v)},expression:"elementProps.tracking_event_data"}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }