<template>
  <div class="custom-date-picker" :style="cssVars">
    <date-picker
      :type="type"
      :value="value"
      @change="changeValue($event)"     
      :format="format || 'YYYY-MM-DD'" 
      popup-class="custom-popper-datepicker"
      :popup-style="cssVars"
    />
  </div>
</template>

<script>

export default {
  components: {
    name: 'DatePicker'
  },
  props: {
    format: String,
    type: String,
    value: Date,
    changeValue: Function,
    primaryColor: String,
  },
  computed: {
    cssVars() {
      return {
        '--primary-color': this.primaryColor || '#0060e5'
      }
    }
  }
};
</script>

<style lang="scss">
.custom-date-picker {
  .mx-datepicker {
    width: 100%;
  }
  .mx-input {
    background-color: #222222;
    border: 1px solid #494949;
    color: #ccc;
    font-size: 12px;
    &:focus {
      border-color: var(--primary-color);
    }
  }
}
.custom-popper-datepicker.mx-datepicker-main {
  background-color: #343b3e !important;
  border: none;
}

.custom-popper-datepicker {
  .mx-calendar-content .cell:hover {
    background-color: #222222;
    color: #D0D0D0;
  }
  .mx-calendar-content .cell.active {
    background-color: var(--primary-color);
  }
  .mx-time {
    background-color: #343b3e;
  }
  .mx-time-header {
    border-bottom: 1px solid #494949;
  }
  .mx-time-column {
    border-left: 1px solid #494949;
    .mx-time-item:hover {
      background-color: #222222;
      color: #D0D0D0;
    }
    .mx-time-item.active {
      color: var(--primary-color);
    }
  }
}
</style>