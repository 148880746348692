<template>
  <div label-position="top" size="small" :model="elementProps">
    <div class="d-flex flex-column py-2">
      <span class="setting-title">Width</span>
      <InputWithSelect
        class="mt-2"
        type="number"
        :value="getValue('width', elementProps[screen].width)"
        :primaryColor="primaryColor"
        :options="['px', '%']"
        v-bind:changeValue="($event) => updateProps('width', $event)"
        v-bind:changeUnit="($event) => updateProps('width_type', $event)"
        :unit="getValue('width_type',elementProps[screen].width_type)"
      />
      <!-- <el-input
        :value="elementProps[screen].width"
        class="input-with-select mt-2"
        @input="updateProps('width', $event)"
        type="number"
        size="medium"
      >
        <el-select
          slot="append"
          placeholder="-"
          :value="elementProps[screen].width_type"
          @change="updateProps('width_type', $event)"
        >
          <el-option label="px" value="px"></el-option>
          <el-option label="%" value="%"></el-option>
        </el-select>
      </el-input> -->
    </div>
  </div>
</template>

<script>
import mixin from "./mixin";
import ToggleButton from "../common/ToggleButton.vue";
import InputWithSelect from "../common/InputWithSelect.vue";
export default {
  mixins: [mixin],
  components: {
    ToggleButton, InputWithSelect
  },
  props: {
    screen: String,
    primaryColor: String
  },
  methods: {
    updateProps(name, value) {
      if (name == 'width') {
        const reg = /^\d+$/;
        if (!reg.test(value)) {
          return
        }
      }
      const screen = this.$props.screen
      const data_screen = Object.assign({}, this.elementProps[screen])
      if(value ==  data_screen[name]) return;
      data_screen[name] = value
      this.elementPropsSetter({ [screen]: data_screen });
    },
  },
};
</script>

