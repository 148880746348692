<template>
  <div label-position="top" size="mini" :model="elementProps">
    <div class="d-flex align-items-center justify-content-between py-2">
      <span class="setting-title">Equal Height</span>
      <el-switch
        :value="elementProps.equal_height"
        :active-color="primaryColor"
        inactive-color="#949494"
        :active-value="true"
        :inactive-value="false"
        @change="updateProps('equal_height', $event)"
      />
    </div>
    <div class="mt-1" style="color: #999999; font-size: 12px; font-style: italic;">
      If checked columns will be set to equal height.
    </div>
    <div class="d-flex flex-column py-2" v-if="elementProps.equal_height">
      <span class="setting-title">Vertical Align</span>
      <Select 
        class="mt-2"
        :primaryColor="primaryColor"
        :value="elementProps.vertical_align"
        v-bind:changeValue="($event) => updateProps('vertical_align', $event)"
        :options="[
          {
            value: 'flex-start',
            label: 'Top'
          },
          {
            value: 'center',
            label: 'Middle'
          },
          {
            value: 'flex-end',
            label: 'Bottom'
          }
        ]"
      />
      <div class="mt-1" style="color: #999999; font-size: 12px; font-style: italic;">
        Vertical Align items inside Row
      </div>
    </div>
    
  </div>
</template>

<script>
import mixin from '../styleSettings/mixin';
import Select from '../common/Select.vue';
export default {
  mixins: [mixin],
  components: {
    Select
  },
  methods: {
    updateProps(name, value) {
      if(value ==  this.elementProps[name]) return;
      this.elementPropsSetter({ [name]: value });
    }
  },
  props: {
    primaryColor: String
  }
};
</script>
