<template>
  <div label-position="top" size="mini" :model="elementProps">
    <div class="d-flex flex-column py-2">
      <span class="setting-title">Tracking event name</span>
      <Input
        class="mt-2"
        :value="elementProps.tracking_event_name"
        v-bind:changeValue="($event) => updateProps('tracking_event_name', $event)"
        :primaryColor="primaryColor"
        placeholder="ShareDiscount, AddToCart, ..."
      />
    </div>
    <div class="d-flex flex-column py-2">
      <span class="setting-title">Tracking event data</span>
      <AceEditor
        class="my-editor"
        v-model="elementProps.tracking_event_data" 
        @init="editorInit" 
        lang="json"
        theme="monokai" 
        width="100%" 
        height="200px"
        :options="{
            enableBasicAutocompletion: true,
            enableLiveAutocompletion: true,
            fontSize: 11,
            fontFamily: 'monospace',
            highlightActiveLine: true,
            enableSnippets: true,
            showLineNumbers: true,
            tabSize: 2,
            showPrintMargin: false,
            showGutter: true,
        }"
      />
    </div>
  </div>
</template>

<script>
import mixin from "./mixin";
import Node from "@/core/Node";
import Slider from "../common/Slider.vue";
import ToggleButton from "../common/ToggleButton.vue";
import Input from "../common/Input.vue";
import Cookies from 'js-cookie';
import AceEditor from 'vuejs-ace-editor';

export default {
  mixins: [mixin],
  components: {
    Slider, ToggleButton, Input, AceEditor
  },
  data() {
    return {
      user: JSON.parse(Cookies.get('user_onepage') || '{}'),
      loading: false
    }
  },
  props: {
    screen: String,
    primaryColor: String,
    node: Node,
  },
  methods: {
    updateProps(name, value) {
      this.elementPropsSetter({ [name]: value });
    },
    editorInit: function () {
      require('brace/ext/language_tools') //language extension prerequsite...
      require('brace/mode/json')
      require('brace/theme/monokai')
      require('brace/snippets/json') //snippet
    }
  },
};
</script>

<style lang="scss" scoped>
  .button-add {
    background-color: #494949;
    width: fit-content;
    padding: 8px 14px;
    font-size: 14px;
    border-radius: 8px;
    transition: all 0.3s ease;
    &:hover {
      background-color: #555555;
      transition: all 0.3s ease;
    }
  }
</style>
