<template>
  <div label-position="top" size="medium" :model="elementProps">
    
    <div class="d-flex align-items-center justify-content-between py-2">
      <div>
        <span class="setting-title">Compare Price</span>
        <div class="sgDesc mt-2">
          Show or hide compare price if current product is on sale.
        </div>
      </div>
      <el-switch
        :value="elementProps.show_compare_price"
        :active-color="primaryColor"
        inactive-color="#949494"
        @change="updatePropsGeneral('show_compare_price', $event)"
      />
    </div>

    <div class="d-flex align-items-center justify-content-between py-2" v-if="elementProps.show_compare_price">
      <div>
        <span class="setting-title">Compare First</span>
        <div class="sgDesc mt-2">
            Display compare price before or after regular price.
        </div>
      </div>
      <el-switch
      :value="elementProps.compare_first"
        :active-color="primaryColor"
        inactive-color="#949494"
        @change="updatePropsGeneral('compare_first', $event)"
      />
    </div>
    <div class="d-flex flex-column py-2" v-if="elementProps.show_compare_price">
      <span class="setting-title">Font Weight</span>
      <Select
        class="mt-2"
        :primaryColor="primaryColor"
        :value="elementProps[screen].compare_font_weight"
        v-bind:changeValue="
          ($event) => updateProps('compare_font_weight', $event)
        "
        :options="[
          {
            value: '100',
            label: 'Thin 100',
          },
          {
            value: '200',
            label: 'Semi Thin 200',
          },
          {
            value: '300',
            label: 'Light 300',
          },
          {
            value: '400',
            label: 'Regular 400',
          },
          {
            value: '500',
            label: 'Medium 500',
          },
          {
            value: '600',
            label: 'Semi Bold 600',
          },
          {
            value: '700',
            label: 'Bold 700',
          },
          {
            value: '800',
            label: 'Super Bold 800',
          },
          {
            value: '900',
            label: 'Boldest 900',
          },
          {
            value: 'lighter',
            label: 'Lighter',
          },
          {
            value: 'bolder',
            label: 'Bolder',
          },
        ]"
      />
    </div>
    <div class="d-flex flex-column py-2" v-if="elementProps.show_compare_price">
      <span class="setting-title">Font Size</span>
      <Slider
        class="mt-2"
        :primaryColor="primaryColor"
        :value="Number.parseInt(elementProps[screen].compare_font_size)"
        :min="0"
        :max="999"
        :step="1"
        v-bind:changeValue="($event) => updateProps('compare_font_size', $event)"
        unit="px"
        :onlyInteger="true"
      />
    </div>
    <div class="d-flex flex-column py-2" v-if="elementProps.show_compare_price">
      <span class="setting-title">Color</span>
      <ColorPicker
        class="mt-2"
        :value="elementProps[screen].compare_color"
        v-bind:changeColor="($event) => updateProps('compare_color', $event)"
        :primaryColor="primaryColor"
      />
    </div>
    
    <div class="d-flex flex-column py-2" v-if="elementProps.show_compare_price">
      <span class="setting-title">Price Gaps</span>
      <Slider
        class="mt-2"
        :primaryColor="primaryColor"
        :value="Number.parseInt(elementProps.compare_gaps)"
        :min="0"
        :max="44"
        :step="1"
        v-bind:changeValue="($event) => updatePropsGeneral('compare_gaps', $event)"
        unit="px"
        :onlyInteger="true"
      />
    </div>

    <div v-if="elementProps.show_compare_price">
      <div class="py-2">
        <span>Font Style</span>
        <ToggleButton 
          :primaryColor="primaryColor"
          :value="elementProps[screen].compare_font_style"
          v-bind:changeValue="($event) => updateProps('compare_font_style', $event)"
          :canRemove="true"
          :options="[
            { value: 'bold', label: 'Bold' },
            { value: 'italic', label: 'Italic' },
          ]"
        />
      </div>
    </div>
    <div class="d-flex flex-column py-2" v-if="elementProps.show_compare_price">
      <span class="setting-title">Font</span>
      <Select
        class="mt-2"
        :primaryColor="primaryColor"
        :value="elementProps.compare_font_family"
        v-bind:changeValue="
          ($event) => updatePropsGeneral('compare_font_family', $event)
        "
        :hasAction="true"
        v-bind:onAction="handleShowModal"
        actionLabel="Open Font Manager"
        :options="getListFont()"
      />
    </div>

    <div class="d-flex align-items-center justify-content-between py-2" v-if="elementProps.show_compare_price">
      <span class="setting-title">Line Through</span>
      <el-switch
        :value="elementProps[screen].show_line_through"
        :active-color="primaryColor"
        inactive-color="#949494"
        @change="updateProps('show_line_through', $event)"
      />
    </div>
    <div class="d-flex flex-column py-2" v-if="elementProps.show_compare_price && elementProps[screen].show_line_through">
      <span class="setting-title">Line Through Color</span>
      <ColorPicker
        class="mt-2"
        :value="elementProps[screen].line_through_color"
        v-bind:changeColor="($event) => updateProps('line_through_color', $event)"
        :primaryColor="primaryColor"
      />
    </div>
    <div class="d-flex flex-column py-2" v-if="elementProps.show_compare_price && elementProps[screen].show_line_through">
      <span class="setting-title">Line Through Position</span>
      <Slider
        class="mt-2"
        :primaryColor="primaryColor"
        :value="Number.parseInt(elementProps[screen].line_through_position)"
        :min="-10"
        :max="10"
        :step="1"
        v-bind:changeValue="($event) => updateProps('line_through_position', $event)"
        unit="px"
      />
    </div>
  </div>
</template>
  
  <script>
import mixin from "../../../elements/styleSettings/mixin";
import ColorPicker from "../../../elements/common/ColorPicker.vue";
import Slider from "../../../elements/common/Slider.vue";
import ToggleButton from "../../../elements/common/ToggleButton.vue";
import Select from "../../../elements/common/Select.vue";
import Node from "@/core/Node";
import { makeId } from "@/constants";

export default {
  mixins: [mixin],
  components: { ColorPicker, Slider, ToggleButton, Select },
  props: {
    node: Node,
    primaryColor: String,
    screen: String,
    activeFonts: Array,
  },
  data() {
    return {
      fontKey: makeId(20),
    };
  },
  watch: {
    activeFonts: function (newValue, oldValue) {
      this.fontKey = makeId(20);
    },
  },
  methods: {
    updateProps(name, value) {
      const screen = this.$props.screen;
      const data_screen = Object.assign({}, this.elementProps[screen]);
      if (value == data_screen[name]) return;
      data_screen[name] = value;
      if (name == "width" && value == "auto" && isNaN(parseInt(value))) {
        data_screen["width_type"] = "";
      }
      this.elementPropsSetter({ [screen]: data_screen });
    },
    updatePropsGeneral(name, value) {
      if (value == this.elementProps[name]) return;
      this.elementPropsSetter({ [name]: value });
    },
    handleShowModal() {
      this.$bus.emit("showFontManager", this.node);
    },
    getListFont() {
      const listFonts = [
        {
          value: "inherit",
          label: "Default",
        },
      ];
      this.node?.editor?.fonts?.map((e) => {
        listFonts.push({ value: e, label: e });
      });
      return listFonts;
    },
  },
};
</script>
  
  <style scoped>
</style>
  