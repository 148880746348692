<template>
  <el-form label-position="top" size="" :model="elementProps">
    <el-form-item label="Format">
      <el-input
        :value="elementProps.format"
        @input="($event) => updateProps('format', $event)"
      />
      <div class="sgDesc mt-2">
        Converts a timestamp into another date format. The format for this syntax is the same as <a class="reference">strftime.</a>
      </div>
    </el-form-item>
  </el-form>
</template>

<script>
import mixin from "../../../elements/styleSettings/mixin";

export default {
  mixins: [mixin],
  components: {},
  methods: {
    updateProps(name, value) {
      if(value ==  this.elementProps[name]) return;
      this.elementPropsSetter({ [name]: value });
    },
  },
};
</script>

<style scoped>
</style>
