export const homeIcons = {
  editWhite: 
    `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" stroke-width="1.5" stroke="#fff" fill="none" stroke-linecap="round" stroke-linejoin="round">
      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
      <path d="M9 7h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3"></path>
      <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3"></path>
      <line x1="16" y1="5" x2="19" y2="8"></line>
    </svg>`,
  edit: 
    `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" stroke-width="1.5" stroke="#000000" fill="none" stroke-linecap="round" stroke-linejoin="round">
      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
      <path d="M9 7h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3"></path>
      <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3"></path>
      <line x1="16" y1="5" x2="19" y2="8"></line>
    </svg>`,
  make_default:
    `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" stroke-width="1.5" stroke="#000000" fill="none" stroke-linecap="round" stroke-linejoin="round">
      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
      <circle cx="12" cy="12" r="9"></circle>
      <path d="M9 12l2 2l4 -4"></path>
    </svg>`,
  rename: `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" stroke-width="1.5" stroke="#000000" fill="none" stroke-linecap="round" stroke-linejoin="round">
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <path d="M12 3a3 3 0 0 0 -3 3v12a3 3 0 0 0 3 3"></path>
    <path d="M6 3a3 3 0 0 1 3 3v12a3 3 0 0 1 -3 3"></path>
    <path d="M13 7h7a1 1 0 0 1 1 1v8a1 1 0 0 1 -1 1h-7"></path>
    <path d="M5 7h-1a1 1 0 0 0 -1 1v8a1 1 0 0 0 1 1h1"></path>
    <path d="M17 12h.01"></path>
    <path d="M13 12h.01"></path>
  </svg>`,
  duplicate: 
    `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" stroke-width="1.5" stroke="#000000" fill="none" stroke-linecap="round" stroke-linejoin="round">
      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
      <rect x="8" y="8" width="12" height="12" rx="2"></rect>
      <path d="M16 8v-2a2 2 0 0 0 -2 -2h-8a2 2 0 0 0 -2 2v8a2 2 0 0 0 2 2h2"></path>
    </svg>`,
  preview:
    `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" stroke-width="1.5" stroke="#000000" fill="none" stroke-linecap="round" stroke-linejoin="round">
      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
      <path d="M4 8v-2a2 2 0 0 1 2 -2h2"></path>
      <path d="M4 16v2a2 2 0 0 0 2 2h2"></path>
      <path d="M16 4h2a2 2 0 0 1 2 2v2"></path>
      <path d="M16 20h2a2 2 0 0 0 2 -2v-2"></path>
      <line x1="12" y1="11" x2="12" y2="11.01"></line>
      <path d="M12 18l-3.5 -5a4 4 0 1 1 7 0l-3.5 5"></path>
    </svg>`,
  delete:
    `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" stroke-width="1.5" stroke="#000000" fill="none" stroke-linecap="round" stroke-linejoin="round">
      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
      <line x1="4" y1="7" x2="20" y2="7"></line>
      <line x1="10" y1="11" x2="10" y2="17"></line>
      <line x1="14" y1="11" x2="14" y2="17"></line>
      <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"></path>
      <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"></path>
    </svg>`,
  more:
    `<svg viewBox="2 12 28 8" width="24" height="8">
      <g id="Icon">
        <path d="M6,12c-2.208,0 -4,1.792 -4,4c0,2.208 1.792,4 4,4c2.208,0 4,-1.792 4,-4c0,-2.208 -1.792,-4 -4,-4Zm0,2c1.104,0 2,0.896 2,2c0,1.104 -0.896,2 -2,2c-1.104,0 -2,-0.896 -2,-2c0,-1.104 0.896,-2 2,-2Z"></path>
        <path d="M26,12c-2.208,0 -4,1.792 -4,4c0,2.208 1.792,4 4,4c2.208,0 4,-1.792 4,-4c0,-2.208 -1.792,-4 -4,-4Zm0,2c1.104,0 2,0.896 2,2c0,1.104 -0.896,2 -2,2c-1.104,0 -2,-0.896 -2,-2c0,-1.104 0.896,-2 2,-2Z"></path>
        <path d="M16,12c-2.208,0 -4,1.792 -4,4c0,2.208 1.792,4 4,4c2.208,0 4,-1.792 4,-4c0,-2.208 -1.792,-4 -4,-4Zm0,2c1.104,0 2,0.896 2,2c0,1.104 -0.896,2 -2,2c-1.104,0 -2,-0.896 -2,-2c0,-1.104 0.896,-2 2,-2Z"></path>
      </g>
    </svg>`,
  eye:
    `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" stroke-width="1.5" stroke="#000000" fill="none" stroke-linecap="round" stroke-linejoin="round">
      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
      <circle cx="12" cy="12" r="2"></circle>
      <path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7"></path>
    </svg>`,
  salegate:
    `<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M23.9499 2.13734C21.5259 0.7341 18.7739 -0.00327579 15.973 1.09396e-05C7.16577 1.09396e-05 0 7.16398 0 15.969C0 20.1324 1.6027 23.9296 4.2241 26.7756C5.35703 28.0065 6.67537 29.053 8.13144 29.877L12.2097 24.7848C10.4826 24.0466 9.01029 22.8177 7.97556 21.2505C6.94083 19.6833 6.38921 17.8468 6.38918 15.969C6.38918 10.6769 10.6803 6.38759 15.973 6.38759C17.4921 6.38663 18.9896 6.74713 20.3418 7.43931C21.694 8.13148 22.862 9.13545 23.7493 10.3682L27.8268 5.27603C26.7058 4.03445 25.3977 2.97547 23.9499 2.13734Z" fill="#007B5C"></path>
      <path d="M31.9459 15.9691C31.9462 17.0417 31.8386 18.1117 31.625 19.1629C30.1412 26.443 23.6861 31.9394 15.973 31.9394C13.2244 31.9428 10.5221 31.2326 8.13039 29.8785L12.2087 24.7863C13.3985 25.2942 14.6792 25.5553 15.973 25.5536C20.1454 25.5536 23.6959 22.8875 25.0111 19.166H16.7719V19.0872L21.8217 12.7753H31.625C31.8386 13.8264 31.9462 14.8964 31.9459 15.9691Z" fill="#007B5C"></path>
      <path d="M31.9459 15.9691C31.9462 17.0417 31.8386 18.1117 31.625 19.1629L16.7719 19.1629V19.084L21.8217 12.7753H31.625C31.8386 13.8264 31.9462 14.8964 31.9459 15.9691Z" fill="#007B5C"></path>
      <path d="M12.2087 24.7831L6.43105 31.9984H0.0418646L8.29017 21.6975C9.30188 23.0529 10.6536 24.1173 12.2087 24.7831Z" fill="#007B5C"></path>
      <path d="M32.0003 0.0607949L27.8268 5.27603L23.7503 10.3683C22.7608 8.99623 21.4264 7.90976 19.882 7.21884L23.9499 2.13734L25.6112 0.0607949H32.0003Z" fill="#007B5C"></path>
    </svg>`,
}