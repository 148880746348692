<template>
  <div label-position="top" size="small" :model="elementProps">
    <div class="d-flex flex-column py-2">
      <span class="setting-title">Width</span>
      <InputWithSelect
        class="mt-2"
        type="number"
        :value="getValue('width',elementProps[screen].width)"
        :primaryColor="primaryColor"
        :options="['px', '%']"
        v-bind:changeValue="($event) => updateProps('width', $event)"
        v-bind:changeUnit="($event) => updateProps('width_type', $event)"
        :unit="getValue('width_type',elementProps[screen].width_type)"
      />
    </div>
    <div class="d-flex flex-column py-2">
      <span class="setting-title">Height</span>
      <Input
        class="mt-2"
        :value="getValue('height',elementProps[screen].height)"
        v-bind:changeValue="($event) => updateProps('height', $event)"
        :primaryColor="primaryColor"
        placeholder="auto"
      />
    </div>
    <div class="d-flex align-items-center justify-content-between py-2">
      <span class="setting-title">Circle Image</span>
      <el-switch
        :value="getValue('circle',elementProps[screen].circle)"
        :active-color="primaryColor"
        inactive-color="#949494"
        :active-value="true"
        :inactive-value="false"
        @change="updateProps('circle', $event)"
      />
    </div>
  </div>
</template>

<script>
import mixin from "./mixin";
import ToggleButton from "../common/ToggleButton.vue";
import InputWithSelect from "../common/InputWithSelect.vue";
import Input from "../common/Input.vue";
export default {
  mixins: [mixin],
  components: {
    ToggleButton, InputWithSelect, Input
  },
  props: {
    screen: String,
    primaryColor:String
  },
  methods: {
    updateProps(name, value) {
      const screen = this.$props.screen
      const data_screen = Object.assign({}, this.elementProps[screen])
      if(value ==  data_screen[name]) return;
      data_screen[name] = value
      this.elementPropsSetter({ [screen]: data_screen });
    },
  },
};
</script>

<style scoped>
.text-description {
  font-style: italic;
  font-size: 12px;
  color: #999999;
}
</style>
