var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"label-position":"top","size":"mini","model":_vm.elementProps}},[_c('div',{staticClass:"d-flex flex-column py-2"},[_c('ToggleButton',{attrs:{"value":_vm.image_type,"changeValue":($event) => _vm.updateType($event),"options":[
        { label: 'Normal', value: 'normal' },
        { label: 'Hover', value: 'hover' }
      ],"primaryColor":"#0060e5"}})],1),(_vm.image_type == 'normal')?_c('div',[_c('div',{staticClass:"d-flex flex-column py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Border Type")]),_c('Select',{staticClass:"mt-2",attrs:{"value":_vm.elementProps.image_border_type,"changeValue":($event) => _vm.updateCommonProps('image_border_type', $event),"options":[
          { value: 'none', label: 'None' },
          { value: 'solid', label: 'Solid' },
          { value: 'dotted', label: 'Dotted' },
          { value: 'dashed', label: 'Dashed' },
        ],"primaryColor":"#0060e5"}})],1),(_vm.elementProps.image_border_type != 'none')?_c('div',{staticClass:"d-flex flex-column py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Border Width")]),_c('Dimension',{attrs:{"units":['px'],"unit":_vm.elementProps.image_border_width_unit || 'px',"primaryColor":"#0060e5","data":_vm.elementProps,"items":[
          {
            key: 'image_border_top_width',
            title: 'Top',
          },
          {
            key: 'image_border_right_width',
            title: 'Right'
          },
          {
            key: 'image_border_bottom_width',
            title: 'Bottom'
          },
          {
            key: 'image_border_left_width',
            title: 'Left'
          }
        ],"changeValue":(name, $event) => _vm.updateCommonProps(name, $event),"changeUnit":($event) => _vm.updateCommonProps('image_border_width_unit', $event)}})],1):_vm._e(),(_vm.elementProps.image_border_type != 'none')?_c('div',{staticClass:"d-flex flex-column py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Border Color")]),_c('ColorPicker',{staticClass:"mt-2",attrs:{"primaryColor":"#0060e5","value":_vm.elementProps.image_border_color,"changeColor":($event) => _vm.updateCommonProps('image_border_color', $event)}})],1):_vm._e(),_c('div',{staticClass:"d-flex flex-column py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Border Radius")]),_c('Dimension',{attrs:{"units":['px', '%'],"unit":_vm.elementProps.image_border_radius_unit || 'px',"primaryColor":"#0060e5","data":_vm.elementProps,"items":[
          {
            key: 'image_border_top_left_radius',
            title: 'Top',
          },
          {
            key: 'image_border_top_right_radius',
            title: 'Right'
          },
          {
            key: 'image_border_bottom_right_radius',
            title: 'Bottom'
          },
          {
            key: 'image_border_bottom_left_radius',
            title: 'Left'
          }
        ],"changeValue":(name, $event) => _vm.updateCommonProps(name, $event),"changeUnit":($event) => _vm.updateCommonProps('image_border_radius_unit', $event)}})],1),_c('div',{staticClass:"d-flex flex-column py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Opacity")]),_c('Slider',{staticClass:"mt-2",attrs:{"step":0.1,"value":_vm.elementProps.image_opacity,"changeValue":($event) => _vm.updateCommonProps('image_opacity', parseFloat($event)),"unit":"","max":1,"min":0,"primaryColor":"#0060e5"}})],1)]):_vm._e(),(_vm.image_type == 'hover')?_c('div',[_c('div',{staticClass:"d-flex flex-column py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Border Type")]),_c('Select',{staticClass:"mt-2",attrs:{"value":_vm.elementProps.image_hover_border_type,"changeValue":($event) => _vm.updateCommonProps('image_hover_border_type', $event),"options":[
          { value: 'none', label: 'None' },
          { value: 'solid', label: 'Solid' },
          { value: 'dotted', label: 'Dotted' },
          { value: 'dashed', label: 'Dashed' },
        ],"primaryColor":"#0060e5"}})],1),(_vm.elementProps.image_hover_border_type != 'none')?_c('div',{staticClass:"d-flex flex-column py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Border Width")]),_c('Dimension',{attrs:{"units":['px'],"unit":_vm.elementProps.image_hover_border_width_unit || 'px',"primaryColor":"#0060e5","data":_vm.elementProps,"items":[
          {
            key: 'image_hover_border_top_width',
            title: 'Top',
          },
          {
            key: 'image_hover_border_right_width',
            title: 'Right'
          },
          {
            key: 'image_hover_border_bottom_width',
            title: 'Bottom'
          },
          {
            key: 'image_hover_border_left_width',
            title: 'Left'
          }
        ],"changeValue":(name, $event) => _vm.updateCommonProps(name, $event),"changeUnit":($event) => _vm.updateCommonProps('image_hover_border_width_unit', $event)}})],1):_vm._e(),(_vm.elementProps.image_hover_border_type != 'none')?_c('div',{staticClass:"d-flex flex-column py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Border Color")]),_c('ColorPicker',{staticClass:"mt-2",attrs:{"primaryColor":"#0060e5","value":_vm.elementProps.image_hover_border_color,"changeColor":($event) => _vm.updateCommonProps('image_hover_border_color', $event)}})],1):_vm._e(),_c('div',{staticClass:"d-flex flex-column py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Border Radius")]),_c('Dimension',{attrs:{"units":['px', '%'],"unit":_vm.elementProps.image_hover_border_radius_unit || 'px',"primaryColor":"#0060e5","data":_vm.elementProps,"items":[
          {
            key: 'image_hover_border_top_left_radius',
            title: 'Top',
          },
          {
            key: 'image_hover_border_top_right_radius',
            title: 'Right'
          },
          {
            key: 'image_hover_border_bottom_right_radius',
            title: 'Bottom'
          },
          {
            key: 'image_hover_border_bottom_left_radius',
            title: 'Left'
          }
        ],"changeValue":(name, $event) => _vm.updateCommonProps(name, $event),"changeUnit":($event) => _vm.updateCommonProps('image_hover_border_radius_unit', $event)}})],1),_c('div',{staticClass:"d-flex flex-column py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Opacity")]),_c('Slider',{staticClass:"mt-2",attrs:{"step":0.1,"value":_vm.elementProps.image_hover_opacity,"changeValue":($event) => _vm.updateCommonProps('image_hover_opacity', parseFloat($event)),"unit":"","max":1,"min":0,"primaryColor":"#0060e5"}})],1)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }