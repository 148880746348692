<template>
  <div label-position="top" size="medium" :model="elementProps">
    <div class="d-flex align-items-center justify-content-between py-2">
      <span class="setting-title">Stretch</span>
      <el-switch
        :value="getValue('stretch',elementProps[screen].stretch)"
        :active-color="primaryColor"
        inactive-color="#949494"
        @change="updateProps('stretch', $event)"
      />
    </div>
    <div class="d-flex flex-column py-2" v-if="!getValue('stretch',elementProps[screen].stretch)">
      <span class="setting-title">Input Width</span>
      <el-input
        :value="getValue('width',elementProps[screen].width)"
        class="input-with-select mt-2"
        @input="($event) => updateProps('width', $event)"
      >
        <el-select
          slot="append"
          placeholder="-"
          :value="getValue('width_type',elementProps[screen].width_type)"
          @change="updateProps('width_type', $event)"
          size="medium"
        >
          <el-option label="px" value="px"></el-option>
          <el-option label="%" value="%"></el-option>
        </el-select>
      </el-input>
    </div>
    <div class="d-flex flex-column py-2">
      <span class="setting-title">Input Height</span>
      <el-input
        class="mt-2"
        :value="getValue('height',elementProps[screen].height)"
        @input="($event) => updateProps('height', $event)"
        size="medium"
      />
    </div>
    <div class="d-flex flex-column py-2">
      <span class="setting-title">Button Width</span>
      <Slider
        class="mt-2"
        :value="getValue('button_width',elementProps[screen].button_width)"
        :primaryColor="primaryColor"
        v-bind:changeValue="($event) => updateProps('button_width', $event)"
        unit="px"
      />
    </div>
  </div>
</template>

<script>
import mixin from "../../../elements/styleSettings/mixin";
import Slider from "../../../elements/common/Slider.vue";

export default {
  mixins: [mixin],
  components: {Slider},
  props: {
    screen: String,
    primaryColor: String,
  },
  methods: {
    updateProps(name, value) {
      const screen = this.$props.screen
      const data_screen = Object.assign({}, this.elementProps[screen])
      if(value ==  data_screen[name]) return;

      data_screen[name] = value
      if (name == 'width' && (value == 'auto' && isNaN(parseInt(value)))) {
        data_screen['width_type'] = ''
      }
      this.elementPropsSetter({ [screen]: data_screen });
    },
  },
};
</script>
