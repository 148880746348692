<template>
  <div label-position="top" size="" :model="elementProps">
    <div class="py-2">
      <span class="setting-title">Accordion Type</span>
      <Select
        :primaryColor="primaryColor"
        :value="elementProps.accordion_type"
        v-bind:changeValue="($event) => updateProps('accordion_type', $event)"
        :options="[
          {
            value: 'single',
            label: 'Single',
          },
          {
            value: 'multiple',
            label: 'Multiple',
          }
        ]"
      />
    </div>
  </div>
</template>

<script>
import mixin from "../../../elements/styleSettings/mixin";
import Select from "../../../elements/common/Select.vue";
export default {
  mixins: [mixin],
  components: { Select },
  props: {
    primaryColor: String,
  },
  methods: {
    updateProps(name, value) {
      if(value ==  this.elementProps[name]) return;
      this.elementPropsSetter({ [name]: value });
    },
  },
};
</script>

<style scoped>
</style>
