<template>
  <div
    class="sg_context-menu"
    :class="{ active: show }"
    style="transform-origin: left top"
    :style="config?.position || {}"
    v-click-outside="onClickOutside"
  >
    <div class="sg_c-section">
      <div class="sg_c-item">
        <span class="sg_c-text"
          ><span>{{ config?.node?.componentName || "" }}</span></span
        >
        <div class="sg_c-caret" style="display: flex; align-items: center">
          <svg
            width="6"
            height="9"
            viewBox="0 0 6 9"
            class="bem-Svg"
            style="display: block; transform: translate(0px, 0px)"
          >
            <path fill="currentColor" d="M5 4.5L1 8V1z"></path>
          </svg>
        </div>
        <!-- <div class="sg_context-menu">
          <div class="sg_c-section">
            <div
              class="sg_c-item"
              data-action="settings"
              data-id="r-1663844205068"
            >
              <div class="sg_c-item-icon"></div>
              <div class="sg_c-item-body">
                <div
                  style="
                    display: grid;
                    grid-template-rows: 19px;
                    grid-template-columns: auto auto;
                    grid-template-areas: none;
                    line-height: 1;
                    gap: 8px 8px;
                    align-items: center;
                  "
                >
                  <span
                    ><svg
                      width="13"
                      height="12"
                      viewBox="0 0 13 12"
                      class="bem-Svg"
                      style="display: block; transform: translate(0px, 0px)"
                    >
                      <path
                        fill="currentColor"
                        d="M11 1H1v10h11V1h-1zm0 9H2V2h9v8zM5 9h1V3H3v6h2zM4 4h1v4H4V4zm5 5h1V3H7v6h2zM8 4h1v4H8V4z"
                      ></path></svg></span
                  ><span class="sg_c-text">Row</span>
                </div>
              </div>
            </div>
            <div
              class="sg_c-item"
              data-action="settings"
              data-id="e-1663904992669"
            >
              <div class="sg_c-item-icon">
                <svg
                  width="13"
                  height="11"
                  viewBox="0 0 13 11"
                  class="bem-Svg"
                  style="
                    display: block;
                    transform: translate(0px, 0px);
                    margin: auto;
                  "
                >
                  <path
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-miterlimit="10"
                    d="M11 2L5.5 8.5 2 5"
                  ></path>
                </svg>
              </div>
              <div class="sg_c-item-body">
                <div
                  style="
                    display: grid;
                    grid-template-rows: 19px;
                    grid-template-columns: auto auto;
                    grid-template-areas: none;
                    line-height: 1;
                    gap: 8px 8px;
                    align-items: center;
                  "
                >
                  <span
                    ><svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      class="bem-Svg"
                      style="display: block; transform: translate(0px, 0px)"
                    >
                      <path
                        fill="currentColor"
                        d="M6 5H3V2H2v7h1V6h3v3h1V2H6v3zm3-3a1 1 0 0 0-1 1v1h1v5h1V2H9z"
                      ></path></svg></span
                  ><span class="sg_c-text">Heading</span>
                </div>
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
    <div class="sg_c-section">
      <div class="sg_c-item" data-action="settings" @click="openSettings">
        <span class="sg_c-text">Open Settings</span>
      </div>
    </div>
    <div class="sg_c-section">
      <div
        class="sg_c-item"
        data-action="edit_code"
        v-if="config?.node?.componentName == 'Liquid'"
        @click="editCode"
      >
        <span class="sg_c-text">Edit Code</span>
      </div>
      <div class="sg_c-item" data-action="copy_css" @click="copyStyle">
        <span class="sg_c-text">Copy Style</span>
      </div>
      <div class="sg_c-item" data-action="paste_css" 
      :class="{'sg_c-item-disabled':config?.nodeStyleClone?.componentName != config?.node?.componentName}"
      @click="pasteStyle">
        <span class="sg_c-text">Paste Style</span>
      </div>
      <div class="sg_c-item" data-action="clone" @click="cloneElement">
        <span class="sg_c-text">Duplicate</span>
      </div>
      <div class="sg_c-item" data-action="delete" @click="removeContainer">
        <span class="sg_c-text">Delete</span
        ><span class="sg_c-shortcut">⌫</span>
      </div>
    </div>
    <div class="sg_c-section">
      <div class="sg_c-item">
        <span class="sg_c-text">Move to...</span>
        <div class="sg_c-caret" style="display: flex; align-items: center">
          <svg
            width="6"
            height="9"
            viewBox="0 0 6 9"
            class="bem-Svg"
            style="display: block; transform: translate(0px, 0px)"
          >
            <path fill="currentColor" d="M5 4.5L1 8V1z"></path>
          </svg>
        </div>
        <div class="sg_context-menu">
          <div class="sg_c-section" data-key="parents">
            <div class="sg_c-item sg_c-item-disabled" data-action="moveup">
              <span class="sg_c-text">Move Up</span>
            </div>
            <div class="sg_c-item sg_c-item-disabled" data-action="movedown">
              <span class="sg_c-text">Move Down</span>
            </div>
            <div class="sg_c-item" data-action="toparent">
              <span class="sg_c-text">Move to Parent</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="sg_c-section" gf-section="interaction">
      <div class="sg_c-item">
        <span class="sg_c-text">Add Animation</span>
        <div class="sg_c-caret" style="display: flex; align-items: center">
          <svg
            width="6"
            height="9"
            viewBox="0 0 6 9"
            class="bem-Svg"
            style="display: block; transform: translate(0px, 0px)"
          >
            <path fill="currentColor" d="M5 4.5L1 8V1z"></path>
          </svg>
        </div>
        <div class="sg_context-menu" style="top: unset; bottom: 0px">
          <div class="sg_c-section" data-key="parents">
            <div
              class="sg_c-item"
              data-key="mouse-click"
              data-action="addinteraction"
            >
              <span class="sg_c-text">Mouse Click</span>
            </div>
            <div
              class="sg_c-item"
              data-key="mouse-hover"
              data-action="addinteraction"
            >
              <span class="sg_c-text">Mouse Hover</span>
            </div>
            <div
              class="sg_c-item"
              data-key="mouse-leave"
              data-action="addinteraction"
            >
              <span class="sg_c-text">Mouse Leave</span>
            </div>
            <div
              class="sg_c-item"
              data-key="scroll-into-view"
              data-action="addinteraction"
            >
              <span class="sg_c-text">Scroll Into view</span>
            </div>
            <div
              class="sg_c-item"
              data-key="scrolling-in-view"
              data-action="addinteraction"
            >
              <span class="sg_c-text">While scrolling in view</span>
            </div>
            <div
              class="sg_c-item"
              data-key="after-page-loaded"
              data-action="addinteraction"
            >
              <span class="sg_c-text">After page finish loading</span>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <!-- <div class="sg_c-section">
      <div class="sg_c-item" data-action="open_action">
        <span class="sg_c-text">Open Flow Actions</span>
      </div>
    </div> -->
    <div class="sg_c-section" @click="saveToLibrary">
      <div class="sg_c-item" data-action="export">
        <span class="sg_c-text">Save to Library</span>
      </div>
    </div>
  </div>
</template>
  
<script>
import Vue from "vue";
Vue.directive("click-outside", {
  bind(el, binding, vnode) {
    el.clickOutsideEvent = (event) => {
      if (
        !(
          el === event?.target ||
          el?.contains(event?.target)
        )
      ) {
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener("click", el.clickOutsideEvent);
  },
  unbind(el) {
    document.body.removeEventListener("click", el.clickOutsideEvent);
  },
});
export default {
  props: {
    config: Object,
    editor: Object,
    show: Boolean,
  },
  data() {
    return {};
  },
  methods: {
    checkChildProductList(node) {
      var parent = node.parent;
      if (parent) {
        if (["ProductList", "RelatedProduct"].includes(parent.componentName)) {
          return node;
        } else {
          return this.checkChildProductList(parent);
        }
      } else {
        return null;
      }
    },
    editCode() {

    },
    saveToLibrary(){
      const { editor } = this.config.node;
      const node = editor.findNode(this.config.node.uuid)
      this.$bus.emit("showModalLibrary", {
        json_data:JSON.stringify(node.serialize()),
        label: node.componentName,
        type: "block"
      }, true, editor);
      this.$bus.emit("showContextMenu", null, null);
    },
    onClickOutside() {
        this.$bus.emit("showContextMenu", null, null);
    },
    elementCSS(cloneStyle) {
      const style = {};
      Object.entries(cloneStyle||{}).forEach(([key, value]) => {
        if(["background_type","background_color","background_image","background_position","background_repeat","background_attachment","background_size",
            "border_style","border_color","border_width",
            "border_top_left_radius","border_top_right_radius","border_bottom_left_radius","border_bottom_right_radius",
            "opacity",
            "shadow_style","shadow_color","shadow_type","shadow_x","shadow_y","shadow_blur","shadow_spread",
            "margin_top","margin_left","margin_right","margin_bottom","padding_top","padding_left","padding_right","padding_bottom",
            "display",
            "font_size","color"].includes(key)){
            style[key] = value;
        }
      });
      return style;
    },
    editCode() {
      this.$bus.emit("showLiquidModal", this.config.node)
    },
    copyStyle(){
        const { editor } = this.config.node;
        const node = editor.findNode(this.config.node.uuid)
        this.$bus.emit("showContextMenu", null, null, node);
    },
    pasteStyle(){
        const {nodeStyleClone } = this.config;
        const { editor } = this.config.node;
        const node = editor.findNode(this.config.node.uuid)
        const defaultProps = {}
        if(nodeStyleClone?.componentName != node?.componentName) return;
        let desktop = {...node.props.desktop, ...this.elementCSS(nodeStyleClone.props.desktop)}
        let laptop = {...node.props.laptop, ...this.elementCSS(nodeStyleClone.props.laptop)}
        let tablet = {...node.props.tablet, ...this.elementCSS(nodeStyleClone.props.tablet)}
        let mobile = {...node.props.mobile, ...this.elementCSS(nodeStyleClone.props.mobile)}
        Object.entries(nodeStyleClone.props||{}).forEach(([key, value])=>{
          if(['size','color'].includes(key)){
            defaultProps[key] = value
          }
        })
        node.setProps({
            desktop,laptop,tablet,mobile, ...defaultProps
        })
        this.$bus.emit("showContextMenu", null, null);
    },
    openSettings(){
        const { editor } = this.config.node;
        const node = editor.findNode(this.config.node.uuid)
        editor.selectNode(node)
        this.$bus.emit("showContextMenu", null, null);
    },
    cloneElement() {
      const { editor } = this.config.node;
      const node = editor.findNode(this.config.node.uuid)
      let nodes = editor.export();
      let popups = editor.exportPopup();
      let inversePatches = { nodes, popups };
      if (node.componentName == "Popup") {
        const newNode = node.duplicate();
        for (var i = 0; i < editor.popups.length; i++) {
          const nodePop = editor.popups[i];
          nodePop.setProps({ showPopup: false }, true);
        }
        editor.popups.push(newNode);
      } else {
        const parentNode = node.parent;
        const newNode = node.duplicate();
        var index = parentNode.children.findIndex((x) => x.uuid == node.uuid);
        parentNode.children.splice(index, 0, newNode);
        var productNode = this.checkChildProductList(node);
        if(productNode){
          var currentList = productNode.parent;
          for (var i = 0; i < currentList.children.length; i++) {
            const product = currentList.children[i]
            if (product.uuid == productNode.uuid) continue;
            const node2 = productNode.duplicate()
            product.children = node2.children;
          }
        }
        if ((newNode.componentName == 'Row' || newNode.props.component == 'Row') && parentNode.props?.component == 'WrapperContainer') {
          const rowNodes = parentNode.children.filter(e => e.componentName == 'Row' || e.props?.component == 'Row')
          for (let i = 0; i < rowNodes.length; i++) {
            rowNodes[i].setProps({name: `Row ${i + 1}`})
          }
        }
      }
      let patches = { nodes: editor.export(), popups: editor.exportPopup() };
      editor.add(patches, inversePatches);
      this.$bus.emit("showContextMenu", null, null);
    },
    removeContainer() {
      const { editor } = this.config.node;
      const node = editor.findNode(this.config.node.uuid)
      const parentNode = node.parent;
      this.$confirm(
        "This will permanently delete this element. Continue?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(() => {
        editor.removeNode(node);
        if ((node.componentName == 'Row' || node.props.component == 'Row') && parentNode?.props?.component == 'WrapperContainer') {
          const nodeMaps = Object.values(editor.nodeMap)
          const wrapper = nodeMaps.find(e => e.props.component == "WrapperContainer")
          const rowNodes = wrapper.children.filter(e => e.componentName == 'Row' || e.props?.component == 'Row')
          for (let i = 0; i < rowNodes.length; i++) {
            rowNodes[i].setProps({name: `Row ${i + 1}`})
          }
        }
      });
      this.$bus.emit("showContextMenu", null, null);
    },
  },
};
</script>
  
  <style scoped>
</style>