export const actionTypes = [
  {
    value: 'mouse_click',
    label: 'Mouse Click',
    description: 'Animate on mouse click'
  },
  {
    value: 'mouse_hover',
    label: 'Mouse Hover',
    description: 'Animate on mouse hover'
  },
  {
    value: 'mouse_leave',
    label: 'Mouse Leave',
    description: 'Animate when mouse leave the object'
  },
  {
    value: 'scroll_into_view',
    label: 'Scroll into view',
    description: 'Animate when the elements scroll into view or first appear',
  },
  {
    value: 'after_page_finish_loading',
    label: 'After page finish loading',
    description: 'Animate trigger after page load'
  },
]

export const animationTypes = [
  {
    value: 'none',
    label: 'None',
    directions: [],
  },
  {
    value: 'back',
    label: 'Back',
    directions: [
      { value: 'InLeft', label: 'Left In' },
      { value: 'InRight', label: 'Right In' },
      { value: 'InUp', label: 'Top In' },
      { value: 'InDown', label: 'Bottom In' },
      { value: 'OutLeft', label: 'Left Out' },
      { value: 'OutRight', label: 'Right Out' },
      { value: 'OutUp', label: 'Top Out' },
      { value: 'OutDown', label: 'Bottom Out' },
    ],
  },
  {
    value: 'bounce',
    label: '(E) Bounce',
    directions: [
      { value: 'InLeft', label: 'Left In' },
      { value: 'InRight', label: 'Right In' },
      { value: 'InUp', label: 'Top In' },
      { value: 'InDown', label: 'Bottom In' },
      { value: 'OutLeft', label: 'Left Out' },
      { value: 'OutRight', label: 'Right Out' },
      { value: 'OutUp', label: 'Top Out' },
      { value: 'OutDown', label: 'Bottom Out' },
    ],
  },
  {
    value: 'fade',
    label: 'Fade',
    directions: [
      { value: 'InLeft', label: 'Left In' },
      { value: 'InRight', label: 'Right In' },
      { value: 'InUp', label: 'Top In' },
      { value: 'InDown', label: 'Bottom In' },
      { value: 'OutLeft', label: 'Left Out' },
      { value: 'OutRight', label: 'Right Out' },
      { value: 'OutUp', label: 'Top Out' },
      { value: 'OutDown', label: 'Bottom Out' },
    ]
  },
  {
    value: 'flip',
    label: 'Flip',
    directions: [
      { value: '', label: 'None' },
      { value: 'InX', label: 'Flip In X' },
      { value: 'InY', label: 'Flip In Y' },
      { value: 'OutX', label: 'Flip Out X' },
      { value: 'OutY', label: 'Flip Out Y' },
    ]
  },
  {
    value: 'zoom',
    label: 'Zoom',
    directions: [
      { value: 'In', label: 'Zoom In' },
      { value: 'InDown', label: 'Zoom In Down' },
      { value: 'InLeft', label: 'Zoom In Left' },
      { value: 'InUp', label: 'Zoom In Up' },
      { value: 'InRight', label: 'Zoom In Right' },
      { value: 'Out', label: 'Zoom Out' },
      { value: 'OutDown', label: 'Zoom Out Down' },
      { value: 'OutLeft', label: 'Zoom Out Left' },
      { value: 'OutUp', label: 'Zoom Out Up' },
      { value: 'OutRight', label: 'Zoom Out Right' },
    ]
  },
  {
    value: 'slide',
    label: 'Slide',
    directions: [
      { value: 'InLeft', label: 'Left In' },
      { value: 'InRight', label: 'Right In' },
      { value: 'InUp', label: 'Top In' },
      { value: 'InDown', label: 'Bottom In' },
      { value: 'OutLeft', label: 'Left Out' },
      { value: 'OutRight', label: 'Right Out' },
      { value: 'OutUp', label: 'Top Out' },
      { value: 'OutDown', label: 'Bottom Out' },
    ]
  },
  {
    value: 'jackInTheBox',
    label: 'Jack In The Box',
    directions: []
  },
  {
    value: 'sbounce',
    label: '(S) Bounce',
    directions: []
  },
  {
    value: 'flash',
    label: 'Flash',
    directions: []
  },
  {
    value: 'pulse',
    label: 'Pulse',
    directions: []
  },
  {
    value: 'rubberBand',
    label: 'Rubber Band',
    directions: []
  },
  {
    value: 'shakeX',
    label: 'Shake X',
    directions: []
  },
  {
    value: 'shakeY',
    label: 'Shake Y',
    directions: []
  },
  {
    value: 'swing',
    label: 'Swing',
    directions: []
  },
  {
    value: 'jello',
    label: 'Jello',
    directions: []
  },
  {
    value: 'heartbeat',
    label: 'Heartbeat',
    directions: []
  },
]