var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-form',{attrs:{"label-position":"top","size":"","model":_vm.elementProps}},[_c('div',{attrs:{"label-position":"top","size":"mini","model":_vm.elementProps}},[_c('div',{staticClass:"d-flex flex-column py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Background Type")]),_c('ToggleButton',{attrs:{"primaryColor":_vm.primaryColor,"value":_vm.getValueLinked('background_type', _vm.elementProps[_vm.screen].background_type, 'image'),"changeValue":($event) => _vm.updateProps(_vm.screen, 'background_type', $event),"options":[
          {
            label: 'Image',
            value: 'image',
          },
          {
            label: 'Video',
            value: 'video',
          },
        ]}})],1),((_vm.getValueLinked('background_type', _vm.elementProps[_vm.screen].background_type, 'image')) == 'video')?_c('div',[_c('el-form-item',{attrs:{"label":"Weblink"}},[_c('el-input',{attrs:{"type":"textarea","rows":4,"value":_vm.getValueLinked('weblink', _vm.elementProps[_vm.screen].weblink),"resize":"none"},on:{"input":($event) => _vm.updateProps(_vm.screen, 'weblink', $event)}})],1),_c('div',{staticClass:"sgDesc mt-2"},[_vm._v(" Eg: "),_c('a',{staticClass:"reference"},[_vm._v("https://www.youtube.com/watch?v=OQBlWco72c4")])])],1):_vm._e(),((_vm.getValueLinked('background_type', _vm.elementProps[_vm.screen].background_type, 'image')) != 'video')?_c('div',[_c('div',{staticClass:"d-flex flex-column py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Background Image")]),_c('Upload',{attrs:{"value":_vm.getValueLinked('imgUrl', _vm.elementProps[_vm.screen].imgUrl),"changeValue":($event) => _vm.updateProps(_vm.screen, 'imgUrl', $event)}})],1),_c('div',{staticClass:"d-flex flex-column py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Image Source")]),_c('el-input',{staticClass:"mt-2",attrs:{"placeholder":"Link","value":_vm.getValueLinked('imgUrl', _vm.elementProps[_vm.screen].imgUrl),"size":"medium"},on:{"input":function($event){return _vm.updateProps(_vm.screen, 'imgUrl', $event)}}})],1)]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }