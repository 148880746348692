<template>
  <div label-position="top" size="mini" :model="elementProps">
    <div class="d-flex flex-column py-2">
      <span>Border Type</span>
      <TabIcon 
        class="mt-2"
        :value="getValue('border_style',elementProps[screen].border_style)"
        v-bind:changeValue="($event) => updateProps(screen, 'border_style', $event)"
        :options="[
          { value: 'none', icon: 'no_border',tooltip: 'No border' },
          { value: 'solid', icon: 'solid',tooltip: 'Solid' },
          { value: 'dashed', icon: 'dashed',tooltip: 'Dashed' },
          { value: 'dotted', icon: 'dotted',tooltip: 'Dotted' },
        ]"
        :canRemove="true"
        :primaryColor="primaryColor"
      />
    </div>
    <div class="d-flex flex-column py-2">
      <span>Border Color</span>
      <ColorPicker 
        :primaryColor="primaryColor"
        class="mt-2"
        :value="getValue('border_color',elementProps[screen].border_color)"
        v-bind:changeColor="($event) => updateProps(screen, 'border_color', $event)"
      />
    </div>
    <div class="d-flex flex-column py-2">
      <span>Border Width</span>
      <Dimension
        :units="['px']"
        unit="px"
        :primaryColor="primaryColor"
        :data="elementProps[screen]"
        :isPositive="true"
        :onlyInteger="true"
        :items="[
          {
            key: 'border_top_width',
            title: 'Top',
          },
          {
            key: 'border_right_width',
            title: 'Right',
          },
          {
            key: 'border_bottom_width',
            title: 'Bottom',
          },
          {
            key: 'border_left_width',
            title: 'Left',
          }
        ]"
        v-bind:changeValue="(name, $event) => updateProps(screen, name, $event)"
      />
    </div>
  </div>
</template>

<script>
import mixin from '../styleSettings/mixin';
import TabIcon from '../common/TabIcon.vue';
import ColorPicker from '../common/ColorPicker.vue';
import Slider from '../common/Slider.vue';
import Dimension from '../common/Dimension.vue';

export default {
  mixins: [mixin],
  components: {
    TabIcon,
    ColorPicker,
    Slider,
    Dimension
  },
  props: {
    screen: String,
    primaryColor: String,
  },
  methods: {
    updateProps(screen, name, value) {
      const data_screen = Object.assign({}, this.elementProps[screen])
      if(value ==  (data_screen[name] || 0)) return;
      data_screen[name] = value
      this.elementPropsSetter({ [screen]: data_screen });
    },
  }
};
</script>
