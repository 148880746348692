var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"label-position":"top","size":"mini","model":_vm.elementProps}},[_c('div',{staticClass:"d-flex align-items-center justify-content-between py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Equal Height")]),_c('el-switch',{attrs:{"value":_vm.elementProps.equal_height,"active-color":_vm.primaryColor,"inactive-color":"#949494","active-value":true,"inactive-value":false},on:{"change":function($event){return _vm.updateProps('equal_height', $event)}}})],1),_c('div',{staticClass:"mt-1",staticStyle:{"color":"#999999","font-size":"12px","font-style":"italic"}},[_vm._v(" If checked columns will be set to equal height. ")]),(_vm.elementProps.equal_height)?_c('div',{staticClass:"d-flex flex-column py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Vertical Align")]),_c('Select',{staticClass:"mt-2",attrs:{"primaryColor":_vm.primaryColor,"value":_vm.elementProps.vertical_align,"changeValue":($event) => _vm.updateProps('vertical_align', $event),"options":[
        {
          value: 'flex-start',
          label: 'Top'
        },
        {
          value: 'center',
          label: 'Middle'
        },
        {
          value: 'flex-end',
          label: 'Bottom'
        }
      ]}}),_c('div',{staticClass:"mt-1",staticStyle:{"color":"#999999","font-size":"12px","font-style":"italic"}},[_vm._v(" Vertical Align items inside Row ")])],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }