<template>
  <div label-position="top" size="" :model="elementProps">
    <div class="d-flex flex-column py-2">
      <span class="setting-title">Products per Row</span>
      <Select
        :value="getValue('products_per_row',elementProps[screen].products_per_row)"
        :primaryColor="primaryColor"
        class="mt-2"
        v-bind:changeValue="($event) => updateProps('products_per_row', $event)"
        :options="[
          {
            value: '1',
            label: '1',
          },
          {
            value: '2',
            label: '2',
          },
          {
            value: '3',
            label: '3',
          },
          {
            value: '4',
            label: '4',
          },
          {
            value: '6',
            label: '6',
          },
        ]"
      />
      <div class="sgDesc mt-2">
        Change products-per-row number sepearately in each screen size.
      </div>
    </div>
  </div>
</template>

<script>
import mixin from "../../../elements/styleSettings/mixin";
import Select from "../../../elements/common/Select.vue";

export default {
  mixins: [mixin],
  components: { Select },
  props: {
    screen: String,
    primaryColor: String,
  },
  methods: {
    // updateProps(name, value) {
    //   this.elementPropsSetter({ [name]: value });
    // },
    updateProps(name, value) {
      const screen = this.$props.screen
      const data_screen = Object.assign({}, this.elementProps[screen])
      if(value ==  data_screen[name]) return;
      data_screen[name] = value
      this.elementPropsSetter({ [screen]: data_screen });
    },
  },
};
</script>

<style scoped>
</style>
