var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"label-position":"top","size":"","model":_vm.elementProps}},[_c('div',{staticClass:"d-flex flex-column py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Products per Row")]),_c('Select',{staticClass:"mt-2",attrs:{"value":_vm.getValue('products_per_row',_vm.elementProps[_vm.screen].products_per_row),"primaryColor":_vm.primaryColor,"changeValue":($event) => _vm.updateProps('products_per_row', $event),"options":[
        {
          value: '1',
          label: '1',
        },
        {
          value: '2',
          label: '2',
        },
        {
          value: '3',
          label: '3',
        },
        {
          value: '4',
          label: '4',
        },
        {
          value: '6',
          label: '6',
        },
      ]}}),_c('div',{staticClass:"sgDesc mt-2"},[_vm._v(" Change products-per-row number sepearately in each screen size. ")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }