<template>
  <div label-position="top" size="mini" :model="elementProps">
    <div class="d-flex flex-column py-2">
      <span style="color: #AAAAAA; font-size: 12px;">Color</span>
      <ColorPicker
        class="mt-2"
        :value="elementProps.color"
        v-bind:changeColor="($event) => updateProps('color', $event)"
        :primaryColor="primaryColor"
      />
    </div>
  </div>
</template>

<script>
import mixin from "./mixin";
import ColorPicker from "../common/ColorPicker.vue";
export default {
  mixins: [mixin],
  components: {
    ColorPicker,
  },
  props: {
    primaryColor: String,
  },
  methods: {
    updateProps(key, value) {
      if(value ==  this.elementProps[name]) return;
      this.elementPropsSetter({ [key]: value })
    },
  },
};
</script>

<style scoped>
</style>
