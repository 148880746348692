
<template>
  <component
    class="sg-text"
    @click="setEditable(true)"
    @keyup="emitInput"
    @paste="handlePaste"
    :contenteditable="true"
    :is="tag"
    ref="editor"
  />
</template>

<script>
export default {
  props: {
    tag: String,
    value: String,
    id: String
  },
  inject: ["editor", "node"],
  data() {
    return {
      editable: false,
    };
  },
  mounted() {
    /*
    Different browser will use different strategy on creating a new line of text.
    In Chrome, it will wrap the new line with <div>,
    so we choose directly manipulating DOM instead of using vue binding
    to avoid the previous problem.

    For further details, please see the following link.
    https://developer.mozilla.org/en-US/docs/Web/Guide/HTML/Editable_content#Differences_in_markup_generation
    */
    this.updateDOMContent();
  },
  updated() {
    this.updateDOMContent();
  },
  watch: {
    value() {
      this.updateDOMContent();
    },
  },
  computed: {
    contenteditable() {
      return this.enabled && this.editable;
    },
    enabled() {
      return this.editor
        ? this.editor.enabled
        : true;
    },
  },
  methods: {
    updateDOMContent() {
      // To avoid that cursor jumps to start on typing.
      if (this.value !== this.$refs.editor.innerHTML) {
        this.$refs.editor.innerHTML = this.value;
      }
    },
    setEditable(bool) {
      this.editable = bool;
      this.$bus.emit("showToolbar", this.$refs, bool, this.node);
      this.$refs.editor.focus();
    },
    emitInput() {
      const value = this.$refs.editor.innerHTML;
      this.$emit('input', value);
      if (this.$props.id){
        this.$emit('input2', {
        id: this.id,
        value: value
      });
      }
      

      // console.log('input',value)
      
    },
    handlePaste(event) {
      event.preventDefault();

      // To paste plain text.
      var iframe = document.getElementById("designEditor")      
      iframe.contentWindow.document.execCommand("insertText", false,event.clipboardData.getData('text'));
    },
  },
};
</script>

<style scoped>
  .heading {
    position: relative;
  }
  .paper .editing {
    outline: 1px solid #fbb144 !important;
    box-shadow: 0 0 3px #fbb144;
  }
  </style>