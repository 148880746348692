<template>
  <div label-position="top" size="mini" :model="elementProps">
    <div class="d-flex flex-column py-2">
      <span class="setting-title">Style</span>
      <Select
        class="mt-2"
        :primaryColor="primaryColor"
        :value="elementProps.display_style"
        v-bind:changeValue="($event) => updateProps('display_style', $event)"
        :options="[
          {
            value: 'default',
            label: 'Default',
          },
          {
            value: 'inline',
            label: 'Inline',
          },
        ]"
      />
    </div>
    <div class="d-flex flex-column py-2" v-if="elementProps.display_style == 'inline'">
      <span class="setting-title">Label Width</span>
      <Slider
        class="mt-2"
        :primaryColor="primaryColor"
        :value="Number.parseInt(elementProps.label_width)"
        :min="50"
        :max="300"
        :step="1"
        v-bind:changeValue="($event) => updateProps('label_width', parseInt($event))"
        unit="px"
        :onlyInteger="true"
      />
    </div>
    <div class="d-flex flex-column py-2">
      <span class="setting-title">Columns</span>
      <Select
        class="mt-2"
        :primaryColor="primaryColor"
        :value="elementProps.columns"
        v-bind:changeValue="($event) => updateProps('columns', $event)"
        :options="[
          {
            value: '1',
            label: '1 Column',
          },
          {
            value: '2',
            label: '2 Columns',
          },
          {
            value: '3',
            label: '3 Columns',
          },
          {
            value: '4',
            label: '4 Columns',
          },
        ]"
      />
    </div>

    <div class="d-flex flex-column py-2">
      <span class="setting-title">Group By</span>
      <Select
        class="mt-2"
        :primaryColor="primaryColor"
        :value="elementProps.group_by"
        v-bind:changeValue="($event) => updateProps('group_by', $event)"
        :options="[
          {
            value: 'default',
            label: 'Default',
          },
          {
            value: 'separately',
            label: 'Separately',
          },
        ]"
      />
    </div>

    <div class="d-flex align-items-center justify-content-between py-2">
      <span class="setting-title">Blank Option</span>
      <el-switch
        :value="elementProps.blank_option"
        :active-color="primaryColor"
        inactive-color="#949494"
        @change="updateProps('blank_option', $event)"
      />
    </div>
    <div class="d-flex flex-column py-2" v-if="elementProps.blank_option">
      <span class="setting-title">Blank Option Text</span>
      <el-input
        :value="elementProps.blank_text"
        class="input-with-select mt-2"
        @input="updateProps('blank_text', $event)"
        size="medium"
      />
    </div>
  </div>
</template>

<script>
import mixin from "../../../elements/styleSettings/mixin";
import Select from "../../../elements/common/Select.vue";
import Slider from "../../../elements/common/Slider.vue";

export default {
  mixins: [mixin],
  components: {Select, Slider },
  props: {
    primaryColor: String
  },
  methods: {
    updateProps(name, value) {
      if(value ==  this.elementProps[name]) return;

      this.elementPropsSetter({ [name]: value });
    }
  },
};
</script>

<style scoped>
</style>
