var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-form',{attrs:{"label-position":"top","size":"","model":_vm.elementProps}},[_c('div',{staticClass:"d-flex flex-column py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Tab Style")]),_c('Select',{staticClass:"mt-2",attrs:{"primaryColor":_vm.primaryColor,"value":_vm.elementProps.tab_style,"changeValue":($event) => _vm.updateProps('tab_style', $event),"options":[
        {
          value: 'default',
          label: 'Default',
        },
        {
          value: 'underline',
          label: 'Underline',
        }
      ]}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }