<template>
  <div label-position="top" size="mini" :model="elementProps">
    <div class="d-flex align-items-center justify-content-between py-2">
      <span>Show on Desktop</span>
      <el-switch
        :value="elementProps['desktop'].display"
        :active-color="primaryColor"
        inactive-color="#949494"
        active-value="block"
        inactive-value="none"
        @change="updateProps('desktop', 'display', $event)"
        :disabled="elementProps.disabled"
      />
    </div>
    <div class="d-flex align-items-center justify-content-between py-2">
      <span>Show on Laptop</span>
      <el-switch
        :value="elementProps['laptop'].display"
        :active-color="primaryColor"
        inactive-color="#949494"
        active-value="block"
        inactive-value="none"
        @change="updateProps('laptop', 'display', $event)"
        :disabled="elementProps.disabled"
      />
    </div>
    <div class="d-flex align-items-center justify-content-between py-2">
      <span>Show on Tablet</span>
      <el-switch
        :value="elementProps['tablet'].display"
        :active-color="primaryColor"
        inactive-color="#949494"
        active-value="block"
        inactive-value="none"
        @change="updateProps('tablet', 'display', $event)"
        :disabled="elementProps.disabled"
      />
    </div>
    <div class="d-flex align-items-center justify-content-between py-2">
      <span>Show on Mobile</span>
      <el-switch
        :value="elementProps['mobile'].display"
        :active-color="primaryColor"
        inactive-color="#949494"
        active-value="block"
        inactive-value="none"
        @change="updateProps('mobile', 'display', $event)"
        :disabled="elementProps.disabled"
      />
    </div>
  </div>
</template>

<script>
import mixin from '../styleSettings/mixin';
export default {
  mixins: [mixin],
  props: {
    primaryColor: String,
  },
  methods: {
    updateProps(screen, name, value) {
      const screens = ['desktop', 'laptop', 'tablet', 'mobile']
      const displays = screens.map(e => e == screen ? value : this.elementProps[e].display)
      const valid = displays.every(e => e == 'none')
      if (valid) {
        window.confirm("You can't hide this element in all screens, better to delete it.")
        return
      }
      const data_screen = Object.assign({}, this.elementProps[screen])
      if(value ==  data_screen[name]) return;
      data_screen[name] = value
      this.elementPropsSetter({ [screen]: data_screen });
    },
  }
};
</script>
