


function checkIfWindows() {
    return navigator.platform.indexOf("Win") > -1;
}
function checkIfMac() {
    return navigator.platform.indexOf("Mac") > -1;
}

function shortcuts(event, editor) {
    if (!checkIfWindows() && checkIfMac() && !event.metaKey) {
        return;
    }
    if (!checkIfMac() && checkIfWindows() && !event.ctrlKey) {
        return;
    }
    if (event.shiftKey) {
        switch (event.key) {
            case "z":
                if (editor.canRedo) {
                    editor.redo();
                    event.preventDefault();
                    event.stopPropagation()
                }
                break;
        }
    } else {
        switch (event.key) {
            case "z":
                if (editor.canUndo) {
                    editor.undo();
                    event.preventDefault();
                    event.stopPropagation()
                }
                break;
        }
    }

}
export default shortcuts;

