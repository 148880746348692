var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"label-position":"top","size":"","model":_vm.elementProps}},[_c('div',{staticClass:"d-flex flex-column py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v(_vm._s(`${_vm.node?.componentName|| ''} Link`))]),_c('Input',{staticClass:"mt-2",attrs:{"value":_vm.elementProps.href,"changeValue":($event) => _vm.updateProps('href', $event),"primaryColor":_vm.primaryColor,"placeholder":"https://, #, ..."}})],1),_c('div',{staticClass:"d-flex gap-2 align-items-center button-pick-link",on:{"click":_vm.pickLink}},[_c('svg',{attrs:{"width":"16","height":"16","viewBox":"0 0 14 14","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M5.59413 10.4727L5.09706 10.9698V10.9698C4.1598 11.9071 2.6402 11.9071 1.70294 10.9698C0.765685 10.0325 0.765685 8.51295 1.70294 7.57569L4.24853 5.02957V5.02957C5.18472 4.09125 6.70431 4.08954 7.64263 5.02573C8.47934 5.86056 8.58367 7.1803 7.88852 8.13622","stroke":"#d6d6d6","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{attrs:{"d":"M8.50324 3.57511L8.90324 3.17511V3.17511C9.8405 2.23785 11.3601 2.23785 12.2974 3.17511C13.2346 4.11237 13.2346 5.63197 12.2974 6.56923L9.75177 9.11482V9.11482C8.81528 10.0528 7.29569 10.0541 6.35767 9.11758C5.52143 8.28269 5.41734 6.96332 6.11232 6.00763","stroke":"#d6d6d6","stroke-linecap":"round","stroke-linejoin":"round"}})]),_vm._v(" Pick Link ")]),_c('div',{staticClass:"d-flex flex-column py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Open link in")]),_c('Select',{staticClass:"mt-2",attrs:{"primaryColor":_vm.primaryColor,"value":_vm.elementProps.target,"changeValue":($event) => _vm.updateProps('target', $event),"options":[
        {
          value: '_self',
          label: 'Current Tab',
        },
        {
          value: '_blank',
          label: 'New Tab',
        },
      ]}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }