<template>
  <div
    class="sg-pricing-item d-flex flex-column"
    :id="`element-item-${node.uuid}`"
  >
    <div class="pricing-item-heading">
      {{ item?.heading }}
    </div>
    <div class="pricing-item-body text-center">
      <span class="currency">{{ node.parent?.props?.currency }}</span>
      <span class="money">{{ item?.price }}</span>
      <span class="plan">/{{ item?.plan }}</span>
    </div>
    <div class="pricing-item-footer">
      <div v-if="node.children?.length == 0" class="d-flex align-items-center justify-content-center">
        <div class="text-center drop-element">
          <span>Add element</span>
        </div>
      </div>
      <slot />
    </div>
  </div>
</template>

<script>
import elementStyleMixin from '../../elements/elementStyleMixin';
import ToolBar from '../../elements/utils/ToolBar.vue';

export default {
  components: {ToolBar},
  mixins: [elementStyleMixin],
  inject: [
    'node',
    'editor'
  ],
  props: {
    component:String,
    
    item: Object,
  },
  data() {
    return {
      isHovering: false,
    }
  },
  craft: {
    defaultProps: {
      elementStyle: {

      },
    },
    settings: {
      settings: {
      },
      design: {
      }
    },
  },
};
</script>

<style lang="scss" scoped>

.dragging{
  position:absolute;
  top:0;
  bottom: 0;
  left: 0;
  right:0;
  background: greenyellow;
  z-index: 10;
}

</style>
