import { render, staticRenderFns } from "./IconSpacing.vue?vue&type=template&id=992ff2dc&scoped=true&"
import script from "./IconSpacing.vue?vue&type=script&lang=js&"
export * from "./IconSpacing.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "992ff2dc",
  null
  
)

export default component.exports