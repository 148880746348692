<template>
  <div label-position="top" size="medium" :model="elementProps">
    <div class="d-flex align-items-center justify-content-between py-2">
      <span class="sgSubTitle">Autoplay</span>
      <el-switch
        :value="elementProps.auto_play"
        :active-color="primaryColor"
        inactive-color="#949494"
        @change="updateProps('auto_play', $event)"
      />
    </div>
    <div class="sgDesc mt-0">
      Auto play Carousel. Better enable 'Loop' option above as well. *Works on
      your live page*
    </div>
    <div class="d-flex flex-column py-2">
      <span class="setting-title">Autoplay Timeout</span>
      <Input 
        class="mt-2"
        type="number"
        :value="String(elementProps.autoplayTimeout)"
        v-bind:changeValue="($event) => updateProps('autoplayTimeout', Number.parseInt($event))"
        :primaryColor="primaryColor"
      />
      <div class="sgDesc mt-2">Auto play Timeout in miliseconds. Eg: 3000</div>
    </div>

    <div class="d-flex align-items-center justify-content-between py-2" v-if="elementProps.auto_play">
      <span class="sgSubTitle">Autoplay Hover Pause</span>
      <el-switch
        :value="elementProps.hover_pause"
        :active-color="primaryColor"
        inactive-color="#949494"
        @change="updateProps('hover_pause', $event)"
      />
    </div>
    <div class="sgDesc mt-0" v-if="elementProps.auto_play">Pause autoplay Carousel when mouseover.</div>
  </div>
</template>

<script>
import mixin from "../../../elements/styleSettings/mixin";
import Slider from "../../../elements/common/Slider.vue";
import Input from "../../../elements/common/Input.vue";

export default {
  mixins: [mixin],
  components: { Slider, Input },
  props: {
    primaryColor: String
  },
  methods: {
    updateProps(name, value) {
      if(value ==  this.elementProps[name]) return;
      this.elementPropsSetter({ [name]: value });
    },
  },
};
</script>

<style scoped>
</style>
