<template>
  <div 
    class="d-flex align-items-center custom-picker"
    ref="myPickColor"
    :style="cssVars"
    :class="show ? 'show' : ''"
    @click="togglePicker"
  >
    <el-input 
      :value="colorValue" 
      @input="onChangeValue" 
      size="medium"
      @blur="resetValue"
      class="color-input-value"
    >
      <template v-slot:prefix>
        <div class="prefix-wrapper">
          <div class="irrs-wrapper">
            <span
              class="irrs"
              :style="{
                border: `1px solid ${value?.includes('rgb')? value : 'transparent'}`,
                background: `${value?.includes('rgb')? value : 'transparent'}`,
              }"
            ></span>
          </div>
        </div>
      </template>
      <el-input
        class="input-append custom-input"
        slot="append"
        type="number"
        size="medium"
        :value="alpha"
        @input="changeInputAlpha(Number.parseInt($event))"
      >
        <div slot="suffix" class="text-suffix">%</div>
      </el-input>
    </el-input>

    <div
      :class="`panel-edit-color ${show ? 'show' : ''}`"
      ref="myDiv"
      :style="styles_md"
    >
      <color-picker
        v-if="show"
        theme="dark"
        :style="styles"
        :color="!value ? 'transparent' : value"
        :sucker-hide="false"
        @changeColor="(color) => changeColorPicker(color)"
        :colorsDefault="colorsDefault"
      />

      <div :class="`v-popper__arrow-container ${placement}`">
        <div class="v-popper__arrow-outer"></div>
        <div class="v-popper__arrow-inner"></div>
      </div>
    </div>
  </div>
</template>

<script>
import colorPicker from "@caohenghu/vue-colorpicker";

export default {
  data() {
    return {
      colorValue: "",
      show: false,
      styles: {
        width: "220px",
        background: "#555",
      },
      placement: "top",
      styles_md: {},
      colorsDefault: [],
      alpha: 0,
      timer: null
    };
  },
  mounted() {
    this.colorValue = this.rgbToHex(this.value);
    this.alpha = this.alphaValue(this.value);
    document.addEventListener('click', this.handleClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside);
  },
  watch: {
    show() {
      if (this.show) {
        this.styles_md = this.elementCSS();
      }
    },
    value(newValue, oldValue) {
      this.colorValue = this.rgbToHex(newValue);
    }
  },
  computed: {
    cssVars() {
      return {
        '--primary-color': this.primaryColor || '#0060e5'
      }
    }
  },
  components: {
    colorPicker,
  },
  props: {
    value: String,
    changeColor: Function,
    primaryColor: String
  },
  methods: {
    togglePicker() {
      this.show = !this.show;
    },
    alphaValue(color) {
      try {
        var alpha = color.match(/[\d.]+/g)[3];
        if (isNaN(alpha)) {
          alpha = "";
        } else {
          alpha = parseFloat(alpha) * 100;
        }
      } catch (error) {
        var alpha = "";
      }

      return alpha;
    },
    hexToRgbA(hex) {
      try {
        var c;
        if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
          c = hex.substring(1).split("");
          if (c.length == 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
          }
          c = "0x" + c.join("");
          return (
            "rgba(" +
            [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
            ",1)"
          );
        }
        return hex;
      } catch (error) {
        // console.log(error, "error");
        return hex;
      }
    },
    rgbToHex(c) {
      try {
        if (!c.includes("rgb")) return 'none';
      if (c == "none" || !c) return "none";
      return (
        "#" +
        c.match(/\d+/g).map((x) => (+x).toString(16).padStart(2, 0)).join``
          .toUpperCase()
          .substring(0, 6)
      );
      } catch (error) {
        return 'none'
      }
      
    },
    changeInputAlpha($event) {
      var value = Math.floor($event)
      this.alpha = value;
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        if (value < 0){
          this.alpha = 0;
          this.changeColorAlpha(0)
        } else if (value > 100){
          this.alpha = 100;
          this.changeColorAlpha(100)
        } else if (isNaN(value)){
          this.alpha = "";
          this.changeColorAlpha(100)
        } else {
          this.changeColorAlpha(value)
        }
      }, 1000);
    },
    changeColorAlpha(event) {
      var alpha = event;
      if (isNaN(alpha) || alpha == NaN) {
        alpha = "";
      } else {
        alpha = parseFloat(alpha) / 100;
      }
      if (!this.$props.value.includes('rgb')) {
        this.$props.changeColor(`rgba(0,0,0,${alpha})`);
        this.colorValue = '#000000'
      } else {
        const rgb_arr = this.$props.value.replace(/[^\d,]/g, '').split(',');
        this.$props.changeColor(`rgba(${rgb_arr[0]}, ${rgb_arr[1]}, ${rgb_arr[2]}, ${alpha})`)
        // this.$props.changeColor(this.$props.value.replace(/[\d.]+(?=\))/, alpha).replace('NaN',alpha));
      }
    },
    changeColorPicker(color) {
      const { r, g, b, a } = color.rgba;
      this.colorValue =  color.hex;
      this.alpha = Number.parseInt(a * 100);
      this.$props.changeColor(`rgba(${r},${g},${b},${a})`);
    },
    onChangeValue(event) {
      this.colorValue = event
      const color_ = this.hexToRgbA(event);
      this.$props.changeColor(color_);
    },
    resetValue(){
      this.colorValue = this.rgbToHex(this.value);
    },
    clearValue(e) {
      e.stopPropagation();
      this.$props.changeColor(null);
    },
    elementCSS: function () {
      // const windowContent = this.iframe.$el.contentWindow;
      const style = {};
      // const top = this.toolbar?.getBoundingClientRect().top;
      // const height_md = 417;
      // if (windowContent.innerHeight - height_md <= top) {
      //   style["bottom"] = `calc(100% + 8px)`;
      // } else {
      style["top"] = `calc(100% + 8px)`;
      // }
      style["left"] = "calc(50% - 110px)";
      return style;
    },
    handleClickOutside(event) {
      if (!this.$refs.myPickColor.contains(event.target)) {
        // Clicked outside the target element
        this.show = false
      }
    }
  },
};
</script>

<style>
.custom-picker {
  width: 100%;
  position: relative;
}

.custom-picker.show .el-input__inner {
  border-color: var(--primary-color) !important;
}

.custom-picker .el-input-group__append {
  padding: 0;
}
.input-append .el-input__inner {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  margin-left: -1px;
  padding-left: 10px;
  padding-right: 0px;
  width: 50px;
}
.input-append .el-input__suffix {
  display: flex;
  align-items: center;
}
.custom-picker .prefix-wrapper {
  width: fit-content;
  height: 100%;
  display: flex;
  align-items: center;
}

.custom-picker .irrs-wrapper {
  background: url("https://api-onepage.salegate.io/static/icons/transparent-color.svg");
  width: 16px;
  height: 16px;
  border-radius: 6px;
  margin-right: 5px;
  margin-left: 5px;
}

.custom-picker .irrs-wrapper span.irrs {
  width: 100%;
  height: 100%;
  border-radius: 3px;
  margin-right: 8px;
  overflow: hidden;
  float: left;
  cursor: pointer;
  border: 0.5px solid #ccc !important;
}

.custom-color-picker {
  width: 100%;
}
.custom-color-picker .el-color-picker__trigger {
  width: 100%;
  border: 1px solid #717171;
  z-index: 2;
  padding: 9px;
  background-color: #222222;
}

.custom-color-picker .el-color-picker__trigger .el-color-picker__color {
  width: unset;
  aspect-ratio: 1/1;
}
.custom-color-picker .el-color-picker__trigger .el-color-picker__icon {
  display: none;
}

.custom-picker .color-value {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 40px;
  color: #fff;
  z-index: 2;
}

.custom-picker .close-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  color: #fff;
  z-index: 2;
  cursor: pointer;
}

.custom-color-picker.transparent .el-color-picker__color-inner {
  background: url("../../../../../assets/images/transparent.svg") !important;
}

.panel-edit-color {
  /* width: 100px; */
  height: fit-content;
  position: absolute;
  z-index: 10;
  background: #343b3e;
  box-shadow: 0 2px 2px 0 rgb(67 67 67 / 50%);
  border-radius: 3px;
  visibility: hidden;
  user-select: none;
}
.panel-edit-color.show {
  visibility: visible;
}
.panel-content {
  width: fit-content;
}

.v-popper__arrow-container {
  position: absolute;
  width: 10px;
  height: 10px;
}

.v-popper__arrow-container.top {
  top: -12px;
  left: calc(50% - 6px);
}

.v-popper__arrow-container.bottom {
  bottom: -10px;
  left: calc(50% - 6px);
}

.v-popper__arrow-outer {
  top: -6px;
}
.v-popper__arrow-inner,
.v-popper__arrow-outer {
  border-left-width: 0;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  border-bottom-color: #555 !important;
}

.bottom .v-popper__arrow-inner,
.bottom .v-popper__arrow-outer {
  border-left-width: 6px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  border-top-color: #555 !important;
}

.v-popper__arrow-outer,
.v-popper__arrow-outer {
  top: -1px;
}
.v-popper__arrow-outer {
  border-color: #343b3e;
}
.v-popper__arrow-outer {
  border-width: 6px;
}
.v-popper__arrow-inner,
.v-popper__arrow-outer {
  border-style: solid;
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
}

.v-popper__arrow-inner {
  top: -4px;
}

.v-popper__arrow-inner,
.v-popper__arrow-inner {
  top: -2px;
}
.v-popper--theme-dropdown .v-popper__arrow-inner {
  visibility: visible;
  border-color: #343b3e;
}
.v-popper__arrow-inner {
  visibility: hidden;
  border-width: 7px;
}
.v-popper__arrow-inner,
.v-popper__arrow-outer {
  border-style: solid;
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
}
</style>