<template>
  <div class="setting-page">
    <TitlePage :title="title" :subTitle="subTitle" :page="page"></TitlePage>
    <div class="my-2"></div>
    <div class="sg-settings-page">
      <div class="sg-settings-page__sidebar">
        <ul class="sg-settings-page__sidebar-nav">
          <li>
            <a href="javascript:void(0)" class="active"> Store Settings </a>
          </li>
          <li>
            <a href="javascript:void(0)" class=""> Plan Information </a>
          </li>
          <li>
            <a href="javascript:void(0)" class=""> Profile Settings </a>
          </li>
          <li>
            <a href="javascript:void(0)" class=""> Email Notification </a>
          </li>
          <li>
            <a href="javascript:void(0)" class=""> Data Privacy </a>
          </li>
          <li>
            <a href="javascript:void(0)" class=""> Add Code To Theme </a>
          </li>
        </ul>
      </div>
      <div class="sg-settings-page__content">
        <section id="store_settings" class="mb-5">
          <div class="sg-card">
            <div class="sg-card__head">
              <h3 class="sg-card__head-title">Store Settings</h3>
            </div>
            <div class="sg-card__body">
              <form>
                <div class="form-group">
                  <label class="label d-flex align-items-center mb-2">
                    <span>Storefront Password</span>
                    <div class="pr-1 ml-1 mr-1 d-flex">
                      <i
                        class="
                          icon icon-info-sign
                          tip
                          typo-icon-14
                          text-light-disabled
                        "
                      >
                      </i>
                    </div>
                  </label>
                  <div class="form-group password-field">
                    <div class="d-flex">
                      <div class="password-field-input">
                        <el-input
                          class=""
                          size="large"
                          placeholder="Input your Shopify store password"
                        >
                          <template #append>
                            <el-button icon="el-icon-view" />
                          </template>
                        </el-input>
                        <div title="Click to Show Password" class="toggle-view">
                          <i class="icon icon-view"></i>
                        </div>
                      </div>
                      <div class="password-field-btn ml-1">
                        <button
                          
                          type="submit"
                          disabled="disabled"
                          class="btn ml-0 btn-secondary"
                        >
                          Save Password
                        </button>
                      </div>
                    </div>
                  </div>
                  <!---->
                  <p
                    class="
                      typo-body4 typo-medium
                      text-light-low
                      typo-italic
                      mt-1
                      pwd
                    "
                  >
                    If your storefront is password protected, entering it here
                    will enable us to show your theme in the Gempages editor.
                    You can go to
                    <a
                      class="link"
                      target="_blank"
                      href="https://vuacasutu.myshopify.com/admin/online_store/preferences"
                    >
                      Preferences Settings
                    </a>
                    to get your password.
                  </p>
                </div>
                <div class="gt-line"></div>
                <div>
                  <div>
                    <p class="typo-button14 typo-semibold text-light-high">
                      Analytics Tools
                    </p>
                  </div>
                  <form>
                    <div class="analytic-tab mt-4">
                      <div class="switcher p-0 bg-light1 clickable">
                        <el-switch
                          active-color="#3c67ff"
                          inactive-color="#949494"
                          active-value="block"
                          inactive-value="none"
                          class="mr-4"
                        />
                        <div class="switcher-desc">
                          <div class="d-flex">
                            <div class="switcher-info flex-grow-1">
                              <p
                                class="
                                  typo-paragraph14 typo-medium
                                  text-light-high
                                  mb-half
                                  noselect
                                "
                              >
                                Google Analytics
                              </p>
                              <p
                                class="
                                  typo-paragraph12 typo-medium
                                  text-light-low
                                "
                              >
                                Turn on the switch and fill in the fields below
                                to connect with your Google Analytics account.
                                <a
                                  class="rl"
                                  href="https://help.gempages.net/articles/how-to-connect-your-A-B-testing-campaigns-to-google-analytics"
                                  target="_blank"
                                  >Instructions</a
                                >
                              </p>
                            </div>
                            <svg
                              width="32"
                              height="32"
                              viewBox="0 0 32 32"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M31.983 16.2994C31.983 14.9884 31.8741 14.0317 31.6385 13.0396H16.3186V18.9569H25.3111C25.1298 20.4274 24.1508 22.642 21.9751 24.1301L21.9446 24.3282L26.7885 27.9952L27.1241 28.0279C30.2062 25.2463 31.983 21.1537 31.983 16.2994Z"
                                fill="#4285F4"
                              ></path>
                              <path
                                d="M16.3188 31.8902C20.7243 31.8902 24.4228 30.4728 27.1243 28.0279L21.9753 24.1301C20.5974 25.0691 18.7481 25.7246 16.3188 25.7246C12.0039 25.7246 8.34162 22.9432 7.03613 19.0986L6.84477 19.1145L1.80806 22.9236L1.74219 23.1026C4.4254 28.3113 9.93695 31.8902 16.3188 31.8902Z"
                                fill="#34A853"
                              ></path>
                              <path
                                d="M7.03611 19.0987C6.69164 18.1066 6.49229 17.0435 6.49229 15.9451C6.49229 14.8466 6.69164 13.7837 7.01799 12.7915L7.00886 12.5803L1.90901 8.70996L1.74215 8.78752C0.636269 10.949 0.00170898 13.3762 0.00170898 15.9451C0.00170898 18.5141 0.636269 20.9412 1.74215 23.1026L7.03611 19.0987Z"
                                fill="#FBBC05"
                              ></path>
                              <path
                                d="M16.3188 6.16537C19.3828 6.16537 21.4496 7.45869 22.6281 8.53948L27.2331 4.14571C24.4049 1.57679 20.7244 0 16.3188 0C9.93699 0 4.42542 3.57875 1.74219 8.78742L7.01803 12.7915C8.34165 8.94693 12.0039 6.16537 16.3188 6.16537Z"
                                fill="#EB4335"
                              ></path>
                            </svg>
                          </div>
                          <!---->
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div class="gt-line"></div>
                <div class="setting other-settings">
                  <div>
                    <p class="typo-button14 typo-semibold text-light-high">
                      Other Settings
                    </p>
                  </div>
                  <div class="mt-4">
                    <div class="switcher p-0 bg-light1 clickable inline">
                      <el-switch
                        active-color="#3c67ff"
                        inactive-color="#949494"
                        active-value="block"
                        inactive-value="none"
                        class="mr-4"
                      />
                      <div class="switcher-desc">
                        <p
                          class="
                            typo-paragraph14 typo-medium
                            text-light-high
                            mb-half
                            noselect
                          "
                        >
                          Global Rich Snippets
                        </p>
                        <p class="typo-paragraph12 typo-medium text-light-low">
                          Turn on/off the product's Rich Snippets on all of your
                          pages
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="mt-4">
                    <div class="switcher p-0 bg-light1 clickable inline">
                      <el-switch
                        active-color="#3c67ff"
                        inactive-color="#949494"
                        active-value="block"
                        inactive-value="none"
                        class="mr-4"
                      />
                      <div class="switcher-desc">
                        <p
                          class="
                            typo-paragraph14 typo-medium
                            text-light-high
                            mb-half
                            noselect
                          "
                        >
                          Load only GemPages' script
                        </p>
                        <p class="typo-paragraph12 typo-medium text-light-low">
                          Turn on this option to edit pages faster &amp;
                          minimize errors in the Editor
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="mt-4">
                    <div class="switcher p-0 bg-light1 clickable inline">
                      <el-switch
                        active-color="#3c67ff"
                        inactive-color="#949494"
                        active-value="block"
                        inactive-value="none"
                        class="mr-4"
                      />
                      <div class="switcher-desc">
                        <p
                          class="
                            typo-paragraph14 typo-medium
                            text-light-high
                            mb-half
                            noselect
                          "
                        >
                          Preload Font and Stylesheet
                        </p>
                        <p class="typo-paragraph12 typo-medium text-light-low">
                          Turn on this option to increase the page loading
                          speed. Existing pages will require re-publishing.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
        <section id="plan_information" class="mb-5">
          <div class="sg-card plan-info">
            <div class="sg-card__head">
              <h3 class="sg-card__head-title">Plan Information</h3>
            </div>
            <div class="sg-card__body">
              <div class="block d-flex align-items-center">
                <div class="icon mr-2">
                  <svg
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M29.7227 14.0273C24.353 8.65757 15.647 8.65757 10.2773 14.0273C4.90758 19.397 4.90757 28.103 10.2773 33.4727C15.647 38.8424 24.353 38.8424 29.7227 33.4727C35.0924 28.103 35.0924 19.397 29.7227 14.0273ZM31.4899 12.259C25.1439 5.91349 14.8554 5.91368 8.50952 12.2595ZM31.4899 12.259C37.8359 18.605 37.8365 28.8945 31.4904 35.2404C25.1444 41.5865 14.8555 41.5865 8.50952 35.2404C2.1635 28.8944 2.16349 18.6055 8.50952 12.2595"
                      fill="#06E884"
                    ></path>
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M34.0172 8.48282C34.5054 8.97097 34.5054 9.76243 34.0172 10.2506L30.8839 13.3839C30.3957 13.8721 29.6043 13.8721 29.1161 13.3839C28.628 12.8958 28.628 12.1043 29.1161 11.6161L32.2494 8.48282C32.7376 7.99466 33.5291 7.99466 34.0172 8.48282Z"
                      fill="#06E884"
                    ></path>
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M31.6161 7.86612C32.1043 7.37796 32.8957 7.37796 33.3839 7.86612L34.6339 9.11612C35.122 9.60427 35.122 10.3957 34.6339 10.8839C34.1457 11.372 33.3543 11.372 32.8661 10.8839L31.6161 9.63388C31.128 9.14573 31.128 8.35427 31.6161 7.86612Z"
                      fill="#06E884"
                    ></path>
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M20 0C20.6904 0 21.25 0.559644 21.25 1.25V8.75C21.25 9.44036 20.6904 10 20 10C19.3096 10 18.75 9.44036 18.75 8.75V1.25C18.75 0.559644 19.3096 0 20 0Z"
                      fill="#06E884"
                    ></path>
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M15 1.25C15 0.559644 15.5596 0 16.25 0H23.75C24.4404 0 25 0.559644 25 1.25C25 1.94036 24.4404 2.5 23.75 2.5H16.25C15.5596 2.5 15 1.94036 15 1.25Z"
                      fill="#06E884"
                    ></path>
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M12.912 17.1561C13.4242 16.6932 14.2146 16.7332 14.6775 17.2454L20.9275 24.1621C21.3903 24.6743 21.3503 25.4648 20.8381 25.9276C20.3259 26.3905 19.5354 26.3504 19.0726 25.8382L12.8226 18.9216C12.3597 18.4093 12.3997 17.6189 12.912 17.1561Z"
                      fill="#06E884"
                    ></path>
                  </svg>
                </div>
                <div class="content">
                  <p class="typo-paragraph14 typo-medium text-lightmode">
                    <span class=""> Trial Plan </span>
                  </p>
                  <p class="typo-paragraph14 typo-medium mt-1">
                    <span class="text-mono3 clickable"> See plans</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="profile_settings" class="mb-3">
          <div class="sg-card">
            <div class="sg-card__head">
              <h3 class="sg-card__head-title">Profile Settings</h3>
            </div>
            <div class="sg-card__body">
              <div class="setting">
                <div class="form-group"><!----></div>
              </div>
              <!---->
              <form>
                <div class="setting-block">
                  <div class="form-group">
                    <label class="label d-flex align-items-center"
                      ><span>Profile Information</span>
                      <div class="pr-1 ml-1 mr-1 d-flex">
                        <i
                          class="
                            icon icon-info-sign
                            tip
                            typo-icon-14
                            text-light-disabled
                          "
                        ></i></div
                    ></label>
                    <div class="shop-info p-4">
                      <h3 class="typo-button14 typo-medium text-lightmode">
                        Hanoi Pho
                      </h3>
                      <div class="typo-button14 text-lightmode mt-1">
                        phandev92@gmail.com
                      </div>
                    </div>
                  </div>
                  <div class="form-group mt-4">
                    <label class="label d-flex align-items-center"
                      ><span>Shopify Domain</span>
                      <div class="pr-1 ml-1 mr-1 d-flex">
                        <i
                          class="
                            icon icon-info-sign
                            tip
                            typo-icon-14
                            text-light-disabled
                          "
                        ></i></div
                    ></label>
                    <div class="shop-info p-4">
                      <h3 class="typo-button14 typo-medium text-lightmode">
                        vuacasutu
                      </h3>
                      <a
                        target="_blank"
                        href="https://vuacasutu.myshopify.com"
                        class="
                          link
                          typo-paragraph14 typo-medium
                          mt-1
                          d-inline-block
                        "
                      >
                        vuacasutu.myshopify.com
                      </a>
                    </div>
                  </div>
                  <div class="gt-line"></div>
                  <!---->
                  <div class="form-group mt-4">
                    <label class="label">Language</label>
                    <el-select
                      v-model="value"
                      class="m-2"
                      placeholder="Select"
                      size="large"
                    >
                      <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                  </div>
                  <div class="v-portal" style="display: none"></div>
                </div>
              </form>
            </div>
          </div>
        </section>

        <section id="email_notification" class="mb-3">
          <div class="sg-card">
            <div class="sg-card__head">
              <h3 class="sg-card__head-title">Email Notification</h3>
            </div>
            <div class="sg-card__body">
              <form>
                <div class="email-tab">
                  <div>
                    <p class="typo-button14 typo-semibold text-lightmode">
                      Select the types of email you would like to receive from
                      us
                    </p>
                  </div>
                  <!---->
                  <div>
                    <div class="mt-4">
                      <div class="switcher p-0 bg-light1 clickable inline">
                        <el-switch
                          active-color="#3c67ff"
                          inactive-color="#949494"
                          active-value="block"
                          inactive-value="none"
                          class="mr-4"
                        />
                        <div class="switcher-desc">
                          <p
                            class="
                              typo-paragraph14 typo-medium
                              text-light-high
                              mb-half
                              noselect
                            "
                          >
                            Theme Change
                          </p>
                          <p
                            class="typo-paragraph12 typo-medium text-light-low"
                          >
                            We’ll send updates when your theme is changed
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="mt-4">
                      <div class="switcher p-0 bg-light1 clickable inline">
                        <el-switch
                          active-color="#3c67ff"
                          inactive-color="#949494"
                          active-value="block"
                          inactive-value="none"
                          class="mr-4"
                        />
                        <div class="switcher-desc">
                          <p
                            class="
                              typo-paragraph14 typo-medium
                              text-light-high
                              mb-half
                              noselect
                            "
                          >
                            Blog &amp; Newsletters
                          </p>
                          <p
                            class="typo-paragraph12 typo-medium text-light-low"
                          >
                            Industry insights, case studies and pro tips
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="mt-4">
                      <div class="switcher p-0 bg-light1 clickable inline">
                        <el-switch
                          active-color="#3c67ff"
                          inactive-color="#949494"
                          active-value="block"
                          inactive-value="none"
                          class="mr-4"
                        />
                        <div class="switcher-desc">
                          <p
                            class="
                              typo-paragraph14 typo-medium
                              text-light-high
                              mb-half
                              noselect
                            "
                          >
                            Product Updates &amp; Announcements
                          </p>
                          <p
                            class="typo-paragraph12 typo-medium text-light-low"
                          >
                            GemPages’ latest features, integrations and
                            improvements
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="mt-4">
                      <div class="switcher p-0 bg-light1 clickable inline">
                        <el-switch
                          active-color="#3c67ff"
                          inactive-color="#949494"
                          active-value="block"
                          inactive-value="none"
                          class="mr-4"
                        />
                        <div class="switcher-desc">
                          <p
                            class="
                              typo-paragraph14 typo-medium
                              text-light-high
                              mb-half
                              noselect
                            "
                          >
                            Partners’ Promotions
                          </p>
                          <p
                            class="typo-paragraph12 typo-medium text-light-low"
                          >
                            Exclusive deals from GemPages’ strategic partners
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="mt-4">
                      <div class="switcher p-0 bg-light1 clickable inline">
                        <el-switch
                          active-color="#3c67ff"
                          inactive-color="#949494"
                          active-value="block"
                          inactive-value="none"
                          class="mr-4"
                        />
                        <div class="switcher-desc">
                          <p
                            class="
                              typo-paragraph14 typo-medium
                              text-light-high
                              mb-half
                              noselect
                            "
                          >
                            Onboarding
                          </p>
                          <p
                            class="typo-paragraph12 typo-medium text-light-low"
                          >
                            Getting started guide to get you familiar with
                            GemPages
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="mt-4">
                      <div class="switcher p-0 bg-light1 clickable inline">
                        <el-switch
                          active-color="#3c67ff"
                          inactive-color="#949494"
                          active-value="block"
                          inactive-value="none"
                          class="mr-4"
                        />
                        <div class="switcher-desc">
                          <p
                            class="
                              typo-paragraph14 typo-medium
                              text-light-high
                              mb-half
                              noselect
                            "
                          >
                            Feedback &amp; Research
                          </p>
                          <p
                            class="typo-paragraph12 typo-medium text-light-low"
                          >
                            Occasional product survey to better your experience
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="mt-4">
                      <div class="switcher p-0 bg-light1 clickable inline">
                        <el-switch
                          active-color="#3c67ff"
                          inactive-color="#949494"
                          active-value="block"
                          inactive-value="none"
                          class="mr-4"
                        />
                        <div class="switcher-desc">
                          <p
                            class="
                              typo-paragraph14 typo-medium
                              text-light-high
                              mb-half
                              noselect
                            "
                          >
                            Analytics
                          </p>
                          <p
                            class="typo-paragraph12 typo-medium text-light-low"
                          >
                            Announcement emails about your site’s performance
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>

        <section id="data_privacy" class="mb-3">
          <div class="sg-card data-privacy">
            <div class="sg-card__head">
              <h3 class="sg-card__head-title">Data Privacy</h3>
            </div>
            <div class="sg-card__body">
              <div class="block flex align-items-center cookie-setting">
                <div class="cookie-setting__left">
                  <p class="typo-paragraph14 typo-semibold text-lightmode mb-1">
                    Cookie Preferences
                  </p>
                  <p class="typo-body4 typo-medium text-light-low">
                    You can choose to accept or reject all cookies, or set your
                    preferences.
                    <a
                      class="rl"
                      href="https://gempages.net/legal/cookies"
                      target="_blank"
                      >Learn more</a
                    >
                  </p>
                </div>
                <div class="cookie-setting__right">
                  <button
                    type="button"
                    class="btn ml-0 btn-secondary cky-banner-element"
                  >
                    Cookie Options
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="add_code_to_theme" class="mb-3">
          <div class="sg-card add-code-to-theme">
            <div class="sg-card__head">
              <h3 class="sg-card__head-title">Add Code To Theme</h3>
            </div>
            <div class="sg-card__body">
              <div class="form-group">
                <label
                  class="
                    label
                    d-flex
                    align-items-center
                    justify-content-between
                  "
                  ><div class="d-flex align-items-center">
                    <span>HTML code for &lt;head&gt;</span>
                    <div class="pr-1 ml-1 mr-1 d-flex">
                      <i
                          class="
                            icon icon-info-sign
                            tip
                            typo-icon-14
                            text-light-disabled
                          "
                        ></i>
                    </div>
                  </div>
                  <span class="reset-code disabled">Reset code</span></label>
                  <textarea
                  id=""
                  cols="30"
                  rows="10"
                  class="custom-code">
                </textarea>
                <div class="script-url my-1">
                  Go to
                  <a
                    style="color: #3c67ff"
                    href="https://vuacasutu.myshopify.com/admin/themes/current/editor?context=apps&amp;activateAppId=20b379d4-1b20-474c-a6ca-665c331919f3/embed-sg-script-head"
                    target="_blank"
                    >Shopify Theme -&gt; App Embed</a
                  >
                  first to enable “GemPages Head code” automatically.
                </div>
              </div>
              <div class="form-group mt-2">
                <label
                  class="
                    label
                    d-flex
                    align-items-center
                    justify-content-between
                  "
                  ><div class="d-flex align-items-center">
                    <span>HTML code for &lt;body&gt;</span>
                    <div class="pr-1 ml-1 mr-1 d-flex">
                      <i
                          class="
                            icon icon-info-sign
                            tip
                            typo-icon-14
                            text-light-disabled
                          "
                        ></i>
                    </div>
                  </div>
                  <span class="reset-code disabled">Reset code</span></label>
                  <textarea
                  id=""
                  cols="30"
                  rows="10"
                  class="custom-code">
                </textarea>
                <div class="script-url my-1">
                  Go to
                  <a
                    style="color: #3c67ff"
                    href="https://vuacasutu.myshopify.com/admin/themes/current/editor?context=apps&amp;activateAppId=20b379d4-1b20-474c-a6ca-665c331919f3/embed-sg-script-body"
                    target="_blank"
                    >Shopify Theme -&gt; App Embed</a
                  >
                  first to enable “GemPages Body code” automatically.
                </div>
              </div>
            </div>
            <div class="sg-card__footer mt-2 d-flex justify-content-end">
              <button disabled="disabled" class="btn btn-secondary">
                Save
              </button>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>
  
  <script>
import TitlePage from "../../layouts/components/TitlePage.vue";
import { request } from "../../services";
import { homeIcons } from "../../constants";
import moment from "moment";
import Cookies from 'js-cookie';

export default {
  components: {
    TitlePage,
  },
  props: {
    page: String,
    title: String,
    subTitle: String,
  },
  created() {},
  data() {
    return {
      options: [
        {
          value: "English",
          label: "English",
        },
        {
          value: "Français",
          label: "Français",
        },
      ],
      activeName: "first",
      templates: [],
      store: JSON.parse(Cookies.get("user_onepage") || "{}").store,
      loadingBtn: false,
      homeIcons,
      modalObj: {},
      input: "",
      error: "",
      paths: {
        landingpages: "landing",
        homepages: "",
        articlepages: "blog",
      },
      product: null,
    };
  },
  methods: {},
};
</script>
  
  <style >
</style>