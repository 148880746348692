<template>
  <div
    data-label="Wrapper Container"
    data-key="wrapper-container"
    class="wrapper-container"
    :style="elementCSS"
    :id="`wrapper-${node.uuid}`"
  >
    <slot />
    <div class="section-box">
      <div id="element-select-section" class="section-wrapper">
        <div
          v-if="showButton"
          class="add-new-sections d-flex align-items-center justify-content-center flex-column"
        >
          <a href="#" class="btn-new-section" @click="onClickAdd($event)">Add New Section</a>
          <span>or drag element from left sidebar</span>
        </div>
        <div v-else class="add-new-row d-flex justify-content-center">
          <div class="close-button" @click="() => onShow()">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="#000000" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
          </div>
          <div class="layout-wrapper">
            <div style="color: #AAAAAA; text-align: center; margin-bottom: 20px;">Select your layout</div>
            <div class="row">
              <div class="col-2 column-wrapper">
                <div class="row column-item" @click="addContainer('12')">
                  <div class="cell cell-12" />
                </div>
              </div>
              <div class="col-2 column-wrapper">
                <div class="row column-item" @click="addContainer('6_6')">
                  <div class="cell cell-6" />
                  <div class="cell cell-6" />
                </div>
              </div>
              <div class="col-2 column-wrapper">
                <div class="row column-item" @click="addContainer('4_4_4')" >
                  <div class="cell cell-4" />
                  <div class="cell cell-4" />
                  <div class="cell cell-4" />
                </div>
              </div>
              <div class="col-2 column-wrapper">
                <div class="row column-item" @click="addContainer('3_3_3_3')">
                  <div class="cell cell-3" />
                  <div class="cell cell-3" />
                  <div class="cell cell-3" />
                  <div class="cell cell-3" />
                </div>
              </div>
              <div class="col-2 column-wrapper">
                <div class="row column-item" @click="addContainer('8_4')">
                  <div class="cell cell-8" />
                  <div class="cell cell-4" />
                </div>
              </div>
              <div class="col-2 column-wrapper">
                <div class="row column-item" @click="addContainer('4_8')">
                  <div class="cell cell-4" />
                  <div class="cell cell-8" />
                </div>
              </div>
              <div class="col-2 column-wrapper">
                <div class="row column-item" @click="addContainer('3_9')">
                  <div class="cell cell-3" />
                  <div class="cell cell-9" />
                </div>
              </div>
              <div class="col-2 column-wrapper">
                <div class="row column-item" @click="addContainer('9_3')">
                  <div class="cell cell-9" />
                  <div class="cell cell-3" />
                </div>
              </div>
              <div class="col-2 column-wrapper">
                <div class="row column-item" @click="addContainer('6_3_3')">
                  <div class="cell cell-6" />
                  <div class="cell cell-3" />
                  <div class="cell cell-3" />
                </div>
              </div>
              <div class="col-2 column-wrapper">
                <div class="row column-item" @click="addContainer('3_3_6')">
                  <div class="cell cell-3" />
                  <div class="cell cell-3" />
                  <div class="cell cell-6" />
                </div>
              </div>
              <div class="col-2 column-wrapper">
                <div class="row column-item" @click="addContainer('3_6_3')">
                  <div class="cell cell-3" />
                  <div class="cell cell-6" />
                  <div class="cell cell-3" />
                </div>
              </div>
              <div class="col-2 column-wrapper">
                <div class="row column-item" @click="addContainer('full')">
                  <div class="cell cell-12 d-flex align-items-center justify-content-center">
                    <svg height="9px" viewBox="0 0 62 9">
                      <path d="M61.7,4.5l-4.4,4.4l-0.7-0.7L59.8,5H2.2l3.1,3.1L4.6,8.9L0.3,4.5l4.4-4.4l0.7,0.7L2.2,4h57.6l-3.1-3.1l0.7-0.7 L61.7,4.5z" style="fill:#fff"></path> 
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-style>
      .page-template-default {
        font-family: {{ body_font_family == 'inherit' ? 'Work Sans' : body_font_family }};
      }

      .page-template-default .wrapper-container {
        background-color: {{ body_background || '#fff' }};
        color: {{ body_text_color || '#000' }};
        font-family: {{ body_font_family || 'inherit' }};
        font-size: {{ body_font_size || '16' }}px;
      }

      <!-- .page-template-default a {
        color: {{ link_text_color || '#0060E5' }};
        font-family: {{ link_font_family || 'inherit' }};
        font-size: {{ link_font_size || '16' }}px;
        font-weight: {{ link_font_weight || '400' }};
        text-decoration: {{ link_text_decoration || 'underline' }};
      }

      .page-template-default a:hover {
        color: {{ link_hover_text_color || '#0060E5' }};
        font-family: {{ link_hover_font_family || 'inherit' }};
        font-size: {{ link_hover_font_size || '16' }}px;
        font-weight: {{ link_hover_font_weight || '400' }};
        text-decoration: {{ link_hover_text_decoration || 'underline' }};
      } -->

      .page-template-default button {
        font-family: {{ button_font_family || 'inherit' }};
        font-size: {{ button_font_size || 16 }}px;
        font-weight: {{ button_font_weight || '700' }};
        text-transform: {{ button_text_transform || 'uppercase' }};
        padding-top: {{ button_padding_top }}{{ button_padding_unit }};
        padding-right: {{ button_padding_right }}{{ button_padding_unit }};
        padding-bottom: {{ button_padding_bottom }}{{ button_padding_unit }};
        padding-left: {{ button_padding_left }}{{ button_padding_unit }};
        
        color: {{ button_text_color || '#fff' }};
        background-color: {{ button_background_color || '#000' }};
        {{ button_border_type != 'none' ? `border-type: ${button_border_type};` : '' }}
        {{ button_border_type != 'none' ? `border-width: ${button_border_top_width}${button_border_width_unit} ${button_border_right_width}${button_border_width_unit} ${button_border_bottom_width}${button_border_width_unit} ${button_border_left_width}${button_border_width_unit};` : '' }}
        {{ button_border_type != 'none' ? `border-color: ${button_border_color || 'transparent'};` : ''}}
        border-radius: {{ `${button_border_top_left_radius}${button_border_radius_unit} ${button_border_top_right_radius}${button_border_radius_unit} ${button_border_bottom_right_radius}${button_border_radius_unit} ${button_border_bottom_left_radius}${button_border_radius_unit}` }};
        transition: all 0.3s ease;
      }

      .page-template-default button:hover {
        color: {{ button_hover_text_color || '#fff' }};
        background-color: {{ button_hover_background_color || '#000' }};
        {{ button_hover_border_type != 'none' ? `border-type: ${button_hover_border_type};` : '' }}
        {{ button_hover_border_type != 'none' ? `border-width: ${button_hover_border_top_width}${button_hover_border_width_unit} ${button_hover_border_right_width}${button_hover_border_width_unit} ${button_hover_border_bottom_width}${button_hover_border_width_unit} ${button_hover_border_left_width}${button_hover_border_width_unit};` : '' }}
        {{ button_hover_border_type != 'none' ? `border-color: ${button_hover_border_color || 'transparent'};` : ''}}
        border-radius: {{ `${button_hover_border_top_left_radius}${button_hover_border_radius_unit} ${button_hover_border_top_right_radius}${button_hover_border_radius_unit} ${button_hover_border_bottom_right_radius}${button_hover_border_radius_unit} ${button_hover_border_bottom_left_radius}${button_hover_border_radius_unit}` }};
        transition: all 0.3s ease;
      }

      .page-template-default img {
        {{ image_border_type != 'none' ? `border-type: ${image_border_type};` : '' }}
        {{ image_border_type != 'none' ? `border-width: ${image_border_top_width}${image_border_width_unit} ${image_border_left_width}${image_border_width_unit} ${image_border_bottom_width}${image_border_width_unit} ${image_border_left_width}${image_border_width_unit};` : '' }}
        {{ image_border_type != 'none' ? `border-color: ${image_border_color || 'transparent'};` : '' }}
        border-radius: {{ `${image_border_top_left_radius}${image_border_radius_unit} ${image_border_top_right_radius}${image_border_radius_unit} ${image_border_bottom_right_radius}${image_border_radius_unit} ${image_border_bottom_left_radius}${image_border_radius_unit}` }};
        opacity: {{ image_opacity }};
        transition: all 0.3s ease;
      }

      .page-template-default img:hover {
        {{ image_hover_border_type != 'none' ? `border-type: ${image_hover_border_type};` : '' }}
        {{ image_hover_border_type != 'none' ? `border-width: ${image_hover_border_top_width}${image_hover_border_width_unit} ${image_hover_border_left_width}${image_hover_border_width_unit} ${image_hover_border_bottom_width}${image_hover_border_width_unit} ${image_hover_border_left_width}${image_hover_border_width_unit};` : '' }}
        {{ image_hover_border_type != 'none' ? `border-color: ${image_hover_border_color || 'transparent'};` : '' }}
        border-radius: {{ `${image_border_top_left_radius}${image_border_radius_unit} ${image_border_top_right_radius}${image_border_radius_unit} ${image_border_bottom_right_radius}${image_border_radius_unit} ${image_border_bottom_left_radius}${image_border_radius_unit}` }};
        opacity: {{ image_hover_opacity }};
        transition: all 0.3s ease;
      }
    </v-style>
    <v-script id="sg-animation">
      {{ getAnimations() }}
    </v-script>
  </div>
  
</template>

<script>
import elementStyleMixin from './elementStyleMixin';
import Node from '@/core/Node';
import { designStyles, otherProps, otherStyles, actionTypes } from '@/constants';
import Body from './siteSettings/Body.vue';
import Button from './siteSettings/Button.vue';
import Link from './siteSettings/Link.vue';
import Image from './siteSettings/Image.vue';

export default {
  mixins: [elementStyleMixin],
  inject: [
    'editor',
    'node'
  ],
  props: {
    // Body
    body_text_color: String,
    body_background: String,
    body_font_family: String,
    body_font_size: Number,

    // Link
    link_type: String,
    link_text_color: String,
    link_font_family: String,
    link_font_weight: String,
    link_font_size: Number,
    link_text_decoration: String,
    link_hover_text_color: String,
    link_hover_font_family: String,
    link_hover_font_weight: String,
    link_hover_font_size: Number,
    link_hover_text_decoration: String,

    // Button
    button_font_family: String,
    button_font_size: Number,
    button_font_weight: String,
    button_text_transform: String,
    button_padding_unit: String,
    button_padding_top: Number,
    button_padding_right: Number,
    button_padding_bottom: Number,
    button_padding_left: Number,
    button_type: String,
    button_text_color: String,
    button_background_color: String,
    button_border_type: String,
    button_border_width_unit: String,
    button_border_top_width: Number,
    button_border_right_width: Number,
    button_border_bottom_width: Number,
    button_border_left_width: Number,
    button_border_color: String,
    button_border_radius_unit: String,
    button_border_top_left_radius: Number,
    button_border_top_right_radius: Number,
    button_border_bottom_right_radius: Number,
    button_border_bottom_left_radius: Number,
    button_hover_text_color: String,
    button_hover_background_color: String,
    button_hover_border_type: String,
    button_hover_border_width_unit: String,
    button_hover_border_top_width: Number,
    button_hover_border_right_width: Number,
    button_hover_border_bottom_width: Number,
    button_hover_border_left_width: Number,
    button_hover_border_color: String,
    button_hover_border_radius_unit: String,
    button_hover_border_top_left_radius: Number,
    button_hover_border_top_right_radius: Number,
    button_hover_border_bottom_right_radius: Number,
    button_hover_border_bottom_left_radius: Number,

    // Image
    image_type: String,
    image_border_type: String,
    image_border_width_unit: String,
    image_border_top_width: Number,
    image_border_right_width: Number,
    image_border_bottom_width: Number,
    image_border_left_width: Number,
    image_border_color: String,
    image_border_radius_unit: String,
    image_border_top_left_radius: Number,
    image_border_top_right_radius: Number,
    image_border_bottom_right_radius: Number,
    image_border_bottom_left_radius: Number,
    image_opacity: Number,
    image_hover_border_type: String,
    image_hover_border_width_unit: String,
    image_hover_border_top_width: Number,
    image_hover_border_right_width: Number,
    image_hover_border_bottom_width: Number,
    image_hover_border_left_width: Number,
    image_hover_border_color: String,
    image_hover_border_radius_unit: String,
    image_hover_border_top_left_radius: Number,
    image_hover_border_top_right_radius: Number,
    image_hover_border_bottom_right_radius: Number,
    image_hover_border_bottom_left_radius: Number,
    image_hover_opacity: Number,
  },
  data() {
    return {
      isHovering: false,
      showButton: true,
      designStyles
    }
  },
  methods: {
    onShow() {
      this.showButton = true
    },
    onHide() {
      this.showButton = false
    },
    onClickAdd(event) {
      event.preventDefault()
      this.onHide()
    },
    updateDict(screen, key, value, node) {
      const data_screen = Object.assign({}, node.props[screen])
      data_screen[key] = value
      node.setProps({ [screen]: data_screen }, true)
    },
    addContainer(layout) {
      const node = this.editor.findNode(this.node.uuid);
      const numRowNodes = node.children?.filter(e => e.componentName == 'Row').length || 0
      const rowProps = {
        component: 'Row',
        full_width: false,
        name: `Row ${numRowNodes + 1}`,
        hiddenConfig: [],
        vertical_align: 'top',
        equal_height: false,
        column_gap: '15',
        desktop: {
          ...this.designStyles,
          layout: '12',
          padding_top: 30,
          padding_bottom: 10,
          width: '1200',
          width_type: 'px'
        },
        laptop: {
          ...this.designStyles,
          layout: '12',
          padding_top: 30,
          padding_bottom: 10,
          width: '1200',
          width_type: 'px'
        },
        tablet: {
          ...this.designStyles,
          layout: '12',
          padding_top: 30,
          padding_bottom: 10,
          width: '1200',
          width_type: 'px'
        },
        mobile: {
          ...this.designStyles,
          layout: '12',
          padding_top: 30,
          padding_bottom: 10,
          width: '1200',
          width_type: 'px'
        },
        elementStyle: {},
      }
      const rowContainer = new Node(
        'Canvas',
        rowProps
      )
      rowContainer.editor = this.editor
      const num_blocks = layout.split('_')
      if (layout == '12') {
        const new_node = new Node(
          'Canvas', {
            component: 'Column',
            "dataLayoutXl": "12",
            "dataLayoutLg": "12",
            "dataLayoutMd": "12",
            "dataLayoutSm": "12",
            elementStyle: {}
          }
        )
        new_node.editor = this.editor
        rowContainer.append(new_node, true)
      } else if (['6_6', '8_4', '4_8', '3_9', '9_3'].includes(layout)) {
        this.updateDict('desktop', 'layout', layout, rowContainer)
        this.updateDict('laptop', 'layout', layout, rowContainer)
        this.updateDict('tablet', 'layout', layout, rowContainer)
        this.updateDict('mobile', 'layout', '12', rowContainer)
        // rowContainer.setProps({
        //   layout_desktop: layout,
        //   layout_laptop: layout,
        //   layout_tablet: layout,
        //   layout_mobile: '12',
        // })
        num_blocks.map(e => {
          const new_node = new Node(
            'Canvas', {
              component: 'Column',
              "dataLayoutXl": e,
              "dataLayoutLg": e,
              "dataLayoutMd": e,
              "dataLayoutSm": '12',
              elementStyle: {}
            }
          )
          new_node.editor = this.editor
          rowContainer.append(new_node, true)
        })
      } else if (['4_4_4', '6_3_3', '3_3_6', '3_6_3'].includes(layout)) {
        this.updateDict('desktop', 'layout', layout, rowContainer)
        this.updateDict('laptop', 'layout', layout, rowContainer)
        this.updateDict('tablet', 'layout', '12', rowContainer)
        this.updateDict('mobile', 'layout', '12', rowContainer)
        // rowContainer.setProps({
        //   layout_desktop: layout,
        //   layout_laptop: layout,
        //   layout_tablet: "12",
        //   layout_mobile: '12',
        // })
        num_blocks.map(e => {
          const new_node = new Node(
            'Canvas', {
              component: 'Column',
              "dataLayoutXl": e,
              "dataLayoutLg": e,
              "dataLayoutMd": '12',
              "dataLayoutSm": '12',
              elementStyle: {}
            }
          )
          new_node.editor = this.editor
          rowContainer.append(new_node, true)
        })
      } else if (layout == '3_3_3_3') {
        this.updateDict('desktop', 'layout', layout, rowContainer)
        this.updateDict('laptop', 'layout', layout, rowContainer)
        this.updateDict('tablet', 'layout', '6_6', rowContainer)
        this.updateDict('mobile', 'layout', '12', rowContainer)
        // rowContainer.setProps({
        //   layout_desktop: layout,
        //   layout_laptop: layout,
        //   layout_tablet: "6_6",
        //   layout_mobile: '12',
        // })
        num_blocks.map(e => {
          const new_node = new Node(
            'Canvas', {
              component: 'Column',
              "dataLayoutXl": e,
              "dataLayoutLg": e,
              "dataLayoutMd": '6',
              "dataLayoutSm": '12',
              elementStyle: {}
            }
          )
          new_node.editor = this.editor
          rowContainer.append(new_node, true)
        })
      } else {
        const new_node = new Node(
          'Canvas', {
            component: 'Column',
            "dataLayoutXl": '12',
            "dataLayoutLg": '12',
            "dataLayoutMd": '12',
            "dataLayoutSm": '12',
            elementStyle: {}
          }
        )
        new_node.editor = this.editor
        rowContainer.append(new_node, true)
        rowContainer.setProps({
          full_width: true
        }, true)
      }
      node.append(rowContainer)
    },
    getAnimations() {
      return JSON.stringify(this.editor.animations || [])
    }
  },
  craft: {
    defaultProps: {
      // Body
      body_text_color: 'rgb(0, 0, 0)',
      body_background: 'rgb(255, 255, 255)',
      body_font_family: 'inherit',
      body_font_size: 16,

      // Link
      link_type: 'normal',
      link_text_color: 'rgb(0, 96, 229)',
      link_font_family: 'inherit',
      link_font_weight: '400',
      link_font_size: 16,
      link_text_decoration: 'underline',
      link_hover_text_color: 'rgb(0, 96, 229)',
      link_hover_font_family: 'inherit',
      link_hover_font_weight: '400',
      link_hover_font_size: 16,
      link_hover_text_decoration: 'underline',

      // Button
      button_font_family: 'inherit',
      button_font_size: 16,
      button_font_weight: '700',
      button_text_transform: 'uppercase',
      button_padding_unit: 'px',
      button_padding_top: 12,
      button_padding_right: 12,
      button_padding_bottom: 12,
      button_padding_left: 12,
      button_type: 'normal',
      button_text_color: 'rgb(255, 255, 255)',
      button_background_color: 'rgb(0, 0, 0)',
      button_border_type: 'none',
      button_border_width_unit: 'px',
      button_border_top_width: 1,
      button_border_right_width: 1,
      button_border_bottom_width: 1,
      button_border_left_width: 1,
      button_border_color: 'rgb(0, 0, 0)',
      button_border_radius_unit: 'px',
      button_border_top_left_radius: 0,
      button_border_top_right_radius: 0,
      button_border_bottom_right_radius: 0,
      button_border_bottom_left_radius: 0,
      button_hover_text_color: 'rgb(255, 255, 255)',
      button_hover_background_color: 'rgb(0, 0, 0)',
      button_hover_border_type: 'none',
      button_hover_border_width_unit: 'px',
      button_hover_border_top_width: 1,
      button_hover_border_right_width: 1,
      button_hover_border_bottom_width: 1,
      button_hover_border_left_width: 1,
      button_hover_border_color: 'rgb(0, 0, 0)',
      button_hover_border_radius_unit: 'px',
      button_hover_border_top_left_radius: 0,
      button_hover_border_top_right_radius: 0,
      button_hover_border_bottom_right_radius: 0,
      button_hover_border_bottom_left_radius: 0,

      // Image
      image_type: 'normal',
      image_border_type: 'none',
      image_border_width_unit: 'px',
      image_border_top_width: 0,
      image_border_right_width: 0,
      image_border_bottom_width: 0,
      image_border_left_width: 0,
      image_border_color: 'rgb(0, 0, 0)',
      image_border_radius_unit: 'px',
      image_border_top_left_radius: 0,
      image_border_top_right_radius: 0,
      image_border_bottom_right_radius: 0,
      image_border_bottom_left_radius: 0,
      image_opacity: 1,
      image_hover_border_type: 'none',
      image_hover_border_width_unit: 'px',
      image_hover_border_top_width: 0,
      image_hover_border_right_width: 0,
      image_hover_border_bottom_width: 0,
      image_hover_border_left_width: 0,
      image_hover_border_color: 'rgb(0, 0, 0)',
      image_hover_border_radius_unit: 'px',
      image_hover_border_top_left_radius: 0,
      image_hover_border_top_right_radius: 0,
      image_hover_border_bottom_right_radius: 0,
      image_hover_border_bottom_left_radius: 0,
      image_hover_opacity: 1,

      elementStyle: {},
    },
    settings: {
      settings: {
        Body,
        // Link,
        Button,
        Image,
      },
      design: {
      }
    },
  },
};
</script>

<style lang="scss" scoped>

.dragging{
  position:absolute;
  top:0;
  bottom: 0;
  left: 0;
  right:0;
  background: greenyellow;
  z-index: 10;
}

</style>
