<template>
  <div label-position="top" size="mini" :model="elementProps">
    <div class="d-flex flex-column py-2">
      <span class="setting-title">Add your own custom CSS here</span>
      <AceEditor
        class="my-editor"
        v-model="elementProps.custom_css" 
        @init="editorInit" 
        lang="css"
        theme="monokai" 
        width="100%" 
        height="300px"
        :options="{
            enableBasicAutocompletion: true,
            enableLiveAutocompletion: true,
            fontSize: 12,
            fontFamily: 'monospace',
            highlightActiveLine: true,
            enableSnippets: true,
            showLineNumbers: true,
            tabSize: 2,
            showPrintMargin: false,
            showGutter: true,
        }"
      />
    </div>
  </div>
</template>

<script>
import mixin from "../styleSettings/mixin";
import AceEditor from 'vuejs-ace-editor';
export default {
  mixins: [mixin],
  components: {
    AceEditor
  },
  props: {

  },
  methods: {
    updateProps(name, value) {
      this.elementPropsSetter({ [name]: value });
    },
    editorInit: function () {
      require('brace/ext/language_tools') //language extension prerequsite...
      require('brace/mode/html')
      require('brace/mode/javascript')    //language
      require('brace/mode/less')
      require('brace/mode/css')
      require('brace/theme/monokai')
      require('brace/snippets/javascript') //snippet
    }
  }
};
</script>

<style lang="scss">
// required class
.my-editor {
  border: 1px solid #494949;
  border-radius: 8px;
  margin-top: 8px; 
}
</style>