<template>
  <div label-position="top" size="mini" :model="elementProps">
    <div class="d-flex flex-column py-2">
      <div class="title-layout">
        <span v-html="icons.desktop" />
        <span class="mx-2">Desktop Layout</span>
      </div>
      <div class="row row-layout">
        <div class="col-4 layout-wrapper">
          <div 
            class="row layout-item" 
            :class="{active: elementProps.desktop.layout == '12'}"
            @click="() => updateProps('layout', '12', 'desktop')"
          >
            <div class="cell cell-12" />
          </div>
        </div>
        <div class="col-4 layout-wrapper">
          <div 
            class="row layout-item" 
            :class="{active: elementProps.desktop.layout == '6_6'}"
            @click="() => updateProps('layout', '6_6', 'desktop')"
          >
            <div class="cell cell-6" />
            <div class="cell cell-6" />
          </div>
        </div>
        <div class="col-4 layout-wrapper">
          <div 
            class="row layout-item" 
            :class="{active: elementProps.desktop.layout == '4_4_4'}"
            @click="() => updateProps('layout', '4_4_4', 'desktop')"
          >
            <div class="cell cell-4" />
            <div class="cell cell-4" />
            <div class="cell cell-4" />
          </div>
        </div>
        <div class="col-4 layout-wrapper">
          <div 
            class="row layout-item" 
            :class="{active: elementProps.desktop.layout == '3_3_3_3'}"
            @click="() => updateProps('layout', '3_3_3_3', 'desktop')"
          >
            <div class="cell cell-3" />
            <div class="cell cell-3" />
            <div class="cell cell-3" />
            <div class="cell cell-3" />
          </div>
        </div>
        <div class="col-4 layout-wrapper">
          <div 
            class="row layout-item" 
            :class="{active: elementProps.desktop.layout == '8_4'}"
            @click="() => updateProps('layout', '8_4', 'desktop')"
          >
            <div class="cell cell-8" />
            <div class="cell cell-4" />
          </div>
        </div>
        <div class="col-4 layout-wrapper">
          <div 
            class="row layout-item" 
            :class="{active: elementProps.desktop.layout == '4_8'}"
            @click="() => updateProps('layout', '4_8', 'desktop')"
          >
            <div class="cell cell-4" />
            <div class="cell cell-8" />
          </div>
        </div>
        <div class="col-4 layout-wrapper">
          <div 
            class="row layout-item" 
            :class="{active: elementProps.desktop.layout == '3_9'}"
            @click="() => updateProps('layout', '3_9', 'desktop')"
          >
            <div class="cell cell-3" />
            <div class="cell cell-9" />
          </div>
        </div>
        <div class="col-4 layout-wrapper">
          <div 
            class="row layout-item" 
            :class="{active: elementProps.desktop.layout == '9_3'}"
            @click="() => updateProps('layout', '9_3', 'desktop')"
          >
            <div class="cell cell-9" />
            <div class="cell cell-3" />
          </div>
        </div>
        <div class="col-4 layout-wrapper">
          <div 
            class="row layout-item" 
            :class="{active: elementProps.desktop.layout == '6_3_3'}"
            @click="() => updateProps('layout', '6_3_3', 'desktop')"
          >
            <div class="cell cell-6" />
            <div class="cell cell-3" />
            <div class="cell cell-3" />
          </div>
        </div>
        <div class="col-4 layout-wrapper">
          <div 
            class="row layout-item" 
            :class="{active: elementProps.desktop.layout == '3_3_6'}"
            @click="() => updateProps('layout', '3_3_6', 'desktop')"
          >
            <div class="cell cell-3" />
            <div class="cell cell-3" />
            <div class="cell cell-6" />
          </div>
        </div>
        <div class="col-4 layout-wrapper">
          <div 
            class="row layout-item" 
            :class="{active: elementProps.desktop.layout == '3_6_3'}"
            @click="() => updateProps('layout', '3_6_3', 'desktop')"
          >
            <div class="cell cell-3" />
            <div class="cell cell-6" />
            <div class="cell cell-3" />
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex flex-column py-2">
      <div class="title-layout">
        <span v-html="icons.laptop" />
        <span class="mx-2">Laptop Layout</span>
      </div>
      <div class="row row-layout">
        <div class="col-4 layout-wrapper">
          <div 
            class="row layout-item" 
            :class="{active: elementProps.laptop.layout == '12'}"
            @click="() => updateProps('layout', '12', 'laptop')"
          >
            <div class="cell cell-12" />
          </div>
        </div>
        <div class="col-4 layout-wrapper" v-if="['6_6', '3_3_3_3'].includes(elementProps.desktop.layout)">
          <div 
            class="row layout-item" 
            :class="{active: elementProps.laptop.layout == '6_6'}"
            @click="() => updateProps('layout', '6_6', 'laptop')"
          >
            <div class="cell cell-6" />
            <div class="cell cell-6" />
          </div>
        </div>
        <div class="col-4 layout-wrapper" v-if="elementProps.desktop.layout == '4_4_4'">
          <div 
            class="row layout-item" 
            :class="{active: elementProps.laptop.layout == '4_4_4'}"
            @click="() => updateProps('layout', '4_4_4', 'laptop')"
          >
            <div class="cell cell-4" />
            <div class="cell cell-4" />
            <div class="cell cell-4" />
          </div>
        </div>
        <div class="col-4 layout-wrapper" v-if="elementProps.desktop.layout == '3_3_3_3'">
          <div 
            class="row layout-item" 
            :class="{active: elementProps.laptop.layout == '3_3_3_3'}"
            @click="() => updateProps('layout', '3_3_3_3', 'laptop')"
          >
            <div class="cell cell-3" />
            <div class="cell cell-3" />
            <div class="cell cell-3" />
            <div class="cell cell-3" />
          </div>
        </div>
        <div class="col-4 layout-wrapper" v-if="elementProps.desktop.layout == '8_4'">
          <div 
            class="row layout-item" 
            :class="{active: elementProps.laptop.layout == '8_4'}"
            @click="() => updateProps('layout', '8_4', 'laptop')"
          >
            <div class="cell cell-8" />
            <div class="cell cell-4" />
          </div>
        </div>
        <div class="col-4 layout-wrapper" v-if="elementProps.desktop.layout == '4_8'">
          <div 
            class="row layout-item" 
            :class="{active: elementProps.laptop.layout == '4_8'}"
            @click="() => updateProps('layout', '4_8', 'laptop')"
          >
            <div class="cell cell-4" />
            <div class="cell cell-8" />
          </div>
        </div>
        <div class="col-4 layout-wrapper" v-if="elementProps.desktop.layout == '3_9'">
          <div 
            class="row layout-item" 
            :class="{active: elementProps.laptop.layout == '3_9'}"
            @click="() => updateProps('layout', '3_9', 'laptop')"
          >
            <div class="cell cell-3" />
            <div class="cell cell-9" />
          </div>
        </div>
        <div class="col-4 layout-wrapper" v-if="elementProps.desktop.layout == '9_3'">
          <div 
            class="row layout-item" 
            :class="{active: elementProps.laptop.layout == '9_3'}"
            @click="() => updateProps('layout', '9_3', 'laptop')"
          >
            <div class="cell cell-9" />
            <div class="cell cell-3" />
          </div>
        </div>
        <div class="col-4 layout-wrapper" v-if="elementProps.desktop.layout == '6_3_3'">
          <div 
            class="row layout-item" 
            :class="{active: elementProps.laptop.layout == '6_3_3'}"
            @click="() => updateProps('layout', '6_3_3', 'laptop')"
          >
            <div class="cell cell-6" />
            <div class="cell cell-3" />
            <div class="cell cell-3" />
          </div>
        </div>
        <div class="col-4 layout-wrapper" v-if="elementProps.desktop.layout == '3_3_6'">
          <div 
            class="row layout-item" 
            :class="{active: elementProps.laptop.layout == '3_3_6'}"
            @click="() => updateProps('layout', '3_3_6', 'laptop')"
          >
            <div class="cell cell-3" />
            <div class="cell cell-3" />
            <div class="cell cell-6" />
          </div>
        </div>
        <div class="col-4 layout-wrapper" v-if="elementProps.desktop.layout == '3_6_3'">
          <div 
            class="row layout-item" 
            :class="{active: elementProps.laptop.layout == '3_6_3'}"
            @click="() => updateProps('layout', '3_6_3', 'laptop')"
          >
            <div class="cell cell-3" />
            <div class="cell cell-6" />
            <div class="cell cell-3" />
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex flex-column py-2">
      <div class="title-layout">
        <span v-html="icons.tablet" />
        <span class="mx-2">Tablet Layout</span>
      </div>
      <div class="row row-layout">
        <div class="col-4 layout-wrapper">
          <div 
            class="row layout-item" 
            :class="{active: elementProps.tablet.layout == '12'}"
            @click="() => updateProps('layout', '12', 'tablet')"
          >
            <div class="cell cell-12" />
          </div>
        </div>
        <div class="col-4 layout-wrapper" v-if="['6_6', '3_3_3_3'].includes(elementProps.desktop.layout)">
          <div 
            class="row layout-item" 
            :class="{active: elementProps.tablet.layout == '6_6'}"
            @click="() => updateProps('layout', '6_6', 'tablet')"
          >
            <div class="cell cell-6" />
            <div class="cell cell-6" />
          </div>
        </div>
        <div class="col-4 layout-wrapper" v-if="elementProps.desktop.layout == '4_4_4'">
          <div 
            class="row layout-item" 
            :class="{active: elementProps.tablet.layout == '4_4_4'}"
            @click="() => updateProps('layout', '4_4_4', 'tablet')"
          >
            <div class="cell cell-4" />
            <div class="cell cell-4" />
            <div class="cell cell-4" />
          </div>
        </div>
        <div class="col-4 layout-wrapper" v-if="elementProps.desktop.layout == '3_3_3_3'">
          <div 
            class="row layout-item" 
            :class="{active: elementProps.tablet.layout == '3_3_3_3'}"
            @click="() => updateProps('layout', '3_3_3_3', 'tablet')"
          >
            <div class="cell cell-3" />
            <div class="cell cell-3" />
            <div class="cell cell-3" />
            <div class="cell cell-3" />
          </div>
        </div>
        <div class="col-4 layout-wrapper" v-if="elementProps.desktop.layout == '8_4'">
          <div 
            class="row layout-item" 
            :class="{active: elementProps.tablet.layout == '8_4'}"
            @click="() => updateProps('layout', '8_4', 'tablet')"
          >
            <div class="cell cell-8" />
            <div class="cell cell-4" />
          </div>
        </div>
        <div class="col-4 layout-wrapper" v-if="elementProps.desktop.layout == '4_8'">
          <div 
            class="row layout-item" 
            :class="{active: elementProps.tablet.layout == '4_8'}"
            @click="() => updateProps('layout', '4_8', 'tablet')"
          >
            <div class="cell cell-4" />
            <div class="cell cell-8" />
          </div>
        </div>
        <div class="col-4 layout-wrapper" v-if="elementProps.desktop.layout == '3_9'">
          <div 
            class="row layout-item" 
            :class="{active: elementProps.tablet.layout == '3_9'}"
            @click="() => updateProps('layout', '3_9', 'tablet')"
          >
            <div class="cell cell-3" />
            <div class="cell cell-9" />
          </div>
        </div>
        <div class="col-4 layout-wrapper" v-if="elementProps.desktop.layout == '9_3'">
          <div 
            class="row layout-item" 
            :class="{active: elementProps.tablet.layout == '9_3'}"
            @click="() => updateProps('layout', '9_3', 'tablet')"
          >
            <div class="cell cell-9" />
            <div class="cell cell-3" />
          </div>
        </div>
        <div class="col-4 layout-wrapper" v-if="elementProps.desktop.layout == '6_3_3'">
          <div 
            class="row layout-item" 
            :class="{active: elementProps.tablet.layout == '6_3_3'}"
            @click="() => updateProps('layout', '6_3_3', 'tablet')"
          >
            <div class="cell cell-6" />
            <div class="cell cell-3" />
            <div class="cell cell-3" />
          </div>
        </div>
        <div class="col-4 layout-wrapper" v-if="elementProps.desktop.layout == '3_3_6'">
          <div 
            class="row layout-item" 
            :class="{active: elementProps.tablet.layout == '3_3_6'}"
            @click="() => updateProps('layout', '3_3_6', 'tablet')"
          >
            <div class="cell cell-3" />
            <div class="cell cell-3" />
            <div class="cell cell-6" />
          </div>
        </div>
        <div class="col-4 layout-wrapper" v-if="elementProps.desktop.layout == '3_6_3'">
          <div 
            class="row layout-item" 
            :class="{active: elementProps.tablet.layout == '3_6_3'}"
            @click="() => updateProps('layout', '3_6_3', 'tablet')"
          >
            <div class="cell cell-3" />
            <div class="cell cell-6" />
            <div class="cell cell-3" />
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex flex-column py-2">
      <div class="title-layout">
        <span v-html="icons.mobile" />
        <span class="mx-2">Mobile Layout</span>
      </div>
      <div class="row row-layout">
        <div class="col-4 layout-wrapper">
          <div 
            class="row layout-item" 
            :class="{active: elementProps.mobile.layout == '12'}"
            @click="() => updateProps('layout', '12', 'mobile')"
          >
            <div class="cell cell-12" />
          </div>
        </div>
        <div class="col-4 layout-wrapper" v-if="['6_6', '3_3_3_3'].includes(elementProps.desktop.layout)">
          <div 
            class="row layout-item" 
            :class="{active: elementProps.mobile.layout == '6_6'}"
            @click="() => updateProps('layout', '6_6', 'mobile')"
          >
            <div class="cell cell-6" />
            <div class="cell cell-6" />
          </div>
        </div>
        <div class="col-4 layout-wrapper" v-if="elementProps.desktop.layout == '4_4_4'">
          <div 
            class="row layout-item" 
            :class="{active: elementProps.mobile.layout == '4_4_4'}"
            @click="() => updateProps('layout', '4_4_4', 'mobile')"
          >
            <div class="cell cell-4" />
            <div class="cell cell-4" />
            <div class="cell cell-4" />
          </div>
        </div>
        <div class="col-4 layout-wrapper" v-if="elementProps.desktop.layout == '3_3_3_3'">
          <div 
            class="row layout-item" 
            :class="{active: elementProps.mobile.layout == '3_3_3_3'}"
            @click="() => updateProps('layout', '3_3_3_3', 'mobile')"
          >
            <div class="cell cell-3" />
            <div class="cell cell-3" />
            <div class="cell cell-3" />
            <div class="cell cell-3" />
          </div>
        </div>
        <div class="col-4 layout-wrapper" v-if="elementProps.desktop.layout == '8_4'">
          <div 
            class="row layout-item" 
            :class="{active: elementProps.mobile.layout == '8_4'}"
            @click="() => updateProps('layout', '8_4', 'mobile')"
          >
            <div class="cell cell-8" />
            <div class="cell cell-4" />
          </div>
        </div>
        <div class="col-4 layout-wrapper" v-if="elementProps.desktop.layout == '4_8'">
          <div 
            class="row layout-item" 
            :class="{active: elementProps.mobile.layout == '4_8'}"
            @click="() => updateProps('layout', '4_8', 'mobile')"
          >
            <div class="cell cell-4" />
            <div class="cell cell-8" />
          </div>
        </div>
        <div class="col-4 layout-wrapper" v-if="elementProps.desktop.layout == '3_9'">
          <div 
            class="row layout-item" 
            :class="{active: elementProps.mobile.layout == '3_9'}"
            @click="() => updateProps('layout', '3_9', 'mobile')"
          >
            <div class="cell cell-3" />
            <div class="cell cell-9" />
          </div>
        </div>
        <div class="col-4 layout-wrapper" v-if="elementProps.desktop.layout == '9_3'">
          <div 
            class="row layout-item" 
            :class="{active: elementProps.mobile.layout == '9_3'}"
            @click="() => updateProps('layout', '9_3', 'mobile')"
          >
            <div class="cell cell-9" />
            <div class="cell cell-3" />
          </div>
        </div>
        <div class="col-4 layout-wrapper" v-if="elementProps.desktop.layout == '6_3_3'">
          <div 
            class="row layout-item" 
            :class="{active: elementProps.mobile.layout == '6_3_3'}"
            @click="() => updateProps('layout', '6_3_3', 'mobile')"
          >
            <div class="cell cell-6" />
            <div class="cell cell-3" />
            <div class="cell cell-3" />
          </div>
        </div>
        <div class="col-4 layout-wrapper" v-if="elementProps.desktop.layout == '3_3_6'">
          <div 
            class="row layout-item" 
            :class="{active: elementProps.mobile.layout == '3_3_6'}"
            @click="() => updateProps('layout', '3_3_6', 'mobile')"
          >
            <div class="cell cell-3" />
            <div class="cell cell-3" />
            <div class="cell cell-6" />
          </div>
        </div>
        <div class="col-4 layout-wrapper" v-if="elementProps.desktop.layout == '3_6_3'">
          <div 
            class="row layout-item" 
            :class="{active: elementProps.mobile.layout == '3_6_3'}"
            @click="() => updateProps('layout', '3_6_3', 'mobile')"
          >
            <div class="cell cell-3" />
            <div class="cell cell-6" />
            <div class="cell cell-3" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mixin from '../styleSettings/mixin';
import { icons } from '../../../../../constants';
export default {
  mixins: [mixin],
  data() {
    return {
      icons
    }
  },
  props: {
    changeScreen: Function,
  },
  methods: {
    updateDict(screen, key, value) {
      const data_screen = Object.assign({}, this.elementProps[screen])
      data_screen[key] = value
      this.elementPropsSetter({ [screen]: data_screen })
    },
    updateProps(name, value, screen) {
      const mapScreen = {
        'desktop': 'xl',
        'laptop': 'lg',
        'tablet': 'md',
        'mobile': 'sm',
      }
      this.updateDict(screen, 'layout', value);
      if (screen == 'desktop') {
        if (value == '12') {
          this.updateDict('laptop', 'layout', value);
          this.updateDict('tablet', 'layout', value);
          this.updateDict('mobile', 'layout', value);
        } else if (['6_6', '8_4', '4_8', '3_9', '9_3'].includes(value)) {
          this.updateDict('laptop', 'layout', value);
          this.updateDict('tablet', 'layout', value);
          this.updateDict('mobile', 'layout', '12');
        } else if (['4_4_4', '6_3_3', '3_3_6', '3_6_3'].includes(value)) {
          this.updateDict('laptop', 'layout', value);
          this.updateDict('tablet', 'layout', '12');
          this.updateDict('mobile', 'layout', '12');
        } else {
          this.updateDict('laptop', 'layout', value);
          this.updateDict('tablet', 'layout', '6_6');
          this.updateDict('mobile', 'layout', '12');
        }
      }

      const children = this.node.children
      const num_blocks = value.split('_')

      if (children.length == num_blocks.length) {
        
      } else if (children.length < num_blocks.length && screen == 'desktop') {
        const diff = num_blocks.length - children.length
        const last_node = children[children.length - 1]
        Array.from(Array(diff).keys()).map(e => {
          const duplicate_note = last_node.duplicate()
          duplicate_note.parent = this.node
          this.node.children.push(duplicate_note)
        })
      } else if (children.length > num_blocks.length && screen == 'desktop') {
        const diff = children.length - num_blocks.length
        Array.from(Array(diff).keys()).map(e => {
          this.node.children.pop()
        })
      }
      
      this.node.children.map((e, index) => {
        if (value == '12') {
          const attrs = {}
          if (screen != 'desktop') {
            attrs[`data-layout-${mapScreen[screen]}`] = num_blocks[index] || num_blocks[0]
          } else {
            attrs['data-layout-xl'] = '12'
            attrs['data-layout-lg'] = '12'
            attrs['data-layout-md'] = '12'
            attrs['data-layout-sm'] = '12'
          } 
          e.setProps(attrs)
        } else if (['6_6', '8_4', '4_8', '3_9', '9_3'].includes(value)) {
          const attrs = {}
          if (screen == 'desktop') {
            attrs['data-layout-xl'] = num_blocks[index] || num_blocks[0],
            attrs['data-layout-lg'] = num_blocks[index] || num_blocks[0],
            attrs['data-layout-md'] = num_blocks[index] || num_blocks[0],
            attrs['data-layout-sm'] = '12'
          } else {
            attrs[`data-layout-${mapScreen[screen]}`] = num_blocks[index] || num_blocks[0]
          }
          e.setProps(attrs)
        } else if (['4_4_4', '6_3_3', '3_3_6', '3_6_3'].includes(value)) {
          const attrs = {}
          if (screen == 'desktop') {
            attrs['data-layout-xl'] = num_blocks[index] || num_blocks[0]
            attrs['data-layout-lg'] = num_blocks[index] || num_blocks[0]
            attrs['data-layout-md'] = '12'
            attrs['data-layout-sm'] = '12'
          } else {
            attrs[`data-layout-${mapScreen[screen]}`] = num_blocks[index] || num_blocks[0]
          }
          e.setProps(attrs)
        } else {
          const attrs = {}
          if (screen == 'desktop') {
            attrs['data-layout-xl'] = num_blocks[index] || num_blocks[0]
            attrs['data-layout-lg'] = num_blocks[index] || num_blocks[0]
            attrs['data-layout-md'] = '6'
            attrs['data-layout-sm'] = '12'
          } else {
            attrs[`data-layout-${mapScreen[screen]}`] = num_blocks[index] || num_blocks[0]
          }
          e.setProps(attrs)
        }
      })
    },
  },
};
</script>

<style lang="scss">
.title-layout {
  padding: 0 14px;
}
.row-layout {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.layout-wrapper {
  padding: 12px 8px 0px !important;
}

.layout-item {
  height: 28px;
  margin-left: 0px !important;
  margin-right: 0px !important;
  
  .cell {
    background-color: #848484;
    border-radius: 2px;
    padding: 0 !important;
  }
  .cell-12 {
    width: calc(100% - 2px);
    flex: 0 0 calc(100% - 2px);
    margin: 0 1px;
  }
  .cell-9 {
    width: calc(75% - 2px);
    flex: 0 0 calc(75% - 2px);
    margin: 0 1px;
  }
  .cell-8 {
    width: calc(200%/3 - 2px);
    flex: 0 0 calc(200%/3 - 2px);
    margin: 0 1px;
  }
  .cell-6 {
    width: calc(50% - 2px);
    flex: 0 0 calc(50% - 2px);
    margin: 0 1px;
  }
  .cell-4 {
    width: calc(100%/3 - 2px);
    flex: 0 0 calc(100%/3 - 2px);
    margin: 0 1px;
  }
  .cell-3 {
    width: calc(25% - 2px);
    flex: 0 0 calc(25% - 2px);
    margin: 0 1px;
  }
}

.layout-item.active {
  .cell {
    background-color: #FDB913;
  }
}
</style>
