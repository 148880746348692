<template>
  <div label-position="top" size="mini" :model="elementProps">
    <div class="py-2">
      <span class="setting-title">Type</span>
      <Select
        class="mt-2"
        :primaryColor="primaryColor"
        :value="getValue('border_style',elementProps[screen].border_style)"
        v-bind:changeValue="($event) => updateProps('border_style', $event)"
        :options="[
          {
            value: 'solid',
            label: 'Solid',
          },
          {
            value: 'dashed',
            label: 'Dashed',
          },
          {
            value: 'dotted',
            label: 'Dotted',
          }
        ]"
      />
    </div>
  </div>
</template>

<script>
import mixin from "./mixin";
import Select from "../common/Select.vue";

export default {
  mixins: [mixin],
  components: {
    Select,
  },
  props: {
    primaryColor: String,
    screen: String,
  },
  methods: {
    updateProps(name, value) {
      const screen = this.$props.screen;
      const data_screen = Object.assign({}, this.elementProps[screen])
      if(value ==  data_screen[name]) return;
      data_screen[name] = value
      this.elementPropsSetter({ [screen]: data_screen });
    },
  },
};
</script>
