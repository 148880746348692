<template>
  <div
    :id="`popupOverlay-${node.uuid}`"
    :data-id="node.uuid"
    class="sg-popupOverlay"
    :class="node.props.overlay && node.props.showPopup ? 'show-overlay' : ''"
    data-effects="fade"
    :data-link-button="node.props.link_button ? 1 : 0"
    :data-showPopup="node.props.showPopup ? 1 : 0"
    :data-display-mobile="node.props.mobile.display == 'none' ? 0 : 1"
    :data-display-tablet="node.props.tablet.display == 'none' ? 0 : 1"
    :data-display-laptop="node.props.laptop.display == 'none' ? 0 : 1"
    :data-display-desktop="node.props.desktop.display == 'none' ? 0 : 1"
    :style="{
      'background-color': `${node.props.overlay_color}`,
      'opacity': `${node.props.overlay_opacity}`,
      
    }"
    @click="
      ($event) => {
        if (node.props.overlay_close) {
          node.setProps({ showPopup: false }, true);
          editor.selectNode(null);
        }
        // $event.stopPropagation();
      }
    "
  >
    <v-style>
      #popupOverlay-{{ node.uuid }} {
        display: none;
      }
      #popupOverlay-{{ node.uuid }}.show-overlay {
        display: block;
      }

      @media (max-width: 767px){ 
        #popupOverlay-{{ node.uuid }} {
          {{ node.props.mobile.display == 'none' ? `display: none !important;` : '' }}
        }
      }
      @media (max-width: 991px) and (min-width: 768px){
        #popupOverlay-{{ node.uuid }} {
          {{ node.props.tablet.display == 'none' ? `display: none !important;` : '' }}
        }
      } 
      @media (max-width:1199px) and (min-width: 992px){ 
        #popupOverlay-{{ node.uuid }} {
          {{ node.props.laptop.display == 'none' ? `display: none !important;` : '' }}
        }
      } 
      @media (min-width: 1200px){
        #popupOverlay-{{ node.uuid }} {
          {{ node.props.desktop.display == 'none' ? `display: none !important;` : '' }}
        }
      }
    </v-style>
  </div>
</template>
<script>
import Node from "../../../../core/Node";

export default {
  props: {
    editor: Object,
    node: Node,
  },
  watch: {
    editor(oldValue, newValue) {},
  },
  data(props) {
    return {};
  },
};
</script>

