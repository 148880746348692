<template>
  <div
    data-label="Google Maps"
    data-key="google_map"
    class="sg-wrap module"
    :id="`element-${node.uuid}`"
    @mouseover="isHovering = true"
    @mouseout="isHovering = false"
    :class="{ 'dragging': dragging == node.uuid }"
  >
    <div
      class="sg-module"
      :class="extra_class"
      id="map"
      style="width: 100%; height: 400px;"
    >
      
    </div>

    <ToolBar
      ref="google_map"
      :toolbar_id="`google_map`"
      class="container-toolbar"
      :class="{ 'container-toolbar-show': isHovering, 'is-dragging': dragging == node.uuid }"
    >
    </ToolBar>

    <v-style>
      {{ custom_css }}
      
      @media (max-width: 767px) {
        #element-{{ node.uuid }} {
          {{ getDisplayValue('mobile') }}
          {{ getMarginValue('mobile') }}
          {{ getPaddingValue('mobile') }}
          {{ getBackgroundValue('mobile') }}
          {{ getBorderValue('mobile') }}
          {{ getBorderRadiusValue('mobile') }}
          {{ getOpacityValue('mobile') }}
          {{ getShadowValue('mobile') }}
          {{ getOtherStyle('mobile') }}
        }
      } 
      @media (max-width: 991px) and (min-width: 768px) {
        #element-{{ node.uuid }} {
          {{ getDisplayValue('tablet') }}
          {{ getMarginValue('tablet') }}
          {{ getPaddingValue('tablet') }}
          {{ getBackgroundValue('tablet') }}
          {{ getBorderValue('tablet') }}
          {{ getBorderRadiusValue('tablet') }}
          {{ getOpacityValue('tablet') }}
          {{ getShadowValue('tablet') }}
          {{ getOtherStyle('tablet') }}
        }
      } 
      @media (max-width: 1199px) and (min-width: 992px) {
        #element-{{ node.uuid }} {
          {{ getDisplayValue('laptop') }}
          {{ getMarginValue('laptop') }}
          {{ getPaddingValue('laptop') }}
          {{ getBackgroundValue('laptop') }}
          {{ getBorderValue('laptop') }}
          {{ getBorderRadiusValue('laptop') }}
          {{ getOpacityValue('laptop') }}
          {{ getShadowValue('laptop') }}
          {{ getOtherStyle('laptop') }}
        }
      } 
      @media (min-width: 1200px) { 
        #element-{{ node.uuid }} {
          {{ getDisplayValue('desktop') }}
          {{ getMarginValue('desktop') }}
          {{ getPaddingValue('desktop') }}
          {{ getBackgroundValue('desktop') }}
          {{ getBorderValue('desktop') }}
          {{ getBorderRadiusValue('desktop') }}
          {{ getOpacityValue('desktop') }}
          {{ getShadowValue('desktop') }}
          {{ getOtherStyle('desktop') }}
        }
      }
    </v-style>
    <v-script>
      $(document).ready(function () {
        function initMap() {
          var mapOptions = {
            center: { lat: 37.7749, lng: -122.4194 },
            zoom: 12
          };
          var map = new google.maps.Map(document.getElementById('map'), mapOptions);
        }
        initMap();
      });
    </v-script>
  </div>
</template>

<script>
import ToolBar from "../../elements/utils/ToolBar.vue";
import elementStyleMixin from "../../elements/elementStyleMixin";
import ExtraClass from "../../elements/otherSettings/ExtraClass.vue";

import Dimension from "./settings/Dimension.vue";
import Location from "./settings/Location.vue";
import Views from "./settings/Views.vue";

import Visibility from "../../elements/designSettings/Visibility.vue";
import Spacing from "../../elements/designSettings/Spacing.vue";
import Background from "../../elements/designSettings/Background.vue";
import Border from "../../elements/designSettings/Border.vue";
import Corner from "../../elements/designSettings/Corner.vue";
import Shadow from "../../elements/designSettings/Shadow.vue";
import Opacity from "../../elements/designSettings/Opacity.vue";

import { designStyles, otherProps, otherStyles, actionTypes } from '@/constants';
import CustomCSS from '../../elements/styleSettings/CustomCSS.vue';
import Animations from '../../elements/designSettings/Animations.vue';

export default {
  components: { ToolBar },
  computed: {
    dragging: function () {
      return this.editor?.draggedNode?.uuid
    },
  },
  data() {
    return {
      isHovering: false,
    };
  },
  mixins: [elementStyleMixin],
  props: {
    ...otherProps,
    extra_class: String,
    component: String,
    location_type: String,
    address: String,
    latitude: String,
    longitude: String,
    view_mode: String,
    desktop: Object,
    laptop: Object,
    tablet: Object,
    mobile: Object,
  },
  inject: ["node","editor"],
  mounted() {
    // var $iframe = $("#designEditor").contents();
    // var script = document.createElement("script");
    // script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDLPebA_baWYMkLhuEVB-YtnBQIz571zlA`;
    // $iframe.find('head').append(script);
  },
  watch: {

  },
  craft: {
    defaultProps: {
      ...otherStyles,
      extra_class: "",
      location_type: "address",
      address: "99 Đàm Quang Trung, Long Biên, Hà Nội",
      latitude: "",
      longitude: "",
      view_mode: "map",
      desktop: {
        ...designStyles,
        height: '400',
        zoom: 15,
      },
      laptop: {
        ...designStyles,
      },
      tablet: {
        ...designStyles,
      },
      mobile: {
        ...designStyles,
      },
    },
    settings: {
      settings: {
        Location,
        Dimension: { Dimension, multi_screen: true },
        Views,
        "Extra Class": ExtraClass,
        "Custom CSS": CustomCSS,
      },
      design: {
        Visibility,
        Spacing: { Spacing, multi_screen: true },
        Background: { Background, multi_screen: true },
        Border: { Border, multi_screen: true },
        Corner: { Corner, multi_screen: true },
        Shadow: { Shadow, multi_screen: true },
        Opacity: { Opacity, multi_screen: true },
      },
      others: {
        Animations: { Animations, actions: actionTypes }
      }
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
