<template>
  <div>
    <el-upload
      class="upload-image"
      drag
      :headers="{'x-access-token': user.token, 'x-shop-domain': user.store?.domain}"
      accept="image/*"
      name="file"
      :data="{'type': 'image'}"
      :action="`https://api-onepage.salegate.io/admin/v1/upload_file?upload_id=${node_id}`"
      :on-success="onSuccess"
      :on-error="onError"
      :before-upload="beforeUpload"
    >
      <img v-if="imageUrl" :src="imageUrl" class="image-preview" />
      <div v-else class="py-3">
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">Drop file here or <em>click to upload</em></div>
      </div>
      <div v-if="imageUrl && !loading" class="image-overlay">
        <div  style="position: relative; width: 100%; height: 100%">
          <svg @click="(e) => removeImage(e)" class="close-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="#FFFFFF" fill="none" stroke-linecap="round" stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
          </svg>
          <div class="text-overlay">Replace Image</div>
        </div>
      </div>
      <div v-if="loading" class="loading-overlay">
        <div class="loading-wrapper" style="position: relative; width: 100%; height: 100%">
          <span class="loading"></span>
        </div>
      </div>
    </el-upload>
  </div>
</template>

<script>
import Cookies from 'js-cookie';
export default {
  components: {
    name: 'Upload'
  },
  props: {
    value: String,
    changeValue: Function,
    node_id: String
  },
  data() {
    return {
      imageUrl: this.value,
      loading: false,
      user: JSON.parse(Cookies.get('user_onepage') || '{}'),
    };
  },
  watch: {
    value: function (newVal, oldVal) {
      this.imageUrl = newVal;
    }
  },
  methods: {
    removeImage(e) {
      e.stopPropagation()
      this.imageUrl = ""
      this.$props.changeValue("")
    },
    onSuccess(res) {
      this.loading = false;
      this.imageUrl = res.data.url;
      this.$props.changeValue(res.data.url, res?.data?.upload_id)
    },
    beforeUpload() {
      this.loading = true;
    },
    onError(error) {
      this.loading = false;
      this.$toastr.e(error?.response?.data?.message);
    }
  }
};
</script>

<style lang="scss">
  .upload-image {
    margin-top: 8px;
    width: 100%;
    position: relative;
    .el-upload {
      width: 100%;
    }
    .el-upload-dragger {
      width: 100%;
      height: auto;
      background-color: #222222;
      border: none;
      border-radius: 0px;
      .el-upload__text {
        color: #F5F5F5;
      }
      .el-icon-upload {
        margin: 0;
      }
    }
    .el-upload-list {
      display: none;
    }
    .image-preview {
      opacity: 1;
      width: 100%;
      height: auto;
      transition: all 0.3s ease;
    }
    .image-overlay {
      transition: all 0.3s ease;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      opacity: 0;
      .close-icon {
        position: absolute;
        top: 10px;
        right: 10px;
      }
      .text-overlay {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
      }
    }
    .loading-overlay {
      transition: all 0.3s ease;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      opacity: 1;
      z-index: 10;
      .loading-wrapper {
        position: relative;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.7);
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .loading {
        width: 28px;
        height: 28px;
        border: 3px solid #FFF;
        border-bottom-color: transparent;
        border-radius: 50%;
        display: inline-block;
        box-sizing: border-box;
        animation: rotation 1s linear infinite;
      }
    }
    &:hover {
      .image-preview {
        opacity: 0.3;
      }
      .image-overlay {
        opacity: 1;
      }
    }
    @keyframes rotation {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    } 
  }
</style>