<template>
  <el-form label-position="top" size="mini" :model="elementProps">
    <el-form-item label="Title Tag">
      <ToggleButton
        :value="elementProps.headingType"
        v-bind:changeValue="updateHeadingType"
        :options="[
          {
            label: 'H1',
            value: 'h1',
          },
          {
            label: 'H2',
            value: 'h2',
          },
          {
            label: 'H3',
            value: 'h3',
          },
        ]"
      />
    </el-form-item>
  </el-form>
</template>

<script>
import { settingMixin } from "@";
import ToggleButton from "../../../elements/common/ToggleButton.vue";
export default {
  mixins: [settingMixin],
  components: {
    ToggleButton,
  },
  methods: {
    updateHeadingType(headingType) {
      var style = {};
      switch (headingType) {
        case "h1":
          style = {
            font_size: 35,
            line_height: 1.2,
            letter_spacing: 0,
          };
          break;
        case "h2":
          style = {
            font_size: 20,
            line_height: 1.2,
            letter_spacing: 2,
          };
          break;
        case "h3":
          style = {
            font_size: 26,
            line_height: 1.2,
            letter_spacing: 0,
          };
          break;
      }

      const desktop = Object.assign({}, this.elementProps["desktop"]);
      const laptop = Object.assign({}, this.elementProps["laptop"]);
      const tablet = Object.assign({}, this.elementProps["tablet"]);
      const mobile = Object.assign({}, this.elementProps["mobile"]);

      this.elementPropsSetter({
        headingType,
        desktop: { ...desktop, ...style },
        laptop: { ...laptop, ...style },
        tablet: { ...tablet, ...style },
        mobile: { ...mobile, ...style },
      });
    },
  },
};
</script>
