<template>
  <div
    data-label="Article"
    data-key="article"
    class="sg-wrap module"
    :id="`element-${node.uuid}`"
  >
    <div
      :class="`sg-module`"
      data-article="{}"
    >
        <div class="sg-item-content">
          <div
          v-if="editor?.findNode(node?.uuid)?.children?.length == 0"
          class="text-center drop-element"
          :class="{ dragging_space: dragging == node.uuid }"
        >
          <span>Add element</span>
        </div>
          <slot> </slot>
        </div>
    </div>
    <v-style>
      #element-{{ node.uuid }} form { 
        z-index: 1!important; 
      } 
      @media (max-width: 767px){ 
        #element-{{ node.uuid }} {
          {{ getDisplayValue('mobile') }}
          {{ getMarginValue('mobile') }}
          {{ getPaddingValue('mobile') }}
          {{ getBackgroundValue('mobile') }}
          {{ getBorderValue('mobile') }}
          {{ getBorderRadiusValue('mobile') }}
          {{ getOpacityValue('mobile') }}
          {{ getShadowValue('mobile') }}
          {{ getOtherStyle('mobile') }}
        }
      } 
      @media (max-width: 991px) and (min-width: 768px){ 
        #element-{{ node.uuid }} {
          {{ getDisplayValue('tablet') }}
          {{ getMarginValue('tablet') }}
          {{ getPaddingValue('tablet') }}
          {{ getBackgroundValue('tablet') }}
          {{ getBorderValue('tablet') }}
          {{ getBorderRadiusValue('tablet') }}
          {{ getOpacityValue('tablet') }}
          {{ getShadowValue('tablet') }}
          {{ getOtherStyle('tablet') }}
        }
      } 
      @media (max-width: 1199px) and (min-width: 992px){ 
        #element-{{ node.uuid }} {
          {{ getDisplayValue('laptop') }}
          {{ getMarginValue('laptop') }}
          {{ getPaddingValue('laptop') }}
          {{ getBackgroundValue('laptop') }}
          {{ getBorderValue('laptop') }}
          {{ getBorderRadiusValue('laptop') }}
          {{ getOpacityValue('laptop') }}
          {{ getShadowValue('laptop') }}
          {{ getOtherStyle('laptop') }}
        }
      } 
      @media (min-width: 1200px){ 
        #element-{{ node.uuid }} {
          {{ getDisplayValue('desktop') }}
          {{ getMarginValue('desktop') }}
          {{ getPaddingValue('desktop') }}
          {{ getBackgroundValue('desktop') }}
          {{ getBorderValue('desktop') }}
          {{ getBorderRadiusValue('desktop') }}
          {{ getOpacityValue('desktop') }}
          {{ getShadowValue('desktop') }}
          {{ getOtherStyle('desktop') }}
        } 
      }
    </v-style>
  </div>
</template>

<script>
import ToolBar from "../../elements/utils/ToolBar.vue";

import elementStyleMixin from "../../elements/elementStyleMixin";
import { request } from "../../../../../services";
import { computed } from "vue";
import { designStyles, otherProps, otherStyles, actionTypes } from "@/constants";

export default {
  components: { ToolBar },
  data() {
    return {
      isHovering: false,
    };
  },
  provide() {
    return {
      article: computed(() => JSON.parse(this.data_article||'{}')),
    };
  },
  watch: {
  },
  created() {
  },
  methods: {
  },
  inject: ["node", "editor"],
  mixins: [elementStyleMixin],
  props: {
    ...otherProps,
    section: String,
    component: String,
    
    extra_class: String,
    data_article:String,
    desktop: Object,
    laptop: Object,
    mobile: Object,
    tablet: Object,
  },
  computed: {
    dragging: function () {
      return this.editor?.draggedNode?.uuid
    },
  },
  craft: {
    defaultProps: {
      ...otherStyles,
      section: "module",
      desktop: {
        ...designStyles,
        padding_top: 30,
        padding_bottom: 30,
        padding_left: 30,
        padding_right: 30,
      },
      laptop: {
        ...designStyles,
      },
      tablet: {
        ...designStyles,
      },
      mobile: {
        ...designStyles,
      },
      elementStyle: {
        
      },
    },
    settings: {
      settings: {
        // Product,
        // "Form ID": Form,
        // "Extra Class": ExtraClass, "Custom CSS": CustomCSS,
      },
      design: {
        // Visibility,
        // Spacing: { Spacing, multi_screen: true },
        // Background: { Background, multi_screen: true },
        // Border: { Border, multi_screen: true },
        // Corner: { Corner, multi_screen: true },
        // Shadow: { Shadow, multi_screen: true },
        // Opacity: { Opacity, multi_screen: true },
      },
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
