<template>
    <div label-position="top" size="medium" :model="elementProps">
      <div class="d-flex flex-column py-2">
        <span class="setting-title">Money</span>
        <el-input-number 
          :value="elementProps.money"
          class="input-with-select mt-2 w-100"
          @input="updateCommonProps('money', $event)"
          size="medium"
          :min="1000"
          :controls="false"
        />
      </div>
    </div>
  </template>
  
  <script>
  import mixin from "../../../elements/styleSettings/mixin";
  import ColorPicker from "../../../elements/common/ColorPicker.vue";
  import Slider from "../../../elements/common/Slider.vue";
  import ToggleButton from "../../../elements/common/ToggleButton.vue";
  import Select from "../../../elements/common/Select.vue";
  import Node from "@/core/Node";
  import { makeId } from "@/constants";
  
  export default {
    mixins: [mixin],
    components: {ColorPicker, Slider, ToggleButton, Select},
    props: {
      node: Node,
      primaryColor: String,
      screen: String,
      activeFonts: Array,
    },
    data() {
      return {
        fontKey: makeId(20)
      }
    },
    watch: {
      activeFonts: function (newValue, oldValue) {
        this.fontKey = makeId(20)
      }
    },
    methods: {
      updateCommonProps(name, value) {
        if(value ==  this.elementProps[name]) return;
        this.elementPropsSetter({ [name]: value });
      },
    },
  };
  </script>
  
  <style scoped>
    
  </style>
  