<template>
  <div 
    data-label="Heading" 
    data-key="heading" 
    section="heading"
    class="sg-wrap heading block" 
    :style="blockCSS" 
    @mouseover="isHovering=true" 
    @mouseout="isHovering=false"
   >
    <div class="text-editor" :class="extra_class" :id="`element-${node.uuid}`">
      <Editor
        :tag="headingType"
        :value="content"
        @input="updateContent"
        ref="heading_editor"
        :node="node"
        :class="[isSelected ? 'editing' : '']"
      ></Editor>
    </div>
    <ToolBar ref="heading_toolbar"
     :heading_refs="heading_refs" 
     :toolbar_id="`heading`" 
     class="container-toolbar" 
     :class="{'container-toolbar-show': isHovering && !isSelected, 'is-dragging': dragging == node.uuid }"

     >
    </ToolBar>
     <div v-if="dragging == node.uuid" class="dragging"></div>

    <v-style>
      {{ custom_css }}
      @media (max-width: 767px){
        div[data-key="heading"]:has(> #element-{{ node.uuid }}) {
          {{ getDisplayValue('mobile') }}
        }
        #element-{{ node.uuid }} {
          {{ getMarginValue('mobile') }}
        }
        #element-{{ node.uuid }} h1, #element-{{ node.uuid }} h2, #element-{{ node.uuid }} h3 {
          {{ getPaddingValue('mobile') }}
          {{ getBackgroundValue('mobile') }}
          {{ getBorderValue('mobile') }}
          {{ getBorderRadiusValue('mobile') }}
          {{ getOpacityValue('mobile') }}
          {{ getShadowValue('mobile') }}
          {{ getOtherStyle('mobile') }}
          text-align: {{ getValue('alignment','mobile') }} !important;
          font-weight: {{ getValue('font_weight','mobile') }}!important;
          {{ getValue('font_size', 'mobile') > 0 ? `font-size: ${getValue('font_size','mobile')}px;` : '' }}
          {{ getValue('color', 'mobile') ? `color: ${getValue('color','mobile')};` : '' }}
          line-height: {{ getValue('line_height','mobile') }}em !important;
          letter-spacing: {{ getValue('letter_spacing','mobile') }}px !important;
          font-family: {{ getValue('font_family','mobile') }};
          text-transform: {{ getValue('text_transform','mobile') }} !important;
          text-shadow: {{getValue('enable_text_shadow','mobile')?`${getValue('text_shadow_x','mobile') || 0}px ${getValue('text_shadow_y','mobile') || 0}px ${getValue('text_shadow_blur','mobile') || 0}px ${getValue('text_shadow_color','mobile') || 'rgb(255, 255, 255)'};` : Object.keys(mobile).includes('enable_text_shadow')?'none !important':''}}
        }
      }
      @media (max-width: 991px) and (min-width: 768px){
        div[data-key="heading"]:has(> #element-{{ node.uuid }}) {
          {{ getDisplayValue('tablet') }}
        }
        #element-{{ node.uuid }} {
          {{ getMarginValue('tablet') }}
        }
        #element-{{ node.uuid }} h1, #element-{{ node.uuid }} h2, #element-{{ node.uuid }} h3 {
          {{ getPaddingValue('tablet') }}
          {{ getBackgroundValue('tablet') }}
          {{ getBorderValue('tablet') }}
          {{ getBorderRadiusValue('tablet') }}
          {{ getOpacityValue('tablet') }}
          {{ getShadowValue('tablet') }}
          {{ getOtherStyle('tablet') }}
          text-align: {{ getValue('alignment','tablet') }};
          font-weight: {{ getValue('font_weight','tablet') }}!important;
          {{ getValue('font_size', 'tablet') > 0 ? `font-size: ${getValue('font_size','tablet')}px;` : '' }}
          {{ getValue('color', 'tablet') ? `color: ${getValue('color','tablet')};` : '' }}
          line-height: {{ getValue('line_height','tablet') }}em !important;
          letter-spacing: {{ getValue('letter_spacing','tablet') }}px !important;
          font-family: {{ getValue('font_family','tablet') }};
          text-transform: {{ getValue('text_transform','tablet') }} !important;
          text-shadow: {{getValue('enable_text_shadow','tablet')?`${getValue('text_shadow_x','tablet') || 0}px ${getValue('text_shadow_y','tablet') || 0}px ${getValue('text_shadow_blur','tablet') || 0}px ${getValue('text_shadow_color','tablet') || 'rgb(255, 255, 255)'};` : Object.keys(tablet).includes('enable_text_shadow')?'none !important':''}}
        }
      }
      @media (max-width: 1199px) and (min-width: 992px){
        div[data-key="heading"]:has(> #element-{{ node.uuid }}) {
          {{ getDisplayValue('laptop') }}
        }
        #element-{{ node.uuid }} {
          {{ getMarginValue('laptop') }}
        }
        #element-{{ node.uuid }} h1, #element-{{ node.uuid }} h2, #element-{{ node.uuid }} h3 {
          {{ getPaddingValue('laptop') }}
          {{ getBackgroundValue('laptop') }}
          {{ getBorderValue('laptop') }}
          {{ getBorderRadiusValue('laptop') }}
          {{ getOpacityValue('laptop') }}
          {{ getShadowValue('laptop') }}
          {{ getOtherStyle('laptop') }}
          text-align: {{ getValue('alignment','laptop') }};
          font-weight: {{ getValue('font_weight','laptop') }}!important;
          {{ getValue('font_size', 'laptop') > 0 ? `font-size: ${getValue('font_size','laptop')}px;` : '' }}
          {{ getValue('color', 'laptop') ? `color: ${getValue('color','laptop')};` : '' }}
          line-height: {{ getValue('line_height','laptop') }}em !important;
          letter-spacing: {{ getValue('letter_spacing','laptop') }}px !important;
          font-family: {{ getValue('font_family','laptop') }};
          text-transform: {{ getValue('text_transform','laptop') }} !important;
          text-shadow: {{getValue('enable_text_shadow','laptop')?`${getValue('text_shadow_x','laptop') || 0}px ${getValue('text_shadow_y','laptop') || 0}px ${getValue('text_shadow_blur','laptop') || 0}px ${getValue('text_shadow_color','laptop') || 'rgb(255, 255, 255)'};` : Object.keys(laptop).includes('enable_text_shadow')?'none !important':''}}
        }
      }
      @media (min-width: 1200px){
        div[data-key="heading"]:has(> #element-{{ node.uuid }}) {
          {{ getDisplayValue('desktop') }}
        }
        #element-{{ node.uuid }} {
          {{ getMarginValue('desktop') }}
        }
        #element-{{ node.uuid }} h1, #element-{{ node.uuid }} h2, #element-{{ node.uuid }} h3 {
          {{ getPaddingValue('desktop') }}
          {{ getBackgroundValue('desktop') }}
          {{ getBorderValue('desktop') }}
          {{ getBorderRadiusValue('desktop') }}
          {{ getOpacityValue('desktop') }}
          {{ getShadowValue('desktop') }}
          {{ getOtherStyle('desktop') }}
          text-align: {{ desktop.alignment }};
          font-weight: {{ desktop.font_weight }};
          {{ getValue('font_size', 'desktop') > 0 ? `font-size: ${getValue('font_size','desktop')}px;` : '' }}
          {{ getValue('color', 'desktop') ? `color: ${getValue('color','desktop')};` : '' }}
          line-height: {{ desktop.line_height }}em;
          letter-spacing: {{ desktop.letter_spacing }}px;
          font-family: {{ desktop.font_family }};
          text-transform: {{ desktop.text_transform }};
          {{ desktop.enable_text_shadow ? `text-shadow: ${desktop.text_shadow_x || 0}px ${desktop.text_shadow_y || 0}px ${desktop.text_shadow_blur || 0}px ${desktop.text_shadow_color || 'rgb(255, 255, 255)'};` : ''}}
        }
      }
    </v-style>
  </div>
</template>

<script>
import TitleTag from "./styleSettings/TitleTag.vue";
import Typography from "./styleSettings/Typography.vue";
import Visibility from "./designSettings/Visibility.vue";
import Spacing from "./designSettings/Spacing.vue";
import Background from "./designSettings/Background.vue";
import Border from "./designSettings/Border.vue";
import Corner from "./designSettings/Corner.vue";
import Shadow from "./designSettings/Shadow.vue";
import Opacity from "./designSettings/Opacity.vue";
import Editor from "./utils/Editor.vue";
import elementStyleMixin from "./elementStyleMixin";
import ToolBar from './utils/ToolBar.vue';
import { designStyles, otherProps, otherStyles, actionTypes } from "@/constants";
import AlignmentBase from "./styleSettings/AlignmentBase.vue";
import ExtraClass from "./otherSettings/ExtraClass.vue";
import TextShadow from "./styleSettings/TextShadow.vue";
import CustomCSS from "./styleSettings/CustomCSS.vue";
import Animations from "./designSettings/Animations.vue";

import Vue from 'vue';
Vue.component("v-style", {
  render(createElement) {
    return createElement("style", this.$slots.default);
  },
});

export default {
  mixins: [elementStyleMixin],
  components: { Editor, ToolBar },
  watch: {
    dragging: function (newValue, oldValue) {
      var $iframe = $("#designEditor").contents();
      var $element = $iframe.find(`#element-${this.node.uuid}`);
      if (newValue && newValue == this.node.uuid) {
        setTimeout(() => {
          $element.hide();
        },100);
      }else if (oldValue == this.node.uuid){
        $element.show();
      }
    },
  },
  computed: {
    dragging: function () {
      return this.editor?.draggedNode?.uuid
    },
  },
  props: {
    ...otherProps,
    headingType: String,
    extra_class: String,
    content: String,
    isSelected: Boolean,
    
    desktop: Object,
    laptop: Object,
    tablet: Object,
    mobile: Object,
  },
  inject: ["node",'editor'],
  methods: {
    updateContent(content) {
      this.node.setProps({ content });
    },
  },
  data() {
    return {
      isHovering: false,
      heading_refs: this.$refs,
      // designStyles
    }
  },
  craft: {
    defaultProps: {
      ...otherStyles,
      typographyActives: [
        'font-size',
        'font-weight',
        'line-height',
        'letter-spacing',
        'color',
        'font-family',
        'text-transform',
      ],
      desktop: {
        ...designStyles,
        font_weight: '600',
        font_size: 35,
        line_height: 1.2,
        letter_spacing: 0,
        color: 'rgb(0, 0, 0)',
        text_transform: 'none',
        font_family: 'inherit',
        enable_text_shadow: false,
        text_shadow_x: 0,
        text_shadow_y: 0,
        text_shadow_blur: 0,
        text_shadow_color: 'rgb(255, 255, 255)',
        alignment: 'center'
      },
      laptop: {
        ...designStyles,
      },
      tablet: {
        ...designStyles,
      },
      mobile: {
        ...designStyles,
      },
      headingType: "h1",
      extra_class: "",
      content: "Your heading text goes here",
      elementStyle: {
      }
    },
    settings: {
      settings: {
        "Title Tag": TitleTag,
        Typography: {Typography, multi_screen: true},
        "Text Shadow": {"Text Shadow": TextShadow, multi_screen: true},
        "Alignment": {"Alignment": AlignmentBase, multi_screen: true},
        "Extra Class": ExtraClass, "Custom CSS": CustomCSS,
        // Margin,
        // Padding,
      },
      design: {
        Visibility,
        Spacing: { Spacing, multi_screen: true },
        Background: { Background, multi_screen: true },
        Border: { Border, multi_screen: true },
        Corner: { Corner, multi_screen: true },
        Shadow: { Shadow, multi_screen: true },
        Opacity: { Opacity, multi_screen: true },
      },
      others: {
        Animations: { Animations, actions: actionTypes }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.block {
  width: 100%;
  box-sizing: border-box;
  z-index: 2;
}
.heading {
  width: 100%;
  position: relative;
}

.text-editor {
  position: relative;
}
.dragging{
  position:absolute;
  top:0;
  bottom: 0;
  left: 0;
  right:0;
  background: greenyellow;
  z-index: 10;
}

.container .container-toolbar {
  display: none;
}

.container-toolbar-show {
  display: block !important;
}
</style>
