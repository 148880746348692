export const icons = {
  desktop: '<svg viewBox="0 0 24 24" width="20" height="20" stroke="#FFF" strokeWidth="1.5" fill="none" strokeLinecap="round" strokeLinejoin="round"><rect x="2" y="3" width="20" height="14" rx="2" ry="2"></rect><line x1="8" y1="21" x2="16" y2="21"></line><line x1="12" y1="17" x2="12" y2="21"></line></svg>',
  laptop: 
    `<svg viewBox="0 0 20 20" width="16" height="16">
      <g transform="matrix(0.234313, 0, 0, 0.342009, 0, -4.59626)">
        <path style="fill: #FFF" d="M77.45,57.695v-3.16V35.567V16.599c0-1.736-1.421-3.16-3.16-3.16H11.066
        c-1.739,0-3.164,1.417-3.164,3.16v18.968v18.968v3.16L0,67.179c0,2.613,2.122,4.738,4.742,4.738h75.872
        c2.616,0,4.742-2.126,4.742-4.738L77.45,57.695z M49.005,70.342H36.358c-0.437,0-0.791-0.351-0.791-0.791
        c0-0.44,0.354-0.791,0.791-0.791h12.648c0.433,0,0.791,0.351,0.791,0.791C49.785,69.992,49.438,70.342,49.005,70.342z
        M29.647,67.182l2.412-2.895h21.233l2.416,2.895H29.647z M73.001,52.104c0,1.525-1.242,2.759-2.756,2.759H15.11
        c-1.514,0-2.756-1.245-2.756-2.759V19.036c0-1.525,1.242-2.759,2.756-2.759h55.136c1.514,0,2.756,1.242,2.756,2.759
        C73.001,19.036,73.001,52.104,73.001,52.104z"></path>
      </g>
    </svg>`,
  tablet: 
    `<svg viewBox="0 0 16 20" width="16" height="18">
      <path style="fill: #FFF" d="M 1.28 0 L 14.719 0 C 15.426 0 16 0.561 16 1.249 L 16 18.75 C 16 19.441 15.426 20 14.719 20 L 1.28 20 C 0.573 20 0 19.441 0 18.75 L 0 1.249 C 0 0.561 0.573 0 1.28 0 Z"></path>
      <path style="fill: #202124" d="M 1.281 1.888 L 1.281 16.784 L 14.719 16.784 L 14.719 1.888 L 1.281 1.888 Z M 8.001 17.432 C 7.472 17.432 7.04 17.866 7.04 18.404 C 7.04 18.939 7.472 19.375 8.001 19.375 C 8.53 19.375 8.961 18.939 8.961 18.404 C 8.961 17.866 8.53 17.432 8.001 17.432 Z"></path>
    </svg>`,
  mobile:
    `<svg viewBox="0 0 11 18" width="11" height="18">
      <g transform="matrix(0.03526, 0, 0, 0.033059, -4.099396, 0)">
        <g>
          <circle cx="243.92" cy="23.18" r="1.606" style="fill: #FFF"></circle>
          <circle cx="248.616" cy="23.18" r="1.606" style="fill: #FFF"></circle>
          <circle cx="253.301" cy="23.18" r="1.606" style="fill: #FFF"></circle>
          <circle cx="257.987" cy="23.18" r="1.606" style="fill: #FFF"></circle>
          <circle cx="262.682" cy="23.18" r="1.606" style="fill: #FFF"></circle>
          <circle cx="267.377" cy="23.18" r="1.606" style="fill: #FFF"></circle>
          <circle cx="272.063" cy="23.18" r="1.606" style="fill: #FFF"></circle>
          <circle cx="276.749" cy="23.18" r="1.606" style="fill: #FFF"></circle>
          <circle cx="281.434" cy="23.18" r="1.606" style="fill: #FFF"></circle>
          <circle cx="286.149" cy="23.18" r="1.606" style="fill: #FFF"></circle>
          <circle cx="290.834" cy="23.18" r="1.606" style="fill: #FFF"></circle>
          <circle cx="295.52" cy="23.18" r="1.606" style="fill: #FFF"></circle>
          <circle cx="300.206" cy="23.18" r="1.606" style="fill: #FFF"></circle>
          <circle cx="304.901" cy="23.18" r="1.606" style="fill: #FFF"></circle>
          <path d="M392.923,0H151.576c-19.508,0-35.314,15.807-35.314,35.314v473.86c0,19.507,15.807,35.314,35.314,35.314h241.338
          c19.508,0,35.314-15.808,35.314-35.314V35.314C428.238,15.807,412.431,0,392.923,0z M368.233,20.971
          c2.151,0,3.901,1.75,3.901,3.901c0,2.161-1.759,3.901-3.901,3.901c-2.161,0-3.901-1.75-3.901-3.901
          C364.322,22.721,366.072,20.971,368.233,20.971z M230.476,17.604h87.219c3.089,0,5.575,2.496,5.575,5.565
          c0,3.079-2.486,5.565-5.575,5.565h-87.219c-3.089,0-5.575-2.496-5.575-5.565C224.911,20.101,227.387,17.604,230.476,17.604z
          M194.894,521.262h-8.587c-0.574,0-1.033-0.469-1.033-1.043c0-0.563,0.459-1.042,1.033-1.042h8.587
          c0.583,0,1.042,0.479,1.042,1.042C195.926,520.793,195.467,521.262,194.894,521.262z M194.894,517.073h-8.587
          c-0.574,0-1.033-0.459-1.033-1.033c0-0.573,0.459-1.042,1.033-1.042h8.587c0.583,0,1.042,0.469,1.042,1.042
          C195.926,516.614,195.467,517.073,194.894,517.073z M202.123,519.798c0,1.234-0.995,2.238-2.228,2.238
          c-1.234,0-2.238-1.004-2.238-2.238v-7.372h-14.124v7.372c0,1.234-0.994,2.238-2.228,2.238c-1.233,0-2.237-1.004-2.237-2.238
          v-9.601c0-1.233,1.004-2.237,2.237-2.237h18.59c1.233,0,2.228,1.004,2.228,2.237V519.798L202.123,519.798z M300.206,519.11
          c0,4.876-3.959,8.826-8.836,8.826h-38.623c-4.887,0-8.836-3.95-8.836-8.826v-7.727c0-4.877,3.949-8.826,8.836-8.826h38.623
          c4.877,0,8.836,3.949,8.836,8.826V519.11z M357.858,522.457h-7.01c-1.233,0-2.228-1.004-2.228-2.238
          c0-1.233,0.994-2.228,2.228-2.228h7.01c1.511,0,2.734-1.177,2.734-2.62c0-1.444-1.224-2.61-2.734-2.61h-5.91
          c-0.22,0.306-0.497,0.459-0.994,0.497c-0.124,0.066-0.335-0.029-0.487-0.096l-3.644-1.54c-0.44-0.191-0.736-0.621-0.736-1.1
          c0-0.478,0.287-0.918,0.727-1.1l3.644-1.539c0.439-0.191,0.966-0.086,1.31,0.258c0.048,0.048,0.096,0.096,0.134,0.153h5.958
          c3.969,0,7.19,3.184,7.19,7.086C365.048,519.282,361.827,522.457,357.858,522.457z M404.685,491.513H136.859V54.449h267.826
          V491.513z" style="fill: #FFF"></path>
        </g>
      </g>
    </svg>`,
  small_desktop: `
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M12.6666 3.33333H3.33331C2.96512 3.33333 2.66665 3.63181 2.66665 4V10C2.66665 10.3682 2.96512 10.6667 3.33331 10.6667H12.6666C13.0348 10.6667 13.3333 10.3682 13.3333 10V4C13.3333 3.63181 13.0348 3.33333 12.6666 3.33333ZM3.33331 2C2.22874 2 1.33331 2.89543 1.33331 4V10C1.33331 11.1046 2.22874 12 3.33331 12H12.6666C13.7712 12 14.6666 11.1046 14.6666 10V4C14.6666 2.89543 13.7712 2 12.6666 2H3.33331Z" fill="#6F767E"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M4.66669 13.3334C4.66669 12.9652 4.96516 12.6667 5.33335 12.6667H10.6667C11.0349 12.6667 11.3334 12.9652 11.3334 13.3334C11.3334 13.7016 11.0349 14.0001 10.6667 14.0001H5.33335C4.96516 14.0001 4.66669 13.7016 4.66669 13.3334Z" fill="#6F767E"/>
    </svg>  
  `,
  small_laptop: `
    <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M19 5H5C4.44772 5 4 5.44772 4 6V15C4 15.5523 4.44772 16 5 16H19C19.5523 16 20 15.5523 20 15V6C20 5.44771 19.5523 5 19 5ZM5 3C3.34315 3 2 4.34315 2 6V15C2 16.6569 3.34315 18 5 18H19C20.6569 18 22 16.6569 22 15V6C22 4.34315 20.6569 3 19 3H5Z" fill="#6F767E"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M1 20C1 19.4477 1.44772 19 2 19H22C22.5523 19 23 19.4477 23 20C23 20.5523 22.5523 21 22 21H2C1.44772 21 1 20.5523 1 20Z" fill="#6F767E"/>
    </svg>
  `,
  small_tablet: `
    <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M18 4H6C5.44771 4 5 4.44772 5 5V19C5 19.5523 5.44772 20 6 20H18C18.5523 20 19 19.5523 19 19V5C19 4.44772 18.5523 4 18 4ZM6 2C4.34315 2 3 3.34315 3 5V19C3 20.6569 4.34315 22 6 22H18C19.6569 22 21 20.6569 21 19V5C21 3.34315 19.6569 2 18 2H6Z" fill="#6F767E"/>
      <path d="M13 18C13 18.5523 12.5523 19 12 19C11.4477 19 11 18.5523 11 18C11 17.4477 11.4477 17 12 17C12.5523 17 13 17.4477 13 18Z" fill="#6F767E"/>
    </svg>
  `,
  small_mobile: `
    <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M9 4H8C7.44771 4 7 4.44772 7 5V19C7 19.5523 7.44772 20 8 20H16C16.5523 20 17 19.5523 17 19V5C17 4.44772 16.5523 4 16 4H15C15 4.82843 14.3284 5.5 13.5 5.5H10.5C9.67157 5.5 9 4.82843 9 4ZM16 2C17.6569 2 19 3.34315 19 5V19C19 20.6569 17.6569 22 16 22H8C6.34315 22 5 20.6569 5 19V5C5 3.34315 6.34315 2 8 2H16Z" fill="#6F767E"/>
    </svg>
  `
}