<template>
  <div
    data-label="Row"
    data-key="row"
    class="container sg-row-layout"
    :class="extra_class"
    @mouseover="isHovering=true" 
    @mouseout="isHovering=false"
    :id="`element-${node.uuid}`"
    :layout_desktop="desktop.layout"
    :layout_laptop="laptop.layout"
    :layout_tablet="tablet.layout"
    :layout_mobile="mobile.layout"
  >
    <div class="sg-row">
    <slot />
    </div>
    <ToolBar ref="container" :toolbar_id="`row`" class="container-toolbar" :class="{'container-toolbar-show': isHovering}"></ToolBar>
    <div v-if="dragging == node.uuid" class="dragging position-ab"></div>
    <v-style>
      {{ custom_css }}
      #element-{{ node.uuid }} >.sg-column {
        display: {{ node.children.length > 1 ? 'flex' : 'block' }};
        flex-direction: column;
        justify-content: {{ equal_height ? vertical_align : 'flex-start' }};
      }
      #element-{{ node.uuid }} >.sg-row {
        flex-wrap: wrap;
      }
      @media (max-width: 767px) {
        #element-{{ node.uuid }} >.sg-row {
          display: {{ mobile.display == 'block' ? 'flex' : mobile.display }};
          max-width: {{ checkFullWidth(full_width, getValue('width','mobile'), getValue('width_type','mobile')) ? '100%' : getValue('width','mobile') + getValue('width_type','mobile') }} !important;
          margin: {{ getValue('margin_top','mobile') }}px {{ getValue('margin_right','mobile') }}px {{ getValue('margin_bottom','mobile') }}px {{ getValue('margin_left','mobile') }}px;
          padding-top: {{`${getValue('padding_top','mobile')}px`}};
          padding-right: {{`${getValue('padding_right','mobile')}px`}};
          padding-bottom: {{`${getValue('padding_bottom','mobile')}px`}};
          padding-left: {{`${getValue('padding_left','mobile')}px`}};
          {{ getValue('background_type','mobile') == 'color' && getValue('background_color','mobile') ? `background-color: ${getValue('background_color','mobile')};` : ''}}
          {{ getValue('background_type','mobile') == 'image' && getValue('background_image','mobile') ? `background-image: url(${getValue('background_image','mobile')});` : ''}}
          {{ getValue('background_type','mobile') == 'image' && getValue('background_size','mobile') ? `background-size: ${getValue('background_size','mobile')};` : ''}}
          {{ getValue('background_type','mobile') == 'image' && getValue('background_position','mobile') ? `background-position: ${getValue('background_position','mobile')};` : ''}}
          {{ getValue('background_type','mobile') == 'image' && getValue('background_repeat','mobile') ? `background-repeat: ${getValue('background_repeat','mobile')};` : ''}}
          {{ getValue('background_type','mobile') == 'image' && getValue('background_attachment','mobile') ? `background-attachment: ${getValue('background_attachment','mobile')};` : ''}}
          {{ getValue('border_style','mobile') ? `border-style: ${getValue('border_style','mobile')};` : '' }}
          {{ getValue('border_color','mobile') ? `border-color: ${getValue('border_color','mobile')};` : '' }}
          {{ getValue('border_width','mobile') ? `border-width: ${getValue('border_width','mobile')}px;` : '' }}
          {{ getValue('border_top_left_radius','mobile') ? `border-top-left-radius: ${getValue('border_top_left_radius','mobile')}px;` : '' }}
          {{ getValue('border_top_right_radius','mobile') ? `border-top-right-radius: ${getValue('border_top_right_radius','mobile')}px;` : '' }}
          {{ getValue('border_bottom_left_radius','mobile') ? `border-bottom-left-radius: ${getValue('border_bottom_left_radius','mobile')}px;` : '' }}
          {{ getValue('border_bottom_right_radius','mobile') ? `border-bottom-right-radius: ${getValue('border_bottom_right_radius','mobile')}px;` : '' }}
          {{ getValue('opacity','mobile') ? `opacity: ${getValue('opacity','mobile')};` : '' }}
          {{ getValue('shadow_style','mobile') == 'drop' ? `filter: drop-shadow(${getValue('shadow_x','mobile') || 0}px ${getValue('shadow_y','mobile') || 0}px ${getValue('shadow_blur','mobile') || 0}px ${getValue('shadow_color','mobile') || 'rgb(255, 255, 255)'});` : ''}}
          {{ getValue('shadow_style','mobile') == 'box' ? `box-shadow: ${getValue('shadow_type','mobile') == 'inset' ? 'inset' : ''} ${getValue('shadow_x','mobile') || 0}px ${getValue('shadow_y','mobile') || 0}px ${getValue('shadow_spread','mobile') || 0}px ${getValue('shadow_blur','mobile') || 0}px ${getValue('shadow_color','mobile') || 'rgb(255, 255, 255)'};` : ''}}
        }
        #element-{{ node.uuid }} >.sg-row > [data-layout-sm="12"] {
          width: calc(100% - {{ `${2*Number.parseInt(column_gap)}px` }}) !important;
          flex: 0 0 calc(100% - {{ `${2*Number.parseInt(column_gap)}px` }});
          margin: {{ `0 ${column_gap}px` }} !important;
        }
        #element-{{ node.uuid }} >.sg-row > [data-layout-sm="9"] {
          width: calc(75% - {{ '5px' }}) !important;
          flex: 0 0 calc(75% - {{ '5px' }});
          margin: {{ `0 ${column_gap}px` }} !important;
        }
        #element-{{ node.uuid }} >.sg-row > [data-layout-sm="8"] {
          width: calc(200%/3 - {{ `${2*Number.parseInt(column_gap)}px` }}) !important;
          flex: 0 0 calc(200%/3 - {{ `${2*Number.parseInt(column_gap)}px` }});
          margin: {{ `0 ${column_gap}px` }} !important;
        }
        #element-{{ node.uuid }} >.sg-row > [data-layout-sm="6"] {
          width: calc(50% - {{ `${2*Number.parseInt(column_gap)}px` }}) !important;
          flex: 0 0 calc(50% - {{ `${2*Number.parseInt(column_gap)}px` }});
          margin: {{ `0 ${column_gap}px` }} !important;
        }
        #element-{{ node.uuid }} >.sg-row > [data-layout-sm="4"] {
          width: calc(100%/3 - {{ `${2*Number.parseInt(column_gap)}px` }}) !important;
          flex: 0 0 calc(100%/3 - {{ `${2*Number.parseInt(column_gap)}px` }});
          margin: {{ `0 ${column_gap}px` }} !important;
        }
        #element-{{ node.uuid }} >.sg-row > [data-layout-sm="3"] {
          width: calc(25% - {{ `${2*Number.parseInt(column_gap)}px` }}) !important;
          flex: 0 0 calc(25% - {{ `${2*Number.parseInt(column_gap)}px` }});
          margin: {{ `0 ${column_gap}px` }} !important;
        }
      }
      @media (max-width: 991px) and (min-width: 768px) {
        #element-{{ node.uuid }} >.sg-row {
          display: {{ tablet.display == 'block' ? 'flex' : tablet.display }};
          max-width: {{ checkFullWidth(full_width, getValue('width','tablet'), getValue('width_type','tablet')) ? '100%' : getValue('width','tablet') + getValue('width_type','tablet') }} !important;
          margin: {{ getValue('margin_top','tablet') }}px {{ getValue('margin_right','tablet') }}px {{ getValue('margin_bottom','tablet') }}px {{ getValue('margin_left','tablet') }}px;
          padding-top: {{`${getValue('padding_top','tablet')}px`}};
          padding-right: {{`${getValue('padding_right','tablet')}px`}};
          padding-bottom: {{`${getValue('padding_bottom','tablet')}px`}};
          padding-left: {{`${getValue('padding_left','tablet')}px`}};
          {{ getValue('background_type','tablet') == 'color' && getValue('background_color','tablet') ? `background-color: ${getValue('background_color','tablet')};` : ''}}
          {{ getValue('background_type','tablet') == 'image' && getValue('background_image','tablet') ? `background-image: url(${getValue('background_image','tablet')});` : ''}}
          {{ getValue('background_type','tablet') == 'image' && getValue('background_size','tablet') ? `background-size: ${getValue('background_size','tablet')};` : ''}}
          {{ getValue('background_type','tablet') == 'image' && getValue('background_position','tablet') ? `background-position: ${getValue('background_position','tablet')};` : ''}}
          {{ getValue('background_type','tablet') == 'image' && getValue('background_repeat','tablet') ? `background-repeat: ${getValue('background_repeat','tablet')};` : ''}}
          {{ getValue('background_type','tablet') == 'image' && getValue('background_attachment','tablet') ? `background-attachment: ${getValue('background_attachment','tablet')};` : ''}}
          {{ getValue('border_style','tablet') ? `border-style: ${getValue('border_style','tablet')};` : '' }}
          {{ getValue('border_color','tablet') ? `border-color: ${getValue('border_color','tablet')};` : '' }}
          {{ getValue('border_width','tablet') ? `border-width: ${getValue('border_width','tablet')}px;` : '' }}
          {{ getValue('border_top_left_radius','tablet') ? `border-top-left-radius: ${getValue('border_top_left_radius','tablet')}px;` : '' }}
          {{ getValue('border_top_right_radius','tablet') ? `border-top-right-radius: ${getValue('border_top_right_radius','tablet')}px;` : '' }}
          {{ getValue('border_bottom_left_radius','tablet') ? `border-bottom-left-radius: ${getValue('border_bottom_left_radius','tablet')}px;` : '' }}
          {{ getValue('border_bottom_right_radius','tablet') ? `border-bottom-right-radius: ${getValue('border_bottom_right_radius','tablet')}px;` : '' }}
          {{ getValue('opacity','tablet') ? `opacity: ${getValue('opacity','tablet')};` : '' }}
          {{ getValue('shadow_style','tablet') == 'drop' ? `filter: drop-shadow(${getValue('shadow_x','tablet') || 0}px ${getValue('shadow_y','tablet') || 0}px ${getValue('shadow_blur','tablet') || 0}px ${getValue('shadow_color','tablet') || 'rgb(255, 255, 255)'});` : ''}}
          {{ getValue('shadow_style','tablet') == 'box' ? `box-shadow: ${getValue('shadow_type','tablet') == 'inset' ? 'inset' : ''} ${getValue('shadow_x','tablet') || 0}px ${getValue('shadow_y','tablet') || 0}px ${getValue('shadow_spread','tablet') || 0}px ${getValue('shadow_blur','tablet') || 0}px ${getValue('shadow_color','tablet') || 'rgb(255, 255, 255)'};` : ''}}
        }
        #element-{{ node.uuid }} >.sg-row > [data-layout-md="12"] {
          width: calc(100% - {{ `${2*Number.parseInt(column_gap)}px` }}) !important;
          flex: 0 0 calc(100% - {{ `${2*Number.parseInt(column_gap)}px` }});
          margin: {{ `0 ${column_gap}px` }} !important;
        }
        #element-{{ node.uuid }} >.sg-row > [data-layout-md="9"] {
          width: calc(75% - {{ `${2*Number.parseInt(column_gap)}px` }}) !important;
          flex: 0 0 calc(75% - {{ `${2*Number.parseInt(column_gap)}px` }});
          margin: {{ `0 ${column_gap}px` }} !important;
        }
        #element-{{ node.uuid }} >.sg-row > [data-layout-md="8"] {
          width: calc(200%/3 - {{ `${2*Number.parseInt(column_gap)}px` }}) !important;
          flex: 0 0 calc(200%/3 - {{ `${2*Number.parseInt(column_gap)}px` }});
          margin: {{ `0 ${column_gap}px` }} !important;
        }
        #element-{{ node.uuid }} >.sg-row > [data-layout-md="6"] {
          width: calc(50% - {{ `${2*Number.parseInt(column_gap)}px` }}) !important;
          flex: 0 0 calc(50% - {{ `${2*Number.parseInt(column_gap)}px` }});
          margin: {{ `0 ${column_gap}px` }} !important;
        }
        #element-{{ node.uuid }} >.sg-row > [data-layout-md="4"] {
          width: calc(100%/3 - {{ `${2*Number.parseInt(column_gap)}px` }}) !important;
          flex: 0 0 calc(100%/3 - {{ `${2*Number.parseInt(column_gap)}px` }});
          margin: {{ `0 ${column_gap}px` }} !important;
        }
        #element-{{ node.uuid }} >.sg-row > [data-layout-md="3"] {
          width: calc(25% - {{ `${2*Number.parseInt(column_gap)}px` }}) !important;
          flex: 0 0 calc(25% - {{ `${2*Number.parseInt(column_gap)}px` }});
          margin: {{ `0 ${column_gap}px` }} !important;
        }
      }
      @media (max-width: 1199px) and (min-width: 992px) {
        #element-{{ node.uuid }} >.sg-row {
          display: {{ laptop.display == 'block' ? 'flex' : laptop.display }};
          max-width: {{ checkFullWidth(full_width, getValue('width','laptop'), getValue('width_type','laptop')) ? '100%' : getValue('width','laptop') + getValue('width_type','laptop') }} !important;
          margin: {{ getValue('margin_top','laptop') }}px {{ getValue('margin_right','laptop') }}px {{ getValue('margin_bottom','laptop') }}px {{ getValue('margin_left','laptop') }}px;
          padding-top: {{`${getValue('padding_top','laptop')}px`}};
          padding-right: {{`${getValue('padding_right','laptop')}px`}};
          padding-bottom: {{`${getValue('padding_bottom','laptop')}px`}};
          padding-left: {{`${getValue('padding_left','laptop')}px`}};
          {{ getValue('background_type','laptop') == 'color' && getValue('background_color','laptop') ? `background-color: ${getValue('background_color','laptop')};` : ''}}
          {{ getValue('background_type','laptop') == 'image' && getValue('background_image','laptop') ? `background-image: url(${getValue('background_image','laptop')});` : ''}}
          {{ getValue('background_type','laptop') == 'image' && getValue('background_size','laptop') ? `background-size: ${getValue('background_size','laptop')};` : ''}}
          {{ getValue('background_type','laptop') == 'image' && getValue('background_position','laptop') ? `background-position: ${getValue('background_position','laptop')};` : ''}}
          {{ getValue('background_type','laptop') == 'image' && getValue('background_repeat','laptop') ? `background-repeat: ${getValue('background_repeat','laptop')};` : ''}}
          {{ getValue('background_type','laptop') == 'image' && getValue('background_attachment','laptop') ? `background-attachment: ${getValue('background_attachment','laptop')};` : ''}}
          {{ getValue('border_style','laptop') ? `border-style: ${getValue('border_style','laptop')};` : '' }}
          {{ getValue('border_color','laptop') ? `border-color: ${getValue('border_color','laptop')};` : '' }}
          {{ getValue('border_width','laptop') ? `border-width: ${getValue('border_width','laptop')}px;` : '' }}
          {{ getValue('border_top_left_radius','laptop') ? `border-top-left-radius: ${getValue('border_top_left_radius','laptop')}px;` : '' }}
          {{ getValue('border_top_right_radius','laptop') ? `border-top-right-radius: ${getValue('border_top_right_radius','laptop')}px;` : '' }}
          {{ getValue('border_bottom_left_radius','laptop') ? `border-bottom-left-radius: ${getValue('border_bottom_left_radius','laptop')}px;` : '' }}
          {{ getValue('border_bottom_right_radius','laptop') ? `border-bottom-right-radius: ${getValue('border_bottom_right_radius','laptop')}px;` : '' }}
          {{ getValue('opacity','laptop') ? `opacity: ${getValue('opacity','laptop')};` : '' }}
          {{ getValue('shadow_style','laptop') == 'drop' ? `filter: drop-shadow(${getValue('shadow_x','laptop') || 0}px ${getValue('shadow_y','laptop') || 0}px ${getValue('shadow_blur','laptop') || 0}px ${getValue('shadow_color','laptop') || 'rgb(255, 255, 255)'});` : ''}}
          {{ getValue('shadow_style','laptop') == 'box' ? `box-shadow: ${getValue('shadow_type','laptop') == 'inset' ? 'inset' : ''} ${getValue('shadow_x','laptop') || 0}px ${getValue('shadow_y','laptop') || 0}px ${getValue('shadow_spread','laptop') || 0}px ${getValue('shadow_blur','laptop') || 0}px ${getValue('shadow_color','laptop') || 'rgb(255, 255, 255)'};` : ''}}
        }
        #element-{{ node.uuid }} >.sg-row > [data-layout-lg="12"] {
          width: calc(100% - {{ `${2*Number.parseInt(column_gap)}px` }}) !important;
          flex: 0 0 calc(100% - {{ `${2*Number.parseInt(column_gap)}px` }});
          margin: {{ `0 ${column_gap}px` }} !important;
        }
        #element-{{ node.uuid }} >.sg-row > [data-layout-lg="9"] {
          width: calc(75% - {{ `${2*Number.parseInt(column_gap)}px` }}) !important;
          flex: 0 0 calc(75% - {{ `${2*Number.parseInt(column_gap)}px` }});
          margin: {{ `0 ${column_gap}px` }} !important;
        }
        #element-{{ node.uuid }} >.sg-row > [data-layout-lg="8"] {
          width: calc(200%/3 - {{ `${2*Number.parseInt(column_gap)}px` }}) !important;
          flex: 0 0 calc(200%/3 - {{ `${2*Number.parseInt(column_gap)}px` }});
          margin: {{ `0 ${column_gap}px` }} !important;
        }
        #element-{{ node.uuid }} >.sg-row > [data-layout-lg="6"] {
          width: calc(50% - {{ `${2*Number.parseInt(column_gap)}px` }}) !important;
          flex: 0 0 calc(50% - {{ `${2*Number.parseInt(column_gap)}px` }});
          margin: {{ `0 ${column_gap}px` }} !important;
        }
        #element-{{ node.uuid }} >.sg-row > [data-layout-lg="4"] {
          width: calc(100%/3 - {{ `${2*Number.parseInt(column_gap)}px` }}) !important;
          flex: 0 0 calc(100%/3 - {{ `${2*Number.parseInt(column_gap)}px` }});
          margin: {{ `0 ${column_gap}px` }} !important;
        }
        #element-{{ node.uuid }} >.sg-row > [data-layout-lg="3"] {
          width: calc(25% - {{ `${2*Number.parseInt(column_gap)}px` }}) !important;
          flex: 0 0 calc(25% - {{ `${2*Number.parseInt(column_gap)}px` }});
          margin: {{ `0 ${column_gap}px` }} !important;
        }
      }
      @media (min-width: 1200px) {
        #element-{{ node.uuid }} >.sg-row {
          display: {{ desktop.display == 'block' ? 'flex' : desktop.display }};
          max-width: {{ checkFullWidth(full_width, desktop.width, desktop.width_type) ? '100%' : desktop.width + desktop.width_type }} !important;
          margin: {{ getValue('margin_top','desktop') }}px {{ getValue('margin_right','desktop') }}px {{ getValue('margin_bottom','desktop') }}px {{ getValue('margin_left','desktop') }}px;
          padding-top: {{`${getValue('padding_top','desktop')}px`}};
          padding-right: {{`${getValue('padding_right','desktop')}px`}};
          padding-bottom: {{`${getValue('padding_bottom','desktop')}px`}};
          padding-left: {{`${getValue('padding_left','desktop')}px`}};
          {{ desktop.background_type == 'color' && desktop.background_color ? `background-color: ${desktop.background_color};` : ''}}
          {{ desktop.background_type == 'image' && desktop.background_image ? `background-image: url(${desktop.background_image});` : ''}}
          {{ desktop.background_type == 'image' && desktop.background_size ? `background-size: ${desktop.background_size};` : ''}}
          {{ desktop.background_type == 'image' && desktop.background_position ? `background-position: ${desktop.background_position};` : ''}}
          {{ desktop.background_type == 'image' && desktop.background_repeat ? `background-repeat: ${desktop.background_repeat};` : ''}}
          {{ desktop.background_type == 'image' && desktop.background_attachment ? `background-attachment: ${desktop.background_attachment};` : ''}}
          {{ desktop.border_style ? `border-style: ${desktop.border_style};` : '' }}
          {{ desktop.border_color ? `border-color: ${desktop.border_color};` : '' }}
          {{ desktop.border_width ? `border-width: ${desktop.border_width}px;` : '' }}
          {{ desktop.border_top_left_radius ? `border-top-left-radius: ${desktop.border_top_left_radius}px;` : '' }}
          {{ desktop.border_top_right_radius ? `border-top-right-radius: ${desktop.border_top_right_radius}px;` : '' }}
          {{ desktop.border_bottom_left_radius ? `border-bottom-left-radius: ${desktop.border_bottom_left_radius}px;` : '' }}
          {{ desktop.border_bottom_right_radius ? `border-bottom-right-radius: ${desktop.border_bottom_right_radius}px;` : '' }}
          {{ desktop.opacity ? `opacity: ${desktop.opacity};` : '' }}
          {{ desktop.shadow_style == 'drop' ? `filter: drop-shadow(${desktop.shadow_x || 0}px ${desktop.shadow_y || 0}px ${desktop.shadow_blur || 0}px ${desktop.shadow_color || 'rgb(255, 255, 255)'});` : ''}}
          {{ desktop.shadow_style == 'box' ? `box-shadow: ${desktop.shadow_type == 'inset' ? 'inset' : ''} ${desktop.shadow_x || 0}px ${desktop.shadow_y || 0}px ${desktop.shadow_spread || 0}px ${desktop.shadow_blur || 0}px ${desktop.shadow_color || 'rgb(255, 255, 255)'};` : ''}}
        }
        #element-{{ node.uuid }}  >.sg-row > [data-layout-xl="12"] {
          width: calc(100% - {{  `${2*Number.parseInt(column_gap)}px` }}) !important;
          flex: 0 0 calc(100% - {{  `${2*Number.parseInt(column_gap)}px` }});
          margin: {{  `0 ${column_gap}px` }} !important;
        }
        #element-{{ node.uuid }}  >.sg-row > [data-layout-xl="9"] {
          width: calc(75% - {{  `${2*Number.parseInt(column_gap)}px` }}) !important;
          flex: 0 0 calc(75% - {{  `${2*Number.parseInt(column_gap)}px` }});
          margin: {{  `0 ${column_gap}px` }} !important;
        }
        #element-{{ node.uuid }} >.sg-row >  [data-layout-xl="8"] {
          width: calc(200%/3 - {{  `${2*Number.parseInt(column_gap)}px` }}) !important;
          flex: 0 0 calc(200%/3 - {{  `${2*Number.parseInt(column_gap)}px` }});
          margin: {{  `0 ${column_gap}px` }} !important;
        }
        #element-{{ node.uuid }}  >.sg-row > [data-layout-xl="6"] {
          width: calc(50% - {{  `${2*Number.parseInt(column_gap)}px` }}) !important;
          flex: 0 0 calc(50% - {{  `${2*Number.parseInt(column_gap)}px` }});
          margin: {{  `0 ${column_gap}px` }} !important;
        }
        #element-{{ node.uuid }} >.sg-row >  [data-layout-xl="4"] {
          width: calc(100%/3 - {{  `${2*Number.parseInt(column_gap)}px` }}) !important;
          flex: 0 0 calc(100%/3 - {{  `${2*Number.parseInt(column_gap)}px` }});
          margin: {{  `0 ${column_gap}px` }} !important;
        }
        #element-{{ node.uuid }}  >.sg-row > [data-layout-xl="3"] {
          width: calc(25% - {{  `${2*Number.parseInt(column_gap)}px` }}) !important;
          flex: 0 0 calc(25% - {{  `${2*Number.parseInt(column_gap)}px` }});
          margin: {{  `0 ${column_gap}px` }} !important;
        }
      }
    </v-style>
  </div>
  
</template>

<script>
import Layout from './styleSettings/Layout.vue';
import elementStyleMixin from './elementStyleMixin';
import ToolBar from './utils/ToolBar.vue';
import Visibility from "./designSettings/Visibility.vue";
import Spacing from "./designSettings/Spacing.vue";
import BackgroundDesign from "./designSettings/Background.vue";
import Border from "./designSettings/Border.vue";
import Corner from "./designSettings/Corner.vue";
import Shadow from "./designSettings/Shadow.vue";
import Opacity from "./designSettings/Opacity.vue";
import { designStyles, otherProps, otherStyles, actionTypes } from '@/constants';
import FullWidth from './styleSettings/FullWidth.vue';
import HeightVerticalAlign from './styleSettings/HeightVerticalAlign.vue';
import ContainerDimension from './styleSettings/ContainerDimension.vue';
import ColumnsGap from './styleSettings/ColumnsGap.vue';
import ExtraClass from './otherSettings/ExtraClass.vue';
import CustomCSS from './styleSettings/CustomCSS.vue';
export default {
  components: {ToolBar},
  mixins: [elementStyleMixin],
  watch: {
    dragging: function (newValue, oldValue) {
      var $iframe = $("#designEditor").contents();
      var $element = $iframe.find(`#element-${this.node.uuid}`);
      var $content = $element.find(`>.sg-row`);
      if (newValue && newValue == this.node.uuid) {
        setTimeout(() => {
        $content.hide();
        $element.css("padding","0px 0px 0px 0px");
        },100);
      }else if (oldValue == this.node.uuid){
        $content.show();
        $element.css("padding","");
      }
    }
  },
  computed: {
    dragging: function () {
      return this.editor?.draggedNode?.uuid
    },
  },
  inject: [
    'editor',
    'node'
  ],
  props: {
    ...otherProps,
    child: String,
    desktop: Object,
    laptop: Object,
    tablet: Object,
    mobile: Object,
    full_width: Boolean,
    vertical_align: String,
    equal_height: Boolean,
    hiddenConfig: Array,
    column_gap: String,
    extra_class: String,
  },
  data() {
    return {
      isHovering: false,
      designStyles
    }
  },
  methods: {
    checkFullWidth(full_width, width, width_type) {
      if (full_width) {
        return true
      }
      if (width.trim() == '100' && width_type == '%') {
        return true
      }
      return false
    }
  },
  craft: {
    defaultProps: {
      ...otherStyles,
      full_width: false,
      extra_class: "",
      hiddenConfig: [],
      vertical_align: 'top',
      equal_height: false,
      column_gap: '15',
      desktop: {
        ...designStyles,
        layout: '12',
        padding_top: 30,
        padding_bottom: 10,
        width: '1200',
        width_type: 'px'
      },
      laptop: {
        ...designStyles,
        layout: '12',
        padding_top: 30,
        padding_bottom: 10,
        width: '1200',
        width_type: 'px'
      },
      tablet: {
        ...designStyles,
        layout: '12',
        padding_top: 30,
        padding_bottom: 10,
        width: '1200',
        width_type: 'px'
      },
      mobile: {
        ...designStyles,
        layout: '12',
        padding_top: 30,
        padding_bottom: 10,
        width: '1200',
        width_type: 'px'
      },
      elementStyle: {},
    },
    settings: {
      settings: {
        Layout,
        "Full Width": FullWidth,
        "Dimension (Width)": {"Dimension (Width)": ContainerDimension, multi_screen: true },
        "Columns Gap": ColumnsGap,
        "Height & Vertical Align": HeightVerticalAlign,
        "Extra Class": ExtraClass, "Custom CSS": CustomCSS,
      },
      design: {
        Visibility,
        Spacing: { Spacing, multi_screen: true },
        Background: { "Background": BackgroundDesign, multi_screen: true },
        Border: { Border, multi_screen: true },
        Corner: { Corner, multi_screen: true },
        Shadow: { Shadow, multi_screen: true },
        Opacity: { Opacity, multi_screen: true },
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  position: relative;
  display: flex;
  width: 100%;
  box-sizing: border-box;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.container:hover {
  outline: 1px solid #0060e5;
}

.container .container-toolbar {
  display: none;
}

.container-toolbar-show {
  display: block !important;
}

.dragging{
  position:absolute;
  top:0;
  bottom: 0;
  left: 0;
  right:0;
  background: greenyellow;
  z-index: 10;
}


</style>
