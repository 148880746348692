<template>
  <div 
    class="tab-content"
    :class="{current: item.index == node.parent?.props.default_tab}"
    :id="item.id"
  >
    <div v-if="editor?.findNode(node?.uuid)?.children?.length == 0" class="text-center drop-element">
      <span>Add element</span>
    </div>
    <slot />
  </div>
</template>

<script>
import elementStyleMixin from '../../elements/elementStyleMixin';
export default {
  components: {

  },
  mixins: [elementStyleMixin],
  inject: [
    'node',
    'editor'
  ],
  props: {
    component: String,
    item: Object,
  },
  data() {
    return {
      isHovering: false,
    }
  },
  craft: {
    defaultProps: {
      elementStyle: {

      },
    },
    settings: {
      settings: {
      },
      design: {
      }
    },
  },
};
</script>