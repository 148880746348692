<template>
  <div label-position="top" size="mini" :model="elementProps">
    <div class="d-flex flex-column py-2" v-if="activeStyles('font-weight')">
      <span class="setting-title"> Font Weight </span>
      <Select
        class="mt-2"
        :clearable="true"
        :primaryColor="primaryColor"
        :value="getValue('font_weight', elementProps[screen].font_weight)"
        v-bind:changeValue="($event) => updateProps('font_weight', $event)"
        :options="[
          {
            value: '100',
            label: 'Thin 100',
          },
          {
            value: '200',
            label: 'Semi Thin 200',
          },
          {
            value: '300',
            label: 'Light 300',
          },
          {
            value: '400',
            label: 'Regular 400',
          },
          {
            value: '500',
            label: 'Medium 500',
          },
          {
            value: '600',
            label: 'Semi Bold 600',
          },
          {
            value: '700',
            label: 'Bold 700',
          },
          {
            value: '800',
            label: 'Super Bold 800',
          },
          {
            value: '900',
            label: 'Boldest 900',
          },
          {
            value: 'lighter',
            label: 'Lighter',
          },
          {
            value: 'bolder',
            label: 'Bolder',
          },
        ]"
      />
    </div>
    <div class="d-flex flex-column py-2" v-if="activeStyles('font-size')">
      <span class="setting-title">Font Size</span>
      <Slider
        class="mt-2"
        :step="1"
        :primaryColor="primaryColor"
        :value="getValue('font_size', elementProps[screen].font_size)"
        v-bind:changeValue="
          ($event) => updateProps('font_size', parseInt($event))
        "
        unit="px"
        :max="999"
        :min="0"
        :onlyInteger="true"
      />
    </div>
    <div class="d-flex flex-column py-2" v-if="activeStyles('line-height')">
      <span class="setting-title">Line Height</span>
      <Slider
        class="mt-2"
        :primaryColor="primaryColor"
        :value="getValue('line_height', elementProps[screen].line_height)"
        :min="0.5"
        :max="3"
        :step="0.1"
        v-bind:changeValue="
          ($event) => updateProps('line_height', parseFloat($event))
        "
        unit="em"
      />
    </div>
    <div class="d-flex flex-column py-2" v-if="activeStyles('letter-spacing')">
      <span class="setting-title">Letter Spacing</span>
      <Slider
        class="mt-2"
        :primaryColor="primaryColor"
        :value="getValue('letter_spacing', elementProps[screen].letter_spacing)"
        :max="4"
        :step="0.1"
        v-bind:changeValue="
          ($event) => updateProps('letter_spacing', parseFloat($event))
        "
        unit="px"
      />
    </div>
    <div class="d-flex flex-column py-2" v-if="activeStyles('color')">
      <span class="setting-title">Text Color</span>
      <ColorPicker
        class="mt-2"
        :value="getValue('color', elementProps[screen].color)"
        v-bind:changeColor="($event) => updateProps('color', $event)"
        :primaryColor="primaryColor"
      />
    </div>
    <div class="d-flex flex-column py-2" v-if="activeStyles('font-family')">
      <span class="setting-title">Font</span>
      <Select
        class="mt-2"
        :primaryColor="primaryColor"
        :value="getValue('font_family', elementProps[screen].font_family)"
        v-bind:changeValue="($event) => updateProps('font_family', $event)"
        :hasAction="true"
        v-bind:onAction="handleShowModal"
        actionLabel="Open Font Manager"
        :options="fonts"
        :key="fontKey"
      />
    </div>
    <div class="d-flex flex-column py-2" v-if="activeStyles('text-transform')">
      <span class="setting-title">Text Transform</span>
      <Select
        class="mt-2"
        :clearable="true"
        :primaryColor="primaryColor"
        :value="getValue('text_transform', elementProps[screen].text_transform)"
        v-bind:changeValue="($event) => updateProps('text_transform', $event)"
        :options="[
          {
            value: 'none',
            label: 'None',
          },
          {
            value: 'uppercase',
            label: 'Uppercase',
          },
          {
            value: 'lowercase',
            label: 'Lowercase',
          },
          {
            value: 'capitalize',
            label: 'Capitalize',
          },
        ]"
      />
    </div>
    <div class="d-flex flex-column py-2" v-if="activeStyles('font_style')">
      <span>Font Style</span>
      <ToggleButton
        :isMulti="true"
        :values="getValue('font_style', elementProps[screen].font_style, [])"
        v-bind:changeValue="($event) => updateArrayProps('font_style', $event)"
        :options="[
          { value: 'bold', label: 'Bold' },
          { value: 'italic', label: 'Italic' },
          { value: 'underline', label: 'Underline' },
        ]"
      />
    </div>
  </div>
</template>

<script>
import mixin from "./mixin";
import ColorPicker from "../common/ColorPicker.vue";
import Slider from "../common/Slider.vue";
import Select from "../common/Select.vue";
import ToggleButton from "../common/ToggleButton.vue";
import Node from "@/core/Node";
import { makeId, listFonts } from "@/constants";

export default {
  mixins: [mixin],
  components: {
    ColorPicker,
    Slider,
    Select,
    ToggleButton,
  },
  props: {
    node: Node,
    activeFonts: Array,
    screen: String,
    primaryColor: String,
  },
  data() {
    return {
      fontKey: makeId(20),
      fonts: [],
    };
  },
  watch: {
    activeFonts: function (newValue, oldValue) {
      this.fontKey = makeId(20);
    },
  },
  created() {
    this.fonts = listFonts();
    this.$bus.on("reloadListFont", () => {
      setTimeout(() => {
        this.fonts = listFonts();
        var obj = {};
        ["desktop", "laptop", "tablet", "mobile"].map((sc, i) => {
          if (
            !this.fonts?.includes(
              this.getValue("font_family", this.elementProps[sc].font_family)
            )
          ) {
            const desk_screen = Object.assign({}, this.elementProps[sc]);
            desk_screen["font_family"] = "inherit";
            obj[sc] = desk_screen
          }
        });
        if (Object.keys(obj).length > 0) {
          this.elementPropsSetter(obj);
        }
      }, 500);
    });
  },
  methods: {
    activeStyles(key) {
      return this.elementProps.typographyActives?.includes(key);
    },
    updateTextShadow(key, value) {
      const textShadow = this.getStyle("text-shadow");
      this.setStyle("text-shadow", {
        ...textShadow,
        [key]: value,
      });
    },
    handleShowModal() {
      this.$bus.emit("showFontManager", this.node);
    },
    getListFont() {
      return listFonts();
    },
    updateProps(name, value) {
      const screen = this.$props.screen;
      const { desktop, laptop, tablet, mobile } = this.elementProps;
      const data_screen = Object.assign({}, this.elementProps[screen]);
      if (value == data_screen[name]) return;
      data_screen[name] = value;
      var obj = {
        [screen]: data_screen,
      };
      this.elementPropsSetter(obj);
    },
    updateArrayProps(name, value) {
      const screen = this.$props.screen;
      const data_screen = Object.assign({}, this.elementProps[screen]);
      const { desktop, laptop, tablet, mobile } = this.elementProps;
      data_screen[name] = Object.assign([], data_screen[name]);
      if (data_screen[name].includes(value)) {
        var index = data_screen[name].findIndex((x) => x == value);
        data_screen[name].splice(index, 1);
      } else {
        data_screen[name].push(value);
      }
      var obj = {
        [screen]: data_screen,
      };
      this.elementPropsSetter(obj);
    },
  },
};
</script>
