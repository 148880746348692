<template>
  <div
    data-label="Blog Title"
    data-key="blog title"
    class="sg-wrap static"
    :id="`element-${node.uuid}`"
    @mouseover="isHovering=true" @mouseout="isHovering=false"
    :class="{ 'dragging': dragging == node.uuid }"

  >
    <div
      class="sg-static"
      :class="extra_class"
    >
        <component :is="headingType" class="blog-single__title" >
            <span class="sg_blog-title">{{"Title of blog use template"}}</span>
        </component>
    </div>

    <ToolBar ref="blog-title" 
      :toolbar_id="`blog-title`"
      class="container-toolbar" 
      :class="{ 'container-toolbar-show': isHovering, 'is-dragging': dragging == node.uuid }"
      >
      </ToolBar>

    <v-style>
      {{ custom_css }}
      #element-{{ node.uuid }} h1, #element-{{ node.uuid }} h2, #element-{{ node.uuid }} h3 {
        line-height: inherit!important;
        margin: 0px!important;
        padding: 0px!important;
        text-align: inherit!important;
      }
      #element-{{ node.uuid }} a { 
        text-decoration: none;
      }
      #element-{{ node.uuid }} .sg_blog-title { 
        z-index: 1!important; 
      } 

      @media (max-width: 767px){ 
        #element-{{ node.uuid }} .sg_blog-title {
          font-size: {{ getValue('font_size','mobile') }}px;
          font-weight: {{ getValue('font_weight','mobile') }};
          line-height: {{ getValue('line_height','mobile') }}em;
          color: {{ getValue('color','mobile') }};
          letter-spacing: {{ getValue('letter_spacing','mobile') }}px;
          font-family: {{ getValue('font_family','mobile') }};
          text-transform: {{ getValue('text_transform','mobile') }};
          {{ getDisplayValue('mobile') }}
          {{ getMarginValue('mobile') }}
          {{ getPaddingValue('mobile') }}
          {{ getBackgroundValue('mobile') }}
          {{ getBorderValue('mobile') }}
          {{ getBorderRadiusValue('mobile') }}
          {{ getOpacityValue('mobile') }}
          {{ getShadowValue('mobile') }}
          {{ getOtherStyle('mobile') }}
        }
        #element-{{ node.uuid }} .sg-module {
          text-align: {{ getValue('alignment','mobile') }};
        }
      } 
      @media (max-width: 991px) and (min-width: 768px){ 
        #element-{{ node.uuid }} .sg_blog-title {
          font-size: {{ getValue('font_size','tablet') }}px;
          font-weight: {{ getValue('font_weight','tablet') }};
          line-height: {{ getValue('line_height','tablet') }}em;
          color: {{ getValue('color','tablet') }};
          letter-spacing: {{ getValue('letter_spacing','tablet') }}px;
          font-family: {{ getValue('font_family','tablet') }};
          text-transform: {{ getValue('text_transform','tablet') }};
          {{ getDisplayValue('tablet') }}
          {{ getMarginValue('tablet') }}
          {{ getPaddingValue('tablet') }}
          {{ getBackgroundValue('tablet') }}
          {{ getBorderValue('tablet') }}
          {{ getBorderRadiusValue('tablet') }}
          {{ getOpacityValue('tablet') }}
          {{ getShadowValue('tablet') }}
          {{ getOtherStyle('tablet') }}
        }
        #element-{{ node.uuid }} .sg-module {
          text-align: {{ getValue('alignment','tablet') }};
        }
      } 
      @media (max-width: 1199px) and (min-width: 992px){ 
        #element-{{ node.uuid }} .sg_blog-title {
          font-size: {{ getValue('font_size','laptop') }}px;
          font-weight: {{ getValue('font_weight','laptop') }};
          line-height: {{ getValue('line_height','laptop') }}em;
          color: {{ getValue('color','laptop') }};
          letter-spacing: {{ getValue('letter_spacing','laptop') }}px;
          font-family: {{ getValue('font_family','laptop') }};
          text-transform: {{ getValue('text_transform','laptop') }};
          {{ getDisplayValue('laptop') }}
          {{ getMarginValue('laptop') }}
          {{ getPaddingValue('laptop') }}
          {{ getBackgroundValue('laptop') }}
          {{ getBorderValue('laptop') }}
          {{ getBorderRadiusValue('laptop') }}
          {{ getOpacityValue('laptop') }}
          {{ getShadowValue('laptop') }}
          {{ getOtherStyle('laptop') }}
        }
        #element-{{ node.uuid }} .sg-module {
          text-align: {{ getValue('alignment','laptop') }};
        }
      } 
      @media (min-width: 1200px){ 
        #element-{{ node.uuid }} .sg_blog-title {
          font-size: {{ desktop.font_size }}px;
          font-weight: {{ desktop.font_weight }};
          line-height: {{ desktop.line_height }}em;
          color: {{ desktop.color }};
          letter-spacing: {{ desktop.letter_spacing }}px;
          font-family: {{ desktop.font_family }};
          text-transform: {{ desktop.text_transform }};
          {{ getDisplayValue('desktop') }}
          {{ getMarginValue('desktop') }}
          {{ getPaddingValue('desktop') }}
          {{ getBackgroundValue('desktop') }}
          {{ getBorderValue('desktop') }}
          {{ getBorderRadiusValue('desktop') }}
          {{ getOpacityValue('desktop') }}
          {{ getShadowValue('desktop') }}
          {{ getOtherStyle('desktop') }}
        }
        #element-{{ node.uuid }} .sg-module {
          text-align: {{ desktop.alignment }};
        }
      }
    </v-style>
  </div>
</template>

<script>
import ToolBar from '../../elements/utils/ToolBar.vue';

import elementStyleMixin from "../../elements/elementStyleMixin";
import ExtraClass from "../../elements/otherSettings/ExtraClass.vue";
import TitleTag from "./settings/TitleTag.vue";
import AlignmentBase from "../../elements/styleSettings/AlignmentBase.vue";
import Typography from "../../elements/styleSettings/Typography.vue";

import Visibility from "../../elements/designSettings/Visibility.vue";
import Spacing from "../../elements/designSettings/Spacing.vue";
import Background from "../../elements/designSettings/Background.vue";
import Border from "../../elements/designSettings/Border.vue";
import Corner from '../../elements/designSettings/Corner.vue';
import Shadow from '../../elements/designSettings/Shadow.vue';
import Opacity from '../../elements/designSettings/Opacity.vue';
import { designStyles, otherProps, otherStyles, actionTypes } from '@/constants';
import CustomCSS from '../../elements/styleSettings/CustomCSS.vue';
import Animations from '../../elements/designSettings/Animations.vue';

export default {
  components: {ToolBar},
  data() {
    return {
      isHovering: false
    }
  },
  inject: ["node",'editor'],
  mixins: [elementStyleMixin],
  props: {
    ...otherProps,
    extra_class: String,
    component:String,
    isProductLink: Boolean,
    title:String,
    headingType:String,
    parent:String,
    desktop: Object,
    laptop: Object,
    mobile: Object,
    tablet: Object,
  },
  computed: {
    dragging: function () {
      return this.editor?.draggedNode?.uuid
    },
  },
  craft: {
    defaultProps: {
      ...otherStyles,
      headingType: "h3",
      backgroundType: "color",
      component:"Blog Title",
      title:"",
      typographyActives: [
        'font-weight',
        'font-size',
        'line-height',
        'color',
        'font-family',
        'text-transform'
      ],
      desktop: {
        ...designStyles,
        font_weight: '600',
        font_size: 20,
        line_height: 1.2,
        letter_spacing: 0,
        color: 'rgb(51, 51, 51)',
        font_family: 'inherit',
        text_transform: 'none',
        alignment: 'center',
        padding_top: 10,
        padding_bottom: 10,
      },
      laptop: {
        ...designStyles,
      },
      tablet: {
        ...designStyles,
      },
      mobile: {
        ...designStyles,
      },
      elementStyle: {
      }
    },
    settings: {
      settings: {
        "Title Tag": TitleTag,
        Typography: {Typography, multi_screen: true},
        "Alignment": {Alignment: AlignmentBase, multi_screen: true},
        "Extra Class": ExtraClass, "Custom CSS": CustomCSS,
      },
      design: {
        Visibility,
        Spacing: { Spacing, multi_screen: true },
        Background: { Background, multi_screen: true },
        Border: { Border, multi_screen: true },
        Corner: { Corner, multi_screen: true },
        Shadow: { Shadow, multi_screen: true },
        Opacity: { Opacity, multi_screen: true },
      },
      others: {
        Animations: { Animations, actions: actionTypes }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
  