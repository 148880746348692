<template>
    <div class="item">
      <div class="d-flex flex-column align-items-center">
        <span v-if="iconType == 'svg'" v-html="elementIcons[icon]" />
        <i v-if="!iconType || iconType=='material'" class="material-icons">
          {{ icon }}
        </i>
		<span class="material-symbols-outlined" v-if="iconType=='google_icons'">
			{{ icon }}
		</span>
        <span style="font-size: 13px; color: #ccc; ">{{ name }}</span>
      </div>
    </div>
</template>

<script>

import { elementIcons } from '../../../constants';
export default {
  props: {
    icon: String,
    name: String,
    iconType: String,
  },
  data() {
    return {
      elementIcons
    }
  }
};
</script>

<style lang="scss" scoped>
$block-height: 50px;

  .material-icons, .material-symbols-outlined {
    font-size: 40px;
    line-height: $block-height;
    color: #919191;
  }

  .item-wrapper {
    cursor: move;
		padding: 0px;
		width: calc(50% - 5px);
		float: left;
		position: relative;
		.item {
			border: 1px solid #333333;
			background-color: #333333;
			color: #ccc;
			padding: 10px 0;
			border-radius: 6px;
			display: flex;
			align-items: center;
			justify-content: center;
			transition: all 0.3s ease;
			svg {
				stroke: #ccc;
			}
			&:hover {
				transition: all 0.3s ease;
				color: #FFF;
				background: #444444;
				svg {
					stroke: #FFF;
				}
			}
		}
	}

</style>
