import { render, staticRenderFns } from "./ShowOptions.vue?vue&type=template&id=37b3ae45&scoped=true&"
import script from "./ShowOptions.vue?vue&type=script&lang=js&"
export * from "./ShowOptions.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37b3ae45",
  null
  
)

export default component.exports