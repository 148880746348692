<template>
  <el-form label-position="top" size="small" :model="elementProps">
    <el-form-item label="Padding">
      <Slider
        :value="getValue('padding',elementProps[screen].padding)"
        :primaryColor="primaryColor"
        v-bind:changeValue="($event) => updateProps('padding', $event)"
        unit="px"
      />
    </el-form-item>
  </el-form>
</template>

<script>
import mixin from "../../../elements/styleSettings/mixin";
import Slider from "../../../elements/common/Slider.vue";

export default {
  mixins: [mixin],
  components: { Slider },
  props: {
    screen: String,
    primaryColor: String,
  },
  methods: {
    updateProps(name, value) {
      const screen = this.$props.screen
      const data_screen = Object.assign({}, this.elementProps[screen])
      if(value ==  data_screen[name]) return;

      data_screen[name] = value
      this.elementPropsSetter({ [screen]: data_screen });
    },
  },
};
</script>

<style scoped>
</style>
