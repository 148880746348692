<template>
  <div label-position="top" size="mini" :model="elementProps">
    <div class="d-flex flex-column py-2">
      <span style="font-size: 12px; color: #ccc">Layout</span>
      <Select
        class="mt-2"
        :primaryColor="primaryColor"
        :value="elementProps.layout"
        v-bind:changeValue="($event) => updateProps('layout', $event)"
        :options="[
          {
            value: 'simple',
            label: 'Simple',
          },
          {
            value: 'button',
            label: 'Button',
          },
        ]"
      />
    </div>
    <div class="d-flex flex-column py-2" v-if="elementProps.layout=='button'">
      <span class="setting-title">Button Shape</span>
      <ToggleButton
        class="mt-2"
        :value="elementProps.button_shape"
        v-bind:changeValue="($event) => updateProps('button_shape', $event)"
        :options="[
          {
            label: 'Square',
            value: 'square',
          },
          {
            label: 'Circle',
            value: 'circle',
          },
        ]"
      />
    </div>
    <div class="py-2" v-if="elementProps.layout=='button'">
      <span>Button Size</span>
      <Slider 
        :primaryColor="primaryColor"
        :value="elementProps.button_size" 
        :min="30"
        :max="50"
        v-bind:changeValue="($event) => updateProps('button_size', parseInt($event || 0))"
      />
    </div>
    <div class="py-2">
      <span>Spacing</span>
      <Slider 
        :primaryColor="primaryColor"
        :value="elementProps[screen].spacing" 
        :min="0"
        :max="50"
        v-bind:changeValue="($event) => updateScreenProps('spacing', parseInt($event || 0))"
      />
    </div>
  </div>
</template>
    
<script>
import mixin from "../../../elements/styleSettings/mixin";
import ColorPicker from "../../../elements/common/ColorPicker.vue";
import Slider from "../../../elements/common/Slider.vue";
import Select from "../../../elements/common/Select.vue";
import ToggleButton from "../../../elements/common/ToggleButton.vue";
import Node from "@/core/Node";
import { makeId } from "@/constants";

export default {
  mixins: [mixin],
  components: {
    ColorPicker,
    Slider,
    Select,
    ToggleButton,
  },
  props: {
    node: Node,
    screen: String,
    primaryColor: String,
  },
  data() {
    return {};
  },
  watch: {},
  methods: {
    updateScreenProps(name, value) {
      const screen = this.$props.screen;
      const data_screen = Object.assign({}, this.elementProps[screen]);
      if (value == data_screen[name]) return;
      data_screen[name] = value;
      this.elementPropsSetter({ [screen]: data_screen });
    },
    updateProps(name, value) {
      if (value == this.elementProps[name]) return;
      this.elementPropsSetter({ [name]: value });
    },
  },
};
</script>
    