<template>
  <div label-position="top" size="mini" :model="elementProps">
    <div class="typography">
      <div class="d-flex flex-column py-2">
        <span class="setting-title">Font Family</span>
        <Select
          class="mt-2"
          :value="elementProps.button_font_family"
          v-bind:changeValue="($event) => updateCommonProps('button_font_family', $event)"
          :hasAction="true"
          v-bind:onAction="handleShowModal"
          actionLabel="Open Font Manager"
          :options="getListFont()"
          :key="fontKey"
        />
      </div>
      <div class="d-flex flex-column py-2">
        <span class="setting-title">Font Size</span>
        <Slider
          :step="1"
          :value="elementProps.button_font_size"
          v-bind:changeValue="($event) => updateCommonProps('button_font_size', parseInt($event))"
          unit="px"
          :max="999"
          :min="1"
          :onlyInteger="true"
        />
      </div>
      <div class="d-flex flex-column py-2">
        <span class="setting-title">Font Weight</span>
        <Select
          class="mt-2"
          :value="elementProps.button_font_weight"
          v-bind:changeValue="($event) => updateCommonProps('button_font_weight', $event)"
          :options="[
            {
              value: '100',
              label: 'Thin 100',
            },
            {
              value: '200',
              label: 'Semi Thin 200',
            },
            {
              value: '300',
              label: 'Light 300',
            },
            {
              value: '400',
              label: 'Regular 400',
            },
            {
              value: '500',
              label: 'Medium 500',
            },
            {
              value: '600',
              label: 'Semi Bold 600',
            },
            {
              value: '700',
              label: 'Bold 700',
            },
            {
              value: '800',
              label: 'Super Bold 800',
            },
            {
              value: '900',
              label: 'Boldest 900',
            }
          ]"
        />
      </div>
      <div class="d-flex flex-column py-2">
        <span class="setting-title">Text Transform</span>
        <Select
          class="mt-2"
          primaryColor="#0060e5"
          :value="elementProps.button_text_transform"
          v-bind:changeValue="($event) => updateCommonProps('button_text_transform', $event)"
          :options="[
            {
              value: 'none',
              label: 'None',
            },
            {
              value: 'uppercase',
              label: 'Uppercase',
            },
            {
              value: 'lowercase',
              label: 'Lowercase',
            },
            {
              value: 'capitalize',
              label: 'Capitalize',
            },
          ]"
        />
      </div>
    </div>
    <div class="d-flex flex-column py-2">
      <span class="setting-title">Padding</span>
      <Dimension
        :units="['px', '%']"
        :unit="elementProps.button_padding_unit || 'px'"
        primaryColor="#0060e5"
        :data="elementProps"
        :items="[
          {
            key: 'button_padding_top',
            title: 'Top',
          },
          {
            key: 'button_padding_right',
            title: 'Right'
          },
          {
            key: 'button_padding_bottom',
            title: 'Bottom'
          },
          {
            key: 'button_padding_left',
            title: 'Left'
          }
        ]"
        v-bind:changeValue="(name, $event) => updateCommonProps(name, parseInt($event))"
        v-bind:changeUnit="($event) => updateCommonProps('button_padding_unit', $event)"
      />
    </div>
    <div class="button-type">
      <div class="d-flex flex-column py-2">
        <ToggleButton
          primaryColor="#0060e5"
          :value="button_type"
          v-bind:changeValue="($event) => updateType($event)"
          :options="[
            { label: 'Normal', value: 'normal' },
            { label: 'Hover', value: 'hover' }
          ]"
        />
      </div>
      <div v-if="button_type == 'normal'">
        <div class="d-flex flex-column py-2">
          <span class="setting-title">Text Color</span>
          <ColorPicker 
            primaryColor="#0060e5"
            class="mt-2"
            :value="elementProps.button_text_color"
            v-bind:changeColor="($event) => updateCommonProps('button_text_color', $event)"
          />
        </div>
        <div class="d-flex flex-column py-2">
          <span class="setting-title">Background Color</span>
          <ColorPicker 
            primaryColor="#0060e5"
            class="mt-2"
            :value="elementProps.button_background_color"
            v-bind:changeColor="($event) => updateCommonProps('button_background_color', $event)"
          />
        </div>
        <div class="d-flex flex-column py-2">
          <span class="setting-title">Border Type</span>
          <Select
            primaryColor="#0060e5"
            class="mt-2"
            :value="elementProps.button_border_type"
            v-bind:changeValue="($event) => updateCommonProps('button_border_type', $event)"
            :options="[
              { value: 'none', label: 'None' },
              { value: 'solid', label: 'Solid' },
              { value: 'dotted', label: 'Dotted' },
              { value: 'dashed', label: 'Dashed' },
            ]"
          />
        </div>
        <div class="d-flex flex-column py-2" v-if="elementProps.button_border_type != 'none'">
          <span class="setting-title">Border Width</span>
          <Dimension
            :units="['px']"
            :unit="elementProps.button_border_width_unit || 'px'"
            primaryColor="#0060e5"
            :data="elementProps"
            :items="[
              {
                key: 'button_border_top_width',
                title: 'Top',
              },
              {
                key: 'button_border_right_width',
                title: 'Right'
              },
              {
                key: 'button_border_bottom_width',
                title: 'Bottom'
              },
              {
                key: 'button_border_left_width',
                title: 'Left'
              }
            ]"
            v-bind:changeValue="(name, $event) => updateCommonProps(name, $event)"
            v-bind:changeUnit="($event) => updateCommonProps('button_border_width_unit', $event)"
          />
        </div>
        <div class="d-flex flex-column py-2" v-if="elementProps.button_border_type != 'none'">
          <span class="setting-title">Border Color</span>
          <ColorPicker 
            primaryColor="#0060e5"
            class="mt-2"
            :value="elementProps.button_border_color"
            v-bind:changeColor="($event) => updateCommonProps('button_border_color', $event)"
          />
        </div>
        <div class="d-flex flex-column py-2">
          <span class="setting-title">Border Radius</span>
          <Dimension
            :units="['px', '%']"
            :unit="elementProps.button_border_radius_unit || 'px'"
            primaryColor="#0060e5"
            :data="elementProps"
            :items="[
              {
                key: 'button_border_top_left_radius',
                title: 'Top',
              },
              {
                key: 'button_border_top_right_radius',
                title: 'Right'
              },
              {
                key: 'button_border_bottom_right_radius',
                title: 'Bottom'
              },
              {
                key: 'button_border_bottom_left_radius',
                title: 'Left'
              }
            ]"
            v-bind:changeValue="(name, $event) => updateCommonProps(name, $event)"
            v-bind:changeUnit="($event) => updateCommonProps('button_border_radius_unit', $event)"
          />
        </div>
      </div>
      <div v-if="button_type == 'hover'">
        <div class="d-flex flex-column py-2">
          <span class="setting-title">Text Color</span>
          <ColorPicker 
            primaryColor="#0060e5"
            class="mt-2"
            :value="elementProps.button_hover_text_color"
            v-bind:changeColor="($event) => updateCommonProps('button_hover_text_color', $event)"
          />
        </div>
        <div class="d-flex flex-column py-2">
          <span class="setting-title">Background Color</span>
          <ColorPicker 
            primaryColor="#0060e5"
            class="mt-2"
            :value="elementProps.button_hover_background_color"
            v-bind:changeColor="($event) => updateCommonProps('button_hover_background_color', $event)"
          />
        </div>
        
        <div class="d-flex flex-column py-2">
          <span class="setting-title">Border Type</span>
          <Select
            primaryColor="#0060e5"
            class="mt-2"
            :value="elementProps.button_hover_border_type"
            v-bind:changeValue="($event) => updateCommonProps('button_hover_border_type', $event)"
            :options="[
              { value: 'none', label: 'None' },
              { value: 'solid', label: 'Solid' },
              { value: 'dotted', label: 'Dotted' },
              { value: 'dashed', label: 'Dashed' },
            ]"
          />
        </div>
        <div class="d-flex flex-column py-2" v-if="elementProps.button_hover_border_type != 'none'">
          <span class="setting-title">Border Width</span>
          <Dimension
            :units="['px', '%']"
            :unit="elementProps.button_hover_border_width_unit || 'px'"
            primaryColor="#0060e5"
            :data="elementProps"
            :items="[
              {
                key: 'button_hover_border_top_width',
                title: 'Top',
              },
              {
                key: 'button_hover_border_right_width',
                title: 'Right'
              },
              {
                key: 'button_hover_border_bottom_width',
                title: 'Bottom'
              },
              {
                key: 'button_hover_border_left_width',
                title: 'Left'
              }
            ]"
            v-bind:changeValue="(name, $event) => updateCommonProps(name, $event)"
            v-bind:changeUnit="($event) => updateCommonProps('button_hover_border_width_unit', $event)"
          />
        </div>
        <div class="d-flex flex-column py-2" v-if="elementProps.button_hover_border_type != 'none'">
          <span class="setting-title">Border Color</span>
          <ColorPicker 
            primaryColor="#0060e5"
            class="mt-2"
            :value="elementProps.button_hover_border_color"
            v-bind:changeColor="($event) => updateCommonProps('button_hover_border_color', $event)"
          />
        </div>
        <div class="d-flex flex-column py-2">
          <span class="setting-title">Border Radius</span>
          <Dimension
            :units="['px', '%']"
            :unit="elementProps.button_hover_border_radius_unit || 'px'"
            primaryColor="#0060e5"
            :data="elementProps"
            :items="[
              {
                key: 'button_hover_border_top_left_radius',
                title: 'Top',
              },
              {
                key: 'button_hover_border_top_right_radius',
                title: 'Right'
              },
              {
                key: 'button_hover_border_bottom_right_radius',
                title: 'Bottom'
              },
              {
                key: 'button_hover_border_bottom_left_radius',
                title: 'Left'
              }
            ]"
            v-bind:changeValue="(name, $event) => updateCommonProps(name, $event)"
            v-bind:changeUnit="($event) => updateCommonProps('button_hover_border_radius_unit', $event)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Node from '@/core/Node';
import { makeId, icons } from "@/constants";
import mixin from '../styleSettings/mixin';
import Slider from '../common/Slider.vue';
import ColorPicker from '../common/ColorPicker.vue';
import Select from '../common/Select.vue';
import ToggleButton from '../common/ToggleButton.vue';
import Dimension from '../common/Dimension.vue';
export default {
  mixins: [mixin],
  components: {
    ColorPicker, Slider, Select, ToggleButton, Dimension
  },
  props: {
    screen: String,
    node: Node,
    activeFonts: Array,
  },
  data() {
    return {
      fontKey: makeId(20),
      button_type: 'normal',
      icons,
      box_index: 0,
    };
  },
  watch: {
    activeFonts: function (newValue, oldValue) {
      this.fontKey = makeId(20);
    },
  },
  methods: {
    setBoxIndex(value) {
      this.box_index = value
    },
    updateType(value) {
      this.button_type = value
    },
    updateCommonProps(name, value) {
      this.elementPropsSetter({ [name]: value });
    },
    updateProps(name, value) {
      const screen = this.$props.screen
      const data_screen = Object.assign({}, this.elementProps[screen])
      data_screen[name] = value
      this.elementPropsSetter({ [screen]: data_screen });
    },
    handleShowModal() {
      this.$bus.emit("showFontManager", this.node);
    },
    getListFont() {
      const listFonts = [
        {
          value: "inherit",
          label: "Default",
        },
      ];
      this.node?.editor?.fonts?.map((e) => {
        listFonts.push({ value: e, label: e });
      });
      return listFonts;
    },
  }
};
</script>
