<template>
  <el-form label-position="top" size="" :model="elementProps">
    <div class="d-flex flex-column py-2">
      <span class="setting-title">Tab Padding X</span>
      <Slider 
        class="mt-2"
        :primaryColor="primaryColor"
        unit="px"
        :min="0"
        :max="100"
        :step="1"
        v-bind:changeValue="($event) => updateProps('tab_padding_x', $event)"
        :value="getValue('tab_padding_x',elementProps[screen].tab_padding_x)"
        :onlyInteger="true"
      />
    </div>
    <div class="d-flex flex-column py-2">
      <span class="setting-title">Tab Padding Y</span>
      <Slider 
        class="mt-2"
        :primaryColor="primaryColor"
        unit="px"
        :min="0"
        :max="100"
        :step="1"
        v-bind:changeValue="($event) => updateProps('tab_padding_y', $event)"
        :value="getValue('tab_padding_y',elementProps[screen].tab_padding_y)"
        :onlyInteger="true"
      />
    </div>
  </el-form>
</template>

<script>
import mixin from "../../../elements/styleSettings/mixin";
import Slider from "../../../elements/common/Slider.vue";
export default {
  mixins: [mixin],
  components: { Slider },
  props: {
    primaryColor: String,
    screen: String,
  },
  methods: {
    updateProps(name, value) {
      const screen = this.$props.screen
      const data_screen = Object.assign({}, this.elementProps[screen])
      data_screen[name] = value
      this.elementPropsSetter({ [screen]: data_screen });
    },
  },
};
</script>
