import { render, staticRenderFns } from "./PaginatorText.vue?vue&type=template&id=3331e50c&scoped=true&"
import script from "./PaginatorText.vue?vue&type=script&lang=js&"
export * from "./PaginatorText.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3331e50c",
  null
  
)

export default component.exports