<template>
  <div label-position="top" size="mini" :model="elementProps">
    <div class="py-2">
      <span class="setting-title">Font Size</span>
      <Slider
        class="mt-2"
        :step="1"
        :primaryColor="primaryColor"
        :value="getValue('label_font_size', elementProps[screen].label_font_size)"
        v-bind:changeValue="($event) => updateProps('label_font_size', parseInt($event))"
        unit="px"
        :max="100"
        :min="0"
        :onlyInteger="true"
      />
    </div>
    <div class="d-flex flex-column py-2">
      <span class="setting-title">Color</span>
      <ColorPicker
        class="mt-2"
        :value="getValue('label_color', elementProps[screen].label_color)"
        v-bind:changeColor="($event) => updateProps('label_color', $event)"
        :primaryColor="primaryColor"
      />
    </div>
    <div class="d-flex flex-column py-2">
      <span class="setting-title">Font</span>
      <Select
        class="mt-2"
        :primaryColor="primaryColor"
        :value="getValue('label_font_family', elementProps[screen].label_font_family)"
        v-bind:changeValue="($event) => updateProps('label_font_family', $event)"
        :hasAction="true"
        v-bind:onAction="handleShowModal"
        actionLabel="Open Font Manager"
        :options="getListFont()"
        :key="fontKey"
      />
    </div>
    <div class="d-flex flex-column py-2">
      <span class="setting-title">Font Weight</span>
      <Select
        class="mt-2"
        :clearable="true"
        :primaryColor="primaryColor"
        :value="getValue('label_font_weight',elementProps[screen].label_font_weight)"
        v-bind:changeValue="($event) => updateProps('label_font_weight', $event)"
        :options="[
          {
            value: '100',
            label: 'Thin 100',
          },
          {
            value: '200',
            label: 'Semi Thin 200',
          },
          {
            value: '300',
            label: 'Light 300',
          },
          {
            value: '400',
            label: 'Regular 400',
          },
          {
            value: '500',
            label: 'Medium 500',
          },
          {
            value: '600',
            label: 'Semi Bold 600',
          },
          {
            value: '700',
            label: 'Bold 700',
          },
          {
            value: '800',
            label: 'Super Bold 800',
          },
          {
            value: '900',
            label: 'Boldest 900',
          },
          {
            value: 'lighter',
            label: 'Lighter',
          },
          {
            value: 'bolder',
            label: 'Bolder',
          },
        ]"
      />
    </div>
    <div class="d-flex flex-column py-2">
      <span class="setting-title">Text Transform</span>
      <Select
        class="mt-2"
        :clearable="true"
        :primaryColor="primaryColor"
        :value="getValue('label_text_transform',elementProps[screen].label_text_transform)"
        v-bind:changeValue="($event) => updateProps('label_text_transform', $event)"
        :options="[
          {
            value: 'none',
            label: 'None',
          },
          {
            value: 'uppercase',
            label: 'Uppercase',
          },
          {
            value: 'lowercase',
            label: 'Lowercase',
          },
          {
            value: 'capitalize',
            label: 'Capitalize',
          },
        ]"
      />
    </div>
  </div>
</template>

<script>
import mixin from '../../../elements/styleSettings/mixin';
import Slider from '../../../elements/common/Slider.vue';
import ColorPicker from '../../../elements/common/ColorPicker.vue';
import Select from '../../../elements/common/Select.vue';
import Node from "@/core/Node";
import { makeId, listFonts } from "@/constants";

export default {
  mixins: [mixin],
  components: {
    Slider, ColorPicker, Select
  },
  props: {
    primaryColor: String,
    screen: String,
    activeFonts: Array,
    node: Node,
  },
  data() {
    return {
      fontKey: makeId(20),
    };
  },
  watch: {
    activeFonts: function (newValue, oldValue) {
      this.fontKey = makeId(20);
    },
  },
  methods: {
    handleShowModal() {
      this.$bus.emit("showFontManager", this.node);
    },
    getListFont() {
      return listFonts();
    },
    updateProps(name, value) {
      const screen = this.$props.screen;
      const { desktop, laptop, tablet, mobile } = this.elementProps;
      const data_screen = Object.assign({}, this.elementProps[screen]);
      if (value == data_screen[name]) return;
      data_screen[name] = value;
      var obj = {
        [screen]: data_screen,
      };
      this.elementPropsSetter(obj);
    },
  },
};
</script>