<template>
  <div
    data-label="Product"
    data-key="product"
    class="sg-wrap module"
    :id="`element-${node.uuid}`"
    :class="{'dynamic':dynamic}"
    @mouseover="isHovering = true"
    @mouseout="isHovering = false"
    :data-pid="product_id"
    :data-status="dynamic?'dynamic':''"
  >
    <div :class="`sg-module`">
      <div class="loading" v-if="isLoading">Product Loading...</div>
      <div class="error" v-else-if="!isLoading && error">
        Product not found. Have you uploaded products to your store?
      </div>
      <form v-else :class="`AddToCartForm ${extra_class}`">
        <input type="hidden" name="product_id" :value="product_id" />
        <input type="hidden" name="name" :value="product_name" />
        <input type="hidden" name="product_item_id" :value="product.items?.length > 0 ? product.items[0].product_item_id : ''" />
        <input type="hidden" name="quantity" value="1" />
        <input type="hidden" name="image" />
        <input type="hidden" name="fastCheckout" value="0" />

        
        <div class="sg-item-content">
          <Node
            v-for="node_item in editor?.findNode(node.uuid)?.children"
            :key="node_item.uuid"
            :node="node_item"
            :screen="screen"
          />
        </div>
      </form>
    </div>

    <ToolBar
      ref="product"
      :dynamic="dynamic"
      :toolbar_id="`product`"
      class="container-toolbar"
      :class="{
        'container-toolbar-show': isHovering,
        'is-dragging': dragging == node.uuid,
      }"
    >
    </ToolBar>
    <div :id="`toast-${node.uuid}`" class="sg-snackbar">Some text some message..</div>
    <div v-if="dragging == node.uuid" class="dragging"></div>
    <v-style> {{ custom_css }}
      #element-{{ node.uuid }} form { 
        z-index: 1!important; 
      } 
      @media (max-width: 767px){ 
        #element-{{ node.uuid }} {
          {{ getDisplayValue('mobile') }}
          {{ getMarginValue('mobile') }}
          {{ getPaddingValue('mobile') }}
          {{ getBackgroundValue('mobile') }}
          {{ getBorderValue('mobile') }}
          {{ getBorderRadiusValue('mobile') }}
          {{ getOpacityValue('mobile') }}
          {{ getShadowValue('mobile') }}
          {{ getOtherStyle('mobile') }}
        }
      } 
      @media (max-width: 991px) and (min-width: 768px){ 
        #element-{{ node.uuid }} {
          {{ getDisplayValue('tablet') }}
          {{ getMarginValue('tablet') }}
          {{ getPaddingValue('tablet') }}
          {{ getBackgroundValue('tablet') }}
          {{ getBorderValue('tablet') }}
          {{ getBorderRadiusValue('tablet') }}
          {{ getOpacityValue('tablet') }}
          {{ getShadowValue('tablet') }}
          {{ getOtherStyle('tablet') }}
        }
      } 
      @media (max-width: 1199px) and (min-width: 992px){ 
        #element-{{ node.uuid }} {
          {{ getDisplayValue('laptop') }}
          {{ getMarginValue('laptop') }}
          {{ getPaddingValue('laptop') }}
          {{ getBackgroundValue('laptop') }}
          {{ getBorderValue('laptop') }}
          {{ getBorderRadiusValue('laptop') }}
          {{ getOpacityValue('laptop') }}
          {{ getShadowValue('laptop') }}
          {{ getOtherStyle('laptop') }}
        }
      } 
      @media (min-width: 1200px){ 
        #element-{{ node.uuid }} {
          {{ getDisplayValue('desktop') }}
          {{ getMarginValue('desktop') }}
          {{ getPaddingValue('desktop') }}
          {{ getBackgroundValue('desktop') }}
          {{ getBorderValue('desktop') }}
          {{ getBorderRadiusValue('desktop') }}
          {{ getOpacityValue('desktop') }}
          {{ getShadowValue('desktop') }}
          {{ getOtherStyle('desktop') }}
        } 
      }
    </v-style>
    <v-script :id="`data-product`" :class="`data-product-${node.uuid}`" type="application/json">
        {{product}}
    </v-script>
  </div>
</template>

<script>
import ToolBar from "../../elements/utils/ToolBar.vue";

import elementStyleMixin from "../../elements/elementStyleMixin";
import ProductDynamic from "./settings/ProductDynamic.vue";
import ExtraClass from "../../elements/otherSettings/ExtraClass.vue";
import Form from "./settings/Form.vue";
import Product from "./settings/Product.vue";
import Visibility from "../../elements/designSettings/Visibility.vue";
import Spacing from "../../elements/designSettings/Spacing.vue";
import Background from "../../elements/designSettings/Background.vue";
import Border from "../../elements/designSettings/Border.vue";
import Corner from "../../elements/designSettings/Corner.vue";
import Shadow from "../../elements/designSettings/Shadow.vue";
import Opacity from "../../elements/designSettings/Opacity.vue";
import { request } from "../../../../../services";
import { computed } from "vue";
import { designStyles, otherProps, otherStyles, actionTypes } from "@/constants";
import Node from "../../../../../components/Node.vue";
import Cookies from 'js-cookie';
import CustomCSS from '../../elements/styleSettings/CustomCSS.vue';
import Animations from '../../elements/designSettings/Animations.vue';


export default {
  components: { ToolBar, Node },
  data() {
    return {
      isHovering: false,
      product: {},
      user: JSON.parse(Cookies.get("user_onepage") || "{}"),
      error: false,
      isLoading: true,
    };
  },
  provide() {
    return {
      product: computed(() => this.product),
    };
  },
  watch: {
    product_id: function (newValue, oldValue) {
      if (oldValue && oldValue != newValue) {
        this.getProducts(newValue);
      }
    },
    dragging: function (newValue, oldValue) {
      var $iframe = $("#designEditor").contents();
      var $element = $iframe.find(`#element-${this.node.uuid}`);
      var $content = $element.find(`.sg-module`);
      if (newValue && newValue == this.node.uuid) {
        setTimeout(() => {
          $element.css("padding", "0px 0px 0px 0px");
          $element.css("margin", "0px 0px 0px 0px");
          $content.hide();
        }, 100);
      } else if (oldValue == this.node.uuid) {
        $element.css("padding", "");
        $element.css("margin", "");
        $content.show();
      }
    },
  },
  created() {
    const product_id = this.$props.product_id;
    this.getProducts(product_id);
  },
  methods: {
    getProducts(product_id) {
      const query = {
        product_id: product_id || null,
      }
      request.get(`/api/v1/product_builder`, query).then(res => {
        this.isLoading = false;
        if (res.data.success) {
          this.error = false;
          var data = res.data.data;
          data['product_node_uuid'] = this.node.uuid;
          // var variantsJson = data.variantsJson;
          // var arrVariants = [];
          // Object.values(data.variants).map((value) => {
          //   arrVariants = [...arrVariants, ...value];
          // });
          // Object.keys(variantsJson).map((key, index) => {
          //   var tags = key.split("_");
          //   var variant_names = [];
          //   tags.map((tag) => {
          //     var posi = arrVariants.findIndex(
          //       (item) => item.variant_id == tag
          //     );
          //     if (posi != -1) {
          //       variant_names.push(arrVariants[posi]["variant"]);
          //     }
          //   });
          //   variantsJson[key]["variants_name"] = variant_names.join(" / ");
          // });
          // data["variantsJson"] = variantsJson;
          this.product = data;
          if (!product_id) {
            this.node.setProps({
              product_id: String(res.data.data.product_id),
              product_name: res.data.data.title,
            }, true);
          }
        } else {
          this.error = true;
        }
      }, (err) => {
        this.isLoading = false;
        this.$toastr.e(err?.response?.data?.message || 'Error');
        this.error = true;
      })
    },
  },
  inject: ["node", "editor"],
  mixins: [elementStyleMixin],
  props: {
    ...otherProps,
    section: String,
    screen: String,
    component: String,
    extra_class: String,
    form_id: String,
    product_id: String,
    product_name: String,
    desktop: Object,
    laptop: Object,
    mobile: Object,
    tablet: Object,
    template: Boolean,
    dynamic:Boolean
  },
  computed: {
    dragging: function () {
      return this.editor?.draggedNode?.uuid;
    }
  },
  craft: {
    defaultProps: { ...otherStyles,
      dynamic:false,
      template: false,
      section: "module",
      product_id: "",
      product_name: "",
      form_id: "",
      extra_class: "",
      desktop: {
        ...designStyles,
        padding_top: 30,
        padding_bottom: 30,
        padding_left: 10,
        padding_right: 10,
      },
      laptop: {
        ...designStyles,
      },
      tablet: {
        ...designStyles,
      },
      mobile: {
        ...designStyles,
      },
      elementStyle: {},
    },
    settings: {
      settings: {
        Product,
        "Dynamic":ProductDynamic,
        "Form ID": Form,
        "Extra Class": ExtraClass, "Custom CSS": CustomCSS,
      },
      design: {
        Visibility,
        Spacing: { Spacing, multi_screen: true },
        Background: { Background, multi_screen: true },
        Border: { Border, multi_screen: true },
        Corner: { Corner, multi_screen: true },
        Shadow: { Shadow, multi_screen: true },
        Opacity: { Opacity, multi_screen: true },
      },
      others: {
        Animations: { Animations, actions: actionTypes.filter(e => ['scroll_into_view', 'after_page_finish_loading'].includes(e.value)) }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
