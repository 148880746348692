<template>
  <div
    data-label="Tabs"
    data-key="tabs"
    class="sg-wrap module sg-tabs"
    @mouseover="isHovering=true" 
    @mouseout="isHovering=false"
    :id="`element-${node.uuid}`"
    :data-default-tab="default_tab"
  >
    <div class="sg-tabs-content" :class="[extra_class, tab_style]">
      <ul class="tabset" :data-id="node.uuid">
        <li
          v-for="item in items"
          class="tab-link"
          :data-id="item.id"
          :class="`${item.index == default_tab ? 'current' : ''}`"
          :key="item.id"
          v-on:click="changeTab(item.id)"
        >
          <Editor
            tag="p"
            :id="item.id"
            :value="item.name"
            @input2="updateContent"
          ></Editor>
        </li>
      </ul>
      <slot />
    </div>
    <ToolBar ref="tabs_toolbar" :toolbar_id="`tabs`" class="container-toolbar" 
    :class="{ 'container-toolbar-show': isHovering, 'is-dragging': dragging == node.uuid }"
    ></ToolBar>
    <div v-if="dragging == node.uuid" class="dragging"></div>
    <v-style>
      {{ custom_css }}
      #element-{{ node.uuid }} ul.tabset {
        text-align: {{ alignment }};
      }

      #element-{{ node.uuid }} .default .tab-link {
        margin-bottom: {{ 0 - border_size }}px;
        border: {{ border_size }}px solid {{ border_color }};
        background: {{ inactive_tab_background }};
        color: {{ inactive_tab_text_color }};
        border-bottom: {{ border_size }}px solid {{ border_color }};
      }

      #element-{{ node.uuid }} .default .tab-link.current {
        background: {{ tab_background }};
        {{ text_color ? `color: ${text_color};` : '' }};
        border-bottom: {{ border_size }}px solid {{ tab_background }};
      }

      #element-{{ node.uuid }} .default .tab-content.current {
        border: {{ border_size }}px solid {{ border_color }};
        background: {{ tab_background }};
      }

      #element-{{ node.uuid }} .underline .tab-link {
        color: {{ inactive_tab_text_color }};
      }

      #element-{{ node.uuid }} .underline .tab-link.current {
        {{ text_color ? `color: ${text_color};` : '' }};
        border-bottom: {{ border_size }}px solid {{ border_color }};
      }

      #element-{{ node.uuid }} .underline .tab-content.current {
        background: {{ tab_background }};
      }

      @media (max-width: 767px) {
        #element-{{ node.uuid }} ul.tabset .tab-link {
          padding: {{ getValue('tab_padding_x','mobile') }}px {{ getValue('tab_padding_y','mobile') }}px;
        }
        #element-{{ node.uuid }} {
          {{ getDisplayValue('mobile') }}
          {{ getMarginValue('mobile') }}
          {{ getPaddingValue('mobile') }}
          {{ getBackgroundValue('mobile') }}
          {{ getBorderValue('mobile') }}
          {{ getBorderRadiusValue('mobile') }}
          {{ getOpacityValue('mobile') }}
          {{ getShadowValue('mobile') }}
          {{ getOtherStyle('mobile') }}
        }
      }
      @media (max-width: 991px) and (min-width: 768px) {
        #element-{{ node.uuid }} ul.tabset .tab-link {
          padding: {{ getValue('tab_padding_x','tablet') }}px {{ getValue('tab_padding_y','tablet') }}px;
        }
        #element-{{ node.uuid }} {
          {{ getDisplayValue('tablet') }}
          {{ getMarginValue('tablet') }}
          {{ getPaddingValue('tablet') }}
          {{ getBackgroundValue('tablet') }}
          {{ getBorderValue('tablet') }}
          {{ getBorderRadiusValue('tablet') }}
          {{ getOpacityValue('tablet') }}
          {{ getShadowValue('tablet') }}
          {{ getOtherStyle('tablet') }}
        }
      }
      @media (max-width: 1199px) and (min-width: 992px) {
        #element-{{ node.uuid }} ul.tabset .tab-link {
          padding: {{ getValue('tab_padding_x','laptop') }}px {{ getValue('tab_padding_y','laptop') }}px;
        }
        #element-{{ node.uuid }} {
          {{ getDisplayValue('laptop') }}
          {{ getMarginValue('laptop') }}
          {{ getPaddingValue('laptop') }}
          {{ getBackgroundValue('laptop') }}
          {{ getBorderValue('laptop') }}
          {{ getBorderRadiusValue('laptop') }}
          {{ getOpacityValue('laptop') }}
          {{ getShadowValue('laptop') }}
          {{ getOtherStyle('laptop') }}
        }
      }
      @media (min-width: 1200px) {
        #element-{{ node.uuid }} ul.tabset .tab-link {
          padding: {{ desktop.tab_padding_x }}px {{ desktop.tab_padding_y }}px;
        }
        #element-{{ node.uuid }} {
          {{ getDisplayValue('desktop') }}
          {{ getMarginValue('desktop') }}
          {{ getPaddingValue('desktop') }}
          {{ getBackgroundValue('desktop') }}
          {{ getBorderValue('desktop') }}
          {{ getBorderRadiusValue('desktop') }}
          {{ getOpacityValue('desktop') }}
          {{ getShadowValue('desktop') }}
          {{ getOtherStyle('desktop') }}
        }
      }
    </v-style>
  </div>
</template>

<script>
import elementStyleMixin from '../../elements/elementStyleMixin';
import ToolBar from '../../elements/utils/ToolBar.vue';
import Visibility from '../../elements/designSettings/Visibility.vue';
import Spacing from '../../elements/designSettings/Spacing.vue';
import BackgroundDesign from '../../elements/designSettings/Background.vue';
import Border from '../../elements/designSettings/Border.vue';
import Corner from '../../elements/designSettings/Corner.vue';
import Shadow from '../../elements/designSettings/Shadow.vue';
import Opacity from '../../elements/designSettings/Opacity.vue';
import ExtraClass from '../../elements/otherSettings/ExtraClass.vue';
import TabContent from './TabContent.vue';
import Items from './settings/Items.vue';
import TabStyle from './settings/TabStyle.vue';
import TabPadding from './settings/TabPadding.vue';
import BorderSetting from './settings/Border.vue';
import ActiveTab from './settings/ActiveTab.vue';
import NormalTab from './settings/NormalTab.vue';
import Alignment from './settings/Alignment.vue';
import { makeId, designStyles, otherProps, otherStyles, actionTypes } from '@/constants';
import CustomCSS from '../../elements/styleSettings/CustomCSS.vue';
import Animations from '../../elements/designSettings/Animations.vue';
import Editor from "../../elements/utils/Editor.vue";


export default {
  components: { ToolBar, TabContent, Editor },
  mixins: [elementStyleMixin],
  inject: [
    'node',
    'editor'
  ],
  computed: {
    dragging: function () {
      return this.editor?.draggedNode?.uuid
    },
  },
  methods:{
    changeTab(item_id){
      var iframe = $("#designEditor").contents();
      const element = iframe.find(`#element-${this.node.uuid}`);
      const lis =  element.find(`ul li`).removeClass("current");
      
      const tabs =  element.find(`.tab-content`).removeClass('current');
      const li =  element.find(`li[data-id="${item_id}"]`).addClass('current')
      const tab =  element.find(`#${item_id}`).addClass('current')

    },
    updateContent(data) {
      var {id, value} = data;
      var key = 'name';
      const items = Object.assign([], JSON.parse(JSON.stringify(this.node.props.items)))
      const item = items.find(e => e.id == id)
      item[key] = value
      this.node.setProps({ items })
      const child_node = this.node.children.find(e => e.props.item.id == id)
      const item_child = Object.assign({},JSON.parse(JSON.stringify(child_node.props.item)))
      item_child[key] = value;
      child_node.setProps({ item }, true)
    },
  },
  mounted(){
    // $(`#element-${this.node.uuid} > .sg-tabs-content > ul.tabset`).on('click', 'li', function () {
    //   console.log(123123);
    //   const element_id = $(this).parent().data('id');
    //   const item_id = $(this).data('id');
    //   $('#element-' + element_id + ' ul li').removeClass('current');
    //   $('#element-' + element_id + ' .tab-content').removeClass('current');
    //   $(this).addClass('current');
    //   $('#' + item_id).addClass('current');
    // })
  },
  props: {
    ...otherProps,
    component: String,
    
    extra_class: String,
    items: Array,
    tab_style: String,
    border_color: String,
    border_size: Number,
    tab_background: String,
    text_color: String,
    default_tab: String,
    inactive_tab_background: String,
    inactive_tab_text_color: String,
    alignment: String,
    desktop: Object,
    laptop: Object,
    mobile: Object,
    tablet: Object,
  },
  watch: {
    dragging: function (newValue, oldValue) {
      var $iframe = $("#designEditor").contents();
      var $element = $iframe.find(`#element-${this.node.uuid}`);
      var $content = $element.find(`.sg-tabs-content`);
      if (newValue && newValue == this.node.uuid) {
        setTimeout(() => {
          $element.css("padding","0px 0px 0px 0px");
          $element.css("margin","0px 0px 0px 0px");
          $content.hide();
        },100);
      }else if (oldValue == this.node.uuid){
        $element.css("padding","");
        $element.css("margin","");
        $content.show();
      }
    },
  },
  data() {
    return {
      isHovering: false,
    }
  },
  craft: {
    defaultProps: {
      ...otherStyles,
      extra_class: "",
      items: [
        {
          id: makeId(20),
          name: 'Tab 1',
          index: '1'
        },
        {
          id: makeId(20),
          name: 'Tab 2',
          index: '2'
        }
      ],
      tab_style: 'default',
      border_color: 'rgba(206, 206, 206, 1)',
      border_size: 1,
      tab_background: 'rgba(255, 255, 255, 1)',
      text_color: 'rgba(51, 51, 51, 1)',
      default_tab: '1',
      inactive_tab_background: 'rgba(224, 224, 224, 1)',
      inactive_tab_text_color: 'rgba(141, 141, 141, 1)',
      alignment: 'left',
      desktop: {
        ...designStyles,
        tab_padding_x: 20,
        tab_padding_y: 20,
        padding_top: 30,
        padding_bottom: 30,
      },
      laptop: {
        ...designStyles,
      },
      tablet: {
        ...designStyles,
      },
      mobile: {
        ...designStyles,
      },
      elementStyle: {

      },
    },
    settings: {
      settings: {
        Items,
        "Tab Style": TabStyle,
        "Tab Padding": {"Tab Padding": TabPadding, multi_screen: true},
        Border: BorderSetting,
        "Active Tab": ActiveTab,
        "Normal Tab": NormalTab,
        Alignment,
        "Extra Class": ExtraClass, "Custom CSS": CustomCSS,
      },
      design: {
        Visibility,
        Spacing: { Spacing, multi_screen: true },
        Background: { "Background": BackgroundDesign, multi_screen: true },
        Border: { Border, multi_screen: true },
        Corner: { Corner, multi_screen: true },
        Shadow: { Shadow, multi_screen: true },
        Opacity: { Opacity, multi_screen: true },
      },
      others: {
        Animations: { Animations, actions: actionTypes.filter(e => ['scroll_into_view', 'after_page_finish_loading'].includes(e.value)) }
      }
    },
  },
};
</script>

<style lang="scss" scoped>

.dragging{
  position:absolute;
  top:0;
  bottom: 0;
  left: 0;
  right:0;
  background: greenyellow;
  z-index: 10;
}

</style>