<template>
  <div
    data-label="SelectField"
    data-key="selectfield"
    class="sg-wrap block"
    :id="`element-${node.uuid}`"
    @mouseover="isHovering=true" 
    @mouseout="isHovering=false"
    :class="{ 'dragging': dragging == node.uuid }"
  >
    <div 
      class="bl"
      :class="extra_class"
    >
      <select 
        class="sg-selectfield"
        :name="name"
        :required="required"
        value=""
      >
        <option value="" disabled selected class="first-option">{{ placeholder }}</option>
      </select>
    </div>
    <ToolBar
      ref="selectfield"
      toolbar_id="selectfield"
      class="container-toolbar"
      :class="{ 'container-toolbar-show': isHovering, 'is-dragging': dragging == node.uuid }"
    ></ToolBar>

    <v-style>
      {{ custom_css }}

      @media (max-width: 767px){ 
        #element-{{ node.uuid }} .sg-selectfield {
          width: {{ getValue('width','mobile') }}{{getValue('width_type','mobile')}};
          height: {{ getHeight(getValue('height','mobile')) }};
          font-family: {{ getValue('font_family','mobile') }};
          font-weight: {{ getValue('font_weight','mobile') }};
          {{ getValue('font_size', 'mobile') > 0 ? `font-size: ${getValue('font_size','mobile')}px;` : '' }}
          {{ getValue('color', 'mobile') ? `color: ${getValue('color','mobile')};` : '' }}

          {{ getPaddingValue('mobile') }}
          {{ getBackgroundValue('mobile') }}
          {{ getBorderValue('mobile') }}
          {{ getBorderRadiusValue('mobile') }}
          {{ getOpacityValue('mobile') }}
          {{ getShadowValue('mobile') }}
          {{ getOtherStyle('mobile') }}
        }
        #element-{{ node.uuid }} .bl {
          text-align: {{ getValue('alignment','mobile') }};
        }
        #element-{{ node.uuid }} {
          {{ getDisplayValue('mobile') }}
          {{ getMarginValue('mobile') }}
        }
      } 
      @media (max-width: 991px) and (min-width: 768px){ 
        #element-{{ node.uuid }} .sg-selectfield {
          width: {{ getValue('width','tablet') }}{{getValue('width_type','tablet')}};
          height: {{ getHeight(getValue('height','tablet')) }};
          font-family: {{ getValue('font_family','tablet') }};
          font-weight: {{ getValue('font_weight','tablet') }};
          {{ getValue('font_size', 'tablet') > 0 ? `font-size: ${getValue('font_size','tablet')}px;` : '' }}
          {{ getValue('color', 'tablet') ? `color: ${getValue('color','tablet')};` : '' }}

          {{ getPaddingValue('tablet') }}
          {{ getBackgroundValue('tablet') }}
          {{ getBorderValue('tablet') }}
          {{ getBorderRadiusValue('tablet') }}
          {{ getOpacityValue('tablet') }}
          {{ getShadowValue('tablet') }}
          {{ getOtherStyle('tablet') }}
        }
        #element-{{ node.uuid }} .bl {
          text-align: {{ getValue('alignment','tablet') }};
        }
        #element-{{ node.uuid }} {
          {{ getDisplayValue('tablet') }}
          {{ getMarginValue('tablet') }}
        }
      } 
      @media (max-width: 1199px) and (min-width: 992px){ 
        #element-{{ node.uuid }} .sg-selectfield {
          width: {{ getValue('width','laptop') }}{{getValue('width_type','laptop')}};
          height: {{ getHeight(getValue('height','laptop')) }};
          font-family: {{ getValue('font_family','laptop') }};
          font-weight: {{ getValue('font_weight','laptop') }};
          {{ getValue('font_size', 'laptop') > 0 ? `font-size: ${getValue('font_size','laptop')}px;` : '' }}
          {{ getValue('color', 'laptop') ? `color: ${getValue('color','laptop')};` : '' }}
          {{ getPaddingValue('laptop') }}
          {{ getBackgroundValue('laptop') }}
          {{ getBorderValue('laptop') }}
          {{ getBorderRadiusValue('laptop') }}
          {{ getOpacityValue('laptop') }}
          {{ getShadowValue('laptop') }}
          {{ getOtherStyle('laptop') }}
        }
        #element-{{ node.uuid }} .bl {
          text-align: {{ getValue('alignment','laptop') }};
        }
        #element-{{ node.uuid }} {
          {{ getDisplayValue('laptop') }}
          {{ getMarginValue('laptop') }}
        }
      } 
      @media (min-width: 1200px){ 
        #element-{{ node.uuid }} .sg-selectfield {
          width: {{ desktop.width }}{{desktop.width_type}};
          height: {{ getHeight(desktop.height) }};
          font-family: {{ desktop.font_family }};
          font-weight: {{ desktop.font_weight }};
          {{ getValue('font_size', 'desktop') > 0 ? `font-size: ${getValue('font_size','desktop')}px;` : '' }}
          {{ getValue('color', 'desktop') ? `color: ${getValue('color','desktop')};` : '' }}
          {{ getPaddingValue('desktop') }}
          {{ getBackgroundValue('desktop') }}
          {{ getBorderValue('desktop') }}
          {{ getBorderRadiusValue('desktop') }}
          {{ getOpacityValue('desktop') }}
          {{ getShadowValue('desktop') }}
          {{ getOtherStyle('desktop') }}
        }
        #element-{{ node.uuid }} .bl {
          text-align: {{ desktop.alignment }};
        }
        #element-{{ node.uuid }} {
          {{ getDisplayValue('desktop') }}
          {{ getMarginValue('desktop') }}
        }
      }

    </v-style>
  </div>

</template>

<script>
import Typography from "./styleSettings/Typography.vue";
import AlignmentBase from "./styleSettings/AlignmentBase.vue";
import Dimensions from "./styleSettings/Dimensions.vue";
import ExtraClass from "./otherSettings/ExtraClass.vue";
import Name from "./otherSettings/Name.vue";
import Placeholder from "./otherSettings/Placeholder.vue";
import Required from "./otherSettings/Required.vue";

import elementStyleMixin from "./elementStyleMixin";
import ToolBar from "./utils/ToolBar.vue";
import Visibility from "./designSettings/Visibility.vue";
import Spacing from "./designSettings/Spacing.vue";
import Background from "./designSettings/Background.vue";
import Border from "./designSettings/Border.vue";
import Corner from "./designSettings/Corner.vue";
import Shadow from "./designSettings/Shadow.vue";
import Opacity from "./designSettings/Opacity.vue";
import { designStyles, otherProps, otherStyles, actionTypes } from "@/constants";
import CustomCSS from "./styleSettings/CustomCSS.vue";
import Animations from "./designSettings/Animations.vue";

export default {
  mixins: [elementStyleMixin],
  components: { ToolBar },
  props: {
    ...otherProps,
    component: String,
    content: String,
    placeholder: String,
    isSelected: Boolean,
    
    extra_class:String,
    name:String,
    required:Boolean,
    placeholder:String,
    desktop: Object,
    laptop: Object,
    mobile: Object,
    tablet: Object,
    read_only_name: Boolean,
    required: Boolean,
    disabled: Boolean,
  },
  data() {
    return {
      isHovering: false,
    };
  },
  inject: ["node",'editor'],
  computed: {
    dragging: function () {
      return this.editor?.draggedNode?.uuid
    },
  },
  methods: {
    updateContent(content) {
      this.node.setProps({ content });
    },
    getHeight(height) {
      if (height.includes('%') || height.includes('px') || isNaN(parseInt(height))) {
        return height;
      }
      return `${height}px`
    }
  },
  craft: {
    defaultProps: {
      ...otherStyles,
      disabled: false,
      placeholder: "Select option",
      content: "",
      extra_class:"",
      name:"contact[name]",
      required:true,
      read_only_name:false,
      desktop: {
        ...designStyles,
        width: '100',
        width_type: '%',
        height: 'auto',
        font_weight: '400',
        font_size: 16,
        font_family: 'inherit',
        color: 'rgb(0, 0, 0)',
        alignment: 'center',
        padding_top: 10,
        padding_bottom: 10,
        padding_left: 18,
        padding_right: 18,
      },
      laptop: {
        ...designStyles,
      },
      mobile: {
        ...designStyles,
      },
      tablet: {
        ...designStyles,
      },
      typographyActives: [
        "font-size",
        "font-weight",
        "font-family",
        "color"
      ],
      dimensionActives: [
        'width', 'height'
      ],
      elementStyle: {
        
      },
    },
    settings: {
      settings: {
        Name,
        Placeholder,
        Required,
        Dimension: { Dimension: Dimensions, multi_screen: true},
        Typography: { Typography, multi_screen: true},
        "Alignment": { Alignment: AlignmentBase, multi_screen: true},
        "Extra Class": ExtraClass,
        "Custom CSS": CustomCSS,
      },
      design: {
        Visibility,
        Spacing: { Spacing, multi_screen: true },
        Background: { Background, multi_screen: true },
        Border: { Border, multi_screen: true },
        Corner: { Corner, multi_screen: true },
        Shadow: { Shadow, multi_screen: true },
        Opacity: { Opacity, multi_screen: true },
      },
      others: {
        Animations: { Animations, actions: actionTypes }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  width: 100%;
  box-sizing: border-box;
  white-space: pre-wrap;
}
</style>
