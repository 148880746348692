<template>
  <div label-position="top" size="mini" :model="elementProps">
    <div class="d-flex flex-column py-2">
      <span class="setting-title">Background Color</span>
      <ColorPicker
        class="mt-2"
        :value="getValue('background_color',elementProps[screen].background_color)"
        v-bind:changeColor="($event) => updateProps('background_color', $event)"
        :primaryColor="primaryColor"
      />
    </div>
    <div class="d-flex flex-column py-2">
      <span class="setting-title">Text Color</span>
      <ColorPicker
        class="mt-2"
        :value="elementProps[screen].color"
        v-bind:changeColor="($event) => updateProps('color', $event)"
        :primaryColor="primaryColor"
      />
    </div>
    <div class="d-flex align-items-center justify-content-between py-2">
      <span class="sgSubTitle">Hover Options</span>
      <el-switch
        :value="getValue('hover',elementProps[screen].hover)"
        :active-color="primaryColor"
        inactive-color="#949494"
        @change="updateProps('hover', $event)"
      />
    </div>
    <div class="d-flex flex-column py-2" v-if="getValue('hover',elementProps[screen].hover)">
      <span class="setting-title">Background Color Hover</span>
      <ColorPicker
        class="mt-2"
        :value="getValue('background_color_hover',elementProps[screen].background_color_hover)"
        v-bind:changeColor="($event) => updateProps('background_color_hover', $event)"
        :primaryColor="primaryColor"
      />
    </div>
    <div class="d-flex flex-column py-2" v-if="getValue('hover',elementProps[screen].hover)">
      <span class="setting-title">Text Color Hover</span>
      <ColorPicker
        class="mt-2"
        :value="getValue('color_hover',elementProps[screen].color_hover)"
        v-bind:changeColor="($event) => updateProps('color_hover', $event)"
        :primaryColor="primaryColor"
      />
    </div>
  </div>
</template>

<script>
import mixin from "./mixin";
import ColorPicker from "../common/ColorPicker.vue";
export default {
  mixins: [mixin],
  components: {
    ColorPicker,
  },
  props: {
    screen: String,
    primaryColor: String,
  },
  methods: {
    updateProps(name, value) {
      const screen = this.$props.screen;
      const screen_data = Object.assign({}, this.elementProps[screen])
      if(value ==  screen_data[name]) return;
      screen_data[name] = value;
      this.elementPropsSetter({ [screen]: screen_data });
    },
  },
};
</script>

<style scoped>
</style>
