<template>
  <aside data-v-801b7630="" class="sidebar trial-plan">
    <section class="sidenav mt-1">
      <div class="scrollBody ps ps--active-y">
        <article class="pages d-none d-lg-flex flex-column">
          <div class="nav-link subtitle">
            <span>PAGES</span>
          </div>
          <a
            href="/landingpages/"
            aria-current="page"
            :class="`nav-link ${
              currentRouteName == 'LandingPage' ? 'rl-eactive rl-active' : ''
            }`"
          >
            <span class="icon material-symbols-outlined"> description </span>
            <span class="text-item">Sale Pages</span>
            <a href="/landingpages/create" class="btn nav-link__quick-add">
              <div style="width: 16px; height: 16px">
                <span class="icon material-symbols-outlined"> add </span>
              </div>
            </a>
          </a>
          <a
            href="/homepages/"
            :class="`nav-link ${
              currentRouteName == 'HomePage' ? 'rl-eactive rl-active' : ''
            }`"
          >
            <span class="icon material-symbols-outlined"> home </span>
            <span class="text-item">Home Pages</span>
            <a href="/editor?type=homepages" class="btn nav-link__quick-add">
              <div style="width: 16px; height: 16px">
                <span class="icon material-symbols-outlined"> add </span>
              </div>
            </a>
          </a>
          <!-- <a
            href="/productpages/"
            :class="`nav-link ${
              currentRouteName == 'ProductPage' ? 'rl-eactive rl-active' : ''
            }`"
          >
            <span class="icon material-symbols-outlined"> local_mall </span>
            <span class="text-item">Product Pages</span>
            <a href="/editor?type=productpages" class="btn nav-link__quick-add">
              <div style="width: 16px; height: 16px">
                <span class="icon material-symbols-outlined"> add </span>
              </div>
            </a>
          </a> -->
          <a
            href="/checkoutpages/"
            :class="`nav-link ${
              currentRouteName == 'CheckoutPage' ? 'rl-eactive rl-active' : ''
            }`"
          >
            <span class="icon material-symbols-outlined"> shopping_cart </span>
            <span class="text-item">Checkout Pages</span>
            <a href="/editor?type=checkoutpages" class="btn nav-link__quick-add">
              <div style="width: 16px; height: 16px">
                <span class="icon material-symbols-outlined"> add </span>
              </div>
            </a>
          </a>
          <!-- <a
            href="/collectionpages"
            :class="`nav-link ${
              currentRouteName == 'CollectionPage' ? 'rl-eactive rl-active' : ''
            }`"
          >
            <span  class="icon material-symbols-outlined">
              grid_view
            </span>

            <span  class="text-item">Collection Pages</span>
            <a
              
              href="/collectionpages/create"
              class="btn nav-link__quick-add"
            >
              <div style="width: 16px; height: 16px">
                <span  class="icon material-symbols-outlined">
                  add
                </span>
              </div>
            </a>
          </a>
          <a
            href="/articlepages/"
            :class="`nav-link ${
              currentRouteName == 'BlogPage' ? 'rl-eactive rl-active' : ''
            }`"
          >
            <span class="icon material-symbols-outlined"> edit </span>
            <span class="text-item">Blog Posts</span>
            <a href="/articlepages/create" class="btn nav-link__quick-add">
              <div style="width: 16px; height: 16px">
                <span class="icon material-symbols-outlined"> add </span>
              </div>
            </a>
          </a> -->
        </article>
        <article class="pages d-none flex-column">
          <div class="nav-link subtitle mt-2"><span>STORE SETTINGS</span></div>
          <a
            href="/account/settings"
            class="nav-link"
            :class="`nav-link ${currentRouteName == 'Settings' ? 'rl-eactive rl-active' : ''}`"
            aria-current="page"
          >
            <span class="icon material-symbols-outlined"> settings </span>
            <span class="text-item">Settings</span></a
          >
        </article>

        <div class="ps__rail-x" style="left: 0px; bottom: -130px">
          <div
            class="ps__thumb-x"
            tabindex="0"
            style="left: 0px; width: 0px"
          ></div>
        </div>
        <div class="ps__rail-y" style="top: 130px; height: 528px; right: 0px">
          <div
            class="ps__thumb-y"
            tabindex="0"
            style="top: 105px; height: 423px"
          ></div>
        </div>
      </div>
    </section>
    <div class="v-portal" style="display: none"></div>
  </aside>
</template>

<script>
export default {
  components: {},
  inject: [],
  props: {},
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.sidebar {
  position: fixed;
  margin-top: 60px;
  left: 0;
  top: 0;
  height: 100vh;
  width: 290px;
  background-color: #252525;
  border-top: 1px solid #494949;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  z-index: 49;
}

@media screen and (min-width: 1440px) {
  .sidebar {
    min-width: 290px;
  }
}

.sidebar .sidenav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  position: relative;
}

.ps {
  position: relative;
}
.ps {
  overflow: hidden !important;
  overflow-anchor: none;
  -ms-overflow-style: none;
  touch-action: auto;
  -ms-touch-action: auto;
}

.starter-plan .scrollBody,
.trial-plan .scrollBody {
  max-height: calc(100vh - 236px);
}

.scrollBody {
  max-height: calc(100vh - 190px);
}

.flex-shrink-0 {
  -ms-flex-negative: 0 !important;
  flex-shrink: 0 !important;
}

.sidebar .sidenav .nav-link {
  padding: 10px 0 10px 21px;
  border-left: 3px solid #333;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-weight: 600;
  text-decoration: none;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.sidebar .sidenav .nav-link,
.sidebar .sidenav .nav-link:active {
  color: inherit;
}

.sidebar .sidenav .nav-link:not(.disabled) {
  color: #aaa;
}

.sidebar .sidenav .nav-link.dashboard {
  margin-bottom: 24px;
}

.sidebar .sidenav .nav-link .text-item {
  height: 17px;
  font-size: 14px;
  line-height: 17px;
}

.flex-column {
  -webkit-box-orient: vertical !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}
.flex-column,
.flex-row {
  -webkit-box-direction: normal !important;
}

.sidebar .sidenav .nav-link.subtitle {
  color: #757575;
  font-size: 12px;
  line-height: 18px;
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 8px;
  letter-spacing: 0.5px;
}

.sidebar .sidenav a.nav-link:hover {
  padding-left: 21px;
  background-color: #323232;
  cursor: pointer;
}
.sidebar .sidenav .nav-link.rl-active,
.sidebar .sidenav .nav-link.rl-eactive {
  border-left: 3px solid #e2e2e2;
  padding-left: 21px;
  background-color: #323232;
  cursor: pointer;
}
.sidebar .sidenav .nav-link.rl-active .text-item,
.sidebar .sidenav .nav-link.rl-active span.icon,
.sidebar .sidenav .nav-link.rl-active svg,
.sidebar .sidenav .nav-link.rl-eactive .text-item,
.sidebar .sidenav .nav-link.rl-eactive span.icon,
.sidebar .sidenav .nav-link.rl-eactive svg {
  color: #e2e2e2;
}

.sidebar .sidenav .nav-link span.icon,
.sidebar .sidenav .nav-link svg {
  margin-right: 16px;
  font-size: 20px;
  line-height: 20px;
  color: #e2e2e2;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  position: relative;
  top: -1px;
}
a.btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  outline: none;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 3px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  font-family: inherit;
  font-style: normal;
  white-space: nowrap;
  padding: 7.5px 24px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  background-color: #fff;
  color: #e2e2e2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
.sidebar .sidenav .nav-link__quick-add {
  padding: 0;
  width: 32px;
  height: 32px;
  background: inherit;
  margin-left: auto;
  display: none;
  z-index: 10;
  position: absolute;
  top: calc(50% - 16px);
  right: 16px;
}

.sidebar .sidenav a.nav-link:hover .nav-link__quick-add {
  display: flex;
}

.nav-link__quick-add span.icon {
  top: unset !important;
  font-size: 16px !important;
  line-height: 16px !important;
  color: #fff !important;
}
.nav-link__quick-add:hover {
  background-color: #252525 !important;
}
@media screen and (max-height: 672px) {
  .sidebar .sidenav {
    margin-top: 12px;
  }

  .scrollBody {
    max-height: calc(100vh - 162px);
  }

  .trial-plan .scrollBody {
    max-height: calc(100vh - 204px);
  }

  .sidebar .sidenav .nav-link.dashboard {
    margin-bottom: 16px;
  }

  .sidebar .sidenav .nav-link.subtitle {
    margin-bottom: 8px;
  }
}

@media screen and (max-height: 600px) {
  .sidebar .sidenav {
    margin-top: 8px;
  }
  .scrollBody {
    max-height: calc(100vh - 145px);
  }
  .trial-plan .scrollBody {
    max-height: calc(100vh - 183px);
  }

  .sidebar .sidenav .nav-link {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .sidebar .sidenav .nav-link.dashboard {
    margin-bottom: 8px;
  }

  .sidebar .sidenav .nav-link.subtitle {
    margin-bottom: 4px;
  }
}
</style>
