<template>
  <div class="sg-popupAnchorList">
    <div
      data-label="Anchor Popup"
      data-key="anchor popup"
      v-for="node in editor.popups.filter(x=>x.componentName == 'Popup')"
      :key="node.uuid"
      :id="`sg-popup-${node.uuid}`"
      :data-id="node.uuid"
      :class="`sg-popupAnchor ${node.props.showPopup ? '' : 'sg-popupOff'} ${node.props.link_button ? 'link-button' : ''}`"
      data-index="0"
      @click="openPopUp($event, node)"
    >
      <span>{{ node.props.name || "New Popup" }}</span>
      <v-style>
        .production .sg-popupAnchor.link-button {
          display: none !important;
        }
        @media (max-width: 767px){ #sg-popup-{{ node.uuid }} { display:
        {{ node.props.mobile.display }} } } @media (max-width: 991px) and
        (min-width: 768px){ #sg-popup-{{ node.uuid }} { display:
        {{ node.props.tablet.display }}; } } @media (max-width:1199px) and
        (min-width: 992px){ #sg-popup-{{ node.uuid }} { display:
        {{ node.props.laptop.display }}; } } @media (min-width: 1200px){
        #sg-popup-{{ node.uuid }} { display: {{ node.props.desktop.display }}; }
        }
      </v-style>
    </div>
  </div>
</template>
<script>
export default {
  name: "Anchor",
  props: {
    editor: Object,
  },
  watch: {
    editor(oldValue, newValue) {},
  },
  data(props) {
    return {};
  },
  methods: {
    openPopUp(event, node) {
      const { editor } = this;
      for (var i = 0; i < editor.popups.length; i++) {
        if (editor.popups[i].uuid != node.uuid) {
          editor.popups[i].setProps({ showPopup: false }, true);
        }
      }
      node.setProps({ showPopup: !node.props.showPopup }, true);
      editor.selectNode(null);
      event.stopPropagation();
    },
  },
};
</script>

