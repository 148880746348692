<template>
  <div>
    <div v-if="checkout_method == 'checkout_page'">
      <TitlePage :title="title" :subTitle="subTitle" :page="page"></TitlePage>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="Templates" name="first">
          <div class="py-3 d-flex justify-content-end align-items-center">
            <el-input 
              style="width: 30%;"
              placeholder="Search template"
              prefix-icon="el-icon-search"
              :value="search_template"
              @change="$event => handleSearchTemplate($event)"
              @input="$event => handleChangeSearch($event)"
            />
          </div>
          <div class="sg-page-list">
            <el-skeleton v-if="loadingTemplate" style="width: 100%;" animated :count="6">
              <template slot="template">
                <div class="page-item d-flex justify-content-between align-items-center mb-3 no-select-hover">
                  <div class="page-item-info d-flex align-items-center w-100">
                    <el-skeleton-item variant="image" style="width: 144px; height: 80px;" />
                    <div class="page-item-info-body d-flex align-items-center px-2 w-100">
                      <div class="flex-1 d-flex flex-column gap-2">
                        <el-skeleton-item variant="text" style="width: 50%;" />
                        <el-skeleton-item variant="text" style="width: 20%;" />
                      </div>
                      <div style="width: 40px; height: 40px;" class="d-flex align-items-center justify-content-center">
                        <el-skeleton-item variant="text" style="width: 100%;" />
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </el-skeleton>
            <div
              v-for="template in (!loadingTemplate ? templates : [])"
              :key="template.template_id"
              class="page-item d-flex justify-content-between align-items-center mb-3 no-select-hover"
              loadinglist="true"
              :class="template.default ? 'active' : ''"
            >
              <div class="default-template" />
              <a
                :href="template.system_default ? '#' : `/editor?id=${template.template_id}&amp;page=${template.slug}&amp;shop=${store?.domain_primary||store?.domain||store?.sub_domain}&amp;type=checkoutpages`"
                class="col align-self-stretch d-flex"
                @click="(e) => handleClickTemplate(e, template)"
              >
                <div class="row">
                  <div class="page-item-info d-flex align-items-center">
                    <div v-if="template.system_default" class="page-item-info-image salegate-home">
                      <span v-html="homeIcons.salegate" />
                      <span class="ml-2">Salegate</span>
                    </div>
                    <div v-else class="page-item-info-image">
                      <img src="../../assets/images/ThumbnailPlaceholder.jpg" />
                    </div>
                    <div class="page-item-info-body ml-2">
                      <div class="d-flex align-items-center">
                        <div class="page-item-info-body-title mr-1 d-flex align-items-center">
                          <p class="typo-heading16 typo-medium text-ligh-high">
                            {{ template.system_default ? 'Default Template' : template.title }}
                          </p>
                        </div>
                        <div
                          v-if="template.default"
                          class="page-item-info-body-status default"
                        >
                          <p class="typo-paragraph12 typo-medium">Default</p>
                        </div>
                      </div>
                      <div class="page-item-info-body-desc d-flex align-items-center justify-content-start typo-paragraph12">
                        <div class="page-item-info-body-modified d-flex align-items-center">
                          <p class="page-item-info-body-modified-at typo-paragraph12 typo-regular text-ligh-low d-flex">
                            {{ template.system_default ? `Your original ${title}` : ` Last Modified ${getUpdatedAt(template.write_date)}` }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
              <div v-if="!template.default" class="align-self-stretch d-flex justify-content-center product-collection-assign clickable pr-half pr-xl-0 mr-1">
                <div class="page-item-tools-status d-flex align-items-center">
                  <div
                    class="typo-button14 typo-medium page-item-tools-status-button make-default"
                    @click="handleClickMenu('make_default', template, template.default)"
                  >
                    Make Default
                  </div>
                </div>
              </div>
              <el-tooltip class="item" effect="dark" content="Options" placement="top">
                <div class="d-flex flex-row-reverse p-0">
                  <el-popover
                    popper-class="more-actions"
                    width="250"
                    :visible-arrow="false"
                  >
                    <ul>
                      <li 
                        :class="{disabled: template.system_default}"
                        @click="handleClickMenu('edit', template, template.system_default ? true : false)"
                      >
                        <span v-html="homeIcons.edit" /> Edit
                      </li>
                      <li 
                        @click="handleClickMenu('preview', template, false)"
                      >
                        <span v-html="homeIcons.preview" /> Preview
                      </li>
                      <li 
                        :class="{disabled: template.system_default}"
                        @click="handleClickMenu('rename', template, template.system_default ? true : false)"
                      >
                        <span v-html="homeIcons.rename" /> Rename
                      </li>
                      <li 
                        :class="{disabled: template.system_default}"
                        @click="handleClickMenu('duplicate', template, template.system_default ? true : false)"
                      >
                        <span v-html="homeIcons.duplicate" /> Duplicate
                      </li>
                      <li
                        :class="{disabled: template.system_default}"
                        @click="handleClickMenu('delete', template, template.system_default ? true : false)"
                      >
                        <span v-html="homeIcons.delete" /> Delete
                      </li>
                    </ul>
                    <div class="page-item-tools-actions" slot="reference">
                      <span v-html="homeIcons.more" />
                    </div>
                  </el-popover>
                </div>
              </el-tooltip>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
      <el-pagination
        v-if="totals > 0"
        class="mt-3 text-center"
        background
        layout="prev, pager, next"
        :total="totals"
        :page-size="6"
        @current-change="changePage($event)"
        @prev-click="changePage(current_page - 1)"
        @next-click="changePage(current_page + 1)"
      >
      </el-pagination>
      <el-dialog
        class="custom-dialog"
        :visible.sync="modalObj.open"
        :width="modalObj.width || '30%'"
        :before-close="handleClose"
        :close-on-click-modal="false"
      >
        <span slot="title">
          <div v-html="modalObj.title" />
        </span>
        <div class="p-4">
          <div v-html="modalObj.content" />
          <div
            class="input-text d-flex flex-column py-2"
            v-if="['rename', 'duplicate'].includes(modalObj.data?.type)"
          >
            <span>Page Title</span>
            <el-input
              class="mt-2"
              :value="input"
              @input="updateValue($event)"
            ></el-input>
            <div v-if="error" class="text-error">{{ error }}</div>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="handleClose">Cancel</el-button>
          <el-button
            :loading="loadingBtn"
            :class="modalObj.btn_class || 'primary'"
            type="primary"
            @click="handleConfirm(modalObj.data)"
            >{{ modalObj.btn_title || "Confirm" }}</el-button
          >
        </span>
      </el-dialog>
    </div>
    <div v-else-if="checkout_method == 'paypal'">
      Please setting "Checkout Page" as checkout method in store admin page to design this page.
    </div>
    <div v-else>

    </div>
  </div>
</template>

<script>
const TitlePage = () => import('../../layouts/components/TitlePage.vue');
import { request } from "../../services";
import { homeIcons } from "../../constants";
import moment from "moment";
import Cookies from "js-cookie";
export default {
  components: {
    TitlePage,
  },
  props: {
    page: String,
    title: String,
    subTitle: String,
  },
  created() {
    this.loadTemplates({})
  },
  data() {
    return {
      checkout_method: "",
      activeName: "first",
      templates: [],
      loadingTemplate: true,
      store: JSON.parse(Cookies.get("user_onepage") || "{}").store,
      loadingBtn: false,
      homeIcons,
      modalObj: {},
      input: "",
      search_template: "",
      search_product: "",
      error: "",
      paths: {
        landingpages: "landing",
        homepages: "",
        articlepages: "blog",
        checkoutpages: "checkout"
      },
      product: null,
      current_page: 1,
      totals: 0
    };
  },
  methods: {
    handleChangeSearch(value) {
      this.search_template = value;
    },
    handleSearchTemplate(value) {
      const query = {
        title: value,
      }
      this.loadingTemplate = true;
      this.loadTemplates(query)
    },
    loadTemplates(queryObject) {
      const query = {
        ...queryObject,
        page_type: 'checkoutpages'
      }
      request.get("/api/v1/templates", query).then((res) => {
        this.loadingTemplate = false;
        if (res.data.data.checkout_method == 'checkout_page') {
          this.templates = res.data.data.items;
          this.totals = res.data.data.total;
        }
        this.checkout_method = res.data.data.checkout_method
      }, err => {
        this.$toastr.e(err?.response?.data?.message || 'Error');
        this.loadingTemplate = false;
      })
    },
    changePage(value) {
      this.current_page = value
      const query = {
        page: value
      }
      this.loadingTemplate = true;
      this.loadTemplates(query)
    },
    handleClickTemplate(e, template) {
      if (template.system_default) {
        e.preventDefault();
        this.$toastr.e(`You can't edit this page because it wasn't created by OnePage`)
      }
    },
    updateModalObject(name, value) {
      const data = this.modalObj.data;
      this.modalObj = {...this.modalObj, data:{...data, [name]:value}};
    },
    updateValue(value) {
      this.input = value;
    },
    handleClickMenu(action, data, disabled) {
      if (disabled) {
        return;
      }
      var modalObj = null;
      if (action == "edit") {
        window.location.href = `/editor?id=${data.template_id}&page=${data.slug}&shop=${this.store?.domain_primary || this.store?.domain || this.store?.sub_domain}&type=checkoutpages`;
      } else if (action == "preview") {
        window.open(`https://${this.store?.domain_primary || this.store?.domain || this.store?.sub_domain}/preview/${data.slug}`, "_blank");
      } else if (action == "rename") {
        modalObj = {
          open: true,
          title: '<div style="font-size: 20px; font-weight: 600;">Rename</div>',
          data: { template: data, type: action, input: this.input },
        };
        this.modalObj = modalObj;
        this.input = data.title;
      } else if (action == "make_default") {
        modalObj = {
          open: true,
          title: '<div style="font-size: 20px; font-weight: 600;">Make Default</div>',
          content: `<div class="text-content">Are you sure you want to make "${ data && data.system_default ? 'Salegate Default Template' : data.title }" as default?</div>`,
          data: {template: data, type: action},
        }
        this.modalObj = modalObj;
      } else if (action == "duplicate") {
        modalObj = {
          open: true,
          title:
            '<div style="font-size: 20px; font-weight: 600;">Duplicate</div>',
          content: `<div class="text-content">Are you sure you want to duplicate "${
            data ? data.title : ""
          }"?</div>`,
          data: { template: data, type: action },
        };
        this.modalObj = modalObj;
        this.input = `Copy of ${data.title}`;
      } else if (action == "delete") {
        modalObj = {
          open: true,
          title:
            '<div style="font-size: 20px; font-weight: 600; color: #EC4B4B;">Delete</div>',
          content: `<div class="text-content">Are you sure you want to delete "${
            data ? data.title : ""
          }"? This action cannot be undone.</div>`,
          data: { template: data, type: action },
          btn_title: "Delete",
          btn_class: "error",
        };
        this.modalObj = modalObj;
      }
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    handleClose() {
      this.modalObj = {};
    },
    handleConfirm(data) {
      this.loadingBtn = true;
      if (data.type == "make_default") {
        const query = { default: 1 }
        request.put(`/api/v1/template/${data.template?.template_id}`, query).then(
          (res) => {
            if (res.data.success) {
              this.$toastr.Add({
                msg: "Success",
                type: "success",
                style: { backgroundColor: "green" },
              });
              this.loadingBtn = false;
              const templates = this.templates.map((e) => e.template_id == data.template?.template_id ? { ...e, default: true, updated_at: new Date() } : { ...e, default: false });
              this.templates = templates;
              this.modalObj = {};
            } else {
              this.$toastr.e("Error");
              this.loadingBtn = false;
            }
          },
          (err) => {
            this.$toastr.e(err?.response?.data?.message || 'Error');
            this.loadingBtn = false;
          }
        );
      } else if (data.type == "rename") {
        if (!this.input) {
          this.error = "Page title name cannot be blank.";
          this.loadingBtn = false;
          return;
        }
        this.error = "";
        const query = {
          title: this.input
        }
        request.put(`/api/v1/template/${data.template?.template_id}`, query).then(res => {
          if (res.data.success) {
            this.$toastr.Add({
              msg: "Success",
              type: "success",
              style: { backgroundColor: "green" },
            });
            this.loadingBtn = false;
            const templates = this.templates.map((e) => e.template_id == data.template?.template_id ? { ...e, title: this.input, slug: res.data.data.slug } : e);
            this.templates = templates;
            this.modalObj = {};
          } else {
            this.$toastr.e("Error");
            this.loadingBtn = false;
          }
        }, (err) => {
          this.$toastr.e(err?.response?.data?.message || 'Error');
          this.loadingBtn = false;
        });
      } else if (data.type == "duplicate") {
        if (!this.input) {
          this.error = "Page title name cannot be blank.";
          this.loadingBtn = false;
          return;
        }
        this.error = "";
        const query = {
          title: this.input,
          template_id: data.template?.template_id,
        }
        request.post(`/api/v1/duplicate_template`, query).then(res => {
          if (res.data.success) {
            this.$toastr.Add({
              msg: "Success",
              type: "success",
              style: { backgroundColor: "green" },
            });
            this.loadingBtn = false;
            const templates = Object.assign([], this.templates);
            templates.push(res.data.data);
            this.templates = templates;
            this.modalObj = {};
          } else {
            this.$toastr.e("Error");
            this.loadingBtn = false;
          }
        }, err => {
          this.$toastr.e(err?.response?.data?.message || 'Error');
          this.loadingBtn = false;
        });
      } else if (data.type == "delete") {
        request.delete(`/api/v1/template/${data.template?.template_id}`).then(res => {
          if (res.data.success) {
            this.$toastr.Add({
              msg: "Success",
              type: "success",
              style: { backgroundColor: "green" },
            });
            this.loadingBtn = false;
            const templates = this.templates.filter(e => e.template_id != data.template?.template_id);
            this.templates = templates;
            this.modalObj = {};
          } else {
            this.$toastr.e("Error");
            this.loadingBtn = false;
          }
        }, err => {
          this.$toastr.e(err?.response?.data?.message || 'Error');
          this.loadingBtn = false;
        });
      }
    },
    getUpdatedAt(value) {
      return moment(value).add(7, 'hours').fromNow();
    },
  },
};
</script>

<style>
  .el-tabs__header {
    margin: 0;
  }
</style>