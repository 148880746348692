<template>
    <el-form label-position="top" size="" :model="elementProps">
      <div class="py-2" v-if="getValueLinked(
            'column_width',
            elementProps[screen]?.column_width,
            'fit_to_content'
          ) == 'fit_to_content'">
        <span class="setting-title">Horizontal</span>
        <ToggleButton
          :primaryColor="primaryColor"
          :value="getValueLinked('place_content', elementProps[screen]?.place_content, 'center')"
          v-bind:changeValue="
            ($event) => updateProps(screen, 'place_content', $event)
          "
          :options="[
            { value: 'start', label: 'Start' },
            { value: 'center', label: 'Center' },
            { value: 'end', label: 'End' },
          ]"
        />
      </div>
    </el-form>
  </template>
      
      <script>
import mixin from "../../../elements/styleSettings/mixin";
import ToggleButton from "../../../elements/common/ToggleButton.vue";
import ColorPicker from "../../../elements/common/ColorPicker.vue";
import Upload from "../../../elements/common/Upload.vue";
import Select from "../../../elements/common/Select.vue";
import Slider from "../../../elements/common/Slider.vue";

export default {
  mixins: [mixin],
  components: {
    ToggleButton,
    ColorPicker,
    Upload,
    Select,
    Slider,
  },
  props: {
    screen: String,
    primaryColor: String,
  },
  methods: {
    updateProps(screen, name, value) {
      const { desktop, laptop, tablet, mobile } = this.elementProps;
      const data_screen = Object.assign({}, this.elementProps[screen]);
      if (value == data_screen[name]) return;

      data_screen[name] = value;
      var obj = {
        [screen]: data_screen,
      };
      this.elementPropsSetter(obj);
    },
  },
};
</script>
      
      <style lang="scss">
.margin-input-1 {
  width: 50%;
}
.margin-input-1 .el-input-group__append {
  border: 1px solid #494949;
  border-left: none;
  background-color: #222222;
  color: rgb(117 117 117);
}
.margin-input-1 .el-input__inner {
  border-right: none !important;
}
</style>
      