<template>
  <div
    data-key="icon-list-hoz"
    data-label="Icon List Hoz"
    class="sg-wrap module sg-icon-list-hoz"
    @mouseover="isHovering=true" 
    @mouseout="isHovering=false"
    :id="`element-${node.uuid}`"
    :class="{ 'dragging': dragging == node.uuid }"

  >
    <div :class="`sg-module sg_module-${desktop.alignment} sg_module-${desktop.alignment}-lg sg_module-${getValue('alignment','laptop')}-md sg_module-${getValue('alignment','tablet')}-sm sg_module-${getValue('alignment','mobile')}-xs`">
      <ul 
        class="icon-list-wrapper sg_icon-list" 
        :class="extra_class"
      >
        <slot />
      </ul>
    </div>
    <ToolBar ref="icon_list_hoz_toolbar" :toolbar_id="`icon_list_hoz`" class="container-toolbar" 
    :class="{ 'container-toolbar-show': isHovering, 'is-dragging': dragging == node.uuid }"
    ></ToolBar>
    <v-style>
      {{ custom_css }}

      @media (max-width: 767px) {
        #element-{{ node.uuid }} {
          {{ getDisplayValue('mobile') }}
          {{ getMarginValue('mobile') }}
          {{ getPaddingValue('mobile') }}
          {{ getBackgroundValue('mobile') }}
          {{ getBorderValue('mobile') }}
          {{ getBorderRadiusValue('mobile') }}
          {{ getOpacityValue('mobile') }}
          {{ getShadowValue('mobile') }}
          {{ getOtherStyle('mobile') }}
        }
      }
      @media (max-width: 991px) and (min-width: 768px) {
        #element-{{ node.uuid }} {
          {{ getDisplayValue('tablet') }}
          {{ getMarginValue('tablet') }}
          {{ getPaddingValue('tablet') }}
          {{ getBackgroundValue('tablet') }}
          {{ getBorderValue('tablet') }}
          {{ getBorderRadiusValue('tablet') }}
          {{ getOpacityValue('tablet') }}
          {{ getShadowValue('tablet') }}
          {{ getOtherStyle('tablet') }}
        }
      }
      @media (max-width: 1199px) and (min-width: 992px) {
        #element-{{ node.uuid }} {
          {{ getDisplayValue('laptop') }}
          {{ getMarginValue('laptop') }}
          {{ getPaddingValue('laptop') }}
          {{ getBackgroundValue('laptop') }}
          {{ getBorderValue('laptop') }}
          {{ getBorderRadiusValue('laptop') }}
          {{ getOpacityValue('laptop') }}
          {{ getShadowValue('laptop') }}
          {{ getOtherStyle('laptop') }}
        }
      }
      @media (min-width: 1200px) {
        #element-{{ node.uuid }} {
          {{ getDisplayValue('desktop') }}
          {{ getMarginValue('desktop') }}
          {{ getPaddingValue('desktop') }}
          {{ getBackgroundValue('desktop') }}
          {{ getBorderValue('desktop') }}
          {{ getBorderRadiusValue('desktop') }}
          {{ getOpacityValue('desktop') }}
          {{ getShadowValue('desktop') }}
          {{ getOtherStyle('desktop') }}
        }
      }
    </v-style>
  </div>
  
</template>

<script>
import elementStyleMixin from '../../elements/elementStyleMixin';
import ToolBar from '../../elements/utils/ToolBar.vue';
import Visibility from '../../elements/designSettings/Visibility.vue';
import Spacing from '../../elements/designSettings/Spacing.vue';
import BackgroundDesign from '../../elements/designSettings/Background.vue';
import Border from '../../elements/designSettings/Border.vue';
import Corner from '../../elements/designSettings/Corner.vue';
import Shadow from '../../elements/designSettings/Shadow.vue';
import Opacity from '../../elements/designSettings/Opacity.vue';
import ExtraClass from '../../elements/otherSettings/ExtraClass.vue';
import Items from './settings/Items.vue';
import AlignmentBase from '../../elements/styleSettings/AlignmentBase.vue';
import IconSpacing from './settings/IconSpacing.vue';
import { makeId, designStyles, otherProps, otherStyles, actionTypes } from '@/constants';
import CustomCSS from '../../elements/styleSettings/CustomCSS.vue';
import Animations from '../../elements/designSettings/Animations.vue';


export default {
  components: { ToolBar },
  mixins: [elementStyleMixin],
  inject: [
    'node',
    'editor'
  ],
  computed: {
    dragging: function () {
      return this.editor?.draggedNode?.uuid
    },
  },
  props: {
    ...otherProps,
    component: String,
    
    extra_class: String,
    items: Array,
    icon_spacing: Number,
    desktop: Object,
    laptop: Object,
    mobile: Object,
    tablet: Object,
  },
  data() {
    return {
      isHovering: false,
    }
  },
  methods: {
    getAlignment(alignment) {
      if (alignment == 'left') {
        return 'flex-start'
      } else if (alignment == 'right') {
        return 'flex-end'
      } else {
        return alignment
      }
    }
  },
  craft: {
    defaultProps: {
      ...otherStyles,
      items: Array.from(Array(5).keys()).map(e => ({id: makeId(20), name: `Item ${e + 1}`})),
      extra_class: "",
      icon_spacing: 50,
      desktop: {
        ...designStyles,
        alignment: 'center',
        padding_top: 30,
      },
      laptop: {
        ...designStyles,
      },
      tablet: {
        ...designStyles,
      },
      mobile: {
        ...designStyles,
      },
      elementStyle: {
      },
    },
    settings: {
      settings: {
        Items,
        "Icon Spacing": IconSpacing,
        Alignment: {Alignment: AlignmentBase, multi_screen: true},
        "Extra Class": ExtraClass, "Custom CSS": CustomCSS,
      },
      design: {
        Visibility,
        Spacing: { Spacing, multi_screen: true },
        Background: { Background: BackgroundDesign, multi_screen: true },
        Border: { Border, multi_screen: true },
        Corner: { Corner, multi_screen: true },
        Shadow: { Shadow, multi_screen: true },
        Opacity: { Opacity, multi_screen: true },
      },
      others: {
        Animations: { Animations, actions: actionTypes.filter(e => ['scroll_into_view', 'after_page_finish_loading'].includes(e.value)) }
      }
    },
  },
};
</script>

<style lang="scss" scoped>

.dragging{
  position:absolute;
  top:0;
  bottom: 0;
  left: 0;
  right:0;
  background: greenyellow;
  z-index: 10;
}

</style>
