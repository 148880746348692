<template>
  <div label-position="top" size="mini" :model="elementProps">
    <div class="py-2">
      <span class="setting-title">Font Size</span>
      <Slider
        class="mt-2"
        :step="1"
        :primaryColor="primaryColor"
        :value="getValue('font_size', elementProps[screen].font_size)"
        v-bind:changeValue="($event) => updateProps('font_size', parseInt($event))"
        unit="px"
        :max="100"
        :min="0"
        :onlyInteger="true"
      />
    </div>
    <div class="py-2">
      <span class="setting-title">Line Height</span>
      <Slider
        class="mt-2"
        :step="0.1"
        :primaryColor="primaryColor"
        :value="getValue('line_height', elementProps[screen].line_height)"
        v-bind:changeValue="($event) => updateProps('line_height', parseFloat($event))"
        unit="em"
        :max="3"
        :min="0.5"
      />
    </div>
    <div class="py-2">
      <span class="setting-title">Letter Spacing</span>
      <Slider
        class="mt-2"
        :step="0.1"
        :primaryColor="primaryColor"
        :value="getValue('letter_spacing', elementProps[screen].letter_spacing)"
        v-bind:changeValue="($event) => updateProps('letter_spacing', parseFloat($event))"
        unit="px"
        :max="4"
        :min="-1"
      />
    </div>
    <div class="d-flex flex-column py-2">
      <span class="setting-title">Font</span>
      <Select
        class="mt-2"
        :primaryColor="primaryColor"
        :value="getValue('font_family', elementProps[screen].font_family)"
        v-bind:changeValue="($event) => updateProps('font_family', $event)"
        :hasAction="true"
        v-bind:onAction="handleShowModal"
        actionLabel="Open Font Manager"
        :options="getListFont()"
        :key="fontKey"
      />
    </div>
  </div>
</template>

<script>
import mixin from '../../../elements/styleSettings/mixin';
import Slider from '../../../elements/common/Slider.vue';
import ColorPicker from '../../../elements/common/ColorPicker.vue';
import Select from '../../../elements/common/Select.vue';
import Node from "@/core/Node";
import { makeId, listFonts } from "@/constants";

export default {
  mixins: [mixin],
  components: {
    Slider, ColorPicker, Select
  },
  props: {
    primaryColor: String,
    screen: String,
    activeFonts: Array,
    node: Node,
  },
  data() {
    return {
      fontKey: makeId(20),
    };
  },
  watch: {
    activeFonts: function (newValue, oldValue) {
      this.fontKey = makeId(20);
    },
  },
  methods: {
    handleShowModal() {
      this.$bus.emit("showFontManager", this.node);
    },
    getListFont() {
      return listFonts();
    },
    updateProps(name, value) {
      const screen = this.$props.screen;
      const { desktop, laptop, tablet, mobile } = this.elementProps;
      const data_screen = Object.assign({}, this.elementProps[screen]);
      if (value == data_screen[name]) return;
      data_screen[name] = value;
      var obj = {
        [screen]: data_screen,
      };
      this.elementPropsSetter(obj);
    },
  },
};
</script>

<style>
.input-number input::-webkit-outer-spin-button, .input-number input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
