<template>
  <!-- <nav class="navbar"> -->
  <div class="header">
    <div class="logo-container">
      <img
        style="height: 50px; width: auto"
        src="../../../assets/images/logo.svg"
      />
      <div class="flex-column mx-2 d-none d-lg-flex">
        <a
          class="breadcrumb-item home"
          style="font-size: 13px"
          :href="back_path"
          >{{ data_routes[back_path] || "" }}<i class="fa fa-angle-right"> </i
        ></a>
        <div
          class="d-flex items-center pointer hover-gray-1 radius-4 p-1"
          @click="showRenameDialog"
        >
          <span
            class="line-clamp-1 mr-2"
            style="font-size: 13px; font-weight: 500"
            >{{ template.title || "" }}</span
          >
          <span v-html="homeIcons.editWhite" />
        </div>
      </div>
    </div>
    <div class="header-container flex-fill">
      <div class="button icon d-none d-lg-block" @click="showLibrary">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 24 24"
          stroke-width="2"
          stroke="#333333"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <rect x="4" y="4" width="6" height="6" rx="1"></rect>
          <rect x="14" y="4" width="6" height="6" rx="1"></rect>
          <rect x="4" y="14" width="6" height="6" rx="1"></rect>
          <rect x="14" y="14" width="6" height="6" rx="1"></rect>
        </svg>
        <span style="margin-left: 6px">Library</span>
      </div>
      <div class="history-control d-flex align-items-center mx-2">
        <el-button
          @click="undo"
          :loading="editor.isUndo"
          :disabled="canUndo"
          data-action="undo"
          title="Undo"
          class="button-sg p-0 sg_history-item d-flex justify-content-center"
        >
          <span>
            <svg
              v-if="!editor.isUndo"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.888672 0.888672V5.62941H5.62941"
                stroke="white"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M8.00005 15.1109V15.1109C11.9274 15.1132 15.1131 11.9313 15.1153 8.00395C15.1176 4.0766 11.9358 0.890949 8.00839 0.888673C4.9933 0.886904 2.30484 2.78675 1.2998 5.62941"
                stroke="white"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
          </span>
        </el-button>
        <el-button
          @click="redo"
          :loading="editor.isRedo"
          :disabled="canRedo"
          data-action="redo"
          title="Redo"
          class="button-sg p-0 sg_history-item d-flex justify-content-center"
        >
          <span>
            <svg
              v-if="!editor.isRedo"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.1157 0.888672V5.62941H10.375"
                stroke="white"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M8.00395 15.1109H8.00395C4.0766 15.1132 0.890949 11.9313 0.888673 8.00395C0.886369 4.0766 4.06826 0.890949 7.99561 0.888673C11.0107 0.886904 13.6992 2.78675 14.7042 5.62941"
                stroke="white"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
          </span>
        </el-button>
      </div>
      <div class="screen-inner flex-fill justify-content-center">
        <div
          :class="{ selected: screen == 'desktop' }"
          @click="changeScreen('desktop')"
        >
          <span v-html="icons.desktop"></span>
        </div>
        <div
          :class="{ selected: screen == 'laptop' }"
          @click="changeScreen('laptop')"
        >
          <span v-html="icons.laptop"></span>
        </div>
        <div
          :class="{ selected: screen == 'tablet' }"
          @click="changeScreen('tablet')"
        >
          <span v-html="icons.tablet"></span>
        </div>
        <div
          :class="{ selected: screen == 'mobile' }"
          @click="changeScreen('mobile')"
        >
          <span v-html="icons.mobile"></span>
        </div>
      </div>
      <div class="button-list d-none d-lg-flex">
        <el-button :loading="loading" @click="handleCheckVersion" class="button-sg">Save</el-button>
        <!-- <div class="button" ></div> -->
        <div v-if="back_path!='/landingpages'" class="button" @click="handlePreview">Preview</div>
        <div v-if="back_path=='/landingpages'" class="button btn-primary" @click="handleViewLivePage">Live page</div>
        
        

        <!-- <el-button
          :disabled="template.is_publish == 1"
          :loading="loading"
          @click="handlePublish"
          class="button-sg publish"
          >Publish</el-button
        > -->

        <el-popover
          popper-class="more-actions-nav"
          width="280"
          :visible-arrow="true"
          effect
          v-model="visible"
        >
          <div>
            <a
              title=""
              class="sg_action-item dropdown_item typo-button14 typo-medium"
              @click="saveTemplateToLibrary"
            >
              <svg
                importItem
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line
                  importItem
                  stroke="currentColor"
                  x1="8.57666"
                  y1="0.617188"
                  x2="8.57666"
                  y2="10.382"
                  stroke-linecap="round"
                ></line>
                <path
                  importItem
                  stroke="currentColor"
                  d="M4.29443 6.96851L8.62368 11.2977L12.953 6.96847"
                  stroke-linecap="round"
                ></path>
                <path
                  importItem
                  stroke="currentColor"
                  d="M1.61963 11.6953V14.0801C1.61963 14.6324 2.06734 15.0801 2.61963 15.0801H14.5821C15.1344 15.0801 15.5821 14.6324 15.5821 14.0801V11.6953"
                  stroke-linecap="round"
                ></path>
                <defs importItem>
                  <rect
                    importItem
                    width="16"
                    height="16"
                    fill="#E2E2E2"
                    transform="translate(0.580078 0.160645)"
                  ></rect>
                </defs>
              </svg>
              <span importItem class="ml-1">Save Template to Library</span></a
            >
          </div>
          <div
            class="page-item-tools-actions sg-button-more px-3"
            slot="reference"
          >
            <span>
              <svg
                importItem
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  importItem
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M3.13389 7.13126C2.64573 6.6431 1.85428 6.6431 1.36612 7.13126C0.87796 7.61941 0.87796 8.41086 1.36612 8.89902C1.85428 9.38718 2.64573 9.38718 3.13389 8.89902C3.62204 8.41086 3.62204 7.61941 3.13389 7.13126ZM3.84099 6.42415C2.96231 5.54547 1.53769 5.54547 0.659012 6.42415C-0.219671 7.30283 -0.219671 8.72745 0.659012 9.60613C1.53769 10.4848 2.96231 10.4848 3.84099 9.60613C4.71967 8.72745 4.71967 7.30283 3.84099 6.42415Z"
                  fill="currentColor"
                ></path>
                <path
                  importItem
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M14.6339 7.13126C14.1457 6.6431 13.3543 6.6431 12.8661 7.13126C12.378 7.61941 12.378 8.41086 12.8661 8.89902C13.3543 9.38718 14.1457 9.38718 14.6339 8.89902C15.122 8.41086 15.122 7.61941 14.6339 7.13126ZM12.159 6.42415C13.0377 5.54547 14.4623 5.54547 15.341 6.42415C16.2197 7.30283 16.2197 8.72745 15.341 9.60613C14.4623 10.4848 13.0377 10.4848 12.159 9.60613C11.2803 8.72745 11.2803 7.30283 12.159 6.42415Z"
                  fill="currentColor"
                ></path>
                <path
                  importItem
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M8.88389 7.13126C8.39573 6.6431 7.60428 6.6431 7.11612 7.13126C6.62796 7.61941 6.62796 8.41086 7.11612 8.89902C7.60428 9.38718 8.39573 9.38718 8.88389 8.89902C9.37204 8.41086 9.37204 7.61941 8.88389 7.13126ZM6.40901 6.42415C7.28769 5.54547 8.71231 5.54547 9.59099 6.42415C10.4697 7.30283 10.4697 8.72745 9.59099 9.60613C8.71231 10.4848 7.28769 10.4848 6.40901 9.60613C5.53033 8.72745 5.53033 7.30283 6.40901 6.42415Z"
                  fill="currentColor"
                ></path>
              </svg>
            </span>
          </div>
        </el-popover>
      </div>
    </div>

    <el-dialog
      class="custom-dialog"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleCloseDialog"
    >
      <span slot="title">
        <div style="font-size: 20px; font-weight: 600">Rename this page</div>
      </span>
      <div class="p-4">
        <div class="input-text d-flex flex-column py-2">
          <el-input class="mt-2" v-model="rename"></el-input>
          <div v-if="error" class="text-error">{{ error }}</div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleCloseDialog" :disabled="dialogLoading">
          Cancel
        </el-button>
        <el-button type="primary" @click="confirmRenameDialog" :loading="dialogLoading">
          Confirm
        </el-button>
      </span>
    </el-dialog>

    <el-dialog
      class="custom-dialog"
      :visible.sync="saveDialogVisible"
      width="30%"
      :before-close="handleCloseSaveDialog"
    >
      <span slot="title">
        <div style="font-size: 20px; font-weight: 600">Warning</div>
      </span>
      <div class="p-4">
        This template version is different on the system. Do you still want to save this version?
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleRevertVersion" :disabled="saveDialogLoading">No, revert to system version</el-button>
        <el-button
          type="primary"
          @click="handleSave"
          :loading="saveDialogLoading"
        >
          Save
        </el-button>
      </span>
    </el-dialog>
  </div>

  <!-- </nav> -->
</template>

<script>
import { request } from "@/services";
import { icons, homeIcons } from "../../../constants";

import Cookies from "js-cookie";

export default {
  inject: ["editor"],
  props: {
    screen: String,
    changeScreen: Function,
    iframe: Object,
    template: Object,
    setTemplate: Function
  },
  mounted() {
    let urlParams = new URLSearchParams(window.location.search);
    this.back_path = `/${urlParams.get("type")}`;
    this.page = `${urlParams.get("page")}`;
  },
  beforeUnmount() {
  },
  data() {
    return {
      store: JSON.parse(Cookies.get("user_onepage") || "{}").store,
      icons,
      dialogVisible: false,
      dialogLoading: false,
      saveDialogVisible: false,
      saveDialogLoading: false,
      rename: "",
      error: "",
      showExportDialog: false,
      showImportDialog: false,
      importData: "",
      loading: false,
      back_path: "",
      homeIcons,
      data_routes: {
        "/productpages": "Product Pages",
        "/homepages": "Home Pages",
        "/checkoutpages": "Checkout Pages",
        "/landingpages": "Sale Pages",
      },
      page: "Sample Page 1",
      // template: {},
      visible: false,
    };
  },
  computed: {
    enabled() {
      return this.editor.enabled;
    },
    canUndo() {
      return !this.editor.canUndo();
    },
    canRedo() {
      return !this.editor.canRedo();
    },
  },
  methods: {
    showRenameDialog() {
      this.rename = this.template.title;
      this.dialogVisible = true;
    },
    confirmRenameDialog() {
      this.dialogLoading = true;
      if (!this.rename) {
        this.error = "This field can't be blank"
        this.dialogLoading = false;
        return
      }
      const query = {
        title: this.rename,
      };
      request.put(`/api/v1/template/${this.template?.template_id}`, query).then(
        (res) => {
          if (res.data.success) {
            this.$toastr.Add({
              msg: "Success",
              type: "success",
              style: { backgroundColor: "green" },
            });
            this.template.title = this.rename
            this.template.slug = res.data.data.slug
            const urlParams = this.$route.query;
            this.$router.replace({ query: { ...urlParams, page: res.data.data.slug }} )
          } else {
            this.$toastr.e("Error");
          }
          this.dialogLoading = false;
          this.dialogVisible = false;
        },
        (err) => {
          this.$toastr.e(err?.response?.data?.message || 'Error');
          this.dialogLoading = false;
          this.dialogVisible = false;
        }
      );
    },
    handleCloseDialog() {
      this.dialogVisible = false;
    },
    handleCloseSaveDialog() {
      this.saveDialogVisible = false;
    },
    handlePreview() {
      window.open(
        `https://${this.store?.domain_primary || this.store?.domain || this.store?.sub_domain}/preview/${
          this.template.slug
        }`,
        "_blank"
      );
    },
    handleViewLivePage(){
      window.open(
        `https://${this.store?.domain_primary || this.store?.domain || this.store?.sub_domain}/${
          this.template.slug
        }`,
        "_blank"
      );
    },
    saveTemplateToLibrary() {
      this.$bus.emit(
        "showModalLibrary",
        {
          json_data: this.editor.export(),
          popups: this.editor.exportPopup(),
          type: "template",
          fonts: JSON.stringify(this.editor.fonts || []),
        },
        true,
        this.editor
      );
      this.visible = false;
    },
    showLibrary() {
      this.$bus.emit("showModalLibrary", {}, true, this.editor);
    },
    undo() {
      this.editor.undo();
    },
    redo() {
      this.editor.redo();
    },
    handlePublish() {
      let urlParams = new URLSearchParams(window.location.search);
      this.loading = true;
      const data = { is_publish: 1 };
      request.put(`/v2/builder/templates/${urlParams.get("id")}`, data).then(
        (res) => {
          this.loading = false;
          if (res.status == 200) {
            this.$toastr.Add({
              msg: "Template Published",
              type: "success",
              style: { backgroundColor: "green" },
            });
          }
        },
        (err) => {
          this.$toastr.e(err?.response?.data?.message || 'Error');
          this.loading = false;
        }
      );
    },
    handleRevertVersion() {
      window.location.reload()
    },
    handleCheckVersion() {
      let urlParams = new URLSearchParams(window.location.search);
      if (urlParams.get('id')) {
        this.loading = true;
        request.get(`/api/v1/template/${urlParams.get('id')}`, {}).then(res => {
          if (res.data.success) {
            if (res.data.data.version != this.template.version) {
              this.loading = false
              this.saveDialogVisible = true;
            } else {
              this.handleSave()
            }
          }
        })
      }
    },
    handleSave() {
      let urlParams = new URLSearchParams(window.location.search);
      const json_data = JSON.parse(this.editor.export() || "[]");
      const popups = JSON.parse(this.editor.exportPopup() || "[]");
      const iframe = document.getElementById("designEditor");
      const iframeClone = iframe.contentDocument.cloneNode(true);
      const carousels = iframeClone.querySelectorAll('[data-label="Carousel"]');
      const body = iframeClone.body;
      const data_head = iframeClone.head.innerHTML;
      iframeClone.head.innerHTML = "";
      body.classList.add("production");
      // head.innerHTML = '';
      for (let i = 0; i < carousels.length; i++) {
        const arrItems = [];
        const items = carousels[i].getElementsByClassName("item");
        for (let j = 0; j < items.length; j++) {
          const cloneItem = items[j].cloneNode(true);
          arrItems.push(cloneItem);
        }
        const module = carousels[i].getElementsByClassName("sg-module")[0];
        module.innerHTML = "";
        module.classList.remove("owl-center");
        module.classList.remove("owl-loaded");
        module.classList.remove("owl-text-select-on");
        module.classList.remove("owl-drag");
        for (let j = 0; j < arrItems.length; j++) {
          module.appendChild(arrItems[j]);
        }
      }
      const imageLists = iframeClone.querySelectorAll('[data-label="(P) Image List"]');
      for (let i = 0; i < imageLists.length; i++) {
        const module = imageLists[i].getElementsByClassName("sg-module")[0];
        if (module.getAttribute("data-style") == "slider") {
          const arrItems = [];
          const items = imageLists[i].getElementsByClassName("item");
          for (let j = 0; j < items.length; j++) {
            const cloneItem = items[j].cloneNode(true);
            arrItems.push(cloneItem);
          }
          const imagetList = imageLists[i].getElementsByClassName(
            "sg_product-images-list"
          )[0];
          imagetList.innerHTML = "";
          imagetList.classList.remove("owl-center");
          imagetList.classList.remove("owl-loaded");
          imagetList.classList.remove("owl-text-select-on");
          imagetList.classList.remove("owl-drag");
          for (let j = 0; j < arrItems.length; j++) {
            imagetList.appendChild(arrItems[j]);
          }
        }
      }

      const variants = iframeClone.querySelectorAll('[data-label="(P) Variants"]');
      for (let i = 0; i < variants.length; i++) {
        const select_seperately = variants[i].getElementsByClassName('sg_variants-wrapper separately');
        if (select_seperately.length > 0) {
          select_seperately[0].innerHTML = "";
        }
        const select_default = variants[i].getElementsByClassName('sg_variants-wrapper default');
        if (select_default.length > 0) {
          select_default[0].innerHTML = "";
        }
        const swatch = variants[i].getElementsByClassName('sg-module swatch');
        if (swatch.length > 0) {
          swatch[0].innerHTML = "";
        }
      } 

      const img_tags = iframeClone.querySelectorAll('img');
      for (let i = 0; i < img_tags.length; i++) {
        var src = img_tags[i].getAttribute("src");
        img_tags[i].setAttribute("data-src", src);
        img_tags[i].removeAttribute("src");
        img_tags[i].classList.add('lazyload');
      } 

      const popupOverlays = iframeClone.querySelectorAll('.sg-popupOverlay');
      for (let i = 0; i < popupOverlays.length; i++) {
        const link_button = popupOverlays[i].getAttribute("data-link-button");
        if (Number.parseInt(link_button) == 1) {
          popupOverlays[i].classList.remove('show-overlay');
        }
      }

      const list_popups = iframeClone.querySelectorAll('[data-label="Popup"]');
      for (let i = 0; i < list_popups.length; i++) {
        const link_button = list_popups[i].getAttribute("data-link-button");
        if (Number.parseInt(link_button) == 1) {
          list_popups[i].classList.remove('showPopup');
        }
      }

      const tags_a = iframeClone.querySelectorAll("[data-href]");
      for (let i = 0; i < tags_a.length; i++) {
        var href = tags_a[i].getAttribute("data-href");
        var target = tags_a[i].getAttribute("data-target");
        if (target) {
          tags_a[i].setAttribute("target", target);
          // tags_a[i].removeAttribute("data-target");
        }
        tags_a[i].setAttribute("href", href);
        // tags_a[i].removeAttribute("data-href")
      }

      const tags_button = iframeClone.querySelectorAll("[data-button-type]");
      for (let i = 0; i < tags_button.length; i++) {
        var type = tags_button[i].getAttribute("data-button-type");
        tags_button[i].setAttribute("type", type);
        tags_button[i].removeAttribute("data-button-type");
      }
      iframeClone.querySelectorAll(".mp-root-toolbar-container").forEach((e) => e.remove());
      iframeClone.querySelectorAll("#element-select-section").forEach((e) => e.remove());
      iframeClone.querySelectorAll('[data-key="liquid"] .liquid-builder')?.forEach(e => e.remove());
      iframeClone.querySelectorAll('[data-key="(p) paypal"] .preview-paypal-button')?.forEach(e => e.remove());
      iframeClone.querySelectorAll('[data-key="wrapper-container"] .section-box')?.forEach(e => e.remove());
      iframeClone.querySelectorAll('[data-key="anchor popup"].link-button')?.forEach(e => e.remove());
      iframeClone.querySelectorAll('[data-key="paragraph"] .text-editor p:empty')?.forEach(e => e.remove());

      const style_tags = iframeClone.querySelectorAll('style');
      var style_text = "";
      for (let i = 0; i < style_tags.length; i++) {
        style_text += (style_tags[i].innerHTML + '\n\n\n');
      }
      iframeClone.querySelectorAll('style')?.forEach(e => e.remove());

      iframeClone.querySelectorAll(".row-dropper, .drop-element").forEach((e) => {
        e.innerHTML = "";
        e.className = "";
      });
      iframeClone.querySelectorAll(".hero-link").forEach((e) => (e.style.display = ""));

      const html_data = `<html>${iframeClone?.documentElement?.innerHTML || ""}</html>`;
      const data = {
        fonts: JSON.parse(localStorage.getItem(`listFonts_${this.store?.domain}`) || "[]") || this.editor.fonts || [],
        popups,
        json_data,
        html_head: `${data_head}`,
        html_body: html_data.replaceAll('contenteditable="true"', ""),
        animations: this.editor.animations || [],
        styles: style_text
      };
      this.saveDialogLoading = true;
      this.loading = true;
      request.put(`/api/v1/template/${urlParams.get("id")}`, data).then(res => {
        this.loading = false;
        this.saveDialogLoading = false;
        this.saveDialogVisible = false;
        if (res.data.success) {
          const template = Object.assign({}, this.template)
          template.version = res.data.data.version
          this.setTemplate(template)
          this.$toastr.Add({
            msg: "Template saved",
            type: "success",
            style: { backgroundColor: "green" },
          });
        }
      }, err => {
        this.$toastr.e(err.response?.data?.message || "Error");
        this.loading = false;
        this.saveDialogLoading = false;
      });
            
    },
    updateState(enabled) {
      if (enabled) {
        this.editor.enable();
      } else {
        this.editor.disable();
      }
    },
    toggleExportDialog() {
      this.showExportDialog = !this.showExportDialog;
    },
    toggleImportDialog() {
      this.showImportDialog = !this.showImportDialog;
    },
    copyExport() {
      this.$refs.exportTextarea.select();
      document.execCommand("Copy");
      this.$message.success("Copied!");
    },
    doImport() {
      try {
        this.editor.import(this.importData);
        this.$message.success("Imported!");
        this.importData = "";
        this.toggleImportDialog();
      } catch ({ message }) {
        this.$message.error(message);
      }
    },
  },
};
</script>


<style>
.breadcrumb-item {
  display: flex;
  white-space: nowrap;
  align-items: center;
  color: #a6a6a6;
  transition: all 0.25s ease;
}

.breadcrumb-item i {
  font-weight: 400;
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 15px;
  line-height: 12px;
  color: #a6a6a6;
  margin-left: 5px;
  transition: all 0.25s ease;
}

.breadcrumb-item:hover,
.breadcrumb-item:hover i {
  color: #fff !important;
}

.button-sg {
  padding: 10px 25px;
  color: #fff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
  background: #333333;
  transition: all 0.2s ease;
  border: none;
}
.button-sg.publish {
  background: #3c67ff;
}
.button-sg.publish:hover {
  background: #5579fa;
  color: #fff;
}
.button-sg:not(.publish):hover {
  background: #555555;
  transition: all 0.2 ease;
  color: #fff;
  border: none;
}

.header {
  height: 60px;
  background: #202124;
  display: flex;
  align-items: center;
  padding: 0px;
}
.logo-container {
  padding: 0 5px;
  width: 290px;
  display: flex;
  align-items: center;
  color: #fff;
  height: 100%;
  border-right: 1px solid #444;
}
.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  height: 100%;
  padding: 0 10px;
}
.button {
  padding: 10px 25px;
  color: #fff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
  background: #333333;
  transition: all 0.2s ease;
}
.button svg {
  stroke: #fff;
}
.button .icon {
  padding: 10px 15px;
}

.button:hover {
  background: #555555;
  transition: all 0.2 ease;
}

.screen-inner {
  display: flex;
  height: 100%;
}

.screen-inner div {
  display: flex;
  align-items: center;
  padding: 0px 12px;
  height: 100%;
  cursor: pointer;
}

.screen-inner div.selected {
  border-bottom: 2px solid #fff;
  background-color: #333333;
  cursor: default;
}

.screen-inner div:hover {
  background: #333333;
}

.button-list {
  display: flex;
}

.button-list div {
  margin: 0px 3px;
}

.button-list div:last-child {
  margin-right: 0px;
}

.btn-secondary {
  background: #333;
  color: #e2e2e2;
  border: transparent;
}
.sg_history-item {
  color: transparent;
  position: relative;
  width: 40px;
  height: 40px;
}
.el-button.is-disabled {
  background: #333;
  opacity: 0.6;
}
.sg-button-more {
  background: #333;
  color: #e2e2e2;
  min-height: 40px;
  padding: 7.5px;
}

.sg-button-more:hover {
  background: #555555;
}
</style>
