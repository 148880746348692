var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-form',{attrs:{"label-position":"top","size":"mini","model":_vm.elementProps}},[_c('div',{staticClass:"d-flex flex-column py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Effect")]),_c('Select',{staticClass:"mt-2",attrs:{"primaryColor":_vm.primaryColor,"value":_vm.elementProps.effect,"changeValue":($event) => _vm.updateProps('effect', $event),"options":[
        {
          value: 'popup-fade',
          label: 'Fade',
        },
        {
          value: 'popup-zoom',
          label: 'Zoom',
        },
        {
          value: 'popup-zoom-out',
          label: 'Zoom Out',
        },
        {
          value: 'popup-horizontal-move',
          label: 'Horizontal Move',
        },
        {
          value: 'popup-vertical-move',
          label: 'Vertical move',
        },
        {
          value: 'popup-unfold',
          label: '3D Unfold',
        },
        {
          value: 'none',
          label: 'None',
        },
      ]}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }