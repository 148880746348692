<template>
  <el-form label-position="top" size="mini" :model="elementProps">
    <div class="d-flex flex-column py-2" >
      <span class="setting-title">Filter Text</span>
      <el-input
        :value="elementProps.filter_text"
        class="input-with-select mt-2"
        @input="updateProps('filter_text', $event)"
        size="medium"
      />
    </div>
    <div class="d-flex flex-column py-2" >
      <span class="setting-title">Sorting Text</span>
      <el-input
        :value="elementProps.sorting_text"
        class="input-with-select mt-2"
        @input="updateProps('sorting_text', $event)"
        size="medium"
      />
    </div>

    <div class="d-flex align-items-center justify-content-between py-2">
      <span class="setting-title">Display Quantity</span>
      <el-switch
        :value="elementProps.show_quantity"
        :active-color="primaryColor"
        inactive-color="#949494"
        @change="updateProps('show_quantity', $event)"
      />
    </div>
    <div class="d-flex flex-column py-2" v-if="elementProps.show_quantity">
      <span class="setting-title">Quantity Text</span>
      <el-input
        :value="elementProps.quantity_text"
        class="input-with-select mt-2"
        @input="updateProps('quantity_text', $event)"
        size="medium"
      />
    </div>
  </el-form>
</template>

<script>
import mixin from "../../../elements/styleSettings/mixin";
import Select from "../../../elements/common/Select.vue";
import Slider from "../../../elements/common/Slider.vue";

export default {
  mixins: [mixin],
  components: {Select, Slider },
  props: {
    primaryColor: String
  },
  methods: {
    updateProps(name, value) {
      if(value ==  this.elementProps[name]) return;
      this.elementPropsSetter({ [name]: value });
    }
  },
};
</script>

<style scoped>
</style>
