<template>
  <div label-position="top" size="mini" :model="elementProps">
    <div class="d-flex flex-column py-2">
      <span class="setting-title">Text Color</span>
      <ColorPicker 
        primaryColor="#0060e5"
        class="mt-2"
        :value="elementProps.body_text_color"
        v-bind:changeColor="($event) => updateCommonProps('body_text_color', $event)"
      />
    </div>
    <div class="d-flex flex-column py-2">
      <span class="setting-title">Body Background</span>
      <ColorPicker 
        primaryColor="#0060e5"
        class="mt-2"
        :value="elementProps.body_background"
        v-bind:changeColor="($event) => updateCommonProps('body_background', $event)"
      />
    </div>
    <div class="d-flex flex-column">
      <span class="setting-title">Font Family</span>
      <Select
        primaryColor="#0060e5"
        class="mt-2"
        :value="elementProps.body_font_family"
        v-bind:changeValue="($event) => updateCommonProps('body_font_family', $event)"
        :hasAction="true"
        v-bind:onAction="handleShowModal"
        actionLabel="Open Font Manager"
        :options="getListFont()"
        :key="fontKey"
      />
    </div>
    <div class="d-flex flex-column pt-2">
      <span class="setting-title">Font Size</span>
      <Slider
        primaryColor="#0060e5"
        class="mt-2"
        :step="1"
        :value="elementProps.body_font_size"
        v-bind:changeValue="($event) => updateCommonProps('body_font_size', parseInt($event))"
        unit="px"
        :max="999"
        :min="1"
        :onlyInteger="true"
      />
    </div>
  </div>
</template>

<script>
import Node from '@/core/Node';
import { makeId, icons } from "@/constants";
import mixin from '../styleSettings/mixin';
import Slider from '../common/Slider.vue';
import ColorPicker from '../common/ColorPicker.vue';
import Select from '../common/Select.vue';
export default {
  mixins: [mixin],
  components: {
    ColorPicker, Slider, Select
  },
  props: {
    screen: String,
    node: Node,
    activeFonts: Array,
    primaryColor: String
  },
  data() {
    return {
      fontKey: makeId(20),
      icons,
      box_index: 0
    };
  },
  watch: {
    activeFonts: function (newValue, oldValue) {
      this.fontKey = makeId(20);
    },
  },
  methods: {
    updateCommonProps(name, value) {
      this.elementPropsSetter({ [name]: value });
    },
    updateProps(name, value) {
      const screen = this.$props.screen
      const data_screen = Object.assign({}, this.elementProps[screen])
      data_screen[name] = value
      this.elementPropsSetter({ [screen]: data_screen });
    },
    handleShowModal() {
      this.$bus.emit("showFontManager", this.node);
    },
    getListFont() {
      const listFonts = [
        {
          value: "inherit",
          label: "Default",
        },
      ];
      this.node?.editor?.fonts?.map((e) => {
        listFonts.push({ value: e, label: e });
      });
      return listFonts;
    },
  }
};
</script>
