<template>
  <div
    data-label="Row"
    data-key="row"
    class="sg-container sg_row sg-row-layout "
    :class="extra_class"
    :data-name="name"
    @mouseover="isHovering=true" 
    @mouseout="isHovering=false"
    :id="`element-${node.uuid}`"
    :layout_desktop="desktop.layout"
    :layout_laptop="laptop.layout"
    :layout_tablet="tablet.layout"
    :layout_mobile="mobile.layout"
    :style="{
        'display': `${equal_height ? 'flex' : 'block'}`,
        'flex-wrap': `${equal_height ? 'wrap' : 'unset'}`,
    }"
    :data-animation="JSON.stringify(animations)"
  >
    <slot />
    <ToolBar ref="container" :toolbar_id="`row`" class="container-toolbar" 
      :class="{ 'container-toolbar-show': isHovering, 'is-dragging': dragging == node.uuid }"
    ></ToolBar>
    <div v-if="dragging == node.uuid" class="dragging position-ab"></div>

    <v-style>
      #element-{{ node.uuid }} >.sg-column {
        display: {{ equal_height ? 'flex' : 'block' }};
        flex-direction: {{ equal_height ? 'column' : 'unset' }};
        justify-content: {{ equal_height ? vertical_align : 'unset' }};
      }

      {{ custom_css }}

      @media (max-width: 767px) {
        #element-{{ node.uuid }}  {
          max-width: {{ checkFullWidth(full_width, getValue('width','mobile'), getValue('width_type','mobile')) ? '100%' : getValue('width','mobile') + getValue('width_type','mobile') }} !important;
          {{ getDisplayValue('mobile') }}
          {{ getMarginValue('mobile') }}
          {{ getPaddingValue('mobile') }}
          {{ getBackgroundValue('mobile') }}
          {{ getBorderValue('mobile') }}
          {{ getBorderRadiusValue('mobile') }}
          {{ getOpacityValue('mobile') }}
          {{ getShadowValue('mobile') }}
          {{ getOtherStyle('mobile') }}
        }
      }
      @media (max-width: 991px) and (min-width: 768px) {
        #element-{{ node.uuid }}  {
          max-width: {{ checkFullWidth(full_width, getValue('width','tablet'), getValue('width_type','tablet')) ? '100%' : getValue('width','tablet') + getValue('width_type','tablet') }} !important;
          {{ getDisplayValue('tablet') }}
          {{ getMarginValue('tablet') }}
          {{ getPaddingValue('tablet') }}
          {{ getBackgroundValue('tablet') }}
          {{ getBorderValue('tablet') }}
          {{ getBorderRadiusValue('tablet') }}
          {{ getOpacityValue('tablet') }}
          {{ getShadowValue('tablet') }}
          {{ getOtherStyle('tablet') }}
        }
      }
      @media (max-width: 1199px) and (min-width: 992px) {
        #element-{{ node.uuid }}  {
          max-width: {{ checkFullWidth(full_width, getValue('width','laptop'), getValue('width_type','laptop')) ? '100%' : getValue('width','laptop') + getValue('width_type','laptop') }} !important;
          {{ getDisplayValue('laptop') }}
          {{ getMarginValue('laptop') }}
          {{ getPaddingValue('laptop') }}
          {{ getBackgroundValue('laptop') }}
          {{ getBorderValue('laptop') }}
          {{ getBorderRadiusValue('laptop') }}
          {{ getOpacityValue('laptop') }}
          {{ getShadowValue('laptop') }}
          {{ getOtherStyle('laptop') }}
        }
      }
      @media (min-width: 1200px) {
        #element-{{ node.uuid }}  {
          max-width: {{ checkFullWidth(full_width, desktop.width, desktop.width_type) ? '100%' : desktop.width + desktop.width_type }} !important;
          {{ getDisplayValue('desktop') }}
          {{ getMarginValue('desktop') }}
          {{ getPaddingValue('desktop') }}
          {{ getBackgroundValue('desktop') }}
          {{ getBorderValue('desktop') }}
          {{ getBorderRadiusValue('desktop') }}
          {{ getOpacityValue('desktop') }}
          {{ getShadowValue('desktop') }}
          {{ getOtherStyle('desktop') }}
        }
      }
      #element-{{ node.uuid }} >.sg-column {
        padding-left: {{  `${Number.parseInt(column_gap)}px` }};
        padding-right: {{  `${Number.parseInt(column_gap)}px` }};
      }
    </v-style>
  </div>
  
</template>

<script>
import RowLayout from './styleSettings/RowLayout.vue';
import elementStyleMixin from './elementStyleMixin';
import ToolBar from './utils/ToolBar.vue';
import Visibility from "./designSettings/Visibility.vue";
import Spacing from "./designSettings/Spacing.vue";
import BackgroundDesign from "./designSettings/Background.vue";
import Border from "./designSettings/Border.vue";
import Corner from "./designSettings/Corner.vue";
import Shadow from "./designSettings/Shadow.vue";
import Opacity from "./designSettings/Opacity.vue";
import { designStyles, otherProps, otherStyles, actionTypes } from '@/constants';
import FullWidth from './styleSettings/FullWidth.vue';
import HeightVerticalAlign from './styleSettings/HeightVerticalAlign.vue';
import Name from './styleSettings/Name.vue';
import ContainerDimension from './styleSettings/ContainerDimension.vue';
import ColumnsGap from './styleSettings/ColumnsGap.vue';
import ExtraClass from './otherSettings/ExtraClass.vue';
import CustomCSS from './styleSettings/CustomCSS.vue';
import Animations from './designSettings/Animations.vue';
import Vue from "vue";

export default {
  components: {ToolBar},
  mixins: [elementStyleMixin],
  created() {
    this.addClearDiv();
  },
  watch: {
    dragging: function (newValue, oldValue) {
      var $iframe = $("#designEditor").contents();
      var $element = $iframe.find(`#element-${this.node.uuid}`);
      var $content = $element.find(`.sg-column`);
      if (newValue && newValue == this.node.uuid) {
        setTimeout(() => {
        $content.hide();
        $element.css("padding","0px 0px 0px 0px");
        },100);
      }else if (oldValue == this.node.uuid){
        $content.show();
        $element.css("padding","");
      }
    },
    clonedDataChange: {
      handler: function (val, oldVal) {
        this.addClearDiv()
      },
      deep: true,
    },
  },
  computed: {
    dragging: function () {
      return this.editor?.draggedNode?.uuid
    },
    clonedDataChange: function () {
      return JSON.parse(
        JSON.stringify({
          desktop: {
            layout: this.desktop.layout
          },
          laptop: {
            layout: this.laptop.layout
          },
          tablet: {
            layout: this.tablet.layout
          },
          mobile: {
            layout: this.mobile.layout
          },
        })
      );
    },
  },
  inject: [
    'editor',
    'node'
  ],
  props: {
    ...otherProps,
    name: String,
    child: String,
    desktop: Object,
    laptop: Object,
    tablet: Object,
    mobile: Object,
    full_width: Boolean,
    vertical_align: String,
    equal_height: Boolean,
    hiddenConfig: Array,
    column_gap: String,
    extra_class: String,
    disabled: Boolean,
    custom_css: String,
  },
  data() {
    return {
      isHovering: false,
      designStyles
    }
  },
  methods: {
    checkFullWidth(full_width, width, width_type) {
      if (full_width) {
        return true
      }
      if (width?.trim() == '100' && width_type == '%') {
        return true
      }
      return false
    },
    removeAllClear(){
      const iframe = document.getElementById("designEditor");
      const rowContainer = iframe.contentWindow.document.getElementById(
        `element-${this.node.uuid}`
      );
      const childrens = Array.from(rowContainer.querySelectorAll(':scope > .sg_clearfix'));
      var arrRemove = []
      for(var i = 0; i<childrens.length; i++){
        arrRemove.push(childrens[i])
      }
      arrRemove.forEach(e=>e.remove())
    },
    addClearDiv(){
      setTimeout(()=>{
      this.removeAllClear();
      },200)
      setTimeout(()=>{
        const {desktop, laptop, tablet, mobile} = this;
        const iframe = document.getElementById("designEditor");
        const rowContainer = iframe.contentWindow.document.getElementById(
          `element-${this.node.uuid}`
        );
        const childrens = Array.from(rowContainer.querySelectorAll(':scope > .sg-column'));

        for(var i = 0; i<childrens.length; i++){
            if (i == 0) continue;
            if (i % parseInt(laptop.layout?.split("_")?.length || 1) == 0){
              const el = document.createElement("DIV");
              const div_clear = new Vue({
                  render(h) {
                    return h(
                      "div",
                      {class: "sg_clearfix visible-md" });
                  },
              });
              childrens[i].parentNode.insertBefore(el, childrens[i]);
              div_clear.$mount(el);
            }
            if (i % parseInt(tablet.layout?.split("_")?.length || 1) == 0){
              const el = document.createElement("DIV");
              const div_clear = new Vue({
                  render(h) {
                    return h(
                      "div",
                      {class: "sg_clearfix visible-sm" });
                  },
              });
              childrens[i].parentNode.insertBefore(el, childrens[i]);
              div_clear.$mount(el);
            }
            if (i % parseInt(mobile.layout?.split("_")?.length || 1) == 0){
              const el = document.createElement("DIV");
              const div_clear = new Vue({
                  render(h) {
                    return h(
                      "div",
                      {class: "sg_clearfix visible-xs" });
                  },
              });
              childrens[i].parentNode.insertBefore(el, childrens[i]);
              div_clear.$mount(el);
            }
        }
      },800);
    },
  },
  craft: {
    disabled:false,
    defaultProps: {
      ...otherStyles,
      name: "",
      full_width: false,
      extra_class: "",
      custom_css: "",
      hiddenConfig: ['margin_left', 'margin_right'],
      vertical_align: 'top',
      equal_height: false,
      column_gap: '15',
      desktop: {
        ...designStyles,
        layout: '12',
        padding_top: 30,
        padding_bottom: 10,
        width: '1200',
        width_type: 'px'
      },
      laptop: {
        ...designStyles,
        layout: '12',
        width: '1200',
        width_type: 'px'
      },
      tablet: {
        ...designStyles,
        layout: '12',
        width: '1200',
        width_type: 'px'
      },
      mobile: {
        ...designStyles,
        layout: '12',
        width: '1200',
        width_type: 'px'
      },
      elementStyle: {},
    },
    settings: {
      settings: {
        "Layout": RowLayout,
        Name,
        "Full Width": FullWidth,
        "Dimension (Width)": {"Dimension (Width)": ContainerDimension, multi_screen: true },
        "Columns Gap": ColumnsGap,
        "Height & Vertical Align": HeightVerticalAlign,
        "Extra Class": ExtraClass, "Custom CSS": CustomCSS,
      },
      design: {
        Visibility,
        Spacing: { Spacing, multi_screen: true },
        Background: { "Background": BackgroundDesign, multi_screen: true },
        Border: { Border, multi_screen: true },
        Corner: { Corner, multi_screen: true },
        Shadow: { Shadow, multi_screen: true },
        Opacity: { Opacity, multi_screen: true },
      },
      others: {
        Animations: { Animations, actions: actionTypes.filter(e => ['scroll_into_view', 'after_page_finish_loading'].includes(e.value)) }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  position: relative;
  display: flex;
  width: 100%;
  box-sizing: border-box;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.container:hover {
  outline: 1px solid #0060e5;
}

.container .container-toolbar {
  display: none;
}

.container-toolbar-show {
  display: block !important;
}

.dragging{
  position:absolute;
  top:0;
  bottom: 0;
  left: 0;
  right:0;
  background: greenyellow;
  z-index: 10;
}


</style>
