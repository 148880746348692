<template>
  <div>
    <div class="button-contact-vr">
      <div class="gom-all-in-one">
        <Node
          v-for="node in editor?.popups.filter(
            (x) => x.componentName == 'FloatingButton' && x.props.position == 'bottom-left'
          )"
          :key="node.uuid"
          :node="node"
          :editor="editor"
          :screen="screen"
        />
      </div>
    </div>
    <div class="button-contact-vr right-bottom">
      <div class="gom-all-in-one">
        <Node
          v-for="node in editor?.popups.filter(
            (x) => x.componentName == 'FloatingButton' && x.props.position == 'bottom-right'
          )"
          :key="node.uuid"
          :node="node"
          :editor="editor"
          :screen="screen"
        />
      </div>
    </div>

    <div class="button-contact-vr left-top">
      <div class="gom-all-in-one">
        <Node
          v-for="node in editor?.popups.filter(
            (x) => x.componentName == 'FloatingButton' && x.props.position == 'top-left'
          )"
          :key="node.uuid"
          :node="node"
          :editor="editor"
          :screen="screen"
        />
      </div>
    </div>

    <div class="button-contact-vr right-top">
      <div class="gom-all-in-one">
        <Node
          v-for="node in editor?.popups.filter(
            (x) => x.componentName == 'FloatingButton' && x.props.position == 'top-right'
          )"
          :key="node.uuid"
          :node="node"
          :editor="editor"
          :screen="screen"
        />
      </div>
    </div>
  </div>
</template>
  <script>
import Node from "./Node.vue";

export default {
  components: {
    Node,
  },
  name: "FrameFloating",
  props: {
    screen: String,
    editor: Object,
  },
  watch: {},
  provide() {
    return {
      editor: this.editor,
    };
  },
  data(props) {
    return {};
  },
  methods: {},
};
</script>
  
  