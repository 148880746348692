<template>
  <el-form label-position="top" size="" :model="elementProps">
    <el-form-item label="Template">
      <el-input
        type="textarea"
        :rows="4"
        :value="elementProps.template"
        @input="($event) => updateProps('template', $event)"
        resize="none"
      />
      <div class="sgDesc mt-2">
       Use %number% to represent remaining number
      </div>
    </el-form-item>
    <el-form-item label="Out Of Stock Message">
      <el-input
        type="textarea"
        :rows="4"
        :value="elementProps.out_of_stock"
        @input="($event) => updateProps('out_of_stock', $event)"
        resize="none"
      />
    </el-form-item>
    <el-form-item label="Continue Selling When Out Of Stock Message">
      <el-input
        type="textarea"
        :rows="4"
        :value="elementProps.continue_out_of_stock"
        @input="($event) => updateProps('continue_out_of_stock', $event)"
        resize="none"
      />
      <div class="sgDesc mt-2">
       Turn out-of-stock products into pre-orders
      </div>
    </el-form-item>
    <el-form-item label="Unlimited Quantity Message">
      <el-input
        type="textarea"
        :rows="4"
        :value="elementProps.no_track_quantity"
        @input="($event) => updateProps('no_track_quantity', $event)"
        resize="none"
      />
    </el-form-item>
  </el-form>
</template>

<script>
import mixin from "../../../elements/styleSettings/mixin";

export default {
  mixins: [mixin],
  components: {},
  methods: {
    updateProps(name, value) {
      if(value ==  this.elementProps[name]) return;
      this.elementPropsSetter({ [name]: value });
    },
  },
};
</script>

<style scoped>
</style>
