var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"label-position":"top","size":"medium","model":_vm.elementProps}},[_c('div',{staticClass:"d-flex flex-column py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Close Button")]),_c('ToggleButton',{staticClass:"mt-2",attrs:{"value":_vm.elementProps.close_btn,"primaryColor":_vm.primaryColor,"changeValue":($event) => _vm.updateProps('close_btn', $event),"options":[
        {
          label: 'Inside',
          value: 'inside',
        },
        {
          label: 'Outside',
          value: 'outside',
        },
      ]}})],1),_c('div',{staticClass:"d-flex flex-column py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Background")]),_c('ColorPicker',{staticClass:"mt-2",attrs:{"value":_vm.elementProps.close_btn_background,"changeColor":($event) => _vm.updateProps('close_btn_background', $event),"primaryColor":_vm.primaryColor}})],1),_c('div',{staticClass:"d-flex flex-column py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Color")]),_c('ColorPicker',{staticClass:"mt-2",attrs:{"value":_vm.elementProps.close_btn_color,"changeColor":($event) => _vm.updateProps('close_btn_color', $event),"primaryColor":_vm.primaryColor}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }