<template>
  <div
    data-label="Submit Button"
    data-key="submit button"
    class="sg-wrap block"
    :id="`element-${node.uuid}`"
    @mouseover="isHovering=true" 
    @mouseout="isHovering=false"
    :class="{ 'dragging': dragging == node.uuid }"
  >
    <div
      class="bl"
      :class="extra_class"
    >
      <button
        :class="`button btn sg-button-submit`"
        type="submit"
      >
        <span>{{ text }}</span>
      </button>
    </div>
    <ToolBar ref="submit_button"  :toolbar_id="`submit_button`" class="container-toolbar" 
    :class="{ 'container-toolbar-show': isHovering, 'is-dragging': dragging == node.uuid }"
    >
  </ToolBar>
    <v-style>
      {{ custom_css }}
      
      @media (max-width: 767px) { 
        #element-{{ node.uuid }} {
          {{ getDisplayValue('mobile') }}
          {{ getMarginValue('mobile') }}
        }
        #element-{{ node.uuid }} .sg-button-submit {
          {{ getValue('font_weight','mobile') ? `font-weight: ${getValue('font_weight','mobile')};` : '' }}
          {{ getValue('font_size', 'mobile') > 0 ? `font-size: ${getValue('font_size','mobile')}px;` : '' }}
          {{ getValue('text_transform','mobile') ? `text-transform: ${getValue('text_transform','mobile')};` : '' }}
          {{ getValue('background_color','mobile') ? `background-color: ${getValue('background_color','mobile')};` : '' }}
          {{ getValue('color','mobile') ? `color: ${getValue('color','mobile')};` : '' }}
          font-family: {{ getValue('font_family','mobile') }};
          {{ getValue('stretch','mobile') ? `width: 100%;` : ''}}
          {{ !getValue('stretch','mobile') && getValue('width','mobile') ? `width: ${getValue('width','mobile')}${getValue('width_type','mobile') || ''};` : '' }}
          {{ getValue('height','mobile') ? `height: ${getHeight(getValue('height','mobile'))};` : '' }}
          {{ getValue('stretch','mobile') ? `justify-content: ${getValue('text_align','mobile')};` : '' }}
          {{ getPaddingValue('mobile') }}
          {{ getBackgroundValue('mobile') }}
          {{ getBorderValue('mobile') }}
          {{ getBorderRadiusValue('mobile') }}
          {{ getOpacityValue('mobile') }}
          {{ getShadowValue('mobile') }}
          {{ getOtherStyle('mobile') }}
        }
        #element-{{ node.uuid }} .sg-button-submit:hover {
          {{ getValue('hover','mobile') ? `background-color: ${getValue('background_color_hover','mobile')}; color: ${getValue('color_hover','mobile')};` : '' }}
        }
        #element-{{ node.uuid }} .bl {
          text-align: {{ getValue('alignment','mobile') }};
        }
      } 
      @media (max-width: 991px) and (min-width: 768px) {
        #element-{{ node.uuid }} {
          {{ getDisplayValue('tablet') }}
          {{ getMarginValue('tablet') }}
        }
        #element-{{ node.uuid }} .sg-button-submit {
          {{ getValue('font_weight','tablet') ? `font-weight: ${getValue('font_weight','tablet')};` : '' }}
          {{ getValue('font_size', 'tablet') > 0 ? `font-size: ${getValue('font_size','tablet')}px;` : '' }}
          {{ getValue('text_transform','tablet') ? `text-transform: ${getValue('text_transform','tablet')};` : '' }}
          {{ getValue('background_color','tablet') ? `background-color: ${getValue('background_color','tablet')};` : '' }}
          {{ getValue('color','tablet') ? `color: ${getValue('color','tablet')};` : '' }}
          font-family: {{ getValue('font_family','tablet') }};
          {{ getValue('stretch','tablet') ? `width: 100%;` : ''}}
          {{ !getValue('stretch','tablet') && getValue('width','tablet') ? `width: ${getValue('width','tablet')}${getValue('width_type','tablet') || ''};` : '' }}
          {{ getValue('height','tablet') ? `height: ${getHeight(getValue('height','tablet'))};` : '' }}
          {{ getValue('stretch','tablet') ? `justify-content: ${getValue('text_align','tablet')};` : '' }}
          {{ getPaddingValue('tablet') }}
          {{ getBackgroundValue('tablet') }}
          {{ getBorderValue('tablet') }}
          {{ getBorderRadiusValue('tablet') }}
          {{ getOpacityValue('tablet') }}
          {{ getShadowValue('tablet') }}
          {{ getOtherStyle('tablet') }}
        }
        #element-{{ node.uuid }} .sg-button-submit:hover {
          {{ getValue('hover','tablet') ? `background-color: ${getValue('background_color_hover','tablet')}; color: ${getValue('color_hover','tablet')};` : '' }}
        }
        #element-{{ node.uuid }} .bl {
          text-align: {{ getValue('alignment','tablet') }};
        }
      }
      @media (max-width: 1199px) and (min-width: 992px){ 
        #element-{{ node.uuid }} {
          {{ getDisplayValue('laptop') }}
          {{ getMarginValue('laptop') }}
        }
        #element-{{ node.uuid }} .sg-button-submit {
          {{ getValue('font_weight','laptop') ? `font-weight: ${getValue('font_weight','laptop')};` : '' }}
          {{ getValue('font_size', 'laptop') > 0 ? `font-size: ${getValue('font_size','laptop')}px;` : '' }}
          {{ getValue('text_transform','laptop') ? `text-transform: ${getValue('text_transform','laptop')};` : '' }}
          {{ getValue('background_color','laptop') ? `background-color: ${getValue('background_color','laptop')};` : '' }}
          {{ getValue('color','laptop') ? `color: ${getValue('color','laptop')};` : '' }}
          font-family: {{ getValue('font_family','laptop') }};
          {{ getValue('stretch','laptop') ? `width: 100%;` : ''}}
          {{ !getValue('stretch','laptop') && getValue('width','laptop') ? `width: ${getValue('width','laptop')}${getValue('width_type','laptop') || ''};` : '' }}
          {{ getValue('height','laptop') ? `height: ${getHeight(getValue('height','laptop'))};` : '' }}
          {{ getValue('stretch','laptop') ? `justify-content: ${getValue('text_align','laptop')};` : '' }}
          {{ getPaddingValue('laptop') }}
          {{ getBackgroundValue('laptop') }}
          {{ getBorderValue('laptop') }}
          {{ getBorderRadiusValue('laptop') }}
          {{ getOpacityValue('laptop') }}
          {{ getShadowValue('laptop') }}
          {{ getOtherStyle('laptop') }}
        }
        #element-{{ node.uuid }} .sg-button-submit:hover {
          {{ getValue('hover','laptop') ? `background-color: ${getValue('background_color_hover','laptop')}; color: ${getValue('color_hover','laptop')};` : '' }}
        }
        #element-{{ node.uuid }} .bl {
          text-align: {{ getValue('alignment','laptop') }};
        }
      } 
      @media (min-width: 1200px){
        #element-{{ node.uuid }} {
          {{ getDisplayValue('desktop') }}
          {{ getMarginValue('desktop') }}
        }
        #element-{{ node.uuid }} .sg-button-submit {
          {{ getValue('font_weight','desktop') ? `font-weight: ${getValue('font_weight','desktop')};` : '' }}
          {{ getValue('font_size', 'desktop') > 0 ? `font-size: ${getValue('font_size','desktop')}px;` : '' }}
          {{ getValue('text_transform','desktop') ? `text-transform: ${getValue('text_transform','desktop')};` : '' }}
          {{ getValue('background_color','desktop') ? `background-color: ${getValue('background_color','desktop')};` : '' }}
          {{ getValue('color','desktop') ? `color: ${getValue('color','desktop')};` : '' }}
          font-family: {{ getValue('font_family','desktop') }};
          {{ getValue('stretch','desktop') ? `width: 100%;` : ''}}
          {{ !getValue('stretch','desktop') && getValue('width','desktop') ? `width: ${getValue('width','desktop')}${getValue('width_type','desktop') || ''};` : '' }}
          {{ getValue('height','desktop') ? `height: ${getHeight(getValue('height','desktop'))};` : '' }}
          {{ getValue('stretch','desktop') ? `justify-content: ${getValue('text_align','desktop')};` : '' }}
          {{ getPaddingValue('desktop') }}
          {{ getBackgroundValue('desktop') }}
          {{ getBorderValue('desktop') }}
          {{ getBorderRadiusValue('desktop') }}
          {{ getOpacityValue('desktop') }}
          {{ getShadowValue('desktop') }}
          {{ getOtherStyle('desktop') }}
        }
        #element-{{ node.uuid }} .sg-button-submit:hover {
          {{ desktop.hover ? `background-color: ${desktop.background_color_hover}; color: ${desktop.color_hover};` : '' }}
        }
        #element-{{ node.uuid }} .bl {
          text-align: {{ desktop.alignment }};
        }
      }
    </v-style>
  </div>
</template>

<script>
import elementStyleMixin from "./elementStyleMixin";
import AlignmentBase from "./styleSettings/AlignmentBase.vue";
import Link from "./otherSettings/Link.vue";
import ExtraClass from "./otherSettings/ExtraClass.vue";
import Visibility from "./designSettings/Visibility.vue";
import Spacing from "./designSettings/Spacing.vue";
import Background from "./designSettings/Background.vue";
import Border from "./designSettings/Border.vue";
import Corner from "./designSettings/Corner.vue";
import Shadow from "./designSettings/Shadow.vue";
import Opacity from "./designSettings/Opacity.vue";
import Text from "./otherSettings/Text.vue";
import Typography from "./styleSettings/Typography.vue";
import ButtonColor from "./styleSettings/ButtonColor.vue";
import Dimensions from "./styleSettings/Dimensions.vue";
import ScrollSpeed from "./otherSettings/ScrollSpeed.vue";
import ToolBar from './utils/ToolBar.vue';
import { designStyles, otherProps, otherStyles, actionTypes } from "@/constants";
import CustomCSS from "./styleSettings/CustomCSS.vue";
import Animations from "./designSettings/Animations.vue";

export default {
  mixins: [elementStyleMixin],
  components: {  ToolBar },
  props: {
    ...otherProps,
    component: String,
    text: String,
    extra_class: String,
    href: String,
    target: String,
    
    desktop: Object,
    laptop: Object,
    mobile: Object,
    tablet: Object,
    disabled:Boolean
  },
  data() {
    return {
      isHovering: false,
    }
  },
  methods: {
    getHeight(height) {
      if (height.includes('%') || height.includes('px') || isNaN(parseInt(height))) {
        return height;
      }
      return `${height}px`
    }
  },
  inject: ["node",'editor'],
  computed: {
    dragging: function () {
      return this.editor?.draggedNode?.uuid
    },
  },
  craft: {
    disabled:false,
    defaultProps: {
      ...otherStyles,
      text: "Submit",
      extra_class: "",
      href: "",
      target: "_blank",
      typographyActives: [
        "font-size",
        "font-weight",
        "font-family",
      ],
      dimensionActives: [
        'stretch',
        'width',
        'height'
      ],
      desktop: {
        ...designStyles,
        font_weight: '600',
        font_size: 14,
        font_family: 'inherit',
        background_color: 'rgb(119, 150, 168)',
        color: 'rgb(255, 255, 255)',
        stretch: false,
        width: 'auto',
        height: 'auto',
        alignment: 'center',
        padding_bottom: 10,
        padding_left: 20,
        padding_right: 20,
        padding_top: 10,
        text_align:'center'
      },
      laptop: {
        ...designStyles,

      },
      mobile: {
        ...designStyles,
      },
      tablet: {
        ...designStyles,
      },
      elementStyle: {
        
      },
    },
    settings: {
      settings: {
        Text,
        Typography: { Typography, multi_screen: true},
        "Button Color": { "Button Color": ButtonColor, multi_screen: true },
        Dimension: { Dimension: Dimensions, multi_screen: true},
        "Alignment": { Alignment: AlignmentBase, multi_screen: true},
        "Extra Class": ExtraClass, "Custom CSS": CustomCSS,
      },
      design: {
        Visibility,
        Spacing: { Spacing, multi_screen: true },
        Background: { Background, multi_screen: true },
        Border: { Border, multi_screen: true },
        Corner: { Corner, multi_screen: true },
        Shadow: { Shadow, multi_screen: true },
        Opacity: { Opacity, multi_screen: true },
      },
      others: {
        Animations: { Animations, actions: actionTypes }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
