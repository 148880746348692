<template>
  <div
    data-label="(P) Quantity"
    data-key="(p) quantity"
    data-parent="product"
    class="sg-wrap module"
    :id="`element-${node.uuid}`"
    @mouseover="isHovering = true"
    @mouseout="isHovering = false"
    :class="{ 'dragging': dragging == node.uuid }"
    :data-product-uuid="product.product_node_uuid"
    :data-default-quantity="default_quantity"
  >
    <div
      class="sg-module"
      :class="extra_class"
    >
      <div :class="`sg-quantity-${display_style}`">
        <label v-if="show_label">{{ label }}</label>
        <div class="sg-product-quantity" :data-node-uuid="node.uuid">
          <a
            class="sg-product-quantity-minus d-flex align-items-center justify-content-center"
            v-if="display_style != 'simple'"
            
          >
            <svg fill="#585858" height="12px" viewBox="0 0 12 12">
              <path d="M11.5,7h-11C0.2,7,0,6.8,0,6.5v-1C0,5.2,0.2,5,0.5,5h11C11.8,5,12,5.2,12,5.5v1C12,6.8,11.8,7,11.5,7z"></path>
            </svg>
          </a>
          <input
            :type="display_style == 'simple' ? 'number' : 'text'"
            name="quantity"
            :value="default_quantity"
            class="sg_pq_qty"
          />
          <a
            class="sg-product-quantity-plus d-flex align-items-center justify-content-center"
            v-if="display_style != 'simple'"
            
          >
            <svg fill="#585858" height="12px" viewBox="0 0 12 12">
              <path d="M12,5.5v1C12,6.8,11.8,7,11.5,7H7v4.5C7,11.8,6.8,12,6.5,12h-1C5.2,12,5,11.8,5,11.5V7H0.5C0.2,7,0,6.8,0,6.5 v-1C0,5.2,0.2,5,0.5,5H5V0.5C5,0.2,5.2,0,5.5,0h1C6.8,0,7,0.2,7,0.5V5h4.5C11.8,5,12,5.2,12,5.5z"></path>
            </svg>
          </a>
        </div>
      </div>
    </div>

    <ToolBar
      ref="(p)quantity"
      :toolbar_id="`(p)quantity`"
      class="container-toolbar"
      :class="{ 'container-toolbar-show': isHovering, 'is-dragging': dragging == node.uuid }"
    >
    </ToolBar>

    <v-style>
      {{ custom_css }}

      #element-{{ node.uuid }} label {
        min-width: {{ display_style == 'inline' ? `${label_width}px` : 'unset' }};
      }

      @media (max-width: 767px){ 
        #element-{{ node.uuid }} .sg-product-quantity {
          height: {{ getValue('height','mobile') }}px;
          width: {{ getValue('stretch','mobile') ? '100%' : 'unset' }};
        }
        #element-{{ node.uuid }} .sg-quantity-default {
          align-items: {{ getAlignment(getValue('alignment','mobile')) }};
        }

        #element-{{ node.uuid }} .sg-quantity-inline {
          justify-content: {{ getAlignment(getValue('alignment','mobile')) }};
        }

        #element-{{ node.uuid }} .sg-quantity-simple {
          align-items: {{ getAlignment(getValue('alignment','mobile')) }};
        }
        #element-{{ node.uuid }} .sg-product-quantity input {
          width: {{ getValue('stretch','mobile') ? `calc(100% - ${2 * getValue('button_width','mobile') + display_style == 'inline' ? label_width : 0}px)` : `${getValue('width','mobile')}${getValue('width_type','mobile')}` }};
          font-weight: {{ getValue('quantity_font_weight','mobile') }};
          {{ getValue('quantity_font_size', 'mobile') > 0 ? `font-size: ${getValue('quantity_font_size','mobile')}px;` : '' }}
          {{ getValue('quantity_color', 'mobile') ? `color: ${getValue('quantity_color','mobile')};` : '' }}
          font-family: {{ getValue('quantity_font_family','mobile') }};
        }
        #element-{{ node.uuid }} .sg-product-quantity a {
          width: {{ getValue('button_width','mobile') }}px;
        }
        #element-{{ node.uuid }} label {
          margin-bottom: {{ getValue('label_margin_bottom','mobile') }}px;
          font-weight: {{ getValue('label_font_weight','mobile') }};
          {{ getValue('label_font_size', 'mobile') > 0 ? `font-size: ${getValue('label_font_size','mobile')}px;` : '' }}
          {{ getValue('label_color', 'mobile') ? `color: ${getValue('label_color','mobile')};` : '' }}
          line-height: {{ getValue('label_line_height','mobile') }}em;
          font-family: {{ getValue('label_font_family','mobile') }};
          text-align: {{ getValue('label_alignment', 'mobile', 'left') }};
        }
        #element-{{ node.uuid }} {
          {{ getDisplayValue('mobile') }}
          {{ getMarginValue('mobile') }}
          {{ getPaddingValue('mobile') }}
          {{ getBackgroundValue('mobile') }}
          {{ getBorderValue('mobile') }}
          {{ getBorderRadiusValue('mobile') }}
          {{ getOpacityValue('mobile') }}
          {{ getShadowValue('mobile') }}
          {{ getOtherStyle('mobile') }}
        }
      } 
      @media (max-width: 991px) and (min-width: 768px){ 
        #element-{{ node.uuid }} .sg-product-quantity {
          height: {{ getValue('height','tablet') }}px;
          width: {{ getValue('stretch','tablet') ? '100%' : 'unset' }};
        }
        #element-{{ node.uuid }} .sg-quantity-default {
          align-items: {{ getAlignment(getValue('alignment','tablet')) }};
        }

        #element-{{ node.uuid }} .sg-quantity-inline {
          justify-content: {{ getAlignment(getValue('alignment','tablet')) }};
        }

        #element-{{ node.uuid }} .sg-quantity-simple {
          align-items: {{ getAlignment(getValue('alignment','tablet')) }};
        }
        #element-{{ node.uuid }} .sg-product-quantity input {
          width: {{ getValue('stretch','tablet') ? `calc(100% - ${2 * getValue('button_width','tablet') + display_style == 'inline' ? label_width : 0}px)` : `${getValue('width','tablet')}${getValue('width_type','tablet')}` }};
          font-weight: {{ getValue('quantity_font_weight','tablet') }};
          {{ getValue('quantity_font_size', 'tablet') > 0 ? `font-size: ${getValue('quantity_font_size','tablet')}px;` : '' }}
          {{ getValue('quantity_color', 'tablet') ? `color: ${getValue('quantity_color','tablet')};` : '' }}
          font-family: {{ getValue('quantity_font_family','tablet') }};
        }
        #element-{{ node.uuid }} .sg-product-quantity a {
          width: {{ getValue('button_width','tablet') }}px;
        }
        #element-{{ node.uuid }} label {
          margin-bottom: {{ getValue('label_margin_bottom','tablet') }}px;
          font-weight: {{ getValue('label_font_weight','tablet') }};
          line-height: {{ getValue('label_line_height','tablet') }}em;
          {{ getValue('label_font_size', 'tablet') > 0 ? `font-size: ${getValue('label_font_size','tablet')}px;` : '' }}
          {{ getValue('label_color', 'tablet') ? `color: ${getValue('label_color','tablet')};` : '' }}
          font-family: {{ getValue('label_font_family','tablet') }};
          text-align: {{ getValue('label_alignment', 'tablet', 'left') }};
        }
        #element-{{ node.uuid }} {
          {{ getDisplayValue('tablet') }}
          {{ getMarginValue('tablet') }}
          {{ getPaddingValue('tablet') }}
          {{ getBackgroundValue('tablet') }}
          {{ getBorderValue('tablet') }}
          {{ getBorderRadiusValue('tablet') }}
          {{ getOpacityValue('tablet') }}
          {{ getShadowValue('tablet') }}
          {{ getOtherStyle('tablet') }}
        }
      } 
      @media (max-width: 1199px) and (min-width: 992px){ 
        #element-{{ node.uuid }} .sg-product-quantity {
          height: {{ getValue('height','laptop') }}px;
          width: {{ getValue('stretch','laptop') ? '100%' : 'unset' }};
        }
        #element-{{ node.uuid }} .sg-quantity-default {
          align-items: {{ getAlignment(getValue('alignment','laptop')) }};
        }

        #element-{{ node.uuid }} .sg-quantity-inline {
          justify-content: {{ getAlignment(getValue('alignment','laptop')) }};
        }

        #element-{{ node.uuid }} .sg-quantity-simple {
          align-items: {{ getAlignment(getValue('alignment','laptop')) }};
        }
        #element-{{ node.uuid }} .sg-product-quantity input {
          width: {{ getValue('stretch','laptop') ? `calc(100% - ${2 * getValue('button_width','laptop') + display_style == 'inline' ? label_width : 0}px)` : `${getValue('width','laptop')}${getValue('width_type','laptop')}` }};
          font-weight: {{ getValue('quantity_font_weight','laptop') }};
          {{ getValue('quantity_font_size', 'laptop') > 0 ? `font-size: ${getValue('quantity_font_size','laptop')}px;` : '' }}
          {{ getValue('quantity_color', 'laptop') ? `color: ${getValue('quantity_color','laptop')};` : '' }}
          font-family: {{ getValue('quantity_font_family','laptop') }};
        }
        #element-{{ node.uuid }} .sg-product-quantity a {
          width: {{ getValue('button_width','laptop') }}px;
        }
        #element-{{ node.uuid }} label {
          margin-bottom: {{ getValue('label_margin_bottom','laptop') }}px;
          font-weight: {{ getValue('label_font_weight','laptop') }};
          line-height: {{ getValue('label_line_height','laptop') }}em;
          {{ getValue('label_font_size', 'laptop') > 0 ? `font-size: ${getValue('label_font_size','laptop')}px;` : '' }}
          {{ getValue('label_color', 'laptop') ? `color: ${getValue('label_color','laptop')};` : '' }}
          font-family: {{ getValue('label_font_family','laptop') }};
          text-align: {{ getValue('label_alignment', 'laptop', 'left') }};
        }
        #element-{{ node.uuid }} {
          {{ getDisplayValue('laptop') }}
          {{ getMarginValue('laptop') }}
          {{ getPaddingValue('laptop') }}
          {{ getBackgroundValue('laptop') }}
          {{ getBorderValue('laptop') }}
          {{ getBorderRadiusValue('laptop') }}
          {{ getOpacityValue('laptop') }}
          {{ getShadowValue('laptop') }}
          {{ getOtherStyle('laptop') }}
        }
      } 
      @media (min-width: 1200px) {
        #element-{{ node.uuid }} .sg-product-quantity {
          height: {{ desktop.height }}px;
          width: {{ desktop.stretch ? '100%' : 'unset' }};
        }
        #element-{{ node.uuid }} .sg-quantity-default {
          align-items: {{ getAlignment(desktop.alignment) }};
        }

        #element-{{ node.uuid }} .sg-quantity-inline {
          justify-content: {{ getAlignment(desktop.alignment) }};
        }

        #element-{{ node.uuid }} .sg-quantity-simple {
          align-items: {{ getAlignment(desktop.alignment) }};
        }
        #element-{{ node.uuid }} .sg-product-quantity input {
          width: {{ desktop.stretch ? `calc(100% - ${2 * desktop.button_width + display_style == 'inline' ? label_width : 0}px)` : `${desktop.width}${desktop.width_type}` }};
          font-weight: {{ desktop.quantity_font_weight }};
          {{ getValue('quantity_font_size', 'desktop') > 0 ? `font-size: ${getValue('quantity_font_size','desktop')}px;` : '' }}
          {{ getValue('quantity_color', 'desktop') ? `color: ${getValue('quantity_color','desktop')};` : '' }}
          font-family: {{ desktop.quantity_font_family }};
        }
        #element-{{ node.uuid }} .sg-product-quantity a {
          width: {{ desktop.button_width }}px;
        }
        #element-{{ node.uuid }} label {
          margin-bottom: {{ desktop.label_margin_bottom }}px;
          font-weight: {{ desktop.label_font_weight }};
          {{ getValue('label_font_size', 'desktop') > 0 ? `font-size: ${getValue('label_font_size','desktop')}px;` : '' }}
          {{ getValue('label_color', 'desktop') ? `color: ${getValue('label_color','desktop')};` : '' }}
          line-height: {{ desktop.label_line_height }}em;
          font-family: {{ desktop.label_font_family }};
          text-align: {{ getValue('label_alignment', 'desktop', 'left') }};
        }
        #element-{{ node.uuid }} {
          {{ getDisplayValue('desktop') }}
          {{ getMarginValue('desktop') }}
          {{ getPaddingValue('desktop') }}
          {{ getBackgroundValue('desktop') }}
          {{ getBorderValue('desktop') }}
          {{ getBorderRadiusValue('desktop') }}
          {{ getOpacityValue('desktop') }}
          {{ getShadowValue('desktop') }}
          {{ getOtherStyle('desktop') }}
        } 
      }
    </v-style>
  </div>
</template>

<script>
import ToolBar from "../../elements/utils/ToolBar.vue";

import elementStyleMixin from "../../elements/elementStyleMixin";
import ExtraClass from "../../elements/otherSettings/ExtraClass.vue"; import CustomCSS from '../../elements/styleSettings/CustomCSS.vue';
import AlignmentBase from "../../elements/styleSettings/AlignmentBase.vue";
import LabelText from "./settings/LabelText.vue";
import DimensionsQuantity from "./settings/DimensionsQuantity.vue";
import InputNumber from "./settings/InputNumber.vue";
import DisplayQuantity from "./settings/DisplayQuantity.vue";

import Visibility from "../../elements/designSettings/Visibility.vue";
import Spacing from "../../elements/designSettings/Spacing.vue";
import Background from "../../elements/designSettings/Background.vue";
import Border from "../../elements/designSettings/Border.vue";
import Corner from "../../elements/designSettings/Corner.vue";
import Shadow from "../../elements/designSettings/Shadow.vue";
import Opacity from "../../elements/designSettings/Opacity.vue";
import { designStyles, otherProps, otherStyles, actionTypes } from "@/constants";
import Animations from '../../elements/designSettings/Animations.vue';

export default {
  components: { ToolBar },
  watch: {
    default_quantity() {
      this.$el.getElementsByClassName("sg_pq_qty")[0].value = this.default_quantity;
    },
  },
  mounted() {
    this.$el.getElementsByClassName("sg_pq_qty")[0].value = this.default_quantity;
  },
  data() {
    return {
      isHovering: false,
    };
  },
  methods: {
    updateValueInput(type) {
      var inputValue = parseInt(
        this.$el.getElementsByClassName("sg_pq_qty")[0].value
      );
      inputValue = isNaN(inputValue) ? 1 : inputValue;
      var value = type == "plus" ? 1 : inputValue <= 1 ? 0 : -1;
      this.$el.getElementsByClassName("sg_pq_qty")[0].value = inputValue + value;
    },
    getAlignment(alignment) {
      if (alignment == 'left') {
        return 'flex-start'
      } else if (alignment == 'right') {
        return 'flex-end'
      } else {
        return alignment
      }
    }
  },
  inject: ["node","product",'editor'],
  mixins: [elementStyleMixin],
  props: {
    
    extra_class: String, ...otherProps,
    component: String,
    parent: String,
    update_price: Boolean,
    default_quantity: String,
    display_style: String,
    desktop: Object,
    laptop: Object,
    mobile: Object,
    tablet: Object,
    show_label: Boolean,
    label: String,
    label_width: Number
  },
  computed: {
    dragging: function () {
      return this.editor?.draggedNode?.uuid
    },
  },
  craft: {
    defaultProps: { ...otherStyles,
      update_price: false,
      extra_class: "",
      show_label: true,
      label: "Quantity",
      parent: "Product",
      default_quantity: "1",
      display_style: "default",
      label_width: 100,
      desktop: {
        ...designStyles,
        label_margin_bottom: 5,
        label_font_weight: '400',
        label_font_size: 16,
        label_line_height: 1.2,
        label_color: 'rgb(61, 66, 70)',
        label_font_family: 'inherit',
        stretch: false,
        width: 60,
        width_type: 'px',
        height: 40,
        button_width: 40,
        quantity_font_weight: '400',
        quantity_font_size: 16,
        quantity_color: 'rgb(61, 66, 70)',
        quantity_font_family: 'inherit',
        alignment: 'center'
      },
      laptop: {
        ...designStyles,
      },
      tablet: {
        ...designStyles,
      },
      mobile: {
        ...designStyles,
      },
      elementStyle: {
        
      },
    },
    settings: {
      settings: {
        "Show Label": {"Show Label": LabelText, multi_screen: true},
        Dimension: {Dimension: DimensionsQuantity, multi_screen: true},
        Display: DisplayQuantity,
        "Input Number": {"Input Number": InputNumber, multi_screen: true},
        Alignment: {Alignment: AlignmentBase, multi_screen: true},
        "Extra Class": ExtraClass, "Custom CSS": CustomCSS,
      },
      design: {
        Visibility,
        Spacing: { Spacing, multi_screen: true },
        Background: { Background, multi_screen: true },
        Border: { Border, multi_screen: true },
        Corner: { Corner, multi_screen: true },
        Shadow: { Shadow, multi_screen: true },
        Opacity: { Opacity, multi_screen: true },
      },
      others: {
        Animations: { Animations, actions: actionTypes }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
