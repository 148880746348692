<template>
  <div class="custom-select" :style="cssVars">
    <el-select
      filterable
      :value="value || 'none'"
      @change="changeValue($event)"
      popper-class="custom-popper-select"
      size="medium"
      :disabled="disabled"
      :clearable="clearable"
    >
      <el-option
        v-for="item in options"
        :key="item.value"
        :value="item.value"
        :label="item.label"
        :disabled="item.disabled"
      >
        <svg class="icon-checked" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" stroke-width="3" :stroke="primaryColor || '#0060e5'" fill="none" stroke-linecap="round" stroke-linejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <path d="M5 12l5 5l10 -10"></path>
        </svg>
        <span style="margin-left: 20px;">{{ item.label }}</span>
      </el-option>
      <div class="action" @click="onAction" v-if="hasAction">
        <span>{{ actionLabel || 'Action' }}</span>
      </div>
    </el-select>
  </div>
</template>

<script>

export default {
  components: {
    name: 'Select'
  },
  props: {
    value: String,
    options: Array,
    changeValue: Function,
    hasAction: Boolean,
    onAction: Function,
    actionLabel: String,
    primaryColor: String,
    clearable: Boolean,
    disabled: Boolean
  },
  computed: {
    cssVars() {
      return {
        '--select-color': this.primaryColor || '#0060e5'
      }
    }
  }
};
</script>

<style>
  .custom-select .el-select {
    width: 100%;
  }
  .custom-select .is-focus input {
    border: 1px solid var(--select-color, #0060e5) !important;
  }
  .custom-select .el-input__inner {
    background-color: #222222;
    border: 1px solid #717171;
    color: #C0C4CC;
  }

  .custom-popper-select .el-select-dropdown__item.hover {
    background-color: transparent;
  }

  .custom-popper-select .el-select-dropdown__item:hover {
    background-color: #666666;
  }

  .custom-popper-select .el-select-dropdown__item.is-disabled {
    color: #888;
  }

  .custom-popper-select.el-select-dropdown {
    background-color: #555555;
    border: unset;
    border-radius: 0px;
  }
  .custom-popper-select .popper__arrow {
    display: none;
  }
  .custom-popper-select[x-placement^=bottom] {
    margin-top: 4px !important;
  }
  .custom-popper-select[x-placement^=top] {
    margin-bottom: 4px !important;
  }
  .custom-popper-select .el-select-dropdown__item {
    color: #E2E2E2;
    position: relative;
    font-size: 12px;
  }
  .custom-popper-select .el-select-dropdown__item.selected {
    font-weight: normal;
    color: #E2E2E2 !important;
  }
  .custom-popper-select .el-select-dropdown__item .icon-checked {
    display: none;
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
  }
  .custom-popper-select .el-select-dropdown__item.selected .icon-checked {
    display: block;
  }
  .custom-popper-select .action {
    border-top: 1px solid #757575;
    padding: 0px 20px;
    text-align: center;
    color: var(--select-color, #04AA6D);
    font-size: 12px;
    cursor: pointer;
    line-height: 40px;
    height: 40px;
  }
  .custom-popper-select .action:hover {
    background-color: #757575;
  }
  .custom-popper-select .el-select-dropdown__list {
    padding: 0px;
  }
</style>