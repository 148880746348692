import kebabCase from 'lodash/kebabCase';
import Indicator from './Indicator';
import Node from './Node';

class Editor {
  constructor(nodes = [], resolverMap = {}, popups = []) {
    this.nodeMap = {};
    this.selectedNode = null;
    this.draggedNode = null;
    this.indicator = new Indicator();
    this.enabled = true;
    this.popups = popups;
    this.setTopLevelNodes(nodes);
    this.setResolverMap(resolverMap);
    this.timeline = [];
    this.pointer = -1;
    this.isUndo = false;
    this.isRedo = false;
    this.fonts = null;
    this.isDraggable = false;
    this.animations = [];
  }

  enable() {
    this.enabled = true;
  }

  disable() {
    this.selectNode(null);
    this.enabled = false;
  }

  setResolverMap(resolverMap) {
    this.resolverMap = {};

    Object.entries(resolverMap).forEach(([key, value]) => {
      this.resolverMap[kebabCase(key)] = value;
    });
  }

  initializeNodeMap(nodes) {
    nodes.forEach((node) => {
      this.nodeMap[node.uuid] = node;
      this.initializeNodeMap(node.children);
    });
  }

  setTopLevelNodes(nodes) {
    this.nodes = nodes;
    this.initializeNodeMap(nodes);
  }

  setLevelNodes(nodes) {
    this.initializeNodeMap(nodes);
  }

  findNode(uuid) {
    return this.nodeMap[uuid];
  }

  selectNode(node) {
    this.selectedNode = node;
  }

  dragNode(node) {
    this.draggedNode = node;
  }

  findResolver(name) {
    return this.resolverMap[kebabCase(name)];
  }

  removeNode(node) {
    let nodes = this.export()
    let popups = this.exportPopup()
    let inversePatches = { nodes, popups }
    var productNode = this.checkChildProductList(node);
    var currentList = productNode?.parent
    node.makeOrphan();
    if (productNode) {
      for (var i = 0; i < currentList.children.length; i++) {
        const product = currentList.children[i]
        if (product.uuid == productNode.uuid) continue;
        const node2 = productNode.duplicate()
        product.children = node2.children;
      }
    }
    if (node === this.selectedNode) {
      this.selectNode(null);
    }
    let patches = { nodes: this.export(), popups: this.exportPopup() };
    this.add(patches, inversePatches)
  }

  checkChildProductList(node) {
    const parent = node.parent
    if (parent) {
      if (["ProductList", "RelatedProduct"].includes(parent.componentName)) {
        return node
      } else {
        return this.checkChildProductList(parent)
      }
    } else {
      return null
    }
  }

  getCraftConfig(node) {
    let resolver;
    if (node.isCanvas()) {
      resolver = this.findResolver(node.props.component);
      if (!resolver) {
        resolver = this.findResolver(node.componentName);
      }
    } else {
      resolver = this.findResolver(node.componentName);
    }

    return resolver.craft || {};
  }

  getSettings(node) {
    return this.getCraftConfig(node).settings || {};
  }

  export() {
    const nodesData = this.nodes.map((node) => node.serialize());
    return JSON.stringify(nodesData);
  }

  import(plainNodesData) {
    try {
      const nodesData = JSON.parse(plainNodesData);
      this.nodes = nodesData.map((data) => Node.unserialize(this, data));
      this.setLevelNodes(this.nodes);
    } catch {
      throw new Error('The input is not valid.');
    }
  }

  exportPopup() {
    const nodesData = this.popups.map((node) => node.serialize());
    return JSON.stringify(nodesData);
  }

  importPopUp(plainNodesData) {
    try {
      const nodesData = JSON.parse(plainNodesData);
      this.popups = nodesData.map((data) => Node.unserialize(this, data));
      this.setLevelNodes(this.popups);
    } catch {
      throw new Error('The input is not valid.');
    }
  }

  add(patches, inversePatches) {
    this.pointer = this.pointer + 1;
    this.timeline.length = this.pointer;
    this.timeline[this.pointer] = {
      patches,
      inversePatches,
      timestamp: Date.now(),
    };
    this.setLevelNodes(this.nodes);
    this.setLevelNodes(this.popups);
  }
  applyPatches(inversePatche) {
    setTimeout(() => {
      this.import(inversePatche.nodes);
      this.importPopUp(inversePatche.popups);
      this.isUndo = false;
      this.isRedo = false;
      this.setLevelNodes(this.nodes);
      this.setLevelNodes(this.popups);
      this.selectNode(null)
    }, 100)
  }

  clear() {
    this.timeline = [];
    this.pointer = -1;
  }

  canUndo() {
    return this.pointer >= 0;
  }

  canRedo() {
    return this.pointer < this.timeline.length - 1;
  }

  undo() {
    if (!this.canUndo()) {
      return;
    }
    this.isUndo = true;
    // this.nodes =[]
    // this.popups =[]
    const { inversePatches } = this.timeline[this.pointer];
    this.pointer = this.pointer - 1;
    return this.applyPatches(inversePatches);
  }

  redo() {
    if (!this.canRedo()) {
      return;
    }
    this.isRedo = true;
    // this.nodes =[]
    // this.popups =[]
    this.pointer = this.pointer + 1;
    const { patches } = this.timeline[this.pointer];
    return this.applyPatches(patches);
  }
}

export default Editor;
