<template>
  <el-form label-position="top" size="medium" :model="elementProps">
    <div class="d-flex flex-column py-2">
      <span class="setting-title">Type</span>
      <ToggleButton
        class="mt-2"
        :value="elementProps.type"
        v-bind:changeValue="($event) => updateProps('type', $event)"
        :primaryColor="primaryColor"
        :options="[
          {
            label: 'Standard',
            value: 'standard',
          },
          {
            label: 'Daily',
            value: 'daily',
          },
          {
            label: 'Evergreen',
            value: 'evergreen',
          },
        ]"
      />
    </div>
    <div class="d-flex flex-column py-2" v-if="elementProps.type == 'standard'">
      <span class="setting-title">Date</span>
      <DatePicker
        class="mt-2"
        :value="new Date(elementProps.end_date)" 
        type="datetime"
        v-bind:changeValue="($event) => updateProps('end_date', `${$event.getFullYear()}-${($event.getMonth()+1)}-${$event.getDate()} ${$event.getHours()}:${$event.getMinutes()}:${$event.getSeconds()}`)"
        format="YYYY-MM-DD HH:mm:ss"
        :primaryColor="primaryColor"
      />
    </div>
    <div class="d-flex flex-column py-2" v-if="elementProps.type == 'standard'">
      <span class="setting-title">Timezone</span>
      <Input 
        class="mt-2"
        :value="elementProps.timezone"
        v-bind:changeValue="($event) => updateProps('timezone', $event)"
        :primaryColor="primaryColor"
      />
    </div>
    <div class="d-flex gap-2" v-if="elementProps.type == 'daily'">
      <div class="d-flex flex-column py-2 flex-1">
        <span class="setting-title text-center">Hours</span>
        <Input 
          type="number"
          class="mt-2 time-label"
          :value="elementProps.daily[0]"
          v-bind:changeValue="($event) => updateArray('daily', 0, $event)"
          :primaryColor="primaryColor"
        />
      </div>
      <div class="d-flex flex-column py-2 flex-1">
        <span class="setting-title text-center">Mins</span>
        <Input 
          type="number"
          class="mt-2 time-label"
          :value="elementProps.daily[1]"
          v-bind:changeValue="($event) => updateArray('daily', 1, $event)"
          :primaryColor="primaryColor"
        />
      </div>
      <div class="d-flex flex-column py-2 flex-1">
        <span class="setting-title text-center">AM</span>
        <el-button class="mt-2" style="height: 36px;" @click="updateArray('daily', 2, time == 'AM' ? 'PM' : 'AM')" size="small" type="info">{{ time }}</el-button>
      </div>
    </div>
    <div class="d-flex gap-2" v-if="elementProps.type == 'evergreen'">
      <div class="d-flex flex-column py-2 flex-1">
        <span class="setting-title text-center">Days</span>
        <Input 
          type="number"
          class="mt-2 time-label"
          :value="elementProps.evergreen[0]"
          v-bind:changeValue="($event) => updateArray('evergreen', 0, $event)"
          :primaryColor="primaryColor"
        />
      </div>
      <div class="d-flex flex-column py-2 flex-1">
        <span class="setting-title text-center">Hours</span>
        <Input 
          class="mt-2 time-label"
          type="number"
          :value="elementProps.evergreen[1]"
          v-bind:changeValue="($event) => updateArray('evergreen', 1, $event)"
          :primaryColor="primaryColor"
        />
      </div>
      <div class="d-flex flex-column py-2 flex-1">
        <span class="setting-title text-center">Mins</span>
        <Input 
          class="mt-2 time-label"
          type="number"
          :value="elementProps.evergreen[2]"
          v-bind:changeValue="($event) => updateArray('evergreen', 2, $event)"
          :primaryColor="primaryColor"
        />
      </div>
    </div>
    <div class="d-flex align-items-center justify-content-between py-2" v-if="elementProps.type == 'evergreen'">
      <span class="sgSubTitle">Loop (refresh after finish)</span>
      <el-switch
        :value="elementProps.loop"
        :active-color="primaryColor"
        inactive-color="#949494"
        @change="updateProps('loop', $event)"
      />
    </div>

    <div class="d-flex align-items-center justify-content-between py-2">
      <span class="sgSubTitle">Auto Hide when timeout</span>
      <el-switch
        :value="elementProps.timeout_hide"
        :active-color="primaryColor"
        inactive-color="#949494"
        @change="updateProps('timeout_hide', $event)"
      />
    </div>
    <div class="sgDesc mt-1">
      The countdown will automatically hide after it finishes instead of showing 0 value.
    </div>
  </el-form>
</template>

<script>
import mixin from "../../../elements/styleSettings/mixin";
import Slider from "../../../elements/common/Slider.vue";
import ToggleButton from "../../../elements/common/ToggleButton.vue";
import DatePicker from "../../../elements/common/DatePicker.vue";
import Input from "../../../elements/common/Input.vue";

export default {
  mixins: [mixin],
  data() {
    return {
      time: "AM",
    };
  },
  props: {
    primaryColor: String
  },
  components: { Slider, ToggleButton, DatePicker, DatePicker, Input },
  methods: {
    changeAM() {
      if (this.time == "AM") {
        this.time = "PM";
      } else {
        this.time = "AM";
      }
    },
    updateProps(name, value) {
      this.elementPropsSetter({ [name]: value });
    },
    updateArray(name, index, value) {
      const data = Object.assign([], this.elementProps[name])
      data[index] = value;
      if (name == 'daily' && index == 2) {
        this.time = value
      }
      this.elementPropsSetter({ [name]: data });
    },
    changeDate(event) {
      this.elementPropsSetter({
        'end_date': new Date(event.date)
      })
    }
  },
};
</script>

<style>
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}

.time-label .el-input__inner {
  text-align: center !important;
}


</style>
