var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"label-position":"top","size":"mini","model":_vm.elementProps}},[_c('div',{staticClass:"d-flex flex-column py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Style")]),_c('Select',{staticClass:"mt-2",attrs:{"primaryColor":_vm.primaryColor,"value":_vm.elementProps.display_style,"changeValue":($event) => _vm.updateProps('display_style', $event),"options":[
        {
          value: 'default',
          label: 'Default',
        },
        {
          value: 'inline',
          label: 'Inline',
        },
      ]}})],1),(_vm.elementProps.display_style == 'inline')?_c('div',{staticClass:"d-flex flex-column py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Label Width")]),_c('Slider',{staticClass:"mt-2",attrs:{"primaryColor":_vm.primaryColor,"value":Number.parseInt(_vm.elementProps.label_width),"min":50,"max":300,"step":1,"changeValue":($event) => _vm.updateProps('label_width', parseInt($event)),"unit":"px","onlyInteger":true}})],1):_vm._e(),_c('div',{staticClass:"d-flex flex-column py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Columns")]),_c('Select',{staticClass:"mt-2",attrs:{"primaryColor":_vm.primaryColor,"value":_vm.elementProps.columns,"changeValue":($event) => _vm.updateProps('columns', $event),"options":[
        {
          value: '1',
          label: '1 Column',
        },
        {
          value: '2',
          label: '2 Columns',
        },
        {
          value: '3',
          label: '3 Columns',
        },
        {
          value: '4',
          label: '4 Columns',
        },
      ]}})],1),_c('div',{staticClass:"d-flex flex-column py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Group By")]),_c('Select',{staticClass:"mt-2",attrs:{"primaryColor":_vm.primaryColor,"value":_vm.elementProps.group_by,"changeValue":($event) => _vm.updateProps('group_by', $event),"options":[
        {
          value: 'default',
          label: 'Default',
        },
        {
          value: 'separately',
          label: 'Separately',
        },
      ]}})],1),_c('div',{staticClass:"d-flex align-items-center justify-content-between py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Blank Option")]),_c('el-switch',{attrs:{"value":_vm.elementProps.blank_option,"active-color":_vm.primaryColor,"inactive-color":"#949494"},on:{"change":function($event){return _vm.updateProps('blank_option', $event)}}})],1),(_vm.elementProps.blank_option)?_c('div',{staticClass:"d-flex flex-column py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Blank Option Text")]),_c('el-input',{staticClass:"input-with-select mt-2",attrs:{"value":_vm.elementProps.blank_text,"size":"medium"},on:{"input":function($event){return _vm.updateProps('blank_text', $event)}}})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }