<template>
  <div>
    <div v-if="!showOtherSetting" class="section-settings">
      <input type="radio" id="tab-settings" name="tab" checked class="el-tab" />
      <label for="tab-settings" class="tab-label">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="#AAAAAA"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <path
            d="M10.325 4.317c.426 -1.756 2.924 -1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543 -.94 3.31 .826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c1.756 .426 1.756 2.924 0 3.35a1.724 1.724 0 0 0 -1.066 2.573c.94 1.543 -.826 3.31 -2.37 2.37a1.724 1.724 0 0 0 -2.572 1.065c-.426 1.756 -2.924 1.756 -3.35 0a1.724 1.724 0 0 0 -2.573 -1.066c-1.543 .94 -3.31 -.826 -2.37 -2.37a1.724 1.724 0 0 0 -1.065 -2.572c-1.756 -.426 -1.756 -2.924 0 -3.35a1.724 1.724 0 0 0 1.066 -2.573c-.94 -1.543 .826 -3.31 2.37 -2.37c1 .608 2.296 .07 2.572 -1.065z"
          ></path>
          <circle cx="12" cy="12" r="3"></circle>
        </svg>
        <span>Settings</span>
      </label>
      <input type="radio" id="tab-design" name="tab" class="el-tab" />
      <label for="tab-design" class="tab-label">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="#AAAAAA"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <path
            d="M12 21a9 9 0 1 1 0 -18a9 8 0 0 1 9 8a4.5 4 0 0 1 -4.5 4h-2.5a2 2 0 0 0 -1 3.75a1.3 1.3 0 0 1 -1 2.25"
          ></path>
          <circle cx="7.5" cy="10.5" r=".5" fill="currentColor"></circle>
          <circle cx="12" cy="7.5" r=".5" fill="currentColor"></circle>
          <circle cx="16.5" cy="10.5" r=".5" fill="currentColor"></circle>
        </svg>
        <span>Design</span>
      </label>
      <div class="line" :style="{'background-color': primaryColor}"></div>
      <div class="content-container">
        <div
          class="content"
          id="content-settings"
          v-if="settingComponents?.settings"
        >
          <div
            v-for="(component, name) in settingComponents.settings"
            class="item-wrapper"
            :key="name"
          >
            <div class="sg-box-setting" :class="{layout: name == 'Layout'}" v-if="checkVisibleComponent(component, selectedNode) || (selectedNode?.props?.hiddenConfig && !selectedNode?.props?.hiddenConfig?.includes(name))">
              <div v-if="name" class="sg-box-title">
                <span>{{ showLayout == 'default' ? name : 'Column Size Customizer' }}</span>
                <span
                  v-if="component.multi_screen == true"
                  v-html="icons[screen]"
                />
              </div>
              <component
                :is="component.multi_screen || component[name] ? component[name] : component"
                :node="selectedNode"
                :screen="screen"
                :name="name"
                :multi_screen="component.multi_screen ? true : false"
                v-bind:changeScreen="changeScreen"
                :activeFonts="activeFonts"
                :product="product"
                :blog="blog"
                :collection="collection"
                :primaryColor="primaryColor"
                :show="showLayout"
                :setShow="setShowLayout"
                :remake="component?.multi_screen || false"
              />
            </div>
          </div>
        </div>
        <div class="content" id="content-design">
          <el-collapse v-if="settingComponents?.design" class="item-wrapper">
            <el-collapse-item
              v-for="(component, name) in settingComponents.design"
              :key="name"
              :name="name"
            >
              <template slot="title">
                <div class="item-title">
                  <span>{{ name }}</span>
                  <span
                    v-if="component.multi_screen == true"
                    v-html="icons[screen]"
                  />
                </div>
              </template>
              <component
                :is="component.multi_screen ? component[name] : component"
                :node="selectedNode"
                :screen="screen"
                :primaryColor="primaryColor"
              />
            </el-collapse-item>
            <el-collapse-item
              v-for="(component, name) in settingComponents.others"
              :key="name"
              :name="name"
            >
              <template slot="title">
                <div class="item-title" @click="() => clickOtherSettings(component, name)">
                  <span>{{ name }}</span>
                  <span
                    v-if="component.multi_screen == true"
                    v-html="icons[screen]"
                  />
                </div>
              </template>
            </el-collapse-item>
          </el-collapse>
        </div>
      </div>
    </div>
    <div v-else>
      <!-- {{ data_component }} -->
      <component
        :is="data_component[component_name]"
        :actions="data_component['actions'] || []"
        :node="selectedNode"
        :name="component_name"
        :screen="screen"
        :primaryColor="primaryColor"
      />
    </div>
  </div>
</template>

<script>
import { icons } from "../../../constants";
import ToggleButton from "./elements/common/ToggleButton.vue";
import ColorPicker from "./elements/common/ColorPicker.vue";
import Slider from "./elements/common/Slider.vue";
export default {
  components: {
    name: "ElementSettings",
    ToggleButton,
    ColorPicker,
    Slider,
  },
  inject: ["editor"],
  props: {
    screen: String,
    settings: Object,
    changeValue: Function,
    changeScreen: Function,
    activeFonts: Array,
    product: Object,
    blog: Object,
    collection: Object,
    primaryColor: String,
    showLayout: String,
    setShowLayout: Function,
    showOtherSetting: Boolean,
    setOtherSetting: Function,
  },
  computed: {
    selectedNode() {
      if (['WrapperContainer', 'ContainerChild',"Column"].includes(this.editor.selectedNode?.props.component)) {
        return null;
      }
      if (['PricingItem', 'AccordionItem', "CarouselItem", "TabContent", "Article"].includes(this.editor.selectedNode?.componentName)) {
        return this.editor.selectedNode.parent;
      }
      return this.editor.selectedNode;
    },
    settingComponents() {
      if (!this.selectedNode) {
        return null;
      }
      return this.editor.getSettings(this.selectedNode);
    },
  },
  data() {
    return {
      icons,
      tab: "settings",
      other_inline: true,
      data_component: null,
      component_name: null
    };
  },
  methods: {
    setTab(tab) {
      this.tab = tab;
    },
    clickOtherSettings(component, name) {
      this.data_component = component;
      this.component_name = name;
      this.setOtherSetting(true);
    },
    checkVisibleComponent(component, selectedNode) {
      if (!component.condition) {
        return true
      }
      const data = []
      component.condition.map(c => {
        const field_value = selectedNode?.props[c[0]]
        const operator = c[1]
        const value = c[2]
        if (operator == '=') {
          data.push(field_value == value ? true : false)
        } else if (operator == '!=') {
          data.push(field_value != value ? true : false)
        }
      })
      return data.every(element => element === true);
    }
  },
};
</script>

<style lang="scss">
$tabs: settings, design;
.section-settings {
  #tab-settings,
  #tab-design {
    display: none;
    &:checked + label.tab-label {
      color: #fff;
      svg {
        stroke: #fff;
      }
    }
    @each $tab in $tabs {
      $i: index($tabs, $tab);
      &#tab-#{$tab}:checked {
        ~ .line {
          left: #{($i - 1) * 50%};
        }
        ~ .content-container #content-#{$tab} {
          display: block;
        }
      }
    }
  }
  @each $tab in $tabs {
    $i: index($tabs, $tab);
    &#tab-#{$tab}:checked {
      ~ .line {
        left: #{($i - 1) * 50%};
      }
      ~ .content-container #content-#{$tab} {
        display: block;
      }
    }
  }
}
label.tab-label {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 42px;
  line-height: 42px;
  width: 50%;
  text-align: center;
  background: #353535;
  color: #aaaaaa;
  position: relative;
  transition: 0.25s background ease;
  cursor: pointer;
  &::after {
    content: "";
    height: 2px;
    width: 100%;
    position: absolute;
    display: block;
    bottom: 0;
    opacity: 0;
    left: 0;
    transition: 0.25s ease;
  }
  &:hover::after {
    opacity: 1;
  }
  span {
    margin-left: 5px;
    font-size: 15px;
  }
}
.line {
  position: absolute;
  height: 2px;
  // background: #04aa6d;
  width: 50%;
  top: 88px;
  left: 0;
  transition: 0.25s ease;
}
.content-container {
  -ms-overflow-style: none;
  scrollbar-width: none;
  position: relative;
  height: calc(100vh - 200px);
  overflow: auto;
  font-size: 14px;
  .content {
    position: absolute;
    padding: 10px;
    width: 100%;
    top: 0;
    display: none;
    transition: 0.25s ease;
  }
}

.content-container::-webkit-scrollbar {
  display: none;
}

#content-design {
  .item-wrapper {
    border-radius: 6px;
    width: 100%;
  }

  .item-title {
    cursor: pointer;
    color: #fff;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .el-collapse {
    border: none;
    width: 100%;
  }
  .el-collapse-item {
    margin-bottom: 10px;
    width: 100%;
    background-color: #353535;
    border-radius: 6px;
    padding: 0 10px;
    .el-collapse-item__arrow {
      display: none;
    }
    .el-collapse-item__header,
    .el-collapse-item__wrap {
      background-color: transparent;
      border-bottom: none;
      overflow: visible;
    }
    .el-collapse-item__content {
      color: #aaaaaa;
      padding-bottom: 10px;
    }
  }
}

#content-settings, #content-site_settings {
  .item-wrapper {
    background-color: #323232;
    border-radius: 1px;
    cursor: pointer;
    margin-bottom: 10px;
    color: #fff;
    transition: all 0.3s ease;

    .item-title {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .el-collapse {
      border: none;
      width: 100%;
    }

    .el-collapse-item {
      width: 100%;
      background-color: #353535;
      border-radius: 6px;
      padding: 0 10px;
      .el-collapse-item__arrow {
        display: none;
      }
    }
    .el-form-item__label {
      color: #aaa;
      font-size: 12px;
    }

    .sg-box-title {
      font-size: 15px;
      height: 40px;
      line-height: 40px;
      font-weight: 400;
      // background-color: #353535;
      border-bottom: none;
      color: #fff;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .el-collapse-item__wrap {
      border-bottom: none;
      background-color: transparent !important;
    }
    .el-collapse-item__content {
      padding-bottom: 10px;
      color: #ccc;
    }
    .sg-box-setting:not(.layout) {
      padding: 8px 14px;
    }

    .sg-box-setting.layout {
      .sg-box-title {
        padding: 0px 14px;
      }
    }

    .input-with-select {
      .el-input--suffix span.el-input__suffix {
        display: none !important;
      }
      .el-input--suffix .el-input__inner {
        padding-right: 0px;
      }
      .el-select .el-input--suffix .el-input__inner {
        border-color: transparent;
        background-color: transparent;
        border-top: 0;
        border-bottom: 0;
        padding: 0;
        text-align: center;
      }
      .el-input-group__append {
        background-color: #222222;
        color: #909399;
        border-top: 1px solid #494949;
        border-bottom: 1px solid #494949;
        border-right: 1px solid #494949;
      }
    }
    .el-textarea {
      textarea {
        background-color: #222222;
        border: 1px solid #494949;
        font-size: 12px;
        color: #ccc;
      }

      ::placeholder {
        color: #757575;
        opacity: 1; /* Firefox */
      }
    }
  }
}

.spacing-input {
  width: 30%;
}
.spacing-input input {
  padding: 0 10px;
}

.spacing-input .el-input-group__append {
  padding: 0 4px;
  border: 1px solid #494949;
  border-left: none;
  background-color: #222222;
  input {
    border-right: none;
  }
}

.spacing-input.el-input-group--prepend {
  input {
    border-left: none;
  }
}

.spacing-input.el-input-group--append {
  input {
    border-right: none;
  }
}

.spacing-input .el-input-group__prepend {
  padding: 0 4px;
  border: 1px solid #494949;
  border-right: none;
  background-color: #222222;
}

#content-design .el-input,
#content-site_settings .el-input,
#content-settings .el-input {
  .el-input__inner {
    background-color: #222222;
    border: 1px solid #494949;
    font-size: 12px;
    color: #ccc;
  }
  ::placeholder {
    color: #757575;
    opacity: 1; /* Firefox */
  }
}

.el-form--label-top .el-form-item__label {
  padding: 0;
}
</style>