import Cookies from "js-cookie";
// import VueCookies from "vue-cookies";

export function authHeader() {
  // return authorization header with jwt token
  let user = JSON.parse(Cookies.get('user_onepage') || "{}")
  if (user && user.token) {
    return {
      "x-access-token": user.token,
      "Accept": "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-shop-domain": user.store?.domain
    }
  } else {
    return {
      "Accept": "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    }
  }
}
