<template>
  <div class="sg-overlayList">
        <OverlayPopup 
            v-for="node in editor.popups.filter(x=>x.componentName == 'Popup')"
            :key="node.uuid"
            :node="node"
            :editor="editor"
        />
  </div>
</template>
<script>
import OverlayPopup from './OverlayPopup.vue';
export default {
  name: "Anchor",
  components:{
    OverlayPopup,
  },
  props: {
    editor: Object,
  },
  watch: {},
  data(props) {
    return {};
  },
  methods: {},
};
</script>
  
  