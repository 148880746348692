var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"label-position":"top","size":"medium","model":_vm.elementProps}},[_c('div',{staticClass:"d-flex flex-column py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Font Weight")]),_c('Select',{staticClass:"mt-2",attrs:{"primaryColor":_vm.primaryColor,"value":_vm.getValue('cp_font_weight',_vm.elementProps[_vm.screen].cp_font_weight),"changeValue":($event) => _vm.updateProps('cp_font_weight', $event),"options":[
        {
          value: '100',
          label: 'Thin 100',
        },
        {
          value: '200',
          label: 'Semi Thin 200',
        },
        {
          value: '300',
          label: 'Light 300',
        },
        {
          value: '400',
          label: 'Regular 400',
        },
        {
          value: '500',
          label: 'Medium 500',
        },
        {
          value: '600',
          label: 'Semi Bold 600',
        },
        {
          value: '700',
          label: 'Bold 700',
        },
        {
          value: '800',
          label: 'Super Bold 800',
        },
        {
          value: '900',
          label: 'Boldest 900',
        },
        {
          value: 'lighter',
          label: 'Lighter',
        },
        {
          value: 'bolder',
          label: 'Bolder',
        },
      ]}})],1),_c('div',{staticClass:"d-flex flex-column py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Font Size")]),_c('Slider',{staticClass:"mt-2",attrs:{"primaryColor":_vm.primaryColor,"value":_vm.getValue('cp_font_size',_vm.elementProps[_vm.screen].cp_font_size),"changeValue":($event) => _vm.updateProps('cp_font_size', $event),"unit":"px"}})],1),_c('div',{staticClass:"d-flex flex-column py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Text Color")]),_c('ColorPicker',{staticClass:"mt-2",attrs:{"value":_vm.getValue('cp_color',_vm.elementProps[_vm.screen].cp_color),"changeColor":($event) => _vm.updateProps('cp_color', $event),"primaryColor":_vm.primaryColor}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }