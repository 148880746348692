var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sg-popupOverlay",class:_vm.node.props.overlay && _vm.node.props.showPopup ? 'show-overlay' : '',style:({
    'background-color': `${_vm.node.props.overlay_color}`,
    'opacity': `${_vm.node.props.overlay_opacity}`,
    
  }),attrs:{"id":`popupOverlay-${_vm.node.uuid}`,"data-id":_vm.node.uuid,"data-effects":"fade","data-link-button":_vm.node.props.link_button ? 1 : 0,"data-showPopup":_vm.node.props.showPopup ? 1 : 0,"data-display-mobile":_vm.node.props.mobile.display == 'none' ? 0 : 1,"data-display-tablet":_vm.node.props.tablet.display == 'none' ? 0 : 1,"data-display-laptop":_vm.node.props.laptop.display == 'none' ? 0 : 1,"data-display-desktop":_vm.node.props.desktop.display == 'none' ? 0 : 1},on:{"click":($event) => {
      if (_vm.node.props.overlay_close) {
        _vm.node.setProps({ showPopup: false }, true);
        _vm.editor.selectNode(null);
      }
      // $event.stopPropagation();
    }}},[_c('v-style',[_vm._v(" #popupOverlay-"+_vm._s(_vm.node.uuid)+" { display: none; } #popupOverlay-"+_vm._s(_vm.node.uuid)+".show-overlay { display: block; } @media (max-width: 767px){ #popupOverlay-"+_vm._s(_vm.node.uuid)+" { "+_vm._s(_vm.node.props.mobile.display == 'none' ? `display: none !important;` : '')+" } } @media (max-width: 991px) and (min-width: 768px){ #popupOverlay-"+_vm._s(_vm.node.uuid)+" { "+_vm._s(_vm.node.props.tablet.display == 'none' ? `display: none !important;` : '')+" } } @media (max-width:1199px) and (min-width: 992px){ #popupOverlay-"+_vm._s(_vm.node.uuid)+" { "+_vm._s(_vm.node.props.laptop.display == 'none' ? `display: none !important;` : '')+" } } @media (min-width: 1200px){ #popupOverlay-"+_vm._s(_vm.node.uuid)+" { "+_vm._s(_vm.node.props.desktop.display == 'none' ? `display: none !important;` : '')+" } } ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }