var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"button-contact-vr"},[_c('div',{staticClass:"gom-all-in-one"},_vm._l((_vm.editor?.popups.filter(
          (x) => x.componentName == 'FloatingButton' && x.props.position == 'bottom-left'
        )),function(node){return _c('Node',{key:node.uuid,attrs:{"node":node,"editor":_vm.editor,"screen":_vm.screen}})}),1)]),_c('div',{staticClass:"button-contact-vr right-bottom"},[_c('div',{staticClass:"gom-all-in-one"},_vm._l((_vm.editor?.popups.filter(
          (x) => x.componentName == 'FloatingButton' && x.props.position == 'bottom-right'
        )),function(node){return _c('Node',{key:node.uuid,attrs:{"node":node,"editor":_vm.editor,"screen":_vm.screen}})}),1)]),_c('div',{staticClass:"button-contact-vr left-top"},[_c('div',{staticClass:"gom-all-in-one"},_vm._l((_vm.editor?.popups.filter(
          (x) => x.componentName == 'FloatingButton' && x.props.position == 'top-left'
        )),function(node){return _c('Node',{key:node.uuid,attrs:{"node":node,"editor":_vm.editor,"screen":_vm.screen}})}),1)]),_c('div',{staticClass:"button-contact-vr right-top"},[_c('div',{staticClass:"gom-all-in-one"},_vm._l((_vm.editor?.popups.filter(
          (x) => x.componentName == 'FloatingButton' && x.props.position == 'top-right'
        )),function(node){return _c('Node',{key:node.uuid,attrs:{"node":node,"editor":_vm.editor,"screen":_vm.screen}})}),1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }