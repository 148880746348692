var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"label-position":"top","size":"mini","model":_vm.elementProps}},[_c('div',{staticClass:"d-flex flex-column py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Margin")]),_c('Dimension',{staticClass:"mt-2",attrs:{"units":['px', 'em', 'rem'],"unit":_vm.elementProps[_vm.screen].margin_unit || 'px',"primaryColor":_vm.primaryColor,"data":_vm.elementProps[_vm.screen],"items":[
        {
          key: 'margin_top',
          title: 'Top',
          disabled: _vm.getValueDisabled('margin_top')
        },
        {
          key: 'margin_right',
          title: 'Right',
          disabled: _vm.getValueDisabled('margin_right')
        },
        {
          key: 'margin_bottom',
          title: 'Bottom',
          disabled: _vm.getValueDisabled('margin_bottom')
        },
        {
          key: 'margin_left',
          title: 'Left',
          disabled: _vm.getValueDisabled('margin_left')
        }
      ],"changeValue":(name, $event) => _vm.updateProps(_vm.screen, name, $event),"changeUnit":($event) => _vm.updateProps(_vm.screen, 'margin_unit', $event)}})],1),_c('div',{staticClass:"d-flex flex-column py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Padding")]),_c('Dimension',{staticClass:"mt-2",attrs:{"units":['px', 'em', 'rem'],"unit":_vm.elementProps[_vm.screen].padding_unit || 'px',"primaryColor":_vm.primaryColor,"data":_vm.elementProps[_vm.screen],"minValue":0,"items":[
        {
          key: 'padding_top',
          title: 'Top',
          disabled: _vm.getValueDisabled('padding_top')
        },
        {
          key: 'padding_right',
          title: 'Right',
          disabled: _vm.getValueDisabled('padding_right')
        },
        {
          key: 'padding_bottom',
          title: 'Bottom',
          disabled: _vm.getValueDisabled('padding_bottom')
        },
        {
          key: 'padding_left',
          title: 'Left',
          disabled: _vm.getValueDisabled('padding_left')
        }
      ],"changeValue":(name, $event) => _vm.updateProps(_vm.screen, name, $event),"changeUnit":($event) => _vm.updateProps(_vm.screen, 'padding_unit', $event)}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }