<template>
    <el-form label-position="top" size="mini" :model="elementProps">
      <div class="d-flex flex-column py-2">
        <span class="setting-title">Image</span>
        <Upload
          :value="elementProps.image"
          v-bind:changeValue="
            ($event) => updateProps('image', $event)
          "
        />
      </div>
      <div class="d-flex flex-column py-2">
        <span class="setting-title">Image Source</span>
        <el-input
          class="mt-2"
          placeholder="Link"
          :value="elementProps.image"
          @input="updateProps('image', $event)"
          size="medium"
        />
      </div>
    </el-form>
  </template>
    
    <script>
  import mixin from "../../../elements/styleSettings/mixin";
  import Select from "../../../elements/common/Select.vue";
  import Slider from "../../../elements/common/Slider.vue";
  import ColorPicker from "../../../elements/common/ColorPicker.vue";
  import Upload from "../../../elements/common/Upload.vue";
  
  export default {
    mixins: [mixin],
    components: { Select, Slider, ColorPicker, Upload },
    methods: {
      updateProps(name, value) {
        if (value == this.elementProps[name]) return;
        this.elementPropsSetter({ [name]: value });
      },
      updateMixStyle(key, value, object) {
        const style = this.getStyle(object);
        this.setStyle(object, {
          ...style,
          [key]: value,
        });
      },
    },
  };
  </script>
    
    <style scoped>
  </style>
    