<template>
  <div label-position="top" size="small" :model="elementProps">
    <div class="d-flex flex-column py-2">
      <span class="setting-title">Success Message</span>
      <Input 
        class="mt-2"
        type="textarea"
        :rows="4"
        :value="elementProps.success_msg"
        :primaryColor="primaryColor"
        v-bind:changeValue="($event) => updateProps('success_msg', $event)"
      />
    </div>
    <div class="d-flex flex-column py-2">
      <span class="setting-title">Success Color</span>
      <ColorPicker
        class="mt-2"
        :value="elementProps.success_color"
        v-bind:changeColor="($event) => updateProps('success_color', $event)"
        :primaryColor="primaryColor"
      />
    </div>
  </div>
</template>

<script>
import mixin from "../../../elements/styleSettings/mixin";
import ColorPicker from "../../../elements/common/ColorPicker.vue";

export default {
  mixins: [mixin],
  components: {ColorPicker},
  methods: {
    updateProps(name, value) {
      if(value ==  this.elementProps[name]) return;

      this.elementPropsSetter({ [name]: value });
    },
    updateMixStyle(key, value, object) {
      const style = this.getStyle(object);
      this.setStyle(object, {
        ...style,
        [key]: value,
      });
    },
  },
};
</script>

<style scoped>
</style>
