var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"label-position":"top","size":"medium","model":_vm.elementProps}},[_c('div',{staticClass:"d-flex flex-column py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Shape")]),_c('ToggleButton',{attrs:{"primaryColor":_vm.primaryColor,"value":_vm.elementProps.shape,"changeValue":($event) => _vm.updateProps('shape', $event),"options":[
        { value: 'rect', label: 'Rect' },
        { value: 'pill', label: 'Pill' },
      ]}})],1),_c('div',{staticClass:"d-flex flex-column py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Color")]),_c('Select',{staticClass:"mt-2",attrs:{"primaryColor":_vm.primaryColor,"value":_vm.elementProps.color,"changeValue":($event) => _vm.updateProps('color', $event),"options":[
        {
          value: 'gold',
          label: 'Gold',
        },
        {
          value: 'blue',
          label: 'Blue',
        },
        {
          value: 'silver',
          label: 'Silver',
        },
        {
          value: 'white',
          label: 'White',
        },
        {
          value: 'black',
          label: 'Black',
        },
      ]}})],1),_c('div',{staticClass:"d-flex flex-column py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Label")]),_c('Select',{staticClass:"mt-2",attrs:{"primaryColor":_vm.primaryColor,"value":_vm.elementProps.label,"changeValue":($event) => _vm.updateProps('label', $event),"options":[
        {
          value: 'paypal',
          label: 'Paypal',
        },
        {
          value: 'checkout',
          label: 'Checkout',
        },
        {
          value: 'buynow',
          label: 'Buy Now',
        },
        {
          value: 'pay',
          label: 'Pay',
        },
      ]}})],1),_c('div',{staticClass:"d-flex flex-column py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Height")]),_c('Slider',{staticClass:"mt-2",attrs:{"primaryColor":_vm.primaryColor,"value":_vm.elementProps.height,"min":25,"max":55,"step":1,"changeValue":($event) => _vm.updateProps('height', $event),"unit":"px","onlyInteger":true}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }