<template>
  <el-form label-position="top" size="medium" :model="elementProps">
    <div class="d-flex flex-column py-2">
      <span class="setting-title">Content</span>
      <el-input 
        :value="elementProps.content"
        class="input-with-select mt-2"
        @input="updateCommonProps('content', $event)"
        size="medium"
      />
    </div>

    <div class="d-flex align-items-center justify-content-between py-2">
      <span class="setting-title">Show copy</span>
      <el-switch
        :value="elementProps.show_copy"
        :active-color="primaryColor"
        inactive-color="#949494"
        @change="updateCommonProps('show_copy', $event)"
      />
    </div>
  </el-form>
</template>

<script>
import mixin from "../../../elements/styleSettings/mixin";
import ColorPicker from "../../../elements/common/ColorPicker.vue";
import Slider from "../../../elements/common/Slider.vue";
import ToggleButton from "../../../elements/common/ToggleButton.vue";
import Select from "../../../elements/common/Select.vue";
import Node from "@/core/Node";
import { makeId } from "@/constants";

export default {
  mixins: [mixin],
  components: {ColorPicker, Slider, ToggleButton, Select},
  props: {
    node: Node,
    primaryColor: String,
    screen: String,
    activeFonts: Array,
  },
  data() {
    return {
      fontKey: makeId(20)
    }
  },
  watch: {
    activeFonts: function (newValue, oldValue) {
      this.fontKey = makeId(20)
    }
  },
  methods: {
    updateCommonProps(name, value) {
      if(value ==  this.elementProps[name]) return;
      this.elementPropsSetter({ [name]: value });
    },
  },
};
</script>

<style scoped>
</style>
