import { rgbaToHex as convertRgbaToHex } from '@/constants';
export default {
  props: {
    elementStyle: Object,
  },
  methods: {
    getValue(name, screen, defaultValue) {
      if (["padding_top", "padding_bottom", "padding_left", "padding_right",
        "margin_top", "margin_bottom", "margin_left", "margin_right",].includes(name)) {
        defaultValue = ""
      } else if (name == "font_style") {
        defaultValue = []
      }
      if (Object.keys(this._props[screen] || {}).includes(name)) {
        return this._props[screen][name] !== undefined ? this._props[screen][name] : (defaultValue != undefined ? defaultValue : 0)
      }
      return this._props['desktop'][name] !== undefined ? this._props['desktop'][name] : (defaultValue != undefined ? defaultValue : 0)
      // return this._props['desktop'][name] || defaultValue || 0
    },
    getDisplayValue(screen) {
      const style = this.getValue('display', screen) == 'none' ? 'display: none !important;' : ''
      return style;
    },
    getValueLinked(name, value, defaultValue) {
      return (value == undefined)? (this._props['desktop'][name] || (defaultValue!=undefined?defaultValue:this._props['desktop'][name])): value;
    },
    getPaddingValue(screen) {
      const padding_top = isNaN(parseInt(this.getValue('padding_top', screen))) ? '' : `padding-top: ${this.getValue('padding_top', screen)}${this.getValue('padding_unit', screen, 'px')};`
      const padding_right = isNaN(parseInt(this.getValue('padding_right', screen))) ? '' : `padding-right: ${this.getValue('padding_right', screen)}${this.getValue('padding_unit', screen, 'px')};`
      const padding_bottom = isNaN(parseInt(this.getValue('padding_bottom', screen))) ? '' : `padding-bottom: ${this.getValue('padding_bottom', screen)}${this.getValue('padding_unit', screen, 'px')};`
      const padding_left = isNaN(parseInt(this.getValue('padding_left', screen))) ? '' : `padding-left: ${this.getValue('padding_left', screen)}${this.getValue('padding_unit', screen, 'px')};`
      return `
        ${padding_top}
        ${padding_right}
        ${padding_bottom}
        ${padding_left}
      `
    },
    getMarginValue(screen) {
      const margin_top = isNaN(parseInt(this.getValue('margin_top', screen))) ? '' : `margin-top: ${this.getValue('margin_top', screen)}${this.getValue('margin_unit', screen, 'px')};`
      const margin_right = isNaN(parseInt(this.getValue('margin_right', screen))) ? '' : `margin-right: ${this.getValue('margin_right', screen)}${this.getValue('margin_unit', screen, 'px')};`
      const margin_bottom = isNaN(parseInt(this.getValue('margin_bottom', screen))) ? '' : `margin-bottom: ${this.getValue('margin_bottom', screen)}${this.getValue('margin_unit', screen, 'px')};`
      const margin_left = isNaN(parseInt(this.getValue('margin_left', screen))) ? '' : `margin-left: ${this.getValue('margin_left', screen)}${this.getValue('margin_unit', screen, 'px')};`
      return `
        ${margin_top}
        ${margin_right}
        ${margin_bottom}
        ${margin_left}
      `
    },
    getBackgroundValue(screen) {
      const background_type = this.getValue('background_type', screen, "");
      const background_color = this.getValue('background_color', screen, "");
      const background_image = this.getValue('background_image', screen, "");
      if (background_type == 'gradient') {
        return `background-image: ${this.getValue('gradient_type', screen)}-gradient(${this.getValue('gradient_type', screen) == 'linear' ? this.getValue('gradient_angle', screen) + 'deg' : this.getValue('gradient_position', screen)}, ${this.getValue('gradient_color', screen)} ${this.getValue('gradient_location', screen)}%, ${this.getValue('gradient_second_color', screen)} ${this.getValue('gradient_second_location', screen)}%);`
      } else if (background_type == 'color_image' && background_color != "" && background_image == "") {
        return `background-color: ${this.getValue('background_color', screen)};`
      } else if (background_type == 'color_image' && (background_color == "" || background_color.includes('none')) && background_image != "") {
        return `
          background-image: url(${this.getValue('background_image', screen)});
          background-size: ${this.getValue('background_size', screen)};
          background-position: ${this.getValue('background_position', screen)};
          background-repeat: ${this.getValue('background_repeat', screen)};
          background-attachment: ${this.getValue('background_attachment', screen)};
        `
      } else if (background_type == 'color_image' && background_color != "" && background_image != "") {
        return `
          background: linear-gradient(${this.rgbaToHex(this.getValue('background_color', screen))}aa, ${this.rgbaToHex(this.getValue('background_color', screen))}aa), url(${this.getValue('background_image', screen)});
          background-size: ${this.getValue('background_size', screen)};
          background-position: ${this.getValue('background_position', screen)};
          background-repeat: ${this.getValue('background_repeat', screen)};
          background-attachment: ${this.getValue('background_attachment', screen)};
        `
      }
      return ""
    },
    getBorderValue(screen) {
      const border_style = this.getValue('border_style', screen) ? `border-style: ${this.getValue('border_style', screen)};` : '';
      const border_color = this.getValue('border_color', screen) ? `border-color: ${this.getValue('border_color', screen)};` : '';
      const border_top_width = isNaN(parseInt(this.getValue('border_top_width', screen))) ? '' : `border-top-width: ${this.getValue('border_top_width', screen)}px;`
      const border_right_width = isNaN(parseInt(this.getValue('border_right_width', screen))) ? '' : `border-right-width: ${this.getValue('border_right_width', screen)}px;`
      const border_bottom_width = isNaN(parseInt(this.getValue('border_bottom_width', screen))) ? '' : `border-bottom-width: ${this.getValue('border_bottom_width', screen)}px;`
      const border_left_width = isNaN(parseInt(this.getValue('border_left_width', screen))) ? '' : `border-left-width: ${this.getValue('border_left_width', screen)}px;`
      const style = `
        ${border_style}
        ${border_color}
        ${border_top_width}
        ${border_right_width}
        ${border_bottom_width}
        ${border_left_width}
      `
      return style;
    },
    getBorderRadiusValue(screen) {
      const top_left = isNaN(parseInt(this.getValue('border_top_left_radius', screen))) ? '' : `border-top-left-radius: ${this.getValue('border_top_left_radius', screen)}${this.getValue('border_radius_unit', screen, 'px')};` 
      const top_right = isNaN(parseInt(this.getValue('border_top_right_radius', screen))) ? '' : `border-top-right-radius: ${this.getValue('border_top_right_radius', screen)}${this.getValue('border_radius_unit', screen, 'px')};` 
      const bottom_left = isNaN(parseInt(this.getValue('border_bottom_left_radius', screen))) ? '' : `border-bottom-left-radius: ${this.getValue('border_bottom_left_radius', screen)}${this.getValue('border_radius_unit', screen, 'px')};` 
      const bottom_right = isNaN(parseInt(this.getValue('border_bottom_right_radius', screen))) ? '' : `border-bottom-right-radius: ${this.getValue('border_bottom_right_radius', screen)}${this.getValue('border_radius_unit', screen, 'px')};`
      return `
        ${top_left}
        ${top_right}
        ${bottom_left}
        ${bottom_right}
      `
    },
    getShadowValue(screen) {
      const shadow_style = this.getValue('shadow_style', screen, '')
      if (shadow_style == 'drop') {
        return `filter: drop-shadow(${this.getValue('shadow_x', screen, 0)}px ${this.getValue('shadow_y', screen, 0)}px ${this.getValue('shadow_blur', screen, 0)}px ${this.getValue('shadow_color', screen, 'rgb(255, 255, 255)')});`
      } else if (shadow_style == 'box') {
        return `box-shadow: ${this.getValue('shadow_type', screen, '') == 'inset' ? 'inset' : ''} ${this.getValue('shadow_x', screen, 0)}px ${this.getValue('shadow_y', screen, 0)}px ${this.getValue('shadow_spread', screen, 0)}px ${this.getValue('shadow_blur', screen, 0)}px ${this.getValue('shadow_color', screen, 'rgb(255, 255, 255)')};`
      }
      return ""

    },
    getOpacityValue(screen) {
      const opacity = this.getValue('opacity', screen) > 0 ? `opacity: ${this.getValue('opacity', screen)};` : ''
      return opacity;
    },
    getOtherStyle(screen) {
      return ""
    },
    rgbaToHex(color) {
      return convertRgbaToHex(color);
    }
  },
  computed: {
    elementCSS() {
      const style = {};
      const styleResolverMap = {
        'box-shadow': ({
          x, y, blur, spread, color,
        }) => `${x}px ${y}px ${blur}px ${spread}px ${color}`,
        'background-image': (url) => (url !== 'none'
          ? `url('${url}')`
          : url),
        'text-shadow': ({
          x, y, blur, color,
        }) => `${x}px ${y}px ${blur}px ${color}`,
      };

      Object.entries(this.elementStyle).forEach(([key, value]) => {
        if (styleResolverMap[key]) {
          style[key] = styleResolverMap[key](value);
        } else if (typeof value === 'number') {
          style[key] = `${value}px`;
        } else {
          style[key] = value;
        }
      });

      return style;
    },
    blockCSS() {
      const style = {};
      Object.entries(this.elementStyle).forEach(([key, value]) => {
        if (["margin-top",
          "margin-left",
          "margin-bottom",
          "margin-right"].indexOf(key) > -1) {
          if (typeof value === 'number') {
            style[key] = `${value}px`;
          } else {
            style[key] = value;
          }
        }

      });
      return style;
    },
    editorCSS() {
      const style = {};
      const styleResolverMap = {
        'box-shadow': ({
          x, y, blur, spread, color,
        }) => `${x}px ${y}px ${blur}px ${spread}px ${color}`,
        'background-image': (url) => (url !== 'none'
          ? `url('${url}')`
          : url),
        'text-shadow': ({
          x, y, blur, color,
        }) => `${x}px ${y}px ${blur}px ${color}`,
      };

      Object.entries(this.elementStyle).forEach(([key, value]) => {
        if (["margin-top",
          "margin-left",
          "margin-bottom",
          "margin-right"].indexOf(key) == -1) {

          if (styleResolverMap[key]) {
            style[key] = styleResolverMap[key](value);
          } else if (typeof value === 'number') {
            style[key] = `${value}px`;
          } else {
            style[key] = value;
          }
        }
      });
      return style;
    },
  },
};
