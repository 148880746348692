<template>
  <div
    data-label="Billing Address"
    data-key="billing_address"
    class="sg-wrap module"
    :id="`element-${node.uuid}`"
    @mouseover="isHovering=true" 
    @mouseout="isHovering=false"
    :class="{ 'dragging': dragging == node.uuid }"
  >
    <div 
      class="bl"
      :class="extra_class"
    >
      <div class="section-title" :data-spacing="`${spacing}px`">
        <span v-if="icon && icon !== '' && icon.startsWith('fa')" class="icon-wrapper">
          <i :class="`sg_icon fa ${icon}`"></i>
        </span>
        <span v-if="icon && icon !== '' && !icon.startsWith('fa')" v-html="icon" class="icon-wrapper" />
        <span v-if="label && label !== ''" class="section-text">{{ label }}</span>
      </div>
      <input
        id="radio-1"
        type="radio"
        name="billing_address"
        checked
        value="1"
      />
      <label for="radio-1" class="label-1 label-box radio-label">
        Same as shipping address
      </label>
      <input
        id="radio-2"
        type="radio"
        name="billing_address"
        value="2"
      />
      <label for="radio-2" class="label-2 label-box radio-label">
        Use a different billing address
      </label>
      <div class="content-box detail-address billing-info">
        <slot />
      </div>
    </div>
    <ToolBar
      ref="billing_address"
      :toolbar_id="`billing_address`"
      class="container-toolbar"
      :class="{ 'container-toolbar-show': isHovering, 'is-dragging': dragging == node.uuid }"
    ></ToolBar>

    <v-style>
      {{ custom_css }}

      #element-{{ node.uuid }} .section-title {
        gap: {{ spacing }}px;
      }

      @media (max-width: 767px){
        #element-{{ node.uuid }} .section-text {
          {{ getValue('font_weight','mobile') ? `font-weight: ${getValue('font_weight','mobile')};` : '' }}
          {{ getValue('font_size', 'mobile') > 0 ? `font-size: ${getValue('font_size','mobile')}px;` : '' }}
          font-family: {{ getValue('font_family','mobile') }};
          {{ getValue('text_transform','mobile') ? `text-transform: ${getValue('text_transform','mobile')};` : '' }}
          {{ getValue('color','mobile') ? `color: ${getValue('color','mobile')};` : '' }}
        }
        #element-{{ node.uuid }} {
          {{ getDisplayValue('mobile') }}
          {{ getMarginValue('mobile') }}
          {{ getPaddingValue('mobile') }}
          {{ getBackgroundValue('mobile') }}
          {{ getBorderValue('mobile') }}
          {{ getBorderRadiusValue('mobile') }}
          {{ getOpacityValue('mobile') }}
          {{ getShadowValue('mobile') }}
          {{ getOtherStyle('mobile') }}
        }
      } 
      @media (max-width: 991px) and (min-width: 768px){
        #element-{{ node.uuid }} .section-text {
          {{ getValue('font_weight','tablet') ? `font-weight: ${getValue('font_weight','tablet')};` : '' }}
          {{ getValue('font_size', 'tablet') > 0 ? `font-size: ${getValue('font_size','tablet')}px;` : '' }}
          font-family: {{ getValue('font_family','tablet') }};
          {{ getValue('text_transform','tablet') ? `text-transform: ${getValue('text_transform','tablet')};` : '' }}
          {{ getValue('color','tablet') ? `color: ${getValue('color','tablet')};` : '' }}
        }
        #element-{{ node.uuid }} {
          {{ getDisplayValue('tablet') }}
          {{ getMarginValue('tablet') }}
          {{ getPaddingValue('tablet') }}
          {{ getBackgroundValue('tablet') }}
          {{ getBorderValue('tablet') }}
          {{ getBorderRadiusValue('tablet') }}
          {{ getOpacityValue('tablet') }}
          {{ getShadowValue('tablet') }}
          {{ getOtherStyle('tablet') }}
        }
      } 
      @media (max-width: 1199px) and (min-width: 992px){
        #element-{{ node.uuid }} .section-text {
          {{ getValue('font_weight','laptop') ? `font-weight: ${getValue('font_weight','laptop')};` : '' }}
          {{ getValue('font_size', 'laptop') > 0 ? `font-size: ${getValue('font_size','laptop')}px;` : '' }}
          font-family: {{ getValue('font_family','laptop') }};
          {{ getValue('text_transform','laptop') ? `text-transform: ${getValue('text_transform','laptop')};` : '' }}
          {{ getValue('color','laptop') ? `color: ${getValue('color','laptop')};` : '' }}
        }
        #element-{{ node.uuid }} {
          {{ getDisplayValue('laptop') }}
          {{ getMarginValue('laptop') }}
          {{ getPaddingValue('laptop') }}
          {{ getBackgroundValue('laptop') }}
          {{ getBorderValue('laptop') }}
          {{ getBorderRadiusValue('laptop') }}
          {{ getOpacityValue('laptop') }}
          {{ getShadowValue('laptop') }}
          {{ getOtherStyle('laptop') }}
        }
      } 
      @media (min-width: 1200px){
        #element-{{ node.uuid }} .section-text {
          {{ getValue('font_weight','desktop') ? `font-weight: ${getValue('font_weight','desktop')};` : '' }}
          {{ getValue('font_size', 'desktop') > 0 ? `font-size: ${getValue('font_size','desktop')}px;` : '' }}
          font-family: {{ getValue('font_family','desktop') }};
          {{ getValue('text_transform','desktop') ? `text-transform: ${getValue('text_transform','desktop')};` : '' }}
          {{ getValue('color','desktop') ? `color: ${getValue('color','desktop')};` : '' }}
        }
        #element-{{ node.uuid }} {
          {{ getDisplayValue('desktop') }}
          {{ getMarginValue('desktop') }}
          {{ getPaddingValue('desktop') }}
          {{ getBackgroundValue('desktop') }}
          {{ getBorderValue('desktop') }}
          {{ getBorderRadiusValue('desktop') }}
          {{ getOpacityValue('desktop') }}
          {{ getShadowValue('desktop') }}
          {{ getOtherStyle('desktop') }}
        }
      }

    </v-style>
  </div>

</template>

<script>
import ExtraClass from "../otherSettings/ExtraClass.vue";
import LabelIcon from "../otherSettings/LabelIcon.vue";
import elementStyleMixin from "../elementStyleMixin";
import ToolBar from "../utils/ToolBar.vue";
import { designStyles, otherProps, otherStyles, actionTypes } from "@/constants";
import CustomCSS from "../styleSettings/CustomCSS.vue";
import Typography from "../styleSettings/Typography.vue";
import Animations from "../designSettings/Animations.vue";
import Spacing from "../designSettings/Spacing.vue";
import Background from "../designSettings/Background.vue";
import Border from "../designSettings/Border.vue";
import Corner from "../designSettings/Corner.vue";
import Shadow from "../designSettings/Shadow.vue";
import Opacity from "../designSettings/Opacity.vue";

export default {
  mixins: [elementStyleMixin],
  components: { ToolBar },
  props: {
    ...otherProps,
    label: String,
    icon: String,
    spacing: Number,
    component: String,
    extra_class:String,
    desktop: Object,
    laptop: Object,
    mobile: Object,
    tablet: Object,
    disabled: Boolean,
    billing_address: Number,
  },
  data() {
    return {
      isHovering: false,
    };
  },
  inject: ["node",'editor'],
  computed: {
    dragging: function () {
      return this.editor?.draggedNode?.uuid
    },
  },
  craft: {
    defaultProps: {
      ...otherStyles,
      disabled: true,
      extra_class: "",
      spacing: 10,
      billing_address: 1,
      label: 'Billing Address',
      icon: '<svg width="22" height="25" viewBox="0 0 22 25"><g fill="none" fill-rule="evenodd"><path d="M21.3 17.57142857C21.3 20.37142857 16.4 25 16.4 25s-4.9-4.62857143-4.9-7.42857143C11.5 14.65714286 14.01125 13 16.4 13c2.38875 0 4.9 1.65714286 4.9 4.57142857z" fill="#1A1A1A"></path><path d="M17.88492524 16.25245215c.82009968.78104732.82009968 2.04738171 0 2.82842903-.82009969.78104732-2.14975079.78104732-2.96985048 0-.82009968-.78104732-.82009968-2.04738171 0-2.82842903.82009969-.78104731 2.14975079-.78104731 2.96985048 0" stroke="#FFF" stroke-width="1.06666668" stroke-linecap="square"></path><path fill="#1A1A1A" fill-rule="nonzero" d="M18.8 0v10.54932175h-2V2H2v19h8.40890765v2H0V0z"></path><path d="M6.36484375 10.03129687c-.40708828 0-.73828125-.31542187-.73828125-.703125H4.15c0 .91659375.61725234 1.69814063 1.4765625 1.98839063V12.84375H7.103125v-1.5271406c.85931016-.29025 1.4765625-1.0717969 1.4765625-1.98843753 0-1.16310937-.99357891-2.109375-2.21484375-2.109375-.40708828 0-.73828125-.31542187-.73828125-.703125 0-.38770312.33119297-.703125.73828125-.703125s.73828125.31542188.73828125.70307813h1.4765625c0-.91659375-.61730156-1.69814063-1.4765625-1.98834375V3H5.6265625v1.52728125C4.76730156 4.81753125 4.15 5.59907812 4.15 6.51567187c0 1.16310938.99357891 2.109375 2.21484375 2.109375.40708828 0 .73828125.31542188.73828125.703125 0 .38770313-.33119297.703125-.73828125.703125zM4.15 14.625h6.3v1.40625h-6.3zM4.15 17.4375h6.3v1.40625h-6.3z" fill="#000" fill-rule="nonzero"></path></g></svg>',
      desktop: {
        ...designStyles,
        font_weight: '500',
        font_size: 16,
        font_family: 'inherit',
        color: 'rgb(0, 0, 0)',
        alignment: 'left',
        padding_top: 30,
        padding_bottom: 10,
        padding_left: 16,
        padding_right: 16
      },
      laptop: {
        ...designStyles,
      },
      mobile: {
        ...designStyles,
      },
      tablet: {
        ...designStyles,
      },
      typographyActives: ['font-weight', 'font-size', 'font-family', 'color'],
      elementStyle: {
        
      },
    },
    settings: {
      settings: {
        "Label & Icon": LabelIcon,
        "Typography": Typography,
        "Extra Class": ExtraClass,
        "Custom CSS": CustomCSS,
      },
      design: {
        Spacing: { Spacing, multi_screen: true },
        Background: { Background, multi_screen: true },
        Border: { Border, multi_screen: true },
        Corner: { Corner, multi_screen: true },
        Shadow: { Shadow, multi_screen: true },
        Opacity: { Opacity, multi_screen: true },
      },
      others: {
        Animations: { Animations, actions: actionTypes }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  width: 100%;
  box-sizing: border-box;
  white-space: pre-wrap;
}
</style>
