<template>
  <el-form label-position="top" size="mini" :model="elementProps">
    <div class="d-flex flex-column py-2">
      <span class="setting-title">Effect</span>
      <Select
        class="mt-2"
        :primaryColor="primaryColor"
        :value="elementProps.effect"
        v-bind:changeValue="($event) => updateProps('effect', $event)"
        :options="[
          {
            value: 'popup-fade',
            label: 'Fade',
          },
          {
            value: 'popup-zoom',
            label: 'Zoom',
          },
          {
            value: 'popup-zoom-out',
            label: 'Zoom Out',
          },
          {
            value: 'popup-horizontal-move',
            label: 'Horizontal Move',
          },
          {
            value: 'popup-vertical-move',
            label: 'Vertical move',
          },
          {
            value: 'popup-unfold',
            label: '3D Unfold',
          },
          {
            value: 'none',
            label: 'None',
          },
        ]"
      />
    </div>
  </el-form>
</template>

<script>
import mixin from "../../../elements/styleSettings/mixin";
import Select from "../../../elements/common/Select.vue";

export default {
  mixins: [mixin],
  components: {
    Select,
  },
  props: {
    primaryColor: String
  },
  methods: {
    updateProps(name, value) {
      if(value ==  this.elementProps[name]) return;

      this.elementPropsSetter({ [name]: value });
    },
  },
};
</script>
