<template>
  <div label-position="top" size="mini" :model="elementProps">
    <div class="py-2">
      <span class="setting-title">Icon Width</span>
      <Slider 
        class="mt-2"
        :value="elementProps.icon_width"
        :primaryColor="primaryColor"
        v-bind:changeValue="($event) => updateProps('icon_width', $event)"
        unit="px"
        :min="0"
        :max="150"
        :step="1"
        :onlyInteger="true"
      />
    </div>
  </div>
</template>

<script>
import mixin from '../../../elements/styleSettings/mixin';
import Slider from '../../../elements/common/Slider.vue';

export default {
  mixins: [mixin],
  components: {
    Slider,
  },
  props: {
    primaryColor: String
  },
  methods: {
    updateProps (name, value) {
      if(value ==  this.elementProps[name]) return;

      this.elementPropsSetter({ [name]: value });
    }
  },
};
</script>

<style scoped>
</style>
