<template>
  <div label-position="top" size="mini" :model="elementProps">
    <div class="d-flex align-items-center justify-content-between py-2">
      <span class="setting-title">Show menu</span>
      <el-switch
        :value="getValue('show_menu', elementProps[screen].show_menu)"
        :active-color="primaryColor"
        inactive-color="#949494"
        :active-value="true"
        :inactive-value="false"
        @change="updateProps('show_menu', $event)"
      />
    </div>
    <!-- <div class="d-flex justify-content-between align-items-center py-2" v-if="elementProps[screen].show_menu">
      <span class="setting-title">Items</span>
      <a class="cursor-pointer add-new-menu" @click="($event) => handleEditMenu($event)">
        Edit Menu
      </a>
    </div> -->
    <div class="d-flex flex-column py-2" v-if="elementProps[screen].show_menu">
      <div class="d-flex justify-content-between">
        <span class="setting-title">Items</span>
        <a class="cursor-pointer add-new-menu" @click="($event) => handleAdd($event)">
          Add new
        </a>
      </div>
      <draggable
        tag="el-collapse"
        v-model="myList"
        :component-data="collapseComponentData()"
        @end="listMoved"
        class="list-menu-items mt-2"
      >
        <el-collapse-item
          v-for="item in elementProps.items"
          :key="item.id"
          :name="item.id"
        >
          <template slot="title">
            <div class="d-flex align-items-center justify-content-between w-100">
              <span class="unset-lineheight">{{ item.label }}</span>
              <div class="d-flex">
                <div class="tab-action" @click="duplicate(item.id, $event)">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" stroke-width="1" stroke="#919191" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <rect x="8" y="8" width="12" height="12" rx="2"></rect>
                    <path d="M16 8v-2a2 2 0 0 0 -2 -2h-8a2 2 0 0 0 -2 2v8a2 2 0 0 0 2 2h2"></path>
                  </svg>
                </div>
                <div class="tab-action" @click="remove(item.id, $event)">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" stroke-width="1" stroke="#919191" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <line x1="4" y1="7" x2="20" y2="7"></line>
                    <line x1="10" y1="11" x2="10" y2="17"></line>
                    <line x1="14" y1="11" x2="14" y2="17"></line>
                    <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"></path>
                    <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"></path>
                  </svg>
                </div>
              </div>
            </div>
          </template>
          <div class="d-flex flex-column py-1">
            <span class="setting-title">Label</span>
            <Input 
              class="mt-1"
              type="text"
              :value="getItemData('label', item.id)"
              :primaryColor="primaryColor"
              v-bind:changeValue="($event) => setItemData('label', item.id, $event)"
            />
          </div>
          <div class="d-flex flex-column py-1">
            <span class="setting-title">Href</span>
            <div class="position-relative mt-1">
              <Input
                type="text"
                :value="getItemData('href', item.id)"
                :primaryColor="primaryColor"
                v-bind:changeValue="($event) => setItemData('href', item.id, $event)"
              />
              <div class="cursor-pointer select-product" @click="() => openModalProduct(item.id)">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" stroke-width="1.5" stroke="#ffffff" fill="none" stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M3 12l3 0"></path>
                  <path d="M12 3l0 3"></path>
                  <path d="M7.8 7.8l-2.2 -2.2"></path>
                  <path d="M16.2 7.8l2.2 -2.2"></path>
                  <path d="M7.8 16.2l-2.2 2.2"></path>
                  <path d="M12 12l9 3l-4 2l-2 4l-3 -9"></path>
                </svg>
              </div>
            </div>
          </div>
          <div class="d-flex flex-column py-1">
            <span class="setting-title">Color</span>
            <ColorPicker
              class="mt-2"
              :value="getItemData('color', item.id)"
              v-bind:changeColor="($event) => setItemData('color', item.id, $event)"
              :primaryColor="primaryColor"
            />
          </div>
          <div class="d-flex flex-column py-1">
            <div class="d-flex align-items-center justify-content-between">
              <span class="setting-title">Submenu</span>
              <a class="add-new-menu" @click="($event) => handleAddSubmenu($event, item.id)">Add submenu</a>
            </div>
            <draggable
              v-model="myList"
              tag="el-collapse"
              :component-data="submenuComponentData()"
              class="list-sub-items mt-2"
            >
              <el-collapse-item
                v-for="child in item.items"
                :key="child.id"
                :name="child.id"
              >
                <template slot="title">
                  <div class="d-flex align-items-center justify-content-between w-100">
                    <span class="unset-lineheight">{{ child.label }}</span>
                    <div class="d-flex">
                      <div class="tab-action" @click="duplicate_child(item.id, child.id, $event)">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" stroke-width="1" stroke="#919191" fill="none" stroke-linecap="round" stroke-linejoin="round">
                          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                          <rect x="8" y="8" width="12" height="12" rx="2"></rect>
                          <path d="M16 8v-2a2 2 0 0 0 -2 -2h-8a2 2 0 0 0 -2 2v8a2 2 0 0 0 2 2h2"></path>
                        </svg>
                      </div>
                      <div class="tab-action" @click="remove_child(item.id, child.id, $event)">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" stroke-width="1" stroke="#919191" fill="none" stroke-linecap="round" stroke-linejoin="round">
                          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                          <line x1="4" y1="7" x2="20" y2="7"></line>
                          <line x1="10" y1="11" x2="10" y2="17"></line>
                          <line x1="14" y1="11" x2="14" y2="17"></line>
                          <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"></path>
                          <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"></path>
                        </svg>
                      </div>
                    </div>
                  </div>
                </template>
                <div class="d-flex flex-column py-1">
                  <span class="setting-title">Label</span>
                  <el-input
                    class="mt-1"
                    :value="getItemChildData('label', item.id, child.id)"
                    @input="setItemChildData('label', item.id, child.id, $event)"
                    size="medium"
                  />
                </div>
                <div class="d-flex flex-column py-1">
                  <span class="setting-title">Href</span>
                  <el-input
                    class="mt-1"
                    :value="getItemChildData('href', item.id, child.id)"
                    @input="setItemChildData('href', item.id, child.id, $event)"
                    size="medium"
                  />
                </div>
                <div class="d-flex flex-column py-1">
                  <span class="setting-title">Color</span>
                  <ColorPicker
                    class="mt-2"
                    :value="getItemChildData('color', item.id, child.id)"
                    v-bind:changeColor="($event) => setItemChildData('color', item.id, child.id, $event)"
                    :primaryColor="primaryColor"
                  />
                </div>
              </el-collapse-item>
            </draggable>
          </div>
        </el-collapse-item>
      </draggable>
    </div>
    <div class="d-flex flex-column py-2" v-if="elementProps[screen].show_menu">
      <span class="setting-title">Alignment</span>
      <TabIcon
        class="mt-2"
        :primaryColor="primaryColor"
        :value="getValue('alignment',elementProps[screen].alignment)"
        v-bind:changeValue="($event) => updateProps('alignment', $event)"
        :options="[
          { value: 'flex-start', icon: 'align-left',tooltip: 'Left' },
          { value: 'center', icon: 'align-center',tooltip: 'Center' },
          { value: 'flex-end', icon: 'align-right', tooltip: 'Right' },
        ]"
      />
    </div>
    <div class="d-flex flex-column py-2" v-if="elementProps[screen].show_menu">
      <span class="setting-title">Menu hover background</span>
      <ColorPicker
        class="mt-2"
        :value="getValue('menu_hover_color', elementProps[screen].menu_hover_color)"
        v-bind:changeColor="($event) => updateProps('menu_hover_color', $event)"
        :primaryColor="primaryColor"
      />
    </div>
    <div class="d-flex flex-column py-2" v-if="elementProps[screen].show_menu">
      <span class="setting-title">Submenu background</span>
      <ColorPicker
        class="mt-2"
        :value="getValue('menu_background_color', elementProps[screen].menu_background_color)"
        v-bind:changeColor="($event) => updateProps('menu_background_color', $event)"
        :primaryColor="primaryColor"
      />
    </div>
    <div class="d-flex flex-column py-2" v-if="elementProps[screen].show_menu">
      <span class="setting-title">Menu Padding</span>
      <Dimension
        class="mt-2"
        :units="['px']"
        unit="px"
        :primaryColor="primaryColor"
        :data="elementProps[screen]"
        :items="[
          {
            key: 'menu_padding_top',
            title: 'Top',
          },
          {
            key: 'menu_padding_right',
            title: 'Right',
          },
          {
            key: 'menu_padding_bottom',
            title: 'Bottom',
          },
          {
            key: 'menu_padding_left',
            title: 'Left',
          }
        ]"
        v-bind:changeValue="(name, $event) => updateProps(name, $event)"
      />
    </div>
    <div class="d-flex flex-column py-2" v-if="elementProps[screen].show_menu">
      <span class="setting-title">Font Size</span>
      <Slider 
        class="mt-2"
        :value="getValue('font_size', elementProps[screen].font_size)"
        :primaryColor="primaryColor"
        :min="0"
        :max="100"
        :step="1"
        unit="px"
        v-bind:changeValue="($event) => updateProps('font_size', parseInt($event))"
        :onlyInteger="true"
      />
    </div>
    <div class="d-flex flex-column py-2" v-if="elementProps[screen].show_menu">
      <span class="setting-title">Font Weight</span>
      <Select
        class="mt-2"
        :primaryColor="primaryColor"
        :value="getValue('font_weight', elementProps[screen].font_weight)"
        v-bind:changeValue="($event) => updateProps('font_weight', $event)"
        :options="[
          {
            value: '100',
            label: 'Thin 100',
          },
          {
            value: '200',
            label: 'Semi Thin 200',
          },
          {
            value: '300',
            label: 'Light 300',
          },
          {
            value: '400',
            label: 'Regular 400',
          },
          {
            value: '500',
            label: 'Medium 500',
          },
          {
            value: '600',
            label: 'Semi Bold 600',
          },
          {
            value: '700',
            label: 'Bold 700',
          },
          {
            value: '800',
            label: 'Super Bold 800',
          },
          {
            value: '900',
            label: 'Boldest 900',
          }
        ]"
      />
    </div>
    <div class="d-flex flex-column py-2" v-if="elementProps[screen].show_menu">
      <span class="setting-title">Font Style</span>
      <ToggleButton
        class="mt-2"
        :primaryColor="primaryColor"
        :value="getValue('font_style', elementProps[screen].font_style)"
        v-bind:changeValue="($event) => updateProps('font_style', $event)"
        :options="[
          {
            label: 'Normal',
            value: 'normal',
          },
          {
            label: 'Italic',
            value: 'italic',
          },
        ]"
      />
    </div>
    <div class="d-flex flex-column py-2" v-if="elementProps[screen].show_menu">
      <span class="setting-title">Text Transform</span>
      <Select
        class="mt-2"
        :primaryColor="primaryColor"
        :value="getValue('text_transform', elementProps[screen].text_transform)"
        v-bind:changeValue="($event) => updateProps('text_transform', $event)"
        :options="[
          {
            value: 'none',
            label: 'None',
          },
          {
            value: 'uppercase',
            label: 'Uppercase',
          },
          {
            value: 'lowercase',
            label: 'Lowercase',
          },
          {
            value: 'capitalize',
            label: 'Capitalize',
          },
        ]"
      />
    </div>
  </div>
</template>

<script>
import mixin from '../../../elements/styleSettings/mixin';
import Slider from '../../../elements/common/Slider.vue';
import Upload from '../../../elements/common/Upload.vue';
import InputWithSelect from '../../../elements/common/InputWithSelect.vue';
import Select from '../../../elements/common/Select.vue';
import TabIcon from '../../../elements/common/TabIcon.vue';
import ToggleButton from '../../../elements/common/ToggleButton.vue';
import ColorPicker from '../../../elements/common/ColorPicker.vue';
import Dimension from '../../../elements/common/Dimension.vue';
import Input from '../../../elements/common/Input.vue';
import draggable from "vuedraggable";
import { makeId } from '@/constants';
import Node from "@/core/Node";

export default {
  mixins: [mixin],
  props: {
    node: Node,
    screen: String,
    primaryColor: String,
    product: Object,
  },
  watch: {
    product: function (newValue, oldValue) {
      const value = `/products/${newValue.slug}`
      this.setItemData('href', this.current_id, value);
      this.current_id = null
    }
  },
  computed: {
    myList: {
      get() {
        return this.elementProps.items
      },
      set(value) {
        this.elementPropsSetter({ items: JSON.parse(JSON.stringify(value)) })
      }
    },
  },
  components: {
    Slider, Upload, InputWithSelect, Input, draggable, TabIcon, Select, ToggleButton, ColorPicker, Dimension
  },
  methods: {
    openModalProduct(id) {
      this.$bus.emit("showProductPicker", this.node);
      this.current_id = id;
    },
    handleEditMenu(e) {
      this.$bus.emit("showMenuModal", this.node);
    },
    handleAdd(e) {
      e.stopPropagation()
      const items = Object.assign([], this.elementProps.items)
      items.push({ id: makeId(6), label: 'New Menu', href: '#', items: [], color: '#000' })
      this.elementPropsSetter({ items });
    },
    handleAddSubmenu(e, id) {
      e.stopPropagation()
      const items = Object.assign([], this.elementProps.items)
      const item = items.find(e => e.id == id)
      item.items.push({ id: makeId(6), label: 'New Submenu', href: '#', color: '#000' })
      this.elementPropsSetter({ items });
    },
    getItemData(key, id) {
      const item = this.elementProps.items.find(e => e.id == id)
      return item[key]
    },
    setItemData(key, id, value) {
      const items = Object.assign([], this.elementProps.items)
      const item_data = items.find(e => e.id == id)
      item_data[key] = value
      this.elementPropsSetter({ items });
    },
    getItemChildData(key, id, child_id) {
      const item = this.elementProps.items.find(e => e.id == id)
      const child = item.items.find(e => e.id == child_id)
      return child[key]
    },
    setItemChildData(key, id, child_id, value) {
      const items = Object.assign([], this.elementProps.items)
      const item_data = items.find(e => e.id == id)
      const child = item_data.items.find(e => e.id == child_id)
      child[key] = value
      this.elementPropsSetter({ items });
    },
    collapseComponentData() {
      return {
        on: {
          input: this.inputChanged,
        },
        props: {
          value: this.activeNames
        }
      }
    },
    submenuComponentData() {
      return {
        on: {
          input: this.inputChangedSubmenu,
        },
        props: {
          value: this.submenuActiveNames
        }
      }
    },
    inputChanged(val) {
      this.activeNames = val;
    },
    inputChangedSubmenu(val) {
      this.submenuActiveNames = val;
    },
    updateProps(name, value) {
      const screen = this.$props.screen
      const data_screen = Object.assign({}, this.elementProps[screen])
      if(value ==  data_screen[name]) return;
      data_screen[name] = value
      this.elementPropsSetter({ [screen]: data_screen });
    },
    updateCommonProps(name, value) {
      this.elementPropsSetter({ [name]: value });
    },
    duplicate(id, event) {
      event.stopPropagation()
      const items = Object.assign([], this.elementProps.items)
      const item = this.elementProps.items.find(e => e.id == id)
      if (item) {
        const childs = item.items.map(e => ({id: makeId(6), label: e.label, href: e.href, color: e.color }))
        const item_copy = {
          id: makeId(6),
          label: item.label,
          href: item.href,
          items: childs,
          color: item.color
        }
        items.push(item_copy)
        this.elementPropsSetter({ items });
      }
    },
    remove(id, event) {
      event.stopPropagation()
      const items = this.elementProps.items.filter(e => e.id != id)
      this.elementPropsSetter({ items });
    },
    duplicate_child(id, child_id, event) {
      event.stopPropagation()
      const items = Object.assign([], this.elementProps.items)
      const item = this.elementProps.items.find(e => e.id == id)
      if (item) {
        const child = item.items.find(e => e.id == child_id)
        if (child) {
          const item_copy = {
            id: makeId(6),
            label: child.label,
            href: child.href,
            color: child.color
          }
          item.items.push(item_copy)
        }
        this.elementPropsSetter({ items });
      }
    },
    remove_child(id, child_id, event) {
      event.stopPropagation()
      const items = Object.assign([], this.elementProps.items)
      const item = items.find(e => e.id == id)
      if (item) {
        const childs = item.items.filter(e => e.id != child_id)
        item.items = childs
      }
      this.elementPropsSetter({ items });
    },
    listMoved() {
    }
  },
  data() {
    return {
      activeNames: [],
      submenuActiveNames: [],
      current_id: null
    }
  }
};
</script>

<style lang="scss">
.list-menu-items {
  .el-collapse-item  {
    background-color: #444444 !important;
    margin-bottom: 8px;
  }
  .tab-action {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background: #555555;
    }
  }
}

.list-sub-items {
  .el-collapse-item  {
    background-color: #555555 !important;
    margin-bottom: 8px;
  }
  .tab-action {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background: #666666;
    }
  }
}

.add-new-menu {
  // width: fit-content;
  padding: 8px 12px;
  // height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  background-color: #444;
  color: #fff;
  border-radius: 8px;
}

.unset-lineheight {
  line-height: 15px !important;
  max-height: 45px;
  overflow: hidden;
}

.select-product {
  background-color: #222222;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  position: absolute;
  top: 50%;
  right: -8px;
  transform: translate(-50%, -50%);
}
</style>
