var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"label-position":"top","size":"mini","model":_vm.elementProps}},[_c('div',{staticClass:"d-flex flex-column py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Heading Type")]),_c('ToggleButton',{staticClass:"mt-2",attrs:{"value":_vm.elementProps.headingType,"changeValue":_vm.updateHeadingType,"primaryColor":_vm.primaryColor,"options":[
        {
          label: 'H1',
          value: 'h1',
        },
        {
          label: 'H2',
          value: 'h2',
        },
        {
          label: 'H3',
          value: 'h3',
        },
      ]}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }