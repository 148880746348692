/* eslint-disable import/no-extraneous-dependencies */
import Vue from 'vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue';
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import locale from 'element-ui/lib/locale/lang/en'

import Bus from './components/EventBus'
import vueTabz from 'vue-tabz'
import VueToastr from "vue-toastr";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserSecret } from '@fortawesome/free-solid-svg-icons'
import { Plugin } from 'vue-fragment'

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import router from './router'
import VueCookies from 'vue-cookies'

// default options config: { expires: '1d', path: '/', domain: '', secure: '', sameSite: 'Lax' }
Vue.use(VueCookies, { expires: '30d', domain: process.env.VUE_APP_DOMAIN })
Vue.use(DatePicker);

library.add(faUserSecret)
Vue.use(Plugin)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.use(vueTabz);
Vue.use(Bus)
Vue.use(ElementUI, { locale });
Vue.use(VueToastr, {
  defaultTimeout: 3000,
	defaultProgressBar: false,
	defaultProgressBarValue: 0,
	defaultType: "error",
	defaultPosition: "toast-top-center",
	defaultCloseOnHover: false,
	defaultStyle: { "background-color": "red" }
});
Vue.component("v-script", {
	render(createElement) {
	  return createElement("script", this.$slots.default);
	},
});
  

Vue.config.productionTip = false;


// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

new Vue({
	router,
  render: (h) => h(App),
}).$mount('#app');
