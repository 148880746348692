<template>
  <div
    data-label="(P) Variants"
    data-key="(p) variants"
    class="sg-wrap module"
    data-parent="product"
    :id="`element-${node.uuid}`"
    @mouseover="isHovering = true"
    @mouseout="isHovering = false"
    :data-product-uuid="product.product_node_uuid"
    :class="{ 'dragging': dragging == node.uuid }"
    :data-variant-type="variant_type"
    :data-group-by="group_by"
    :data-pid="product.product_id"
    :data-blank-text="blank_text"
    :data-blank="blank_option?1:0"
    :data-option1="show_option_1 ? 1 : 0"
    :data-option2="show_option_2 ? 1 : 0"
    :data-option3="show_option_3 ? 1 : 0"
    :data-display-style="display_style"
  > 
    <div
      class="sg-module select"
      :class="extra_class"
      v-if="variant_type=='select'"
    >
      <div
        class="sg_variants-wrapper sg_row sg_row_no_tools sg_row-no-padding separately" 
        v-if="group_by=='separately'"
      >
        <div class="sg-column">
          <div
            class="sg-variant-item"
            :class="display_style == 'inline' ? 'sg_align-module sg_align-i-center' : ''" 
            v-for="variant in ((product.options || []).filter((element, index) => (index==0 && show_option_1 || index==1 && show_option_2 || index==2 && show_option_3)))" 
            :key="variant.variant_group_id"
          >
            <label class="variant-label">{{ variant.group_name }}</label>
            <select
              class="sg_variants single-option-selector single-option-selector-product seperately-select"
              :data-type="group_by"
              :data-variant-group-id="variant.variant_group_id"
            >
              <option value="blank" v-if="blank_option" class="sg_blank-option">{{ blank_text }}</option>
              <option 
                v-for="option in variant.variants"
                :data-id="option.variant_id" 
                :value="option.variant_id" 
                :key="option.variant_id"
              >
                {{ option.variant }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="sg_variants-wrapper sg_row sg_row_no_tools sg_row-no-padding default" v-if="group_by=='default'">
        <div class="sg_column">
          <select
            class="sg_variants single-option-selector single-option-selector-product default-select"
            :data-type="group_by"
          >
            <option value="blank" v-if="blank_option" class="sg_blank-option">{{blank_text}}</option>
            <option :value="[item[0]]" v-for="(item) in (Object.entries(product?.variantsJson||{}))" :key="item[0]">{{item[1]['variants_name']}}</option>
          </select>
        </div>
      </div>
    </div>
    
    <div 
      class="sg-module swatch"
      :class="extra_class"
      v-if="variant_type=='swatch'"
    >
      <div class="sg-column">
        <div
          class="sg-variant-item d-flex flex-column"
          v-for="variant in ((product.options || []).filter((element, index) => (index==0 && show_option_1 || index==1 && show_option_2 || index==2 && show_option_3)))" 
          :key="variant.variant_group_id"
        >
          <label class="variant-label">{{ variant.group_name }}</label>
          <div 
            class="variant-list"
            :class="`variant-group-${variant.variant_group_id}`"
          >
            <div 
              class="item variant-swatch"
              :data-node-uuid="node.uuid"
              v-for="(option, index) in variant.variants"
              :key="option.variant_id"
              :data-id="option.variant_id"
              :data-variant-group-id="variant.variant_group_id"
              :class="{active: index == 0}"
            >
              <p><span>{{ option.variant }}</span></p>
            </div>
          </div>
        </div>
      </div>
    </div>


    <ToolBar
      ref="(p)variants"
      :toolbar_id="`(p)variants`"
      class="container-toolbar"
      :class="{ 'container-toolbar-show': isHovering, 'is-dragging': dragging == node.uuid }"
    >
    </ToolBar>

    <v-style>
      {{ custom_css }}
      #element-{{ node.uuid }} .sg-module.select {
        display: {{ variant_type == 'select' ? 'block' : 'none' }};
      }
      #element-{{ node.uuid }} .sg-module.swatch {
        display: {{ variant_type == 'swatch' ? 'block' : 'none' }};
      }
      #element-{{ node.uuid }} .sg_variants-wrapper.separately {
        display: {{ variant_type == 'select' && group_by == 'separately' ? 'block' : 'none' }};
      }
      #element-{{ node.uuid }} .sg_variants-wrapper.default {
        display: {{ variant_type == 'select' && group_by == 'default' ? 'block' : 'none' }};
      }
      

      #element-{{ node.uuid }} .variant-list .item {
        margin-right: {{ swatch_distance }}px;
        margin-bottom: {{ swatch_margin_bottom }}px;
      }

      #element-{{ node.uuid }} .select label {
        {{ display_style == 'inline' ? `width: ${label_width}px;` : '' }}
        {{ display_style == 'inline' ? `text-align: left;` : '' }}
      }

      @media (max-width: 767px) {
        #element-{{ node.uuid }} {
          {{ getDisplayValue('mobile') }}
          {{ getMarginValue('mobile') }}
        } 
        #element-{{ node.uuid }} label {
          display: {{ show_label ? 'block' : 'none' }};
          margin-bottom: {{ getValue('label_margin_bottom','mobile') }}px;
          font-weight: {{ getValue('label_font_weight','mobile') }};
          {{ getValue('label_font_size', 'mobile') > 0 ? `font-size: ${getValue('label_font_size','mobile')}px;` : '' }}
          {{ getValue('label_color', 'mobile') ? `color: ${getValue('label_color','mobile')};` : '' }}
          line-height: {{ getValue('label_line_height','mobile') }}em;
          font-family: {{ getValue('label_font_family','mobile') }};
        }
        #element-{{ node.uuid }} .sg_variants {
          width: {{ getValue('select_width','mobile') }}{{ getValue('select_width_type','mobile') }};
          height: {{ getValue('select_height','mobile') }};
        }
        #element-{{ node.uuid }} .sg-variant-item {
          text-align: {{ getValue('alignment','mobile') }};
          margin-bottom: {{ getValue('select_margin_bottom','mobile') }}px;
        }
        #element-{{ node.uuid }} .variant-list .item p {
          width: {{ getValue('swatch_width','mobile') }}{{ getValue('swatch_width_type','mobile') }};
          line-height: {{ getHeight(getValue('swatch_height','mobile')) }};
          font-weight: {{ getValue('swatch_font_weight','mobile') }};
          {{ getValue('swatch_font_size', 'mobile') > 0 ? `font-size: ${getValue('swatch_font_size','mobile')}px;` : '' }}
          {{ getValue('swatch_color', 'mobile') ? `color: ${getValue('swatch_color','mobile')};` : '' }}
          font-family: {{ getValue('swatch_font_family','mobile') }};
          {{ getPaddingValue('mobile') }}
          {{ getBackgroundValue('mobile') }}
          {{ getBorderValue('mobile') }}
          {{ getBorderRadiusValue('mobile') }}
          {{ getOpacityValue('mobile') }}
          {{ getShadowValue('mobile') }}
          {{ getOtherStyle('mobile') }}
        }
        #element-{{ node.uuid }} .variant-list .item p span {
          visibility: {{ getValue('show_swatch_text','mobile') ? "visible" : 'hidden' }};
        }
        #element-{{ node.uuid }} .variant-list .item.active {
          border: {{ getValue('show_border_selected_swatch','mobile') ? `${getValue('selected_swatch_border_width','mobile')}px solid ${getValue('selected_swatch_border_color','mobile')}` : 'none' }};
          {{ getValue('border_top_left_radius','mobile') ? `border-top-left-radius: ${getValue('border_top_left_radius','mobile')}px;` : '' }}
          {{ getValue('border_top_right_radius','mobile') ? `border-top-right-radius: ${getValue('border_top_right_radius','mobile')}px;` : '' }}
          {{ getValue('border_bottom_left_radius','mobile') ? `border-bottom-left-radius: ${getValue('border_bottom_left_radius','mobile')}px;` : '' }}
          {{ getValue('border_bottom_right_radius','mobile') ? `border-bottom-right-radius: ${getValue('border_bottom_right_radius','mobile')}px;` : '' }}
        }
      } 
      @media (max-width: 991px) and (min-width: 768px) { 
        #element-{{ node.uuid }} {
          {{ getDisplayValue('tablet') }}
          {{ getMarginValue('tablet') }}
        } 
        #element-{{ node.uuid }} label {
          display: {{ show_label ? 'block' : 'none' }};
          margin-bottom: {{ getValue('label_margin_bottom','tablet') }}px;
          font-weight: {{ getValue('label_font_weight','tablet') }};
          line-height: {{ getValue('label_line_height','tablet') }}em;
          font-family: {{ getValue('label_font_family','tablet') }};
          {{ getValue('label_font_size', 'tablet') > 0 ? `font-size: ${getValue('label_font_size','tablet')}px;` : '' }}
          {{ getValue('label_color', 'tablet') ? `color: ${getValue('label_color','tablet')};` : '' }}
        }
        #element-{{ node.uuid }} .sg_variants {
          width: {{ getValue('select_width','tablet') }}{{ getValue('select_width_type','tablet') }};
          height: {{ getValue('select_height','tablet') }};
        }
        #element-{{ node.uuid }} .sg-variant-item {
          text-align: {{ getValue('alignment','tablet') }};
          margin-bottom: {{ getValue('select_margin_bottom','tablet') }}px;
        }
        #element-{{ node.uuid }} .variant-list .item p {
          width: {{ getValue('swatch_width','tablet') }}{{ getValue('swatch_width_type','tablet') }};
          line-height: {{ getHeight(getValue('swatch_height','tablet')) }};
          font-weight: {{ getValue('swatch_font_weight','tablet') }};
          {{ getValue('swatch_font_size', 'tablet') > 0 ? `font-size: ${getValue('swatch_font_size','tablet')}px;` : '' }}
          {{ getValue('swatch_color', 'tablet') ? `color: ${getValue('swatch_color','tablet')};` : '' }}
          font-family: {{ getValue('swatch_font_family','tablet') }};
          {{ getPaddingValue('tablet') }}
          {{ getBackgroundValue('tablet') }}
          {{ getBorderValue('tablet') }}
          {{ getBorderRadiusValue('tablet') }}
          {{ getOpacityValue('tablet') }}
          {{ getShadowValue('tablet') }}
          {{ getOtherStyle('tablet') }}
        }
        #element-{{ node.uuid }} .variant-list .item p span {
          visibility: {{ getValue('show_swatch_text','tablet') ? "visible" : 'hidden' }};
        }
        #element-{{ node.uuid }} .variant-list .item.active {
          border: {{ getValue('show_border_selected_swatch','tablet') ? `${getValue('selected_swatch_border_width','tablet')}px solid ${getValue('selected_swatch_border_color','tablet')}` : 'none' }};
          {{ getValue('border_top_left_radius','tablet') ? `border-top-left-radius: ${getValue('border_top_left_radius','tablet')}px;` : '' }}
          {{ getValue('border_top_right_radius','tablet') ? `border-top-right-radius: ${getValue('border_top_right_radius','tablet')}px;` : '' }}
          {{ getValue('border_bottom_left_radius','tablet') ? `border-bottom-left-radius: ${getValue('border_bottom_left_radius','tablet')}px;` : '' }}
          {{ getValue('border_bottom_right_radius','tablet') ? `border-bottom-right-radius: ${getValue('border_bottom_right_radius','tablet')}px;` : '' }}
        }
      } 
      @media (max-width: 1199px) and (min-width: 992px) { 
        #element-{{ node.uuid }} {
          {{ getDisplayValue('laptop') }}
          {{ getMarginValue('laptop') }}
        } 
        #element-{{ node.uuid }} label {
          display: {{ show_label ? 'block' : 'none' }};
          margin-bottom: {{ getValue('label_margin_bottom','laptop') }}px;
          font-weight: {{ getValue('label_font_weight','laptop') }};
          line-height: {{ getValue('label_line_height','laptop') }}em;
          font-family: {{ getValue('label_font_family','laptop') }};
          {{ getValue('label_font_size', 'laptop') > 0 ? `font-size: ${getValue('label_font_size','laptop')}px;` : '' }}
          {{ getValue('label_color', 'laptop') ? `color: ${getValue('label_color','laptop')};` : '' }}
        }
        #element-{{ node.uuid }} .sg_variants {
          width: {{ getValue('select_width','laptop') }}{{ getValue('select_width_type','laptop') }};
          height: {{ getValue('select_height','laptop') }};
        }
        #element-{{ node.uuid }} .sg-variant-item {
          text-align: {{ getValue('alignment','laptop') }};
          margin-bottom: {{ getValue('select_margin_bottom','laptop') }}px;
        }
        #element-{{ node.uuid }} .variant-list .item p {
          width: {{ getValue('swatch_width','laptop') }}{{ getValue('swatch_width_type','laptop') }};
          line-height: {{ getHeight(getValue('swatch_height','laptop')) }};
          font-weight: {{ getValue('swatch_font_weight','laptop') }};
          {{ getValue('swatch_font_size', 'laptop') > 0 ? `font-size: ${getValue('swatch_font_size','laptop')}px;` : '' }}
          {{ getValue('swatch_color', 'laptop') ? `color: ${getValue('swatch_color','laptop')};` : '' }}
          font-family: {{ getValue('swatch_font_family','laptop') }};
          {{ getPaddingValue('laptop') }}
          {{ getBackgroundValue('laptop') }}
          {{ getBorderValue('laptop') }}
          {{ getBorderRadiusValue('laptop') }}
          {{ getOpacityValue('laptop') }}
          {{ getShadowValue('laptop') }}
          {{ getOtherStyle('laptop') }}
        }
        #element-{{ node.uuid }} .variant-list .item p span {
          visibility: {{ getValue('show_swatch_text','laptop') ? "visible" : 'hidden' }};
        }
        #element-{{ node.uuid }} .variant-list .item.active {
          border: {{ getValue('show_border_selected_swatch','laptop') ? `${getValue('selected_swatch_border_width','laptop')}px solid ${getValue('selected_swatch_border_color','laptop')}` : 'none' }};
          {{ getValue('border_top_left_radius','laptop') ? `border-top-left-radius: ${getValue('border_top_left_radius','laptop')}px;` : '' }}
          {{ getValue('border_top_right_radius','laptop') ? `border-top-right-radius: ${getValue('border_top_right_radius','laptop')}px;` : '' }}
          {{ getValue('border_bottom_left_radius','laptop') ? `border-bottom-left-radius: ${getValue('border_bottom_left_radius','laptop')}px;` : '' }}
          {{ getValue('border_bottom_right_radius','laptop') ? `border-bottom-right-radius: ${getValue('border_bottom_right_radius','laptop')}px;` : '' }}
        }
      } 
      @media (min-width: 1200px){ 
        #element-{{ node.uuid }} {
          {{ getDisplayValue('desktop') }}
          {{ getMarginValue('desktop') }}
        } 
        #element-{{ node.uuid }} label {
          display: {{ show_label ? 'block' : 'none' }};
          margin-bottom: {{ desktop.label_margin_bottom }}px;
          font-weight: {{ desktop.label_font_weight }};
          {{ getValue('label_font_size', 'desktop') > 0 ? `font-size: ${getValue('label_font_size','desktop')}px;` : '' }}
          {{ getValue('label_color', 'desktop') ? `color: ${getValue('label_color','desktop')};` : '' }}
          line-height: {{ desktop.label_line_height }}em;
          font-family: {{ desktop.label_font_family }};
        }
        #element-{{ node.uuid }} .sg_variants {
          width: {{ desktop.select_width }}{{ desktop.select_width_type }};
          height: {{ desktop.select_height }};
        }
        #element-{{ node.uuid }} .sg-variant-item {
          text-align: {{ desktop.alignment }};
          margin-bottom: {{ desktop.select_margin_bottom }}px;
        }
        #element-{{ node.uuid }} .variant-list .item p {
          width: {{ desktop.swatch_width }}{{ desktop.swatch_width_type }};
          line-height: {{ getHeight(desktop.swatch_height) }};
          font-weight: {{ desktop.swatch_font_weight }};
          {{ getValue('swatch_font_size', 'desktop') > 0 ? `font-size: ${getValue('swatch_font_size','desktop')}px;` : '' }}
          {{ getValue('swatch_color', 'desktop') ? `color: ${getValue('swatch_color','desktop')};` : '' }}
          font-family: {{ desktop.swatch_font_family }};
          {{ getPaddingValue('desktop') }}
          {{ getBackgroundValue('desktop') }}
          {{ getBorderValue('desktop') }}
          {{ getBorderRadiusValue('desktop') }}
          {{ getOpacityValue('desktop') }}
          {{ getShadowValue('desktop') }}
          {{ getOtherStyle('desktop') }}
        }
        #element-{{ node.uuid }} .variant-list .item p span {
          visibility: {{ desktop.show_swatch_text ? "visible" : 'hidden' }};
        }
        #element-{{ node.uuid }} .variant-list .item.active {
          border: {{ desktop.show_border_selected_swatch ? `${desktop.selected_swatch_border_width}px solid ${desktop.selected_swatch_border_color}` : 'none' }};
          {{ desktop.border_top_left_radius ? `border-top-left-radius: ${desktop.border_top_left_radius}px;` : '' }}
          {{ desktop.border_top_right_radius ? `border-top-right-radius: ${desktop.border_top_right_radius}px;` : '' }}
          {{ desktop.border_bottom_left_radius ? `border-bottom-left-radius: ${desktop.border_bottom_left_radius}px;` : '' }}
          {{ desktop.border_bottom_right_radius ? `border-bottom-right-radius: ${desktop.border_bottom_right_radius}px;` : '' }}
        }
      }
    </v-style>
    <v-script type="application/json" id="variantsJson">
      {{ JSON.stringify(product?.variantsJson) }}
    </v-script>
  </div>
</template>

<script>
import ToolBar from "../../elements/utils/ToolBar.vue";
import elementStyleMixin from "../../elements/elementStyleMixin";
import ExtraClass from "../../elements/otherSettings/ExtraClass.vue";
import CustomCSS from '../../elements/styleSettings/CustomCSS.vue';
import VariantDimension from "./settings/VariantDimension.vue";
import AlignmentBase from "../../elements/styleSettings/AlignmentBase.vue";
import LabelText from "./settings/LabelText.vue";
import ShowOptions from "./settings/ShowOptions.vue";
import Display from "./settings/Display.vue";
import VariantType from "./settings/VariantType.vue";
import SpacingSetting from "./settings/Spacing.vue";
import SwatchText from './settings/SwatchText.vue';
import SelectedSwatch from './settings/SelectedSwatch.vue';
import Visibility from "../../elements/designSettings/Visibility.vue";
import Spacing from "../../elements/designSettings/Spacing.vue";
import Background from "../../elements/designSettings/Background.vue";
import Border from "../../elements/designSettings/Border.vue";
import Corner from "../../elements/designSettings/Corner.vue";
import Shadow from "../../elements/designSettings/Shadow.vue";
import Opacity from "../../elements/designSettings/Opacity.vue";
import Animations from '../../elements/designSettings/Animations.vue';
import { designStyles, otherProps, otherStyles, actionTypes } from "@/constants";

export default {
  components: { ToolBar },
  data() {
    return {
      isHovering: false,
      classRows:{
        '1':'sg_col-xs-12',
        '2':'sg_col-xs-6',
        '3':'sg_col-xs-4',
        '4':'sg_col-xs-3',
      }
    };
  },
  inject: ["node","product",'editor'],
  mixins: [elementStyleMixin],
  methods: {
    getHeight(height) {
      if (height.includes('%') || height.includes('px') || isNaN(parseInt(height))) {
        return height;
      }
      return `${height}px`
    }
  },
  props: {
    component: String,
    parent: String,
    show_label: Boolean,
    extra_class: String, ...otherProps,
    variant_type: String,
    show_option_1: Boolean,
    show_option_2: Boolean,
    show_option_3: Boolean,

    display_style: String,
    label_width: String,
    columns: String,
    blank_text: String,
    blank_option: Boolean,
    group_by: String,

    swatch_margin_bottom: Number,
    swatch_distance: Number,
    
    desktop: Object,
    laptop: Object,
    tablet: Object,
    mobile: Object,
  },
  mounted(){
  },
  computed: {
    dragging: function () {
      return this.editor?.draggedNode?.uuid
    },
  },
  craft: {
    defaultProps: { ...otherStyles,
        parent: "Product",
        variant_type: 'select',
        show_option_1: true,
        show_option_2: true,
        show_option_3: true,

        show_label: true,
        // Display select
        blank_text: 'Please select an item in the list',
        blank_option: false,
        display_style: 'default',
        columns: "1",
        group_by: "separately",
        label_width: '100',
        elementStyle: {
        },

        // display swatch
        swatch_margin_bottom: 15,
        swatch_distance: 15,

        desktop: {
          ...designStyles,
          label_margin_bottom: 5,
          label_font_weight: '400',
          label_font_size: 16,
          label_line_height: 1.2,
          label_color: 'rgb(61, 66, 70)',
          label_font_family: 'inherit',
          show_swatch_text: true,
          swatch_font_weight: '400',
          swatch_font_size: 16,
          swatch_color: 'rgb(61, 66, 70)',
          swatch_font_family: 'inherit',
          show_border_selected_swatch: true,
          selected_swatch_border_width: 1,
          selected_swatch_border_color: 'rgb(0, 0, 0)',
          select_width: '100',
          select_width_type: '%',
          select_height: 'auto',
          select_margin_bottom: '15',
          swatch_width: 'auto',
          swatch_width_type: '',
          swatch_height: '44',
          alignment: 'left',
          padding_left: 20,
          padding_right: 20,
        },
        laptop: {
          ...designStyles,
        },
        tablet: {
          ...designStyles,
        },
        mobile: {
          ...designStyles,
        },
    },
    settings: {
      settings: {
        "Variant Type": VariantType,
        "Show Label": {"Show Label": LabelText, multi_screen: true},
        "Swatch Text": {"Swatch Text": SwatchText, condition: [['variant_type', '=', 'swatch']], multi_screen: true},
        "Selected Swatch": {"Selected Swatch": SelectedSwatch, condition: [['variant_type', '=', 'swatch']], multi_screen: true},
        Dimension: {Dimension: VariantDimension, multi_screen: true},
        Spacing: {Spacing: SpacingSetting, condition: [['variant_type', '=', 'swatch']]},
        Display: {Display, condition: [['variant_type', '=', 'select']]},
        "Show Options": {"Show Options": ShowOptions, condition: [['variant_type', '=', 'select'], ['group_by', '!=', 'default']]},
        Alignment: {Alignment: AlignmentBase, multi_screen: true},
        "Extra Class": ExtraClass, "Custom CSS": CustomCSS,
      },
      design: {
        Visibility,
        Spacing: { Spacing, multi_screen: true },
        Background: { Background, multi_screen: true },
        Border: { Border, multi_screen: true },
        Corner: { Corner, multi_screen: true },
        Shadow: { Shadow, multi_screen: true },
        Opacity: { Opacity, multi_screen: true },
      },
      others: {
        Animations: { Animations, actions: actionTypes }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
