<template>
  <div id="sg_featherlight-v4" class="sg_featherlight" style="display: none" @click="hidePreview()">
    <div class="sg_featherlight-content" @click.stop="stopEvent()">
      <button
        class="sg_featherlight-close-icon sg_featherlight-close"
        aria-label="Close"
        @click="hidePreview()"
      >
        ✕
      </button>
      <span class="sg_featherlight-previous" @click="previousPreview()">
        <span title="previous" data-action="previous">
          <svg viewBox="0 0 448 512">
            <path
              d="M231.536 475.535l7.071-7.07c4.686-4.686 4.686-12.284 0-16.971L60.113 273H436c6.627 0 12-5.373 12-12v-10c0-6.627-5.373-12-12-12H60.113L238.607 60.506c4.686-4.686 4.686-12.284 0-16.971l-7.071-7.07c-4.686-4.686-12.284-4.686-16.97 0L3.515 247.515c-4.686 4.686-4.686 12.284 0 16.971l211.051 211.05c4.686 4.686 12.284 4.686 16.97-.001z"
              class=""
            ></path>
          </svg>
        </span>
      </span>
      <span class="sg_featherlight-next" @click="nextPreview()">
        <span title="next" data-action="next">
          <svg viewBox="0 0 448 512">
            <path
              d="M216.464 36.465l-7.071 7.07c-4.686 4.686-4.686 12.284 0 16.971L387.887 239H12c-6.627 0-12 5.373-12 12v10c0 6.627 5.373 12 12 12h375.887L209.393 451.494c-4.686 4.686-4.686 12.284 0 16.971l7.071 7.07c4.686 4.686 12.284 4.686 16.97 0l211.051-211.05c4.686-4.686 4.686-12.284 0-16.971L233.434 36.465c-4.686-4.687-12.284-4.687-16.97 0z"
              class=""
            ></path>
          </svg>
        </span>
      </span>
      <div class="sg_featherlight-loading">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      
    </div>
  </div>
</template>
  <script>
export default {
  name: "PreviewModal",
  props: {},
  watch: {},
  data(props) {
    return {};
  },
  methods: {
        stopEvent(){
        },
        hidePreview(){
            var $iframe = $("#designEditor").contents();
            $iframe.find('#sg_featherlight-v4').hide();
        },
        nextPreview(){
            var $iframe = $("#designEditor").contents();
            let visible = 0;
            let size = $iframe.find('#sg_featherlight-v4').find(".sg_featherlight-content").find("div[id^='sg_featherlight-item']").length;
            $iframe.find("div[id^='sg_featherlight-item']").each(function (i, el) {
                var display = $(this).css("display");
                if(display != 'none' || !display){
                    $(this).hide();
                    if(i == size -1){
                        visible = 0;
                    }else{
                        visible = i+1;
                    }
                }
            });
            $iframe.find("div[id^='sg_featherlight-item']").each(function (i, el) {
                if(i == visible){
                    $(this).show();
                }
            });
        },
        previousPreview(){
            var $iframe = $("#designEditor").contents();
            let visible = 0;
            let size = $iframe.find('#sg_featherlight-v4').find(".sg_featherlight-content").find("div[id^='sg_featherlight-item']").length;
            $iframe.find("div[id^='sg_featherlight-item']").each(function (i, el) {
                var display = $(this).css("display");
                if(display != 'none' || !display){
                    $(this).hide();
                    if(i == 0){
                        visible = size -1;
                    }else{
                        visible = i-1;
                    }
                }
            });
            $iframe.find("div[id^='sg_featherlight-item']").each(function (i, el) {
                if(i == visible){
                    $(this).show();
                }
            });
        }
  },
};
</script>
  
  