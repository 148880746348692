<template>
  <div>
    <div
      class="sidebar d-none d-lg-block"
      :class="{ 'd-none': !this.editor.enabled || this.editor.selectedNode }"
    >
      <input
        type="radio"
        id="tab-elements"
        name="tab2"
        checked
        class="el-tab"
      />
      <label for="tab-elements" class="tab-label-sidebar">
        <span>Elements</span>
      </label>
      <input type="radio" id="tab-site_settings" name="tab2" class="el-tab" />
      <label for="tab-site_settings" class="tab-label-sidebar">
        <span>Site Settings</span>
      </label>
      <div
        class="line-container"
        style="position: relative; width: 100%; height: 2px"
      >
        <div class="line" style="background-color: #2a85ff" />
      </div>
      <div class="content-container">
        <div class="tab-content" id="content-elements">
          <div style="padding-top: 12px">
            <input
              class="input-search input-search-element"
              :name="query"
              placeholder="Search elements..."
              v-on:input="searchSection"
            />
          </div>
          <div class="list-blocks">
            <el-collapse v-model="groups">
              <el-collapse-item
                title="Base"
                name="base"
                v-if="
                  visibleParent([
                    'Row',
                    'Heading',
                    'Header',
                    'Text Block',
                    'Image',
                    'Icon',
                    'Button',
                    'Separator',
                    'Liquid',
                  ])
                "
              >
                <div class="element-container">
                  <Blueprint
                    :component="ElementBlock"
                    iconType="svg"
                    icon="row"
                    name="Row"
                    v-if="visibleElement('Row')"
                  >
                    <template v-slot:blueprint>
                      <Row>
                        <Canvas component="Column" />
                      </Row>
                    </template>
                  </Blueprint>
                  <Blueprint
                    :component="ElementBlock"
                    iconType="svg"
                    icon="header"
                    name="Header"
                    v-if="visibleElement('Header')"
                  >
                    <template v-slot:blueprint>
                      <Header component="Header">
                        <HeaderButton component="Header Button">
                          <Button
                            component="Button"
                            icon="fa-shopping-cart"
                            text="Buy Now"
                            :spacing="12"
                            :desktop="headerButton"
                          />
                        </HeaderButton>
                      </Header>
                    </template>
                  </Blueprint>
                  <Blueprint
                    :component="ElementBlock"
                    iconType="svg"
                    icon="heading"
                    name="Heading"
                    v-if="visibleElement('Heading')"
                  >
                    <template v-slot:blueprint>
                      <Heading />
                    </template>
                  </Blueprint>

                  <Blueprint
                    :component="ElementBlock"
                    iconType="svg"
                    icon="text"
                    name="Text Block"
                    v-if="visibleElement('Text Block')"
                  >
                    <template v-slot:blueprint>
                      <Paragraph component="Text Block" />
                    </template>
                  </Blueprint>

                  <Blueprint
                    :component="ElementBlock"
                    iconType="svg"
                    icon="image"
                    name="Image"
                    v-if="visibleElement('Image')"
                  >
                    <template v-slot:blueprint>
                      <Picture component="Image" />
                    </template>
                  </Blueprint>

                  <Blueprint
                    :component="ElementBlock"
                    iconType="svg"
                    icon="icon"
                    name="Icon"
                    v-if="visibleElement('Icon')"
                  >
                    <template v-slot:blueprint>
                      <Icon />
                    </template>
                  </Blueprint>

                  <Blueprint
                    :component="ElementBlock"
                    iconType="svg"
                    icon="button"
                    name="Button"
                    v-if="visibleElement('Button')"
                  >
                    <template v-slot:blueprint>
                      <Button />
                    </template>
                  </Blueprint>

                  <Blueprint
                    :component="ElementBlock"
                    iconType="svg"
                    icon="separator"
                    name="Separator"
                    v-if="visibleElement('Separator')"
                  >
                    <template v-slot:blueprint>
                      <Separator />
                    </template>
                  </Blueprint>

                  <Blueprint
                    :component="ElementBlock"
                    iconType="svg"
                    icon="liquid"
                    name="Liquid"
                    v-if="visibleElement('Liquid')"
                  >
                    <template v-slot:blueprint>
                      <Liquid component="Liquid" />
                    </template>
                  </Blueprint>
                </div>
              </el-collapse-item>
              <el-collapse-item
                title="Banner"
                name="banner"
                v-if="visibleParent(['Parallax', 'Hero Banner'])"
              >
                <div class="element-container">
                  <Blueprint
                    :component="ElementBlock"
                    iconType="svg"
                    icon="parallax"
                    name="Parallax"
                    v-if="visibleElement('Parallax')"
                  >
                    <template v-slot:blueprint>
                      <Parallax :screen="screen" component="Parallax" />
                    </template>
                  </Blueprint>
                  <Blueprint
                    :component="ElementBlock"
                    iconType="svg"
                    icon="banner"
                    name="Hero Banner"
                    v-if="visibleElement('Hero Banner')"
                  >
                    <template v-slot:blueprint>
                      <Banner :screen="screen" component="Banner" />
                    </template>
                  </Blueprint>
                </div>
              </el-collapse-item>
              <el-collapse-item
                title="Stack"
                name="stack"
                v-if="
                  visibleParent(['Tabs', 'Carousel', 'Pricing', 'Accordion'])
                "
              >
                <div class="element-container">
                  <Blueprint
                    :component="ElementBlock"
                    iconType="svg"
                    icon="tab"
                    name="Tabs"
                    v-if="visibleElement('Tabs')"
                  >
                    <template v-slot:blueprint>
                      <Tabs component="Tabs">
                        <TabContent
                          v-for="item in tabItems"
                          component="TabContent"
                          :item="item"
                          :key="item.id"
                        />
                      </Tabs>
                    </template>
                  </Blueprint>
                  <Blueprint
                    :component="ElementBlock"
                    iconType="svg"
                    icon="carousel"
                    name="Carousel"
                    v-if="visibleElement('Carousel')"
                  >
                    <template v-slot:blueprint>
                      <Carousel component="Carousel">
                        <CarouselItem
                          component="CarouselItem"
                          v-for="item in carouselItems"
                          :item="item"
                          :key="item.id"
                        />
                      </Carousel>
                    </template>
                  </Blueprint>
                  <Blueprint
                    :component="ElementBlock"
                    iconType="svg"
                    icon="pricing"
                    name="Pricing"
                    v-if="visibleElement('Pricing')"
                  >
                    <template v-slot:blueprint>
                      <Pricing component="Pricing">
                        <PricingItem
                          v-for="item in pricingItems.slice(0, 3)"
                          component="PricingItem"
                          :item="item"
                          :key="item.id"
                        />
                      </Pricing>
                    </template>
                  </Blueprint>
                  <Blueprint
                    :component="ElementBlock"
                    iconType="svg"
                    icon="accordion"
                    name="Accordion"
                    v-if="visibleElement('Accordion')"
                  >
                    <template v-slot:blueprint>
                      <Accordion component="Accordion">
                        <AccordionItem
                          v-for="item in accordionItems.slice(0, 3)"
                          component="AccordionItem"
                          :item="item"
                          :key="item.id"
                        />
                      </Accordion>
                    </template>
                  </Blueprint>
                </div>
              </el-collapse-item>
              <el-collapse-item
                title="List"
                name="list"
                v-if="visibleParent(['Icon List Hoz', 'Icon List'])"
              >
                <div class="element-container">
                  <Blueprint
                    :component="ElementBlock"
                    iconType="svg"
                    icon="icon_list_hoz"
                    name="Icon List Hoz"
                    v-if="visibleElement('Icon List Hoz')"
                  >
                    <template v-slot:blueprint>
                      <IconListHoz component="Icon List Hoz">
                        <IconHozItem
                          v-for="item in iconItems"
                          :key="item.id"
                          component="IconHozItem"
                          :item="item"
                        >
                          <Icon component="Icon" />
                        </IconHozItem>
                      </IconListHoz>
                    </template>
                  </Blueprint>
                  <Blueprint
                    :component="ElementBlock"
                    iconType="svg"
                    icon="icon_list"
                    name="Icon List"
                    v-if="visibleElement('Icon List')"
                  >
                    <template v-slot:blueprint>
                      <IconList component="Icon List">
                        <IconListItem
                          v-for="item in iconListItems"
                          :key="item.id"
                          component="IconListItem"
                          :item="item"
                        >
                          <Icon
                            component="Icon"
                            :size="20"
                            icon="fa-check-circle"
                          />
                          <Paragraph
                            component="Text Block"
                            content="Your custom text goes here"
                          />
                        </IconListItem>
                      </IconList>
                    </template>
                  </Blueprint>
                </div>
              </el-collapse-item>
              <el-collapse-item
                title="Module"
                name="module"
                v-if="visibleParent(['Breadcrumbs', 'Progress'])"
              >
                <div class="element-container">
                  <Blueprint
                    :component="ElementBlock"
                    icon="breadcrumbs"
                    name="Breadcrumbs"
                    iconType="svg"
                    v-if="visibleElement('Breadcrumbs')"
                  >
                    <template v-slot:blueprint>
                      <Breadcrumbs component="Breadcrumbs" />
                    </template>
                  </Blueprint>
                  <Blueprint
                    :component="ElementBlock"
                    icon="floating_button"
                    name="Progress"
                    iconType="svg"
                    v-if="visibleElement('Progress')"
                  >
                    <template v-slot:blueprint>
                      <Progress component="Progress" />
                    </template>
                  </Blueprint>
                  <!-- <Blueprint
                    :component="ElementBlock"
                    icon="google_map"
                    name="Google Maps"
                    iconType="svg"
                    v-if="visibleElement('Google Maps')"
                  >
                    <template v-slot:blueprint>
                      <GoogleMaps component="Google Maps" />
                    </template>
                  </Blueprint> -->
                </div>
              </el-collapse-item>
              <el-collapse-item
                title="Popup"
                name="popup"
                v-if="visibleParent(['Popup', 'Floating Button'])"
              >
                <div class="element-container">
                  <Blueprint
                    :component="ElementBlock"
                    icon="popup"
                    name="Popup"
                    iconType="svg"
                    v-if="visibleElement('Popup')"
                  >
                    <template v-slot:blueprint>
                      <Popup component="Popup" />
                    </template>
                  </Blueprint>

                  <Blueprint
                    :component="ElementBlock"
                    icon="floating_button"
                    name="Floating Button"
                    iconType="svg"
                    v-if="visibleElement('Floating Button')"
                  >
                    <template v-slot:blueprint>
                      <FloatingButton component="Floating Button" />
                    </template>
                  </Blueprint>
                </div>
              </el-collapse-item>
              <el-collapse-item
                title="Video"
                name="video"
                v-if="visibleParent(['Youtube'])"
              >
                <div class="element-container">
                  <Blueprint
                    :component="ElementBlock"
                    iconType="svg"
                    icon="youtube"
                    name="Youtube"
                    v-if="visibleElement('Youtube')"
                  >
                    <template v-slot:blueprint>
                      <Youtube component="Youtube" />
                    </template>
                  </Blueprint>
                </div>
              </el-collapse-item>
              <el-collapse-item
                title="Product"
                name="product"
                v-if="
                  visibleParent([
                    'Product',
                    '(P) Paypal',
                    'Product List',
                    'Related Product',
                    '(P) Title',
                    '(P) Description',
                    '(P) Price',
                    '(P) Image',
                    '(P) Vendor',
                    '(P) SKU',
                    '(P) Image List',
                    '(P) Cart Button',
                    '(P) View More',
                    '(P) Variants',
                    '(P) Stock Counter',
                    '(P) Quantity',
                  ])
                "
              >
                <div class="element-container">
                  <Blueprint
                    :component="ElementBlock"
                    icon="product"
                    iconType="svg"
                    name="Product"
                    v-if="visibleElement('Product')"
                  >
                    <template v-slot:blueprint>
                      <Product component="Product">
                        <ProductImage component="(P) Image" />
                        <ProductTitle component="(P) Title" />
                        <ProductPrice component="(P) Price" />
                        <!-- <ProductCartButton component="(P) Cart Button" /> -->
                      </Product>
                    </template>
                  </Blueprint>

                  <Blueprint
                    :component="ElementBlock"
                    icon="product_list"
                    name="Product List"
                    iconType="svg"
                    v-if="visibleElement('Product List')"
                  >
                    <template v-slot:blueprint>
                      <ProductList component="Product List" />
                    </template>
                  </Blueprint>

                  <Blueprint
                    :component="ElementBlock"
                    icon="related_product"
                    name="Related Product"
                    iconType="svg"
                    v-if="visibleElement('Related Product')"
                  >
                    <template v-slot:blueprint>
                      <RelatedProduct component="Related Product" />
                    </template>
                  </Blueprint>

                  <Blueprint
                    :component="ElementBlock"
                    iconType="svg"
                    icon="p_title"
                    name="(P) Title"
                    v-if="visibleElement('(P) Title')"
                  >
                    <template v-slot:blueprint>
                      <ProductTitle component="(P) Title" />
                    </template>
                  </Blueprint>
                  <Blueprint
                    :component="ElementBlock"
                    icon="p_description"
                    iconType="svg"
                    name="(P) Description"
                    v-if="visibleElement('(P) Description')"
                  >
                    <template v-slot:blueprint>
                      <ProductDescription component="(P) Description" />
                    </template>
                  </Blueprint>
                  <Blueprint
                    :component="ElementBlock"
                    icon="p_price"
                    iconType="svg"
                    name="(P) Price"
                    v-if="visibleElement('(P) Price')"
                  >
                    <template v-slot:blueprint>
                      <ProductPrice component="(P) Price" />
                    </template>
                  </Blueprint>
                  <Blueprint
                    :component="ElementBlock"
                    icon="p_image"
                    iconType="svg"
                    name="(P) Image"
                    v-if="visibleElement('(P) Image')"
                  >
                    <template v-slot:blueprint>
                      <ProductImage component="(P) Image" />
                    </template>
                  </Blueprint>
                  <Blueprint
                    :component="ElementBlock"
                    icon="p_vendor"
                    iconType="svg"
                    name="(P) Vendor"
                    v-if="visibleElement('(P) Vendor')"
                  >
                    <template v-slot:blueprint>
                      <ProductVendor component="(P) Vendor" />
                    </template>
                  </Blueprint>
                  <Blueprint
                    :component="ElementBlock"
                    iconType="svg"
                    icon="p_sku"
                    name="(P) SKU"
                    v-if="visibleElement('(P) SKU')"
                  >
                    <template v-slot:blueprint>
                      <ProductSKU component="(P) SKU" />
                    </template>
                  </Blueprint>
                  <Blueprint
                    :component="ElementBlock"
                    icon="p_image_list"
                    iconType="svg"
                    name="(P) Image List"
                    v-if="visibleElement('(P) Image List')"
                  >
                    <template v-slot:blueprint>
                      <ProductImageList component="(P) Image List" />
                    </template>
                  </Blueprint>
                  <Blueprint
                    :component="ElementBlock"
                    iconType="svg"
                    icon="p_cart_button"
                    name="(P) Cart Button"
                    v-if="visibleElement('(P) Cart Button')"
                  >
                    <template v-slot:blueprint>
                      <ProductCartButton component="(P) CartButton" />
                    </template>
                  </Blueprint>
                  <Blueprint
                    :component="ElementBlock"
                    iconType="svg"
                    icon="p_paypal"
                    name="(P) Paypal"
                    v-if="visibleElement('(P) Paypal')"
                  >
                    <template v-slot:blueprint>
                      <Paypal component="(P) Paypal" />
                    </template>
                  </Blueprint>
                  <Blueprint
                    :component="ElementBlock"
                    icon="p_view_more"
                    iconType="svg"
                    name="(P) View More"
                    v-if="visibleElement('(P) View More')"
                  >
                    <template v-slot:blueprint>
                      <ProductViewMore component="(P) View More" />
                    </template>
                  </Blueprint>

                  <Blueprint
                    :component="ElementBlock"
                    iconType="svg"
                    icon="p_variants"
                    name="(P) Variants"
                    v-if="visibleElement('(P) Variants')"
                  >
                    <template v-slot:blueprint>
                      <ProductVariants component="(P) Variants" />
                    </template>
                  </Blueprint>
                  <Blueprint
                    :component="ElementBlock"
                    icon="p_stock_counter"
                    iconType="svg"
                    name="(P) Stock Counter"
                    v-if="visibleElement('(P) Stock Counter')"
                  >
                    <template v-slot:blueprint>
                      <ProductStockCounter component="(P) Stock Counter" />
                    </template>
                  </Blueprint>

                  <Blueprint
                    :component="ElementBlock"
                    icon="p_quantity"
                    iconType="svg"
                    name="(P) Quantity"
                    v-if="visibleElement('(P) Quantity')"
                  >
                    <template v-slot:blueprint>
                      <ProductQuantity component="(P) Quantity" />
                    </template>
                  </Blueprint>
                </div>
              </el-collapse-item>
              <el-collapse-item
                title="Booster"
                name="booster"
                v-if="visibleParent(['Countdown Timer', 'Counter'])"
              >
                <div class="element-container">
                  <Blueprint
                    :component="ElementBlock"
                    icon="countdown_timer"
                    iconType="svg"
                    name="Countdown Timer"
                    v-if="visibleElement('Countdown Timer')"
                  >
                    <template v-slot:blueprint>
                      <CountdownTimer component="Countdown Timer" />
                    </template>
                  </Blueprint>
                  <Blueprint
                    :component="ElementBlock"
                    icon="p_quantity"
                    iconType="svg"
                    name="Counter"
                    v-if="visibleElement('Counter')"
                  >
                    <template v-slot:blueprint>
                      <Counter component="Counter" />
                    </template>
                  </Blueprint>
                </div>
              </el-collapse-item>
              <!-- <el-collapse-item
                title="Checkout"
                name="checkout"
                v-if="
                  visibleParent([
                    'Shipping Address',
                    'Billing Address',
                    'Payment',
                    'Payment Button',
                    'Order Summary'
                  ])
                "
              >
                <div class="element-container">
                  <Blueprint
                    :component="ElementBlock"
                    iconType="svg"
                    icon="shipping_address"
                    name="Shipping Address"
                    v-if="visibleElement('Shipping Address')"
                  >
                    <template v-slot:blueprint>
                      <ShippingAddress :disabled="true" component="Shipping Address">
                        <Canvas v-bind="checkoutInfoRowProps" component="Row">
                          <Canvas component="Column" v-bind="checkoutInfoColumnProps">
                            <EmailField 
                              component="Email Field"
                              :disabled="true"
                              name="email"
                              placeholder="Email"
                              :required="true"
                              v-bind="checkoutEmailField"
                            />
                          </Canvas>
                        </Canvas>
                        <Canvas v-bind="checkoutInfo2RowProps" component="Row">
                          <Canvas component="Column" v-bind="checkoutInfo2ColumnProps">
                            <TextField 
                              component="Text Field"
                              :disabled="true"
                              name="first_name"
                              placeholder="First Name"
                              :required="true"
                              v-bind="checkoutTextField"
                            />
                          </Canvas>
                          <Canvas component="Column" v-bind="checkoutInfo2ColumnProps">
                            <TextField 
                              component="Text Field"
                              :disabled="true"
                              name="last_name"
                              placeholder="Last Name"
                              :required="true"
                              v-bind="checkoutTextField"
                            />
                          </Canvas>
                        </Canvas>
                        <Canvas v-bind="checkoutInfoRowProps" component="Row">
                          <Canvas component="Column" v-bind="checkoutInfoColumnProps">
                            <TextField 
                              component="Text Field"
                              :disabled="true"
                              name="address1"
                              placeholder="Address"
                              :required="true"
                              v-bind="checkoutTextField"
                            />
                          </Canvas>
                        </Canvas>
                        <Canvas v-bind="checkoutInfoRowProps" component="Row">
                          <Canvas component="Column" v-bind="checkoutInfoColumnProps">
                            <TextField 
                              component="Text Field"
                              :disabled="true"
                              name="address2"
                              placeholder="Apartment, suite, etc. (optional)"
                              v-bind="checkoutTextField"
                            />
                          </Canvas>
                        </Canvas>
                        <Canvas v-bind="checkoutInfo2RowProps" component="Row">
                          <Canvas component="Column" v-bind="checkoutInfo2ColumnProps">
                            <SelectField 
                              component="Select Field"
                              :disabled="true"
                              name="country_code"
                              placeholder="Country"
                              :required="true"
                              v-bind="checkoutSelectField"
                            />
                          </Canvas>
                          <Canvas component="Column" v-bind="checkoutInfo2ColumnProps">
                            <SelectField 
                              component="Select Field"
                              :disabled="true"
                              name="province_code"
                              placeholder="Region"
                              :required="true"
                              v-bind="checkoutSelectField"
                            />
                          </Canvas>
                        </Canvas>
                        <Canvas v-bind="checkoutInfo2RowProps" component="Row">
                          <Canvas component="Column" v-bind="checkoutInfo2ColumnProps">
                            <TextField 
                              component="Text Field"
                              :disabled="true"
                              name="city"
                              placeholder="City"
                              :required="true"
                              v-bind="checkoutTextField"
                            />
                            
                          </Canvas>
                          <Canvas component="Column" v-bind="checkoutInfo2ColumnProps">
                            <TextField 
                              component="Text Field"
                              :disabled="true"
                              name="zip"
                              placeholder="Postal Code"
                              :required="true"
                              v-bind="checkoutTextField"
                            />
                          </Canvas>
                        </Canvas>
                        <Canvas v-bind="checkoutInfoRowProps" component="Row">
                          <Canvas component="Column" v-bind="checkoutInfoColumnProps">
                            <TextField 
                              component="Text Field"
                              :disabled="true"
                              name="phone"
                              placeholder="Phone"
                              v-bind="checkoutTextField"
                            />
                          </Canvas>
                        </Canvas>
                      </ShippingAddress>
                    </template>
                  </Blueprint>
                  <Blueprint
                    :component="ElementBlock"
                    iconType="svg"
                    icon="billing_address"
                    name="Billing Address"
                    v-if="visibleElement('Billing Address')"
                  >
                    <template v-slot:blueprint>
                      <BillingAddress :disabled="true" component="Billing Address">
                        <Canvas v-bind="checkoutInfo2RowProps" component="Row">
                          <Canvas component="Column" v-bind="checkoutInfo2ColumnProps">
                            <TextField 
                              component="Text Field"
                              :disabled="true"
                              name="first_name"
                              placeholder="First Name"
                              :required="true"
                              v-bind="checkoutTextField"
                            />
                          </Canvas>
                          <Canvas component="Column" v-bind="checkoutInfo2ColumnProps">
                            <TextField 
                              component="Text Field"
                              :disabled="true"
                              name="last_name"
                              placeholder="Last Name"
                              :required="true"
                              v-bind="checkoutTextField"
                            />
                          </Canvas>
                        </Canvas>
                        <Canvas v-bind="checkoutInfoRowProps" component="Row">
                          <Canvas component="Column" v-bind="checkoutInfoColumnProps">
                            <TextField 
                              component="Text Field"
                              :disabled="true"
                              name="address1"
                              placeholder="Address"
                              :required="true"
                              v-bind="checkoutTextField"
                            />
                          </Canvas>
                        </Canvas>
                        <Canvas v-bind="checkoutInfoRowProps" component="Row">
                          <Canvas component="Column" v-bind="checkoutInfoColumnProps">
                            <TextField 
                              component="Text Field"
                              :disabled="true"
                              name="address2"
                              placeholder="Apartment, suite, etc. (optional)"
                              v-bind="checkoutTextField"
                            />
                          </Canvas>
                        </Canvas>
                        <Canvas v-bind="checkoutInfo2RowProps" component="Row">
                          <Canvas component="Column" v-bind="checkoutInfo2ColumnProps">
                            <SelectField 
                              component="Select Field"
                              :disabled="true"
                              name="country_code"
                              placeholder="Country"
                              :required="true"
                              v-bind="checkoutSelectField"
                            />
                          </Canvas>
                          <Canvas component="Column" v-bind="checkoutInfo2ColumnProps">
                            <SelectField 
                              component="Select Field"
                              :disabled="true"
                              name="province_code"
                              placeholder="Region"
                              :required="true"
                              v-bind="checkoutSelectField"
                            />
                          </Canvas>
                        </Canvas>
                        <Canvas v-bind="checkoutInfo2RowProps" component="Row">
                          <Canvas component="Column" v-bind="checkoutInfo2ColumnProps">
                            <TextField 
                              component="Text Field"
                              :disabled="true"
                              name="city"
                              placeholder="City"
                              :required="true"
                              v-bind="checkoutTextField"
                            />
                          </Canvas>
                          <Canvas component="Column" v-bind="checkoutInfo2ColumnProps">
                            <TextField 
                              component="Text Field"
                              :disabled="true"
                              name="zip"
                              placeholder="Postal Code"
                              :required="true"
                              v-bind="checkoutTextField"
                            />
                          </Canvas>
                        </Canvas>
                      </BillingAddress>
                    </template>
                  </Blueprint>
                  <Blueprint
                    :component="ElementBlock"
                    iconType="svg"
                    icon="payment"
                    name="Payment"
                    v-if="visibleElement('Payment')"
                  >
                    <template v-slot:blueprint>
                      <Payment :disabled="true" component="Payment">
                        <Canvas v-bind="checkoutInfoRowProps" component="Row">
                          <Canvas component="Column" v-bind="checkoutInfoColumnProps">
                            <TextField 
                              component="Text Field"
                              :disabled="true"
                              name="card_number"
                              placeholder="Card number"
                              :required="true"
                              v-bind="checkoutTextField"
                            />
                          </Canvas>
                        </Canvas>
                        <Canvas v-bind="checkoutInfo2RowProps" component="Row">
                          <Canvas component="Column" v-bind="checkoutInfo2ColumnProps">
                            <TextField 
                              component="Text Field"
                              :disabled="true"
                              name="valid_date"
                              placeholder="MM/YY"
                              :required="true"
                              v-bind="checkoutTextField"
                            />
                          </Canvas>
                          <Canvas component="Column" v-bind="checkoutInfo2ColumnProps">
                            <TextField 
                              component="Text Field"
                              :disabled="true"
                              name="cvv"
                              placeholder="CVV"
                              :required="true"
                              v-bind="checkoutTextField"
                            />
                          </Canvas>
                        </Canvas>
                      </Payment>
                    </template>
                  </Blueprint>
                  <Blueprint
                    :component="ElementBlock"
                    iconType="svg"
                    icon="payment_button"
                    name="Payment Button"
                    v-if="visibleElement('Payment Button')"
                  >
                    <template v-slot:blueprint>
                      <PaymentButton 
                        :disabled="true"
                        component="Payment Button" 
                      />
                    </template>
                  </Blueprint>
                  <Blueprint
                    :component="ElementBlock"
                    iconType="svg"
                    icon="order_summary"
                    name="Order Summary"
                    v-if="visibleElement('Order Summary')"
                  >
                    <template v-slot:blueprint>
                      <OrderSummary 
                        :disabled="true"
                        component="Order Summary" 
                      />
                    </template>
                  </Blueprint>
                </div>
              </el-collapse-item> -->
              <el-collapse-item
                title="Form Field"
                name="form_field"
                v-if="
                  visibleParent([
                    'Newsletter',
                    'QR Form',
                    'Contact Form',
                    'Text Field',
                    'Email Field',
                    'Select Field',
                    'Checkbox Field',
                    'Text Area',
                    'Submit Button',
                  ])
                "
              >
                <div class="element-container">
                  <Blueprint
                    :component="ElementBlock"
                    iconType="svg"
                    icon="newsletter"
                    name="Newsletter"
                    v-if="visibleElement('Newsletter')"
                  >
                    <template v-slot:blueprint>
                      <Newsletter component="Newsletter">
                        <Paragraph
                          component="Text Block"
                          content="First Name:"
                        />
                        <TextField
                          component="Text Field"
                          placeholder="Enter first name"
                          name="contact[first_name]"
                        />
                        <Paragraph
                          component="Text Block"
                          content="Last Name:"
                        />
                        <TextField
                          component="Text Field"
                          placeholder="Enter last name"
                          name="contact[last_name]"
                        />
                        <Paragraph component="Text Block" content="Email:" />
                        <EmailField
                          component="Email Field"
                          placeholder="Enter email address"
                          :read_only_name="true"
                        />
                        <SubmitButton component="Submit Button" />
                      </Newsletter>
                    </template>
                  </Blueprint>

                  <Blueprint
                    :component="ElementBlock"
                    iconType="svg"
                    icon="contact_form"
                    name="Contact Form"
                    v-if="visibleElement('Contact Form')"
                  >
                    <template v-slot:blueprint>
                      <ContactForm component="Contact Form">
                        <Paragraph component="Text Block" content="Name:" />
                        <TextField
                          component="Text Field"
                          placeholder="Enter your name"
                          name="contact[name]"
                        />
                        <Paragraph component="Text Block" content="Phone:" />
                        <TextField
                          component="Text Field"
                          placeholder="Enter your phone number"
                          name="contact[phone]"
                        />
                        <Paragraph component="Text Block" content="Email:" />
                        <EmailField
                          component="Email Field"
                          placeholder="Enter your email address"
                          :read_only_name="true"
                        />
                        <Paragraph component="Text Block" content="Message:" />
                        <TextArea component="Text Area" />
                        <SubmitButton component="Submit Button" />
                      </ContactForm>
                    </template>
                  </Blueprint>

                  <Blueprint
                    :component="ElementBlock"
                    iconType="svg"
                    icon="qr_form"
                    name="QR Form"
                    v-if="visibleElement('QR Form')"
                  >
                    <template v-slot:blueprint>
                      <QRForm component="QR Form">
                        <Paragraph
                          component="Text Block"
                          content="Name:"
                          :disabled="true"
                        />
                        <TextField
                          component="Text Field"
                          placeholder="Enter your name"
                          name="contact[name]"
                          :disabled="true"
                          :read_only_name="true"
                        />
                        <Paragraph
                          component="Text Block"
                          content="Phone:"
                          :disabled="true"
                        />
                        <TextField
                          component="Text Field"
                          placeholder="Enter your phone number"
                          name="contact[phone]"
                          required="1"
                          :read_only_name="true"
                          :disabled="true"
                        />
                        <Paragraph
                          component="Text Block"
                          content="Email:"
                          :disabled="true"
                        />
                        <EmailField
                          component="Email Field"
                          placeholder="Enter your email address"
                          :read_only_name="true"
                          :disabled="true"
                        />
                        <SubmitButton
                          component="Submit Button"
                          :disabled="true"
                        />

                        <Canvas v-bind="qrRowProps" component="Row">
                          <Canvas component="Column" v-bind="qrColumnLeftProps">
                            <QRInfo
                              component="(QR) Text"
                              label="Bank: "
                              content="Techcombank"
                            />
                            <QRInfo
                              component="(QR) Text"
                              label="Account number: "
                              content="<Account number>"
                              :show_copy="true"
                            />
                            <QRInfo
                              component="(QR) Text"
                              label="Account holder: "
                              content="<Account holder name>"
                              :show_copy="true"
                            />
                            <QRInfo
                              component="(QR) Text"
                              label="Transfer content: "
                              content="prefix"
                              :show_copy="true"
                              keyInfo="tranfer_content"
                            />
                          </Canvas>
                          <Canvas
                            component="Column"
                            v-bind="qrColumnRightProps"
                          >
                            <QRImage component="(QR) Image" />
                          </Canvas>
                        </Canvas>
                      </QRForm>
                    </template>
                  </Blueprint>

                  <Blueprint
                    :component="ElementBlock"
                    icon="text_field"
                    iconType="svg"
                    name="Text Field"
                    v-if="visibleElement('Text Field')"
                  >
                    <template v-slot:blueprint>
                      <TextField component="Text Field" />
                    </template>
                  </Blueprint>
                  
                  <Blueprint
                    :component="ElementBlock"
                    icon="select_field"
                    iconType="svg"
                    name="Select Field"
                    v-if="visibleElement('Select Field')"
                  >
                    <template v-slot:blueprint>
                      <SelectField component="Select Field" />
                    </template>
                  </Blueprint>
                  
                  <Blueprint
                    :component="ElementBlock"
                    icon="checkbox_field"
                    iconType="svg"
                    name="Checkbox Field"
                    v-if="visibleElement('Checkbox Field')"
                  >
                    <template v-slot:blueprint>
                      <CheckboxField component="Checkbox Field" />
                    </template>
                  </Blueprint>
                  
                  <Blueprint
                    :component="ElementBlock"
                    icon="email_field"
                    iconType="svg"
                    name="Email Field"
                    v-if="visibleElement('Email Field')"
                  >
                    <template v-slot:blueprint>
                      <EmailField component="Email Field" />
                    </template>
                  </Blueprint>
                  
                  <Blueprint
                    :component="ElementBlock"
                    icon="textarea"
                    iconType="svg"
                    name="Text Area"
                    v-if="visibleElement('Text Area')"
                  >
                    <template v-slot:blueprint>
                      <TextArea component="Text Area" />
                    </template>
                  </Blueprint>
                  
                  <Blueprint
                    :component="ElementBlock"
                    icon="submit_button"
                    iconType="svg"
                    name="Submit Button"
                    v-if="visibleElement('Submit Button')"
                  >
                    <template v-slot:blueprint>
                      <SubmitButton component="Submit Button" />
                    </template>
                  </Blueprint>
                </div>
              </el-collapse-item>
            </el-collapse>
          </div>
        </div>
        <div class="tab-content" id="content-site_settings">
          <div style="height: 12px" />
          <div
            v-for="(component, name) in siteSettings.settings"
            class="item-wrapper"
            :key="name"
          >
            <div class="sg-box-setting">
              <div v-if="name" class="sg-box-title">
                <span>{{ name }}</span>
              </div>
              <component
                :is="component.multi_screen ? component[name] : component"
                :node="wrapperNode"
                :screen="screen"
              />
            </div>
          </div>
        </div>
        <div class=""></div>
      </div>
    </div>
    <div
      class="sidebar-settings"
      :class="{
        'd-none':
          !this.editor.enabled || !this.editor.selectedNode || !selectedNode,
      }"
    >
      <div
        class="title title-setting"
        :style="{
          'background-color': selectedNode
            ? this.checkNodeDynamic(selectedNode)
              ? '#9900FF'
              : sections.find(
                  (e) =>
                    e.title ==
                    (selectedNode.props?.component ||
                      selectedNode.componentName)
                )?.color
            : '#04aa6d',
        }"
      >
        <span v-if="selectedNode">{{
          selectedNode.props?.component || selectedNode.componentName
        }}</span>
        <span v-else>Title</span>
        <svg
          @click="actionBack"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="#FFFFFF"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <line x1="5" y1="12" x2="19" y2="12"></line>
          <line x1="5" y1="12" x2="11" y2="18"></line>
          <line x1="5" y1="12" x2="11" y2="6"></line>
        </svg>
      </div>
      <ElementSettings
        :screen="screen"
        :settings="state.settings"
        v-bind:changeValue="($event, name) => changeValue($event, name)"
        v-bind:changeScreen="changeScreen"
        :activeFonts="activeFonts"
        :product="product"
        :collection="collection"
        :blog="blog"
        :primaryColor="
          selectedNode
            ? this.checkNodeDynamic(selectedNode)
              ? '#9900FF'
              : sections.find(
                  (e) =>
                    e.title ==
                    (selectedNode.props?.component ||
                      selectedNode.componentName)
                )?.color
            : '#04aa6d'
        "
        :showLayout="showLayout"
        :setShowLayout="setShowLayout"
        :showOtherSetting="showOtherSetting"
        :setOtherSetting="setOtherSetting"
      />
      <div v-if="showActions" class="actions">
        <el-button
          @click="deleteNode"
          type="danger"
          icon="el-icon-delete"
          circle
        ></el-button>
        <el-button
          @click="focusParent"
          icon="el-icon-top-right"
          circle
        ></el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { Blueprint, Canvas } from "@";
import ElementBlock from "./ElementBlock.vue";
import ElementBlockFrontAws from "./ElementBlockFrontAws.vue";
import Paragraph from "./elements/Paragraph.vue";
import Heading from "./elements/Heading.vue";
import Header from "./modules/header/Header.vue";
import HeaderButton from "./modules/header/HeaderButton.vue";
import Picture from "./elements/Picture.vue";
import Carousel from "./modules/stack/Carousel.vue";
import CarouselItem from "./modules/stack/CarouselItem.vue";
import WrapperContainer from "./elements/WrapperContainer.vue";
import Container from "./elements/Container.vue";
import ContainerChild from "./elements/ContainerChild.vue";
import ElementSettings from "./ElementSettings.vue";
import Icon from "./elements/Icon.vue";
import Button from "./elements/Button.vue";
import Liquid from './modules/liquid/Liquid.vue';

import ShippingAddress from "./elements/checkout/ShippingAddress.vue";
import BillingAddress from "./elements/checkout/BillingAddress.vue";
import Payment from "./elements/checkout/Payment.vue";
import PaymentButton from "./elements/checkout/PaymentButton.vue";
import OrderSummary from "./elements/checkout/OrderSummary.vue";

import Separator from "./elements/Separator.vue";
import Youtube from "./modules/video/Youtube.vue";
import Product from "./modules/product/Product.vue";
import Paypal from "./modules/product/Paypal.vue";
import ProductImage from "./modules/product/ProductImage.vue";
import ProductTitle from "./modules/product/ProductTitle.vue";
import ProductPrice from "./modules/product/ProductPrice.vue";
import ProductCartButton from "./modules/product/ProductCartButton.vue";
import ProductSKU from "./modules/product/ProductSKU.vue";
import ProductVendor from "./modules/product/ProductVendor.vue";
import ProductDescription from "./modules/product/ProductDescription.vue";
import ProductViewMore from "./modules/product/ProductViewMore.vue";
import ProductVariants from "./modules/product/ProductVariants.vue";
import ProductStockCounter from "./modules/product/ProductStockCounter.vue";
import ProductQuantity from "./modules/product/ProductQuantity.vue";
import ProductImageList from "./modules/product/ProductImageList.vue";
import Newsletter from "./modules/formField/Newsletter.vue";
import ContactForm from "./modules/formField/ContactForm.vue";
import QRForm from "./modules/formField/QRForm.vue";
import QRInfo from "./modules/formField/QRInfo.vue";
import QRImage from "./modules/formField/QRImage.vue";
import ContactQRForm from "./modules/formField/ContactQRForm.vue";
import Progress from "./modules/progress/Progress.vue";
import TextField from "./elements/TextField.vue";
import SelectField from "./elements/SelectField.vue";
import EmailField from "./elements/EmailField.vue";
import CheckboxField from "./elements/CheckboxField.vue";
import SubmitButton from "./elements/SubmitButton.vue";
import TextArea from "./elements/TextArea.vue";
import Popup from "./modules/popup/Popup.vue";
import Breadcrumbs from "./modules/breadcrumbs/Breadcrumbs.vue";
import ProductList from "./modules/product/ProductList.vue";
import RelatedProduct from "./modules/product/RelatedProduct.vue";
import Counter from './modules/counter/Counter.vue';
import CountdownTimer from "./modules/booster/CountdownTimer.vue";
import IconListHoz from "./modules/IconListHoz/IconListHoz.vue";
import IconHozItem from "./modules/IconListHoz/IconHozItem.vue";

import IconList from "./modules/IconList/IconList.vue";
import IconListItem from "./modules/IconList/IconListItem.vue";
import GoogleMaps from "./modules/GoogleMaps/GoogleMaps.vue";
import Banner from "./modules/banner/Banner.vue";
import Parallax from "./elements/Parallax.vue";
import PricingItem from "./modules/pricing/PricingItem.vue";
import Pricing from "./modules/pricing/Pricing.vue";
import Accordion from "./modules/accordion/Accordion.vue";
import AccordionItem from "./modules/accordion/AccordionItem.vue";
import Tabs from "./modules/tabs/Tabs.vue";
import TabContent from "./modules/tabs/TabContent.vue";

import { reactive } from "vue";

import ArticleList from "./modules/article/ArticleList.vue";
import ArticleImage from "./modules/article/ArticleImage.vue";
import ArticleTitle from "./modules/article/ArticleTitle.vue";
import ArticleContent from "./modules/article/ArticleContent.vue";
import ArticleDate from "./modules/article/ArticleDate.vue";
import BlogTitle from "./modules/article/BlogTitle.vue";
import BlogContent from "./modules/article/BlogContent.vue";
import { toolbarType } from "@/constants";
import _ from "lodash";

import Row from "./elements/Row.vue";
import Column from "./elements/Column.vue";

import CollectionBanner from "./modules/collections/CollectionBanner.vue";
import CollectionTitle from "./modules/collections/CollectionTitle.vue";
import CollectionDescription from "./modules/collections/CollectionDescription.vue";
import CollectionToolbar from "./modules/collections/CollectionToolbar.vue";
import Paginator from "./modules/collections/Paginator.vue";

import FloatingButton from "./modules/popup/FloatingButton.vue";

export default {
  components: {
    WrapperContainer,
    Blueprint,
    Canvas,
    Paragraph,
    Heading,
    Header,
    HeaderButton,
    Picture,
    Carousel,
    ElementSettings,
    Icon,
    Liquid,
    Button,
    ShippingAddress,
    BillingAddress,
    Payment,
    PaymentButton,
    OrderSummary,
    Separator,
    Youtube,
    Product,
    Paypal,
    ProductImage,
    ProductTitle,
    ProductPrice,
    ProductCartButton,
    ProductSKU,
    ProductVendor,
    Banner,
    Progress,
    Parallax,
    ProductDescription,
    ProductViewMore,
    ProductVariants,
    Pricing,
    PricingItem,
    Accordion,
    AccordionItem,
    Tabs,
    TabContent,
    GoogleMaps,
    ProductStockCounter,
    ProductQuantity,
    ProductImageList,
    Newsletter,
    TextField,
    SelectField,
    EmailField,
    CheckboxField,
    SubmitButton,
    ContactForm,
    TextArea,
    Counter,
    CountdownTimer,
    IconListHoz,
    IconHozItem,
    IconList,
    IconListItem,
    Popup,
    Breadcrumbs,
    ProductList,
    RelatedProduct,
    CarouselItem,
    ArticleList,
    ArticleImage,
    ArticleTitle,
    ArticleContent,
    ArticleDate,
    BlogTitle,
    BlogContent,
    Row,
    Column,
    QRForm,
    QRInfo,
    QRImage,
    ContactQRForm,
    CollectionBanner,
    CollectionTitle,
    CollectionDescription,
    CollectionToolbar,
    Paginator,
    FloatingButton,
  },
  inject: ["editor"],
  props: {
    screen: String,
    component: String,
    changeScreen: Function,
    activeFonts: Array,
    product: Object,
    blog: Object,
    collection: Object,
    showLayout: String,
    setShowLayout: Function,
    setOtherSetting: Function,
    showOtherSetting: Boolean,
  },
  setup(props) {
    const state = reactive({
      settings: {},
    });
    const changeValue = (value, name) => {
      state.settings[name] = value;
    };
    return {
      state,
      changeValue,
    };
  },
  // watch: {
  //   "editor.selectedNode": function (newValue, oldValue) {
  //     if (
  //       ["WrapperContainer", "ContainerChild", "Column"].includes(
  //         newValue?.props.component
  //       )
  //     ) {
  //       this.selectedNode = null;
  //     } else if (
  //       [
  //         "PricingItem",
  //         "AccordionItem",
  //         "CarouselItem",
  //         "Article",
  //         "TabContent",
  //       ].includes(newValue?.componentName)
  //     ) {
  //       this.selectedNode = newValue.parent;
  //     } else {
  //       this.selectedNode = newValue;
  //     }
  //   },
  // },
  computed: {
    wrapperNode() {
      return this.editor?.nodes[0];
      // return null
    },
    selectedNode() {
      if (["WrapperContainer", "ContainerChild", "Column"].includes(this.editor.selectedNode?.props.component)) {
        return null;
      }
      if (["PricingItem", "AccordionItem", "CarouselItem", "Article", "TabContent"].includes(this.editor.selectedNode?.componentName)) {
        return this.editor.selectedNode.parent;
      }
      return this.editor.selectedNode;
    },
    settingComponents() {
      if (!this.selectedNode) {
        return null;
      }
      return this.editor.getSettings(this.selectedNode);
    },
    showActions() {
      return this.selectedNode && (this.selectedNode.parent || ['Popup', 'FloatingButton'].includes(this.selectedNode.componentName));
    },
  },
  methods: {
    visibleParent(array) {
      return (
        _.intersection(
          this.sections.map((e) => e.title),
          array
        ).length > 0
      );
    },
    visibleElement(element) {
      return this.sections.map((e) => e.title).includes(element);
    },
    searchSection(e) {
      this.query = e.target.value;
      this.sections = Object.values(toolbarType).filter((x) =>
        x.title.toLowerCase().includes(e.target.value.toLowerCase())
      );
    },
    isBlog() {
      let urlParams = new URLSearchParams(window.location.search);
      return urlParams?.get("type") == "articlepages" ? true : false;
    },
    actionBack() {
      if (this.$props.showOtherSetting) {
        this.setOtherSetting(false);
      } else {
        this.editor.selectNode(null);
      }
    },
    deleteNode() {
      this.$confirm(
        "This will permanently delete this element. Continue?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(() => {
        const node = this.selectedNode;
        const parentNode = node.parent;
        if (node.props?.disabled) {
          this.$toastr.e('This element cannot be removed');
        } else {
          if (node.componentName == 'Popup' && node.props.button_id) {
            const nodeMaps = Object.values(this.editor.nodeMap);
            const buttonNodes = nodeMaps.filter(e => e.props.href == `#element-${node.uuid}`)
            if (buttonNodes) {
              buttonNodes.map(e => {
                e.setProps({
                  action_type: 'product',
                  href: ''
                })
              })
            }
          }
          this.editor.removeNode(this.selectedNode);
          if ((node.componentName == 'Row' || node.props.component == 'Row') && parentNode.props?.component == 'WrapperContainer') {
            const nodeMaps = Object.values(this.editor.nodeMap)
            const wrapper = nodeMaps.find(e => e.props.component == "WrapperContainer")
            const rowNodes = wrapper.children.filter(e => e.componentName == 'Row' || e.props?.component == 'Row')
            for (let i = 0; i < rowNodes.length; i++) {
              rowNodes[i].setProps({name: `Row ${i + 1}`})
            }
          }
        }
      });
    },
    focusParent() {
      const { parent } = this.selectedNode;
      this.editor.selectNode(parent);
    },
    getAncestor(node, result) {
      if (!result) {
        result = [];
      }
      if (node.parent) {
        const product_dynamic =
          node.parent.componentName == "Product" && node.parent.props.dynamic
            ? true
            : false;
        result.push(product_dynamic);
        return this.getAncestor(node.parent, result);
      } else {
        return result;
      }
    },
    checkNodeDynamic(node) {
      const ancestors = this.getAncestor(node);
      if (
        (ancestors.includes(true) && node.props.parent == "Product") ||
        node.props.dynamic
      ) {
        return true;
      }
      return false;
    },
  },
  data() {
    return {
      headerButton: {
        font_weight: "400",
        font_size: 16,
        font_family: "inherit",
        text_transform: "capitalize",
        background_color: "rgb(0, 0, 0)",
        color: "rgb(255, 255, 255)",
        scroll_speed: "2000",
        alignment: "center",
        padding_bottom: 12,
        padding_left: 20,
        padding_right: 20,
        padding_top: 12,
        text_align: "center",
        border_top_left_radius: 24,
        border_top_right_radius: 24,
        border_bottom_right_radius: 24,
        border_bottom_left_radius: 24,
        width_type: "%",
        height: "auto",
      },
      showCustomLayoutModal: false,
      node: null,
      query: "",
      sections: Object.values(toolbarType),
      siteSettings: WrapperContainer.craft.settings,
      groups: [
        "base",
        "video",
        "stack",
        "list",
        "product",
        "banner",
        "form_field",
        "booster",
        "checkout",
        "popup",
        "module",
        "article",
        "collection",
      ],
      ElementBlock,
      ElementBlockFrontAws,
      rowProps: {
        elementStyle: {
          ...Container.craft.defaultProps.elementStyle,
          "flex-direction": "row",
        },
      },
      qrStaticProps: {
        canDrag: function () {
          return false;
        },
      },
      qrRowProps: {
        extra_class: "sg-hidden",
        disabled: true,
        full_width: false,
        vertical_align: "center",
        equal_height: true,
        desktop: {
          ...Row.craft.defaultProps.desktop,
          layout: "8_4",
          width: "1000",
        },
        laptop: {
          ...Row.craft.defaultProps.laptop,
          layout: "8_4",
          width: "1000",
        },
        tablet: {
          ...Row.craft.defaultProps.tablet,
          layout: "8_4",
          width: "1000",
        },
        mobile: {
          ...Row.craft.defaultProps.mobile,
          layout: "12",
        },
      },
      qrColumnLeftProps: {
        dataLayoutLg: "8",
        dataLayoutMd: "8",
        dataLayoutSm: "8",
        dataLayoutXl: "12",
      },
      qrColumnRightProps: {
        dataLayoutLg: "4",
        dataLayoutSm: "4",
        dataLayoutMd: "4",
        dataLayoutXl: "12",
      },
      pricingItems: Pricing.craft.defaultProps.items,
      accordionItems: Accordion.craft.defaultProps.items,
      tabItems: Tabs.craft.defaultProps.items,
      iconItems: IconListHoz.craft.defaultProps.items,
      iconListItems: IconList.craft.defaultProps.items,
      carouselItems: Carousel.craft.defaultProps.items,
      // selectedNode: null,

      checkoutInfoRowProps: {
        extra_class: "",
        disabled: true,
        full_width: true,
        vertical_align: "center",
        equal_height: true,
        column_gap: '6',
        desktop: {
          ...Row.craft.defaultProps.desktop,
          layout: "12",
          width: "1000",
          margin_top: 0,
          padding_top: 0,
          padding_left: 0,
          padding_right: 0,
          padding_bottom: 0,
        },
        laptop: {
          ...Row.craft.defaultProps.laptop,
          layout: "12",
          width: "1000",
          margin_top: 0,
          padding_top: 0,
          padding_left: 0,
          padding_right: 0,
          padding_bottom: 0,
        },
        tablet: {
          ...Row.craft.defaultProps.tablet,
          layout: "12",
          width: "1000",
          margin_top: 0,
          padding_top: 0,
          padding_left: 0,
          padding_right: 0,
          padding_bottom: 0,
        },
        mobile: {
          ...Row.craft.defaultProps.mobile,
          layout: "12",
          margin_top: 0,
          padding_top: 0,
          padding_left: 0,
          padding_right: 0,
          padding_bottom: 0,
        },
      },
      checkoutInfo2RowProps: {
        extra_class: "",
        disabled: true,
        full_width: true,
        vertical_align: "center",
        equal_height: true,
        column_gap: '6',
        desktop: {
          ...Row.craft.defaultProps.desktop,
          layout: "6_6",
          width: "1000",
          margin_top: 0,
          padding_top: 0,
          padding_left: 0,
          padding_right: 0,
          padding_bottom: 0,
        },
        laptop: {
          ...Row.craft.defaultProps.laptop,
          layout: "6_6",
          width: "1000",
          margin_top: 0,
          padding_top: 0,
          padding_left: 0,
          padding_right: 0,
          padding_bottom: 0,
        },
        tablet: {
          ...Row.craft.defaultProps.tablet,
          layout: "12",
          width: "1000",
          margin_top: 0,
          padding_top: 0,
          padding_left: 0,
          padding_right: 0,
          padding_bottom: 0,
        },
        mobile: {
          ...Row.craft.defaultProps.mobile,
          layout: "12",
          margin_top: 0,
          padding_top: 0,
          padding_left: 0,
          padding_right: 0,
          padding_bottom: 0,
        },
      },
      checkoutInfoColumnProps: {
        dataLayoutLg: "12",
        dataLayoutMd: "12",
        dataLayoutSm: "12",
        dataLayoutXl: "12",
      },
      checkoutInfo2ColumnProps: {
        dataLayoutLg: "6",
        dataLayoutMd: "6",
        dataLayoutSm: "12",
        dataLayoutXl: "12",
      },
      checkoutIconList: {
        disabled: true,
        desktop: {
          ...IconList.craft.defaultProps.desktop,
          alignment: 'left',
          padding_top: 0,
          padding_left: 0,
          padding_right: 0,
          padding_bottom: 0,
        }
      },
      checkoutIcon: {
        desktop: {
          ...Icon.craft.defaultProps.desktop,
          font_size: 24,
          alignment: 'center',
        }
      },
      checkoutTextBlock: {
        desktop: {
          ...Paragraph.craft.defaultProps.desktop,
          font_weight: '500',
        }
      },
      checkoutEmailField: {
        desktop: {
          ...EmailField.craft.defaultProps.desktop,
          border_color: 'rgba(193, 193, 193, 1)',
          border_style: 'solid',
          border_top_width: 1,
          border_right_width: 1,
          border_bottom_width: 1,
          border_left_width: 1,
          border_top_left_radius: 8,
          border_top_right_radius: 8,
          border_bottom_right_radius: 8,
          border_bottom_left_radius: 8,
        }
      },
      checkoutTextField: {
        desktop: {
          ...TextField.craft.defaultProps.desktop,
          border_color: 'rgba(193, 193, 193, 1)',
          border_style: 'solid',
          border_top_width: 1,
          border_right_width: 1,
          border_bottom_width: 1,
          border_left_width: 1,
          border_top_left_radius: 8,
          border_top_right_radius: 8,
          border_bottom_right_radius: 8,
          border_bottom_left_radius: 8,
        }
      },
      checkoutSelectField: {
        desktop: {
          ...SelectField.craft.defaultProps.desktop,
          border_color: 'rgba(193, 193, 193, 1)',
          border_style: 'solid',
          border_top_width: 1,
          border_right_width: 1,
          border_bottom_width: 1,
          border_left_width: 1,
          border_top_left_radius: 8,
          border_top_right_radius: 8,
          border_bottom_right_radius: 8,
          border_bottom_left_radius: 8,
        }
      }
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../app.scss";

.sidebar {
  position: absolute;
  top: $navbar-height;
  left: 0;
  bottom: 0;
  width: $sidebar-width;
  padding: 0px 12px;
  overflow: auto;
  background-color: $color-black;

  transition: 0.2s transform;
  transition-timing-function: ease-in-out;
  &.disable {
    transform: translateX(-100%);
  }

  // @include scrollbar();
  .input-search {
    width: 100%;
    background-color: #333333 !important;
    color: #fff !important;
    border-radius: 12px !important;
    border: none;
    padding: 10px 12px;
  }

  .input-search:focus {
    outline: none;
  }

  .element-container {
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    position: relative;
    clear: both;
    overflow: hidden;
  }
}

$actions-height: 50px;

.sidebar-settings {
  position: absolute;
  top: $navbar-height;
  left: 0;
  bottom: 0;
  width: $sidebar-width;
  background-color: $color-black;

  transition: 0.2s transform;
  transition-timing-function: ease-in-out;
  &.disable {
    transform: translateX(100%);
  }
  .title {
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    span {
      color: #fff;
      font-size: 14px;
      text-transform: uppercase;
      font-weight: 600;
    }
    svg {
      cursor: pointer;
    }
  }
}

.content {
  max-height: calc(100vh - 160px);
  padding: 5px 10px;
  overflow: auto;

  // @include scrollbar();

  &.has-actions {
    bottom: $actions-height;
  }
}

.actions {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: $actions-height;
  line-height: $actions-height;
  padding: 0 0.8em;
  border-top: 1px solid #4a4c50;
  background-color: $color-black;
  button {
    padding: 7px;
    & + button {
      margin-left: 6px;
    }
  }
}

.item-wrapper.expanded {
  .el-collapse-item {
    // margin-bottom: 10px;
    width: 100%;
    background-color: transparent;
    border-radius: 6px;
    padding: 0 0;
  }
}

@media only screen and (max-width: 1300px) {
  .sidebar {
    width: $sm-sidebar-width;
  }
  .sidebar-settings {
    width: $sm-sidebar-width;
  }
}
</style>

<style lang="scss">
.list-blocks {
  .el-collapse-item__header {
    background-color: transparent;
    color: #ccc;
    margin-left: 10px;
    border-bottom: none;
    text-transform: uppercase;
    font-weight: 300;
    font-size: 14px;
  }

  .el-collapse-item__wrap {
    border-bottom: none;
    background-color: transparent;
  }
  .el-collapse-item__content {
    padding: 0px;
  }
}

.sidebar-settings {
  .setting-title {
    color: #aaaaaa;
    font-size: 12px;
  }
}
.input-search-element {
  position: sticky;
  top: 0;
  z-index: 2;
}
.sidebar-settings {
  z-index: 2;
}
</style>

<style lang="scss">
$tabs: elements, site_settings;
.sidebar {
  #tab-elements,
  #tab-site_settings {
    display: none;
    &:checked + label {
      color: #2a85ff;
    }
    @each $tab in $tabs {
      $i: index($tabs, $tab);
      &#tab-#{$tab}:checked {
        ~ .line-container .line {
          left: #{calc(($i - 1) * 50%)};
        }
        ~ .content-container #content-#{$tab} {
          display: block;
        }
      }
    }
  }
  @each $tab in $tabs {
    $i: index($tabs, $tab);
    &#tab-#{$tab}:checked {
      ~ .line-container .line {
        left: #{calc(($i - 1) * 50%)};
      }
      ~ .content-container #content-#{$tab} {
        display: block;
      }
    }
  }
}

.sidebar {
  border-top: 1px solid #444444;
  label.tab-label-sidebar {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 42px;
    line-height: 42px;
    width: 50%;
    text-align: center;
    background: #202124;
    color: #6f767e;
    font-weight: 600;
    position: relative;
    transition: 0.25s background ease;
    cursor: pointer;
    &::after {
      content: "";
      height: 2px;
      width: 100%;
      position: absolute;
      display: block;
      bottom: 0;
      opacity: 1;
      background-color: #444444;
      left: 0;
      transition: 0.25s ease;
    }
    &:hover::after {
      opacity: 1;
    }
    span {
      margin-left: 5px;
      font-size: 13px;
    }
  }
  .line {
    position: absolute;
    height: 2px;
    background: #70767d;
    width: 50%;
    top: -2px;
    left: 0px;
    transition: 0.25s ease;
  }
  .content-container {
    -ms-overflow-style: none;
    scrollbar-width: none;
    background-color: #202124;
    position: relative;
    height: calc(100vh - 110px);
    overflow: auto;
    font-size: 14px;
    .tab-content {
      position: absolute;
      // padding: 10px;
      width: 100%;
      top: 0;
      display: none;
      transition: 0.25s ease;
      background-color: #202124;
    }
  }
  .content-container::-webkit-scrollbar {
    display: none;
  }
}
</style>
