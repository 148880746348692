<template>
  <div label-position="top" size="small" :model="elementProps">

    <div class="d-flex flex-column py-2">
      <span class="setting-title">Font Weight</span>
      <Select
        class="mt-2"
        :primaryColor="primaryColor"
        :value="getValue('quantity_font_weight',elementProps[screen].quantity_font_weight)"
        v-bind:changeValue="($event) => updateProps('quantity_font_weight', $event)"
        :options="[
          {
            value: '100',
            label: 'Thin 100',
          },
          {
            value: '200',
            label: 'Semi Thin 200',
          },
          {
            value: '300',
            label: 'Light 300',
          },
          {
            value: '400',
            label: 'Regular 400',
          },
          {
            value: '500',
            label: 'Medium 500',
          },
          {
            value: '600',
            label: 'Semi Bold 600',
          },
          {
            value: '700',
            label: 'Bold 700',
          },
          {
            value: '800',
            label: 'Super Bold 800',
          },
          {
            value: '900',
            label: 'Boldest 900',
          },
          {
            value: 'lighter',
            label: 'Lighter',
          },
          {
            value: 'bolder',
            label: 'Bolder',
          },
        ]"
      />
    </div>

    <div class="d-flex flex-column py-2">
      <span class="setting-title">Font Size</span>
      <Slider
        :value="getValue('quantity_font_size',elementProps[screen].quantity_font_size)"
        :primaryColor="primaryColor"
        v-bind:changeValue="($event) => updateProps('quantity_font_size', $event)"
        :max="100"
        :step="1"
        unit="px"
        :onlyInteger="true"
      />
    </div>
    <div class="d-flex flex-column py-2">
      <span class="setting-title">Color</span>
      <ColorPicker
        class="mt-2"
        :value="getValue('quantity_color',elementProps[screen].quantity_color)"
        v-bind:changeColor="($event) => updateProps('quantity_color', $event)"
        :primaryColor="primaryColor"
      />
    </div>
    <div class="d-flex flex-column py-2">
      <span class="setting-title">Font</span>
      <Select
        class="mt-2"
        :primaryColor="primaryColor"
        :value="getValue('quantity_font_family',elementProps[screen].quantity_font_family)"
        v-bind:changeValue="($event) => updateProps('quantity_font_family', $event)"
        :hasAction="true"
        v-bind:onAction="handleShowModal"
        actionLabel="Open Font Manager"
        :options="getListFont()"
      />
    </div>

    <div class="d-flex flex-column py-2">
      <span class="setting-title">Default Quantity</span>
      <el-input
        class="mt-2"
        size="medium"
        :value="elementProps.default_quantity"
        @input="($event) => updateCommonProps('default_quantity', $event)"
      />
    </div>
  </div>
</template>

<script>
import mixin from "../../../elements/styleSettings/mixin";
import ColorPicker from "../../../elements/common/ColorPicker.vue";
import Slider from "../../../elements/common/Slider.vue";
import ToggleButton from "../../../elements/common/ToggleButton.vue";
import Select from "../../../elements/common/Select.vue";
import Node from "@/core/Node";
import { makeId } from "@/constants";

export default {
  mixins: [mixin],
  components: {ColorPicker, Slider, ToggleButton, Select},
  props: {
    primaryColor: String,
    screen: String,
    node: Node,
    activeFonts: Array,
  },
  data() {
    return {
      fontKey: makeId(20)
    }
  },
  watch: {
    activeFonts: function (newValue, oldValue) {
      this.fontKey = makeId(20)
    }
  },
  methods: {
    handleShowModal() {
      this.$bus.emit("showFontManager", this.node);
    },
    getListFont() {
      const listFonts = [
        {
          value: 'inherit',
          label: 'Default',
        }
      ]
      this.node?.editor?.fonts?.map(e => {
        listFonts.push({ value: e, label: e })
      })
      return listFonts;
    },
    updateProps(name, value) {
      const screen = this.$props.screen
      const data_screen = Object.assign({}, this.elementProps[screen])
      if(value ==  data_screen[name]) return;

      data_screen[name] = value
      this.elementPropsSetter({ [screen]: data_screen });
    },
    updateCommonProps(name, value) {
      this.elementPropsSetter({ [name]: value });
    },
  },
};
</script>

<style scoped>
</style>
