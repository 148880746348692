<template>
  <div
    data-label="(P) Image List"
    data-key="(p) image list"
    data-parent="product"
    class="sg-wrap module"
    :id="`element-${node.uuid}`"
    @mouseover="isHovering = true"
    @mouseout="isHovering = false"
    :data-product-uuid="product.product_node_uuid"
    :data-parent-id="`${node.parent.uuid}`"
    :data-pid="product.product_id"
  >
    <div
      class="style-default sg-module main-slider"
      :class="`${extra_class || ''} ${lightbox_icon? 'gallery-icon-sg':''}`"
      :id="`element-${node.uuid}-image-list`"
      :data-product-node="product.product_node_uuid"
      :data-navspeed="navSpeed"
      :data-loop="loop || 0"
      :data-style="display_style"
      :data-collg="desktop.columns"
      :data-colmd="laptop.columns"
      :data-colsm="tablet.columns"
      :data-colxs="mobile.columns"
      :data-dotslg="desktop.show_dots  || 0"
      :data-dotsmd="laptop.show_dots  || 0"
      :data-dotssm="tablet.show_dots  || 0"
      :data-dotsxs="mobile.show_dots  || 0"
      :data-marginlg="desktop.spacing"
      :data-marginmd="laptop.spacing"
      :data-marginsm="tablet.spacing"
      :data-marginxs="mobile.spacing"
      :data-navlg="desktop.show_pre_next  || 0"
      :data-navmd="laptop.show_pre_next  || 0"
      :data-navsm="tablet.show_pre_next  || 0"
      :data-navxs="mobile.show_pre_next  || 0"
      :data-gallery="lightbox  || 0"
      :data-galleryicon="lightbox_icon  || 0"
      :data-borderactive="borderactive ? 1 : 0"
      data-3d-model="0"
      :data-display-style="display_style"
    >
      <div class="sg_product-images-list"
        :class="display_style=='slider'?`sg_product-slider owl-carousel owl-theme`:''"
      >
      </div>
    </div>

    <ToolBar
      ref="(p)imagelist"
      :toolbar_id="`(p)imagelist`"
      class="container-toolbar"
      :class="{ 'container-toolbar-show': isHovering, 'is-dragging': dragging == node.uuid }"
    >
    </ToolBar>

    <div v-if="dragging == node.uuid" class="dragging"></div>

    <v-style> 
      {{ custom_css }}

      #element-{{ node.uuid }} .sg_product-image-thumb.active {
        border-width: {{ border_width }}px !important;
        border-color: {{ border_color }} !important;
      }

      @media (max-width: 767px){ 
        #element-{{ node.uuid }} .sg_product-image-thumb {
          width: {{ getValue('width','mobile') || 'auto'}}{{ getValue('width_type','mobile') || "" }};
          height: {{ getValue('height','mobile') || '100'}}{{ getValue('height','mobile') == "auto" ? "" : "px" }};
        } 
        #element-{{ node.uuid }} .sg-module {
          text-align: {{ getValue('alignment','mobile') }};
        }

        #element-{{ node.uuid }} {
          {{ getDisplayValue('mobile') }}
        }

        #element-{{ node.uuid }} .sg_product-image-thumb {
          {{ getDisplayValue('mobile') }}
          {{ getMarginValue('mobile') }}
          {{ getPaddingValue('mobile') }}
          {{ getBackgroundValue('mobile') }}
          {{ getBorderValue('mobile') }}
          {{ getBorderRadiusValue('mobile') }}
          {{ getOpacityValue('mobile') }}
          {{ getShadowValue('mobile') }}
          {{ getOtherStyle('mobile') }}
        }
        .sg_featherlight-item {
          width: 60vh; 
          height: 60vh;
        }
        
      } 
      @media (max-width: 991px) and (min-width: 768px){ 
        #element-{{ node.uuid }} .sg_product-image-thumb {
          width: {{ getValue('width','tablet') || 'auto'}}{{ getValue('width_type','tablet') || "" }};
          height: {{ getValue('height','tablet') || '100'}}{{ getValue('height','tablet') == "auto" ? "" : "px" }};
        } 
        #element-{{ node.uuid }} .sg-module {
          text-align: {{ getValue('alignment','tablet') }};
        }

        #element-{{ node.uuid }} {
          {{ getDisplayValue('tablet') }}
        }

        #element-{{ node.uuid }} .sg_product-image-thumb {
          {{ getDisplayValue('tablet') }}
          {{ getMarginValue('tablet') }}
          {{ getPaddingValue('tablet') }}
          {{ getBackgroundValue('tablet') }}
          {{ getBorderValue('tablet') }}
          {{ getBorderRadiusValue('tablet') }}
          {{ getOpacityValue('tablet') }}
          {{ getShadowValue('tablet') }}
          {{ getOtherStyle('tablet') }}
        }
        .sg_featherlight-item {
          width: 80vh; 
          height: 80vh;
        }
      } 
      @media (max-width: 1199px) and (min-width: 992px){ 
        #element-{{ node.uuid }} .sg_product-image-thumb {
          width: {{ getValue('width','laptop') || 'auto'}}{{ getValue('width_type','laptop') || "" }};
          height: {{ getValue('height','laptop') || '100'}}{{ getValue('height','laptop') == "auto" ? "" : "px" }};
        } 
        #element-{{ node.uuid }} .sg-module {
          text-align: {{ getValue('alignment','laptop') }};
        }

        #element-{{ node.uuid }} {
          {{ getDisplayValue('laptop') }}
        }

        #element-{{ node.uuid }} .sg_product-image-thumb {
          {{ getDisplayValue('laptop') }}
          {{ getMarginValue('laptop') }}
          {{ getPaddingValue('laptop') }}
          {{ getBackgroundValue('laptop') }}
          {{ getBorderValue('laptop') }}
          {{ getBorderRadiusValue('laptop') }}
          {{ getOpacityValue('laptop') }}
          {{ getShadowValue('laptop') }}
          {{ getOtherStyle('laptop') }}
        }
        .sg_featherlight-item {
          width: 80vh; 
          height: 80vh;
        }
      } 
      @media (min-width: 1200px){ 
        #element-{{ node.uuid }} .sg_product-image-thumb {
          width: {{ desktop.width || 'auto'}}{{ desktop.width_type || "" }};
          height: {{ desktop.height || '100'}}{{ desktop.height == "auto" ? "" : "px" }};
        } 
        #element-{{ node.uuid }} .sg-module {
          text-align: {{ desktop.alignment }};
        }
        
        #element-{{ node.uuid }} {
          {{ getDisplayValue('desktop') }}
        }

        #element-{{ node.uuid }} .sg_product-image-thumb {
          {{ getMarginValue('desktop') }}
          {{ getPaddingValue('desktop') }}
          {{ getBackgroundValue('desktop') }}
          {{ getBorderValue('desktop') }}
          {{ getBorderRadiusValue('desktop') }}
          {{ getOpacityValue('desktop') }}
          {{ getShadowValue('desktop') }}
          {{ getOtherStyle('desktop') }}
        }
        .sg_featherlight-item {
          width: 80vh; 
          height: 80vh;
        }
      }
        
    </v-style>
  </div>
</template>

<script>
import ToolBar from "../../elements/utils/ToolBar.vue";

import elementStyleMixin from "../../elements/elementStyleMixin";
import ExtraClass from "../../elements/otherSettings/ExtraClass.vue";
 import CustomCSS from '../../elements/styleSettings/CustomCSS.vue';
import AlignmentBase from "../../elements/styleSettings/AlignmentBase.vue";
import DisplayImageList from "./settings/DisplayImageList.vue";
import ActiveBorder from "./settings/ActiveBorder.vue";
import Gallery from "./settings/Gallery.vue";

import Visibility from "../../elements/designSettings/Visibility.vue";
import Spacing from "../../elements/designSettings/Spacing.vue";
import Background from "../../elements/designSettings/Background.vue";
import Border from "../../elements/designSettings/Border.vue";
import Corner from "../../elements/designSettings/Corner.vue";
import Shadow from "../../elements/designSettings/Shadow.vue";
import Opacity from "../../elements/designSettings/Opacity.vue";
import { makeId, designStyles, otherProps, otherStyles, actionTypes } from "@/constants";
import Vue from "vue";
import Animations from '../../elements/designSettings/Animations.vue';

export default {
  components: { ToolBar },
  data() {
    return {
      isHovering: false,
      cdn_url: process.env.VUE_APP_API_URL,
    };
  },
  computed: {
    clonedItems: function () {
      return JSON.parse(
        JSON.stringify({
          ...this.$props,
          screen: this.screen,
          product: this.product,
          isUndo: this.editor.isUndo,
          isRedo: this.editor.isRedo,
        })
      );
    },
    dragging: function () {
      return this.editor?.draggedNode?.uuid
    },
  },
  mounted() {
    if(this.product){
      this.loadCarousel()
    }
  },
  methods: {
    listenChange(val, oldVal) {
      var childs = [
        "columns",
        "spacing",
        "show_pre_next",
        "navSpeed",
        "show_dots",
      ];
      var parents = [
        "children",
        "screen",
        "desktop",
        "laptop",
        "tablet",
        "mobile",
        "display_style",
        "isUndo",
        "isRedo"
      ];
      for (let i = 0; i < parents.length; i++) {
        if (typeof val[parents[i]] == "object") {
          for (let j = 0; j < childs.length; j++) {
            if (val[parents[i]][childs[j]] != oldVal[parents[i]][childs[j]]) {
              return `${childs[j]} (${parents[i]})`;
              break;
            }
          }
        } else {
          if (val[parents[i]] != oldVal[parents[i]]) {
            return parents[i];
            break;
          }
        }
      }
      if(val?.product?.title!= oldVal?.product?.title){
        return val?.product?.title
      }
      return false;
    },
    loadCarousel(data){
      setTimeout(() => {
        const node_uuid = this.node.uuid;
        var $iframe = $("#designEditor").contents();
        var $parent = $iframe.find(`#element-${this.node.uuid}-image-list`);
        const {navSpeed,desktop, laptop,tablet,mobile, screen} = this;
        var navspeed = navSpeed,
          navlg = desktop.show_pre_next,
          navmd = laptop.show_pre_next,
          navsm = tablet.show_pre_next,
          navxs = mobile.show_pre_next,
          collg = desktop.columns,
          colmd = laptop.columns,
          colsm = tablet.columns,
          colxs = mobile.columns,
          dotslg = desktop.show_dots,
          dotsmd = laptop.show_dots,
          dotssm = tablet.show_dots,
          dotsxs = mobile.show_dots,
          marginlg = parseInt(desktop.spacing),
          marginmd = parseInt(laptop.spacing),
          marginsm = parseInt(tablet.spacing),
          marginxs = parseInt(mobile.spacing),
          autoplay = 0,
          autoRefresh = 0,
          loop = 0;
        switch (screen) {
          case "laptop":
            collg =  laptop.columns
            navlg =  laptop.show_pre_next
            dotslg =  laptop.show_dots
            marginlg =  parseInt(laptop.spacing)
            break;
          case "tablet":
            collg =  tablet.columns
            navlg =  tablet.show_pre_next
            dotslg =  tablet.show_dots
            marginlg =  parseInt(tablet.spacing)
            break;
          case "mobile":
            collg =  mobile.columns
            navlg =  mobile.show_pre_next
            dotslg =  mobile.show_dots
            marginlg =  parseInt(mobile.spacing)
            break;
          default:
            break;
        }
        var $owl = $parent.find(".sg_product-images-list");
        $owl.trigger("destroy.owl.carousel");
        $owl.removeClass("owl-center owl-loaded owl-text-select-on");
        $owl.empty();
        var borderactive = data?.borderactive;
        if (borderactive == undefined) {
          borderactive = parseInt($parent.data('borderactive'));
        }
        const childrens = this.product.images;
        const product = this.product;
        const url = this.cdn_url;
        const el = document.createElement("DIV"); // we will mount or nested app to this element
        const carousel = this?.$el?.getElementsByClassName("sg_product-images-list")[0];
        const component = this;
        for (var i = 0; i < childrens?.length ||0; i++) {
          carousel.appendChild(el);
          const image_url = `${childrens[i].image?.includes("http") ? "" : url}${childrens[i].image || ""}`;
          const item = new Vue({
            name: "Item",
            data: {},
            render(createElement) {
              return createElement(
                "a",
                {
                  attrs: {
                    class: "item",
                    href:"javascript:void(0)",
                    id: 'sg_product-image-thumb-' + String(i)
                  },
                },
                [
                  createElement(
                    "div",
                    {
                      attrs: {
                        class: borderactive == 1 && i == 0 ? "sg_product-image-thumb active" : "sg_product-image-thumb",
                        "data-image": image_url,
                        "data-zoom": image_url,
                        "data-node-uuid": node_uuid
                      },
                      on: {
                        click: function(e) {
                          if (component.lightbox) {
                            component.showPreview(image_url);
                          }
                          const parent_id = e.target.offsetParent.id;
                          var $imageWrapper = $parent.find(`#${parent_id} .sg_product-image-thumb`);
                          if (borderactive == 1) {
                            $parent.find('.sg_product-image-thumb').removeClass('active');
                            $imageWrapper.addClass('active');
                          }
                          const imgUrl = $imageWrapper.data('image');
                          const product_node_uuid = $parent.data('product-node');
                          var $product = $iframe.find(`#element-${product_node_uuid}`);
                          if ($product) {
                            var $product_image = $product.find("[data-label='(P) Image']");
                            if ($product_image) {
                              $product_image.find('.sg_product-image').attr('src', imgUrl);
                            }
                          }
                        }
                      }
                    },
                    [
                      createElement("img", {
                        attrs: {
                          src: image_url,
                          width: "800",
                          height: "800",
                          alt: product.title,
                        },
                      }),
                    ]
                  ),
                ]
              );
            },
          });
          item.$mount(el);
        }
        if ($owl.attr('class').includes("owl-carousel")){
          $owl.owlCarousel({
            mouseDrag: false,
            autoplay: autoplay,
            autoRefresh: autoRefresh,
            autoplaySpeed: navspeed,
            loop: loop,
            navSpeed: navspeed,
            autoWidth: !1,
            responsiveClass: true,
            responsive: {
              0: {
                items: colxs,
                nav: navxs,
                dots: dotsxs,
                margin: marginxs,
              },
              768: {
                items: colsm,
                nav: navsm,
                dots: dotssm,
                margin: marginsm,
              },
              992: {
                items: colmd,
                nav: navmd,
                dots: dotsmd,
                margin: marginmd,
              },
              1200: {
                items: collg,
                nav: navlg,
                dots: dotslg,
                margin: marginlg,
              },
            },
          });
        }
      },500);
    },
    showPreview(current_url){
      var $iframe = $("#designEditor").contents();
      $iframe.find('#sg_featherlight-v4').find(".sg_featherlight-content").find("div[id^='sg_featherlight-item']").remove();
      const data_product = this.product;
      const cdn_url = this.cdn_url
      $.each(data_product['images'], function( index, value ) {
        var url_image = value['image'].includes("http")? value['image'] : (cdn_url + value['image']);
        var style = current_url == url_image ? "opacity: 1; display: block; overflow:hidden": "opacity: 1; display: none; overflow:hidden";
        const elItem = document.createElement("DIV");
        const item = new Vue({
          name: "item",
          data: {},
          render(createElement) {
            return createElement('div', {
              attrs: { 
                id : "sg_featherlight-item-"+index,
                "data-src":url_image,
                "style":style,
                "data-loaded":"1",
                "data-width":"800",
                "data-height":"800", 
              }, 
              "class":"sg_featherlight-item sg_featherlight-image"
            }, [<img src={url_image}/>]);
          },
        });
        $iframe.find('#sg_featherlight-v4').find(".sg_featherlight-content").append(elItem);
        item.$mount(elItem);
      });
      $iframe.find('#sg_featherlight-v4').show();
    }
  },
  inject: ["node", "product","editor"],
  mixins: [elementStyleMixin],
  props: {
    extra_class: String, ...otherProps,
    component: String,
    parent: String,
    border: Boolean,
    display_style: String,
    loop: Boolean,
    desktop: Object,
    laptop: Object,
    tablet: Object,
    mobile: Object,
    screen: String,
    navSpeed: Number,
    borderactive: Boolean,
    border_color: String,
    border_width: Number,
    lightbox_icon:Boolean,
    lightbox:Boolean
  },
  watch: {
    display_style: {
      handler: function (val, oldVal) {
        // var check = this.listenChange(val, oldVal);
        // if (check) {
        //   this.loadCarousel();
        // }else{
        //   return false
        // }
      },
      deep: true,
    },
    clonedItems: {
      handler: function (val, oldVal) {
        var check = this.listenChange(val, oldVal);
        if (check) {
          this.loadCarousel();
        }else{
          return false
        }
      },
      deep: true,
    },
    dragging: function (newValue, oldValue) {
      var $iframe = $("#designEditor").contents();
      var $element = $iframe.find(`#element-${this.node.uuid}`);
      var $content = $element.find(`.sg-module`);
      if (newValue && newValue == this.node.uuid) {
        setTimeout(() => {
        $content.hide();
        },100);
      }else if (oldValue == this.node.uuid){
        $content.show();
      }
    },
    borderactive: function (val, oldVal) {
      var $iframe = $("#designEditor").contents();
      var $element = $iframe.find(`#element-${this.node.uuid}`);
      if (val) {
        var $image = $element.find('.sg_product-image-thumb').first();
        if ($image) {
          $image.addClass('active');
        }
        this.loadCarousel({borderactive: 1});
      } else {
        var $image = $element.find('.sg_product-image-thumb.active');
        if ($image) {
          $image.removeClass('active');
        }
        this.loadCarousel({borderactive: 0});
      }
    }
  },
  created() {
  },
  craft: {
    defaultProps: { ...otherStyles,
      parent: "Product",
      typeWidth: null,
      display_style: "default",
      loop: false,
      extra_class: "",
      navSpeed: 1200,
      typeWidth:null,
      borderactive:false,
      border_color: "rgb(97, 97, 97)",
      border_width: 1,
      lightbox:false,
      lightbox_icon:false,
      desktop: {
        ...designStyles,
        columns: 3,
        spacing: 5,
        show_dots: true,
        show_pre_next: false,
        width: "auto",
        height: "100",
        width_type: "",
        alignment: "left",
        margin_bottom: 6,
        margin_right: 6,
        image_spacing:6,
      },
      laptop: {
        ...designStyles,
        columns: 3,
        spacing: 5,
        image_spacing:6,
        show_dots: true,
        show_pre_next: false,

      },
      tablet: {
        ...designStyles,
        columns: 3,
        spacing: 5,
        image_spacing:6,
        show_dots: true,
        show_pre_next: false,

      },
      mobile: {
        ...designStyles,
        columns: 2,
        spacing: 5,
        image_spacing:6,
        show_dots: true,
        show_pre_next: false,
      },
      elementStyle: {
      },
    },
    settings: {
      settings: {
        Display: { "Display":DisplayImageList, multi_screen: true },
        // "Original Size": OriginalSize,
        Gallery,
        "Active Border": ActiveBorder,
        Alignment: {Alignment: AlignmentBase, multi_screen: true },
        "Extra Class": ExtraClass, "Custom CSS": CustomCSS,
      },
      design: {
        Visibility,
        Spacing: { Spacing, multi_screen: true },
        Background: { Background, multi_screen: true },
        Border: { Border, multi_screen: true },
        Corner: { Corner, multi_screen: true },
        Shadow: { Shadow, multi_screen: true },
        Opacity: { Opacity, multi_screen: true },
      },
      others: {
        Animations: { Animations, actions: actionTypes.filter(e => ['scroll_into_view', 'after_page_finish_loading'].includes(e.value)) }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>

<!-- <v-script> 
  $(document).ready(function () {
    var parent = $('#element-{{ node.uuid }}-image-list');
    var gallery = parent.attr("data-gallery");
    var navspeed = parent.data("navspeed"),
      autoplaytimeout = parent.data("autoplaytimeout"),
      autoplayhoverpause = parent.data("autoplayhoverpause"),
      navlg = parent.data("navlg"),
      navmd = parent.data("navmd"),
      navsm = parent.data("navsm"),
      navxs = parent.data("navxs"),
      collg = parent.data("collg"),
      colmd = parent.data("colmd"),
      colsm = parent.data("colsm"),
      colxs = parent.data("colxs"),
      dotslg = parent.data("dotslg"),
      dotsmd = parent.data("dotsmd"),
      dotssm = parent.data("dotssm"),
      dotsxs = parent.data("dotsxs"),
      marginlg = parseInt(parent.data("marginlg")),
      marginmd = parseInt(parent.data("marginmd")),
      marginsm = parseInt(parent.data("marginsm")),
      marginxs = parseInt(parent.data("marginxs"));
    var mode = $("body").hasClass("production") ? "production" : "dev";
    if (mode == "production") {
      var autoplay = 0,
        autoRefresh = false,
        loop = parent.data("loop");
    } else {
      var autoplay = 0,
        autoRefresh = false,
        loop = 0;
    }
    var owl = parent.children('.sg_product-images-list');
    const owl_class = owl.attr('class') || [];
    if (owl_class.includes("owl-carousel")) {
      owl.owlCarousel({
        mouseDrag: false,
        autoplayHoverPause: autoplayhoverpause,
        autoplay: autoplay,
        autoRefresh: autoRefresh,
        autoplaySpeed: navspeed,
        autoplayTimeout: autoplaytimeout,
        loop: loop,
        navSpeed: navspeed,
        autoWidth: !1,
        responsiveClass: true,
        responsive: {
          0: {
            items: colxs,
            nav: navxs,
            dots: dotsxs,
            margin: marginxs,
          },
          768: {
            items: colsm,
            nav: navsm,
            dots: dotssm,
            margin: marginsm,
          },
          992: {
            items: colmd,
            nav: navmd,
            dots: dotsmd,
            margin: marginmd,
          },
          1200: {
            items: collg,
            nav: navlg,
            dots: dotslg,
            margin: marginlg,
          },
        },
        onInitialized: function () {
          owl.closest('.sg-wrap[data-label="(P) Image List"]').addClass('sg-carousel-loaded');
          jQuery(window).trigger("resize");
          $('#element-{{ node.uuid }} .sg_product-image-thumb').off('click').on('click', function() {
            var root_element = $('#element-{{ node.uuid }}');
            const product_uuid = root_element.attr('data-product-uuid');
            var parent = $('#element-{{ node.uuid }}-image-list');
            var gallery = parent.attr("data-gallery");
            const border_active = parseInt(parent.data('borderactive'));
            if (gallery == "true") {
              $('#sg_featherlight-v4').children(".sg_featherlight-content").children("div[id^='sg_featherlight-item']").remove();
              var pid =  $('#element-{{ node.uuid }}').attr("data-pid");
              var data_product_text = $(`.data-product-${product_uuid}`).text();
              const data_product = JSON.parse(data_product_text);
              var current_url =  $(this).attr('data-image');
              $.each(data_product['images'], function( index, value ) {
                var url_image = value['image'].includes("http")? value['image'] : ('{{cdn_url}}' + value['image']);
                var style = current_url == url_image ? "opacity: 1; display: block; overflow:hidden": "opacity: 1; display: none;overflow:hidden";
                $('#sg_featherlight-v4').children(".sg_featherlight-content").append(
                  $('<div></div>').attr({ 
                    id : "sg_featherlight-item-" + index,
                    "data-src":url_image,
                    "style":style,
                    "data-loaded":"1",
                    "data-width":"800",
                    "data-height":"800"
                  }).addClass("sg_featherlight-item").addClass("sg_featherlight-image").append(
                    $('<img/>').attr({
                      "data-src": url_image,
                      "class": "lazyload"
                    })
                  )
                );
              });
              $('#sg_featherlight-v4').show();
            }
            if (border_active == 1) {
              $('#element-{{ node.uuid }} .sg_product-image-thumb').removeClass('active');
              $(this).addClass('active');
            }
            const imgUrl = $(this).data('image');
            const product_node_uuid = $('#element-{{ node.uuid }} .sg-module').data('product-node');
            const $product_image = $(`#element-${product_node_uuid}`).find('[data-label="(P) Image"]');
            if ($product_image) {
              $product_image.find('.sg_product-image').attr('src', imgUrl);
            }
          });
        }
      });
    }

    $('#element-{{ node.uuid }} .sg_product-image-thumb').on('click', function() {
      var parent = $('#element-{{ node.uuid }}-image-list');
      var root_element = $('#element-{{ node.uuid }}');
      const product_uuid = root_element.attr('data-product-uuid');
      const display_style = parent.data('display-style');
      if (display_style == 'default') {
        var gallery = parent.attr("data-gallery");
        const border_active = parseInt(parent.data('borderactive'));
        if (gallery == "true") {
          $('#sg_featherlight-v4').children(".sg_featherlight-content").children("div[id^='sg_featherlight-item']").remove();
          var pid =  $('#element-{{ node.uuid }}').attr("data-pid");
          var data_product_text = $(`.data-product-${product_uuid}`).text();
          const data_product = JSON.parse(data_product_text);
          var current_url =  $(this).attr('data-image');
          $.each(data_product['images'], function( index, value ) {
            var url_image = value['image'].includes("http")? value['image'] : ('{{cdn_url}}' + value['image']);
            var style = current_url == url_image ? "opacity: 1; display: block; overflow:hidden": "opacity: 1; display: none;overflow:hidden";
            $('#sg_featherlight-v4').children(".sg_featherlight-content").append(
              $('<div></div>').attr({ 
                id : "sg_featherlight-item-"+index,
                "data-src":url_image,
                "style":style,
                "data-loaded":"1",
                "data-width":"800",
                "data-height":"800"
              }).addClass("sg_featherlight-item").addClass("sg_featherlight-image").append(
                $('<img/>').attr({"src":url_image}))
              );
            });
            $('#sg_featherlight-v4').show();
        }
        if (border_active == 1) {
          $('#element-{{ node.uuid }} .sg_product-image-thumb').removeClass('active');
          $(this).addClass('active');
        }
        const imgUrl = $(this).data('image');
        const product_node_uuid = $('#element-{{ node.uuid }} .sg-module').data('product-node');
        const $product_image = $(`#element-${product_node_uuid}`).find('[data-label="(P) Image"]');
        if ($product_image) {
          $product_image.find('.sg_product-image').attr('src', imgUrl);
        }
      }
    });

  });
</v-script> -->