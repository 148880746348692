<template>
  <el-form label-position="top" size="small" :model="elementProps">
    <div class="d-flex flex-column py-2">
      <span class="setting-title">Variant Type</span>
      <Select 
        class="mt-2"
        :primaryColor="primaryColor"
        :value="elementProps.variant_type"
        v-bind:changeValue="($event) => updateProps('variant_type', $event)"
        :options="[
          {
            value: 'select',
            label: 'Select',
          },
          {
            value: 'swatch',
            label: 'Swatch',
          }
        ]"
      />
    </div>
  </el-form>
</template>

<script>
import mixin from "../../../elements/styleSettings/mixin";
import Select from "../../../elements/common/Select.vue";
import Node from "@/core/Node";

export default {
  mixins: [mixin],
  components: {
    Select
  },
  methods: {
    updateProps(key, value) {
      if(value ==  this.elementProps[name]) return;
      this.elementPropsSetter({ [key]: value })
    },
  },
  props: {
    primaryColor: String,
    node: Node
  }
};
</script>

<style scoped>
</style>
