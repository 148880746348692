var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"label-position":"top","size":"mini","model":_vm.elementProps}},[_c('div',{staticClass:"d-flex flex-column py-2"},[_c('span',[_vm._v("Border Type")]),_c('TabIcon',{staticClass:"mt-2",attrs:{"value":_vm.getValue('border_style',_vm.elementProps[_vm.screen].border_style),"changeValue":($event) => _vm.updateProps(_vm.screen, 'border_style', $event),"options":[
        { value: 'none', icon: 'no_border',tooltip: 'No border' },
        { value: 'solid', icon: 'solid',tooltip: 'Solid' },
        { value: 'dashed', icon: 'dashed',tooltip: 'Dashed' },
        { value: 'dotted', icon: 'dotted',tooltip: 'Dotted' },
      ],"canRemove":true,"primaryColor":_vm.primaryColor}})],1),_c('div',{staticClass:"d-flex flex-column py-2"},[_c('span',[_vm._v("Border Color")]),_c('ColorPicker',{staticClass:"mt-2",attrs:{"primaryColor":_vm.primaryColor,"value":_vm.getValue('border_color',_vm.elementProps[_vm.screen].border_color),"changeColor":($event) => _vm.updateProps(_vm.screen, 'border_color', $event)}})],1),_c('div',{staticClass:"d-flex flex-column py-2"},[_c('span',[_vm._v("Border Width")]),_c('Dimension',{attrs:{"units":['px'],"unit":"px","primaryColor":_vm.primaryColor,"data":_vm.elementProps[_vm.screen],"isPositive":true,"onlyInteger":true,"items":[
        {
          key: 'border_top_width',
          title: 'Top',
        },
        {
          key: 'border_right_width',
          title: 'Right',
        },
        {
          key: 'border_bottom_width',
          title: 'Bottom',
        },
        {
          key: 'border_left_width',
          title: 'Left',
        }
      ],"changeValue":(name, $event) => _vm.updateProps(_vm.screen, name, $event)}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }