var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-form',{attrs:{"label-position":"top","size":"","model":_vm.elementProps}},[_c('div',{staticClass:"py-3 px-3"},[_c('span',{staticClass:"setting-title"},[_vm._v("Column width")]),_c('ToggleButton',{attrs:{"primaryColor":_vm.primaryColor,"value":_vm.getValueLinked(
          'column_width',
          _vm.elementProps[_vm.screen]?.column_width,
          'fit_to_content'
        ),"changeValue":($event) => _vm.updateProps(_vm.screen, 'column_width', $event),"options":[
        { value: 'fit_to_content', label: 'Fit to content' },
        { value: 'full_widht', label: 'Full width' },
      ]}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }