<template>
  <div id="sg-font-manager">
    <el-dialog
      title="Font Manager"
      :visible.sync="dialogVisible"
      width="60%"
      top="8vh"
      :before-close="handleClose"
    >
      <div class="active-fonts">
        <div class="active-fonts__description">
          <div class="title">
            <span>Active Fonts</span>
            <svg v-if="activeFonts.length > 3" viewBox="0 0 16 13.272" width="16" height="13.272" class="mx-2">
              <g transform="matrix(0.032467, 0, 0, 0.032467, -0.000485, -1.363816)">
                <g>
                  <path d="M482.592,381.614L288.863,69.966c-11.22-18.044-26.348-27.96-42.656-27.96c-16.32,0-31.456,9.924-42.672,27.976
                  L10.267,381.142c-11.216,18.04-13.316,35.268-5.94,48.564c7.432,13.38,23.36,20.728,44.864,20.752l394.608,0.3h-0.336v0.04
                  c19.272,0,37.56-7.316,44.984-20.652C495.824,416.89,493.808,399.666,482.592,381.614z M256.96,388.59
                  c-2.868,2.86-6.736,4.484-10.792,4.484c-4.048,0-7.988-1.64-10.868-4.5c-2.856-2.86-4.476-6.852-4.472-10.932
                  c0.008-4.056,0.956-8.024,3.82-10.86c2.924-2.888,5.404-4.54,9.26-4.54l0.72-0.008c4.04,0,8.84,1.66,11.744,4.564
                  c2.872,2.856,4.932,6.812,4.924,10.876C261.292,381.762,259.852,385.742,256.96,388.59z M246.216,331.398
                  c-4.12,0-7.94-1.6-10.852-4.512c-2.912-2.916-4.488-6.792-4.484-10.92l-1.616-139.068c0.016-8.512,5.972-15.416,13.684-15.416
                  h1.772c4.124,0,8.88,1.604,11.788,4.52c2.916,2.92,4.932,6.788,4.928,10.916l0.1,139.068
                  C261.528,324.482,254.724,331.398,246.216,331.398z" style="fill: rgb(247, 128, 0);"></path>
                </g>
              </g>
              <g transform="matrix(0.571424, 0, 0, 0.571424, -0.000215, 0)"></g>
              <g transform="matrix(0.571424, 0, 0, 0.571424, -0.000215, 0)"></g>
              <g transform="matrix(0.571424, 0, 0, 0.571424, -0.000215, 0)"></g>
              <g transform="matrix(0.571424, 0, 0, 0.571424, -0.000215, 0)"></g>
              <g transform="matrix(0.571424, 0, 0, 0.571424, -0.000215, 0)"></g>
              <g transform="matrix(0.571424, 0, 0, 0.571424, -0.000215, 0)"></g>
              <g transform="matrix(0.571424, 0, 0, 0.571424, -0.000215, 0)"></g>
              <g transform="matrix(0.571424, 0, 0, 0.571424, -0.000215, 0)"></g>
              <g transform="matrix(0.571424, 0, 0, 0.571424, -0.000215, 0)"></g>
              <g transform="matrix(0.571424, 0, 0, 0.571424, -0.000215, 0)"></g>
              <g transform="matrix(0.571424, 0, 0, 0.571424, -0.000215, 0)"></g>
              <g transform="matrix(0.571424, 0, 0, 0.571424, -0.000215, 0)"></g>
              <g transform="matrix(0.571424, 0, 0, 0.571424, -0.000215, 0)"></g>
              <g transform="matrix(0.571424, 0, 0, 0.571424, -0.000215, 0)"></g>
              <g transform="matrix(0.571424, 0, 0, 0.571424, -0.000215, 0)"></g>
            </svg>
          </div>
          <span v-if="activeFonts.length <= 3" class="content normal">Fonts that can be used on this page. You can activate font from Font Library.</span>
          <span v-else class="content warning">Having more than <b>3 fonts</b> activated may slow your page.</span>
        </div>
        <div class="active-fonts__items">
          <div
            v-for="font in activeFonts"
            class="font-item"
            :key="font"
          >
            <div class="d-flex align-items-center justify-content-between">
              <div>
                <div class="d-flex align-items-center">
                  <span class="line-clamp-1" style="color: #000;" :style="{'font-family': font}">{{ font }}</span>
                  <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-circle-check" width="20" height="20" viewBox="0 0 24 24" stroke-width="2" stroke="#FFF" fill="#04AA6D" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <circle cx="12" cy="12" r="9"></circle>
                    <path d="M9 12l2 2l4 -4"></path>
                  </svg>
                </div>
                <span style="color: #9E9E9E; font-size: 12px;" :style="{'font-family': font}">abcdef123</span>
              </div>
              <div @click="() => handleClickFont(font)" class="font-item__action d-flex align-items-center justify-content-center">
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-circle-x" width="20" height="20" viewBox="0 0 24 24" stroke-width="1.5" stroke="#000000" fill="none" stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <circle cx="12" cy="12" r="9"></circle>
                  <path d="M10 10l4 4m0 -4l-4 4"></path>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="font-library">
        <div class="title">Font Library</div>
        <el-tabs v-model="activeTab">
          <el-tab-pane label="Google Fonts" name="google">
            <el-input
              class="mb-3"
              placeholder="Search fonts"
              prefix-icon="el-icon-search"
              v-model="search_term"
            >
            </el-input>
            <div class="row list-fonts">
              <div class="font-item-wrapper" v-for="(font, index) in fonts.filter(e => e.family.toLowerCase().includes((search_term || '').toLowerCase()))" :key="index">
                <div class="font-item" >
                  <div class="d-flex align-items-center justify-content-between">
                    <div>
                      <div class="d-flex align-items-center">
                        <span class="line-clamp-1" style="color: #000;" :style="{'font-family': font.family}">{{ font.family }}</span>
                        <svg v-if="activeFonts.includes(font.family)" xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-circle-check" width="20" height="20" viewBox="0 0 24 24" stroke-width="2" stroke="#FFF" fill="#04AA6D" stroke-linecap="round" stroke-linejoin="round">
                          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                          <circle cx="12" cy="12" r="9"></circle>
                          <path d="M9 12l2 2l4 -4"></path>
                        </svg>
                      </div>
                      <span style="color: #9E9E9E; font-size: 12px;" :style="{'font-family': font.family}">abcdef123</span>
                    </div>
                    <div @click="() => handleClickFont(font.family)" class="font-item__action d-flex align-items-center justify-content-center">
                      <svg v-if="activeFonts.includes(font.family)" xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-circle-x" width="20" height="20" viewBox="0 0 24 24" stroke-width="1.5" stroke="#000000" fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <circle cx="12" cy="12" r="9"></circle>
                        <path d="M10 10l4 4m0 -4l-4 4"></path>
                      </svg>
                      <svg v-else xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="#000000" fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <line x1="12" y1="5" x2="12" y2="19"></line>
                        <line x1="5" y1="12" x2="19" y2="12"></line>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="Theme Fonts" name="theme">
            Coming soon
          </el-tab-pane>
        </el-tabs>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">Cancel</el-button>
        <el-button type="primary" @click="handleSave(activeFonts)">Save</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Cookies from 'js-cookie';
export default {
  props: {
    dialogVisible: Boolean,
    handleClose: Function,
    handleSave: Function,
    fonts: Array,
    editor: Object
  },
  data() {
    const user = JSON.parse(Cookies.get('user_onepage') || "{}")
    return {
      search_term: "",
      activeTab: 'google',
      activeFonts: JSON.parse(localStorage.getItem(`listFonts_${user.store.domain}`) || "[]"),
    };
  },
  methods: {
    handleClickFont(font) {
      if (this.activeFonts.includes(font)) {
        const index = this.activeFonts.indexOf(font)
        if (index > -1) {
          this.activeFonts.splice(index, 1)
        }
      } else {
        this.activeFonts.push(font)
      }
    },
  },
};
</script>

<style lang="scss">
#sg-font-manager {
  .el-dialog__wrapper {
    z-index: 10000 !important;
  }

  .el-dialog__header {
    padding: 20px;
    border-bottom: 1px solid #e2e2e2;
  }

  .el-dialog__body {
    padding: 0px;
  }

  .el-dialog__footer {
    border-top: 1px solid #e2e2e2;
    padding: 10px 20px;
  }

  .active-fonts {
    padding: 10px 0 10px 20px;
    display: flex;
    align-items: center;
    background-color: #F4F4F4;
    .active-fonts__description {
      flex: 1;
      .title {
        color: #212121;
        font-size: 14px;
      }
      .content {
        font-size: 12px;
        word-break: normal;
      }
      .warning {
        color: rgb(247, 128, 0);
      }
      .normal {
        color: #676767;
      }
        
    }
    .active-fonts__items {
      flex: 4;
      overflow-x: auto;
      display: flex;
      margin-left: 10px;
      .font-item {
        flex: 0 0 200px;
        padding: 15px;
        margin-right: 12px;
        background-color: #FFF;
        .font-item__action {
          border-radius: 4px;
          width: 30px;
          height: 30px;
          line-height: 30px;
          opacity: 0;
          cursor: pointer;
          &:hover {
            background-color: #F4F4F4;
          }
        }
        &:hover {
          .font-item__action {
            opacity: 1;
          }
        }
      }
    }
  }

  .font-library {
    padding: 10px 20px;
    .title {
      color: #212121;
      font-size: 14px;
    }
    .el-tabs__content {
      margin-top: 1rem;
    }
  }

  .list-fonts {
    max-height: 45vh;
    overflow: auto;
    display: flex;
    .font-item-wrapper {
      flex: 0 0 25%;
      width: 25%;
      margin-bottom: .75rem;
      .font-item {
        border: 1px solid #e2e2e2;
        border-radius: 4px;
        padding: 15px;
      }
      .font-item__action {
        border-radius: 4px;
        width: 30px;
        height: 30px;
        line-height: 30px;
        opacity: 0;
        cursor: pointer;
        &:hover {
          background-color: #F4F4F4;
        }
      }
      &:hover {
        .font-item__action {
          opacity: 1;
        }
      }
    }
  }
}

.line-clamp-1 {
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  overflow: hidden;
  display: -webkit-box;
  word-wrap: break-word;
}
</style>