<template>
  <div
    data-label="QR Form"
    data-key="contact_qr_form"
    class="sg-wrap module"
    :id="`element-${node.uuid}`"
    @mouseover="isHovering=true" 
    @mouseout="isHovering=false"
  >
    <div 
      class="sg-module"
      :class="extra_class"
      :data-success-msg="success_msg"
      :data-success-color="success_color"
      :data-error-msg="error_msg"
      :data-error-color="error_color"
    >
        <form :data-id="node.uuid" :class="`qr-form`" method="post" action="/api/v2/contact_from" id="qr_form" accept-charset="UTF-8">
            <div class="sg-item-content">
                <slot>
                </slot>
            </div>
        </form>
    </div>

    <ToolBar ref="contact_qr_form" 
      :toolbar_id="`contact_qr_form`"
     class="container-toolbar" 
     :class="{ 'container-toolbar-show': isHovering, 'is-dragging': dragging == node.uuid }"
     >
     </ToolBar>
     <div v-if="dragging == node.uuid" class="dragging"></div>
     <div :id="`toast-${node.uuid}`" class="sg-snackbar">Some text some message..</div>
    <v-style>
      {{ custom_css }}
      @media (max-width: 767px){ 
        #element-{{ node.uuid }} {
          {{ getDisplayValue('mobile') }}
          {{ getMarginValue('mobile') }}
          {{ getPaddingValue('mobile') }}
          {{ getBackgroundValue('mobile') }}
          {{ getBorderValue('mobile') }}
          {{ getBorderRadiusValue('mobile') }}
          {{ getOpacityValue('mobile') }}
          {{ getShadowValue('mobile') }}
          {{ getOtherStyle('mobile') }}
        }
      } 
      @media (max-width: 991px) and (min-width: 768px){ 
        #element-{{ node.uuid }} {
          {{ getDisplayValue('tablet') }}
          {{ getMarginValue('tablet') }}
          {{ getPaddingValue('tablet') }}
          {{ getBackgroundValue('tablet') }}
          {{ getBorderValue('tablet') }}
          {{ getBorderRadiusValue('tablet') }}
          {{ getOpacityValue('tablet') }}
          {{ getShadowValue('tablet') }}
          {{ getOtherStyle('tablet') }}
        }
      } 
      @media (max-width: 1199px) and (min-width: 992px){ 
        #element-{{ node.uuid }} {
          {{ getDisplayValue('laptop') }}
          {{ getMarginValue('laptop') }}
          {{ getPaddingValue('laptop') }}
          {{ getBackgroundValue('laptop') }}
          {{ getBorderValue('laptop') }}
          {{ getBorderRadiusValue('laptop') }}
          {{ getOpacityValue('laptop') }}
          {{ getShadowValue('laptop') }}
          {{ getOtherStyle('laptop') }}
        }
      } 
      @media (min-width: 1200px){ 
        #element-{{ node.uuid }} {
          {{ getDisplayValue('desktop') }}
          {{ getMarginValue('desktop') }}
          {{ getPaddingValue('desktop') }}
          {{ getBackgroundValue('desktop') }}
          {{ getBorderValue('desktop') }}
          {{ getBorderRadiusValue('desktop') }}
          {{ getOpacityValue('desktop') }}
          {{ getShadowValue('desktop') }}
          {{ getOtherStyle('desktop') }}
        } 
      }
    </v-style>
    <!-- <v-script type="text/javascript">
      $(document).ready(function() {
        var form = $(`#element-{{node.uuid}}`).find('#qr_form');
        form.submit(function(e) {
          var tranfer = form.find(`[data-key='tranfer_content']`).text();
          var phone = form.find(`[name='contact[phone]']`).val();
          var name = form.find(`[name='contact[name]']`).val();
          var email = form.find(`[name='contact[email]']`).val();
          var money = form.find(`[data-money]`).attr('data-money');
          $.ajax({
              type:"post",
              url:"/api/v2/contact_from",
              data: 
              {  
                'name' :name,
                'phone' :phone,
                'email': email,
                'tranfer_content': tranfer,
                'money': money
              },
              cache:false,
              success: function (html) 
              {
                var form = $(`#element-{{node.uuid}}`).find('#qr_form');
                const tranfer = form.find(`[data-key='tranfer_content']`);
                var module = $(`#element-{{node.uuid}} > .sg-module`);
                var x = $(`#toast-{{node.uuid}}`);
                if(html['status']==200){
                  var order_id = html['data']['order_id'];
                  var tranfer_content = tranfer.attr('data-content')+order_id;
                  const image = form.find("[data-label='(QR) Image']").find('.sg_qr-form-image');
                  var url =  image.attr("data-url")+"?amount="+image.attr("data-money")+"&addInfo="+ encodeURIComponent(tranfer_content);
                  image.attr('src',url);
                  tranfer.text(tranfer_content);
                  x.addClass("show msg-success");
                  x.text(module.attr('data-success-msg'));
                  x.css('background-color',module.attr('data-success-color'));
                  setTimeout(function(){ x.removeClass("show msg-success");; }, 2000);
                  form.find('.sg-hidden').removeClass('sg-hidden');
                }else{

                  var text = "";
                  $.each(html['data'], function(key, value) {
                    text += value;
                  });
                  x.addClass("show msg-error");
                  x.text(text);
                  x.css('background-color',module.attr('data-error-color'));
                  setTimeout(function(){ x.removeClass("show msg-error");; }, 2000);
                }
              },
              error: function (request, status, error) {
                var module = $(`#element-{{node.uuid}} > .sg-module`);
                var x = $(`#toast-{{node.uuid}}`);
                x.addClass("show msg-error");
                x.text(module.attr('data-error-msg'));
                x.css('background-color',module.attr('data-error-color'));
                setTimeout(function(){ x.removeClass("show msg-error");; }, 2000);
              }
          });
          e.preventDefault();
          return false;
        });
      });
    </v-script> -->
  </div>
</template>

<script>
import ToolBar from '../../elements/utils/ToolBar.vue';

import elementStyleMixin from "../../elements/elementStyleMixin";
import Success from "./settings/Success.vue";
import Error from "./settings/Error.vue";
import SubmitCallbackUrl from "./settings/SubmitCallbackUrl.vue";
import ExtraClass from '../../elements/otherSettings/ExtraClass.vue';
import Visibility from "../../elements/designSettings/Visibility.vue";
import Spacing from "../../elements/designSettings/Spacing.vue";
import Background from "../../elements/designSettings/Background.vue";
import Border from "../../elements/designSettings/Border.vue";
import Corner from '../../elements/designSettings/Corner.vue';
import Shadow from '../../elements/designSettings/Shadow.vue';
import Opacity from '../../elements/designSettings/Opacity.vue';
import { designStyles, otherProps, otherStyles, actionTypes } from '@/constants';
import CustomCSS from '../../elements/styleSettings/CustomCSS.vue';
import Animations from '../../elements/designSettings/Animations.vue';

export default {
  components: {ToolBar},
  data() {
    return {
      isHovering: false
    }
  },
  inject: [
    'node',
    'editor'
  ],
  mixins: [elementStyleMixin],
  props: {
    ...otherProps,
    section: String,
    component:String,
    extra_class: String,
    success_msg: String,
    success_color: String,
    error_msg: String,
    error_color: String,
    callback_url:String,
    desktop: Object,
    laptop: Object,
    mobile: Object,
    tablet: Object,
  },
  watch: {
    dragging: function (newValue, oldValue) {
      var $iframe = $("#designEditor").contents();
      var $element = $iframe.find(`#element-${this.node.uuid}`);
      var $content = $element.find(`.sg-module`);
      if (newValue && newValue == this.node.uuid) {
        setTimeout(() => {
          $element.css("padding","0px 0px 0px 0px");
          $element.css("margin","0px 0px 0px 0px");
          $content.hide();
        },100);
      }else if (oldValue == this.node.uuid){
        $element.css("padding","");
        $element.css("margin","");
        $content.show();
      }
    },
  },
  computed: {
    dragging: function () {
      return this.editor?.draggedNode?.uuid
    },
  },
  craft: {
    defaultProps: {
      ...otherStyles,
      section:"module",
      success_msg:"Thanks for contacting us. We'll get back to you as soon as possible.",
      success_color: 'rgb(129, 215, 66)',
      error_msg:"Can't send email. Please try again later.",
      error_color: "rgb(221, 51, 51)",
      callback_url:"",
      desktop: {
        ...designStyles,
        padding_top: 60,
        padding_bottom: 30,
        padding_left: 10,
        padding_right: 10
      },
      laptop: {
        ...designStyles,
      },
      mobile: {
        ...designStyles,
      },
      tablet: {
        ...designStyles,
      },
      elementStyle: {
      },
    },
    settings: {
      settings: {
        Success,
        Error,
        "Extra Class": ExtraClass, "Custom CSS": CustomCSS,
      },
      design: {
        Visibility,
        Spacing: { Spacing, multi_screen: true },
        Background: { Background, multi_screen: true },
        Border: { Border, multi_screen: true },
        Corner: { Corner, multi_screen: true },
        Shadow: { Shadow, multi_screen: true },
        Opacity: { Opacity, multi_screen: true },
      },
      others: {
        Animations: { Animations, actions: actionTypes.filter(e => ['scroll_into_view', 'after_page_finish_loading'].includes(e.value)) }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
