<template>
    <div
      data-label="Hero Banner"
      data-key="hero-banner"
      class="sg-wrap module hero-banner"
      :class="`${fixed ? 'sg_hero-fixed-mode ' : ''}${extra_class || ''}`"
      @mouseover="isHovering = true"
      @mouseout="isHovering = false"
      :id="`element-${node.uuid}`"
      :style="elementCSS"
      :data-desktop_bgtype="desktop.background_type"
      :data-laptop_bgtype="laptop.background_type"
      :data-tablet_bgtype="tablet.background_type"
      :data-mobile_bgtype="mobile.background_type"
      :data-desktop_weblink="desktop.weblink"
      :data-laptop_weblink="laptop.weblink"
      :data-tablet_weblink="tablet.weblink"
      :data-mobile_weblink="mobile.weblink"
      :data-desktop_imgUrl="desktop.imgUrl"
      :data-laptop_imgUrl="laptop.imgUrl"
      :data-tablet_imgUrl="tablet.imgUrl"
      :data-mobile_imgUrl="mobile.imgUrl"
    >
      <div
        class="relative flex flex-col overflow-hidden justify-center sg-col-content"
        :id="`sg-col-content-${node.uuid}`"
        style="
          display: flex;
          flex-direction: column;
          max-width: 100%;
          position: relative;
        "
      >
        <div
          
          aria-label="Background Image"
          role="banner"
          class="absolute inset-0 left-[var(--left)] top-[var(--top)] flex items-center justify-center overflow-hidden transition-transform sg-bgi"
        >
          <!-- <article
            v-if="getEmbed()"
            aria-hidden="true"
            class="sg-article desktop"
            data-title=""
            style="
                aspect-ratio: 16 / 9;
                  pointer-events: none;
                  padding-bottom: 56.25%;
                  height: 100%;
                  width: 100%;
                  overflow: hidden;
                  position: relative;
                  max-width: none !important;
                  "
          >
            <iframe
              class="bg-black"
              title=""
              frameborder="0"
              allow="autoplay; encrypted-media"
              allowfullscreen=""
              :src="getEmbed()"
              referrerpolicy="strict-origin-when-cross-origin"
              style="
                  aspect-ratio: 16 / 9;
                  inset: 0px;
                  background: rgb(0, 0, 0);
                  position: absolute;
                  scale: 1.04;
                  width: 500% !important;
                  margin-left: -200% !important;
                  height: 100% !important;
                  max-width: none !important;
              "
            ></iframe>
          </article> -->
        </div>
        <div :class="`sg-module ${editor?.findNode(node?.uuid)?.children?.length == 0?'sg-d-flex':'sg-d-grid'}`" >
          <span class="item-content w-100">
            <div
              v-if="editor?.findNode(node?.uuid)?.children?.length == 0"
              class="text-center drop-element row-dropper"
              :class="{ dragging_space: dragging == node.uuid }"
            >
              <span>Add element</span>
            </div>
            <slot />
          </span>
          <a
            v-if="href"
            class="sg-link hero-link"
            href="javascript:void(0)"
            :data-href="href"
            :data-target="target"
            style="display: none"
          />
        </div>
      </div>
  
      <div
        v-if="parseFloat(overlay_opacity) > 0"
        class="banner-overlay"
        :data-fixedMode="fixed ? 1 : 0"
      />
      <!-- <picture class="sg_hero-bg-wrap" v-if="fixed">
        <source media="(min-width: 1200px)" :srcset="desktop?.imgUrl || 'none'" />
        <source media="(min-width: 992px)" :srcset="getUrl(laptop)" />
        <source media="(min-width: 768px)" :srcset="getUrl(tablet)" />
        <img :src="getUrl(mobile)" alt="" />
      </picture> -->
      <ToolBar
        ref="banner_toolbar"
        :toolbar_id="`hero_banner`"
        class="container-toolbar"
        :class="{
          'container-toolbar-show': isHovering,
          'is-dragging': dragging == node.uuid,
        }"
      >
      </ToolBar>
      <div v-if="dragging == node.uuid" class="dragging"></div>
      <v-style>
        {{ custom_css }}
  
        #element-{{ node.uuid }} .banner-overlay { 
          background-color: {{ overlay_color }};
          opacity: {{ overlay_opacity }};
          z-index: {{ fixed ? "2" : "unset" }}; 
        }

        @media (max-width: 767px) { 
          #element-{{ node.uuid }} > .sg-col-content{
            height: {{ `${!Number.isNaN(mobile.height) ? `${mobile.height}px` : "auto"}` }};
            justify-content: {{ getValueLinked('content_align', mobile?.content_align, 'center') }};

          }

          #element-{{ node.uuid }}{
            {{ getDisplayValue("mobile") }}
          }
          
          #sg-col-content-{{ node.uuid }}{
            {{ getMarginValue("mobile") }}
            {{ getPaddingValue("mobile") }}
            {{ getBorderValue("mobile") }}
            {{ getBorderRadiusValue("mobile") }}
            {{ getOpacityValue("mobile") }}
            {{ getShadowValue("mobile") }}
            {{ getOtherStyle("mobile") }}
          } 
          
          #element-{{ node.uuid }} .sg-bgi { 
            background-image:{{ getValueLinked('background_type',mobile.background_type) == 'image' ? `url(${getUrl(mobile)}) !important`:'' }};
          }

          #element-{{ node.uuid }} > .sg-col-content > .sg-module { 
            place-content: {{ getValueLinked('place_content', mobile?.place_content, 'center') }};
          }

          #element-{{ node.uuid }} > .sg-col-content > .sg-module > .item-content > div:first-child { 
            min-width: 200px;
          } 

            #element-{{ node.uuid }}  > .sg-col-content > .sg-module { 
              display: {{getValueLinked(
              'column_width',
              mobile?.column_width,
              'fit_to_content'
            )== 'fit_to_content'?'grid':'flex'
            }};
            } 
        } 
        @media (max-width: 991px) and (min-width: 768px) { 
          #element-{{ node.uuid }} > .sg-col-content{
            height: {{ `${!Number.isNaN(tablet.height) ? `${tablet.height}px` : "auto"}` }};
            justify-content: {{ getValueLinked('content_align', tablet?.content_align, 'center') }};

          }
          #element-{{ node.uuid }}{
            {{ getDisplayValue("tablet") }}
          }
          #sg-col-content-{{ node.uuid }} {
            {{ getMarginValue("tablet") }}
            {{ getPaddingValue("tablet") }}
            {{ getBorderValue("tablet") }}
            {{ getBorderRadiusValue("tablet") }}
            {{ getOpacityValue("tablet") }}
            {{ getShadowValue("tablet") }}
            {{ getOtherStyle("tablet") }}
            } 
            #element-{{ node.uuid }} .sg-bgi { 
              background-image:{{ getValueLinked('background_type',tablet.background_type) == 'image' ?`url(${getUrl(tablet)}) !important`:'' }};
            } 
            #element-{{ node.uuid }} > .sg-col-content  > .sg-module { 
              place-content: {{ getValueLinked('place_content', tablet?.place_content, 'center') }};
            } 
            #element-{{ node.uuid }} > .sg-col-content > .sg-module > .item-content > div:first-child { 
            min-width: 200px;
          } 

            #element-{{ node.uuid }} > .sg-col-content > .sg-module { 
              display: {{getValueLinked(
              'column_width',
              tablet?.column_width,
              'fit_to_content'
            )== 'fit_to_content'?'grid':'flex'
            }};
            } 
          } 
            
          @media (max-width: 1199px) and (min-width: 992px) { 
            #element-{{ node.uuid }} > .sg-col-content{
              height: {{ `${!Number.isNaN(laptop.height) ? `${laptop.height}px` : "auto"}` }};
              justify-content: {{ getValueLinked('content_align', laptop?.content_align, 'center') }};

            }
            #element-{{ node.uuid }}{
            {{ getDisplayValue("laptop") }}
          }
              
            #sg-col-content-{{ node.uuid }} {
              {{ getMarginValue("laptop") }}
              {{ getPaddingValue("laptop") }}
              {{ getBorderValue("laptop") }}
              {{ getBorderRadiusValue("laptop") }}
              {{ getOpacityValue("laptop") }}
              {{ getShadowValue("laptop") }}
              {{ getOtherStyle("laptop") }}
            } 

            #element-{{ node.uuid }} .sg-bgi { 
              background-image:{{ getValueLinked('background_type',laptop.background_type) == 'image' ? `url(${getUrl(laptop)}) !important`:'' }};
            }
            #element-{{ node.uuid }}  > .sg-col-content > .sg-module { 
              place-content: {{ getValueLinked('place_content', laptop?.place_content, 'center') }};
            } 

            #element-{{ node.uuid }} > .sg-col-content > .sg-module > .item-content > div:first-child { 
              min-width: 200px;
            } 

            #element-{{ node.uuid }}  > .sg-col-content > .sg-module { 
              display: {{getValueLinked(
              'column_width',
              laptop?.column_width,
              'fit_to_content'
            )== 'fit_to_content'?'grid':'flex'
            }};
            } 
          } 
        @media (min-width: 1200px) { 
          #element-{{ node.uuid }} > .sg-col-content{
            height: {{ `${!Number.isNaN(desktop.height) ? `${desktop.height}px` : "auto"}` }};
            justify-content: {{ getValueLinked('content_align', desktop?.content_align, 'center') }};
          }
          #element-{{ node.uuid }}{
            {{ getDisplayValue("desktop") }}
          }
        
          #sg-col-content-{{ node.uuid }} { 
            {{ getMarginValue("desktop") }}
            {{ getPaddingValue("desktop") }}       
            {{ getBorderValue("desktop") }}
            {{ getBorderRadiusValue("desktop") }}
            {{ getOpacityValue("desktop") }}
            {{ getShadowValue("desktop") }}
            {{ getOtherStyle("desktop") }}
          } 


          #element-{{ node.uuid }} .sg-bgi { 
            background-image:{{ (desktop?.imgUrl && desktop?.background_type == 'image') ? `url(${desktop.imgUrl}) !important` : "" }};
          } 

          #element-{{ node.uuid }} > .sg-col-content  > .sg-module { 
            place-content: {{ getValueLinked('place_content', desktop?.place_content, 'center') }};
          } 


          #element-{{ node.uuid }} > .sg-col-content > .sg-module > .item-content > div:first-child { 
            min-width: 200px;
          } 

          #element-{{ node.uuid }}  > .sg-col-content > .sg-module { 
            display: {{getValueLinked(
            'column_width',
            desktop?.column_width,
            'fit_to_content'
          )== 'fit_to_content'?'grid':'flex'
          }};
          } 



        }

        
      </v-style>
    </div>
  </template>
  
  <script>
  import elementStyleMixin from "../../elements/elementStyleMixin";
  import ToolBar from "../../elements/utils/ToolBar.vue";
  import Visibility from "../../elements/designSettings/Visibility.vue";
  import Spacing from "../../elements/designSettings/Spacing.vue";
  import Border from "../../elements/designSettings/Border.vue";
  import Corner from "../../elements/designSettings/Corner.vue";
  import Shadow from "../../elements/designSettings/Shadow.vue";
  import Opacity from "../../elements/designSettings/Opacity.vue";
  import BannerDescription from "../../elements/styleSettings/BannerDescription.vue";
  import Link from "../../elements/otherSettings/Link.vue";
  import BannerDimension from "../../elements/styleSettings/BannerDimension.vue";
  import ExtraClass from "../../elements/otherSettings/ExtraClass.vue";
  import Overlay from "../../elements/otherSettings/Overlay.vue";
  import CustomCSS from "../../elements/styleSettings/CustomCSS.vue";
  import Background from "./settings/Background.vue";
  import Size from "./settings/Size.vue";
  import Align from "./settings/Align.vue";
  import Layout from "./settings/Layout.vue";


  import {
    designStyles,
    otherProps,
    otherStyles,
    actionTypes,
  } from "@/constants";
  import Animations from "../../elements/designSettings/Animations.vue";
  
  export default {
    components: { ToolBar },
    mixins: [elementStyleMixin],
    inject: ["node", "editor"],
    methods: {
      getUrl(obj) {
        return Object.keys(obj || {}).includes("imgUrl")
          ? obj?.imgUrl || "none"
          : this.desktop?.imgUrl || "none";
      },
      // getEmbed() {
      //   var obj = {}
      //   switch (this.$props.screen) {
      //       case 'desktop':
      //           obj = this.$props.desktop
      //           break;
      //       case 'laptop':
      //           obj = this.$props.laptop
      //           break;
      //       case 'tablet':
      //           obj = this.$props.tablet
      //           break;
      //       case 'mobile':
      //           obj = this.$props.mobile
      //           break;
      //       default:
      //           break;
      //   }
      //   var background_type = this.$props.desktop.background_type;
      //   var weblink = this.$props.desktop.weblink;
      //   if(Object.keys(obj).includes("background_type")){
      //     background_type = obj.background_type
      //   }
      //   if(Object.keys(obj).includes("weblink")){
      //     weblink = obj.weblink
      //   }
      //   if(background_type!='video'){
      //     return null
      //   }
      //   try {
      //     videoId = new URL(weblink)?.searchParams.get("v");
      //     if (weblink.includes("/shorts/")) {
      //       var regex = /\/shorts\/([^/]+)$/;
      //       var match = weblink.match(regex);
      //       videoId = match ? match[1] : null;
      //     }
      //     this.create_iframe();
      //   } catch (e) {
      //     return null;
      //   }
      // },
      create_iframe(object_data){
      var $iframe = $("#designEditor").contents();
      var $obj = $iframe.find(`#element-${this.node.uuid}`);
      if(!$obj) return;
      var desktop_bgtype = $obj.data("desktop_bgtype"),
      laptop_bgtype = $obj.data("laptop_bgtype"),
      tablet_bgtype = $obj.data("tablet_bgtype"),
      mobile_bgtype = $obj.data("mobile_bgtype"),
      desktop_weblink = $obj.data("desktop_weblink"),
      laptop_weblink = $obj.data("laptop_weblink"),
      tablet_weblink = $obj.data("tablet_weblink"),
      mobile_weblink = $obj.data("mobile_weblink");
      var screen = this.$props.screen;
      var bg_type = 'image';
      var weblink = null;
      if(object_data){
        desktop_bgtype = object_data.desktop.background_type;
        laptop_bgtype = object_data.laptop.background_type;
        tablet_bgtype = object_data.tablet.background_type;
        mobile_bgtype = object_data.mobile.background_type;
        desktop_weblink = object_data.desktop.weblink;
        laptop_weblink = object_data.laptop.weblink;
        tablet_weblink = object_data.tablet.weblink;
        mobile_weblink = object_data.mobile.weblink;
        screen = object_data.screen
      }
      if (screen == 'mobile') {
        bg_type = mobile_bgtype || desktop_bgtype;
        weblink = mobile_weblink || desktop_weblink;
      } else if (screen == 'tablet') {
        bg_type = tablet_bgtype || desktop_bgtype;
        weblink = tablet_weblink || desktop_weblink;

      } else if (screen == 'laptop') {
        bg_type = laptop_bgtype || desktop_bgtype;
        weblink = laptop_weblink || desktop_weblink;
      } else {
        bg_type = desktop_bgtype || desktop_bgtype;
        weblink = desktop_weblink || desktop_bgtype;
      }
      var sgBgiElements = $obj.find('.sg-bgi');
      if(sgBgiElements.length == 0){
        return;
      }
      if (bg_type == 'image'){
        $obj.find('.sg-bgi').empty();
      }else{
        $obj.find('.sg-bgi').empty();
        var videoId = null;
        try {
          const regex = /^.*(youtu\.be\/|v\/|u\/\w\/|shorts\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
          var match = weblink.match(regex);
          videoId = (match && match[2].length==11) ? match[2] : false;
        } catch {
          return null;
        }
        if(!videoId) return;
        var articleElement = $('<article>', {
          'aria-hidden': 'true',
          'class': 'sg-article live',
          'data-title': '',
          'style':'aspect-ratio: 16 / 9; pointer-events: none; padding-bottom: 56.25%; height: 100%; width: 100%; overflow: hidden; position: relative; max-width: none !important;',
          'html': 
          $('<div>', {
              'id':`player-${this.node.uuid}`,
              'style': 'aspect-ratio: 16 / 9; inset: 0px; background: rgb(0, 0, 0); position: absolute; scale: 1.04; width: 500% !important; margin-left: -200% !important; height: 100% !important; max-width: none !important;',

            }),
        });
        $obj.find('.sg-bgi').append(articleElement);

        const iframe = document.getElementById("designEditor");
        
        var player;
        if (!iframe?.contentWindow?.YT){
            setTimeout(()=>{
              this.create_iframe(object_data)
            },2000);
            return;
        }
        player = new iframe.contentWindow.YT.Player(`player-${this.node.uuid}`, {
          videoId: videoId,
          playerVars: {
            'autoplay':1,
            'state':1,
            'mute':1,
            'loop':1,
            'playlist':videoId,
            'controls':0,
            'enablejsapi':1,
            'showinfo':0,
            'modestbranding':1,
            'rel':0
          },
          events: {
            'onReady': onPlayerReady,
            'onStateChange': onPlayerStateChange
          }
        });
        function onPlayerReady(event) {

          event.target.playVideo();
          
        }
        var done = false;
        function onPlayerStateChange(event) {
          // if (event.data == YT.PlayerState.PLAYING && !done) {
          //   setTimeout(stopVideo, 6000);
          //   done = true;
          // }
        }
        function stopVideo() {
          // player.stopVideo();
        }
        
      }
      }
    },
    mounted(){
      setTimeout(()=>{
        this.create_iframe();
      },2000)
      
    },
    watch: {
      dragging: function (newValue, oldValue) {
        var $iframe = $("#designEditor").contents();
        var $element = $iframe.find(`#element-${this.node.uuid}`);
        var $content = $element.find(`.sg-module`);
        var $content2 = $element.find(`.sg_hero-bg-wrap`);
  
        if (newValue && newValue == this.node.uuid) {
          setTimeout(() => {
            $element.css("padding", "0px 0px 0px 0px");
            $element.css("margin", "0px 0px 0px 0px");
            $content.hide();
            $content2.hide();
          }, 100);
        } else if (oldValue == this.node.uuid) {
          $element.css("padding", "");
          $element.css("margin", "");
          $content.show();
          $content2.show();
        }
      },
      clonedItems: {
        handler: function (val, oldVal) {
          this.create_iframe(val);
        },
          deep: true,
        }
    },
    computed: {
      dragging: function () {
        return this.editor?.draggedNode?.uuid;
      },
      clonedItems: function () {
      return JSON.parse(
        JSON.stringify({
          'screen': this.$props.screen,
          'desktop':  {
            'background_type': this.$props.desktop.background_type,
            'weblink': this.$props.desktop.weblink
          },
          'tablet':  {
            'background_type': this.$props.tablet.background_type,
            'weblink': this.$props.tablet.weblink
          },
          'laptop':  {
            'background_type': this.$props.laptop.background_type,
            'weblink': this.$props.laptop.weblink
          },
          'mobile':  {
            'background_type': this.$props.mobile.background_type,
            'weblink': this.$props.mobile.weblink
          },
        })
      );
    },
    },
    props: {
      ...otherProps,
      screen: String,
      component: String,
      content_position: String,
      fixed: Boolean,
      href: String,
      target: String,
      alt_tag: String,
      overlay_opacity: Number,
      overlay_color: String,
      desktop: Object,
      laptop: Object,
      tablet: Object,
      mobile: Object,
      extra_class: String,
    },
    data() {
      return {
        isHovering: false,
      };
    },
    craft: {
      defaultProps: {
        ...otherStyles,
        href: "",
        extra_class: "",
        target: "_self",
        content_position: "middle",
        fixed: false,
        alt_tag: "",
        overlay_color: "rgb(255, 255, 255)",
        overlay_opacity: 0,
        desktop: {
          ...designStyles,
          padding_top: 100,
          padding_left: 100,
          padding_right: 100,
          padding_bottom: 100,
          height: "auto",
          imgUrl:
            "https://www.wallpaperbetter.com/wallpaper/135/183/824/canoeing-hd-1080P-wallpaper.jpg",
          background_type: 'image',
          weblink: "https://www.youtube.com/watch?v=JIcFP4JcJ3Y",
          height:500,
          place_content: 'center',
          content_align: 'center',
          column_width:'fit_to_content'
        },
        laptop: {
          ...designStyles,
          padding_top: 100,
          padding_bottom: 100,
          padding_left: 100,
          padding_right: 100,
          height:500,
          
        },
        tablet: {
          ...designStyles,
          padding_top: 100,
          padding_bottom: 100,
          padding_left: 100,
          padding_right: 100,
          height:500,
        },
        mobile: {
          ...designStyles,
          padding_top: 100,
          padding_bottom: 100,
          padding_left: 100,
          padding_right: 100,
          height:500,
        },
        elementStyle: {},
      },
      settings: {
        settings:{
          "": BannerDescription,
          Background: { Background, multi_screen: true },
          Layout: {Layout, multi_screen:true},
          Align: {Align, multi_screen:true},
          Size:{Size, multi_screen: true  },
          Link,
          // Dimension: { Dimension: BannerDimension, multi_screen: true },
          Overlay,
          "Extra Class": ExtraClass,
          "Custom CSS": CustomCSS,

        },
        design: {
          Visibility,
          Spacing: { Spacing, multi_screen: true },
          Border: { Border, multi_screen: true },
          Corner: { Corner, multi_screen: true },
          Shadow: { Shadow, multi_screen: true },
          Opacity: { Opacity, multi_screen: true },
        },
        others: {
          Animations: {
            Animations,
            actions: actionTypes.filter((e) =>
              ["scroll_into_view", "after_page_finish_loading"].includes(e.value)
            ),
          },
        },
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .dragging {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: greenyellow;
    z-index: 10;
  }
  </style>
  