var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"label-position":"top","size":"mini","model":_vm.elementProps}},[_c('div',{staticClass:"d-flex flex-column py-2"},[_c('span',[_vm._v("Element")]),_c('ToggleButton',{attrs:{"value":_vm.element,"changeValue":($event) => {(_vm.element = $event)},"options":[
        { value: 'quantity', label: 'Quantity' },
        { value: 'selector', label: 'Selector' },
      ],"primaryColor":_vm.primaryColor}})],1),_c('div',{staticClass:"d-flex flex-column py-2"},[_c('span',{staticStyle:{"font-size":"12px","color":"#ccc"}},[_vm._v("Color")]),_c('ColorPicker',{staticClass:"mt-2",attrs:{"value":_vm.element == 'quantity'
          ? _vm.getValue('quantity_color',_vm.elementProps[_vm.screen].quantity_color)
          : _vm.getValue('select_color',_vm.elementProps[_vm.screen].select_color),"changeColor":($event) =>
          _vm.updateProps(
            _vm.element == 'quantity' ? 'quantity_color' : 'select_color',
            $event
          ),"primaryColor":_vm.primaryColor}})],1),_c('div',{staticClass:"d-flex flex-column py-2"},[_c('span',{staticStyle:{"color":"#aaaaaa","font-size":"12px"}},[_vm._v("Font Size")]),_c('Slider',{staticClass:"mt-2",attrs:{"step":1,"primaryColor":_vm.primaryColor,"value":_vm.element == 'quantity'
          ? _vm.getValue('quantity_font_size',_vm.elementProps[_vm.screen].quantity_font_size)
          : _vm.getValue('select_font_size',_vm.elementProps[_vm.screen].select_font_size),"changeValue":($event) =>
          _vm.updateProps(
            _vm.element == 'quantity' ? 'quantity_font_size' : 'select_font_size',
            parseInt($event)
          ),"unit":"px","max":100,"min":1,"onlyInteger":true}})],1),_c('div',{staticClass:"d-flex flex-column py-2"},[_c('span',{staticStyle:{"font-size":"12px","color":"#ccc"}},[_vm._v("Font")]),_c('Select',{key:_vm.fontKey,staticClass:"mt-2",attrs:{"primaryColor":_vm.primaryColor,"value":_vm.element == 'quantity'
          ? _vm.getValue('quantity_font_family',_vm.elementProps[_vm.screen].quantity_font_family)
          : _vm.getValue('select_font_family',_vm.elementProps[_vm.screen].select_font_family),"changeValue":($event) =>
          _vm.updateProps(
            _vm.element == 'quantity'
              ? 'quantity_font_family'
              : 'select_font_family',
            $event
          ),"hasAction":true,"onAction":_vm.handleShowModal,"actionLabel":"Open Font Manager","options":_vm.getListFont()}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }