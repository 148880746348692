<template>
  <div label-position="top" size="small" :model="elementProps">
    <div class="d-flex flex-column py-2">
      <span class="setting-title">Spacing</span>
      <Slider
        class="mt-2"
        :value="elementProps.spacing"
        :primaryColor="primaryColor"
        v-bind:changeValue="($event) => updateProps('spacing', $event)"
        unit="px"
        :min="0"
        :max="50"
        :onlyInteger="true"
      />
    </div>
  </div>
</template>

<script>
import mixin from "../../../elements/styleSettings/mixin";
import ToggleButton from "../../../elements/common/ToggleButton.vue";
import Slider from "../../../elements/common/Slider.vue";

export default {
  mixins: [mixin],
  components: { ToggleButton, Slider },
  methods: {
    updateProps(name, value) {
      if(value ==  this.elementProps[name]) return;

      this.elementPropsSetter({ [name]: value });
    },
  },
  props: {
    primaryColor: String
  }
};
</script>

<style scoped>
</style>
