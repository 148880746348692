<template>
  <el-form label-position="top" size="small" :model="elementProps">
    <div class="d-flex flex-column py-2">
      <el-input
        :disabled="true"
        :value="elementProps.title"
      />
      <el-button
        icon="el-icon-aim"
        class="pick-btn mt-2"
        @click="() => openModalBlog()"
      >
        Pick a Blog
      </el-button>
    </div>
  </el-form>
</template>

<script>
import mixin from "../../../elements/styleSettings/mixin";

export default {
  mixins: [mixin],
  components: {  },
  props: {
    blog: Object,
    type: String,
  },
  watch: {
    blog: function (newValue, oldValue) {
      this.elementPropsSetter({
        title: newValue.title,
        blog_id: newValue.blog_id
      })
    }
  },
  methods: {
    updateProps(name, value) {
      if(value ==  this.elementProps[name]) return;
      this.elementPropsSetter({ [name]: value });
    },
    openModalBlog() {
      this.$bus.emit("showBlogPicker", this.node);
    }
  },
};
</script>

<style lang="scss" scoped>
  .pick-btn {
    background-color: #494949;
    color: #F4F4F4;
    border-color: #494949;
    &:hover {
      background-color: #555555;
    }
  }
  .text-description {
    color: #D6D6D6;
    font-size: 12px;
    font-style: italic;
  }
</style>
