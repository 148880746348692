<template>
  <div label-position="top" size="mini" :model="elementProps">
    <div class="d-flex align-items-center justify-content-between py-2">
      <span class="setting-title">Show logo</span>
      <el-switch
        :value="getValue('show_logo', elementProps[screen].show_logo)"
        :active-color="primaryColor"
        inactive-color="#949494"
        :active-value="true"
        :inactive-value="false"
        @change="updateProps('show_logo', $event)"
      />
    </div>
    <div class="d-flex flex-column py-2" v-if="elementProps[screen].show_logo">
      <span class="setting-title">Image</span>
      <Upload
        :value="elementProps.logo_img"
        v-bind:changeValue="($event) => updateCommonProps('logo_img', $event)"
      />
    </div>
    <div class="d-flex flex-column py-2" v-if="elementProps[screen].show_logo">
      <span class="setting-title">Image Source</span>
      <Input
        class="mt-2"
        placeholder="Image Link"
        :value="elementProps.logo_img"
        v-bind:changeValue="($event) => updateCommonProps('logo_img', $event)"
        :primaryColor="primaryColor"
      />
    </div>
    <div class="d-flex flex-column py-2" v-if="elementProps[screen].show_logo">
      <span class="setting-title">Link</span>
      <Input
        class="mt-2"
        placeholder="Logo Link"
        :value="elementProps.logo_link"
        v-bind:changeValue="($event) => updateCommonProps('logo_link', $event)"
        :primaryColor="primaryColor"
      />
    </div>
    <div class="d-flex flex-column py-2" v-if="elementProps[screen].show_logo">
      <span class="setting-title">Logo Height</span>
      <InputWithSelect
        class="mt-2"
        type="number"
        :value="getValue('logo_height',elementProps[screen].logo_height)"
        :primaryColor="primaryColor"
        :options="['px']"
        v-bind:changeValue="($event) => updateProps('logo_height', $event)"
        v-bind:changeUnit="($event) => updateProps('logo_height_type', $event)"
        :unit="getValue('logo_height_type',elementProps[screen].logo_height_type)"
      />
    </div>
    <div class="d-flex flex-column py-2" v-if="elementProps[screen].show_logo">
      <span class="setting-title">Logo Width</span>
      <Input
        class="mt-2"
        placeholder="Width"
        :value="getValue('logo_width', elementProps[screen].logo_width)"
        v-bind:changeValue="($event) => updateProps('logo_width', $event)"
        :primaryColor="primaryColor"
      />
    </div>
  </div>
</template>

<script>
import mixin from '../../../elements/styleSettings/mixin';
import Slider from '../../../elements/common/Slider.vue';
import Upload from '../../../elements/common/Upload.vue';
import InputWithSelect from '../../../elements/common/InputWithSelect.vue';
import Input from '../../../elements/common/Input.vue';

export default {
  mixins: [mixin],
  props: {
    screen: String,
    primaryColor: String
  },
  components: {
    Slider, Upload, InputWithSelect, Input
  },
  methods: {
    updateProps(name, value) {
      const screen = this.$props.screen
      const data_screen = Object.assign({}, this.elementProps[screen])
      if(value ==  data_screen[name]) return;
      data_screen[name] = value
      if (name == 'logo_height' && (value == 'auto' && isNaN(parseInt(value)))) {
        data_screen['logo_height_type'] = ''
      }
      this.elementPropsSetter({ [screen]: data_screen });
    },
    updateCommonProps(name, value) {
      this.elementPropsSetter({ [name]: value });
    }
  }
};
</script>

<style>
  .custom-input-height input::-webkit-outer-spin-button, .custom-input-height input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
</style>
