<template>
  <el-form label-position="top" size="" :model="elementProps">
    <el-form-item label="End Video at">
      <el-input
        :value="elementProps.end_video"
        @input="($event) => updateProps('end_video', $event)"
      />
      <div class="sgDesc mt-2">
        End video at xx seconds
      </div>
    </el-form-item>
  </el-form>
</template>

<script>
import mixin from "../../../elements/styleSettings/mixin";

export default {
  mixins: [mixin],
  components: {},
  methods: {
    updateProps(name, value) {
      if(value ==  this.elementProps[name]) return;
      this.elementPropsSetter({ [name]: value });
    },
  },
};
</script>

<style scoped>
</style>
