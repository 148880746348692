var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"label-position":"top","size":"mini","model":_vm.elementProps}},[_c('div',{staticClass:"py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Shadow Style")]),_c('ToggleButton',{attrs:{"value":_vm.getValue('shadow_style',_vm.elementProps[_vm.screen].shadow_style),"changeValue":($event) => _vm.updateProps(_vm.screen, 'shadow_style', $event),"options":[
        { value: 'drop', label: 'Drop Shadow' },
        { value: 'box', label: 'Box Shadow' },
      ],"canRemove":true,"primaryColor":_vm.primaryColor}})],1),(_vm.getValue('shadow_style',_vm.elementProps[_vm.screen].shadow_style))?_c('div',{staticClass:"py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Shadow Color")]),_c('ColorPicker',{staticClass:"mt-2",attrs:{"primaryColor":_vm.primaryColor,"value":_vm.getValue('shadow_color',_vm.elementProps[_vm.screen].shadow_color) || 'rgb(255, 255, 255)',"changeColor":($event) => _vm.updateProps(_vm.screen, 'shadow_color', $event)}})],1):_vm._e(),(_vm.getValue('shadow_style',_vm.elementProps[_vm.screen].shadow_style) == 'box')?_c('div',{staticClass:"py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Shadow Type")]),_c('ToggleButton',{attrs:{"value":_vm.getValue('shadow_type',_vm.elementProps[_vm.screen].shadow_type),"changeValue":($event) => _vm.updateProps(_vm.screen, 'shadow_type', $event),"options":[
        { value: 'inset', label: 'INSET' },
        { value: 'outset', label: 'OUTSET' },
      ],"primaryColor":_vm.primaryColor}})],1):_vm._e(),(_vm.getValue('shadow_style',_vm.elementProps[_vm.screen].shadow_style))?_c('div',{staticClass:"py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Horizontal offset")]),_c('Slider',{attrs:{"primaryColor":_vm.primaryColor,"value":_vm.getValue('shadow_x',_vm.elementProps[_vm.screen].shadow_x),"min":0,"max":20,"changeValue":($event) => _vm.updateProps(_vm.screen, 'shadow_x', $event),"unit":"px","onlyInteger":true}})],1):_vm._e(),(_vm.getValue('shadow_style',_vm.elementProps[_vm.screen].shadow_style))?_c('div',{staticClass:"py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Vertical offset")]),_c('Slider',{attrs:{"primaryColor":_vm.primaryColor,"value":_vm.getValue('shadow_y',_vm.elementProps[_vm.screen].shadow_y),"min":0,"max":20,"changeValue":($event) => _vm.updateProps(_vm.screen, 'shadow_y', $event),"unit":"px","onlyInteger":true}})],1):_vm._e(),(_vm.getValue('shadow_style',_vm.elementProps[_vm.screen].shadow_style))?_c('div',{staticClass:"py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Blur radius")]),_c('Slider',{attrs:{"primaryColor":_vm.primaryColor,"value":_vm.getValue('shadow_blur',_vm.elementProps[_vm.screen].shadow_blur),"min":0,"max":20,"changeValue":($event) => _vm.updateProps(_vm.screen, 'shadow_blur', $event),"unit":"px","onlyInteger":true}})],1):_vm._e(),(_vm.getValue('shadow_style',_vm.elementProps[_vm.screen].shadow_style) == 'box')?_c('div',{staticClass:"py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Spread radius")]),_c('Slider',{attrs:{"primaryColor":_vm.primaryColor,"value":_vm.getValue('shadow_spread',_vm.elementProps[_vm.screen].shadow_spread),"min":0,"max":20,"changeValue":($event) => _vm.updateProps(_vm.screen, 'shadow_spread', $event),"unit":"px","onlyInteger":true}})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }