import { render, staticRenderFns } from "./ArticleList.vue?vue&type=template&id=70e8cbf8&scoped=true&"
import script from "./ArticleList.vue?vue&type=script&lang=js&"
export * from "./ArticleList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70e8cbf8",
  null
  
)

export default component.exports