<template>
  <div
    data-key="image"
    data-label="Image"
    :id="`element-${node.uuid}`" 
    @mouseover="isHovering=true"
    @mouseout="isHovering=false"
    class="sg-wrap block"
  >
    <div 
      class="sg-image image-wrapper" 
      ref="refContent"
      :class="extra_class"
    >
      <img
        class="image"
        :src="imageUrl"
      />
    </div>
    <a v-if="href" class="sg-link image-link" :data-href="href" href="javascript:void(0)" :data-target="target" />
    <ToolBar ref="image_toolbar" 
    :toolbar_id="`image`" 
    class="container-toolbar"
    :class="{ 'container-toolbar-show': isHovering, 'is-dragging': dragging == node.uuid }"
     >
    </ToolBar>
     <div v-if="dragging == node.uuid" class="dragging"></div>

    <v-style>
      {{ custom_css }}
      
      @media (max-width: 767px){
        #element-{{ node.uuid }} {
          {{ getDisplayValue('mobile') }}
          {{ getMarginValue('mobile') }}
        }
        #element-{{ node.uuid }} img {
          width: {{ getValue('width','mobile') }}{{getValue('width_type','mobile')}};
          height: {{ `${!Number.isNaN(getValue('height','mobile', "")) ? `${getValue('height','mobile', "")}px` : "auto"}` }};
          {{ getValue('circle','mobile') ? `border-radius: 50% !important;` : '' }}

          {{ getPaddingValue('mobile') }}
          {{ getBackgroundValue('mobile') }}
          {{ getBorderValue('mobile') }}
          {{ getBorderRadiusValue('mobile') }}
          {{ getOpacityValue('mobile') }}
          {{ getShadowValue('mobile') }}
          {{ getOtherStyle('mobile') }}
        }
        #element-{{ node.uuid }} .image-wrapper {
          justify-content: {{ getValue('alignment','mobile') == 'left' ? 'flex-start' : getValue('alignment','mobile') == 'right' ? 'flex-end' : 'center' }}
        }
      }
      @media (max-width: 991px) and (min-width: 768px){
        #element-{{ node.uuid }} {
          {{ getDisplayValue('tablet') }}
          {{ getMarginValue('tablet') }}
        }
        #element-{{ node.uuid }} img {
          width: {{ getValue('width','tablet') }}{{getValue('width_type','tablet')}};
          height: {{ `${!Number.isNaN(getValue('height','tablet', "")) ? `${getValue('height','tablet', "")}px` : "auto"}` }};

          {{ getValue('circle','tablet') ? `border-radius: 50% !important;` : '' }}
          {{ getPaddingValue('tablet') }}
          {{ getBackgroundValue('tablet') }}
          {{ getBorderValue('tablet') }}
          {{ getBorderRadiusValue('tablet') }}
          {{ getOpacityValue('tablet') }}
          {{ getShadowValue('tablet') }}
          {{ getOtherStyle('tablet') }}
        }
        #element-{{ node.uuid }} .image-wrapper {
          justify-content: {{ getValue('alignment','tablet') == 'left' ? 'flex-start' : getValue('alignment','tablet') == 'right' ? 'flex-end' : 'center' }}
        }
      }
      @media (max-width: 1199px) and (min-width: 992px){
        #element-{{ node.uuid }} {
          {{ getDisplayValue('laptop') }}
          {{ getMarginValue('laptop') }}
        }
        #element-{{ node.uuid }} img {
          width: {{ getValue('width','laptop') }}{{getValue('width_type','laptop')}};
          height: {{ `${!Number.isNaN(getValue('height','laptop', "")) ? `${getValue('height','laptop', "")}px` : "auto"}` }};
          {{ getValue('circle','laptop') ? `border-radius: 50% !important;` : '' }}
          {{ getPaddingValue('laptop') }}
          {{ getBackgroundValue('laptop') }}
          {{ getBorderValue('laptop') }}
          {{ getBorderRadiusValue('laptop') }}
          {{ getOpacityValue('laptop') }}
          {{ getShadowValue('laptop') }}
          {{ getOtherStyle('laptop') }}
        }
        #element-{{ node.uuid }} .image-wrapper {
          justify-content: {{ getValue('alignment','laptop') == 'left' ? 'flex-start' : getValue('alignment','laptop') == 'right' ? 'flex-end' : 'center' }}
        }
      }
      @media (min-width: 1200px) {
        #element-{{ node.uuid }} {
          {{ getDisplayValue('desktop') }}
          {{ getMarginValue('desktop') }}
        }
        #element-{{ node.uuid }} img {
          width: {{ desktop.width }}{{desktop.width_type}};
          height: {{ `${!Number.isNaN(desktop.height) ? `${desktop.height}px` : "auto"}` }};
          {{ getValue('circle','desktop') ? `border-radius: 50% !important;` : '' }}
          {{ getPaddingValue('desktop') }}
          {{ getBackgroundValue('desktop') }}
          {{ getBorderValue('desktop') }}
          {{ getBorderRadiusValue('desktop') }}
          {{ getOpacityValue('desktop') }}
          {{ getShadowValue('desktop') }}
          {{ getOtherStyle('desktop') }}
        }
        #element-{{ node.uuid }} .image-wrapper {
          justify-content: {{ getValue('alignment','desktop') == 'left' ? 'flex-start' : getValue('alignment','desktop') == 'right' ? 'flex-end' : 'center' }}
        }
      }
    </v-style>
  </div>
</template>

<script>
import ToolBar from './utils/ToolBar.vue';
import PictureSetting from './PictureSetting.vue';
import elementStyleMixin from './elementStyleMixin';
import Visibility from "./designSettings/Visibility.vue";
import Spacing from "./designSettings/Spacing.vue";
import Background from "./designSettings/Background.vue";
import Border from "./designSettings/Border.vue";
import Corner from "./designSettings/Corner.vue";
import Shadow from "./designSettings/Shadow.vue";
import Opacity from "./designSettings/Opacity.vue";
import Dimension from './styleSettings/ImageDimension.vue';
import Link from './otherSettings/Link.vue';
import AltTag from './otherSettings/AltTag.vue';
import Alignment from './styleSettings/AlignmentBase.vue';
import ExtraClass from './otherSettings/ExtraClass.vue';
import { designStyles, otherProps, otherStyles, actionTypes } from '@/constants';
import CustomCSS from './styleSettings/CustomCSS.vue';
import Animations from "./designSettings/Animations.vue";

export default {
  mixins: [elementStyleMixin],
  components: { ToolBar },
  inject: ["node",'editor'],
  watch: {
    dragging: function (newValue, oldValue) {
      var $iframe = $("#designEditor").contents();
      var $element = $iframe.find(`#element-${this.node.uuid}`);
      var $image = $element.find(`.sg-image`);
      if (newValue && newValue == this.node.uuid) {
        setTimeout(() => {
        $image.hide();
        },100);
      }else if (oldValue == this.node.uuid){
        $image.show();
      }
    }
  },
  computed: {
    dragging: function () {
      return this.editor?.draggedNode?.uuid
    },
  },
  props: {
    ...otherProps,
    imageUrl: String,
    component: String,
    
    desktop: Object,
    laptop: Object,
    tablet: Object,
    mobile: Object,
    href: String,
    target: String,
    extra_class: String,
  },
  data() {
    return {
      isHovering: false
    }
  },
  craft: {
    defaultProps: {
      ...otherStyles,
      imageUrl: "https://d1g1nj7c6ilt37.cloudfront.net/OxuYjRW4k8ZWuqHNqkLS9CINlwHr5Bm2E2mP2EAh.jpeg",
      extra_class: "",
      href: "",
      target: '_blank',
      href:"https://",
      desktop: {
        ...designStyles,
        width: '100',
        width_type: '%',
        height: 'auto',
        circle: false,
        alignment: 'center'
      },
      laptop: {
        ...designStyles,
      },
      tablet: {
        ...designStyles,
      },
      mobile: {
        ...designStyles,
      },
      elementStyle: {
      },
    },
    settings: {
      
      settings: {
        "Image": PictureSetting,
        "Dimension": { Dimension, multi_screen: true },
        Link,
        "Alt Tag": AltTag,
        "Alignment": { Alignment, multi_screen: true },
        "Extra Class": ExtraClass, "Custom CSS": CustomCSS,
      },
      design: {
        Visibility,
        Spacing: { Spacing, multi_screen: true },
        Background: { Background, multi_screen: true },
        Border: { Border, multi_screen: true },
        Corner: { Corner, multi_screen: true },
        Shadow: { Shadow, multi_screen: true },
        Opacity: { Opacity, multi_screen: true },
      },
      others: {
        Animations: { Animations, actions: actionTypes }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.image {
  max-width: 100%;
}
</style>