<template>
  <component :is="component">
    <slot></slot>
  </component>
</template>

<script>
import Editor from "../core/Editor";
import shortcuts from "../utils";
export default {
  props: {
    component: [Object, String],
    resolverMap: Object,
    import: {
      required: false,
      type: String,
    },
    importPopUp: {
      required: false,
      type: String,
    },
    fonts: {
      required: false,
      type: String,
    },
    animations: {
      required: false,
      type: String,
    },
  },
  data() {
    return {
      editor: new Editor([], this.resolverMap, []),
    };
  },
  computed: {
    isDragging() {
      return this.editor.isDraggable;
    },
  },
  created() {
    if (this.import) {
      this.editor.import(this.import);
      this.editor.fonts = JSON.parse(this.fonts || "[]");
      this.editor.animations = JSON.parse(this.animations || "[]");
    }
    if (this.importPopUp) {
      this.editor.importPopUp(this.importPopUp);
    }
    this.editor.state = {
      0: {
        nodes: this.editor.nodes,
        popups: this.editor.popups,
      },
    };
    this.editor.current_state = 0;
  },
  provide() {
    return {
      editor: this.editor,
    };
  },
  mounted() {
    const _this = this;
    document.addEventListener("keydown", function (event) {
      shortcuts(event, _this.editor);
    });
  },
  beforeDestroy() {
    document.removeEventListener("keydown");
  },
  methods: {},
};
</script>
