<template>
  <div label-position="top" size="small" :model="elementProps">
    <div class="d-flex align-items-center justify-content-between py-1">
      <span class="sgSubTitle">Enable</span>
      <el-switch
        :value="elementProps.image_same_height"
        :active-color="primaryColor"
        inactive-color="#949494"
        @change="updateProps('image_same_height', $event)"
      />
    </div>
    <div class="sgDesc mt-0">Make (P) Image all the same height.</div>
    <div class="d-flex flex-column py-2" v-if="elementProps.image_same_height">
      <span class="setting-title">Image Height</span>
      <Slider
        class="mt-2"
        :value="elementProps.image_height"
        :primaryColor="primaryColor"
        v-bind:changeValue="($event) => updateProps('image_height', $event)"
        unit="px"
        :min="100"
        :max="500"
        :onlyInteger="true"
      />
    </div>

    <div class="d-flex align-items-center justify-content-between py-1">
      <span class="sgSubTitle">Enable</span>
      <el-switch
        :value="elementProps.title_same_height"
        :active-color="primaryColor"
        inactive-color="#949494"
        @change="updateProps('title_same_height', $event)"
      />
    </div>
    <div class="sgDesc mt-1">Make (P) Title all the same height.</div>
    <div class="d-flex flex-column py-2" v-if="elementProps.title_same_height">
      <span class="setting-title">Title Height</span>
      <Slider
        class="mt-2"
        :value="elementProps.title_height"
        :primaryColor="primaryColor"
        v-bind:changeValue="($event) => updateProps('title_height', $event)"
        unit="px"
        :min="10"
        :max="500"
        :onlyInteger="true"
      />
    </div>
  </div>
</template>

<script>
import mixin from "../../../elements/styleSettings/mixin";
import ToggleButton from "../../../elements/common/ToggleButton.vue";
import Slider from "../../../elements/common/Slider.vue";

export default {
  mixins: [mixin],
  components: { ToggleButton, Slider },
  props: {
    primaryColor: String
  },
  methods: {
    updateProps(name, value) {
      if(value ==  this.elementProps[name]) return;
      this.elementPropsSetter({ [name]: value });
    },
  },
};
</script>

<style scoped>
</style>
