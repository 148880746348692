<template>
  <div
    data-label="Icon"
    data-key="icon"
    class="sg-wrap block"
    :id="`element-${node.uuid}`"
    @mouseover="isHovering=true" @mouseout="isHovering=false"
    :class="{'dragging': dragging == node.uuid}"
  >
    <div
      class="bl"
      :class="extra_class"
    >
      <a v-if="href?.length > 0" :data-href="href" href="javascript:void(0)" :data-target="target">
        <div class="sg-icon-wrap">
          <i :class="`fa ${icon}`"></i>
        </div>
      </a>
      <div class="sg-icon-wrap" v-else>
        <i :class="`fa ${icon}`"></i>
      </div>
    </div>
    <ToolBar ref="icon_toolbar"  :toolbar_id="`icon`" class="container-toolbar"
    :class="{ 'container-toolbar-show': isHovering, 'is-dragging': dragging == node.uuid }"
     ></ToolBar>
    
    <v-style>
      {{ custom_css }}
      #element-{{ node.uuid }} .sg-icon-wrap {
        color: {{ color }};
      }
      @media (max-width: 767px) { 
        #element-{{ node.uuid }} {
          {{ getDisplayValue('mobile') }}
          {{ getMarginValue('mobile') }}
        }
        #element-{{ node.uuid }} .bl {
          text-align: {{ getValue('alignment','mobile') }};
        }
        #element-{{ node.uuid }} .sg-icon-wrap {
          font-size: {{ getValue('font_size', 'mobile') }}px;
          {{ getPaddingValue('mobile') }}
          {{ getBackgroundValue('mobile') }}
          {{ getBorderValue('mobile') }}
          {{ getBorderRadiusValue('mobile') }}
          {{ getOpacityValue('mobile') }}
          {{ getShadowValue('mobile') }}
          {{ getOtherStyle('mobile') }}
        }
      } 
      @media (max-width: 991px) and (min-width: 768px) {
        #element-{{ node.uuid }} {
          {{ getDisplayValue('tablet') }}
          {{ getMarginValue('tablet') }}
        }
        #element-{{ node.uuid }} .bl {
          text-align: {{ getValue('alignment','tablet') }};
        }
        #element-{{ node.uuid }} .sg-icon-wrap {
          font-size: {{ getValue('font_size', 'tablet') }}px;
          {{ getPaddingValue('tablet') }}
          {{ getBackgroundValue('tablet') }}
          {{ getBorderValue('tablet') }}
          {{ getBorderRadiusValue('tablet') }}
          {{ getOpacityValue('tablet') }}
          {{ getShadowValue('tablet') }}
          {{ getOtherStyle('tablet') }}
        }
      }
      @media (max-width: 1199px) and (min-width: 992px){ 
        #element-{{ node.uuid }} {
          {{ getDisplayValue('laptop') }}
          {{ getMarginValue('laptop') }}
        }
        #element-{{ node.uuid }} .bl {
          text-align: {{ getValue('alignment','laptop') }};
        }
        #element-{{ node.uuid }} .sg-icon-wrap {
          font-size: {{ getValue('font_size', 'laptop') }}px;
          {{ getPaddingValue('laptop') }}
          {{ getBackgroundValue('laptop') }}
          {{ getBorderValue('laptop') }}
          {{ getBorderRadiusValue('laptop') }}
          {{ getOpacityValue('laptop') }}
          {{ getShadowValue('laptop') }}
          {{ getOtherStyle('laptop') }}
        }
      } 
      @media (min-width: 1200px){
        #element-{{ node.uuid }} {
          {{ getDisplayValue('desktop') }}
          {{ getMarginValue('desktop') }}
        }
        #element-{{ node.uuid }} .bl {
          text-align: {{ desktop.alignment }};
        }
        #element-{{ node.uuid }} .sg-icon-wrap {
          font-size: {{ getValue('font_size', 'desktop') }}px;
          {{ getPaddingValue('desktop') }}
          {{ getBackgroundValue('desktop') }}
          {{ getBorderValue('desktop') }}
          {{ getBorderRadiusValue('desktop') }}
          {{ getOpacityValue('desktop') }}
          {{ getShadowValue('desktop') }}
          {{ getOtherStyle('desktop') }}
        }
      }
    </v-style>
  </div>
</template>

<script>
import IconPicker from "./styleSettings/IconPicker.vue";
import Color from "./styleSettings/Color.vue";
import elementStyleMixin from "./elementStyleMixin";
import Size from "./styleSettings/Size.vue";
import AlignmentBase from "./styleSettings/AlignmentBase.vue";
import Link from "./otherSettings/Link.vue";
import ExtraClass from "./otherSettings/ExtraClass.vue";
import Visibility from "./designSettings/Visibility.vue";
import Spacing from "./designSettings/Spacing.vue";
import Background from "./designSettings/Background.vue";
import Border from "./designSettings/Border.vue";
import Corner from "./designSettings/Corner.vue";
import Shadow from "./designSettings/Shadow.vue";
import Opacity from "./designSettings/Opacity.vue";
import ToolBar from './utils/ToolBar.vue';
import CustomCSS from "./styleSettings/CustomCSS.vue";
import { designStyles, otherProps, otherStyles, actionTypes } from "@/constants";
import Animations from "./designSettings/Animations.vue";

export default {
  mixins: [elementStyleMixin],
  components: {  ToolBar },
  props: {
    ...otherProps,
    icon: String,
    extra_class: String,
    href: String,
    target: String,
    size:Number,
    item: Object,
    color: String,
    desktop: Object,
    laptop: Object,
    mobile: Object,
    tablet: Object,
    disabled: Boolean,
  },
  data() {
    return {
      isHovering: false,
    }
  },
  inject: ["node",'editor'],
  computed: {
    dragging: function () {
      return this.editor?.draggedNode?.uuid
    },
  },
  craft: {
    defaultProps: {
      ...otherStyles,
      icon: "fa-heart",
      extra_class: "",
      href: "",
      target: "_blank",
      color: 'rgb(0, 0, 0)',
      desktop: {
        ...designStyles,
        font_size: 32,
        alignment: 'center'
      },
      laptop: {
        ...designStyles,
      },
      tablet: {
        ...designStyles,
      },
      mobile: {
        ...designStyles,
      },
      elementStyle: {
      },
    },
    settings: {
      settings: {
        "Icon": IconPicker,
        Color,
        Size: {Size, multi_screen: true},
        Alignment: {"Alignment": AlignmentBase, multi_screen: true},
        Link,
        "Extra Class": ExtraClass, "Custom CSS": CustomCSS,
      },
      design: {
        Visibility,
        Spacing: { Spacing, multi_screen: true },
        Background: { Background, multi_screen: true },
        Border: { Border, multi_screen: true },
        Corner: { Corner, multi_screen: true },
        Shadow: { Shadow, multi_screen: true },
        Opacity: { Opacity, multi_screen: true },
      },
      others: {
        Animations: { Animations, actions: actionTypes }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
