<template>
  <div label-position="top" size="mini" :model="elementProps">
    <div class="d-flex flex-column py-2">
      <span class="setting-title">Position</span>
      <Select
        class="mt-2"
        :primaryColor="primaryColor"
        :value="elementProps.position"
        v-bind:changeValue="($event) => updateProps('position', $event)"
        :options="[
          {
            value: 'top-left',
            label: 'Top Left',
          },
          {
            value: 'top-right',
            label: 'Top Right',
          },
          {
            value: 'bottom-left',
            label: 'Bottom Left',
          },
          {
            value: 'bottom-right',
            label: 'Bottom Right',
          },
        ]"
      />
    </div>
    <div class="d-flex flex-column py-2">
      <span class="setting-title">Background Color</span>
      <ColorPicker
        class="mt-2"
        :value="elementProps.background_color"
        v-bind:changeColor="($event) => updateProps('background_color', $event)"
        :primaryColor="primaryColor"
      />
    </div>
    <div class="d-flex flex-column py-2">
      <span class="setting-title">Background Fill</span>
      <ColorPicker
        class="mt-2"
        :value="elementProps.background_color_fill"
        v-bind:changeColor="($event) => updateProps('background_color_fill', $event)"
        :primaryColor="primaryColor"
      />
    </div>
    <div class="d-flex flex-column py-2">
      <span class="setting-title">Shadow Fill</span>
      <ColorPicker
        class="mt-2"
        :value="elementProps.shadow_color_fill"
        v-bind:changeColor="($event) => updateProps('shadow_color_fill', $event)"
        :primaryColor="primaryColor"
      />
    </div>
    <div class="d-flex align-items-center justify-content-between py-2">
      <span class="sgSubTitle">Effect</span>
      <el-switch
        :value="elementProps.effect"
        :active-color="primaryColor"
        inactive-color="#949494"
        @change="updateProps('effect', $event)"
      />
    </div>
  </div>
</template>
  
  <script>
import mixin from "../../../elements/styleSettings/mixin";
import Select from "../../../elements/common/Select.vue";
import Slider from "../../../elements/common/Slider.vue";
import ColorPicker from "../../../elements/common/ColorPicker.vue";
import Upload from "../../../elements/common/Upload.vue";

export default {
  mixins: [mixin],
  components: { Select, Slider, ColorPicker, Upload },
  props: {
    primaryColor: String
  },
  methods: {
    updateProps(name, value) {
      if (value == this.elementProps[name]) return;
      this.elementPropsSetter({ [name]: value });
    },
    updateMixStyle(key, value, object) {
      const style = this.getStyle(object);
      this.setStyle(object, {
        ...style,
        [key]: value,
      });
    },
  },
};
</script>
  
  <style scoped>
</style>
  