<template>
    <div
      data-label="Floating Button"
      data-key="floating-button"
      class="sg-wrap module"
      :id="`element-${node.uuid}`"
      @mouseover="isHovering=true"
      @mouseout="isHovering=false"
      :class="{'dragging': dragging == node.uuid}"
    >
        <div class="sg-module sg-button-contact">
            <div class="phone-vr">
                <div class="phone-vr-circle-fill"></div>
                <div class="phone-vr-img-circle">
                    <a :data-target="target" :data-href="href">				
                    <img width="100" height="95" 
                        :src="image||'https://cdn.shopify.com/s/images/admin/no-image-2048x2048.gif'" 
                        :data-lazy-src="image||'https://cdn.shopify.com/s/images/admin/no-image-2048x2048.gif'" 
                        data-ll-status="loaded" 
                        class="entered lazyloaded">
                    <noscript>
                        <img width="100" height="95" :src="image||'https://cdn.shopify.com/s/images/admin/no-image-2048x2048.gif'" />
                    </noscript>
                    </a>
                </div>
            </div>
        </div>
        <ToolBar
            ref="floating-button"
            :toolbar_id="`floating-button`"
            class="container-toolbar"
            :class="{ 'container-toolbar-show': isHovering, 'is-dragging': dragging == node.uuid }"
            :isDraggable="false"
            >
            </ToolBar>
      <v-style>
        {{ custom_css }}
        #element-{{ node.uuid }} .phone-vr-circle-fill {
          {{ effect ? "animation: zoom 1.3s infinite; -webkit-animation: zoom 1.3s infinite;" : '' }};
        }

        #element-{{ node.uuid }} .phone-vr-circle-fill {
            box-shadow: 0 0 0 0 {{shadow_color_fill}};
            background-color: {{background_color_fill}};
        }

        #element-{{ node.uuid }} .phone-vr-img-circle {
            -webkit-animation: phonering-alo-circle-img-anim 1s infinite ease-in-out;
            {{ effect ? "animation: phone-vr-circle-fill 1s infinite ease-in-out":''}};
        }

        #element-{{ node.uuid }} .phone-vr-img-circle {
            background-color: {{background_color}};
        }

        @media (max-width: 767px) { 
          #element-{{ node.uuid }} {
            {{ getDisplayValue('mobile') }}
            {{ getMarginValue('mobile') }}
          }
          #element-{{ node.uuid }} .sg-button-contact {
            background-color: {{ getValue('background_color','mobile') }};
            color: {{ getValue('color','mobile') }};
            {{ getPaddingValue('mobile') }}
            {{ getOpacityValue('mobile') }}
            {{ getOtherStyle('mobile') }}
          }
        } 
        @media (max-width: 991px) and (min-width: 768px) {
          #element-{{ node.uuid }} {
            {{ getDisplayValue('tablet') }}
            {{ getMarginValue('tablet') }}
          }
  
          #element-{{ node.uuid }} .sg-button-contact {
            background-color: {{ getValue('background_color','tablet') }};
            color: {{ getValue('color','tablet') }};
            {{ getPaddingValue('tablet') }}
            {{ getOpacityValue('tablet') }}
            {{ getOtherStyle('tablet') }}
          }
        }
        @media (max-width: 1199px) and (min-width: 992px){ 
          #element-{{ node.uuid }} {
            {{ getDisplayValue('laptop') }}
            {{ getMarginValue('laptop') }}
          }
  
          #element-{{ node.uuid }} .sg-button-contact {
            background-color: {{ getValue('background_color','laptop') }};
            color: {{ getValue('color','laptop') }};
            {{ getPaddingValue('laptop') }}
            {{ getOpacityValue('laptop') }}
            {{ getOtherStyle('laptop') }}
          }
        } 
        @media (min-width: 1200px){
          #element-{{ node.uuid }} {
            {{ getDisplayValue('desktop') }}
            {{ getMarginValue('desktop') }}
          }
          #element-{{ node.uuid }} .sg-button-contact {
            background-color: {{ desktop.background_color }};
            color: {{ desktop.color }};
            {{ getPaddingValue('desktop') }}
            {{ getOpacityValue('desktop') }}
            {{ getOtherStyle('desktop') }}
          }
        }
      </v-style>
    </div>
  </template>
  
  <script>
import ToolBar from "../../elements/utils/ToolBar.vue";

import elementStyleMixin from "../../elements/elementStyleMixin";
import DisplayFloating from "./settings/DisplayFloating.vue";
import ButtonLink from "./settings/ButtonLink.vue";
import Image from "./settings/Image.vue";

import ExtraClass from "../../elements/otherSettings/ExtraClass.vue";
import Visibility from "../../elements/designSettings/Visibility.vue";
import Spacing from "../../elements/designSettings/Spacing.vue";
import Opacity from "../../elements/designSettings/Opacity.vue";
import CustomCSS from '../../elements/styleSettings/CustomCSS.vue';
  import { designStyles, otherProps, otherStyles, actionTypes } from "@/constants";
  
  export default {
    mixins: [elementStyleMixin],
    components: {  ToolBar },
    props: {
      ...otherProps,
      extra_class: String,
      component: String,
      href: String,
      target: String,
      desktop: Object,
      laptop: Object,
      tablet: Object,
      mobile: Object,
      position:String,
      shadow_color_fill:String,
      background_color_fill:String,
      background_color:String,
      effect: Boolean,
      image:String
    },
    data() {
      return {
        isHovering: false,
      }
    },
    methods: {
    },
    inject: ["node",'editor'],
    computed: {
      dragging: function () {
        return this.editor?.draggedNode?.uuid
      },
    },
    craft: {
      defaultProps: {
        ...otherStyles,
        image:"https://tdtv.com.vn/wp-content/plugins/button-contact-vr/img/zalo.png",
        extra_class: "",
        position:'bottom-left',
        href: "https://zing.vn",
        target: "_blank",
        shadow_color_fill:'rgb(33,150,243)',
        background_color_fill:"rgba(33,150,243,0.7)",
        background_color:"rgb(33,150,243)",
        effect: true,
        desktop: {
          ...designStyles,
        },
        laptop: {
          ...designStyles,
        },
        tablet: {
          ...designStyles,
        },
        mobile: {
          ...designStyles,
        },
        elementStyle: {
        },
      },
      settings: {
        settings: {
            "Button Link":ButtonLink,
            "Display":DisplayFloating,
            "Image":Image
        },
        design: {
          Visibility,
          Spacing: { Spacing, multi_screen: true },
          Opacity: { Opacity, multi_screen: true },
        }
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  </style>
  