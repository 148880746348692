export const productData = `[
  {
    "componentName": "Canvas",
    "props": {
      "elementStyle": {},
      "component": "WrapperContainer",
      "body_text_color": "rgb(0, 0, 0)",
      "body_background": "rgb(255, 255, 255)",
      "body_font_family": "inherit",
      "body_font_size": 16,
      "link_type": "normal",
      "link_text_color": "rgb(0, 96, 229)",
      "link_font_family": "inherit",
      "link_font_weight": "400",
      "link_font_size": 16,
      "link_text_decoration": "underline",
      "link_hover_text_color": "rgb(0, 96, 229)",
      "link_hover_font_family": "inherit",
      "link_hover_font_weight": "400",
      "link_hover_font_size": 16,
      "link_hover_text_decoration": "underline",
      "button_font_family": "inherit",
      "button_font_size": 16,
      "button_font_weight": "700",
      "button_text_transform": "uppercase",
      "button_padding_unit": "px",
      "button_padding_top": 12,
      "button_padding_right": 12,
      "button_padding_bottom": 12,
      "button_padding_left": 12,
      "button_type": "normal",
      "button_text_color": "rgb(255, 255, 255)",
      "button_background_color": "rgb(0, 0, 0)",
      "button_border_type": "none",
      "button_border_width_unit": "px",
      "button_border_top_width": 1,
      "button_border_right_width": 1,
      "button_border_bottom_width": 1,
      "button_border_left_width": 1,
      "button_border_color": "rgb(0, 0, 0)",
      "button_border_radius_unit": "px",
      "button_border_top_left_radius": 0,
      "button_border_top_right_radius": 0,
      "button_border_bottom_right_radius": 0,
      "button_border_bottom_left_radius": 0,
      "button_hover_text_color": "rgb(255, 255, 255)",
      "button_hover_background_color": "rgb(0, 0, 0)",
      "button_hover_border_type": "none",
      "button_hover_border_width_unit": "px",
      "button_hover_border_top_width": 1,
      "button_hover_border_right_width": 1,
      "button_hover_border_bottom_width": 1,
      "button_hover_border_left_width": 1,
      "button_hover_border_color": "rgb(0, 0, 0)",
      "button_hover_border_radius_unit": "px",
      "button_hover_border_top_left_radius": 0,
      "button_hover_border_top_right_radius": 0,
      "button_hover_border_bottom_right_radius": 0,
      "button_hover_border_bottom_left_radius": 0,
      "image_type": "normal",
      "image_border_type": "none",
      "image_border_width_unit": "px",
      "image_border_top_width": 0,
      "image_border_right_width": 0,
      "image_border_bottom_width": 0,
      "image_border_left_width": 0,
      "image_border_color": "rgb(0, 0, 0)",
      "image_border_radius_unit": "px",
      "image_border_top_left_radius": 0,
      "image_border_top_right_radius": 0,
      "image_border_bottom_right_radius": 0,
      "image_border_bottom_left_radius": 0,
      "image_opacity": 1,
      "image_hover_border_type": "none",
      "image_hover_border_width_unit": "px",
      "image_hover_border_top_width": 0,
      "image_hover_border_right_width": 0,
      "image_hover_border_bottom_width": 0,
      "image_hover_border_left_width": 0,
      "image_hover_border_color": "rgb(0, 0, 0)",
      "image_hover_border_radius_unit": "px",
      "image_hover_border_top_left_radius": 0,
      "image_hover_border_top_right_radius": 0,
      "image_hover_border_bottom_right_radius": 0,
      "image_hover_border_bottom_left_radius": 0,
      "image_hover_opacity": 1
    },
    "children": [
      {
        "componentName": "Product",
        "props": {
          "dynamic":true,
          "template": false,
          "section": "module",
          "product_id": null,
          "product_name": null,
          "form_id": "",
          "extra_class": "",
          "desktop": {
            "display": "block",
            "margin_top": 0,
            "margin_left": 0,
            "margin_bottom": 0,
            "margin_right": 0,
            "padding_top": 30,
            "padding_left": 10,
            "padding_bottom": 30,
            "padding_right": 10
          },
          "laptop": {
            "display": "block",
            "margin_top": 0,
            "margin_left": 0,
            "margin_bottom": 0,
            "margin_right": 0,
            "padding_top": 30,
            "padding_left": 10,
            "padding_bottom": 30,
            "padding_right": 10
          },
          "tablet": {
            "display": "block",
            "margin_top": 0,
            "margin_left": 0,
            "margin_bottom": 0,
            "margin_right": 0,
            "padding_top": 30,
            "padding_left": 0,
            "padding_bottom": 30,
            "padding_right": 0
          },
          "mobile": {
            "display": "block",
            "margin_top": 0,
            "margin_left": 0,
            "margin_bottom": 0,
            "margin_right": 0,
            "padding_top": 30,
            "padding_left": 0,
            "padding_bottom": 30,
            "padding_right": 0
          },
          "elementStyle": {

          },
          "component": "Product"
        },
        "children": [
          {
            "componentName": "Row",
            "props": {
              "full_width": false,
              "extra_class": "",
              "hiddenConfig": [

              ],
              "vertical_align": "top",
              "equal_height": false,
              "column_gap": "15",
              "desktop": {
                "display": "block",
                "margin_top": 0,
                "margin_left": 0,
                "margin_bottom": 0,
                "margin_right": 0,
                "padding_top": 30,
                "padding_left": 0,
                "padding_bottom": 10,
                "padding_right": 0,
                "layout": "6_6",
                "width": "1200",
                "width_type": "px"
              },
              "laptop": {
                "display": "block",
                "margin_top": 0,
                "margin_left": 0,
                "margin_bottom": 0,
                "margin_right": 0,
                "padding_top": 30,
                "padding_left": 0,
                "padding_bottom": 10,
                "padding_right": 0,
                "layout": "6_6",
                "width": "1200",
                "width_type": "px"
              },
              "tablet": {
                "display": "block",
                "margin_top": 0,
                "margin_left": 0,
                "margin_bottom": 0,
                "margin_right": 0,
                "padding_top": 30,
                "padding_left": 0,
                "padding_bottom": 10,
                "padding_right": 0,
                "layout": "6_6",
                "width": "1200",
                "width_type": "px"
              },
              "mobile": {
                "display": "block",
                "margin_top": 0,
                "margin_left": 0,
                "margin_bottom": 0,
                "margin_right": 0,
                "padding_top": 30,
                "padding_left": 0,
                "padding_bottom": 10,
                "padding_right": 0,
                "layout": "12",
                "width": "1200",
                "width_type": "px"
              },
              "elementStyle": {

              }
            },
            "children": [
              {
                "componentName": "Canvas",
                "props": {
                  "dataLayoutXl": "12",
                  "dataLayoutLg": "6",
                  "dataLayoutMd": "6",
                  "dataLayoutSm": "6",
                  "elementStyle": {

                  },
                  "component": "Column"
                },
                "children": [
                  {
                    "componentName": "ProductImage",
                    "props": {
                      "parent": "Product",
                      "extra_class": "",
                      "typeWidth": "%",
                      "isProductLink": false,
                      "dimensionActives": [
                        "width",
                        "height"
                      ],
                      "desktop": {
                        "display": "block",
                        "margin_top": 0,
                        "margin_left": 0,
                        "margin_bottom": 0,
                        "margin_right": 0,
                        "padding_top": 30,
                        "padding_left": 10,
                        "padding_bottom": 30,
                        "padding_right": 10,
                        "width": "60",
                        "width_type": "%",
                        "height": "auto",
                        "alignment": "center"
                      },
                      "laptop": {
                        "display": "block",
                        "margin_top": 0,
                        "margin_left": 0,
                        "margin_bottom": 0,
                        "margin_right": 0,
                        "padding_top": 30,
                        "padding_left": 10,
                        "padding_bottom": 30,
                        "padding_right": 10,
                        "width": "60",
                        "width_type": "%",
                        "height": "auto",
                        "alignment": "center"
                      },
                      "tablet": {
                        "display": "block",
                        "margin_top": 0,
                        "margin_left": 0,
                        "margin_bottom": 0,
                        "margin_right": 0,
                        "padding_top": 30,
                        "padding_left": 10,
                        "padding_bottom": 30,
                        "padding_right": 10,
                        "width": "60",
                        "width_type": "%",
                        "height": "auto",
                        "alignment": "center"
                      },
                      "mobile": {
                        "display": "block",
                        "margin_top": 0,
                        "margin_left": 0,
                        "margin_bottom": 0,
                        "margin_right": 0,
                        "padding_top": 30,
                        "padding_left": 10,
                        "padding_bottom": 30,
                        "padding_right": 10,
                        "width": "60",
                        "width_type": "%",
                        "height": "auto",
                        "alignment": "center"
                      },
                      "elementStyle": {

                      },
                      "component": "(P) Image"
                    },
                    "children": [

                    ],
                    "addition": {

                    },
                    "uuid": "reset"
                  },
                  {
                    "componentName": "ProductImageList",
                    "props": {
                      "parent": "Product",
                      "typeWidth": null,
                      "display_style": "default",
                      "loop": false,
                      "extra_class": "",
                      "navSpeed": 1200,
                      "borderactive": false,
                      "lightbox": false,
                      "lightbox_icon": false,
                      "desktop": {
                        "display": "block",
                        "margin_top": 0,
                        "margin_left": 0,
                        "margin_bottom": 6,
                        "margin_right": 6,
                        "padding_top": 0,
                        "padding_left": 0,
                        "padding_bottom": 0,
                        "padding_right": 0,
                        "columns": 3,
                        "spacing": 5,
                        "show_dots": true,
                        "show_pre_next": false,
                        "width": "auto",
                        "height": "100",
                        "width_type": "",
                        "alignment": "left",
                        "image_spacing": 6
                      },
                      "laptop": {
                        "display": "block",
                        "margin_top": 0,
                        "margin_left": 0,
                        "margin_bottom": 6,
                        "margin_right": 6,
                        "padding_top": 0,
                        "padding_left": 0,
                        "padding_bottom": 0,
                        "padding_right": 0,
                        "columns": 3,
                        "spacing": 5,
                        "show_dots": true,
                        "show_pre_next": false,
                        "width": "auto",
                        "height": "100",
                        "width_type": "",
                        "alignment": "left",
                        "image_spacing": 6
                      },
                      "tablet": {
                        "display": "block",
                        "margin_top": 0,
                        "margin_left": 0,
                        "margin_bottom": 6,
                        "margin_right": 6,
                        "padding_top": 0,
                        "padding_left": 0,
                        "padding_bottom": 0,
                        "padding_right": 0,
                        "columns": 3,
                        "spacing": 5,
                        "show_dots": true,
                        "show_pre_next": false,
                        "width": "auto",
                        "height": "100",
                        "width_type": "",
                        "alignment": "left",
                        "image_spacing": 6
                      },
                      "mobile": {
                        "display": "block",
                        "margin_top": 0,
                        "margin_left": 0,
                        "margin_bottom": 6,
                        "margin_right": 6,
                        "padding_top": 0,
                        "padding_left": 0,
                        "padding_bottom": 0,
                        "padding_right": 0,
                        "columns": 2,
                        "spacing": 5,
                        "image_spacing": 6,
                        "show_dots": true,
                        "show_pre_next": false,
                        "width": "auto",
                        "height": "100",
                        "width_type": "",
                        "alignment": "left"
                      },
                      "elementStyle": {
                        "border-color": "rgb(97, 97, 97)"
                      },
                      "component": "(P) Image List"
                    },
                    "children": [

                    ],
                    "addition": {

                    },
                    "uuid": "reset"
                  }
                ],
                "addition": {

                },
                "uuid": "reset"
              },
              {
                "componentName": "Canvas",
                "props": {
                  "dataLayoutXl": "12",
                  "dataLayoutLg": "6",
                  "dataLayoutMd": "6",
                  "dataLayoutSm": "6",
                  "elementStyle": {

                  },
                  "component": "Column"
                },
                "children": [
                  {
                    "componentName": "ProductTitle",
                    "props": {
                      "headingType": "h3",
                      "backgroundType": "color",
                      "content": "Product",
                      "isProductLink": false,
                      "parent": "Product",
                      "typographyActives": [
                        "font-weight",
                        "font-size",
                        "line-height",
                        "letter-spacing",
                        "color",
                        "font-family",
                        "text-transform"
                      ],
                      "desktop": {
                        "display": "block",
                        "margin_top": 0,
                        "margin_left": 0,
                        "margin_bottom": 0,
                        "margin_right": 0,
                        "padding_top": 0,
                        "padding_left": 0,
                        "padding_bottom": 0,
                        "padding_right": 0,
                        "font_weight": "600",
                        "font_size": 20,
                        "line_height": 1.2,
                        "letter_spacing": 0,
                        "color": "rgb(51, 51, 51)",
                        "font_family": "inherit",
                        "text_transform": "none",
                        "alignment": "center"
                      },
                      "laptop": {
                        "display": "block",
                        "margin_top": 0,
                        "margin_left": 0,
                        "margin_bottom": 0,
                        "margin_right": 0,
                        "padding_top": 0,
                        "padding_left": 0,
                        "padding_bottom": 0,
                        "padding_right": 0,
                        "font_weight": "600",
                        "font_size": 20,
                        "line_height": 1.2,
                        "letter_spacing": 0,
                        "color": "rgb(51, 51, 51)",
                        "font_family": "inherit",
                        "text_transform": "none",
                        "alignment": "center"
                      },
                      "tablet": {
                        "display": "block",
                        "margin_top": 0,
                        "margin_left": 0,
                        "margin_bottom": 0,
                        "margin_right": 0,
                        "padding_top": 0,
                        "padding_left": 0,
                        "padding_bottom": 0,
                        "padding_right": 0,
                        "font_weight": "600",
                        "font_size": 20,
                        "line_height": 1.2,
                        "letter_spacing": 0,
                        "color": "rgb(51, 51, 51)",
                        "font_family": "inherit",
                        "text_transform": "none",
                        "alignment": "center"
                      },
                      "mobile": {
                        "display": "block",
                        "margin_top": 0,
                        "margin_left": 0,
                        "margin_bottom": 0,
                        "margin_right": 0,
                        "padding_top": 0,
                        "padding_left": 0,
                        "padding_bottom": 0,
                        "padding_right": 0,
                        "font_weight": "600",
                        "font_size": 20,
                        "line_height": 1.2,
                        "letter_spacing": 0,
                        "color": "rgb(51, 51, 51)",
                        "font_family": "inherit",
                        "text_transform": "none",
                        "alignment": "center"
                      },
                      "elementStyle": {

                      },
                      "component": "(P) Title"
                    },
                    "children": [

                    ],
                    "addition": {

                    },
                    "uuid": "reset"
                  },
                  {
                    "componentName": "ProductPrice",
                    "props": {
                      "price": "20.99$",
                      "compare": "32.99$",
                      "parent": "Product",
                      "compare_first": true,
                      "show_compare_price": false,
                      "compare_gaps": 5,
                      "typographyActives": [
                        "font-size",
                        "font-weight",
                        "color",
                        "font-family"
                      ],
                      "desktop": {
                        "display": "block",
                        "margin_top": 0,
                        "margin_left": 0,
                        "margin_bottom": 0,
                        "margin_right": 0,
                        "padding_top": 0,
                        "padding_left": 0,
                        "padding_bottom": 0,
                        "padding_right": 0,
                        "font_weight": "400",
                        "font_size": 16,
                        "color": "rgb(51, 51, 51)",
                        "font_family": "inherit",
                        "alignment": "center",
                        "compare_first": true,
                        "compare_font_weight": "400",
                        "compare_font_size": 16,
                        "compare_color": "rgb(51, 51, 51)",
                        "show_line_through": true,
                        "line_through_color": "rgb(51, 51, 51)",
                        "line_through_position": 0
                      },
                      "laptop": {
                        "display": "block",
                        "margin_top": 0,
                        "margin_left": 0,
                        "margin_bottom": 0,
                        "margin_right": 0,
                        "padding_top": 0,
                        "padding_left": 0,
                        "padding_bottom": 0,
                        "padding_right": 0,
                        "font_weight": "400",
                        "font_size": 16,
                        "color": "rgb(51, 51, 51)",
                        "font_family": "inherit",
                        "alignment": "center",
                        "compare_font_weight": "400",
                        "compare_font_size": 16,
                        "compare_color": "rgb(51, 51, 51)",
                        "show_line_through": true,
                        "line_through_color": "rgb(51, 51, 51)",
                        "line_through_position": 0
                      },
                      "tablet": {
                        "display": "block",
                        "margin_top": 0,
                        "margin_left": 0,
                        "margin_bottom": 0,
                        "margin_right": 0,
                        "padding_top": 0,
                        "padding_left": 0,
                        "padding_bottom": 0,
                        "padding_right": 0,
                        "font_weight": "400",
                        "font_size": 16,
                        "color": "rgb(51, 51, 51)",
                        "font_family": "inherit",
                        "alignment": "center",
                        "compare_font_weight": "400",
                        "compare_font_size": 16,
                        "compare_color": "rgb(51, 51, 51)",
                        "show_line_through": true,
                        "line_through_color": "rgb(51, 51, 51)",
                        "line_through_position": 0
                      },
                      "mobile": {
                        "display": "block",
                        "margin_top": 0,
                        "margin_left": 0,
                        "margin_bottom": 0,
                        "margin_right": 0,
                        "padding_top": 0,
                        "padding_left": 0,
                        "padding_bottom": 0,
                        "padding_right": 0,
                        "font_weight": "400",
                        "font_size": 16,
                        "color": "rgb(51, 51, 51)",
                        "font_family": "inherit",
                        "alignment": "center",
                        "compare_font_weight": "400",
                        "compare_font_size": 16,
                        "compare_color": "rgb(51, 51, 51)",
                        "show_line_through": true,
                        "line_through_color": "rgb(51, 51, 51)",
                        "line_through_position": 0
                      },
                      "elementStyle": {

                      },
                      "component": "(P) Price"
                    },
                    "children": [

                    ],
                    "addition": {

                    },
                    "uuid": "reset"
                  },
                  {
                    "componentName": "ProductVariants",
                    "props": {
                      "parent": "Product",
                      "variant_type": "swatch",
                      "show_option_1": true,
                      "show_option_2": true,
                      "show_option_3": true,
                      "show_label": true,
                      "blank_text": "Please select an item in the list",
                      "blank_option": false,
                      "display_style": "default",
                      "columns": "1",
                      "group_by": "separately",
                      "label_width": "100",
                      "elementStyle": {

                      },
                      "swatch_margin_bottom": 15,
                      "swatch_distance": 15,
                      "desktop": {
                        "display": "block",
                        "margin_top": 0,
                        "margin_left": 0,
                        "margin_bottom": 0,
                        "margin_right": 0,
                        "padding_top": 0,
                        "padding_left": 20,
                        "padding_bottom": 0,
                        "padding_right": 20,
                        "label_margin_bottom": 5,
                        "label_font_weight": "400",
                        "label_font_size": 16,
                        "label_line_height": 1.2,
                        "label_color": "rgb(61, 66, 70)",
                        "label_font_family": "inherit",
                        "show_swatch_text": true,
                        "swatch_font_weight": "400",
                        "swatch_font_size": 16,
                        "swatch_color": "rgb(61, 66, 70)",
                        "swatch_font_family": "inherit",
                        "show_border_selected_swatch": true,
                        "selected_swatch_border_width": 1,
                        "selected_swatch_border_color": "rgb(0, 0, 0)",
                        "select_width": "100",
                        "select_width_type": "%",
                        "select_height": "auto",
                        "select_margin_bottom": "15",
                        "swatch_width": "auto",
                        "swatch_width_type": "",
                        "swatch_height": "44",
                        "alignment": "left"
                      },
                      "laptop": {
                        "display": "block",
                        "margin_top": 0,
                        "margin_left": 0,
                        "margin_bottom": 0,
                        "margin_right": 0,
                        "padding_top": 0,
                        "padding_left": 20,
                        "padding_bottom": 0,
                        "padding_right": 20,
                        "label_margin_bottom": 5,
                        "label_font_weight": "400",
                        "label_font_size": 16,
                        "label_line_height": 1.2,
                        "label_color": "rgb(61, 66, 70)",
                        "label_font_family": "inherit",
                        "show_swatch_text": true,
                        "swatch_font_weight": "400",
                        "swatch_font_size": 16,
                        "swatch_color": "rgb(61, 66, 70)",
                        "swatch_font_family": "inherit",
                        "show_border_selected_swatch": true,
                        "selected_swatch_border_width": 1,
                        "selected_swatch_border_color": "rgb(0, 0, 0)",
                        "select_width": "100",
                        "select_width_type": "%",
                        "select_height": "auto",
                        "select_margin_bottom": "15",
                        "swatch_width": "auto",
                        "swatch_width_type": "",
                        "swatch_height": "44",
                        "alignment": "left"
                      },
                      "tablet": {
                        "display": "block",
                        "margin_top": 0,
                        "margin_left": 0,
                        "margin_bottom": 0,
                        "margin_right": 0,
                        "padding_top": 0,
                        "padding_left": 20,
                        "padding_bottom": 0,
                        "padding_right": 20,
                        "label_margin_bottom": 5,
                        "label_font_weight": "400",
                        "label_font_size": 16,
                        "label_line_height": 1.2,
                        "label_color": "rgb(61, 66, 70)",
                        "label_font_family": "inherit",
                        "show_swatch_text": true,
                        "swatch_font_weight": "400",
                        "swatch_font_size": 16,
                        "swatch_color": "rgb(61, 66, 70)",
                        "swatch_font_family": "inherit",
                        "show_border_selected_swatch": true,
                        "selected_swatch_border_width": 1,
                        "selected_swatch_border_color": "rgb(0, 0, 0)",
                        "select_width": "100",
                        "select_width_type": "%",
                        "select_height": "auto",
                        "select_margin_bottom": "15",
                        "swatch_width": "auto",
                        "swatch_width_type": "",
                        "swatch_height": "44",
                        "alignment": "left"
                      },
                      "mobile": {
                        "display": "block",
                        "margin_top": 0,
                        "margin_left": 0,
                        "margin_bottom": 0,
                        "margin_right": 0,
                        "padding_top": 0,
                        "padding_left": 20,
                        "padding_bottom": 0,
                        "padding_right": 20,
                        "label_margin_bottom": 5,
                        "label_font_weight": "400",
                        "label_font_size": 16,
                        "label_line_height": 1.2,
                        "label_color": "rgb(61, 66, 70)",
                        "label_font_family": "inherit",
                        "show_swatch_text": true,
                        "swatch_font_weight": "400",
                        "swatch_font_size": 16,
                        "swatch_color": "rgb(61, 66, 70)",
                        "swatch_font_family": "inherit",
                        "show_border_selected_swatch": true,
                        "selected_swatch_border_width": 1,
                        "selected_swatch_border_color": "rgb(0, 0, 0)",
                        "select_width": "100",
                        "select_width_type": "%",
                        "select_height": "auto",
                        "select_margin_bottom": "15",
                        "swatch_width": "auto",
                        "swatch_width_type": "",
                        "swatch_height": "44",
                        "alignment": "left"
                      },
                      "component": "(P) Variants"
                    },
                    "children": [

                    ],
                    "addition": {

                    },
                    "uuid": "reset"
                  },
                  {
                    "componentName": "ProductQuantity",
                    "props": {
                      "update_price": false,
                      "extra_class": "",
                      "show_label": true,
                      "label": "Quantity",
                      "parent": "Product",
                      "default_quantity": "1",
                      "display_style": "default",
                      "label_width": 100,
                      "desktop": {
                        "display": "block",
                        "margin_top": 0,
                        "margin_left": 0,
                        "margin_bottom": "010",
                        "margin_right": 0,
                        "padding_top": 0,
                        "padding_left": 0,
                        "padding_bottom": 0,
                        "padding_right": 0,
                        "label_margin_bottom": 5,
                        "label_font_weight": "400",
                        "label_font_size": 16,
                        "label_line_height": 1.2,
                        "label_color": "rgb(61, 66, 70)",
                        "label_font_family": "inherit",
                        "stretch": false,
                        "width": 60,
                        "width_type": "px",
                        "height": 40,
                        "button_width": 40,
                        "quantity_font_weight": "400",
                        "quantity_font_size": 16,
                        "quantity_color": "rgb(61, 66, 70)",
                        "quantity_font_family": "inherit",
                        "alignment": "center"
                      },
                      "laptop": {
                        "display": "block",
                        "margin_top": 0,
                        "margin_left": 0,
                        "margin_bottom": 0,
                        "margin_right": 0,
                        "padding_top": 0,
                        "padding_left": 0,
                        "padding_bottom": 0,
                        "padding_right": 0,
                        "label_margin_bottom": 5,
                        "label_font_weight": "400",
                        "label_font_size": 16,
                        "label_line_height": 1.2,
                        "label_color": "rgb(61, 66, 70)",
                        "label_font_family": "inherit",
                        "stretch": false,
                        "width": 60,
                        "width_type": "px",
                        "height": 40,
                        "button_width": 40,
                        "quantity_font_weight": "400",
                        "quantity_font_size": 16,
                        "quantity_color": "rgb(61, 66, 70)",
                        "quantity_font_family": "inherit",
                        "alignment": "center"
                      },
                      "tablet": {
                        "display": "block",
                        "margin_top": 0,
                        "margin_left": 0,
                        "margin_bottom": 0,
                        "margin_right": 0,
                        "padding_top": 0,
                        "padding_left": 0,
                        "padding_bottom": 0,
                        "padding_right": 0,
                        "label_margin_bottom": 5,
                        "label_font_weight": "400",
                        "label_font_size": 16,
                        "label_line_height": 1.2,
                        "label_color": "rgb(61, 66, 70)",
                        "label_font_family": "inherit",
                        "stretch": false,
                        "width": 60,
                        "width_type": "px",
                        "height": 40,
                        "button_width": 40,
                        "quantity_font_weight": "400",
                        "quantity_font_size": 16,
                        "quantity_color": "rgb(61, 66, 70)",
                        "quantity_font_family": "inherit",
                        "alignment": "center"
                      },
                      "mobile": {
                        "display": "block",
                        "margin_top": 0,
                        "margin_left": 0,
                        "margin_bottom": 0,
                        "margin_right": 0,
                        "padding_top": 0,
                        "padding_left": 0,
                        "padding_bottom": 0,
                        "padding_right": 0,
                        "label_margin_bottom": 5,
                        "label_font_weight": "400",
                        "label_font_size": 16,
                        "label_line_height": 1.2,
                        "label_color": "rgb(61, 66, 70)",
                        "label_font_family": "inherit",
                        "stretch": false,
                        "width": 60,
                        "width_type": "px",
                        "height": 40,
                        "button_width": 40,
                        "quantity_font_weight": "400",
                        "quantity_font_size": 16,
                        "quantity_color": "rgb(61, 66, 70)",
                        "quantity_font_family": "inherit",
                        "alignment": "center"
                      },
                      "elementStyle": {

                      },
                      "component": "(P) Quantity"
                    },
                    "children": [

                    ],
                    "addition": {

                    },
                    "uuid": "reset"
                  },
                  {
                    "componentName": "ProductCartButton",
                    "props": {
                      "text": "Sold out",
                      "parent": "Product",
                      "extra_class": "",
                      "typographyActives": [
                        "font-size",
                        "font-weight",
                        "text-transform",
                        "font-family"
                      ],
                      "dimensionActives": [
                        "stretch",
                        "width",
                        "height"
                      ],
                      "desktop": {
                        "display": "block",
                        "margin_top": 0,
                        "margin_left": 0,
                        "margin_bottom": 0,
                        "margin_right": 0,
                        "padding_top": 10,
                        "padding_left": 20,
                        "padding_bottom": 10,
                        "padding_right": 20,
                        "font_weight": "600",
                        "font_size": 14,
                        "font_family": "inherit",
                        "text_transform": "uppercase",
                        "background_color": "rgb(0, 0, 0)",
                        "color": "rgb(255, 255, 255)",
                        "stretch": false,
                        "width": "auto",
                        "height": "auto",
                        "alignment": "center",
                        "text_align": "center"
                      },
                      "laptop": {
                        "display": "block",
                        "margin_top": 0,
                        "margin_left": 0,
                        "margin_bottom": 0,
                        "margin_right": 0,
                        "padding_top": 10,
                        "padding_left": 20,
                        "padding_bottom": 10,
                        "padding_right": 20,
                        "font_weight": "600",
                        "font_size": 14,
                        "font_family": "inherit",
                        "text_transform": "none",
                        "background_color": "rgb(0, 0, 0)",
                        "color": "rgb(255, 255, 255)",
                        "stretch": false,
                        "width": "auto",
                        "height": "auto",
                        "alignment": "center",
                        "text_align": "center"
                      },
                      "tablet": {
                        "display": "block",
                        "margin_top": 0,
                        "margin_left": 0,
                        "margin_bottom": 0,
                        "margin_right": 0,
                        "padding_top": 10,
                        "padding_left": 20,
                        "padding_bottom": 10,
                        "padding_right": 20,
                        "font_weight": "600",
                        "font_size": 14,
                        "font_family": "inherit",
                        "text_transform": "none",
                        "background_color": "rgb(0, 0, 0)",
                        "color": "rgb(255, 255, 255)",
                        "stretch": false,
                        "width": "auto",
                        "height": "auto",
                        "alignment": "center",
                        "text_align": "center"
                      },
                      "mobile": {
                        "display": "block",
                        "margin_top": 0,
                        "margin_left": 0,
                        "margin_bottom": 0,
                        "margin_right": 0,
                        "padding_top": 10,
                        "padding_left": 20,
                        "padding_bottom": 10,
                        "padding_right": 20,
                        "font_weight": "600",
                        "font_size": 14,
                        "font_family": "inherit",
                        "text_transform": "none",
                        "background_color": "rgb(0, 0, 0)",
                        "color": "rgb(255, 255, 255)",
                        "stretch": false,
                        "width": "auto",
                        "width_type": "%",
                        "height": "auto",
                        "alignment": "center",
                        "text_align": "center"
                      },
                      "elementStyle": {

                      },
                      "component": "(P) Cart Button"
                    },
                    "children": [

                    ],
                    "addition": {

                    },
                    "uuid": "reset"
                  }
                ],
                "addition": {

                },
                "uuid": "reset"
              }
            ],
            "addition": {

            },
            "uuid": "reset"
          }
        ],
        "addition": {

        },
        "uuid": "reset"
      }
    ],
    "addition": {

    },
    "uuid": "reset"
  }
]`

export const collectionData = `[
  {
    "componentName": "Canvas",
    "props": {
      "elementStyle": {},
      "component": "WrapperContainer"
    },
    "children": [
      {
        "componentName": "Row",
        "props": {
          "full_width": true,
          "extra_class": "",
          "hiddenConfig": [
            "Dimension (Width)",
            "Columns Gap"
          ],
          "vertical_align": "top",
          "equal_height": false,
          "column_gap": "0",
          "desktop": {
            "display": "block",
            "margin_top": 0,
            "margin_left": 0,
            "margin_bottom": 0,
            "margin_right": 0,
            "padding_top": 30,
            "padding_left": 0,
            "padding_bottom": "0",
            "padding_right": 0,
            "layout": "12",
            "width": "1200",
            "width_type": "px"
          },
          "laptop": {
            "display": "block",
            "margin_top": 0,
            "margin_left": 0,
            "margin_bottom": 0,
            "margin_right": 0,
            "padding_top": 30,
            "padding_left": 0,
            "padding_bottom": 10,
            "padding_right": 0,
            "layout": "12",
            "width": "1200",
            "width_type": "px"
          },
          "tablet": {
            "display": "block",
            "margin_top": 0,
            "margin_left": 0,
            "margin_bottom": 0,
            "margin_right": 0,
            "padding_top": 30,
            "padding_left": 0,
            "padding_bottom": 10,
            "padding_right": 0,
            "layout": "12",
            "width": "1200",
            "width_type": "px"
          },
          "mobile": {
            "display": "block",
            "margin_top": 0,
            "margin_left": 0,
            "margin_bottom": 0,
            "margin_right": 0,
            "padding_top": 30,
            "padding_left": 0,
            "padding_bottom": 10,
            "padding_right": 0,
            "layout": "12",
            "width": "1200",
            "width_type": "px"
          },
          "elementStyle": {}
        },
        "children": [
          {
            "componentName": "Canvas",
            "props": {
              "dataLayoutXl": "12",
              "dataLayoutLg": "12",
              "dataLayoutMd": "12",
              "dataLayoutSm": "12",
              "elementStyle": {},
              "component": "Column"
            },
            "children": [
              {
                "componentName": "CollectionBanner",
                "props": {
                  "overlay_color": "rgb(255, 255, 255)",
                  "overlay_opacity": 0,
                  "quality": "lighter",
                  "imgUrl": "https://cdn.shopify.com/s/files/1/0657/0813/files/slide-girl_2048x.jpg",
                  "hover_effect": "none",
                  "hover_effect_time": 1.6,
                  "image_size": "1024x1024",
                  "desktop": {
                    "display": "block",
                    "margin_top": 0,
                    "margin_left": 0,
                    "margin_bottom": 0,
                    "margin_right": 0,
                    "padding_top": 200,
                    "padding_left": 0,
                    "padding_bottom": 200,
                    "padding_right": 0
                  },
                  "laptop": {
                    "display": "block",
                    "margin_top": 0,
                    "margin_left": 0,
                    "margin_bottom": 0,
                    "margin_right": 0,
                    "padding_top": 200,
                    "padding_left": 0,
                    "padding_bottom": 200,
                    "padding_right": 0
                  },
                  "tablet": {
                    "display": "block",
                    "margin_top": 0,
                    "margin_left": 0,
                    "margin_bottom": 0,
                    "margin_right": 0,
                    "padding_top": 200,
                    "padding_left": 0,
                    "padding_bottom": 200,
                    "padding_right": 0
                  },
                  "mobile": {
                    "display": "block",
                    "margin_top": 0,
                    "margin_left": 0,
                    "margin_bottom": 0,
                    "margin_right": 0,
                    "padding_top": 200,
                    "padding_left": 0,
                    "padding_bottom": 200,
                    "padding_right": 0
                  },
                  "elementStyle": {},
                  "component": "(C) Banner"
                },
                "children": [
                  {
                    "componentName": "CollectionTitle",
                    "props": {
                      "notice": "This element will not show correct information until a collection is picked in a Product List.",
                      "component": "Collection Title",
                      "title": "",
                      "typographyActives": [
                        "font-weight",
                        "font-size",
                        "line-height",
                        "color",
                        "font-family",
                        "text-transform",
                        "font_style"
                      ],
                      "desktop": {
                        "display": "block",
                        "margin_top": 0,
                        "margin_left": 0,
                        "margin_bottom": 0,
                        "margin_right": 0,
                        "padding_top": 10,
                        "padding_left": 0,
                        "padding_bottom": 10,
                        "padding_right": 0,
                        "font_weight": "600",
                        "font_size": 105,
                        "line_height": 1.2,
                        "letter_spacing": 0,
                        "color": "rgba(255, 255, 255, 1)",
                        "font_family": "inherit",
                        "text_transform": "none",
                        "alignment": "center"
                      },
                      "laptop": {
                        "display": "block",
                        "margin_top": 0,
                        "margin_left": 0,
                        "margin_bottom": 0,
                        "margin_right": 0,
                        "padding_top": 10,
                        "padding_left": 0,
                        "padding_bottom": 10,
                        "padding_right": 0,
                        "font_weight": "600",
                        "font_size": 105,
                        "line_height": 1.2,
                        "letter_spacing": 0,
                        "color": "rgba(255, 255, 255, 1)",
                        "font_family": "inherit",
                        "text_transform": "none",
                        "alignment": "center"
                      },
                      "tablet": {
                        "display": "block",
                        "margin_top": 0,
                        "margin_left": 0,
                        "margin_bottom": 0,
                        "margin_right": 0,
                        "padding_top": 10,
                        "padding_left": 0,
                        "padding_bottom": 10,
                        "padding_right": 0,
                        "font_weight": "600",
                        "font_size": 105,
                        "line_height": 1.2,
                        "letter_spacing": 0,
                        "color": "rgba(255, 255, 255, 1)",
                        "font_family": "inherit",
                        "text_transform": "none",
                        "alignment": "center"
                      },
                      "mobile": {
                        "display": "block",
                        "margin_top": 0,
                        "margin_left": 0,
                        "margin_bottom": 0,
                        "margin_right": 0,
                        "padding_top": 10,
                        "padding_left": 0,
                        "padding_bottom": 10,
                        "padding_right": 0,
                        "font_weight": "600",
                        "font_size": 96,
                        "line_height": 1.2,
                        "letter_spacing": 0,
                        "color": "rgba(255, 255, 255, 1)",
                        "font_family": "inherit",
                        "text_transform": "none",
                        "alignment": "center"
                      },
                      "elementStyle": {}
                    },
                    "children": [],
                    "addition": {},
                    "uuid": "reset"
                  }
                ],
                "addition": {},
                "uuid": "reset"
              },
              {
                "componentName": "CollectionToolbar",
                "props": {
                  "notice": "This element will not show correct information until a collection is picked in a Product List.",
                  "component": "Collection Toolbar",
                  "filter_text": "Filter",
                  "sorting_text": "Sorting",
                  "show_quantity": true,
                  "quantity_text": "%number% products",
                  "desktop": {
                    "display": "block",
                    "margin_top": 0,
                    "margin_left": 0,
                    "margin_bottom": 0,
                    "margin_right": 0,
                    "padding_top": 0,
                    "padding_left": 0,
                    "padding_bottom": 0,
                    "padding_right": 0,
                    "typeWidth": "%",
                    "width": 100,
                    "height": 50,
                    "quantity_color": "rgb(61,66,70)",
                    "quantity_font_size": 16,
                    "select_color": "rgb(61,66,70)",
                    "select_font_size": 16,
                    "alignment": "center"
                  },
                  "laptop": {
                    "display": "block",
                    "margin_top": 0,
                    "margin_left": 0,
                    "margin_bottom": 0,
                    "margin_right": 0,
                    "padding_top": 0,
                    "padding_left": 0,
                    "padding_bottom": 0,
                    "padding_right": 0,
                    "typeWidth": "%",
                    "width": 100,
                    "height": 50,
                    "quantity_color": "rgb(61,66,70)",
                    "quantity_font_size": 16,
                    "select_color": "rgb(61,66,70)",
                    "select_font_size": 16,
                    "alignment": "center"
                  },
                  "tablet": {
                    "display": "block",
                    "margin_top": 0,
                    "margin_left": 0,
                    "margin_bottom": 0,
                    "margin_right": 0,
                    "padding_top": 0,
                    "padding_left": 0,
                    "padding_bottom": 0,
                    "padding_right": 0,
                    "typeWidth": "%",
                    "width": 100,
                    "height": 50,
                    "quantity_color": "rgb(61,66,70)",
                    "quantity_font_size": 16,
                    "select_color": "rgb(61,66,70)",
                    "select_font_size": 16,
                    "alignment": "center"
                  },
                  "mobile": {
                    "display": "block",
                    "margin_top": 0,
                    "margin_left": 0,
                    "margin_bottom": 0,
                    "margin_right": 0,
                    "padding_top": 0,
                    "padding_left": 0,
                    "padding_bottom": 0,
                    "padding_right": 0,
                    "typeWidth": "%",
                    "width": 100,
                    "height": 50,
                    "quantity_color": "rgb(61,66,70)",
                    "quantity_font_size": 16,
                    "select_color": "rgb(61,66,70)",
                    "select_font_size": 16,
                    "alignment": "center"
                  },
                  "elementStyle": {}
                },
                "children": [],
                "addition": {},
                "uuid": "reset"
              },
              {
                "componentName": "ProductList",
                "props": {
                  "dynamic":true,
                  "section": "module",
                  "image_same_height": false,
                  "title_same_height": false,
                  "image_height": 200,
                  "title_height": 72,
                  "spacing": 20,
                  "extra_class": "",
                  "number_of_products": 8,
                  "desktop": {
                    "display": "block",
                    "margin_top": 0,
                    "margin_left": 0,
                    "margin_bottom": 0,
                    "margin_right": 0,
                    "padding_top": 0,
                    "padding_left": 0,
                    "padding_bottom": 30,
                    "padding_right": 0,
                    "products_per_row": "4"
                  },
                  "laptop": {
                    "display": "block",
                    "margin_top": 0,
                    "margin_left": 0,
                    "margin_bottom": 0,
                    "margin_right": 0,
                    "padding_top": 0,
                    "padding_left": 0,
                    "padding_bottom": 30,
                    "padding_right": 0,
                    "products_per_row": "3"
                  },
                  "mobile": {
                    "display": "block",
                    "margin_top": 0,
                    "margin_left": 0,
                    "margin_bottom": 0,
                    "margin_right": 0,
                    "padding_top": 0,
                    "padding_left": 0,
                    "padding_bottom": 30,
                    "padding_right": 0,
                    "products_per_row": "2"
                  },
                  "tablet": {
                    "display": "block",
                    "margin_top": 0,
                    "margin_left": 0,
                    "margin_bottom": 0,
                    "margin_right": 0,
                    "padding_top": 0,
                    "padding_left": 0,
                    "padding_bottom": 30,
                    "padding_right": 0,
                    "products_per_row": "3"
                  },
                  "elementStyle": {},
                  "component": "Product List"
                },
                "children": [
                ],
                "addition": {},
                "uuid": "reset"
              },
              {
                "componentName": "Paginator",
                "props": {
                  "page_quantity": 10,
                  "previous_text": "PREVIOUS",
                  "next_text": "NEXT",
                  "layout": "simple",
                  "button_shape": "square",
                  "button_size": 40,
                  "desktop": {
                    "display": "block",
                    "margin_top": 0,
                    "margin_left": 0,
                    "margin_bottom": 0,
                    "margin_right": 0,
                    "padding_top": 0,
                    "padding_left": 0,
                    "padding_bottom": 0,
                    "padding_right": 0,
                    "alignment": "center",
                    "color": "#000000",
                    "active_color": "#dd3333",
                    "font_size": 16,
                    "spacing": 10
                  },
                  "laptop": {
                    "display": "block",
                    "margin_top": 0,
                    "margin_left": 0,
                    "margin_bottom": 0,
                    "margin_right": 0,
                    "padding_top": 0,
                    "padding_left": 0,
                    "padding_bottom": 0,
                    "padding_right": 0,
                    "alignment": "center",
                    "color": "#000000",
                    "active_color": "#dd3333",
                    "font_size": 16,
                    "spacing": 10
                  },
                  "tablet": {
                    "display": "block",
                    "margin_top": 0,
                    "margin_left": 0,
                    "margin_bottom": 0,
                    "margin_right": 0,
                    "padding_top": 0,
                    "padding_left": 0,
                    "padding_bottom": 0,
                    "padding_right": 0,
                    "alignment": "center",
                    "color": "#000000",
                    "active_color": "#dd3333",
                    "font_size": 16,
                    "spacing": 10
                  },
                  "mobile": {
                    "display": "block",
                    "margin_top": 0,
                    "margin_left": 0,
                    "margin_bottom": 0,
                    "margin_right": 0,
                    "padding_top": 0,
                    "padding_left": 0,
                    "padding_bottom": 0,
                    "padding_right": 0,
                    "alignment": "center",
                    "color": "#000000",
                    "active_color": "#dd3333",
                    "font_size": 16,
                    "spacing": 10
                  },
                  "elementStyle": {},
                  "component": "Paginator"
                },
                "children": [],
                "addition": {},
                "uuid": "reset"
              }
            ],
            "addition": {},
            "uuid": "reset"
          }
        ],
        "addition": {},
        "uuid": "reset"
      }
    ],
    "addition": {},
    "uuid": "reset"
  }
]`

export const homeData = `[
  {
    "componentName":"Canvas",
    "props": {
      "elementStyle": {},
      "component": "WrapperContainer",
      "body_text_color": "rgb(0, 0, 0)",
      "body_background": "rgb(255, 255, 255)",
      "body_font_family": "inherit",
      "body_font_size": 16,
      "link_type": "normal",
      "link_text_color": "rgb(0, 96, 229)",
      "link_font_family": "inherit",
      "link_font_weight": "400",
      "link_font_size": 16,
      "link_text_decoration": "underline",
      "link_hover_text_color": "rgb(0, 96, 229)",
      "link_hover_font_family": "inherit",
      "link_hover_font_weight": "400",
      "link_hover_font_size": 16,
      "link_hover_text_decoration": "underline",
      "button_font_family": "inherit",
      "button_font_size": 16,
      "button_font_weight": "700",
      "button_text_transform": "uppercase",
      "button_padding_unit": "px",
      "button_padding_top": 12,
      "button_padding_right": 12,
      "button_padding_bottom": 12,
      "button_padding_left": 12,
      "button_type": "normal",
      "button_text_color": "rgb(255, 255, 255)",
      "button_background_color": "rgb(0, 0, 0)",
      "button_border_type": "none",
      "button_border_width_unit": "px",
      "button_border_top_width": 1,
      "button_border_right_width": 1,
      "button_border_bottom_width": 1,
      "button_border_left_width": 1,
      "button_border_color": "rgb(0, 0, 0)",
      "button_border_radius_unit": "px",
      "button_border_top_left_radius": 0,
      "button_border_top_right_radius": 0,
      "button_border_bottom_right_radius": 0,
      "button_border_bottom_left_radius": 0,
      "button_hover_text_color": "rgb(255, 255, 255)",
      "button_hover_background_color": "rgb(0, 0, 0)",
      "button_hover_border_type": "none",
      "button_hover_border_width_unit": "px",
      "button_hover_border_top_width": 1,
      "button_hover_border_right_width": 1,
      "button_hover_border_bottom_width": 1,
      "button_hover_border_left_width": 1,
      "button_hover_border_color": "rgb(0, 0, 0)",
      "button_hover_border_radius_unit": "px",
      "button_hover_border_top_left_radius": 0,
      "button_hover_border_top_right_radius": 0,
      "button_hover_border_bottom_right_radius": 0,
      "button_hover_border_bottom_left_radius": 0,
      "image_type": "normal",
      "image_border_type": "none",
      "image_border_width_unit": "px",
      "image_border_top_width": 0,
      "image_border_right_width": 0,
      "image_border_bottom_width": 0,
      "image_border_left_width": 0,
      "image_border_color": "rgb(0, 0, 0)",
      "image_border_radius_unit": "px",
      "image_border_top_left_radius": 0,
      "image_border_top_right_radius": 0,
      "image_border_bottom_right_radius": 0,
      "image_border_bottom_left_radius": 0,
      "image_opacity": 1,
      "image_hover_border_type": "none",
      "image_hover_border_width_unit": "px",
      "image_hover_border_top_width": 0,
      "image_hover_border_right_width": 0,
      "image_hover_border_bottom_width": 0,
      "image_hover_border_left_width": 0,
      "image_hover_border_color": "rgb(0, 0, 0)",
      "image_hover_border_radius_unit": "px",
      "image_hover_border_top_left_radius": 0,
      "image_hover_border_top_right_radius": 0,
      "image_hover_border_bottom_right_radius": 0,
      "image_hover_border_bottom_left_radius": 0,
      "image_hover_opacity": 1
    },
    "children": [],
    "addition": {},
    "uuid": "reset"
  }
]`

export const checkoutData = `[
  {
    "uuid": "b7fe3ae5-fa33-4c1d-9948-89b2c2e5111c",
    "props": {
      "component": "WrapperContainer",
      "link_type": "normal",
      "image_type": "normal",
      "button_type": "normal",
      "elementStyle": {},
      "image_opacity": 1,
      "body_font_size": 16,
      "link_font_size": 16,
      "body_background": "rgb(255, 255, 255)",
      "body_text_color": "rgb(0, 0, 0)",
      "link_text_color": "rgb(0, 96, 229)",
      "body_font_family": "inherit",
      "button_font_size": 16,
      "link_font_family": "inherit",
      "link_font_weight": "400",
      "button_text_color": "rgb(255, 255, 255)",
      "image_border_type": "none",
      "button_border_type": "none",
      "button_font_family": "inherit",
      "button_font_weight": "700",
      "button_padding_top": 12,
      "image_border_color": "rgb(0, 0, 0)",
      "button_border_color": "rgb(0, 0, 0)",
      "button_padding_left": 12,
      "button_padding_unit": "px",
      "image_hover_opacity": 1,
      "button_padding_right": 12,
      "link_hover_font_size": 16,
      "link_text_decoration": "underline",
      "button_padding_bottom": 12,
      "button_text_transform": "uppercase",
      "link_hover_text_color": "rgb(0, 96, 229)",
      "image_border_top_width": 0,
      "link_hover_font_family": "inherit",
      "link_hover_font_weight": "400",
      "button_background_color": "rgb(0, 0, 0)",
      "button_border_top_width": 1,
      "button_hover_text_color": "rgb(255, 255, 255)",
      "image_border_left_width": 0,
      "image_border_width_unit": "px",
      "image_hover_border_type": "none",
      "button_border_left_width": 1,
      "button_border_width_unit": "px",
      "button_hover_border_type": "none",
      "image_border_radius_unit": "px",
      "image_border_right_width": 0,
      "image_hover_border_color": "rgb(0, 0, 0)",
      "button_border_radius_unit": "px",
      "button_border_right_width": 1,
      "button_hover_border_color": "rgb(0, 0, 0)",
      "image_border_bottom_width": 0,
      "button_border_bottom_width": 1,
      "link_hover_text_decoration": "underline",
      "image_border_top_left_radius": 0,
      "image_hover_border_top_width": 0,
      "button_border_top_left_radius": 0,
      "button_hover_background_color": "rgb(0, 0, 0)",
      "button_hover_border_top_width": 1,
      "image_border_top_right_radius": 0,
      "image_hover_border_left_width": 0,
      "image_hover_border_width_unit": "px",
      "button_border_top_right_radius": 0,
      "button_hover_border_left_width": 1,
      "button_hover_border_width_unit": "px",
      "image_hover_border_radius_unit": "px",
      "image_hover_border_right_width": 0,
      "button_hover_border_radius_unit": "px",
      "button_hover_border_right_width": 1,
      "image_border_bottom_left_radius": 0,
      "image_hover_border_bottom_width": 0,
      "button_border_bottom_left_radius": 0,
      "button_hover_border_bottom_width": 1,
      "image_border_bottom_right_radius": 0,
      "button_border_bottom_right_radius": 0,
      "image_hover_border_top_left_radius": 0,
      "button_hover_border_top_left_radius": 0,
      "image_hover_border_top_right_radius": 0,
      "button_hover_border_top_right_radius": 0,
      "image_hover_border_bottom_left_radius": 0,
      "button_hover_border_bottom_left_radius": 0,
      "image_hover_border_bottom_right_radius": 0,
      "button_hover_border_bottom_right_radius": 0
    },
    "addition": {},
    "children": [
      {
        "uuid": "9a01ebb7-20e9-4b0d-9558-55bfce691599",
        "props": {
          "laptop": {
            "width": "1200",
            "layout": "7_5",
            "display": "block",
            "width_type": "px",
            "padding_top": 30,
            "padding_bottom": 10
          },
          "mobile": {
            "width": "1200",
            "layout": "12",
            "display": "block",
            "width_type": "px",
            "padding_top": 30,
            "padding_bottom": 10
          },
          "tablet": {
            "width": "1200",
            "layout": "12",
            "display": "block",
            "width_type": "px",
            "padding_top": 30,
            "padding_bottom": 10
          },
          "desktop": {
            "width": "1200",
            "layout": "7_5",
            "display": "block",
            "width_type": "px",
            "padding_top": 30,
            "padding_bottom": 10
          },
          "no_copy": true,
          "disabled": true,
          "component": "Row",
          "column_gap": "15",
          "full_width": false,
          "elementStyle": {},
          "equal_height": false,
          "hiddenConfig": [],
          "vertical_align": "top"
        },
        "addition": {},
        "children": [
          {
            "uuid": "c5e7aba2-bf21-4187-a951-41deb293833c",
            "props": {
              "component": "Column",
              "dataLayoutLg": "7",
              "dataLayoutMd": "7",
              "dataLayoutSm": "12",
              "dataLayoutXl": "12",
              "elementStyle": {}
            },
            "addition": {},
            "children": [
              {
                "uuid": "525a738a-62ca-4b59-9014-f1b6e6bd7f59",
                "props": {
                  "icon": "fa-map-marker",
                  "label": "Shipping Address",
                  "laptop": {
                    "display": "block"
                  },
                  "mobile": {
                    "display": "block"
                  },
                  "tablet": {
                    "display": "block"
                  },
                  "desktop": {
                    "color": "rgb(0, 0, 0)",
                    "display": "block",
                    "alignment": "left",
                    "font_size": 16,
                    "font_family": "inherit",
                    "font_weight": "500",
                    "padding_top": 30,
                    "padding_left": 16,
                    "padding_right": 16,
                    "padding_bottom": 10
                  },
                  "no_copy": true,
                  "spacing": 10,
                  "disabled": true,
                  "component": "Shipping Address",
                  "animations": [],
                  "custom_css": "",
                  "extra_class": "",
                  "elementStyle": {},
                  "typographyActives": [
                    "font-weight",
                    "font-size",
                    "font-family",
                    "color"
                  ]
                },
                "addition": {},
                "children": [
                  {
                    "uuid": "f4b99e0f-4285-4fe5-8d9a-3080972081f3",
                    "props": {
                      "laptop": {
                        "width": "1000",
                        "layout": "12",
                        "display": "block",
                        "margin_top": 0,
                        "width_type": "px",
                        "padding_top": 0,
                        "padding_left": 0,
                        "padding_right": 0,
                        "padding_bottom": 0
                      },
                      "mobile": {
                        "width": "1200",
                        "layout": "12",
                        "display": "block",
                        "margin_top": 0,
                        "width_type": "px",
                        "padding_top": 0,
                        "padding_left": 0,
                        "padding_right": 0,
                        "padding_bottom": 0
                      },
                      "tablet": {
                        "width": "1000",
                        "layout": "12",
                        "display": "block",
                        "margin_top": 0,
                        "width_type": "px",
                        "padding_top": 0,
                        "padding_left": 0,
                        "padding_right": 0,
                        "padding_bottom": 0
                      },
                      "desktop": {
                        "width": "1000",
                        "layout": "12",
                        "display": "block",
                        "margin_top": 0,
                        "width_type": "px",
                        "padding_top": 0,
                        "padding_left": 0,
                        "padding_right": 0,
                        "padding_bottom": 0
                      },
                      "no_copy": true,
                      "disabled": true,
                      "component": "Row",
                      "animations": [],
                      "column_gap": "6",
                      "custom_css": "",
                      "full_width": true,
                      "extra_class": "",
                      "elementStyle": {},
                      "equal_height": true,
                      "hiddenConfig": [
                        "margin_left",
                        "margin_right"
                      ],
                      "vertical_align": "center"
                    },
                    "addition": {},
                    "children": [
                      {
                        "uuid": "ad625430-0381-4673-95c6-f22839348cd3",
                        "props": {
                          "component": "Column",
                          "dataLayoutLg": "12",
                          "dataLayoutMd": "12",
                          "dataLayoutSm": "12",
                          "dataLayoutXl": "12",
                          "elementStyle": {}
                        },
                        "addition": {},
                        "children": [
                          {
                            "uuid": "46faa0c5-29bf-4555-858f-ee42719359d6",
                            "props": {
                              "name": "email",
                              "laptop": {
                                "display": "block"
                              },
                              "mobile": {
                                "display": "block"
                              },
                              "tablet": {
                                "display": "block"
                              },
                              "content": "",
                              "desktop": {
                                "color": "rgb(0, 0, 0)",
                                "width": "100",
                                "height": "auto",
                                "display": "block",
                                "alignment": "center",
                                "font_size": 16,
                                "width_type": "%",
                                "font_family": "inherit",
                                "font_weight": "400",
                                "padding_top": 10,
                                "border_color": "rgba(193, 193, 193, 1)",
                                "border_style": "solid",
                                "padding_left": 18,
                                "padding_right": 18,
                                "padding_bottom": 10,
                                "border_top_width": 1,
                                "border_left_width": 1,
                                "border_right_width": 1,
                                "border_bottom_width": 1,
                                "border_top_left_radius": 8,
                                "border_top_right_radius": 8,
                                "border_bottom_left_radius": 8,
                                "border_bottom_right_radius": 8
                              },
                              "no_copy": true,
                              "disabled": true,
                              "required": true,
                              "component": "Email Field",
                              "animations": [],
                              "custom_css": "",
                              "extra_class": "",
                              "placeholder": "Email",
                              "elementStyle": {},
                              "default_value": "abc@gmail.com",
                              "hide_on_client": false,
                              "read_only_name": false,
                              "dimensionActives": [
                                "width",
                                "height"
                              ],
                              "typographyActives": [
                                "font-size",
                                "font-weight",
                                "font-family",
                                "color"
                              ]
                            },
                            "addition": {},
                            "children": [],
                            "componentName": "EmailField"
                          }
                        ],
                        "componentName": "Canvas"
                      }
                    ],
                    "componentName": "Canvas"
                  },
                  {
                    "uuid": "a3ce9018-d994-42db-9d33-0dbb7ec66838",
                    "props": {
                      "laptop": {
                        "width": "1000",
                        "layout": "6_6",
                        "display": "block",
                        "margin_top": 0,
                        "width_type": "px",
                        "padding_top": 0,
                        "padding_left": 0,
                        "padding_right": 0,
                        "padding_bottom": 0
                      },
                      "mobile": {
                        "width": "1200",
                        "layout": "12",
                        "display": "block",
                        "margin_top": "",
                        "width_type": "px",
                        "padding_top": 0,
                        "padding_left": 0,
                        "margin_bottom": "",
                        "padding_right": 0,
                        "padding_bottom": 0
                      },
                      "tablet": {
                        "width": "1000",
                        "layout": "12",
                        "display": "block",
                        "margin_top": 0,
                        "width_type": "px",
                        "padding_top": 0,
                        "padding_left": 0,
                        "padding_right": 0,
                        "padding_bottom": 0
                      },
                      "desktop": {
                        "width": "1000",
                        "layout": "6_6",
                        "display": "block",
                        "margin_top": 0,
                        "width_type": "px",
                        "padding_top": 0,
                        "padding_left": 0,
                        "padding_right": 0,
                        "padding_bottom": 0
                      },
                      "no_copy": true,
                      "disabled": true,
                      "component": "Row",
                      "animations": [],
                      "column_gap": "6",
                      "custom_css": "",
                      "full_width": true,
                      "extra_class": "",
                      "elementStyle": {},
                      "equal_height": false,
                      "hiddenConfig": [
                        "margin_left",
                        "margin_right"
                      ],
                      "vertical_align": "center"
                    },
                    "addition": {},
                    "children": [
                      {
                        "uuid": "bcf0b0c1-e14f-43a1-8230-342c9bd38763",
                        "props": {
                          "component": "Column",
                          "dataLayoutLg": "6",
                          "dataLayoutMd": "6",
                          "dataLayoutSm": "12",
                          "dataLayoutXl": "12",
                          "elementStyle": {}
                        },
                        "addition": {},
                        "children": [
                          {
                            "uuid": "bf867695-41e7-484a-9bef-d412de33f111",
                            "props": {
                              "name": "first_name",
                              "laptop": {
                                "display": "block"
                              },
                              "mobile": {
                                "display": "block"
                              },
                              "tablet": {
                                "display": "block"
                              },
                              "content": "",
                              "desktop": {
                                "color": "rgb(0, 0, 0)",
                                "width": "100",
                                "height": "auto",
                                "display": "block",
                                "alignment": "center",
                                "font_size": 16,
                                "width_type": "%",
                                "font_family": "inherit",
                                "font_weight": "400",
                                "padding_top": 10,
                                "border_color": "rgba(193, 193, 193, 1)",
                                "border_style": "solid",
                                "padding_left": 18,
                                "padding_right": 18,
                                "padding_bottom": 10,
                                "border_top_width": 1,
                                "border_left_width": 1,
                                "border_right_width": 1,
                                "border_bottom_width": 1,
                                "border_top_left_radius": 8,
                                "border_top_right_radius": 8,
                                "border_bottom_left_radius": 8,
                                "border_bottom_right_radius": 8
                              },
                              "no_copy": true,
                              "disabled": true,
                              "required": true,
                              "component": "Text Field",
                              "animations": [],
                              "custom_css": "",
                              "extra_class": "",
                              "placeholder": "First Name",
                              "elementStyle": {},
                              "read_only_name": false,
                              "dimensionActives": [
                                "width",
                                "height"
                              ],
                              "typographyActives": [
                                "font-size",
                                "font-weight",
                                "font-family",
                                "color"
                              ]
                            },
                            "addition": {},
                            "children": [],
                            "componentName": "TextField"
                          }
                        ],
                        "componentName": "Canvas"
                      },
                      {
                        "uuid": "5f066a4b-2e86-4823-9c36-010c60975d6b",
                        "props": {
                          "component": "Column",
                          "dataLayoutLg": "6",
                          "dataLayoutMd": "6",
                          "dataLayoutSm": "12",
                          "dataLayoutXl": "12",
                          "elementStyle": {}
                        },
                        "addition": {},
                        "children": [
                          {
                            "uuid": "96e01c49-69bc-4019-8538-713499ad8b81",
                            "props": {
                              "name": "last_name",
                              "laptop": {
                                "display": "block"
                              },
                              "mobile": {
                                "display": "block",
                                "font_size": 16,
                                "margin_top": "16",
                                "margin_left": "",
                                "margin_right": "",
                                "margin_bottom": ""
                              },
                              "tablet": {
                                "display": "block",
                                "margin_top": "16"
                              },
                              "content": "",
                              "desktop": {
                                "color": "rgb(0, 0, 0)",
                                "width": "100",
                                "height": "auto",
                                "display": "block",
                                "alignment": "center",
                                "font_size": 16,
                                "width_type": "%",
                                "font_family": "inherit",
                                "font_weight": "400",
                                "padding_top": 10,
                                "border_color": "rgba(193, 193, 193, 1)",
                                "border_style": "solid",
                                "padding_left": 18,
                                "padding_right": 18,
                                "padding_bottom": 10,
                                "border_top_width": 1,
                                "border_left_width": 1,
                                "border_right_width": 1,
                                "border_bottom_width": 1,
                                "border_top_left_radius": 8,
                                "border_top_right_radius": 8,
                                "border_bottom_left_radius": 8,
                                "border_bottom_right_radius": 8
                              },
                              "no_copy": true,
                              "disabled": true,
                              "required": true,
                              "component": "Text Field",
                              "animations": [],
                              "custom_css": "",
                              "extra_class": "",
                              "placeholder": "Last Name",
                              "elementStyle": {},
                              "read_only_name": false,
                              "dimensionActives": [
                                "width",
                                "height"
                              ],
                              "typographyActives": [
                                "font-size",
                                "font-weight",
                                "font-family",
                                "color"
                              ]
                            },
                            "addition": {},
                            "children": [],
                            "componentName": "TextField"
                          }
                        ],
                        "componentName": "Canvas"
                      }
                    ],
                    "componentName": "Canvas"
                  },
                  {
                    "uuid": "8ada3931-9aa0-42fd-b2aa-2a502e538cf8",
                    "props": {
                      "laptop": {
                        "width": "1000",
                        "layout": "12",
                        "display": "block",
                        "margin_top": 0,
                        "width_type": "px",
                        "padding_top": 0,
                        "padding_left": 0,
                        "padding_right": 0,
                        "padding_bottom": 0
                      },
                      "mobile": {
                        "width": "1200",
                        "layout": "12",
                        "display": "block",
                        "margin_top": 0,
                        "width_type": "px",
                        "padding_top": 0,
                        "padding_left": 0,
                        "padding_right": 0,
                        "padding_bottom": 0
                      },
                      "tablet": {
                        "width": "1000",
                        "layout": "12",
                        "display": "block",
                        "margin_top": 0,
                        "width_type": "px",
                        "padding_top": 0,
                        "padding_left": 0,
                        "padding_right": 0,
                        "padding_bottom": 0
                      },
                      "desktop": {
                        "width": "1000",
                        "layout": "12",
                        "display": "block",
                        "margin_top": 0,
                        "width_type": "px",
                        "padding_top": 0,
                        "padding_left": 0,
                        "padding_right": 0,
                        "padding_bottom": 0
                      },
                      "no_copy": true,
                      "disabled": true,
                      "component": "Row",
                      "animations": [],
                      "column_gap": "6",
                      "custom_css": "",
                      "full_width": true,
                      "extra_class": "",
                      "elementStyle": {},
                      "equal_height": true,
                      "hiddenConfig": [
                        "margin_left",
                        "margin_right"
                      ],
                      "vertical_align": "center"
                    },
                    "addition": {},
                    "children": [
                      {
                        "uuid": "9de5e52f-7f92-4740-9197-a97e42bcac83",
                        "props": {
                          "component": "Column",
                          "dataLayoutLg": "12",
                          "dataLayoutMd": "12",
                          "dataLayoutSm": "12",
                          "dataLayoutXl": "12",
                          "elementStyle": {}
                        },
                        "addition": {},
                        "children": [
                          {
                            "uuid": "65534420-3ab1-49a1-8e8f-91597c739653",
                            "props": {
                              "name": "address1",
                              "laptop": {
                                "display": "block"
                              },
                              "mobile": {
                                "display": "block"
                              },
                              "tablet": {
                                "display": "block"
                              },
                              "content": "",
                              "desktop": {
                                "color": "rgb(0, 0, 0)",
                                "width": "100",
                                "height": "auto",
                                "display": "block",
                                "alignment": "center",
                                "font_size": 16,
                                "width_type": "%",
                                "font_family": "inherit",
                                "font_weight": "400",
                                "padding_top": 10,
                                "border_color": "rgba(193, 193, 193, 1)",
                                "border_style": "solid",
                                "padding_left": 18,
                                "padding_right": 18,
                                "padding_bottom": 10,
                                "border_top_width": 1,
                                "border_left_width": 1,
                                "border_right_width": 1,
                                "border_bottom_width": 1,
                                "border_top_left_radius": 8,
                                "border_top_right_radius": 8,
                                "border_bottom_left_radius": 8,
                                "border_bottom_right_radius": 8
                              },
                              "no_copy": true,
                              "disabled": true,
                              "required": true,
                              "component": "Text Field",
                              "animations": [],
                              "custom_css": "",
                              "extra_class": "",
                              "placeholder": "Address",
                              "elementStyle": {},
                              "read_only_name": false,
                              "dimensionActives": [
                                "width",
                                "height"
                              ],
                              "typographyActives": [
                                "font-size",
                                "font-weight",
                                "font-family",
                                "color"
                              ]
                            },
                            "addition": {},
                            "children": [],
                            "componentName": "TextField"
                          }
                        ],
                        "componentName": "Canvas"
                      }
                    ],
                    "componentName": "Canvas"
                  },
                  {
                    "uuid": "2b85cf8c-01e2-4f88-8989-0e5873e77da0",
                    "props": {
                      "laptop": {
                        "width": "1000",
                        "layout": "12",
                        "display": "block",
                        "margin_top": 0,
                        "width_type": "px",
                        "padding_top": 0,
                        "padding_left": 0,
                        "padding_right": 0,
                        "padding_bottom": 0
                      },
                      "mobile": {
                        "width": "1200",
                        "layout": "12",
                        "display": "block",
                        "margin_top": 0,
                        "width_type": "px",
                        "padding_top": 0,
                        "padding_left": 0,
                        "padding_right": 0,
                        "padding_bottom": 0
                      },
                      "tablet": {
                        "width": "1000",
                        "layout": "12",
                        "display": "block",
                        "margin_top": 0,
                        "width_type": "px",
                        "padding_top": 0,
                        "padding_left": 0,
                        "padding_right": 0,
                        "padding_bottom": 0
                      },
                      "desktop": {
                        "width": "1000",
                        "layout": "12",
                        "display": "block",
                        "margin_top": 0,
                        "width_type": "px",
                        "padding_top": 0,
                        "padding_left": 0,
                        "padding_right": 0,
                        "padding_bottom": 0
                      },
                      "no_copy": true,
                      "disabled": true,
                      "component": "Row",
                      "animations": [],
                      "column_gap": "6",
                      "custom_css": "",
                      "full_width": true,
                      "extra_class": "",
                      "elementStyle": {},
                      "equal_height": true,
                      "hiddenConfig": [
                        "margin_left",
                        "margin_right"
                      ],
                      "vertical_align": "center"
                    },
                    "addition": {},
                    "children": [
                      {
                        "uuid": "5b9d70ed-aa5e-4265-8491-a5dfb8100175",
                        "props": {
                          "component": "Column",
                          "dataLayoutLg": "12",
                          "dataLayoutMd": "12",
                          "dataLayoutSm": "12",
                          "dataLayoutXl": "12",
                          "elementStyle": {}
                        },
                        "addition": {},
                        "children": [
                          {
                            "uuid": "cb1c670c-f606-4ca9-ba77-c57269ec1e72",
                            "props": {
                              "name": "address2",
                              "laptop": {
                                "display": "block"
                              },
                              "mobile": {
                                "display": "block"
                              },
                              "tablet": {
                                "display": "block"
                              },
                              "content": "",
                              "desktop": {
                                "color": "rgb(0, 0, 0)",
                                "width": "100",
                                "height": "auto",
                                "display": "block",
                                "alignment": "center",
                                "font_size": 16,
                                "width_type": "%",
                                "font_family": "inherit",
                                "font_weight": "400",
                                "padding_top": 10,
                                "border_color": "rgba(193, 193, 193, 1)",
                                "border_style": "solid",
                                "padding_left": 18,
                                "padding_right": 18,
                                "padding_bottom": 10,
                                "border_top_width": 1,
                                "border_left_width": 1,
                                "border_right_width": 1,
                                "border_bottom_width": 1,
                                "border_top_left_radius": 8,
                                "border_top_right_radius": 8,
                                "border_bottom_left_radius": 8,
                                "border_bottom_right_radius": 8
                              },
                              "no_copy": true,
                              "disabled": true,
                              "required": false,
                              "component": "Text Field",
                              "animations": [],
                              "custom_css": "",
                              "extra_class": "",
                              "placeholder": "Apartment, suite, etc. (optional)",
                              "elementStyle": {},
                              "read_only_name": false,
                              "dimensionActives": [
                                "width",
                                "height"
                              ],
                              "typographyActives": [
                                "font-size",
                                "font-weight",
                                "font-family",
                                "color"
                              ]
                            },
                            "addition": {},
                            "children": [],
                            "componentName": "TextField"
                          }
                        ],
                        "componentName": "Canvas"
                      }
                    ],
                    "componentName": "Canvas"
                  },
                  {
                    "uuid": "c58346f1-1dab-4e85-b7af-eb7c1b3bc541",
                    "props": {
                      "laptop": {
                        "width": "1000",
                        "layout": "6_6",
                        "display": "block",
                        "margin_top": 0,
                        "width_type": "px",
                        "padding_top": 0,
                        "padding_left": 0,
                        "padding_right": 0,
                        "padding_bottom": 0
                      },
                      "mobile": {
                        "width": "1200",
                        "layout": "12",
                        "display": "block",
                        "margin_top": 0,
                        "width_type": "px",
                        "padding_top": 0,
                        "padding_left": 0,
                        "padding_right": 0,
                        "padding_bottom": 0
                      },
                      "tablet": {
                        "width": "1000",
                        "layout": "12",
                        "display": "block",
                        "margin_top": 0,
                        "width_type": "px",
                        "padding_top": 0,
                        "padding_left": 0,
                        "padding_right": 0,
                        "padding_bottom": 0
                      },
                      "desktop": {
                        "width": "1000",
                        "layout": "6_6",
                        "display": "block",
                        "margin_top": 0,
                        "width_type": "px",
                        "padding_top": 0,
                        "padding_left": 0,
                        "padding_right": 0,
                        "padding_bottom": 0
                      },
                      "no_copy": true,
                      "disabled": true,
                      "component": "Row",
                      "animations": [],
                      "column_gap": "6",
                      "custom_css": "",
                      "full_width": true,
                      "extra_class": "",
                      "elementStyle": {},
                      "equal_height": false,
                      "hiddenConfig": [
                        "margin_left",
                        "margin_right"
                      ],
                      "vertical_align": "center"
                    },
                    "addition": {},
                    "children": [
                      {
                        "uuid": "5764aa1e-2d88-450a-9d8b-57ec5ee8bdf3",
                        "props": {
                          "component": "Column",
                          "dataLayoutLg": "6",
                          "dataLayoutMd": "6",
                          "dataLayoutSm": "12",
                          "dataLayoutXl": "12",
                          "elementStyle": {}
                        },
                        "addition": {},
                        "children": [
                          {
                            "uuid": "e06e5c68-ee30-4632-8913-aa921328d8b1",
                            "props": {
                              "name": "country_code",
                              "laptop": {
                                "display": "block"
                              },
                              "mobile": {
                                "display": "block"
                              },
                              "tablet": {
                                "display": "block"
                              },
                              "content": "",
                              "desktop": {
                                "color": "rgb(0, 0, 0)",
                                "width": "100",
                                "height": "auto",
                                "display": "block",
                                "alignment": "center",
                                "font_size": 16,
                                "width_type": "%",
                                "font_family": "inherit",
                                "font_weight": "400",
                                "padding_top": 10,
                                "border_color": "rgba(193, 193, 193, 1)",
                                "border_style": "solid",
                                "padding_left": 18,
                                "padding_right": 18,
                                "padding_bottom": 10,
                                "border_top_width": 1,
                                "border_left_width": 1,
                                "border_right_width": 1,
                                "border_bottom_width": 1,
                                "border_top_left_radius": 8,
                                "border_top_right_radius": 8,
                                "border_bottom_left_radius": 8,
                                "border_bottom_right_radius": 8
                              },
                              "no_copy": true,
                              "disabled": true,
                              "required": true,
                              "component": "Select Field",
                              "animations": [],
                              "custom_css": "",
                              "extra_class": "",
                              "placeholder": "Country",
                              "elementStyle": {},
                              "read_only_name": false,
                              "dimensionActives": [
                                "width",
                                "height"
                              ],
                              "typographyActives": [
                                "font-size",
                                "font-weight",
                                "font-family",
                                "color"
                              ]
                            },
                            "addition": {},
                            "children": [],
                            "componentName": "SelectField"
                          }
                        ],
                        "componentName": "Canvas"
                      },
                      {
                        "uuid": "7c3c8490-6423-48cc-bc27-0e1951edd08d",
                        "props": {
                          "component": "Column",
                          "dataLayoutLg": "6",
                          "dataLayoutMd": "6",
                          "dataLayoutSm": "12",
                          "dataLayoutXl": "12",
                          "elementStyle": {}
                        },
                        "addition": {},
                        "children": [
                          {
                            "uuid": "8b09164b-f97b-4723-b54b-a5b1761b824b",
                            "props": {
                              "name": "province_code",
                              "laptop": {
                                "display": "block"
                              },
                              "mobile": {
                                "display": "block",
                                "margin_top": "16"
                              },
                              "tablet": {
                                "display": "block",
                                "margin_top": "16"
                              },
                              "content": "",
                              "desktop": {
                                "color": "rgb(0, 0, 0)",
                                "width": "100",
                                "height": "auto",
                                "display": "block",
                                "alignment": "center",
                                "font_size": 16,
                                "width_type": "%",
                                "font_family": "inherit",
                                "font_weight": "400",
                                "padding_top": 10,
                                "border_color": "rgba(193, 193, 193, 1)",
                                "border_style": "solid",
                                "padding_left": 18,
                                "padding_right": 18,
                                "padding_bottom": 10,
                                "border_top_width": 1,
                                "border_left_width": 1,
                                "border_right_width": 1,
                                "border_bottom_width": 1,
                                "border_top_left_radius": 8,
                                "border_top_right_radius": 8,
                                "border_bottom_left_radius": 8,
                                "border_bottom_right_radius": 8
                              },
                              "no_copy": true,
                              "disabled": true,
                              "required": true,
                              "component": "Select Field",
                              "animations": [],
                              "custom_css": "",
                              "extra_class": "",
                              "placeholder": "Region",
                              "elementStyle": {},
                              "read_only_name": false,
                              "dimensionActives": [
                                "width",
                                "height"
                              ],
                              "typographyActives": [
                                "font-size",
                                "font-weight",
                                "font-family",
                                "color"
                              ]
                            },
                            "addition": {},
                            "children": [],
                            "componentName": "SelectField"
                          }
                        ],
                        "componentName": "Canvas"
                      }
                    ],
                    "componentName": "Canvas"
                  },
                  {
                    "uuid": "a9622d83-7c74-4919-9ca4-ef05d472341a",
                    "props": {
                      "laptop": {
                        "width": "1000",
                        "layout": "6_6",
                        "display": "block",
                        "margin_top": 0,
                        "width_type": "px",
                        "padding_top": 0,
                        "padding_left": 0,
                        "padding_right": 0,
                        "padding_bottom": 0
                      },
                      "mobile": {
                        "width": "1200",
                        "layout": "12",
                        "display": "block",
                        "margin_top": 0,
                        "width_type": "px",
                        "padding_top": 0,
                        "padding_left": 0,
                        "padding_right": 0,
                        "padding_bottom": 0
                      },
                      "tablet": {
                        "width": "1000",
                        "layout": "12",
                        "display": "block",
                        "margin_top": 0,
                        "width_type": "px",
                        "padding_top": 0,
                        "padding_left": 0,
                        "padding_right": 0,
                        "padding_bottom": 0
                      },
                      "desktop": {
                        "width": "1000",
                        "layout": "6_6",
                        "display": "block",
                        "margin_top": 0,
                        "width_type": "px",
                        "padding_top": 0,
                        "padding_left": 0,
                        "padding_right": 0,
                        "padding_bottom": 0
                      },
                      "no_copy": true,
                      "disabled": true,
                      "component": "Row",
                      "animations": [],
                      "column_gap": "6",
                      "custom_css": "",
                      "full_width": true,
                      "extra_class": "",
                      "elementStyle": {},
                      "equal_height": false,
                      "hiddenConfig": [
                        "margin_left",
                        "margin_right"
                      ],
                      "vertical_align": "center"
                    },
                    "addition": {},
                    "children": [
                      {
                        "uuid": "31481f65-8aae-4c2a-a161-82313cc44453",
                        "props": {
                          "component": "Column",
                          "dataLayoutLg": "6",
                          "dataLayoutMd": "6",
                          "dataLayoutSm": "12",
                          "dataLayoutXl": "12",
                          "elementStyle": {}
                        },
                        "addition": {},
                        "children": [
                          {
                            "uuid": "b0f56726-43cc-408f-9cce-24feedb8a7c1",
                            "props": {
                              "name": "city",
                              "laptop": {
                                "display": "block"
                              },
                              "mobile": {
                                "display": "block"
                              },
                              "tablet": {
                                "display": "block"
                              },
                              "content": "",
                              "desktop": {
                                "color": "rgb(0, 0, 0)",
                                "width": "100",
                                "height": "auto",
                                "display": "block",
                                "alignment": "center",
                                "font_size": 16,
                                "width_type": "%",
                                "font_family": "inherit",
                                "font_weight": "400",
                                "padding_top": 10,
                                "border_color": "rgba(193, 193, 193, 1)",
                                "border_style": "solid",
                                "padding_left": 18,
                                "padding_right": 18,
                                "padding_bottom": 10,
                                "border_top_width": 1,
                                "border_left_width": 1,
                                "border_right_width": 1,
                                "border_bottom_width": 1,
                                "border_top_left_radius": 8,
                                "border_top_right_radius": 8,
                                "border_bottom_left_radius": 8,
                                "border_bottom_right_radius": 8
                              },
                              "no_copy": true,
                              "disabled": true,
                              "required": true,
                              "component": "Text Field",
                              "animations": [],
                              "custom_css": "",
                              "extra_class": "",
                              "placeholder": "City",
                              "elementStyle": {},
                              "read_only_name": false,
                              "dimensionActives": [
                                "width",
                                "height"
                              ],
                              "typographyActives": [
                                "font-size",
                                "font-weight",
                                "font-family",
                                "color"
                              ]
                            },
                            "addition": {},
                            "children": [],
                            "componentName": "TextField"
                          }
                        ],
                        "componentName": "Canvas"
                      },
                      {
                        "uuid": "b0971998-dd74-45d4-93d2-ace6c225aa16",
                        "props": {
                          "component": "Column",
                          "dataLayoutLg": "6",
                          "dataLayoutMd": "6",
                          "dataLayoutSm": "12",
                          "dataLayoutXl": "12",
                          "elementStyle": {}
                        },
                        "addition": {},
                        "children": [
                          {
                            "uuid": "31032bf3-6ed4-45d6-8d41-2e697b6694b4",
                            "props": {
                              "name": "zip",
                              "laptop": {
                                "display": "block"
                              },
                              "mobile": {
                                "display": "block",
                                "margin_top": "16"
                              },
                              "tablet": {
                                "display": "block",
                                "margin_top": "16"
                              },
                              "content": "",
                              "desktop": {
                                "color": "rgb(0, 0, 0)",
                                "width": "100",
                                "height": "auto",
                                "display": "block",
                                "alignment": "center",
                                "font_size": 16,
                                "width_type": "%",
                                "font_family": "inherit",
                                "font_weight": "400",
                                "padding_top": 10,
                                "border_color": "rgba(193, 193, 193, 1)",
                                "border_style": "solid",
                                "padding_left": 18,
                                "padding_right": 18,
                                "padding_bottom": 10,
                                "border_top_width": 1,
                                "border_left_width": 1,
                                "border_right_width": 1,
                                "border_bottom_width": 1,
                                "border_top_left_radius": 8,
                                "border_top_right_radius": 8,
                                "border_bottom_left_radius": 8,
                                "border_bottom_right_radius": 8
                              },
                              "no_copy": true,
                              "disabled": true,
                              "required": true,
                              "component": "Text Field",
                              "animations": [],
                              "custom_css": "",
                              "extra_class": "",
                              "placeholder": "Postal Code",
                              "elementStyle": {},
                              "read_only_name": false,
                              "dimensionActives": [
                                "width",
                                "height"
                              ],
                              "typographyActives": [
                                "font-size",
                                "font-weight",
                                "font-family",
                                "color"
                              ]
                            },
                            "addition": {},
                            "children": [],
                            "componentName": "TextField"
                          }
                        ],
                        "componentName": "Canvas"
                      }
                    ],
                    "componentName": "Canvas"
                  },
                  {
                    "uuid": "2b36816a-8bc6-460e-84f2-74134d1123c6",
                    "props": {
                      "laptop": {
                        "width": "1000",
                        "layout": "12",
                        "display": "block",
                        "margin_top": 0,
                        "width_type": "px",
                        "padding_top": 0,
                        "padding_left": 0,
                        "padding_right": 0,
                        "padding_bottom": 0
                      },
                      "mobile": {
                        "width": "1200",
                        "layout": "12",
                        "display": "block",
                        "margin_top": 0,
                        "width_type": "px",
                        "padding_top": 0,
                        "padding_left": 0,
                        "padding_right": 0,
                        "padding_bottom": 0
                      },
                      "tablet": {
                        "width": "1000",
                        "layout": "12",
                        "display": "block",
                        "margin_top": 0,
                        "width_type": "px",
                        "padding_top": 0,
                        "padding_left": 0,
                        "padding_right": 0,
                        "padding_bottom": 0
                      },
                      "desktop": {
                        "width": "1000",
                        "layout": "12",
                        "display": "block",
                        "margin_top": 0,
                        "width_type": "px",
                        "padding_top": 0,
                        "padding_left": 0,
                        "padding_right": 0,
                        "padding_bottom": 0
                      },
                      "no_copy": true,
                      "disabled": true,
                      "component": "Row",
                      "animations": [],
                      "column_gap": "6",
                      "custom_css": "",
                      "full_width": true,
                      "extra_class": "",
                      "elementStyle": {},
                      "equal_height": true,
                      "hiddenConfig": [
                        "margin_left",
                        "margin_right"
                      ],
                      "vertical_align": "center"
                    },
                    "addition": {},
                    "children": [
                      {
                        "uuid": "9cac652c-bbf1-45b3-ba43-69c8dcfcb972",
                        "props": {
                          "component": "Column",
                          "dataLayoutLg": "12",
                          "dataLayoutMd": "12",
                          "dataLayoutSm": "12",
                          "dataLayoutXl": "12",
                          "elementStyle": {}
                        },
                        "addition": {},
                        "children": [
                          {
                            "uuid": "fb66e5ac-8b1c-48ae-a2c4-54e777981481",
                            "props": {
                              "name": "phone",
                              "laptop": {
                                "display": "block"
                              },
                              "mobile": {
                                "display": "block"
                              },
                              "tablet": {
                                "display": "block"
                              },
                              "content": "",
                              "desktop": {
                                "color": "rgb(0, 0, 0)",
                                "width": "100",
                                "height": "auto",
                                "display": "block",
                                "alignment": "center",
                                "font_size": 16,
                                "width_type": "%",
                                "font_family": "inherit",
                                "font_weight": "400",
                                "padding_top": 10,
                                "border_color": "rgba(193, 193, 193, 1)",
                                "border_style": "solid",
                                "padding_left": 18,
                                "padding_right": 18,
                                "padding_bottom": 10,
                                "border_top_width": 1,
                                "border_left_width": 1,
                                "border_right_width": 1,
                                "border_bottom_width": 1,
                                "border_top_left_radius": 8,
                                "border_top_right_radius": 8,
                                "border_bottom_left_radius": 8,
                                "border_bottom_right_radius": 8
                              },
                              "no_copy": true,
                              "disabled": true,
                              "required": true,
                              "component": "Text Field",
                              "animations": [],
                              "custom_css": "",
                              "extra_class": "",
                              "placeholder": "Phone",
                              "elementStyle": {},
                              "read_only_name": false,
                              "dimensionActives": [
                                "width",
                                "height"
                              ],
                              "typographyActives": [
                                "font-size",
                                "font-weight",
                                "font-family",
                                "color"
                              ]
                            },
                            "addition": {},
                            "children": [],
                            "componentName": "TextField"
                          }
                        ],
                        "componentName": "Canvas"
                      }
                    ],
                    "componentName": "Canvas"
                  }
                ],
                "componentName": "ShippingAddress"
              },
              {
                "uuid": "e824d1f0-61f7-4f6a-9e20-8bfb35a38630",
                "props": {
                  "icon": "<svg width='22' height='25' viewBox='0 0 22 25'><g fill='none' fill-rule='evenodd'><path d='M21.3 17.57142857C21.3 20.37142857 16.4 25 16.4 25s-4.9-4.62857143-4.9-7.42857143C11.5 14.65714286 14.01125 13 16.4 13c2.38875 0 4.9 1.65714286 4.9 4.57142857z' fill='#1A1A1A'></path><path d='M17.88492524 16.25245215c.82009968.78104732.82009968 2.04738171 0 2.82842903-.82009969.78104732-2.14975079.78104732-2.96985048 0-.82009968-.78104732-.82009968-2.04738171 0-2.82842903.82009969-.78104731 2.14975079-.78104731 2.96985048 0' stroke='#FFF' stroke-width='1.06666668' stroke-linecap='square'></path><path fill='#1A1A1A' fill-rule='nonzero' d='M18.8 0v10.54932175h-2V2H2v19h8.40890765v2H0V0z'></path><path d='M6.36484375 10.03129687c-.40708828 0-.73828125-.31542187-.73828125-.703125H4.15c0 .91659375.61725234 1.69814063 1.4765625 1.98839063V12.84375H7.103125v-1.5271406c.85931016-.29025 1.4765625-1.0717969 1.4765625-1.98843753 0-1.16310937-.99357891-2.109375-2.21484375-2.109375-.40708828 0-.73828125-.31542187-.73828125-.703125 0-.38770312.33119297-.703125.73828125-.703125s.73828125.31542188.73828125.70307813h1.4765625c0-.91659375-.61730156-1.69814063-1.4765625-1.98834375V3H5.6265625v1.52728125C4.76730156 4.81753125 4.15 5.59907812 4.15 6.51567187c0 1.16310938.99357891 2.109375 2.21484375 2.109375.40708828 0 .73828125.31542188.73828125.703125 0 .38770313-.33119297.703125-.73828125.703125zM4.15 14.625h6.3v1.40625h-6.3zM4.15 17.4375h6.3v1.40625h-6.3z' fill='#000' fill-rule='nonzero'></path></g></svg>",
                  "label": "Billing Address",
                  "laptop": {
                    "display": "block",
                    "font_size": 16
                  },
                  "mobile": {
                    "display": "block"
                  },
                  "tablet": {
                    "display": "block"
                  },
                  "desktop": {
                    "color": "rgb(0, 0, 0)",
                    "display": "block",
                    "alignment": "left",
                    "font_size": 16,
                    "font_family": "inherit",
                    "font_weight": "500",
                    "padding_top": 30,
                    "padding_left": 16,
                    "padding_right": 16,
                    "padding_bottom": 10
                  },
                  "no_copy": true,
                  "spacing": 10,
                  "disabled": true,
                  "component": "Billing Address",
                  "animations": [],
                  "custom_css": "",
                  "extra_class": "",
                  "elementStyle": {},
                  "billing_address": 1,
                  "typographyActives": [
                    "font-weight",
                    "font-size",
                    "font-family",
                    "color"
                  ]
                },
                "addition": {},
                "children": [
                  {
                    "uuid": "acf8b519-ae08-4b03-8b3e-af7671d9c67c",
                    "props": {
                      "laptop": {
                        "width": "1000",
                        "layout": "6_6",
                        "display": "block",
                        "margin_top": "16",
                        "width_type": "px",
                        "padding_top": 0,
                        "padding_left": 0,
                        "padding_right": 0,
                        "padding_bottom": 0
                      },
                      "mobile": {
                        "width": "1200",
                        "layout": "12",
                        "display": "block",
                        "margin_top": "16",
                        "width_type": "px",
                        "padding_top": 0,
                        "padding_left": 0,
                        "padding_right": 0,
                        "padding_bottom": 0
                      },
                      "tablet": {
                        "width": "1000",
                        "layout": "12",
                        "display": "block",
                        "margin_top": "16",
                        "width_type": "px",
                        "padding_top": 0,
                        "padding_left": 0,
                        "padding_right": 0,
                        "padding_bottom": 0
                      },
                      "desktop": {
                        "width": "1000",
                        "layout": "6_6",
                        "display": "block",
                        "margin_top": "16",
                        "width_type": "px",
                        "padding_top": 0,
                        "padding_left": 0,
                        "margin_bottom": "",
                        "padding_right": 0,
                        "padding_bottom": 0
                      },
                      "disabled": true,
                      "component": "Row",
                      "animations": [],
                      "column_gap": "6",
                      "custom_css": "",
                      "full_width": true,
                      "extra_class": "",
                      "elementStyle": {},
                      "equal_height": false,
                      "hiddenConfig": [
                        "margin_left",
                        "margin_right"
                      ],
                      "vertical_align": "center"
                    },
                    "addition": {},
                    "children": [
                      {
                        "uuid": "1ad86472-acb6-4947-bce9-5af1ed716bda",
                        "props": {
                          "component": "Column",
                          "dataLayoutLg": "6",
                          "dataLayoutMd": "6",
                          "dataLayoutSm": "12",
                          "dataLayoutXl": "12",
                          "elementStyle": {}
                        },
                        "addition": {},
                        "children": [
                          {
                            "uuid": "02c9b02e-e6cd-416d-91ff-bd3f0cbfb835",
                            "props": {
                              "name": "first_name",
                              "laptop": {
                                "display": "block"
                              },
                              "mobile": {
                                "display": "block"
                              },
                              "tablet": {
                                "display": "block"
                              },
                              "content": "",
                              "desktop": {
                                "color": "rgb(0, 0, 0)",
                                "width": "100",
                                "height": "auto",
                                "display": "block",
                                "alignment": "center",
                                "font_size": 16,
                                "width_type": "%",
                                "font_family": "inherit",
                                "font_weight": "400",
                                "padding_top": 10,
                                "border_color": "rgba(193, 193, 193, 1)",
                                "border_style": "solid",
                                "padding_left": 18,
                                "padding_right": 18,
                                "padding_bottom": 10,
                                "border_top_width": 1,
                                "border_left_width": 1,
                                "border_right_width": 1,
                                "border_bottom_width": 1,
                                "border_top_left_radius": 8,
                                "border_top_right_radius": 8,
                                "border_bottom_left_radius": 8,
                                "border_bottom_right_radius": 8
                              },
                              "disabled": true,
                              "required": true,
                              "component": "Text Field",
                              "animations": [],
                              "custom_css": "",
                              "extra_class": "",
                              "placeholder": "First Name",
                              "elementStyle": {},
                              "read_only_name": false,
                              "dimensionActives": [
                                "width",
                                "height"
                              ],
                              "typographyActives": [
                                "font-size",
                                "font-weight",
                                "font-family",
                                "color"
                              ]
                            },
                            "addition": {},
                            "children": [],
                            "componentName": "TextField"
                          }
                        ],
                        "componentName": "Canvas"
                      },
                      {
                        "uuid": "4afe9fe3-2c32-497a-a77b-a37f6451f02a",
                        "props": {
                          "component": "Column",
                          "dataLayoutLg": "6",
                          "dataLayoutMd": "6",
                          "dataLayoutSm": "12",
                          "dataLayoutXl": "12",
                          "elementStyle": {}
                        },
                        "addition": {},
                        "children": [
                          {
                            "uuid": "2d566564-21d9-46bf-bd72-30ba63656062",
                            "props": {
                              "name": "last_name",
                              "laptop": {
                                "display": "block"
                              },
                              "mobile": {
                                "display": "block",
                                "font_size": 16,
                                "margin_top": "16"
                              },
                              "tablet": {
                                "display": "block",
                                "font_size": 16,
                                "margin_top": "16"
                              },
                              "content": "",
                              "desktop": {
                                "color": "rgb(0, 0, 0)",
                                "width": "100",
                                "height": "auto",
                                "display": "block",
                                "alignment": "center",
                                "font_size": 16,
                                "width_type": "%",
                                "font_family": "inherit",
                                "font_weight": "400",
                                "padding_top": 10,
                                "border_color": "rgba(193, 193, 193, 1)",
                                "border_style": "solid",
                                "padding_left": 18,
                                "padding_right": 18,
                                "padding_bottom": 10,
                                "border_top_width": 1,
                                "border_left_width": 1,
                                "border_right_width": 1,
                                "border_bottom_width": 1,
                                "border_top_left_radius": 8,
                                "border_top_right_radius": 8,
                                "border_bottom_left_radius": 8,
                                "border_bottom_right_radius": 8
                              },
                              "disabled": true,
                              "required": true,
                              "component": "Text Field",
                              "animations": [],
                              "custom_css": "",
                              "extra_class": "",
                              "placeholder": "Last Name",
                              "elementStyle": {},
                              "read_only_name": false,
                              "dimensionActives": [
                                "width",
                                "height"
                              ],
                              "typographyActives": [
                                "font-size",
                                "font-weight",
                                "font-family",
                                "color"
                              ]
                            },
                            "addition": {},
                            "children": [],
                            "componentName": "TextField"
                          }
                        ],
                        "componentName": "Canvas"
                      }
                    ],
                    "componentName": "Canvas"
                  },
                  {
                    "uuid": "17356c15-1146-42bc-8962-f18c4c79d9cb",
                    "props": {
                      "laptop": {
                        "width": "1000",
                        "layout": "12",
                        "display": "block",
                        "margin_top": 0,
                        "width_type": "px",
                        "padding_top": 0,
                        "padding_left": 0,
                        "padding_right": 0,
                        "padding_bottom": 0
                      },
                      "mobile": {
                        "width": "1200",
                        "layout": "12",
                        "display": "block",
                        "margin_top": 0,
                        "width_type": "px",
                        "padding_top": 0,
                        "padding_left": 0,
                        "padding_right": 0,
                        "padding_bottom": 0
                      },
                      "tablet": {
                        "width": "1000",
                        "layout": "12",
                        "display": "block",
                        "margin_top": 0,
                        "width_type": "px",
                        "padding_top": 0,
                        "padding_left": 0,
                        "padding_right": 0,
                        "padding_bottom": 0
                      },
                      "desktop": {
                        "width": "1000",
                        "layout": "12",
                        "display": "block",
                        "margin_top": 0,
                        "width_type": "px",
                        "padding_top": 0,
                        "padding_left": 0,
                        "padding_right": 0,
                        "padding_bottom": 0
                      },
                      "disabled": true,
                      "component": "Row",
                      "animations": [],
                      "column_gap": "6",
                      "custom_css": "",
                      "full_width": true,
                      "extra_class": "",
                      "elementStyle": {},
                      "equal_height": true,
                      "hiddenConfig": [
                        "margin_left",
                        "margin_right"
                      ],
                      "vertical_align": "center"
                    },
                    "addition": {},
                    "children": [
                      {
                        "uuid": "d9572793-7791-42c0-b814-5e4603e76f0c",
                        "props": {
                          "component": "Column",
                          "dataLayoutLg": "12",
                          "dataLayoutMd": "12",
                          "dataLayoutSm": "12",
                          "dataLayoutXl": "12",
                          "elementStyle": {}
                        },
                        "addition": {},
                        "children": [
                          {
                            "uuid": "313f5b4b-ecc8-47f8-b7f5-2f0fb090b441",
                            "props": {
                              "name": "address1",
                              "laptop": {
                                "display": "block"
                              },
                              "mobile": {
                                "display": "block"
                              },
                              "tablet": {
                                "display": "block"
                              },
                              "content": "",
                              "desktop": {
                                "color": "rgb(0, 0, 0)",
                                "width": "100",
                                "height": "auto",
                                "display": "block",
                                "alignment": "center",
                                "font_size": 16,
                                "width_type": "%",
                                "font_family": "inherit",
                                "font_weight": "400",
                                "padding_top": 10,
                                "border_color": "rgba(193, 193, 193, 1)",
                                "border_style": "solid",
                                "padding_left": 18,
                                "padding_right": 18,
                                "padding_bottom": 10,
                                "border_top_width": 1,
                                "border_left_width": 1,
                                "border_right_width": 1,
                                "border_bottom_width": 1,
                                "border_top_left_radius": 8,
                                "border_top_right_radius": 8,
                                "border_bottom_left_radius": 8,
                                "border_bottom_right_radius": 8
                              },
                              "disabled": true,
                              "required": true,
                              "component": "Text Field",
                              "animations": [],
                              "custom_css": "",
                              "extra_class": "",
                              "placeholder": "Address",
                              "elementStyle": {},
                              "read_only_name": false,
                              "dimensionActives": [
                                "width",
                                "height"
                              ],
                              "typographyActives": [
                                "font-size",
                                "font-weight",
                                "font-family",
                                "color"
                              ]
                            },
                            "addition": {},
                            "children": [],
                            "componentName": "TextField"
                          }
                        ],
                        "componentName": "Canvas"
                      }
                    ],
                    "componentName": "Canvas"
                  },
                  {
                    "uuid": "aa488d04-6868-4a21-a239-70d0984feb0d",
                    "props": {
                      "laptop": {
                        "width": "1000",
                        "layout": "12",
                        "display": "block",
                        "margin_top": 0,
                        "width_type": "px",
                        "padding_top": 0,
                        "padding_left": 0,
                        "padding_right": 0,
                        "padding_bottom": 0
                      },
                      "mobile": {
                        "width": "1200",
                        "layout": "12",
                        "display": "block",
                        "margin_top": 0,
                        "width_type": "px",
                        "padding_top": 0,
                        "padding_left": 0,
                        "padding_right": 0,
                        "padding_bottom": 0
                      },
                      "tablet": {
                        "width": "1000",
                        "layout": "12",
                        "display": "block",
                        "margin_top": 0,
                        "width_type": "px",
                        "padding_top": 0,
                        "padding_left": 0,
                        "padding_right": 0,
                        "padding_bottom": 0
                      },
                      "desktop": {
                        "width": "1000",
                        "layout": "12",
                        "display": "block",
                        "margin_top": 0,
                        "width_type": "px",
                        "padding_top": 0,
                        "padding_left": 0,
                        "padding_right": 0,
                        "padding_bottom": 0
                      },
                      "disabled": true,
                      "component": "Row",
                      "animations": [],
                      "column_gap": "6",
                      "custom_css": "",
                      "full_width": true,
                      "extra_class": "",
                      "elementStyle": {},
                      "equal_height": true,
                      "hiddenConfig": [
                        "margin_left",
                        "margin_right"
                      ],
                      "vertical_align": "center"
                    },
                    "addition": {},
                    "children": [
                      {
                        "uuid": "2bc7d0f3-4d23-42f4-b011-a8a534a5df59",
                        "props": {
                          "component": "Column",
                          "dataLayoutLg": "12",
                          "dataLayoutMd": "12",
                          "dataLayoutSm": "12",
                          "dataLayoutXl": "12",
                          "elementStyle": {}
                        },
                        "addition": {},
                        "children": [
                          {
                            "uuid": "da1eb2ef-fe68-485b-8d76-4696f48278a4",
                            "props": {
                              "name": "address2",
                              "laptop": {
                                "display": "block"
                              },
                              "mobile": {
                                "display": "block"
                              },
                              "tablet": {
                                "display": "block"
                              },
                              "content": "",
                              "desktop": {
                                "color": "rgb(0, 0, 0)",
                                "width": "100",
                                "height": "auto",
                                "display": "block",
                                "alignment": "center",
                                "font_size": 16,
                                "width_type": "%",
                                "font_family": "inherit",
                                "font_weight": "400",
                                "padding_top": 10,
                                "border_color": "rgba(193, 193, 193, 1)",
                                "border_style": "solid",
                                "padding_left": 18,
                                "padding_right": 18,
                                "padding_bottom": 10,
                                "border_top_width": 1,
                                "border_left_width": 1,
                                "border_right_width": 1,
                                "border_bottom_width": 1,
                                "border_top_left_radius": 8,
                                "border_top_right_radius": 8,
                                "border_bottom_left_radius": 8,
                                "border_bottom_right_radius": 8
                              },
                              "disabled": true,
                              "required": false,
                              "component": "Text Field",
                              "animations": [],
                              "custom_css": "",
                              "extra_class": "",
                              "placeholder": "Apartment, suite, etc. (optional)",
                              "elementStyle": {},
                              "read_only_name": false,
                              "dimensionActives": [
                                "width",
                                "height"
                              ],
                              "typographyActives": [
                                "font-size",
                                "font-weight",
                                "font-family",
                                "color"
                              ]
                            },
                            "addition": {},
                            "children": [],
                            "componentName": "TextField"
                          }
                        ],
                        "componentName": "Canvas"
                      }
                    ],
                    "componentName": "Canvas"
                  },
                  {
                    "uuid": "811065da-02b9-4afc-9c2e-be9e741412cc",
                    "props": {
                      "laptop": {
                        "width": "1000",
                        "layout": "6_6",
                        "display": "block",
                        "margin_top": 0,
                        "width_type": "px",
                        "padding_top": 0,
                        "padding_left": 0,
                        "padding_right": 0,
                        "padding_bottom": 0
                      },
                      "mobile": {
                        "width": "1200",
                        "layout": "12",
                        "display": "block",
                        "margin_top": 0,
                        "width_type": "px",
                        "padding_top": 0,
                        "padding_left": 0,
                        "padding_right": 0,
                        "padding_bottom": 0
                      },
                      "tablet": {
                        "width": "1000",
                        "layout": "12",
                        "display": "block",
                        "margin_top": 0,
                        "width_type": "px",
                        "padding_top": 0,
                        "padding_left": 0,
                        "padding_right": 0,
                        "padding_bottom": 0
                      },
                      "desktop": {
                        "width": "1000",
                        "layout": "6_6",
                        "display": "block",
                        "margin_top": 0,
                        "width_type": "px",
                        "padding_top": 0,
                        "padding_left": 0,
                        "padding_right": 0,
                        "padding_bottom": 0
                      },
                      "disabled": true,
                      "component": "Row",
                      "animations": [],
                      "column_gap": "6",
                      "custom_css": "",
                      "full_width": true,
                      "extra_class": "",
                      "elementStyle": {},
                      "equal_height": false,
                      "hiddenConfig": [
                        "margin_left",
                        "margin_right"
                      ],
                      "vertical_align": "center"
                    },
                    "addition": {},
                    "children": [
                      {
                        "uuid": "db09ac23-f755-4ebb-bfe5-69eaf599dd51",
                        "props": {
                          "component": "Column",
                          "dataLayoutLg": "6",
                          "dataLayoutMd": "6",
                          "dataLayoutSm": "12",
                          "dataLayoutXl": "12",
                          "elementStyle": {}
                        },
                        "addition": {},
                        "children": [
                          {
                            "uuid": "86baca0e-54fe-4523-9b8a-9c9aea50845c",
                            "props": {
                              "name": "country_code",
                              "laptop": {
                                "display": "block"
                              },
                              "mobile": {
                                "display": "block"
                              },
                              "tablet": {
                                "display": "block"
                              },
                              "content": "",
                              "desktop": {
                                "color": "rgb(0, 0, 0)",
                                "width": "100",
                                "height": "auto",
                                "display": "block",
                                "alignment": "center",
                                "font_size": 16,
                                "width_type": "%",
                                "font_family": "inherit",
                                "font_weight": "400",
                                "padding_top": 10,
                                "border_color": "rgba(193, 193, 193, 1)",
                                "border_style": "solid",
                                "padding_left": 18,
                                "padding_right": 18,
                                "padding_bottom": 10,
                                "border_top_width": 1,
                                "border_left_width": 1,
                                "border_right_width": 1,
                                "border_bottom_width": 1,
                                "border_top_left_radius": 8,
                                "border_top_right_radius": 8,
                                "border_bottom_left_radius": 8,
                                "border_bottom_right_radius": 8
                              },
                              "disabled": true,
                              "required": true,
                              "component": "Select Field",
                              "animations": [],
                              "custom_css": "",
                              "extra_class": "",
                              "placeholder": "Country",
                              "elementStyle": {},
                              "read_only_name": false,
                              "dimensionActives": [
                                "width",
                                "height"
                              ],
                              "typographyActives": [
                                "font-size",
                                "font-weight",
                                "font-family",
                                "color"
                              ]
                            },
                            "addition": {},
                            "children": [],
                            "componentName": "SelectField"
                          }
                        ],
                        "componentName": "Canvas"
                      },
                      {
                        "uuid": "25566de2-1e13-4a3f-b327-1f7ba0118f9f",
                        "props": {
                          "component": "Column",
                          "dataLayoutLg": "6",
                          "dataLayoutMd": "6",
                          "dataLayoutSm": "12",
                          "dataLayoutXl": "12",
                          "elementStyle": {}
                        },
                        "addition": {},
                        "children": [
                          {
                            "uuid": "bfc22c09-ef29-4aaf-b05c-f8858754bb55",
                            "props": {
                              "name": "province_code",
                              "laptop": {
                                "display": "block"
                              },
                              "mobile": {
                                "display": "block",
                                "margin_top": "16"
                              },
                              "tablet": {
                                "display": "block",
                                "margin_top": "16"
                              },
                              "content": "",
                              "desktop": {
                                "color": "rgb(0, 0, 0)",
                                "width": "100",
                                "height": "auto",
                                "display": "block",
                                "alignment": "center",
                                "font_size": 16,
                                "width_type": "%",
                                "font_family": "inherit",
                                "font_weight": "400",
                                "padding_top": 10,
                                "border_color": "rgba(193, 193, 193, 1)",
                                "border_style": "solid",
                                "padding_left": 18,
                                "padding_right": 18,
                                "padding_bottom": 10,
                                "border_top_width": 1,
                                "border_left_width": 1,
                                "border_right_width": 1,
                                "border_bottom_width": 1,
                                "border_top_left_radius": 8,
                                "border_top_right_radius": 8,
                                "border_bottom_left_radius": 8,
                                "border_bottom_right_radius": 8
                              },
                              "disabled": true,
                              "required": true,
                              "component": "Select Field",
                              "animations": [],
                              "custom_css": "",
                              "extra_class": "",
                              "placeholder": "Region",
                              "elementStyle": {},
                              "read_only_name": false,
                              "dimensionActives": [
                                "width",
                                "height"
                              ],
                              "typographyActives": [
                                "font-size",
                                "font-weight",
                                "font-family",
                                "color"
                              ]
                            },
                            "addition": {},
                            "children": [],
                            "componentName": "SelectField"
                          }
                        ],
                        "componentName": "Canvas"
                      }
                    ],
                    "componentName": "Canvas"
                  },
                  {
                    "uuid": "7aeff004-2592-4be2-8b51-f51437d460e7",
                    "props": {
                      "laptop": {
                        "width": "1000",
                        "layout": "6_6",
                        "display": "block",
                        "margin_top": 0,
                        "width_type": "px",
                        "padding_top": 0,
                        "padding_left": 0,
                        "padding_right": 0,
                        "padding_bottom": 0
                      },
                      "mobile": {
                        "width": "1200",
                        "layout": "12",
                        "display": "block",
                        "margin_top": 0,
                        "width_type": "px",
                        "padding_top": 0,
                        "padding_left": 0,
                        "padding_right": 0,
                        "padding_bottom": 0
                      },
                      "tablet": {
                        "width": "1000",
                        "layout": "12",
                        "display": "block",
                        "margin_top": 0,
                        "width_type": "px",
                        "padding_top": 0,
                        "padding_left": 0,
                        "padding_right": 0,
                        "padding_bottom": 0
                      },
                      "desktop": {
                        "width": "1000",
                        "layout": "6_6",
                        "display": "block",
                        "margin_top": 0,
                        "width_type": "px",
                        "padding_top": 0,
                        "padding_left": 0,
                        "padding_right": 0,
                        "padding_bottom": 0
                      },
                      "disabled": true,
                      "component": "Row",
                      "animations": [],
                      "column_gap": "6",
                      "custom_css": "",
                      "full_width": true,
                      "extra_class": "",
                      "elementStyle": {},
                      "equal_height": false,
                      "hiddenConfig": [
                        "margin_left",
                        "margin_right"
                      ],
                      "vertical_align": "center"
                    },
                    "addition": {},
                    "children": [
                      {
                        "uuid": "f2d70a9b-5b14-4a77-83b5-a308c34b6874",
                        "props": {
                          "component": "Column",
                          "dataLayoutLg": "6",
                          "dataLayoutMd": "6",
                          "dataLayoutSm": "12",
                          "dataLayoutXl": "12",
                          "elementStyle": {}
                        },
                        "addition": {},
                        "children": [
                          {
                            "uuid": "4328a1b8-b7a5-407f-af3c-604416ccad5b",
                            "props": {
                              "name": "city",
                              "laptop": {
                                "display": "block"
                              },
                              "mobile": {
                                "display": "block"
                              },
                              "tablet": {
                                "display": "block"
                              },
                              "content": "",
                              "desktop": {
                                "color": "rgb(0, 0, 0)",
                                "width": "100",
                                "height": "auto",
                                "display": "block",
                                "alignment": "center",
                                "font_size": 16,
                                "width_type": "%",
                                "font_family": "inherit",
                                "font_weight": "400",
                                "padding_top": 10,
                                "border_color": "rgba(193, 193, 193, 1)",
                                "border_style": "solid",
                                "padding_left": 18,
                                "padding_right": 18,
                                "padding_bottom": 10,
                                "border_top_width": 1,
                                "border_left_width": 1,
                                "border_right_width": 1,
                                "border_bottom_width": 1,
                                "border_top_left_radius": 8,
                                "border_top_right_radius": 8,
                                "border_bottom_left_radius": 8,
                                "border_bottom_right_radius": 8
                              },
                              "disabled": true,
                              "required": true,
                              "component": "Text Field",
                              "animations": [],
                              "custom_css": "",
                              "extra_class": "",
                              "placeholder": "City",
                              "elementStyle": {},
                              "read_only_name": false,
                              "dimensionActives": [
                                "width",
                                "height"
                              ],
                              "typographyActives": [
                                "font-size",
                                "font-weight",
                                "font-family",
                                "color"
                              ]
                            },
                            "addition": {},
                            "children": [],
                            "componentName": "TextField"
                          }
                        ],
                        "componentName": "Canvas"
                      },
                      {
                        "uuid": "2280716c-a128-4c33-ae28-6619c743372e",
                        "props": {
                          "component": "Column",
                          "dataLayoutLg": "6",
                          "dataLayoutMd": "6",
                          "dataLayoutSm": "12",
                          "dataLayoutXl": "12",
                          "elementStyle": {}
                        },
                        "addition": {},
                        "children": [
                          {
                            "uuid": "98121f97-df35-48d2-b01b-8ec74c54db03",
                            "props": {
                              "name": "zip",
                              "laptop": {
                                "display": "block"
                              },
                              "mobile": {
                                "display": "block",
                                "margin_top": "16"
                              },
                              "tablet": {
                                "display": "block",
                                "margin_top": "16"
                              },
                              "content": "",
                              "desktop": {
                                "color": "rgb(0, 0, 0)",
                                "width": "100",
                                "height": "auto",
                                "display": "block",
                                "alignment": "center",
                                "font_size": 16,
                                "width_type": "%",
                                "font_family": "inherit",
                                "font_weight": "400",
                                "padding_top": 10,
                                "border_color": "rgba(193, 193, 193, 1)",
                                "border_style": "solid",
                                "padding_left": 18,
                                "padding_right": 18,
                                "padding_bottom": 10,
                                "border_top_width": 1,
                                "border_left_width": 1,
                                "border_right_width": 1,
                                "border_bottom_width": 1,
                                "border_top_left_radius": 8,
                                "border_top_right_radius": 8,
                                "border_bottom_left_radius": 8,
                                "border_bottom_right_radius": 8
                              },
                              "disabled": true,
                              "required": true,
                              "component": "Text Field",
                              "animations": [],
                              "custom_css": "",
                              "extra_class": "",
                              "placeholder": "Postal Code",
                              "elementStyle": {},
                              "read_only_name": false,
                              "dimensionActives": [
                                "width",
                                "height"
                              ],
                              "typographyActives": [
                                "font-size",
                                "font-weight",
                                "font-family",
                                "color"
                              ]
                            },
                            "addition": {},
                            "children": [],
                            "componentName": "TextField"
                          }
                        ],
                        "componentName": "Canvas"
                      }
                    ],
                    "componentName": "Canvas"
                  }
                ],
                "componentName": "BillingAddress"
              },
              {
                "uuid": "1629f7ca-7c51-4d1c-a1e4-3323e98ef8e8",
                "props": {
                  "icon": "<svg width='24' height='20' viewBox='0 0 24 20'><g stroke='#1A1A1A' stroke-width='2' fill='none' fill-rule='evenodd' stroke-linecap='square'><path d='M1 5h22M1 9h22M21 19H3c-1.105 0-2-.895-2-2V3c0-1.105.895-2 2-2h18c1.105 0 2 .895 2 2v14c0 1.105-.895 2-2 2zM5 14h5M18 14h1'></path></g></svg>",
                  "label": "Payment",
                  "laptop": {
                    "display": "block"
                  },
                  "mobile": {
                    "display": "block"
                  },
                  "tablet": {
                    "display": "block"
                  },
                  "desktop": {
                    "color": "rgb(0, 0, 0)",
                    "display": "block",
                    "alignment": "left",
                    "font_size": 16,
                    "font_family": "inherit",
                    "font_weight": "500",
                    "padding_top": 30,
                    "padding_left": 16,
                    "padding_right": 16,
                    "padding_bottom": 10
                  },
                  "no_copy": true,
                  "spacing": 10,
                  "disabled": true,
                  "component": "Payment",
                  "animations": [],
                  "custom_css": "",
                  "extra_class": "",
                  "elementStyle": {},
                  "typographyActives": [
                    "font-weight",
                    "font-size",
                    "font-family",
                    "color"
                  ]
                },
                "addition": {},
                "children": [
                  {
                    "uuid": "0ef84aa7-aac7-4da6-ac49-c8fd3bc6ca08",
                    "props": {
                      "laptop": {
                        "width": "1000",
                        "layout": "12",
                        "display": "block",
                        "margin_top": 0,
                        "width_type": "px",
                        "padding_top": 0,
                        "padding_left": 0,
                        "padding_right": 0,
                        "padding_bottom": 0
                      },
                      "mobile": {
                        "width": "1200",
                        "layout": "12",
                        "display": "block",
                        "margin_top": 0,
                        "width_type": "px",
                        "padding_top": 0,
                        "padding_left": 0,
                        "padding_right": 0,
                        "padding_bottom": 0
                      },
                      "tablet": {
                        "width": "1000",
                        "layout": "12",
                        "display": "block",
                        "margin_top": 0,
                        "width_type": "px",
                        "padding_top": 0,
                        "padding_left": 0,
                        "padding_right": 0,
                        "padding_bottom": 0
                      },
                      "desktop": {
                        "width": "1000",
                        "layout": "12",
                        "display": "block",
                        "margin_top": 0,
                        "width_type": "px",
                        "padding_top": 0,
                        "padding_left": 0,
                        "padding_right": 0,
                        "padding_bottom": 0
                      },
                      "disabled": true,
                      "component": "Row",
                      "animations": [],
                      "column_gap": "6",
                      "custom_css": "",
                      "full_width": true,
                      "extra_class": "",
                      "elementStyle": {},
                      "equal_height": true,
                      "hiddenConfig": [
                        "margin_left",
                        "margin_right"
                      ],
                      "vertical_align": "center"
                    },
                    "addition": {},
                    "children": [
                      {
                        "uuid": "e76544ff-9bfa-459d-830d-27f3102ed0f3",
                        "props": {
                          "component": "Column",
                          "dataLayoutLg": "12",
                          "dataLayoutMd": "12",
                          "dataLayoutSm": "12",
                          "dataLayoutXl": "12",
                          "elementStyle": {}
                        },
                        "addition": {},
                        "children": [
                          {
                            "uuid": "781c44c5-ef68-4f54-9e7c-97eae4a4ba52",
                            "props": {
                              "name": "card_number",
                              "laptop": {
                                "display": "block"
                              },
                              "mobile": {
                                "display": "block"
                              },
                              "tablet": {
                                "display": "block"
                              },
                              "content": "",
                              "desktop": {
                                "color": "rgb(0, 0, 0)",
                                "width": "100",
                                "height": "auto",
                                "display": "block",
                                "alignment": "center",
                                "font_size": 16,
                                "width_type": "%",
                                "font_family": "inherit",
                                "font_weight": "400",
                                "padding_top": 10,
                                "border_color": "rgba(193, 193, 193, 1)",
                                "border_style": "solid",
                                "padding_left": 18,
                                "padding_right": 18,
                                "padding_bottom": 10,
                                "border_top_width": 1,
                                "border_left_width": 1,
                                "border_right_width": 1,
                                "border_bottom_width": 1,
                                "border_top_left_radius": 8,
                                "border_top_right_radius": 8,
                                "border_bottom_left_radius": 8,
                                "border_bottom_right_radius": 8
                              },
                              "disabled": true,
                              "required": true,
                              "component": "Text Field",
                              "animations": [],
                              "custom_css": "",
                              "extra_class": "",
                              "placeholder": "Card number",
                              "elementStyle": {},
                              "read_only_name": false,
                              "dimensionActives": [
                                "width",
                                "height"
                              ],
                              "typographyActives": [
                                "font-size",
                                "font-weight",
                                "font-family",
                                "color"
                              ]
                            },
                            "addition": {},
                            "children": [],
                            "componentName": "TextField"
                          }
                        ],
                        "componentName": "Canvas"
                      }
                    ],
                    "componentName": "Canvas"
                  },
                  {
                    "uuid": "c6c20945-b0f7-4b77-b5d8-224a2b5c1e4f",
                    "props": {
                      "laptop": {
                        "width": "1000",
                        "layout": "6_6",
                        "display": "block",
                        "margin_top": 0,
                        "width_type": "px",
                        "padding_top": 0,
                        "padding_left": 0,
                        "padding_right": 0,
                        "padding_bottom": 0
                      },
                      "mobile": {
                        "width": "1200",
                        "layout": "12",
                        "display": "block",
                        "margin_top": 0,
                        "width_type": "px",
                        "padding_top": 0,
                        "padding_left": 0,
                        "padding_right": 0,
                        "padding_bottom": 0
                      },
                      "tablet": {
                        "width": "1000",
                        "layout": "12",
                        "display": "block",
                        "margin_top": 0,
                        "width_type": "px",
                        "padding_top": 0,
                        "padding_left": 0,
                        "padding_right": 0,
                        "padding_bottom": 0
                      },
                      "desktop": {
                        "width": "1000",
                        "layout": "6_6",
                        "display": "block",
                        "margin_top": 0,
                        "width_type": "px",
                        "padding_top": 0,
                        "padding_left": 0,
                        "padding_right": 0,
                        "padding_bottom": 0
                      },
                      "disabled": true,
                      "component": "Row",
                      "animations": [],
                      "column_gap": "6",
                      "custom_css": "",
                      "full_width": true,
                      "extra_class": "",
                      "elementStyle": {},
                      "equal_height": false,
                      "hiddenConfig": [
                        "margin_left",
                        "margin_right"
                      ],
                      "vertical_align": "center"
                    },
                    "addition": {},
                    "children": [
                      {
                        "uuid": "03fa9746-be16-48fc-8cf8-1a8ef7220531",
                        "props": {
                          "component": "Column",
                          "dataLayoutLg": "6",
                          "dataLayoutMd": "6",
                          "dataLayoutSm": "12",
                          "dataLayoutXl": "12",
                          "elementStyle": {}
                        },
                        "addition": {},
                        "children": [
                          {
                            "uuid": "74937b5d-f808-46df-88e2-bf48995aec27",
                            "props": {
                              "name": "valid_date",
                              "laptop": {
                                "display": "block"
                              },
                              "mobile": {
                                "display": "block"
                              },
                              "tablet": {
                                "display": "block"
                              },
                              "content": "",
                              "desktop": {
                                "color": "rgb(0, 0, 0)",
                                "width": "100",
                                "height": "auto",
                                "display": "block",
                                "alignment": "center",
                                "font_size": 16,
                                "width_type": "%",
                                "font_family": "inherit",
                                "font_weight": "400",
                                "padding_top": 10,
                                "border_color": "rgba(193, 193, 193, 1)",
                                "border_style": "solid",
                                "padding_left": 18,
                                "padding_right": 18,
                                "padding_bottom": 10,
                                "border_top_width": 1,
                                "border_left_width": 1,
                                "border_right_width": 1,
                                "border_bottom_width": 1,
                                "border_top_left_radius": 8,
                                "border_top_right_radius": 8,
                                "border_bottom_left_radius": 8,
                                "border_bottom_right_radius": 8
                              },
                              "disabled": true,
                              "required": true,
                              "component": "Text Field",
                              "animations": [],
                              "custom_css": "",
                              "extra_class": "",
                              "placeholder": "MM/YY",
                              "elementStyle": {},
                              "read_only_name": false,
                              "dimensionActives": [
                                "width",
                                "height"
                              ],
                              "typographyActives": [
                                "font-size",
                                "font-weight",
                                "font-family",
                                "color"
                              ]
                            },
                            "addition": {},
                            "children": [],
                            "componentName": "TextField"
                          }
                        ],
                        "componentName": "Canvas"
                      },
                      {
                        "uuid": "a9b9557d-2558-4b5b-a668-a9bdea3d4ae1",
                        "props": {
                          "component": "Column",
                          "dataLayoutLg": "6",
                          "dataLayoutMd": "6",
                          "dataLayoutSm": "12",
                          "dataLayoutXl": "12",
                          "elementStyle": {}
                        },
                        "addition": {},
                        "children": [
                          {
                            "uuid": "5e08dd86-eac8-4e9f-9156-efd4505f81ab",
                            "props": {
                              "name": "cvv",
                              "laptop": {
                                "display": "block"
                              },
                              "mobile": {
                                "display": "block",
                                "margin_top": "16"
                              },
                              "tablet": {
                                "display": "block",
                                "margin_top": "16"
                              },
                              "content": "",
                              "desktop": {
                                "color": "rgb(0, 0, 0)",
                                "width": "100",
                                "height": "auto",
                                "display": "block",
                                "alignment": "center",
                                "font_size": 16,
                                "width_type": "%",
                                "font_family": "inherit",
                                "font_weight": "400",
                                "padding_top": 10,
                                "border_color": "rgba(193, 193, 193, 1)",
                                "border_style": "solid",
                                "padding_left": 18,
                                "padding_right": 18,
                                "padding_bottom": 10,
                                "border_top_width": 1,
                                "border_left_width": 1,
                                "border_right_width": 1,
                                "border_bottom_width": 1,
                                "border_top_left_radius": 8,
                                "border_top_right_radius": 8,
                                "border_bottom_left_radius": 8,
                                "border_bottom_right_radius": 8
                              },
                              "disabled": true,
                              "required": true,
                              "component": "Text Field",
                              "animations": [],
                              "custom_css": "",
                              "extra_class": "",
                              "placeholder": "CVV",
                              "elementStyle": {},
                              "read_only_name": false,
                              "dimensionActives": [
                                "width",
                                "height"
                              ],
                              "typographyActives": [
                                "font-size",
                                "font-weight",
                                "font-family",
                                "color"
                              ]
                            },
                            "addition": {},
                            "children": [],
                            "componentName": "TextField"
                          }
                        ],
                        "componentName": "Canvas"
                      }
                    ],
                    "componentName": "Canvas"
                  }
                ],
                "componentName": "Payment"
              },
              {
                "uuid": "31a14f69-aa3a-4f7c-a97d-d64eae4ddbba",
                "props": {
                  "label": "Place Your Order",
                  "laptop": {
                    "display": "block"
                  },
                  "mobile": {
                    "display": "block"
                  },
                  "tablet": {
                    "display": "block"
                  },
                  "desktop": {
                    "color": "rgba(255,255,255,1)",
                    "hover": true,
                    "display": "block",
                    "alignment": "right",
                    "font_size": 24,
                    "margin_top": "16",
                    "color_hover": "rgba(255,255,255,1)",
                    "font_family": "inherit",
                    "font_weight": "500",
                    "margin_left": 16,
                    "padding_top": "16",
                    "margin_right": 16,
                    "padding_left": "48",
                    "margin_bottom": "16",
                    "padding_right": "48",
                    "padding_bottom": "16",
                    "text_transform": "none",
                    "background_color": "rgba(0,0,0,1)",
                    "background_color_hover": "rgba(68,68,68,1)",
                    "border_top_left_radius": "24",
                    "border_top_right_radius": "24",
                    "border_bottom_left_radius": "24",
                    "border_bottom_right_radius": "24"
                  },
                  "no_copy": true,
                  "spacing": 0,
                  "disabled": true,
                  "component": "Payment Button",
                  "animations": [],
                  "custom_css": "",
                  "extra_class": "",
                  "elementStyle": {},
                  "show_text_price": true,
                  "typographyActives": [
                    "font-weight",
                    "font-size",
                    "font-family",
                    "text-transform"
                  ]
                },
                "addition": {},
                "children": [],
                "componentName": "PaymentButton"
              }
            ],
            "componentName": "Canvas"
          },
          {
            "uuid": "42d13962-af71-45a6-a9f2-9fb669eea057",
            "props": {
              "component": "Column",
              "dataLayoutLg": "5",
              "dataLayoutMd": "5",
              "dataLayoutSm": "12",
              "dataLayoutXl": "12",
              "elementStyle": {}
            },
            "addition": {},
            "children": [
              {
                "uuid": "362c14a5-2904-40ac-a190-d3e851539395",
                "props": {
                  "icon": "",
                  "label": "Order Summary",
                  "laptop": {
                    "display": "block"
                  },
                  "mobile": {
                    "display": "block"
                  },
                  "tablet": {
                    "display": "block"
                  },
                  "desktop": {
                    "color": "rgb(0, 0, 0)",
                    "display": "block",
                    "alignment": "left",
                    "font_size": 16,
                    "font_family": "inherit",
                    "font_weight": "600",
                    "padding_top": 30,
                    "padding_left": 16,
                    "padding_right": 16,
                    "padding_bottom": 10
                  },
                  "no_copy": true,
                  "spacing": 10,
                  "disabled": true,
                  "component": "Order Summary",
                  "animations": [],
                  "custom_css": "",
                  "extra_class": "",
                  "elementStyle": {},
                  "typographyActives": [
                    "font-weight",
                    "font-size",
                    "font-family",
                    "color"
                  ]
                },
                "addition": {},
                "children": [],
                "componentName": "OrderSummary"
              }
            ],
            "componentName": "Canvas"
          }
        ],
        "componentName": "Canvas"
      }
    ],
    "componentName": "Canvas"
  }
]`