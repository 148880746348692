import Cookies from "js-cookie";
export const listFonts = () => {
  const listFonts = [
    {
      value: "inherit",
      label: "Default",
    },
  ];
  const user = JSON.parse(Cookies.get('user_onepage'), "{}")
  const fonts = JSON.parse(localStorage.getItem(`listFonts_${user.store?.domain}`) || "[]");
  fonts?.map((e) => {
    listFonts.push({ value: e, label: e });
  });
  return listFonts;
}