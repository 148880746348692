<template>
  <div id="sg-product-picker">
    <el-dialog
      title="Select a Blog"
      :visible.sync="dialogVisible"
      width="40%"
      top="8vh"
      :before-close="() => closeModal()"
    >
      <div class="product-body">
        <el-input
          placeholder="Search products"
          :value="input"
          @input="searchBlog($event)"
        >
          <i slot="prefix" class="el-input__icon el-icon-search"></i>
        </el-input>
        <div class="list-products">
          <el-table
            v-loading="loading"
            :data="blogs"
            style="width: 100%"
            :show-header="false"
            cell-class-name="custom-cell"
            row-class-name="custom-row"
            @row-click="(row) => handlePick(row)"
          >
            <el-table-column width="120">
              <template slot-scope="scope">
                <div class="text-center">
                  <img v-if="scope.row.image" :src="scope.row.image.includes('https://') ? scope.row.image : getImageUrl(scope.row.image)" style="width: auto; height: 50px;" />
                  <img v-else src="https://static.thenounproject.com/png/212328-200.png" style="width: auto; height: 50px;" />
                </div>
              </template>
            </el-table-column>
            <el-table-column>
              <template slot-scope="scope">
                <span class="line-clamp-1">{{ scope.row.title }}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { request } from '@/services';
import Cookies from 'js-cookie';
export default {
  props: {
    dialogVisible: Boolean,
    handleClose: Function,
    handleSave: Function
  },
  data() {
    return {
      input: "",
      blogs: [],
      loading: true,
      user: JSON.parse(Cookies.get('user_onepage') || "{}"),
      apiUrl: process.env.VUE_APP_API_URL
    };
  },
  watch: {
    dialogVisible: function (newValue, oldValue) {
      if (newValue) {
        this.loading = true;
        request.get(`${this.apiUrl}/v2/admin/blogs`, { title: this.input, type:"blogs" }).then(res => {
          this.loading = false;
          if (res.status == 200) {
            this.blogs = res.data.pages.data;
          }
        }, err => {
          this.$toastr.e(err?.response?.data?.message || 'Error');
          this.loading = false;
        })
      }
    }
  },
  methods: {
    getImageUrl(url) {
      return `${process.env.VUE_APP_API_URL}${url}`
    },
    closeModal() {
      this.input = ""
      this.$props.handleClose()
    },
    handlePick(row) {
      const blog = {
        blog_id: row.page_id,
        title: row.title
      }
      this.input = ""
      this.$props.handleSave(blog)
    },
    searchBlog(value) {
      this.input = value;
      this.loading = true;
      request.get(`${this.apiUrl}/v2/admin/blogs`, { title: value, type:"blogs" }).then(res => {
        this.loading = false;
        if (res.status == 200) {
            this.blogs = res.data.pages.data;
        }
      }, err => {
        this.$toastr.e(err?.response?.data?.message || 'Error');
        this.loading = false;
      })
    }
  },
};
</script>

<style lang="scss">
#sg-product-picker {
  .el-dialog__wrapper {
    z-index: 10000 !important;
  }

  .el-dialog__header {
    padding: 20px;
  }

  .el-dialog__body {
    padding: 0px;
  }
  .el-input__inner {
    border-color: #e2e2e2;
    border-radius: 0;
    border-left: none;
    border-right: none;
    &:focus {
      border-color: #e2e2e2;
    }
  }
  .list-products {
    padding: 20px;
    background-color: #F3F5F9;
    height: 60vh;
    overflow-y: auto;
    overflow-x: hidden;
    .custom-row {
      cursor: pointer;
    }
    .custom-cell:first-child {
      border-left: 1px solid #EBEEF5;
    }
    .custom-cell:last-child {
      border-right: 1px solid #EBEEF5;
    }
  }

}

.line-clamp-1 {
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  overflow: hidden;
  display: -webkit-box;
  word-wrap: break-word;
}
</style>