
<script>
import { request } from "@/services";
import Vue from "vue";
import Anchor from "./AnchorList.vue";
import PopupLayer from "../../../../components/PopupLayer.vue";
import FrameFloating from "../../../../components/FrameFloating.vue";

import { v4 as uuidv4 } from "uuid";
import Node from "../../../../components/Node.vue";
import OverlayList from "../Iframe/OverlayList.vue";
import PreviewModal from "../Iframe/PreviewModal.vue";
import PaypalModal from "./PaypalModal.vue";
import CartModal from "./CartModal.vue";
import CartButton from "./CartButton.vue";
import FontsActive from "./FontsActive.vue";
import Cookies from "js-cookie";
import shortcuts from "@/utils";
export default {
  name: "Iframe",
  render(h) {
    return h("iframe", {
      on: { load: this.renderChildren },
      attrs: {
        // scrolling: "yes",
        src: "/builder" // You may need to change this to an absolute URL
      }
    });
  },
  mounted() {
    var $iframe = $("#designEditor").contents();
    var page = $iframe.find(`[data-key="wrapper-container"]`);
    page[0]?.addEventListener("scroll", () => {
      this.$bus.emit("scrollPreview");
    });
    const iframe = document.getElementById("designEditor");
    const editor = this.editor;
    iframe.contentDocument.addEventListener("keydown", function (event) {
      shortcuts(event, editor);
    });
  },
  props: {},
  beforeDestroy(){
    const iframe = document.getElementById("designEditor");
    iframe.contentDocument.removeEventListener("keydown");
  },
  beforeUpdate() {
    //freezing to prevent unnessessary Reactifiation of vNodes
    this.iApp.children = Object.freeze(this.$slots.default);
  },
  destroyed() {
    this.$el.contentWindow.document.removeEventListener("scroll");
  },
  inject: ["editor"],
  watch: {},
  methods: {
    renderChildren() {
      const editor = this.editor;
      const screen = this.screen;
      const user = JSON.parse(Cookies.get("user_onepage") || "{}");

      // Add font default
      // let font_default = document.createElement("link");
      // font_default.rel = "stylesheet";
      // font_default.href =
      //   "https://fonts.googleapis.com/css2?family=Work+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap";
      // this.$el.contentDocument.head.appendChild(font_default);
      

      const children = this.$slots.default;
      const body = this.$el.contentDocument.body;
      body.classList.add("page-template-default");
      while (body.firstChild) {
        body.removeChild(body.firstChild);
      }
      const header = this.$el.contentDocument.head;
      while (header.firstChild) {
        header.removeChild(header.firstChild);
      }
      const header_el = document.createElement("DIV"); // we will mount or nested app to this element
      header.appendChild(header_el);
      const fonts = new Vue({
        name: "fonts",
        render(h) {
          return h(FontsActive, { props: { editor: editor } });
        },
      });
      fonts.$mount(header_el);
      const el = document.createElement("DIV"); // we will mount or nested app to this element

      body.appendChild(el);

      let cssFiles = [
        "https://css-builder.pages.dev/bootstrap.min.css?v=1.14",
        `https://css-builder.pages.dev/builder_salegate.css?v=${uuidv4()}`,
        `https://css-builder.pages.dev/animate.css?v=${uuidv4()}`,
        `https://css-builder.pages.dev/popup_salegate.css?v=${uuidv4()}`,
        `https://css-builder.pages.dev/element_styles.css?v=${uuidv4()}`,
        `https://css-builder.pages.dev/font-awesome.min.css?v=${uuidv4()}`,
        "https://css-builder.pages.dev/owlcarousel/assets/owl.carousel.min.css",
        "https://css-builder.pages.dev/owlcarousel/assets/owl.theme.default.min.css",
      ];

      let jsFiles = [
        "https://css-builder.pages.dev/vendors/bootstrap.bundle.min.js",
        "https://css-builder.pages.dev/vendors/jquery.min.js",
        "https://css-builder.pages.dev/owlcarousel/owl.carousel.js",
        "https://www.youtube.com/iframe_api"
      ];
      for (let file of cssFiles) {
        let link = document.createElement("link");
        link.type = "text/css";
        link.rel = "stylesheet";
        link.href = file;

        this.$el.contentDocument.head.appendChild(link);
      }

      for (let file of jsFiles) {
        let script = document.createElement("script");
        script.src = file;

        this.$el.contentDocument.head.appendChild(script);
      }

      let meta = document.createElement("meta");
      meta.name = "viewport";
      meta.content = "width=device-width, initial-scale=1.0";
      this.$el.contentDocument.head.appendChild(meta);
      const iApp = new Vue({
        name: "iApp",
        //freezing to prevent unnessessary Reactifiation of vNodes
        data: {
          children: Object.freeze(children),
          editor: this.editor,
          bus: this.$bus,
        },
        render(h) {
          return h(
            "div",
            { attrs: { id: "PageContainer" }, class: "page-container" },
            this.children
          );
        },
      });

      iApp.$mount(el); // mount into iframe

      this.iApp = iApp; // cache instance for later updates

      el.addEventListener("scroll", () => {
        this.$bus.emit("scrollPreview");
      });

      const popupAnchorList = new Vue({
        name: "Anchor",
        data: {
          editor: editor,
        },
        render(h) {
          return h(Anchor, { props: { editor: editor } });
        },
      });
      const el2 = document.createElement("DIV"); // we will mount or nested app to this element
      body.appendChild(el2);
      popupAnchorList.$mount(el2); // mount into iframe

      
      const popupList = new Vue({
        name: "PopupLayer",
        data: {
          editor: editor,
        },
        render(h) {
          return h(PopupLayer, { props: { editor: editor, screen: screen } });
        },
      });
      const el3 = document.createElement("DIV"); // we will mount or nested app to this element
      body.appendChild(el3);
      popupList.$mount(el3);

      const OvelayList = new Vue({
        name: "OverlayList",
        data: {
          editor: editor,
        },
        render(h) {
          return h(OverlayList, { props: { editor: editor, screen: screen } });
        },
      });
      const el4 = document.createElement("DIV"); // we will mount or nested app to this element
      body.appendChild(el4);
      OvelayList.$mount(el4);

      const elModal = document.createElement("DIV"); // we will mount or nested app to this element
      body.appendChild(elModal);
      const modal = new Vue({
        name: "modal",
        data: {},
        render(createElement) {
          return createElement(PreviewModal, {
            props: {},
          });
        },
      });
      modal.$mount(elModal);

      const ppModal = document.createElement("DIV"); // we will mount or nested app to this element
      body.appendChild(ppModal);
      const pmodal = new Vue({
        name: "modal",
        data: {},
        render(createElement) {
          return createElement(PaypalModal, {
            props: {},
          });
        },
      });
      pmodal.$mount(ppModal);

      if (user.store) {
        request.get(`/api/v1/store/${user.store?.public_id}`, {}).then(res => {
          if (res.data.success) {
            const paypal = res.data.data.payments.find(e => e.payment_type == 3);
            if (paypal && paypal.info?.client_id) {
              let paypal_script = document.createElement("script");
              paypal_script.src = `https://www.paypal.com/sdk/js?client-id=${paypal.info?.client_id}&components=buttons&enable-funding=venmo,paylater&currency=${res.data.data.currency_id?.code || 'USD'}`;
              paypal_script.setAttribute("data-namespace", "paypal");
              this.$el.contentDocument.head.appendChild(paypal_script);
            }
          }
        });
      }

      const cartModal = document.createElement("DIV"); // we will mount or nested app to this element
      body.appendChild(cartModal);
      const cModal = new Vue({
        name: "modal",
        data: {},
        render(createElement) {
          return createElement(CartModal, {
            props: {},
          });
        },
      });
      cModal.$mount(cartModal);


      const cartButton = document.createElement("DIV"); // we will mount or nested app to this element
      body.appendChild(cartButton);
      const cButton = new Vue({
        name: "div",
        data: {},
        render(createElement) {
          return createElement(CartButton, {
            props: {},
          });
        },
      });
      cButton.$mount(cartButton);

      const toast = document.createElement("DIV"); // we will mount or nested app to this element
      body.appendChild(toast);
      const toastAttr = new Vue({
        name: "toast",
        data: {},
        render(createElement) {
          return createElement('div', {
            attrs: {
              id: 'toast-msg',
              class: 'sg-snackbar',
            },
          });
        },
      });
      toastAttr.$mount(toast);

      const frameFloating = new Vue({
        name: "FrameFloating",
        data: {
          editor: editor,
        },
        render(h) {
          return h(FrameFloating, {
            props: { editor: editor, screen: screen },
          });
        },
      });
      const el5 = document.createElement("DIV"); // we will mount or nested app to this element
      body.appendChild(el5);
      frameFloating.$mount(el5);

    },
  },
};
</script>
<style scoped>
.header {
  height: 60px;
  background: #202124;
  display: flex;
  align-items: center;
  padding: 0px 10px;
}
</style>
