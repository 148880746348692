<template>
  <div class="sg-popup-list">
    <Node v-for="node in editor?.popups.filter(x=>x.componentName == 'Popup')" :key="node.uuid" :node="node" :editor="editor" :screen="screen"/>
  </div>
</template>

<script>

import Node from "./Node.vue";

export default {
  components: {
    Node,
  },
  props: {
    screen:String,
    editor: Object,

  },
  provide() {
    return {
      editor: this.editor
    }
  },
  created() {
    
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
</style>
