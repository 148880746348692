<template>
  <div id="sg-button-link-picker" :style="cssVars">
    <el-dialog
      title="Link Picker"
      :visible.sync="dialogVisible"
      width="40%"
      top="5vh"
      :before-close="() => closeModal()"
    >
      <div class="product-body p-4">
        <div class="d-flex align-items-center gap-2">
          <div class="d-flex flex-column flex-2">
            <span class="setting-title">
              Custom URL
            </span>
            <Input 
              class="mt-2"
              :value="dataButton.href"
              v-bind:changeValue="($event) => updateProps('href', $event)"
              type="text"
              :disabled="true"
              size="medium"
              :primaryColor="primaryColor"
            />
          </div>
          <div class="d-flex flex-column flex-1">
            <span class="setting-title">
              Target
            </span>
            <Select
              class="mt-2 link-picker"
              :primaryColor="primaryColor"
              :value="dataButton.target"
              v-bind:changeValue="($event) => updateProps('target', $event)"
              :options="[
                {
                  value: '_self',
                  label: 'Current Tab',
                },
                {
                  value: '_blank',
                  label: 'New Tab',
                },
              ]"
            />
          </div>
        </div>
        <el-tabs class="mt-2" v-model="dataButton.action_type" @tab-click="(tab) => updateProps('action_type', tab.name)">
          <el-tab-pane label="Product" name="product">
            <div class="d-flex flex-column mt-3 list-items gap-1">
              <div 
                v-for="product in products"
                :key="product.product_id"
                :class="dataButton.href == `/products/${product.slug}` ? 'active' : ''"
                class="d-flex align-items-center product-item"
                @click="() => updateProps('href', `/products/${product.slug}`)"
              >
                <img :src="product.media_url" :alt="product.title" />
                <div class="product-content">{{ product.title }}</div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="Scroll To" name="scroll">
            <div class="d-flex flex-column mt-3 list-items gap-1">
              <div 
                v-for="element in elements"
                :key="element.uuid"
                :class="dataButton.href == `#element-${element.uuid}` ? 'active' : ''"
                class="d-flex elements-item"
                @click="() => updateProps('href', `#element-${element.uuid}`)"
              >
                <div class="icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="#9e9e9e" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M4 4l16 0"></path>
                    <path d="M4 20l16 0"></path>
                    <path d="M6 9m0 2a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v2a2 2 0 0 1 -2 2h-8a2 2 0 0 1 -2 -2z"></path>
                  </svg>
                </div>
                <div class="product-content py-3 d-flex flex-column gap-2">
                    <span class="title">{{ element.name }}</span>
                    <span class="title-id">ID: {{ element.uuid }}</span>
                  <!-- <span>{{ element.name || element.description }}</span> -->
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="Link Popup" name="popup">
            <div class="d-flex flex-column mt-3 list-items gap-1">
              <div 
                v-for="popup in popups"
                :key="popup.uuid"
                :class="dataButton.href == `#element-${popup.uuid}` ? 'active' : ''"
                class="d-flex elements-item"
                @click="() => updateProps('href', `#element-${popup.uuid}`)"
              >
                <div class="icon" style="border-right: 1px solid #E2E2E2;">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="#9e9e9e" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M4 4l16 0"></path>
                    <path d="M4 20l16 0"></path>
                    <path d="M6 9m0 2a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v2a2 2 0 0 1 -2 2h-8a2 2 0 0 1 -2 -2z"></path>
                  </svg>
                </div>
                <div class="product-content py-3 d-flex flex-column">
                  <span class="title">{{ popup.props.name }}</span>
                  <span class="title-id">ID: {{ popup.uuid }}</span>
                </div>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">Cancel</el-button>
        <el-button type="primary" :disabled="node && dataButton.href == node.props.href && dataButton.target == node.props.target" @click="handleSave(dataButton)">Save</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Node from '@/core/Node';
import { request } from '@/services';
import Input from '@/views/landing/components/elements/common/Input.vue';
import Select from '@/views/landing/components/elements/common/Select.vue';

export default {
  props: {
    dialogVisible: Boolean,
    handleClose: Function,
    handleSave: Function,
    node: Node,
    primaryColor: String,
  },
  components: {
    Input, Select
  },
  data() {
    return {
      products: [],
      popups: [],
      elements: [],
      dataButton: {
        href: "",
        target: "",
        action_type: ""
      }
    };
  },
  computed: {
    cssVars() {
      return {
        '--primaryColor': this.primaryColor || '#0060e5'
      }
    }
  },
  watch: {
    dialogVisible: function (newValue, oldValue) {
      if (newValue) {
        request.get('/admin/v1/products', {no_limit: 1}).then(res => {
          if (res.data.success) {
            this.products = res.data.data.items
          }
        })
        const { node } = this.$props;
        const data = Object.assign({}, this.dataButton)
        data['href'] = node.props.href;
        data['target'] = node.props.target;
        data['action_type'] = node.props.action_type || 'product';
        this.dataButton = data;

        const editor = node.editor;
        
        // Get list elements
        const nodeMaps = Object.values(editor.nodeMap)
        const wrapper = nodeMaps.find(e => e.props.component == "WrapperContainer")
        const wrapper_uuid = wrapper.uuid;
        const listRows = nodeMaps.filter(e => e.parent?.uuid == wrapper_uuid)
        const elements = []
        listRows.map(row => {
          var name = ""
          if (row.componentName == 'Row') {
            name = row.props.name
          } else {
            name = row.componentName
          }
          // const textNode = nodeMaps.find(e => e.isAncestor(row) && (e.componentName == 'Heading' || e.componentName == "Paragraph"))
          // const childNode = nodeMaps.find(e => e.isAncestor(row) && !['Row', 'Canvas'].includes(e.componentName))
          // var description = ""
          // if (textNode) {
          //   description = textNode.props.content
          // } else if (childNode) {
          //   description = childNode.componentName
          // }
          elements.push({
            uuid: row.uuid,
            name
          })
        })
        this.elements = elements
        // Get list popup
        const popups = editor.exportPopup()
        this.popups = JSON.parse(popups)
      }
    },
  },
  methods: {
    closeModal() {
      this.$props.handleClose()
    },
    updateProps(key, value) {
      const data = Object.assign({}, this.dataButton)
      data[key] = value
      this.dataButton = data
    },
  },
};
</script>

<style lang="scss">
#sg-button-link-picker {
  .el-dialog__header {
    padding: 20px;
  }

  .el-dialog__body {
    padding: 0px;
  }

  .custom-select.link-picker .el-input__inner {
    background-color: #FFFFFF;
    border: 1px solid #DCDFE6;
    color: #606266;
  }

  .custom-select.link-picker .el-input.is-disabled .el-input__inner {
    color: #444444;
    background-color: #F5F7FA;
    border-color: #E4E7ED;
  }

  .product-item, .elements-item {
    gap: 10px;
    border: 1px solid #E2E2E2;
    cursor: pointer;
  }

  .product-item.active, .elements-item.active {
    border: 2px solid var(--primaryColor);
  }

  .list-items {
    max-height: 60vh;
    overflow: auto;
  }

  .product-item img {
    width: 60px;
    height: 60px;
    object-fit: contain;
  }

  .product-item .product-content {
    flex: 1;
  }

  .elements-item .icon {
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .elements-item .title {
    font-size: 16px;
    color: #000;
    font-weight: 500;
  }

}

.line-clamp-1 {
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  overflow: hidden;
  display: -webkit-box;
  word-wrap: break-word;
}
</style>