<template>
  <div 
    data-label="Liquid" 
    data-key="liquid"
    class="sg-wrap module"
    :id="`element-${node.uuid}`"
    @mouseover="isHovering=true" 
    @mouseout="isHovering=false"
  >
    <div class="liquid-builder" :data-name="name"></div>
    <div v-if="template" :id="`liquid-${ node.uuid }`" class="liquid-wrapper" v-html="template" />
    <ToolBar
      ref="liquid_toolbar"
      toolbar_id="liquid"
      class="container-toolbar"
      :class="{ 'container-toolbar-show': isHovering, 'is-dragging': dragging == node.uuid }"
    ></ToolBar>
    <div v-if="dragging == node.uuid" class="dragging"></div>

    <v-style>

      @media (max-width: 767px){
        #element-{{ node.uuid }} .liquid-wrapper {
          text-align: {{ getValue('alignment', 'mobile') }};
        }
        #element-{{ node.uuid }} {
          {{ getDisplayValue('mobile') }}
          {{ getMarginValue('mobile') }}
          {{ getPaddingValue('mobile') }}
          {{ getBackgroundValue('mobile') }}
          {{ getBorderValue('mobile') }}
          {{ getBorderRadiusValue('mobile') }}
          {{ getOpacityValue('mobile') }}
          {{ getShadowValue('mobile') }}
          {{ getOtherStyle('mobile') }}
        }
      }
      @media (max-width: 991px) and (min-width: 768px){
        #element-{{ node.uuid }} .liquid-wrapper {
          text-align: {{ getValue('alignment', 'tablet') }};
        }
        #element-{{ node.uuid }} {
          {{ getDisplayValue('tablet') }}
          {{ getMarginValue('tablet') }}
          {{ getPaddingValue('tablet') }}
          {{ getBackgroundValue('tablet') }}
          {{ getBorderValue('tablet') }}
          {{ getBorderRadiusValue('tablet') }}
          {{ getOpacityValue('tablet') }}
          {{ getShadowValue('tablet') }}
          {{ getOtherStyle('tablet') }}
        }
      }
      @media (max-width: 1199px) and (min-width: 992px){
        #element-{{ node.uuid }} .liquid-wrapper {
          text-align: {{ getValue('alignment', 'laptop') }};
        }
        #element-{{ node.uuid }} {
          {{ getDisplayValue('laptop') }}
          {{ getMarginValue('laptop') }}
          {{ getPaddingValue('laptop') }}
          {{ getBackgroundValue('laptop') }}
          {{ getBorderValue('laptop') }}
          {{ getBorderRadiusValue('laptop') }}
          {{ getOpacityValue('laptop') }}
          {{ getShadowValue('laptop') }}
          {{ getOtherStyle('laptop') }}
        }
      }
      @media (min-width: 1200px){
        #element-{{ node.uuid }} .liquid-wrapper {
          text-align: {{ getValue('alignment', 'desktop') }};
        }
        #element-{{ node.uuid }} {
          {{ getDisplayValue('desktop') }}
          {{ getMarginValue('desktop') }}
          {{ getPaddingValue('desktop') }}
          {{ getBackgroundValue('desktop') }}
          {{ getBorderValue('desktop') }}
          {{ getBorderRadiusValue('desktop') }}
          {{ getOpacityValue('desktop') }}
          {{ getShadowValue('desktop') }}
          {{ getOtherStyle('desktop') }}
        }
      }

      {{ css }}

    </v-style>

    <v-script>
      {{ javascript }}
    </v-script>
  </div>
</template>

<script>
import Visibility from "../../elements/designSettings/Visibility.vue";
import Spacing from "../../elements/designSettings/Spacing.vue";
import Background from "../../elements/designSettings/Background.vue";
import Border from "../../elements/designSettings/Border.vue";
import Corner from "../../elements/designSettings/Corner.vue";
import Shadow from "../../elements/designSettings/Shadow.vue";
import Opacity from "../../elements/designSettings/Opacity.vue";

import Introduce from "./settings/Introduce.vue";
import Name from "./settings/Name.vue";
import AlignmentBase from '../../elements/styleSettings/AlignmentBase.vue';
import elementStyleMixin from "../../elements/elementStyleMixin";
import ToolBar from "../../elements/utils/ToolBar.vue";
import { designStyles, otherProps, otherStyles, actionTypes } from "@/constants";
import Animations from "../../elements/designSettings/Animations.vue";

export default {
  mixins: [elementStyleMixin],
  components: { ToolBar },
  watch: {
    dragging: function (newValue, oldValue) {
      var $iframe = $("#designEditor").contents();
      var $element = $iframe.find(`#element-${this.node.uuid}`);
      if (newValue && newValue == this.node.uuid) {
        setTimeout(() => {
          $element.hide();
        },100);
      }else if (oldValue == this.node.uuid){
        $element.show();
      }
    },
  },
  computed: {
    dragging: function () {
      return this.editor?.draggedNode?.uuid
    },
  },
  props: {
    ...otherProps,
    name: String,
    desktop: Object,
    laptop: Object,
    tablet: Object,
    mobile: Object,
    template: String,
    css: String,
    javascript: String
  },
  inject: ["node", 'editor'],
  methods: {
    
  },
  data() {
    return {
      isHovering: false,
    }
  },
  craft: {
    defaultProps: {
      ...otherStyles,
      name: 'Right click on this, and choose Edit Code.',
      template: "You can add custom HTML / CSS / Javascript right here.",
      css: "",
      javascript: `$(document).ready(function() {

});`,
      desktop: {
        ...designStyles,
        alignment: 'left'
      },
      laptop: {
        ...designStyles,
      },
      tablet: {
        ...designStyles,
      },
      mobile: {
        ...designStyles,
      },
    },
    settings: {
      settings: {
        Introduce,
        Name,
        Alignment: {Alignment: AlignmentBase, multi_screen: true},
      },
      design: {
        Visibility,
        Spacing: { Spacing, multi_screen: true },
        Background: { Background, multi_screen: true },
        Border: { Border, multi_screen: true },
        Corner: { Corner, multi_screen: true },
        Shadow: { Shadow, multi_screen: true },
        Opacity: { Opacity, multi_screen: true },
      },
      others: {
        Animations: { Animations, actions: actionTypes }
      }
    },
  },
};
</script>
