import Vue from 'vue'
//Dòng này để import vue-router
import Router from 'vue-router'
import App from '../views/landing/App.vue';
// import Home from '../views/homepage/HomePage.vue';
import SettingPage from '../views/settings/SettingPage.vue';
import HomePage from '../views/pages/HomePage.vue';
import ProductPage from '../views/pages/ProductPage.vue';
import CheckoutPage from '../views/pages/CheckoutPage.vue';
import LandingPage from '../views/v2/pages/LandingPage.vue';
import EditorTemplate from '../views/pages/EditorTemplate.vue';

Vue.use(Router)

export default new Router({
  mode: 'history',
  hash: false,
  routes: [ 
    {
      path: '/landingpages', ///path của route
      name: 'LandingPage', // tên route
      component: LandingPage, // component route sử dụng
      props: {
        page: 'landingpages',
        title: "Sale Pages",
        subTitle: "Create a web page designed for the purpose of persuading visitors to act on an offer."
      }
    },
    {
      path: '/homepages', ///path của route
      name: 'HomePage', // tên route
      component: HomePage, // component route sử dụng
      props: {
        page: 'homepages',
        title: "Home Pages",
        subTitle: "First impressions matter. Create a homepage as unique as your brand with full control of layout."
      }
    },
    // {
    //   path: '/productpages', ///path của route
    //   name: 'ProductPage', // tên route
    //   component: ProductPage, // component route sử dụng
    //   props: {
    //     page: 'productpages',
    //     title: "Product Pages",
    //     subTitle: "Create and manage layout of your products."
    //   }
    // },
    // {
    //   path: '/productpages', ///path của route
    //   name: 'ProductPage', // tên route
    //   component: ProductPage, // component route sử dụng
    //   props: {
    //     page: 'productpages',
    //     title: "Product Pages",
    //     subTitle: "Create and manage layout of your products."
    //   }
    // },
    {
      path: '/checkoutpages', ///path của route
      name: 'CheckoutPage', // tên route
      component: CheckoutPage, // component route sử dụng
      props: {
        page: 'checkoutpages',
        title: "Checkout Pages",
        subTitle: "Create and manage layout of your checkout page."
      }
    },
    // {
    //   path: '/collectionpages', ///path của route
    //   name: 'CollectionPage', // tên route
    //   component: CollectionPage, // component route sử dụng
    //   props: {
    //     page: 'collectionpages',
    //     title: "Collection Pages",
    //     subTitle: "Creating collections is you can promote your product, showcase all the products focused on one design."
    //   }
    // },
    // {
    //   path: '/articlepages', ///path của route
    //   name: 'BlogPage', // tên route
    //   component: BlogPage, // component route sử dụng
    //   props: {
    //     page: 'articlepages',
    //     title: "Blog Posts",
    //     subTitle: "Create A Blog Post to Drive traffic to your website. Increase your SEO/ SERP."
    //   }
    // },
    {
      path: '/editor', ///path của route
      name: 'Editor', // tên route
      component: App // component route sử dụng
    },
    {
      path: '/account/settings', ///path của route
      name: 'Settings', // tên route
      component: SettingPage, // component route sử dụng
      props: {
        title: "Settings",
        subTitle: "Configure your account information, email preferences & more!"
      }
    },
    {
      path: '/builder', ///path của route
      name: 'Builder', // tên route
      component: EditorTemplate, // component route sử dụng
      props: {}
    },

  ]

})