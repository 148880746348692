<template>
    <div>
        Loading
    </div>
  </template>
  
  <script>
  import { Frame, Canvas } from "@";
  import Paper from "../landing/components/Paper.vue";
  
  
  export default {
    components: {

    },
    mounted(){

    },
    // inject: ["editor"],
    data() {
      return {

      };
    },
    props: {
    },
  };
  </script>
  
  
  