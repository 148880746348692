var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"label-position":"top","size":"mini","model":_vm.elementProps}},[_c('div',{staticClass:"d-flex flex-column py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Position")]),_c('Select',{staticClass:"mt-2",attrs:{"primaryColor":_vm.primaryColor,"value":_vm.elementProps.position,"changeValue":($event) => _vm.updateProps('position', $event),"options":[
        {
          value: 'top-left',
          label: 'Top Left',
        },
        {
          value: 'top-right',
          label: 'Top Right',
        },
        {
          value: 'bottom-left',
          label: 'Bottom Left',
        },
        {
          value: 'bottom-right',
          label: 'Bottom Right',
        },
      ]}})],1),_c('div',{staticClass:"d-flex flex-column py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Background Color")]),_c('ColorPicker',{staticClass:"mt-2",attrs:{"value":_vm.elementProps.background_color,"changeColor":($event) => _vm.updateProps('background_color', $event),"primaryColor":_vm.primaryColor}})],1),_c('div',{staticClass:"d-flex flex-column py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Background Fill")]),_c('ColorPicker',{staticClass:"mt-2",attrs:{"value":_vm.elementProps.background_color_fill,"changeColor":($event) => _vm.updateProps('background_color_fill', $event),"primaryColor":_vm.primaryColor}})],1),_c('div',{staticClass:"d-flex flex-column py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Shadow Fill")]),_c('ColorPicker',{staticClass:"mt-2",attrs:{"value":_vm.elementProps.shadow_color_fill,"changeColor":($event) => _vm.updateProps('shadow_color_fill', $event),"primaryColor":_vm.primaryColor}})],1),_c('div',{staticClass:"d-flex align-items-center justify-content-between py-2"},[_c('span',{staticClass:"sgSubTitle"},[_vm._v("Effect")]),_c('el-switch',{attrs:{"value":_vm.elementProps.effect,"active-color":_vm.primaryColor,"inactive-color":"#949494"},on:{"change":function($event){return _vm.updateProps('effect', $event)}}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }