  <template>
  <div
    data-label="Parallax"
    data-key="parallax"
    class="sg-wrap module sg-parallax"
    @mouseover="isHovering=true" 
    @mouseout="isHovering=false"
    :id="`element-${node.uuid}`"
    data-speed="1"
  >
    <div  :class="extra_class" class="sg-parallax-content">
      <div v-if="editor?.findNode(node?.uuid)?.children?.length == 0" class="text-center drop-element">
        <span>Add element</span>
      </div>
      <slot />
    </div>
    <ToolBar ref="parallax_toolbar" :toolbar_id="`parallax`" class="container-toolbar" 
    :class="{ 'container-toolbar-show': isHovering, 'is-dragging': dragging == node.uuid }"
    ></ToolBar>
    <div v-if="dragging == node.uuid" class="dragging"></div>
    <v-style>
      {{ custom_css }}
      #element-{{ node.uuid }}.sg-parallax {
        background-image: url({{ dragging == node.uuid?'':imgUrl }});
      }

      @media (max-width: 767px) {
        #element-{{ node.uuid }}.sg-parallax {
          {{ getDisplayValue('mobile') }}
          {{ getMarginValue('mobile') }}
          {{ getPaddingValue('mobile') }}
          {{ getBackgroundValue('mobile') }}
          {{ getBorderValue('mobile') }}
          {{ getBorderRadiusValue('mobile') }}
          {{ getOpacityValue('mobile') }}
          {{ getShadowValue('mobile') }}
          {{ getOtherStyle('mobile') }}
        }
      }
      @media (max-width: 991px) and (min-width: 768px) {
        #element-{{ node.uuid }}.sg-parallax {
          {{ getDisplayValue('tablet') }}
          {{ getMarginValue('tablet') }}
          {{ getPaddingValue('tablet') }}
          {{ getBackgroundValue('tablet') }}
          {{ getBorderValue('tablet') }}
          {{ getBorderRadiusValue('tablet') }}
          {{ getOpacityValue('tablet') }}
          {{ getShadowValue('tablet') }}
          {{ getOtherStyle('tablet') }}
        }
      }
      @media (max-width: 1199px) and (min-width: 992px) {
        #element-{{ node.uuid }}.sg-parallax {
          {{ getDisplayValue('laptop') }}
          {{ getMarginValue('laptop') }}
          {{ getPaddingValue('laptop') }}
          {{ getBackgroundValue('laptop') }}
          {{ getBorderValue('laptop') }}
          {{ getBorderRadiusValue('laptop') }}
          {{ getOpacityValue('laptop') }}
          {{ getShadowValue('laptop') }}
          {{ getOtherStyle('laptop') }}
        }
      }
      @media (min-width: 1200px) {
        #element-{{ node.uuid }}.sg-parallax {
          {{ getDisplayValue('desktop') }}
          {{ getMarginValue('desktop') }}
          {{ getPaddingValue('desktop') }}
          {{ getBackgroundValue('desktop') }}
          {{ getBorderValue('desktop') }}
          {{ getBorderRadiusValue('desktop') }}
          {{ getOpacityValue('desktop') }}
          {{ getShadowValue('desktop') }}
          {{ getOtherStyle('desktop') }}
        }
      }
      
    </v-style>
  </div>
  
</template>

<script>
import elementStyleMixin from './elementStyleMixin';
import ToolBar from './utils/ToolBar.vue';
import Visibility from "./designSettings/Visibility.vue";
import Spacing from "./designSettings/Spacing.vue";
import BackgroundDesign from "./designSettings/Background.vue";
import Border from "./designSettings/Border.vue";
import Corner from "./designSettings/Corner.vue";
import Shadow from "./designSettings/Shadow.vue";
import Opacity from "./designSettings/Opacity.vue";
import Image from './styleSettings/Image.vue';
import ExtraClass from './otherSettings/ExtraClass.vue';
import CustomCSS from './styleSettings/CustomCSS.vue';
import { designStyles, otherProps, otherStyles, actionTypes } from '@/constants';
import Animations from './designSettings/Animations.vue';

export default {
  components: {ToolBar},
  mixins: [elementStyleMixin],
  watch: {
    dragging: function (newValue, oldValue) {
      var $iframe = $("#designEditor").contents();
      var $element = $iframe.find(`#element-${this.node.uuid}`);
      var $content = $element.find(`.sg-parallax-content`);
      if (newValue && newValue == this.node.uuid) {
        setTimeout(() => {
        $element.css("padding","0px 0px 0px 0px");
        $element.css("margin","0px 0px 0px 0px");
        $content.hide();
        },100);
      }else if (oldValue == this.node.uuid){
        $element.css("padding","");
        $element.css("margin","");
        $content.show();
      }
    }
  },
  computed: {
    dragging: function () {
      return this.editor?.draggedNode?.uuid
    },
  },
  inject: [
    'node',
    'editor'
  ],
  props: {
    ...otherProps,
    screen: String,
    component:String,
    
    imgUrl: String,
    extra_class: String,
    desktop: Object,
    laptop: Object,
    tablet: Object,
    mobile: Object,
  },
  data() {
    return {
      isHovering: false
    }
  },
  craft: {
    defaultProps: {
      ...otherStyles,
      imgUrl: "https://cdn.shopify.com/s/files/1/0657/0813/files/slide-girl_2048x.jpg",
      extra_class: "",
      desktop: {
        ...designStyles,
        padding_top: 200,
        padding_bottom: 200
      },
      laptop: {
        ...designStyles,
      },
      tablet: {
        ...designStyles,
      },
      mobile: {
        ...designStyles,
      },
      elementStyle: {},
    },
    settings: {
      settings: {
        Image,
        "Extra Class": ExtraClass, "Custom CSS": CustomCSS,
      },
      design: {
        Visibility,
        Spacing: { Spacing, multi_screen: true },
        Background: { Background: BackgroundDesign, multi_screen: true },
        Border: { Border, multi_screen: true },
        Corner: { Corner, multi_screen: true },
        Shadow: { Shadow, multi_screen: true },
        Opacity: { Opacity, multi_screen: true },
      },
      others: {
        Animations: { Animations, actions: actionTypes.filter(e => ['scroll_into_view', 'after_page_finish_loading'].includes(e.value)) }
      }
    },
  },
};
</script>

<style lang="scss" scoped>

.dragging{
  position:absolute;
  top:0;
  bottom: 0;
  left: 0;
  right:0;
  background: greenyellow;
  z-index: 10;
}

</style>
