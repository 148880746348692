<template>
  <div label-position="top" size="mini" :model="elementProps">
    <div class="d-flex flex-column py-2">
      <span class="setting-title">Size</span>
      <Slider 
        class="mt-2"
        :value="getValue('font_size',elementProps[screen].font_size)"
        :primaryColor="primaryColor"
        v-bind:changeValue="($event) => updateProps('font_size', $event)"
        unit="px"
      />
    </div>
  </div>
</template>

<script>
import mixin from "./mixin";
import Slider from '../common/Slider.vue';

export default {
  mixins: [mixin],
  components: {
    Slider,
  },
  methods: {
    updateProps(name, value) {
      const screen = this.$props.screen
      const data_screen = Object.assign({}, this.elementProps[screen])
      if(value ==  data_screen[name]) return;
      data_screen[name] = value
      this.elementPropsSetter({ [screen]: data_screen });
    },
  },
  props: {
    primaryColor: String,
    screen: String,
  }
};
</script>

<style scoped>
</style>
