<template>
  <div label-position="top" size="mini" :model="elementProps">
    <div class="py-2">
      <span>Opacity</span>
      <Slider 
        :primaryColor="primaryColor"
        :value="getValue('opacity',elementProps[screen].opacity) || 1" 
        :min="0.2"
        :max="1"
        :step="0.1"
        v-bind:changeValue="($event) => updateProps(screen, 'opacity', parseFloat($event || 0))"
        unit=""
      />
    </div>
  </div>
</template>

<script>
import mixin from '../styleSettings/mixin';
import Slider from '../common/Slider.vue';
export default {
  mixins: [mixin],
  components: {
    Slider
  },
  props: {
    screen: String,
    primaryColor: String,
  },
  methods: {
    updateProps(screen, name, value) {
      const data_screen = Object.assign({}, this.elementProps[screen])
      if(value ==  (data_screen[name]==undefined?1:data_screen[name])) return;
      data_screen[name] = value
      this.elementPropsSetter({ [screen]: data_screen });
    },
  }
};
</script>

<style>
</style>
