<template>
  <div
    data-label="Countdown Timer"
    data-key="countdown_timer"
    class="sg-wrap module"
    :id="`element-${node.uuid}`"
    @mouseover="isHovering = true"
    @mouseout="isHovering = false"
    :class="{ 'dragging': dragging == node.uuid }"
  >
    <div
      class="sg-module countdown-timer"
      :class="extra_class"
      :data-type="type"
      :data-end="getDate(end_date)"
      :data-timezone="timezone"
      :data-daily="getDaily(daily)"
      :data-evergreen="getEvergreen(evergreen)"
      :data-showweek="show_week?1:0"
      :data-showday="show_day?1:0"
      :data-dateend="getDateEnd(true)"
      :data-timeouthide="timeout_hide?1:0"
      :data-loop="loop?1:0"
    >
      <input class="input-hidden" type="hidden" name="show_week" data-name="show_week" :value="show_week" />
      <div class="count-down-wrap">
        <div class="count-down-inner">
          <div class="week-left time-left" v-if="show_week">
            <span class="num sg_gs-text-paragraph-1">{{(times?.w || 0).toString().padStart(2, '0')}}</span>
            <span class="count-label sg_gs-text-paragraph-1">{{ week_label }}</span>
          </div>
          <div class="day-left time-left" v-if="show_day">
            <span class="num sg_gs-text-paragraph-1">{{(times?.d || 0).toString().padStart(2, '0')}}</span>
            <span class="count-label sg_gs-text-paragraph-1">{{ day_label }}</span>
          </div>

          <div class="hour-left time-left" v-if="show_hour">
            <span class="num sg_gs-text-paragraph-1">{{(times?.h || 0).toString().padStart(2, '0')}}</span>
            <span class="count-label sg_gs-text-paragraph-1">{{ hour_label }}</span>
          </div>

          <div class="minute-left time-left" v-if="show_minute">
            <span class="num sg_gs-text-paragraph-1">{{(times?.m || 0).toString().padStart(2, '0')}}</span>
            <span class="count-label sg_gs-text-paragraph-1">{{ minute_label }}</span>
          </div>

          <div class="second-left time-left" v-if="show_second">
            <span class="num sg_gs-text-paragraph-1">{{(times?.s || 0).toString().padStart(2, '0')}}</span>
            <span class="count-label sg_gs-text-paragraph-1">{{ second_label }}</span>
          </div>
        </div>
      </div>
    </div>

    <ToolBar
      ref="countdown_timer"
      :toolbar_id="`countdown_timer`"
      class="container-toolbar"
      :class="{ 'container-toolbar-show': isHovering, 'is-dragging': dragging == node.uuid }"
    >
    </ToolBar>

    <v-style>
      {{ custom_css }}
      
      @media (max-width: 767px) {
        #element-{{ node.uuid }} .num.sg_gs-text-paragraph-1 {
          font-family: {{ getValue('font_family','mobile') }};
          {{ getValue('font_size', 'mobile') > 0 ? `font-size: ${getValue('font_size','mobile')}px;` : '' }}
          {{ getValue('color', 'mobile') ? `color: ${getValue('color','mobile')};` : '' }}
        } 
        #element-{{ node.uuid }} .count-label.sg_gs-text-paragraph-1 {
          font-family: {{ getValue('label_font_family','mobile') }};
          {{ getValue('label_font_size', 'mobile') > 0 ? `font-size: ${getValue('label_font_size','mobile')}px;` : '' }}
          {{ getValue('label_color', 'mobile') ? `color: ${getValue('label_color','mobile')};` : '' }}
        } 
        #element-{{ node.uuid }} .time-left span.num {
          padding-bottom: {{ getValue('vertical_gap','mobile') }}px;
        }
        #element-{{ node.uuid }} .time-left {
          padding: {{ getValue('horizontal_gap','mobile') }}px;
        }
        #element-{{ node.uuid }} .sg-module {
          text-align: {{ getValue('alignment','mobile') }};
        }
        #element-{{ node.uuid }} {
          {{ getDisplayValue('mobile') }}
          {{ getMarginValue('mobile') }}
          {{ getPaddingValue('mobile') }}
          {{ getBackgroundValue('mobile') }}
          {{ getBorderValue('mobile') }}
          {{ getBorderRadiusValue('mobile') }}
          {{ getOpacityValue('mobile') }}
          {{ getShadowValue('mobile') }}
          {{ getOtherStyle('mobile') }}
        }
      } 
      @media (max-width: 991px) and (min-width: 768px){ 
        #element-{{ node.uuid }} .num.sg_gs-text-paragraph-1 {
          font-family: {{ getValue('font_family','tablet') }};
          {{ getValue('font_size', 'tablet') > 0 ? `font-size: ${getValue('font_size','tablet')}px;` : '' }}
          {{ getValue('color', 'tablet') ? `color: ${getValue('color','tablet')};` : '' }}
        } 
        #element-{{ node.uuid }} .count-label.sg_gs-text-paragraph-1 {
          font-family: {{ getValue('label_font_family','tablet') }};
          {{ getValue('label_font_size', 'tablet') > 0 ? `font-size: ${getValue('label_font_size','tablet')}px;` : '' }}
          {{ getValue('label_color', 'tablet') ? `color: ${getValue('label_color','tablet')};` : '' }}
        } 
        #element-{{ node.uuid }} .time-left span.num {
          padding-bottom: {{ getValue('vertical_gap','tablet') }}px;
        }
        #element-{{ node.uuid }} .time-left {
          padding: {{ getValue('horizontal_gap','tablet') }}px;
        }
        #element-{{ node.uuid }} .sg-module {
          text-align: {{ getValue('alignment','tablet') }};
        }
        #element-{{ node.uuid }} {
          {{ getDisplayValue('tablet') }}
          {{ getMarginValue('tablet') }}
          {{ getPaddingValue('tablet') }}
          {{ getBackgroundValue('tablet') }}
          {{ getBorderValue('tablet') }}
          {{ getBorderRadiusValue('tablet') }}
          {{ getOpacityValue('tablet') }}
          {{ getShadowValue('tablet') }}
          {{ getOtherStyle('tablet') }}
        }
      } 
      @media (max-width: 1199px) and (min-width: 992px){ 
        #element-{{ node.uuid }} .num.sg_gs-text-paragraph-1 {
          font-family: {{ getValue('font_family','laptop') }};
          {{ getValue('font_size', 'laptop') > 0 ? `font-size: ${getValue('font_size','laptop')}px;` : '' }}
          {{ getValue('color', 'laptop') ? `color: ${getValue('color','laptop')};` : '' }}
        } 
        #element-{{ node.uuid }} .count-label.sg_gs-text-paragraph-1 {
          font-family: {{ getValue('label_font_family','laptop') }};
          {{ getValue('label_font_size', 'laptop') > 0 ? `font-size: ${getValue('label_font_size','laptop')}px;` : '' }}
          {{ getValue('label_color', 'laptop') ? `color: ${getValue('label_color','laptop')};` : '' }}
        } 
        #element-{{ node.uuid }} .time-left span.num {
          padding-bottom: {{ getValue('vertical_gap','laptop') }}px;
        }
        #element-{{ node.uuid }} .time-left {
          padding: {{ getValue('horizontal_gap','laptop') }}px;
        }
        #element-{{ node.uuid }} .sg-module {
          text-align: {{ getValue('alignment','laptop') }};
        }
        #element-{{ node.uuid }} {
          {{ getDisplayValue('laptop') }}
          {{ getMarginValue('laptop') }}
          {{ getPaddingValue('laptop') }}
          {{ getBackgroundValue('laptop') }}
          {{ getBorderValue('laptop') }}
          {{ getBorderRadiusValue('laptop') }}
          {{ getOpacityValue('laptop') }}
          {{ getShadowValue('laptop') }}
          {{ getOtherStyle('laptop') }}
        }
      } 
      @media (min-width: 1200px) { 
        #element-{{ node.uuid }} .num.sg_gs-text-paragraph-1 {
          font-family: {{ desktop?.font_family }};
          {{ getValue('font_size', 'desktop') > 0 ? `font-size: ${getValue('font_size','desktop')}px;` : '' }}
          {{ getValue('color', 'desktop') ? `color: ${getValue('color','desktop')};` : '' }}
        } 
        #element-{{ node.uuid }} .count-label.sg_gs-text-paragraph-1 {
          font-family: {{ desktop?.label_font_family }};
          {{ getValue('label_font_size', 'desktop') > 0 ? `font-size: ${getValue('label_font_size','desktop')}px;` : '' }}
          {{ getValue('label_color', 'desktop') ? `color: ${getValue('label_color','desktop')};` : '' }}
        } 
        #element-{{ node.uuid }} .time-left span.num {
          padding-bottom: {{ desktop?.vertical_gap }}px;
        }
        #element-{{ node.uuid }} .time-left {
          padding: {{ desktop?.horizontal_gap }}px;
        }
        #element-{{ node.uuid }} .sg-module {
          text-align: {{ desktop?.alignment }};
        }
        #element-{{ node.uuid }} {
          {{ getDisplayValue('desktop') }}
          {{ getMarginValue('desktop') }}
          {{ getPaddingValue('desktop') }}
          {{ getBackgroundValue('desktop') }}
          {{ getBorderValue('desktop') }}
          {{ getBorderRadiusValue('desktop') }}
          {{ getOpacityValue('desktop') }}
          {{ getShadowValue('desktop') }}
          {{ getOtherStyle('desktop') }}
        }
      }
    </v-style>
  </div>
</template>

<script>
import ToolBar from "../../elements/utils/ToolBar.vue";
import elementStyleMixin from "../../elements/elementStyleMixin";
import ExtraClass from "../../elements/otherSettings/ExtraClass.vue";
import CountdownSetting from "./settings/CountdownSetting.vue";

import Visibility from "../../elements/designSettings/Visibility.vue";
import Spacing from "../../elements/designSettings/Spacing.vue";
import Background from "../../elements/designSettings/Background.vue";
import Border from "../../elements/designSettings/Border.vue";
import Corner from "../../elements/designSettings/Corner.vue";
import Shadow from "../../elements/designSettings/Shadow.vue";
import Opacity from "../../elements/designSettings/Opacity.vue";

import { designStyles, otherProps, otherStyles, actionTypes } from '@/constants';
import TextNumber from "./settings/TextNumber.vue";
import TextLabel from './settings/TextLabel.vue';
import Label from './settings/Label.vue';
import SpacingTimer from './settings/Spacing.vue';
import AlignmentBase from '../../elements/styleSettings/AlignmentBase.vue';
import RedirectUrl from "./settings/RedirectUrl.vue";
import moment from "moment";
import CustomCSS from '../../elements/styleSettings/CustomCSS.vue';
import Animations from '../../elements/designSettings/Animations.vue';

export default {
  components: { ToolBar },
  computed: {
    clonedData: function () {
      return JSON.parse(
        JSON.stringify({
          type: this.type,
          daily:this.daily,
          end_date: this.getDate(this.end_date),
          evergreen:this.evergreen,
          isUndo: this.editor.isUndo,
          isRedo: this.editor.isRedo,
        })
      );
    },
    dragging: function () {
      return this.editor?.draggedNode?.uuid
    },
  },
  data() {
    return {
      isHovering: false,
      monthNames: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
      times:{},
      seconds:0,
      timer: null
    };
  },
  mixins: [elementStyleMixin],
  props: {
    ...otherProps,
    section: String,
    
    extra_class: String,
    component: String,
    type: String,
    end_date: String,
    timezone: String,
    daily: Array,
    evergreen: Array,
    loop: Boolean,
    auto_hide: Boolean,
    show_day: Boolean,
    day_label: String,
    show_hour: Boolean,
    hour_label: String,
    show_minute: Boolean,
    minute_label: String,
    show_second: Boolean,
    second_label: String,
    show_week: Boolean,
    week_label: String,
    redirect_url: String,
    desktop: Object,
    laptop: Object,
    tablet: Object,
    mobile: Object,
    timeout_hide: Boolean
  },
  inject: ["node","editor"],
  mounted(){
      this.seconds = this.getDifferent();
      this.times = this.secondsToTime(this.getDifferent());
      this.timer = setInterval(this.countDown, 1000);
  },
  watch:{
    clonedData: function (newValue, oldValue) {
        clearInterval(this.timer);
        this.seconds = this.getDifferent();
        this.times = this.secondsToTime(this.getDifferent());
        this.timer = setInterval(this.countDown, 1000);
    }
  },
  methods: {
    getDateEnd(format){
      var stringTime = ""
      switch (this.type) {
        case 'standard':
          if(format) {
            stringTime = this.end_date
          }else{
            stringTime = this.getDate(this.end_date)
          }
          break;
        case 'daily':
          var ATM = moment();
          var dailyTime =  moment(`${moment().format('YYYY-MM-DD')} ${this.daily[0]}:${this.daily[1]} ${this.daily[2]}`)
          if (ATM > dailyTime) {
            dailyTime = dailyTime.add(1, 'days');
          }
          stringTime = dailyTime.format('YYYY-MM-DD HH:mm:ss')
          break;
        case 'evergreen':
          var ATM = moment();
          ATM = ATM.add(this.evergreen[0], 'days')
          ATM = ATM.add(this.evergreen[1], 'hours')
          ATM = ATM.add(this.evergreen[2], 'seconds')
          stringTime = ATM.format('YYYY-MM-DD HH:mm:ss')
          break;
        default:
          break;
      }
      return stringTime
    },
    countDown(){
        this.seconds -= 1;
        this.times = this.secondsToTime(this.seconds)
        if (this.seconds <= 0) {
            clearInterval(this.timer);
        }
    },
    getDifferent(){
      var stringTime = this.getDateEnd()
      var d1 = moment(stringTime)
      var d2 = new Date(new Date().toLocaleString("en-US", { timeZone: "Asia/Ho_Chi_Minh" }))
      d2 =  moment(d2)
      var difference = (moment.duration(d1.diff(d2))) / 1000;
      return difference
    },
    secondsToTime(secs){
        let weeks = Math.floor(secs / (60 * 60 * 24 * 7));
        let divisor_for_days = secs % (60 * 60 * 24 * 7);
        if(!this.show_week){
          divisor_for_days = secs
        }
        let days = Math.floor(divisor_for_days / (60 * 60 * 24));
        let divisor_for_hours = divisor_for_days % (60 * 60 * 24);
        if(!this.show_day){
          divisor_for_hours = divisor_for_days
        }
        let hours = Math.floor(divisor_for_hours / (60*60));
        let divisor_for_minutes = divisor_for_hours % (60*60);
        let minutes = Math.floor(divisor_for_minutes / 60);
        let divisor_for_seconds = divisor_for_minutes % 60;
        let seconds = Math.ceil(divisor_for_seconds);
        if (seconds == 60) {
            seconds = 0
            minutes += 1
        }
        let obj = {
            "w": weeks,
            "d": days,
            "h": hours,
            "m": minutes,
            "s": seconds
        };
        return obj;
    },
    getDate(date) {
      try {
        date = new Date(date);
        const current_date = date.getDate();
        const current_month = this.monthNames[date.getMonth()];
        const current_year = date.getFullYear();
        const current_hour = date.getHours();
        const current_minute = date.getMinutes();
        const current_second = date.getSeconds();
        return `${current_date} ${current_month} ${current_year} ${current_hour}:${current_minute}:${current_second}${this.$props.timezone ? ' ' + this.$props.timezone : ''}`
      } catch (error) {
        return ""
      }
    },
    getDaily(arr) {
      return `${arr[0] || 0}h,${arr[1] || 0}m,${arr[2] || 0}s`
    },
    getEvergreen(arr) {
      return `${arr[0] || 0}d,${arr[1] || 0}h,${arr[2] || 0}m`
    }
  },
  craft: {
    defaultProps: {
      ...otherStyles,
      section: "module",
      extra_class: "",
      type: 'standard',
      end_date: moment(new Date(new Date().getTime() + 691200000)).format('YYYY-MM-DD HH:mm:ss'),
      timezone: `UTC${Math.floor(new Date().getTimezoneOffset() / (-60)) > 0 ? '+' + String(Math.floor(new Date().getTimezoneOffset() / (-60))) : Math.floor(new Date().getTimezoneOffset() / (-60))}${Math.abs(new Date().getTimezoneOffset() % 60) > 0 ? ':' + String(Math.abs(new Date().getTimezoneOffset() % 60)) : ''}`,
      auto_hide: false,
      daily: [10, 30, 'AM'],
      evergreen: [0, 3, 0],
      loop: true,
      auto_hide: false,
      show_day: true,
      day_label: 'Days',
      show_hour: true,
      hour_label: 'Hours',
      show_minute: true,
      minute_label: 'Minutes',
      show_second: true,
      second_label: 'Seconds',
      show_week: false,
      week_label: 'Weeks',
      redirect_url: '#',
      timeout_hide:false,
      desktop: {
        ...designStyles,
        color: 'rgb(61, 66, 70)',
        font_family: 'inherit',
        font_size: 40,
        label_color: 'rgb(61, 66, 70)',
        label_font_family: 'inherit',
        label_font_size: 16,
        horizontal_gap: 20,
        vertical_gap: 10,
        alignment: 'center'
      },
      laptop: {
        ...designStyles,
      },
      tablet: {
        ...designStyles,
      },
      mobile: {
        ...designStyles,
      },
    },
    settings: {
      settings: {
        "Countdown Settings": CountdownSetting,
        "Text Number": {"Text Number": TextNumber, multi_screen: true},
        "Text Label": {"Text Label": TextLabel, multi_screen: true},
        "Spacing": {Spacing: SpacingTimer, multi_screen: true},
        "Label": {Label, multi_screen: true},
        "Alignment": {"Alignment": AlignmentBase, multi_screen: true},
        "Redirect Url": RedirectUrl,
        "Extra Class": ExtraClass, "Custom CSS": CustomCSS,
      },
      design: {
        Visibility,
        Spacing: { Spacing, multi_screen: true },
        Background: { Background, multi_screen: true },
        Border: { Border, multi_screen: true },
        Corner: { Corner, multi_screen: true },
        Shadow: { Shadow, multi_screen: true },
        Opacity: { Opacity, multi_screen: true },
      },
      others: {
        Animations: { Animations, actions: actionTypes }
      }
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
