<template>
  <div
    :class="`sg-column
    sg_col-xs-${editor?.findNode(node?.uuid)?.props['dataLayoutXl'] || '12'}
    sg_col-sm-${editor?.findNode(node?.uuid)?.props['dataLayoutSm']|| '12'}
    sg_col-md-${editor?.findNode(node?.uuid)?.props['dataLayoutMd']|| '12'}
    sg_col-lg-${editor?.findNode(node?.uuid)?.props['dataLayoutLg']|| '12'}
    `"
    :id="`c-${node.uuid}`"
  >
    <div v-if="(editor?.findNode(node?.uuid)?.children?.length || 0) == 0" class="text-center row-dropper">
      <span>Add element</span>
    </div>
    <slot />
  </div>
  
</template>

<script>
import elementStyleMixin from './elementStyleMixin';

export default {
  mixins: [elementStyleMixin],
  inject: [
    'editor',
    'node'
  ],
  props: {
    child: String,
    
    dataLayoutXl: String,
    dataLayoutLg: String,
    dataLayoutMd: String,
    dataLayoutSm: String,
    full_width: Boolean
  },
  craft: {
    defaultProps: {
      "dataLayoutXl": "12",
      "dataLayoutLg": "12",
      "dataLayoutMd": "12",
      "dataLayoutSm": "12",
      elementStyle: {
      },
    },
    settings: {
      settings: {
      },
      design: {
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
