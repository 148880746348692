import { render, staticRenderFns } from "./DisplayStock.vue?vue&type=template&id=098d0823&scoped=true&"
import script from "./DisplayStock.vue?vue&type=script&lang=js&"
export * from "./DisplayStock.vue?vue&type=script&lang=js&"
import style0 from "./DisplayStock.vue?vue&type=style&index=0&id=098d0823&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "098d0823",
  null
  
)

export default component.exports