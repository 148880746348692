<template>
  <div class="header sticky-menu">
    <div class="logo-container">
      <img style="height: 50px; width: auto" src="../assets/images/logo.svg" />
      <div class="flex-column mx-2 d-none d-lg-flex">
        <span style="font-size: 16px; font-weight: bold">Salegate</span>
      </div>
    </div>
    <div class="header-container">
      <div class="screen-inner"></div>

      <div class="d-flex flex-row-reverse p-0">
        <el-popover
          popper-class="more-actions"
          width="250"
          :visible-arrow="false"
        >
          <ul>
            <li style="color: black">
              <a
                href="https://admin-onepage.salegate.io/"
                target="_blank"
                class="d-flex"
                style="color: black; width: 100%"
              >
                <div class="sg_icon-wrap mr-2">
                  <i :class="`sg_icon fa fa-bar-chart`"></i>
                </div>
                Store Admin
              </a>
            </li>
          </ul>
          <div class="page-item-tools-actions" slot="reference">
            <div
              class="button-list d-none d-lg-flex hover-gray-1 p-1 radius-4 px-2"
            >
              <div
                class="circle_avatar"
                :style="{
                  backgroundColor: `#${((user?.user_id || 0) * 5.5 * 1000)
                    .toString(16)
                    .substr(0, 3)}`,
                }"
              >
                <span
                  :style="{ color: '#fff', fontWeight: 'bold', fontSize: 13 }"
                  >{{ (user.email || "").substring(0, 1).toUpperCase() }}</span
                >
              </div>

              <div class="user-info" v-if="Object.keys(user).length > 0">
                <span class="user-info__name">
                  {{ user.email }}
                </span>
                <span class="user-info__store">
                  {{ user.store.domain }}
                </span>
              </div>
            </div>
          </div>
        </el-popover>
      </div>
    </div>
  </div>
</template>

<script>
import { icons } from "../constants";
import Cookies from "js-cookie";
import { awsIcons } from "../components/icons";

export default {
  inject: [],
  props: {},
  data() {
    return {
      icons,
      user: JSON.parse(Cookies.get("user_onepage") || "{}"),
      awsIcons: awsIcons,
    };
  },
  computed: {},
  methods: {},
};
</script>


<style>
.header {
  height: 60px;
  background: #202124;
  display: flex;
  align-items: center;
  padding: 0px 10px;
}
.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  height: 100%;
}
.button {
  padding: 10px 25px;
  color: #fff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
  background: #333333;
  transition: all 0.2s ease;
}
.button svg {
  stroke: #fff;
}
.button .icon {
  padding: 10px 15px;
}

.button:hover {
  background: #555555;
  transition: all 0.2 ease;
}

.screen-inner {
  display: flex;
  height: 100%;
}

.screen-inner div {
  display: flex;
  align-items: center;
  padding: 0px 12px;
  height: 100%;
  cursor: pointer;
}

.screen-inner div.selected {
  border-bottom: 2px solid #fff;
  background-color: #333333;
  cursor: default;
}

.screen-inner div:hover {
  background: #333333;
}

.button-list {
  display: flex;
  color: #fff;
}

.button-list div {
  margin: 0px 3px;
}

.button-list div:last-child {
  margin-right: 0px;
}

.user-info {
  display: flex;
  flex-direction: column;
}

.user-info .user-info__name {
  color: #e2e2e2;
  font-size: 16px;
  font-weight: 700;
}
.user-info .user-info__store {
  color: #a6a6a6;
  font-size: 13px;
  font-weight: 400;
}

.sticky-menu {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 49;
  width: 100%;
  height: 60px;
  background: #252525;
  border-radius: 0;
  display: flex;
  flex-direction: row;
  padding: 0px 8px 0px 24px;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
}
</style>
