<template>
  <div label-position="top" size="mini" :model="elementProps">
    <div class="d-flex align-items-center justify-content-between py-2">
      <span class="setting-title">Enable</span>
      <el-switch
        :value="getValue('enable_text_shadow',elementProps[screen].enable_text_shadow)"
        :active-color="primaryColor"
        inactive-color="#949494"
        :active-value="true"
        :inactive-value="false"
        @change="updateProps('enable_text_shadow', $event)"
      />
    </div>
    <div class="py-2" v-if="elementProps[screen].enable_text_shadow">
      <span class="setting-title">Shadow Color</span>
      <ColorPicker
        class="mt-2"
        :value="getValue('text_shadow_color',elementProps[screen].text_shadow_color, 'rgb(255, 255, 255)')"
        v-bind:changeColor="($event) => updateProps('text_shadow_color', $event)"
        :primaryColor="primaryColor"
      />
    </div>
    <div class="py-2" v-if="elementProps[screen].enable_text_shadow">
      <span class="setting-title">Horizontal offset</span>
      <Slider 
        :value="getValue('text_shadow_x',elementProps[screen].text_shadow_x)"
        :primaryColor="primaryColor"
        :min="0"
        :max="20"
        v-bind:changeValue="($event) => updateProps('text_shadow_x', $event)"
        unit="px"
      />
    </div>
    <div class="py-2" v-if="elementProps[screen].enable_text_shadow">
      <span class="setting-title">Vertical offset</span>
      <Slider 
        :value="getValue('text_shadow_y',elementProps[screen].text_shadow_y)"
        :primaryColor="primaryColor"
        :min="0"
        :max="20"
        v-bind:changeValue="($event) => updateProps('text_shadow_y', $event)"
        unit="px"
      />
    </div>
    <div class="py-2" v-if="elementProps[screen].enable_text_shadow">
      <span class="setting-title">Blur radius</span>
      <Slider 
        :value="getValue('text_shadow_blur',elementProps[screen].text_shadow_blur)"
        :primaryColor="primaryColor"
        :min="0"
        :max="20"
        v-bind:changeValue="($event) => updateProps('text_shadow_blur', $event)"
        unit="px"
      />
    </div>
  </div>
</template>

<script>
import mixin from '../styleSettings/mixin';
import { allIcons } from '../../../../../constants';
import ToggleButton from '../common/ToggleButton.vue';
import ColorPicker from '../common/ColorPicker.vue';
import Slider from '../common/Slider.vue';
export default {
  mixins: [mixin],
  data() {
    return {
      allIcons
    }
  },
  props: {
    screen: String,
    primaryColor: String,
  },
  components: {
    ToggleButton, ColorPicker, Slider
  },
  methods: {
    updateProps(name, value) {
      const screen = this.$props.screen;
      const data_screen = Object.assign({}, this.elementProps[screen])
      const { desktop, laptop, tablet, mobile } = this.elementProps;
      if(value ==  data_screen[name]) return;
      data_screen[name] = value
      var obj = {
        [screen]: data_screen,
      };
      if (screen == "desktop") {
        if (!Object.keys(laptop).includes(name)) {
          laptop[name] = value;
          obj["laptop"] = laptop;
        }
        if (!Object.keys(tablet).includes(name)) {
          tablet[name] = value;
          obj["tablet"] = tablet;
        }
        if (!Object.keys(mobile).includes(name)) {
          mobile[name] = value;
          obj["mobile"] = mobile;
        }
      }
      this.elementPropsSetter(obj);
    },
  }
};
</script>

<style>
</style>
