<template>
  <header 
    data-label="Header" 
    data-key="header" 
    section="header"
    class="sg-wrap block header-section"
    :id="`element-${node.uuid}`"
    @mouseover="isHovering=true" 
    @mouseout="isHovering=false"
  >
    <div class="header-container" :class="extra_class">
      <div class="logo-container h-100">
        <a :data-href="logo_link || '#'">
          <img class="logo-image" :src="logo_img" alt="Logo" />
        </a>
      </div>
      <div class="mega-menu">
        <ul class="menu-list">
          <li
            v-for="item in items"
            class="menu-item"
            :key="item.id"
          >
            <a href="javascript:void(0);" :data-href="item.href" :style="{color: item.color}" class="menu-label">{{ item.label }}</a>
            <svg v-if="item.items.length > 0" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" stroke-width="1.5" stroke="#000" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path d="M6 9l6 6l6 -6"></path>
            </svg>
            <ul v-if="item.items.length > 0" class="menu-dropdown">
              <li
                v-for="child in item.items"
                :key="child.id"
              >
                <a :style="{color: child.color}" href="javascript:void(0);" :data-href="child.href">{{ child.label }}</a>
              </li>
            </ul>
          </li>

        </ul>
      </div>
      <div class="button-header">
        <slot />
      </div>
    </div>
    <ToolBar
      ref="header_toolbar"
      toolbar_id="header"
      class="container-toolbar"
      :class="{ 'container-toolbar-show': isHovering, 'is-dragging': dragging == node.uuid }"
    ></ToolBar>
    <div v-if="dragging == node.uuid" class="dragging"></div>

    <v-style>
      {{ custom_css }}

      .production #element-{{ node.uuid }} {
        position: {{ position }};
        {{ position == 'sticky' ? 'top: 0px; z-index: 10;' : '' }}
      }

      @media (max-width: 767px){
        #element-{{ node.uuid }} {
          height: {{ getValue('height', 'mobile') }}px;
          {{ getDisplayValue('mobile') }}
          {{ getMarginValue('mobile') }}
          {{ getPaddingValue('mobile') }}
          {{ getBackgroundValue('mobile') }}
          {{ getBorderValue('mobile') }}
          {{ getBorderRadiusValue('mobile') }}
          {{ getOpacityValue('mobile') }}
          {{ getShadowValue('mobile') }}
          {{ getOtherStyle('mobile') }}
        }
        #element-{{ node.uuid }} .header-container {
          gap: {{ getValue('gap', 'mobile') }}px;
        }
        
        #element-{{ node.uuid }} .menu-list {
          {{ !getValue('show_menu', 'mobile') ? 'display: none;' : ''}}
          {{ getValue('alignment', 'mobile') ? `justify-content: ${getValue('alignment', 'mobile')};` : '' }}
        }

        #element-{{ node.uuid }} .menu-list .menu-dropdown {
          {{ ['flex-start', 'center'].includes(getValue('alignment', 'mobile')) ? 'left: 0px;' : '' }}
          {{ getValue('alignment', 'mobile') == 'flex-end' ? 'right: 0px;' : '' }}
        }

        #element-{{ node.uuid }} .menu-list .menu-item {
          transition: all 0.3s ease;
          {{ isNaN(parseInt(getValue('menu_padding_top', 'mobile'))) ? '' : `padding-top: ${getValue('menu_padding_top', 'mobile')}px;` }}
          {{ isNaN(parseInt(getValue('menu_padding_right', 'mobile'))) ? '' : `padding-right: ${getValue('menu_padding_right', 'mobile')}px;` }}
          {{ isNaN(parseInt(getValue('menu_padding_bottom', 'mobile'))) ? '' : `padding-bottom: ${getValue('menu_padding_bottom', 'mobile')}px;` }}
          {{ isNaN(parseInt(getValue('menu_padding_left', 'mobile'))) ? '' : `padding-left: ${getValue('menu_padding_left', 'mobile')}px;` }}
        }

        #element-{{ node.uuid }} .menu-list .menu-label {
          font-size: {{ getValue('font_size', 'mobile') }}px;
          font-weight: {{ getValue('font_weight', 'mobile') }};
          text-transform: {{ getValue('text_transform', 'mobile') }};
          font-style: {{ getValue('font_style', 'mobile') }};
        }

        #element-{{ node.uuid }} .menu-list svg {
          width: {{ getValue('font_size', 'mobile') }}px;
          height: {{ getValue('font_size', 'mobile') }}px;
        }

        #element-{{ node.uuid }} .menu-list .menu-item:hover {
          transition: all 0.3s ease;
          {{ getValue('menu_hover_color', 'mobile') ? `background-color: ${getValue('menu_hover_color', 'mobile')};` : '' }}
        }

        #element-{{ node.uuid }} .menu-list .menu-item .menu-dropdown li:hover {
          {{ getValue('menu_hover_color', 'mobile') ? `background-color: ${getValue('menu_hover_color', 'mobile')};` : '' }}
          transition: all 0.3s ease;
        }

        #element-{{ node.uuid }} .logo-container {
          {{ !getValue('show_logo', 'mobile') ? 'display: none;' : ''}}
        }

        #element-{{ node.uuid }} .button-header {
          {{ !getValue('show_button', 'mobile') ? 'display: none;' : ''}}
          width: {{ getValue('button_width', 'mobile', 80) }}px;
        }

        #element-{{ node.uuid }} .logo-container img {
          width: {{ Number.isNaN(getValue('logo_width','mobile')) ? `${getValue('logo_width','mobile')}px` : `auto` }};
          height: {{ getValue('logo_height', 'mobile') }}{{ getValue('logo_height_type', 'mobile') }};
        }

        #element-{{ node.uuid }} .menu-list .menu-item .menu-dropdown {
          {{ getValue('menu_background_color', 'mobile') ? `background-color: ${getValue('menu_background_color', 'mobile')};` : '#FFF' }}
        }
      }
      @media (max-width: 991px) and (min-width: 768px){
        #element-{{ node.uuid }} {
          height: {{ getValue('height', 'tablet') }}px;
          {{ getDisplayValue('tablet') }}
          {{ getMarginValue('tablet') }}
          {{ getPaddingValue('tablet') }}
          {{ getBackgroundValue('tablet') }}
          {{ getBorderValue('tablet') }}
          {{ getBorderRadiusValue('tablet') }}
          {{ getOpacityValue('tablet') }}
          {{ getShadowValue('tablet') }}
          {{ getOtherStyle('tablet') }}
        }
        #element-{{ node.uuid }} .header-container {
          gap: {{ getValue('gap', 'tablet') }}px;
        }
        
        #element-{{ node.uuid }} .menu-list {
          {{ !getValue('show_menu', 'tablet') ? 'display: none;' : ''}}
          {{ getValue('alignment', 'tablet') ? `justify-content: ${getValue('alignment', 'tablet')};` : '' }}
        }

        #element-{{ node.uuid }} .menu-list .menu-dropdown {
          {{ ['flex-start', 'center'].includes(getValue('alignment', 'tablet')) ? 'left: 0px;' : '' }}
          {{ getValue('alignment', 'tablet') == 'flex-end' ? 'right: 0px;' : '' }}
        }

        #element-{{ node.uuid }} .menu-list .menu-item {
          transition: all 0.3s ease;
          {{ isNaN(parseInt(getValue('menu_padding_top', 'tablet'))) ? '' : `padding-top: ${getValue('menu_padding_top', 'tablet')}px;` }}
          {{ isNaN(parseInt(getValue('menu_padding_right', 'tablet'))) ? '' : `padding-right: ${getValue('menu_padding_right', 'tablet')}px;` }}
          {{ isNaN(parseInt(getValue('menu_padding_bottom', 'tablet'))) ? '' : `padding-bottom: ${getValue('menu_padding_bottom', 'tablet')}px;` }}
          {{ isNaN(parseInt(getValue('menu_padding_left', 'tablet'))) ? '' : `padding-left: ${getValue('menu_padding_left', 'tablet')}px;` }}
        }

        #element-{{ node.uuid }} .menu-list .menu-label {
          font-size: {{ getValue('font_size', 'tablet') }}px;
          font-weight: {{ getValue('font_weight', 'tablet') }};
          text-transform: {{ getValue('text_transform', 'tablet') }};
          font-style: {{ getValue('font_style', 'tablet') }};
        }

        #element-{{ node.uuid }} .menu-list svg {
          width: {{ getValue('font_size', 'tablet') }}px;
          height: {{ getValue('font_size', 'tablet') }}px;
        }

        #element-{{ node.uuid }} .menu-list .menu-item:hover {
          transition: all 0.3s ease;
          {{ getValue('menu_hover_color', 'tablet') ? `background-color: ${getValue('menu_hover_color', 'tablet')};` : '' }}
        }

        #element-{{ node.uuid }} .menu-list .menu-item .menu-dropdown li:hover {
          {{ getValue('menu_hover_color', 'tablet') ? `background-color: ${getValue('menu_hover_color', 'tablet')};` : '' }}
          transition: all 0.3s ease;
        }

        #element-{{ node.uuid }} .logo-container {
          {{ !getValue('show_logo', 'tablet') ? 'display: none;' : ''}}
        }

        #element-{{ node.uuid }} .button-header {
          {{ !getValue('show_button', 'tablet') ? 'display: none;' : ''}}
          width: {{ getValue('button_width', 'tablet', 100) }}px;
        }

        #element-{{ node.uuid }} .logo-container img {
          width: {{ Number.isNaN(getValue('logo_width','tablet')) ? `${getValue('logo_width','tablet')}px` : `auto` }};
          height: {{ getValue('logo_height', 'tablet') }}{{ getValue('logo_height_type', 'tablet') }};
        }
        #element-{{ node.uuid }} .menu-list .menu-item .menu-dropdown {
          {{ getValue('menu_background_color', 'tablet') ? `background-color: ${getValue('menu_background_color', 'tablet')};` : '#FFF' }}
        }
      }
      @media (max-width: 1199px) and (min-width: 992px){
        #element-{{ node.uuid }} {
          height: {{ getValue('height', 'laptop') }}px;
          {{ getDisplayValue('laptop') }}
          {{ getMarginValue('laptop') }}
          {{ getPaddingValue('laptop') }}
          {{ getBackgroundValue('laptop') }}
          {{ getBorderValue('laptop') }}
          {{ getBorderRadiusValue('laptop') }}
          {{ getOpacityValue('laptop') }}
          {{ getShadowValue('laptop') }}
          {{ getOtherStyle('laptop') }}
        }
        #element-{{ node.uuid }} .header-container {
          gap: {{ getValue('gap', 'laptop') }}px;
        }
        
        #element-{{ node.uuid }} .menu-list {
          {{ !getValue('show_menu', 'laptop') ? 'display: none;' : ''}}
          {{ getValue('alignment', 'laptop') ? `justify-content: ${getValue('alignment', 'laptop')};` : '' }}
        }

        #element-{{ node.uuid }} .menu-list .menu-dropdown {
          {{ ['flex-start', 'center'].includes(getValue('alignment', 'laptop')) ? 'left: 0px;' : '' }}
          {{ getValue('alignment', 'laptop') == 'flex-end' ? 'right: 0px;' : '' }}
        }

        #element-{{ node.uuid }} .menu-list .menu-item {
          transition: all 0.3s ease;
          {{ isNaN(parseInt(getValue('menu_padding_top', 'laptop'))) ? '' : `padding-top: ${getValue('menu_padding_top', 'laptop')}px;` }}
          {{ isNaN(parseInt(getValue('menu_padding_right', 'laptop'))) ? '' : `padding-right: ${getValue('menu_padding_right', 'laptop')}px;` }}
          {{ isNaN(parseInt(getValue('menu_padding_bottom', 'laptop'))) ? '' : `padding-bottom: ${getValue('menu_padding_bottom', 'laptop')}px;` }}
          {{ isNaN(parseInt(getValue('menu_padding_left', 'laptop'))) ? '' : `padding-left: ${getValue('menu_padding_left', 'laptop')}px;` }}
        }

        #element-{{ node.uuid }} .menu-list .menu-label {
          font-size: {{ getValue('font_size', 'laptop') }}px;
          font-weight: {{ getValue('font_weight', 'laptop') }};
          text-transform: {{ getValue('text_transform', 'laptop') }};
          font-style: {{ getValue('font_style', 'laptop') }};
        }

        #element-{{ node.uuid }} .menu-list svg {
          width: {{ getValue('font_size', 'laptop') }}px;
          height: {{ getValue('font_size', 'laptop') }}px;
        }

        #element-{{ node.uuid }} .menu-list .menu-item:hover {
          transition: all 0.3s ease;
          {{ getValue('menu_hover_color', 'laptop') ? `background-color: ${getValue('menu_hover_color', 'laptop')};` : '' }}
        }

        #element-{{ node.uuid }} .menu-list .menu-item .menu-dropdown li:hover {
          {{ getValue('menu_hover_color', 'laptop') ? `background-color: ${getValue('menu_hover_color', 'laptop')};` : '' }}
          transition: all 0.3s ease;
        }

        #element-{{ node.uuid }} .logo-container {
          {{ !getValue('show_logo', 'laptop') ? 'display: none;' : ''}}
        }

        #element-{{ node.uuid }} .button-header {
          {{ !getValue('show_button', 'laptop') ? 'display: none;' : ''}}
          width: {{ getValue('button_width', 'laptop', 200) }}px;
        }

        #element-{{ node.uuid }} .logo-container img {
          width: {{ Number.isNaN(getValue('logo_width','laptop')) ? `${getValue('logo_width','laptop')}px` : `auto` }};
          height: {{ getValue('logo_height', 'laptop') }}{{ getValue('logo_height_type', 'laptop') }};
        }
        #element-{{ node.uuid }} .menu-list .menu-item .menu-dropdown {
          {{ getValue('menu_background_color', 'laptop') ? `background-color: ${getValue('menu_background_color', 'laptop')};` : '#FFF' }}
        }
      }
      @media (min-width: 1200px){
        #element-{{ node.uuid }} {
          height: {{ getValue('height', 'desktop') }}px;
          {{ getDisplayValue('desktop') }}
          {{ getMarginValue('desktop') }}
          {{ getPaddingValue('desktop') }}
          {{ getBackgroundValue('desktop') }}
          {{ getBorderValue('desktop') }}
          {{ getBorderRadiusValue('desktop') }}
          {{ getOpacityValue('desktop') }}
          {{ getShadowValue('desktop') }}
          {{ getOtherStyle('desktop') }}
        }
        #element-{{ node.uuid }} .header-container {
          gap: {{ getValue('gap', 'desktop') }}px;
        }
        
        #element-{{ node.uuid }} .menu-list {
          {{ !getValue('show_menu', 'desktop') ? 'display: none;' : ''}}
          {{ getValue('alignment', 'desktop') ? `justify-content: ${getValue('alignment', 'desktop')};` : '' }}
        }

        #element-{{ node.uuid }} .menu-list .menu-dropdown {
          {{ ['flex-start', 'center'].includes(getValue('alignment', 'desktop')) ? 'left: 0px;' : '' }}
          {{ getValue('alignment', 'desktop') == 'flex-end' ? 'right: 0px;' : '' }}
        }

        #element-{{ node.uuid }} .menu-list .menu-item {
          transition: all 0.3s ease;
          {{ isNaN(parseInt(getValue('menu_padding_top', 'desktop'))) ? '' : `padding-top: ${getValue('menu_padding_top', 'desktop')}px;` }}
          {{ isNaN(parseInt(getValue('menu_padding_right', 'desktop'))) ? '' : `padding-right: ${getValue('menu_padding_right', 'desktop')}px;` }}
          {{ isNaN(parseInt(getValue('menu_padding_bottom', 'desktop'))) ? '' : `padding-bottom: ${getValue('menu_padding_bottom', 'desktop')}px;` }}
          {{ isNaN(parseInt(getValue('menu_padding_left', 'desktop'))) ? '' : `padding-left: ${getValue('menu_padding_left', 'desktop')}px;` }}
        }

        #element-{{ node.uuid }} .menu-list .menu-label {
          font-size: {{ getValue('font_size', 'desktop') }}px;
          font-weight: {{ getValue('font_weight', 'desktop') }};
          text-transform: {{ getValue('text_transform', 'desktop') }};
          font-style: {{ getValue('font_style', 'desktop') }};
        }

        #element-{{ node.uuid }} .menu-list svg {
          width: {{ getValue('font_size', 'desktop') }}px;
          height: {{ getValue('font_size', 'desktop') }}px;
        }

        #element-{{ node.uuid }} .menu-list .menu-item:hover {
          transition: all 0.3s ease;
          {{ getValue('menu_hover_color', 'desktop') ? `background-color: ${getValue('menu_hover_color', 'desktop')};` : '' }}
        }

        #element-{{ node.uuid }} .menu-list .menu-item .menu-dropdown li:hover {
          {{ getValue('menu_hover_color', 'desktop') ? `background-color: ${getValue('menu_hover_color', 'desktop')};` : '' }}
          transition: all 0.3s ease;
        }

        #element-{{ node.uuid }} .logo-container {
          {{ !getValue('show_logo', 'desktop') ? 'display: none;' : ''}}
        }

        #element-{{ node.uuid }} .button-header {
          {{ !getValue('show_button', 'desktop') ? 'display: none;' : ''}}
          width: {{ getValue('button_width', 'desktop', 200) }}px;
        }

        #element-{{ node.uuid }} .logo-container img {
          width: {{ Number.isNaN(getValue('logo_width','desktop')) ? `${getValue('logo_width','desktop')}px` : `auto` }};
          height: {{ getValue('logo_height', 'desktop') }}{{ getValue('logo_height_type', 'desktop') }};
        }

        #element-{{ node.uuid }} .menu-list .menu-item .menu-dropdown {
          {{ getValue('menu_background_color', 'desktop') ? `background-color: ${getValue('menu_background_color', 'desktop')};` : '#FFF' }}
        }
      }
    </v-style>
  </header>
</template>

<script>
import Visibility from "../../elements/designSettings/Visibility.vue";
import Spacing from "../../elements/designSettings/Spacing.vue";
import Background from "../../elements/designSettings/Background.vue";
import Border from "../../elements/designSettings/Border.vue";
import Corner from "../../elements/designSettings/Corner.vue";
import Shadow from "../../elements/designSettings/Shadow.vue";
import Opacity from "../../elements/designSettings/Opacity.vue";

import Height from "./settings/Height.vue";
import Logo from "./settings/Logo.vue";
import Button from "./settings/Button.vue";
import Menu from "./settings/Menu.vue";
import Style from './settings/Style.vue';

import elementStyleMixin from "../../elements/elementStyleMixin";
import ToolBar from "../../elements/utils/ToolBar.vue";
import { designStyles, otherProps, otherStyles, actionTypes, makeId } from "@/constants";
import ExtraClass from "../../elements/otherSettings/ExtraClass.vue";
import CustomCSS from "../../elements/styleSettings/CustomCSS.vue";
import Animations from "../../elements/designSettings/Animations.vue";
import HeaderButton from "./HeaderButton.vue";

export default {
  mixins: [elementStyleMixin],
  components: { ToolBar, HeaderButton },
  watch: {
    dragging: function (newValue, oldValue) {
      var $iframe = $("#designEditor").contents();
      var $element = $iframe.find(`#element-${this.node.uuid}`);
      if (newValue && newValue == this.node.uuid) {
        setTimeout(() => {
          $element.hide();
        },100);
      }else if (oldValue == this.node.uuid){
        $element.show();
      }
    },
  },
  computed: {
    dragging: function () {
      return this.editor?.draggedNode?.uuid
    },
  },
  props: {
    ...otherProps,
    position: String,
    extra_class: String,
    position: String,
    logo_img: String,
    logo_link: String,
    desktop: Object,
    laptop: Object,
    tablet: Object,
    mobile: Object,
    items: Array,
  },
  inject: ["node",'editor'],
  methods: {
    
  },
  data() {
    return {
      isHovering: false,
    }
  },
  craft: {
    defaultProps: {
      ...otherStyles,
      logo_img: 'https://d1g1nj7c6ilt37.cloudfront.net/upload_file/66eaa872d3320ff114999838acc996_logo.d0c351b4.svg',
      logo_link: '#',
      position: 'sticky',
      items: [
        {
          id: makeId(6), 
          label: 'Home', 
          href: '#', 
          color: '#000',
          items: [],
        },
        {
          id: makeId(6), 
          label: 'Catalogs', 
          href: '#', 
          color: '#000',
          items: [
            {
              id: makeId(6),
              label: 'New Arrivals',
              href: '#',
              color: '#000'
            },
            {
              id: makeId(6),
              label: 'Collections',
              href: '#',
              color: '#000'
            },
            {
              id: makeId(6),
              label: 'Best Sellers',
              href: '#',
              color: '#000'
            }
          ]
        }
      ],
      desktop: {
        ...designStyles,
        height: 60,
        gap: 8,
        show_logo: true,
        show_menu: true,
        show_button: true,
        button_width: 200,
        logo_height: "60",
        logo_height_type: 'px',
        logo_width: 'auto',
        padding_right: 20,
        padding_left: 20,
        alignment: 'flex-start',
        font_size: 16,
        font_weight: '600',
        text_transform: 'capitalize',
        font_style: 'normal',
        menu_padding_top: 12,
        menu_padding_right: 16,
        menu_padding_bottom: 12,
        menu_padding_left: 16,
        menu_hover_color: 'rgb(217, 221, 228)',
        menu_background_color: 'rgb(255, 255, 255)'
      },
      laptop: {
        ...designStyles,
        button_width: 200,
      },
      tablet: {
        ...designStyles,
        button_width: 100,
      },
      mobile: {
        ...designStyles,
        button_width: 80,
      },
      extra_class: "",
      elementStyle: {
      }
    },
    settings: {
      settings: {
        Height: { Height, multi_screen: true },
        Style,
        Logo: { Logo, multi_screen: true },
        Menu: { Menu, multi_screen: true },
        Button: { Button, multi_screen: true },
        "Extra Class": ExtraClass,
        "Custom CSS": CustomCSS,
      },
      design: {
        Visibility,
        Spacing: { Spacing, multi_screen: true },
        Background: { Background, multi_screen: true },
        Border: { Border, multi_screen: true },
        Corner: { Corner, multi_screen: true },
        Shadow: { Shadow, multi_screen: true },
        Opacity: { Opacity, multi_screen: true },
      },
      others: {
        Animations: { Animations, actions: actionTypes }
      }
    },
  },
};
</script>
