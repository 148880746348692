<template>
    <div class="preview-drag" id="preview-drag" 
    :class="editor?.draggedNode?.componentName?'show':''"
    >
        <span>{{editor?.draggedNode?.componentName}}</span>
    </div>
  </template>
  
  <script>
  
  export default {
    inject: ["editor"],
    computed:{
        show(){
          return this.editor.isDraggable && editor?.draggedNode?.componentName
        }
    },
    props: {
    },
    methods: {
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .preview-drag {
    position: absolute;
    display: none;
    background-color: #0460e5;
    pointer-events: none;
    z-index: 999999;
    border-radius: 5px;
    padding: 5px;
  }
  .preview-drag span{
    color: white;
    font-weight: bold;
    font-size: 18px;
  }
  .preview-drag.show{
    display: block ;
  }
  </style>
  