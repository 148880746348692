<template>
  <div
    data-label="(P) Vendor"
    data-key="(p) vendor"
    data-parent="product"
    class="sg-wrap module"
    :id="`element-${node.uuid}`"
    @mouseover="isHovering=true" @mouseout="isHovering=false"
    :class="{ 'dragging': dragging == node.uuid }"

  >
    <div
      class="sg-module"
      :class="extra_class"
    >
        <component :is="headingType" class="sg_product-vendor" >
          <span class="sg_vendor-prefix" v-if="show_label">{{label}}</span>
          <span class="sg_vendor-value">{{product?.vendor||''}}</span>
        </component>
    </div>

    <ToolBar ref="(p)vendor" 
      :toolbar_id="`(p)vendor`"
     class="container-toolbar" 
     :class="{ 'container-toolbar-show': isHovering, 'is-dragging': dragging == node.uuid }"
     >
     </ToolBar>


    <v-style> {{ custom_css }}
      
      @media (max-width: 767px){ 
        #element-{{ node.uuid }} {
          {{ getDisplayValue('mobile') }}
          {{ getMarginValue('mobile') }}
        }
        #element-{{ node.uuid }} .sg_product-vendor {
          line-height: {{ getValue('line_height','mobile') }}em;
          {{ getValue('font_size', 'mobile') > 0 ? `font-size: ${getValue('font_size','mobile')}px;` : '' }}
          {{ getValue('color', 'mobile') ? `color: ${getValue('color','mobile')};` : '' }}
          letter-spacing: {{ getValue('letter_spacing','mobile') }}px;
          font-family: {{ getValue('font_family','mobile') }};
          text-transform: {{ getValue('text_transform','mobile') }};
          {{ getPaddingValue('mobile') }}
          {{ getBackgroundValue('mobile') }}
          {{ getBorderValue('mobile') }}
          {{ getBorderRadiusValue('mobile') }}
          {{ getOpacityValue('mobile') }}
          {{ getShadowValue('mobile') }}
          {{ getOtherStyle('mobile') }}
        }
        #element-{{ node.uuid }} .sg-module {
          text-align: {{ getValue('alignment','mobile') }};
        }
      } 
      @media (max-width: 991px) and (min-width: 768px){ 
        #element-{{ node.uuid }} {
          {{ getDisplayValue('tablet') }}
          {{ getMarginValue('tablet') }}
        }
        #element-{{ node.uuid }} .sg_product-vendor {
          line-height: {{ getValue('line_height','tablet') }}em;
          {{ getValue('font_size', 'tablet') > 0 ? `font-size: ${getValue('font_size','tablet')}px;` : '' }}
          {{ getValue('color', 'tablet') ? `color: ${getValue('color','tablet')};` : '' }}
          letter-spacing: {{ getValue('letter_spacing','tablet') }}px;
          font-family: {{ getValue('font_family','tablet') }};
          text-transform: {{ getValue('text_transform','tablet') }};
          {{ getPaddingValue('tablet') }}
          {{ getBackgroundValue('tablet') }}
          {{ getBorderValue('tablet') }}
          {{ getBorderRadiusValue('tablet') }}
          {{ getOpacityValue('tablet') }}
          {{ getShadowValue('tablet') }}
          {{ getOtherStyle('tablet') }}
        }
        #element-{{ node.uuid }} .sg-module {
          text-align: {{ getValue('alignment','tablet') }};
        }
      } 
      @media (max-width: 1199px) and (min-width: 992px){ 
        #element-{{ node.uuid }} {
          {{ getDisplayValue('laptop') }}
          {{ getMarginValue('laptop') }}
        }
        #element-{{ node.uuid }} .sg_product-vendor {
          line-height: {{ getValue('line_height','laptop') }}em;
          {{ getValue('font_size', 'laptop') > 0 ? `font-size: ${getValue('font_size','laptop')}px;` : '' }}
          {{ getValue('color', 'laptop') ? `color: ${getValue('color','laptop')};` : '' }}
          letter-spacing: {{ getValue('letter_spacing','laptop') }}px;
          font-family: {{ getValue('font_family','laptop') }};
          text-transform: {{ getValue('text_transform','laptop') }};
          {{ getPaddingValue('laptop') }}
          {{ getBackgroundValue('laptop') }}
          {{ getBorderValue('laptop') }}
          {{ getBorderRadiusValue('laptop') }}
          {{ getOpacityValue('laptop') }}
          {{ getShadowValue('laptop') }}
          {{ getOtherStyle('laptop') }}
        }
        #element-{{ node.uuid }} .sg-module {
          text-align: {{ getValue('alignment','laptop') }};
        }
      } 
      @media (min-width: 1200px){ 
        #element-{{ node.uuid }} {
          {{ getDisplayValue('desktop') }}
          {{ getMarginValue('desktop') }}
        }
        #element-{{ node.uuid }} .sg_product-vendor {
          {{ getValue('font_size', 'desktop') > 0 ? `font-size: ${getValue('font_size','desktop')}px;` : '' }}
          {{ getValue('color', 'desktop') ? `color: ${getValue('color','desktop')};` : '' }}
          line-height: {{ desktop.line_height }}em;
          letter-spacing: {{ desktop.letter_spacing }}px;
          font-family: {{ desktop.font_family }};
          text-transform: {{ desktop.text_transform }};
          {{ getPaddingValue('desktop') }}
          {{ getBackgroundValue('desktop') }}
          {{ getBorderValue('desktop') }}
          {{ getBorderRadiusValue('desktop') }}
          {{ getOpacityValue('desktop') }}
          {{ getShadowValue('desktop') }}
          {{ getOtherStyle('desktop') }}
        }
        #element-{{ node.uuid }} .sg-module {
          text-align: {{ desktop.alignment }};
        }
      }
    </v-style>
  </div>
</template>

<script>
import ToolBar from '../../elements/utils/ToolBar.vue';

import elementStyleMixin from "../../elements/elementStyleMixin";
import ExtraClass from "../../elements/otherSettings/ExtraClass.vue"; import CustomCSS from '../../elements/styleSettings/CustomCSS.vue';
import TitleTag from "./settings/TitleTag.vue";
import AlignmentBase from "../../elements/styleSettings/AlignmentBase.vue";
import Typography from "../../elements/styleSettings/Typography.vue";
import LabelText from "./settings/LabelText.vue";

import Visibility from "../../elements/designSettings/Visibility.vue";
import Spacing from "../../elements/designSettings/Spacing.vue";
import Background from "../../elements/designSettings/Background.vue";
import Border from "../../elements/designSettings/Border.vue";
import Corner from '../../elements/designSettings/Corner.vue';
import Shadow from '../../elements/designSettings/Shadow.vue';
import Opacity from '../../elements/designSettings/Opacity.vue';
import { designStyles, otherProps, otherStyles, actionTypes } from '@/constants';
import Animations from '../../elements/designSettings/Animations.vue';

export default {
  components: {ToolBar},
  data() {
    return {
      isHovering: false
    }
  },
  inject: ["node","product",'editor'],
  mixins: [elementStyleMixin],
  props: {
    
    extra_class: String, ...otherProps,
    component:String,
    content:String,
    headingType:String,
    parent:String,
    show_label:Boolean,
    label: String,
    vendor:String,
    desktop: Object,
    laptop: Object,
    tablet: Object,
    mobile: Object,
  },
  computed: {
    dragging: function () {
      return this.editor?.draggedNode?.uuid
    },
  },
  craft: {
    defaultProps: { ...otherStyles,
      headingType: "h3",
      backgroundType: "color",
      label: "Vendor:",
      vendor:"vuacaheo",
      parent:"Product",
      show_label:true,
      typographyActives: [
        'font-size',
        'line-height',
        'letter-spacing',
        'color',
        'font-family',
        'text-transform'
      ],
      desktop: {
        ...designStyles,
        font_size: 17,
        line_height: 1.5,
        letter_spacing: 0,
        color: 'rgb(68, 91, 197)',
        font_family: 'inherit',
        text_transform: 'none',
        alignment: 'left'
      },
      laptop: {
        ...designStyles,
      },
      mobile: {
        ...designStyles,
      },
      tablet: {
        ...designStyles,
      },
      elementStyle: {
      }
    },
    settings: {
      settings: {
        "Title Tag":TitleTag,
        Typography: { Typography, multi_screen: true},
        "Label Text": LabelText,
        "Alignment":{ Alignment: AlignmentBase, multi_screen: true },
        "Extra Class": ExtraClass, "Custom CSS": CustomCSS,
      },
      design: {
        Visibility,
        Spacing: { Spacing, multi_screen: true },
        Background: { Background, multi_screen: true },
        Border: { Border, multi_screen: true },
        Corner: { Corner, multi_screen: true },
        Shadow: { Shadow, multi_screen: true },
        Opacity: { Opacity, multi_screen: true },
      },
      others: {
        Animations: { Animations, actions: actionTypes }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
