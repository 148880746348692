<template>
  <div label-position="top" size="" :model="elementProps">
    <div class="d-flex flex-column py-2">
      <span class="setting-title">Border Color</span>
      <ColorPicker
        class="mt-2"
        :value="elementProps.border_color"
        v-bind:changeColor="($event) => updateProps('border_color', $event)"
        :primaryColor="primaryColor"
      />
    </div>
    <div class="d-flex flex-column py-2">
      <span class="setting-title">Border Size</span>
      <Slider 
        class="mt-2"
        :primaryColor="primaryColor"
        unit="px"
        :min="0"
        :max="10"
        :step="1"
        v-bind:changeValue="($event) => updateProps('border_size', $event)"
        :value="elementProps.border_size"
        :onlyInteger="true"
      />
    </div>
  </div>
</template>

<script>
import mixin from "../../../elements/styleSettings/mixin";
import ColorPicker from "../../../elements/common/ColorPicker.vue";
import Slider from "../../../elements/common/Slider.vue";
export default {
  mixins: [mixin],
  components: { Slider, ColorPicker },
  props: {
    primaryColor: String
  },
  methods: {
    updateProps(name, value) {
      this.elementPropsSetter({ [name]: value });
    },
  },
};
</script>

<style scoped>
</style>
