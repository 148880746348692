<template>
  <div label-position="top" size="mini" :model="elementProps">
    <div class="d-flex flex-column py-2">
      <span class="setting-title">Background Type</span>
      <ToggleButton
        :primaryColor="primaryColor"
        :value="getValue('background_type', elementProps[screen].background_type)"
        v-bind:changeValue="($event) => updateProps(screen, 'background_type', $event)"
        :options="[
          {
            label: 'Color & Image',
            value: 'color_image',
          },
          {
            label: 'Gradient',
            value: 'gradient',
          },
        ]"
      />
    </div>
    <div v-if="getValue('background_type', elementProps[screen].background_type) == 'gradient'">
      <div class="d-flex flex-column py-2">
        <span class="setting-title">Color</span>
        <ColorPicker
          :primaryColor="primaryColor"
          class="mt-2"
          :value="getValue('gradient_color', elementProps[screen].gradient_color)"
          v-bind:changeColor="($event) => updateProps(screen, 'gradient_color', $event)"
        />
      </div>
      <div class="d-flex flex-column py-2">
        <span class="setting-title">Location</span>
        <Slider 
          class="mt-2"
          :value="getValue('gradient_location',elementProps[screen].gradient_location)"
          :primaryColor="primaryColor"
          :min="0"
          :max="100"
          :step="1"
          unit="%"
          v-bind:changeValue="($event) => updateProps(screen, 'gradient_location', $event)"
          :onlyInteger="true"
        />
      </div>
      <div class="d-flex flex-column py-2">
        <span class="setting-title">Second Color</span>
        <ColorPicker 
          :primaryColor="primaryColor"
          class="mt-2"
          :value="getValue('gradient_second_color',elementProps[screen].gradient_second_color)"
          v-bind:changeColor="($event) => updateProps(screen, 'gradient_second_color', $event)"
        />
      </div>
      <div class="d-flex flex-column py-2">
        <span class="setting-title">Location</span>
        <Slider 
          class="mt-2"
          :value="getValue('gradient_second_location',elementProps[screen].gradient_second_location)"
          :primaryColor="primaryColor"
          :min="0"
          :max="100"
          :step="1"
          unit="%"
          v-bind:changeValue="($event) => updateProps(screen, 'gradient_second_location', $event)"
          :onlyInteger="true"
        />
      </div>
      <div class="d-flex flex-column py-2">
        <span class="setting-title">Type</span>
        <Select 
          :primaryColor="primaryColor"
          class="mt-2"
          :value="getValue('gradient_type',elementProps[screen].gradient_type)"
          v-bind:changeValue="($event) => updateProps(screen, 'gradient_type', $event)"
          :options="[
            {
              value: 'linear',
              label: 'Linear',
            },
            {
              value: 'radial',
              label: 'Radial',
            }
          ]"
        />
      </div>
      <div class="d-flex flex-column py-2" v-if="getValue('gradient_type',elementProps[screen].gradient_type) == 'linear'">
        <span class="setting-title">Angle</span>
        <Slider 
          class="mt-2"
          :value="getValue('gradient_angle',elementProps[screen].gradient_angle)"
          :primaryColor="primaryColor"
          :min="0"
          :max="360"
          :step="1"
          unit="°"
          v-bind:changeValue="($event) => updateProps(screen, 'gradient_angle', $event)"
          :onlyInteger="true"
        />
      </div>
      <div class="d-flex flex-column py-2" v-if="getValue('gradient_type', elementProps[screen].gradient_type) == 'radial'">
        <span class="setting-title">Position</span>
        <Select 
          :primaryColor="primaryColor"
          class="mt-2"
          :value="getValue('gradient_position', elementProps[screen].gradient_position)"
          v-bind:changeValue="($event) => updateProps(screen, 'gradient_position', $event)"
          :options="[
            { value: 'at center center', label: 'Center Center' },
            { value: 'at center left', label: 'Center Left' },
            { value: 'at center right', label: 'Center Right' },
            { value: 'at top center', label: 'Top Center' },
            { value: 'at top left', label: 'Top Left' },
            { value: 'at top right', label: 'Top Right' },
            { value: 'at bottom center', label: 'Bottom Center' },
            { value: 'at bottom left', label: 'Bottom Left' },
            { value: 'at bottom right', label: 'Bottom Right' }
          ]"
        />
      </div>
    </div>
    <div v-if="getValue('background_type', elementProps[screen].background_type) == 'color_image'">
      <div class="d-flex flex-column py-2">
        <span class="setting-title">Color</span>
        <ColorPicker
          :primaryColor="primaryColor"
          class="mt-2"
          :value="getValue('background_color',elementProps[screen].background_color)"
          v-bind:changeColor="($event) => updateProps(screen, 'background_color', $event)"
        />
      </div>
      <div class="d-flex flex-column py-2">
        <span class="setting-title">Background Image</span>
        <Upload
          :value="getValue('background_image',elementProps[screen].background_image)"
          v-bind:changeValue="
            ($event) => updateProps(screen, 'background_image', $event)
          "
        />
      </div>
      <div class="d-flex flex-column py-2">
        <span class="setting-title">Image Source</span>
        <el-input
          class="mt-2"
          placeholder="Link"
          :value="getValue('background_image',elementProps[screen].background_image)"
          @input="updateProps(screen, 'background_image', $event)"
          size="medium"
        />
      </div>
      <div class="d-flex flex-column py-2">
        <span class="setting-title">Background Size</span>
        <ToggleButton
          :primaryColor="primaryColor"
          :value="getValue('background_size',elementProps[screen].background_size)"
          v-bind:changeValue="
            ($event) => updateProps(screen, 'background_size', $event)
          "
          :options="[
            {
              label: 'Cover',
              value: 'cover',
            },
            {
              label: 'Contain',
              value: 'contain',
            },
          ]"
        />
      </div>
      <div class="d-flex flex-column py-2">
        <span class="setting-title">Background Position</span>
        <div class="mt-2 row square-toggle">
          <div
            class="item"
            :class="{
              active: getValue('background_position',elementProps[screen].background_position) == 'left top',
            }"
            @click="
              () => updateProps(screen, 'background_position', 'left top')
            "
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="#FFFFFF"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path d="M8 16v-8h8"></path>
            </svg>
          </div>
          <div
            class="item"
            :class="{
              active: getValue('background_position',elementProps[screen].background_position) == 'center top',
            }"
            @click="
              () => updateProps(screen, 'background_position', 'center top')
            "
          >
            <svg
              style="margin-top: -10px"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="#FFFFFF"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <line x1="5" y1="12" x2="19" y2="12"></line>
            </svg>
          </div>
          <div
            class="item"
            :class="{
              active: getValue('background_position',elementProps[screen].background_position) == 'right top',
            }"
            @click="
              () => updateProps(screen, 'background_position', 'right top')
            "
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="#FFFFFF"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path d="M8 8h8v8"></path>
            </svg>
          </div>
          <div
            class="item"
            :class="{
              active: getValue('background_position',elementProps[screen].background_position) == 'left center',
            }"
            @click="
              () => updateProps(screen, 'background_position', 'left center')
            "
          >
            <svg
              style="margin-left: -10px"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="#FFFFFF"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path d="M12 5v14"></path>
            </svg>
          </div>
          <div
            class="item"
            :class="{
              active:
                getValue('background_position',elementProps[screen].background_position) == 'center center',
            }"
            @click="
              () => updateProps(screen, 'background_position', 'center center')
            "
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="#FFFFFF"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <circle cx="12" cy="12" r="5" fill="#FFFFFF"></circle>
            </svg>
          </div>
          <div
            class="item"
            :class="{
              active:
                getValue('background_position',elementProps[screen].background_position) == 'right center',
            }"
            @click="
              () => updateProps(screen, 'background_position', 'right center')
            "
          >
            <svg
              style="margin-left: 10px"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="#FFFFFF"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path d="M12 5v14"></path>
            </svg>
          </div>
          <div
            class="item"
            :class="{
              active: getValue('background_position',elementProps[screen].background_position) == 'left bottom',
            }"
            @click="
              () => updateProps(screen, 'background_position', 'left bottom')
            "
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="#FFFFFF"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path d="M8 8v8h8"></path>
            </svg>
          </div>
          <div
            class="item"
            :class="{
              active:
                getValue('background_position',elementProps[screen].background_position) == 'center bottom',
            }"
            @click="
              () => updateProps(screen, 'background_position', 'center bottom')
            "
          >
            <svg
              style="margin-top: 10px"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="#FFFFFF"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <line x1="5" y1="12" x2="19" y2="12"></line>
            </svg>
          </div>
          <div
            class="item"
            :class="{
              active:
                getValue('background_position',elementProps[screen].background_position) == 'right bottom',
            }"
            @click="
              () => updateProps(screen, 'background_position', 'right bottom')
            "
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="#FFFFFF"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path d="M16 8v8h-8"></path>
            </svg>
          </div>
        </div>
      </div>
      <div class="d-flex flex-column py-2">
        <span class="setting-title">Background Repeat</span>
        <Select
          :primaryColor="primaryColor"
          class="mt-2"
          :value="getValue('background_repeat',elementProps[screen].background_repeat)"
          v-bind:changeValue="
            ($event) => updateProps(screen, 'background_repeat', $event)
          "
          :options="[
            {
              value: 'repeat',
              label: 'Repeat X & Y',
            },
            {
              value: 'repeat-x',
              label: 'Repeat X',
            },
            {
              value: 'repeat-y',
              label: 'Repeat Y',
            },
            {
              value: 'no-repeat',
              label: 'No Repeat',
            },
          ]"
        />
      </div>
      <div class="d-flex flex-column py-2">
        <span class="setting-title">Background Attachment</span>
        <ToggleButton
          :primaryColor="primaryColor"
          :value="getValue('background_attachment',elementProps[screen].background_attachment)"
          v-bind:changeValue="
            ($event) => updateProps(screen, 'background_attachment', $event)
          "
          :options="[
            {
              value: 'scroll',
              label: 'Scroll',
            },
            {
              value: 'fixed',
              label: 'Fixed',
            },
            {
              value: 'local',
              label: 'Local',
            },
          ]"
        />
      </div>
    </div>
  </div>
</template>

<script>
import mixin from "../styleSettings/mixin";
import ToggleButton from "../common/ToggleButton.vue";
import ColorPicker from "../common/ColorPicker.vue";
import Upload from "../common/Upload.vue";
import Select from "../common/Select.vue";
import Slider from "../common/Slider.vue";

export default {
  mixins: [mixin],
  components: {
    ToggleButton,
    ColorPicker,
    Upload,
    Select,
    Slider
  },
  props: {
    screen: String,
    primaryColor: String,
  },
  methods: {
    updateProps(screen, name, value) {
      const data_screen = Object.assign({}, this.elementProps[screen]);
      if(value == data_screen[name]) return;
      if (name == 'background_image') {
        if (!data_screen['background_size']) {
          data_screen['background_size'] = 'cover'
        }
        if (!data_screen['background_position']) {
          data_screen['background_position'] = 'center center'
        }
      }
      data_screen[name] = value;
      var obj = {
        [screen]: data_screen
      }
      this.elementPropsSetter(obj);
    },
  },
};
</script>

<style lang="scss">
.square-toggle {
  width: 60%;
  margin-left: 0px !important;
  margin-right: 0px !important;
  .item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 30%;
    flex: 0 0 30%;
    aspect-ratio: 1/1;
    margin: 1px;
    background-color: #666666;
  }
  .item.active {
    background-color: transparent;
  }
}
</style>
