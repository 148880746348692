<template>
  <div label-position="top" size="mini" :model="elementProps">
    <div class="d-flex flex-column py-2">
      <span class="setting-title">Section name</span>
      <Input
        class="mt-2"
        type="text"
        :value="elementProps.name"
        :primaryColor="primaryColor"
        v-bind:changeValue="($event) => updateProps('name', $event)"
      />
    </div>

  </div>
</template>

<script>
import mixin from './mixin';
import Input from '../common/Input.vue';
export default {
  mixins: [mixin],
  components: {
    Input
  },
  props: {
    primaryColor: String,
  },
  methods: {
    updateProps(name, value) {
      this.elementPropsSetter({ [name]: value });
    },
  },
};
</script>
