<template>
  <el-form label-position="top" size="small" :model="elementProps">
    <div class="d-flex flex-column py-2">
      <el-input
        :disabled="true"
        :value="elementProps.collection_name"
      />
      <el-button
        icon="el-icon-aim"
        class="pick-btn mt-2"
        @click="() => openModalCollection()"
      >
        Pick a Collection
      </el-button>
    </div>
  </el-form>
</template>

<script>
import mixin from "../../../elements/styleSettings/mixin";

export default {
  mixins: [mixin],
  components: {  },
  props: {
    collection: Object
  },
  watch: {
    collection: function (newValue, oldValue) {
      this.elementPropsSetter({
        collection_name: newValue.title,
        collection_id: newValue.collection_id,
        handle:  newValue.slug,
      })
    }
  },
  methods: {
    updateProps(name, value) {
      if(value ==  this.elementProps[name]) return;

      this.elementPropsSetter({ [name]: value });
    },
    openModalCollection() {
      this.$bus.emit("showCollectionPicker", this.node);
    }
  },
};
</script>

<style lang="scss" scoped>
  .pick-btn {
    background-color: #494949;
    color: #F4F4F4;
    border-color: #494949;
    &:hover {
      background-color: #555555;
    }
  }
</style>
