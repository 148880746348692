var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"label-position":"top","size":"small","model":_vm.elementProps}},[_c('div',{staticClass:"d-flex flex-column py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Remaining Quantity Source")]),_c('ToggleButton',{staticClass:"mt-2",attrs:{"value":_vm.elementProps.quantity_source,"changeValue":($event) => _vm.updateProps('quantity_source', $event),"options":[
        {
          label: 'Auto',
          value: 'auto',
        },
        {
          label: 'Manual',
          value: 'manual',
        },
      ]}})],1),(_vm.elementProps.quantity_source == 'manual')?_c('div',{staticClass:"d-flex flex-column py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Remaining Quantity")]),_c('el-input',{staticClass:"mt-2",attrs:{"value":_vm.elementProps.remaining_quantity},on:{"input":($event) => _vm.updateProps('remaining_quantity', $event)}})],1):_vm._e(),_c('div',{staticClass:"d-flex flex-column py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Remaining Percentage (%)")]),_c('Slider',{staticClass:"mt-2",attrs:{"value":_vm.elementProps.remaining_percentage,"primaryColor":_vm.primaryColor,"changeValue":($event) => _vm.updateProps('remaining_percentage', $event),"unit":"%"}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }