var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"label-position":"top","size":"","model":_vm.elementProps}},[_c('div',{staticClass:"py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Accordion Type")]),_c('Select',{attrs:{"primaryColor":_vm.primaryColor,"value":_vm.elementProps.accordion_type,"changeValue":($event) => _vm.updateProps('accordion_type', $event),"options":[
        {
          value: 'single',
          label: 'Single',
        },
        {
          value: 'multiple',
          label: 'Multiple',
        }
      ]}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }