var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-form',{attrs:{"label-position":"top","size":"mini","model":_vm.elementProps}},[_c('div',{staticClass:"d-flex flex-column py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Position")]),_c('Select',{staticClass:"mt-2",attrs:{"primaryColor":_vm.primaryColor,"value":_vm.elementProps.position,"changeValue":($event) => _vm.updateProps('position', $event),"options":[
        {
          value: 'center-center',
          label: 'Center Center',
        },
        {
          value: 'top-left',
          label: 'Top Left',
        },
        {
          value: 'top-right',
          label: 'Top Right',
        },
        {
          value: 'bottom-left',
          label: 'Bottom Left',
        },
        {
          value: 'bottom-right',
          label: 'Bottom Right',
        },
        {
          value: 'top-center',
          label: 'Top Center',
        },
        {
          value: 'bottom-center',
          label: 'Bottom Center',
        },
        {
          value: 'center-left',
          label: 'Center Left',
        },
        {
          value: 'center-right',
          label: 'Center Right',
        },
      ]}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }