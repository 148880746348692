<template>
    <div class="item">
      <div class="d-flex flex-column align-items-center">
	  <div class="snippet-icon">
        <i :class="icon">
        </i>
		</div>
        <span style="font-size: 13px; color: #ccc">{{ name }}</span>
      </div>
    </div>
</template>

<script>
export default {
  props: {
    icon: String,
    name: String,
  },
};
</script>

<style lang="scss" scoped>
$block-height: 50px;

.snippet-icon {
  font-size: 40px;
  line-height: $block-height;
  color: #919191;
}

.item-wrapper {
  cursor: move;
  padding: 0px;
  width: calc(50% - 5px);
  float: left;
  position: relative;
  .item {
    border: 1px solid #333333;
    background-color: #333333;
    color: #ccc;
    padding: 10px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      stroke: #ccc;
    }
    &:hover {
      color: #fff;
      background: #444444;
      svg {
        stroke: #fff;
      }
    }
  }
}
</style>
