<template>
  <div class="mp-root-toolbar-container" ref="mp_toolbar">
    <ul
      class="
        mp-toolbar-container mp-toolbar-container-wrap
        gf-r-tool-wrap
        mp-toolbar-container-show
      "
      :class="
        editToolbarId.includes(toolbar_id) ? 'gf-r-tool-edit' : 'gf-r-tool'
      "
      :style="cssGlobal"
    >
      <li class="t-handle">
        <a
          class="r-handle"
          :draggable="isDraggable"
          :style="{
            transition: 'unset',
            opacity:
              editor.draggedNode != null &&
              editor?.draggedNode?.uuid == node?.uuid
                ? '0 !important'
                : '1',
          }"
        >
          <span v-if="isDraggable">
            <svg class="editor move" width="14" viewBox="0 0 14 14">
              <path
                d="M11,10V8H8v3h2l-3,3l-3-3h2V8H3v2L0,7l3-3v2h3V3H4l3-3l3,3H8v3h3V4l3,3L11,10z"
              ></path>
            </svg>
          </span>
          <span class="mp-toolbar-container-label">
            {{ toolbarType[toolbar_id].title == 'Row' && node.parent?.props?.component == 'WrapperContainer' ? node.props.name : toolbarType[toolbar_id].title }}</span>
        </a>
      </li>
      <li
        class="e-edit"
        v-if="toolbarType[toolbar_id].edit"
        :style="{
          opacity:
            editor.draggedNode != null &&
            editor?.draggedNode?.uuid == node?.uuid
              ? '0 !important'
              : '1',
        }"
      >
        <a class="e-edit" @click="showEditor">
          <svg class="need_a_name" height="14px" viewBox="0 0 14 14">
            <path
              d="M12.81,3.69c0.26-0.26,0.26-0.68,0-0.94l-1.56-1.56c-0.26-0.26-0.68-0.26-0.94,0L9.09,2.41l2.5,2.5L12.81,3.69zM1,10.5V13h2.5l7.37-7.37l-2.5-2.5L1,10.5z"
            ></path>
          </svg>
        </a>
      </li>
      <li
        class="t-wrap-hide r-wrap"
        :class="{ 't-wrap-show': isShowMoreStatus }"
        :style="{
          opacity:
            editor.draggedNode != null &&
            editor?.draggedNode?.uuid == node?.uuid
              ? '0 !important'
              : '1',
        }"
      >
        <!-- <a class="r-export" title="Save row" onclick="return false;">
                    <svg class="editor save" width="14" height="14" viewBox="0 0 14 14">
                        <path
                            d="M12.2499 9.78559V12.4106H1.74999V9.78559H0V12.4106C0 13.3731 0.787495 14.1606 1.74999 14.1606H12.2499C13.2124 14.1606 13.9999 13.3731 13.9999 12.4106V9.78559H12.2499ZM11.3749 6.28561L10.1412 5.05187L7.87495 7.30935V0.160645H6.12496V7.30935L3.85873 5.05187L2.62498 6.28561L6.99996 10.6606L11.3749 6.28561Z">
                        </path>
                    </svg>
                </a> -->
        <a class="r-clone" title="Clone Row" @click="cloneElement" v-if="isClonable">
          <svg class="editor duplicate" width="14" viewBox="0 0 14 14">
            <path d="M10,10H0V0h10V10z M12,4v8H4v2h10V4H12z"></path>
          </svg>
        </a>
        <a class="r-remove" title="Remove Row" @click="removeContainer">
          <svg class="editor delete" width="14" viewBox="0 0 14 14">
            <path
              d="M7,0C3.1,0,0,3.1,0,7c0,3.9,3.1,7,7,7s7-3.1,7-7C14,3.1,10.9,0,7,0z M7,8.4l-2.8,2.8c-0.6-0.4-1-0.9-1.4-1.4 L5.6,7L2.8,4.2c0.4-0.6,0.9-1,1.4-1.4L7,5.6l2.8-2.8c0.6,0.4,1,0.9,1.4,1.4L8.4,7l2.8,2.8c-0.4,0.6-0.9,1-1.4,1.4L7,8.4z"
            ></path>
          </svg>
        </a>
      </li>
      <li
        class="t-collapse"
        @click="showMore"
        :style="{
          opacity:
            editor.draggedNode != null &&
            editor?.draggedNode?.uuid == node?.uuid
              ? '0 !important'
              : '1',
        }"
      >
        <a title="Expand / Collapse">
          <svg class="editor expand" width="8" viewBox="0 0 8 14">
            <path d="M1,3l4,4l-4,4V3z"></path>
          </svg>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import { toolbarType, editToolbarId } from "../../../../../../src/constants";

export default {
  inject: ["editor", "node"],
  props: {
    toolbar_id: String,
    dragging: Boolean,
    heading_refs: Object,
    dynamic: {
      type: Boolean,
      required: false,
    },
  },
  mounted() {
    this.isShowMoreStatus = false;
  },
  data() {
    return {
      isShowMoreStatus: this.isShowMoreStatus,
      toolbarType: toolbarType,
      editToolbarId,
    };
  },
  computed: {
    selectedNode() {
      return this.editor.selectedNode;
    },
    isSelected() {
      return this._provided.node === this.editor.selectedNode;
    },
    isDraggable() {
      return (
        this.editor.enabled &&
        this._provided.node.isDraggable() &&
        !this._provided.node.props.disabled
      );
    },
    isClonable() {
      return !this._provided.node.props.no_copy
    },
    cssGlobal() {
      return {
        "--bg-color": this.checkNodeDynamic(this.node) ? "#9900FF" : toolbarType[this.toolbar_id].color,
        "--var-scale": toolbarType[this.toolbar_id].size,
        "--var-top": toolbarType[this.toolbar_id].top,
        "--var-left": toolbarType[this.toolbar_id].left,
        "--var-tranform-top": toolbarType[this.toolbar_id]["tranform-top"],
        "--var-zindex": toolbarType[this.toolbar_id]['z-index'] || 1,
        "background-color":
          this.editor.draggedNode != null &&
          this.editor?.draggedNode?.uuid == this.node?.uuid ? "transparent !important" : this.checkNodeDynamic(this.node) ? "#9900FF" : toolbarType[this.toolbar_id].color,
      };
    },
  },

  methods: {
    getAncestor(node, result) {
      if (!result) {
        result = [];
      }
      if (node.parent) {
        const product_dynamic = node.parent.componentName == 'Product' && node.parent.props.dynamic ? true : false
        result.push(product_dynamic)
        return this.getAncestor(node.parent, result)
      } else {
        return result
      }
    },
    checkNodeDynamic(node) {
      const ancestors = this.getAncestor(node);
      if (ancestors.includes(true) && node.props.parent == 'Product' || node.props.dynamic) {
        return true;
      }
      return false
    },
    checkChildProductList(node) {
      var parent = node.parent;
      if (parent) {
        if (["ProductList", "RelatedProduct"].includes(parent.componentName)) {
          return node;
        } else {
          return this.checkChildProductList(parent);
        }
      } else {
        return null;
      }
    },
    cloneElement(event) {
      const { node } = this;
      let nodes = this.editor.export();
      let popups = this.editor.exportPopup();
      let inversePatches = { nodes, popups };
      if (["Popup","FloatingButton"].includes(node.componentName)) {
        const newNode = node.duplicate();
        for (var i = 0; i < this.editor.popups.length; i++) {
          const nodePop = this.editor.popups[i];
          nodePop.setProps({ showPopup: false }, true);
        }
        this.editor.popups.push(newNode);
      } else {
        const parentNode = node.parent;
        
        const newNode = node.duplicate();
        var index = parentNode.children.findIndex((x) => x.uuid == node.uuid);
        parentNode.children.splice(index, 0, newNode);
        var productNode = this.checkChildProductList(node);
        if(productNode){
          var currentList = productNode.parent;
          for (var i = 0; i < currentList.children.length; i++) {
            const product = currentList.children[i]
            if (product.uuid == productNode.uuid) continue;
            const node2 = productNode.duplicate()
            product.children = node2.children;
          }
        }
        if ((newNode.componentName == 'Row' || newNode.props.component == 'Row') && parentNode.props?.component == 'WrapperContainer') {
          const rowNodes = parentNode.children.filter(e => e.componentName == 'Row' || e.props?.component == 'Row')
          for (let i = 0; i < rowNodes.length; i++) {
            rowNodes[i].setProps({name: `Row ${i + 1}`})
          }
        }
      }
      let patches = {
        nodes: this.editor.export(),
        popups: this.editor.exportPopup(),
      };
      this.editor.add(patches, inversePatches);
      event.stopPropagation();
    },
    removeContainer(event) {
      this.$confirm(
        "This will permanently delete this element. Continue?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(() => {
        const node = this._provided.node;
        const parentNode = node.parent;
        if (node.props?.disabled) {
          this.$toastr.e('This element cannot be removed');
        } else {
          if (node.componentName == 'Popup') {
            const nodeMaps = Object.values(this.editor.nodeMap);
            const buttonNodes = nodeMaps.filter(e => e.props.href == `#element-${node.uuid}`)
            if (buttonNodes) {
              buttonNodes.map(e => {
                e.setProps({
                  action_type: 'product',
                  href: ''
                })
              })
            }
          }
          this.editor.removeNode(this._provided.node);
          if ((node.componentName == 'Row' || node.props.component == 'Row') && parentNode.props?.component == 'WrapperContainer') {
            const nodeMaps = Object.values(this.editor.nodeMap)
            const wrapper = nodeMaps.find(e => e.props.component == "WrapperContainer")
            const rowNodes = wrapper.children.filter(e => e.componentName == 'Row' || e.props?.component == 'Row')
            for (let i = 0; i < rowNodes.length; i++) {
              rowNodes[i].setProps({name: `Row ${i + 1}`})
            }
          }
        }
      });
      event.stopPropagation();
    },
    showMore(event) {
      this.isShowMoreStatus = !this.isShowMoreStatus;
      event.stopPropagation();
    },
    showEditor() {
      if (this.node.componentName == 'Liquid') {
        this.$bus.emit("showLiquidModal", this.node)
      } else {
        this.heading_refs.heading_editor.setEditable(true);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.mp-toolbar-container.gf-r-tool {
  position: absolute;
  background: var(--bg-color);
  left: var(--var-left);
  top: var(--var-top);
  transform: scale(var(--var-scale));
}

.mp-toolbar-container.gf-r-tool-edit {
  position: absolute;
  background: var(--bg-color);
  left: var(--var-left);
  top: var(--var-top);
}

.mp-toolbar-container.mp-toolbar-container-show {
  visibility: visible;
  opacity: 1;
}

.mp-root-toolbar-container ul {
  padding-left: 5px !important;
}

.mp-toolbar-container li {
  margin: 0 !important;
  padding: 0 !important;
  list-style: none !important;
  white-space: nowrap;
}

.mp-toolbar-container.gf-r-tool li a {
  background: var(--bg-color);
}

.mp-toolbar-container.gf-r-tool-edit li a {
  background: var(--bg-color);
}

.mp-toolbar-container li.t-handle a {
  width: auto;
  display: flex !important;
  flex-wrap: nowrap;
}

.mp-toolbar-container li a {
  display: block;
  float: left;
  text-decoration: none;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  width: 26px;
  height: 30px;
  line-height: 30px;
  color: #fff !important;
  background: red;
  position: relative;
  cursor: pointer;
}

.mp-toolbar-container li.t-handle span {
  display: inline-block;
  // float: left;
  line-height: 30px;
  width: 26px;
  height: 30px;
  position: relative;
  cursor: move;
}

.mp-toolbar-container li.t-handle span svg {
  left: 6px;
  transform: translateY(-50%);
}

.mp-toolbar-container li a svg {
  fill: #fff !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0 !important;
  padding: 0 !important;
  width: 14px !important;
  height: 15px !important;
  vertical-align: initial !important;
}

.mp-toolbar-container li.t-handle span.mp-toolbar-container-label {
  display: flex !important;
  flex-wrap: nowrap;
  width: auto;
  height: 30px;
  line-height: 30px;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 14px !important;
  font-family: Inter, Roboto, Helvetica Neue, sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  cursor: pointer;
  color: #fff;
}

.mp-toolbar-container li .e-edit,
.mp-toolbar-container li.t-wrap-hide {
  display: none !important;
}

.mp-toolbar-container li .e-edit,
.mp-toolbar-container li.t-wrap-show {
  display: flex !important;
}

.mp-toolbar-container,
.mp-toolbar-container li {
  float: left;
  display: flex !important;
  flex-wrap: nowrap;
  height: 30px;
}

.mp-toolbar-container {
  display: none;
}

.gf_equal-height > * {
  visibility: visible;
}

.mp-toolbar-container {
  background: red;
  left: 50%;
  top: 50%;
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  user-select: none !important;
  visibility: hidden;
  opacity: 0;
  overflow: hidden;
  border-radius: 0px 3px 3px 3px;
  transform: translate(-50%, -50%) scale(0.8);
}

.gf-delete,
.mp-toolbar-container {
  position: absolute;
  z-index: 11;
}

.gf_equal-height {
  visibility: hidden;
}
</style>