<template>
  <div
    data-label="Payment Button"
    data-key="payment_button"
    class="sg-wrap module"
    :id="`element-${node.uuid}`"
    @mouseover="isHovering=true" 
    @mouseout="isHovering=false"
    :class="{ 'dragging': dragging == node.uuid }"
  >
    <div 
      class="bl"
      :class="extra_class"
    >
      <button class="button-checkout" :data-spacing="`${spacing}px`">
        <span class="button-text">{{ label }}</span>
        <span class="price-text" v-if="show_text_price">Total: $100</span>
      </button>
    </div>
    <div class="sg-snackbar toast-checkout">Some text some message..</div>
    <ToolBar
      ref="payment_button"
      :toolbar_id="`payment_button`"
      class="container-toolbar"
      :class="{ 'container-toolbar-show': isHovering, 'is-dragging': dragging == node.uuid }"
    ></ToolBar>
    
    <v-style>
      {{ custom_css }}

      #element-{{ node.uuid }} .button-checkout {
        gap: {{ spacing }}px;
      }

      @media (max-width: 767px){
        #element-{{ node.uuid }} .button-text {
          {{ getValue('font_weight','mobile') ? `font-weight: ${getValue('font_weight','mobile')};` : '' }}
          {{ getValue('font_size', 'mobile') > 0 ? `font-size: ${getValue('font_size','mobile')}px;` : '' }}
          font-family: {{ getValue('font_family','mobile') }};
          {{ getValue('text_transform','mobile') ? `text-transform: ${getValue('text_transform','mobile')};` : '' }}
          {{ getValue('color','mobile') ? `color: ${getValue('color','mobile')};` : '' }}
        }
        #element-{{ node.uuid }} {
          {{ getDisplayValue('mobile') }}
          {{ getMarginValue('mobile') }}
        }

        #element-{{ node.uuid }} .bl {
          text-align: {{ getValue('alignment', 'mobile') }};
        }
        #element-{{ node.uuid }} .button-checkout:hover {
          {{ getValue('hover','mobile') ? `background-color: ${getValue('background_color_hover','mobile')}; color: ${getValue('color_hover','mobile')};` : '' }}
        }
        #element-{{ node.uuid }} .button-checkout {
          {{ getValue('background_color','mobile') ? `background-color: ${getValue('background_color','mobile')};` : '' }}
          {{ getValue('stretch','mobile') ? `width: 100%;` : ''}}
          {{ getValue('stretch','mobile') ? `align-items: ${getValue('text_align','mobile')=='left'?'start':(getValue('text_align','mobile')=='right'?'end':getValue('text_align','mobile'))};` : '' }}

          {{ getPaddingValue('mobile') }}
          {{ getBackgroundValue('mobile') }}
          {{ getBorderValue('mobile') }}
          {{ getBorderRadiusValue('mobile') }}
          {{ getOpacityValue('mobile') }}
          {{ getShadowValue('mobile') }}
          {{ getOtherStyle('mobile') }}
        }

      } 
      @media (max-width: 991px) and (min-width: 768px){
        #element-{{ node.uuid }} .button-text {
          {{ getValue('font_weight','tablet') ? `font-weight: ${getValue('font_weight','tablet')};` : '' }}
          {{ getValue('font_size', 'tablet') > 0 ? `font-size: ${getValue('font_size','tablet')}px;` : '' }}
          font-family: {{ getValue('font_family','tablet') }};
          {{ getValue('text_transform','tablet') ? `text-transform: ${getValue('text_transform','tablet')};` : '' }}
          {{ getValue('color','tablet') ? `color: ${getValue('color','tablet')};` : '' }}
        }
        #element-{{ node.uuid }} {
          {{ getDisplayValue('tablet') }}
          {{ getMarginValue('tablet') }}
        }
        #element-{{ node.uuid }} .bl {
          text-align: {{ getValue('alignment', 'tablet') }};
        }
        #element-{{ node.uuid }} .button-checkout:hover {
          {{ getValue('hover','tablet') ? `background-color: ${getValue('background_color_hover','tablet')}; color: ${getValue('color_hover','tablet')};` : '' }}
        }
        #element-{{ node.uuid }} .button-checkout {
          {{ getValue('background_color','tablet') ? `background-color: ${getValue('background_color','tablet')};` : '' }}
          {{ getValue('stretch','tablet') ? `width: 100%;` : ''}}
          {{ getValue('stretch','tablet') ? `align-items: ${getValue('text_align','tablet')=='left'?'start':(getValue('text_align','tablet')=='right'?'end':getValue('text_align','tablet'))};` : '' }}

          {{ getPaddingValue('tablet') }}
          {{ getBackgroundValue('tablet') }}
          {{ getBorderValue('tablet') }}
          {{ getBorderRadiusValue('tablet') }}
          {{ getOpacityValue('tablet') }}
          {{ getShadowValue('tablet') }}
          {{ getOtherStyle('tablet') }}
        }
      } 
      @media (max-width: 1199px) and (min-width: 992px){
        #element-{{ node.uuid }} .button-text {
          {{ getValue('font_weight','laptop') ? `font-weight: ${getValue('font_weight','laptop')};` : '' }}
          {{ getValue('font_size', 'laptop') > 0 ? `font-size: ${getValue('font_size','laptop')}px;` : '' }}
          font-family: {{ getValue('font_family','laptop') }};
          {{ getValue('text_transform','laptop') ? `text-transform: ${getValue('text_transform','laptop')};` : '' }}
          {{ getValue('color','laptop') ? `color: ${getValue('color','laptop')};` : '' }}
        }
        #element-{{ node.uuid }} {
          {{ getDisplayValue('laptop') }}
          {{ getMarginValue('laptop') }}
        }
        #element-{{ node.uuid }} .bl {
          text-align: {{ getValue('alignment', 'laptop') }};
        }
        #element-{{ node.uuid }} .button-checkout:hover {
          {{ getValue('hover','laptop') ? `background-color: ${getValue('background_color_hover','laptop')}; color: ${getValue('color_hover','laptop')};` : '' }}
        }
        #element-{{ node.uuid }} .button-checkout {
          {{ getValue('background_color','laptop') ? `background-color: ${getValue('background_color','laptop')};` : '' }}
          {{ getValue('stretch','laptop') ? `width: 100%;` : ''}}
          {{ getValue('stretch','laptop') ? `align-items: ${getValue('text_align','laptop')=='left'?'start':(getValue('text_align','laptop')=='right'?'end':getValue('text_align','laptop'))};` : '' }}

          {{ getPaddingValue('laptop') }}
          {{ getBackgroundValue('laptop') }}
          {{ getBorderValue('laptop') }}
          {{ getBorderRadiusValue('laptop') }}
          {{ getOpacityValue('laptop') }}
          {{ getShadowValue('laptop') }}
          {{ getOtherStyle('laptop') }}
        }
      }
      @media (min-width: 1200px){
        #element-{{ node.uuid }} .button-text {
          {{ getValue('font_weight','desktop') ? `font-weight: ${getValue('font_weight','desktop')};` : '' }}
          {{ getValue('font_size', 'desktop') > 0 ? `font-size: ${getValue('font_size','desktop')}px;` : '' }}
          font-family: {{ getValue('font_family','desktop') }};
          {{ getValue('text_transform','desktop') ? `text-transform: ${getValue('text_transform','desktop')};` : '' }}
          {{ getValue('color','desktop') ? `color: ${getValue('color','desktop')};` : '' }}
        }
        #element-{{ node.uuid }} {
          {{ getDisplayValue('desktop') }}
          {{ getMarginValue('desktop') }}
        }
        #element-{{ node.uuid }} .bl {
          text-align: {{ getValue('alignment', 'desktop') }};
        }
        #element-{{ node.uuid }} .button-checkout:hover {
          {{ getValue('hover','desktop') ? `background-color: ${getValue('background_color_hover','desktop')}; color: ${getValue('color_hover','desktop')};` : '' }}
        }

        #element-{{ node.uuid }} .button-checkout {
          {{ getValue('background_color','desktop') ? `background-color: ${getValue('background_color','desktop')};` : '' }}
          {{ getValue('stretch','desktop') ? `width: 100%;` : ''}}
          {{ getValue('stretch','desktop') ? `align-items: ${getValue('text_align','desktop')=='left'?'start':(getValue('text_align','desktop')=='right'?'end':getValue('text_align','desktop'))};` : '' }}
          {{ getPaddingValue('desktop') }}
          {{ getBackgroundValue('desktop') }}
          {{ getBorderValue('desktop') }}
          {{ getBorderRadiusValue('desktop') }}
          {{ getOpacityValue('desktop') }}
          {{ getShadowValue('desktop') }}
          {{ getOtherStyle('desktop') }}
        }
      }

    </v-style>

  </div>

</template>

<script>
import ExtraClass from "../otherSettings/ExtraClass.vue";
import LabelIcon from "../otherSettings/LabelIcon.vue";
import elementStyleMixin from "../elementStyleMixin";
import ToolBar from "../utils/ToolBar.vue";
import { designStyles, otherProps, otherStyles, actionTypes } from "@/constants";
import CustomCSS from "../styleSettings/CustomCSS.vue";
import ButtonColor from "../styleSettings/ButtonColor.vue";
import AlignmentBase from "../styleSettings/AlignmentBase.vue";
import Typography from "../styleSettings/Typography.vue";
import Animations from "../designSettings/Animations.vue";
import Spacing from "../designSettings/Spacing.vue";
import Background from "../designSettings/Background.vue";
import Border from "../designSettings/Border.vue";
import Corner from "../designSettings/Corner.vue";
import Shadow from "../designSettings/Shadow.vue";
import Opacity from "../designSettings/Opacity.vue";
import Dimensions from "../../elements/styleSettings/Dimensions.vue";

export default {
  mixins: [elementStyleMixin],
  components: { ToolBar },
  props: {
    ...otherProps,
    label: String,
    spacing: Number,
    component: String,
    extra_class:String,
    desktop: Object,
    laptop: Object,
    mobile: Object,
    tablet: Object,
    disabled: Boolean,
    show_text_price: Boolean,
  },
  data() {
    return {
      isHovering: false,
    };
  },
  inject: ["node",'editor'],
  computed: {
    dragging: function () {
      return this.editor?.draggedNode?.uuid
    },
  },
  craft: {
    defaultProps: {
      ...otherStyles,
      disabled: true,
      extra_class: "",
      label: 'Place Your Order',
      show_text_price: true,
      spacing: 0,
      dimensionActives: ["stretch"],
      desktop: {
        ...designStyles,
        font_weight: '500',
        font_size: 16,
        font_family: 'inherit',
        color: 'rgb(255, 255, 255)',
        text_transform: 'none',
        padding_top: 16,
        padding_bottom: 16,
        padding_left: 16,
        padding_right: 16,
        margin_left: 16,
        margin_right: 16,
        background_color: 'rgb(0, 0, 0)',
        alignment: 'right',
        stretch: false,
        text_align:'center'
      },
      laptop: {
        ...designStyles,
      },
      mobile: {
        ...designStyles,
      },
      tablet: {
        ...designStyles,
      },
      typographyActives: ['font-weight', 'font-size', 'font-family', 'text-transform'],
      elementStyle: {
      },
    },
    settings: {
      settings: {
        "Label & Icon": LabelIcon,
        "Typography": Typography,
        "Button Color": {"Button Color": ButtonColor, multi_screen: true },
        Dimension: { Dimension: Dimensions, multi_screen: true, remake : true },
        "Alignment": {"Alignment": AlignmentBase, multi_screen: true },
        "Extra Class": ExtraClass,
        "Custom CSS": CustomCSS,

      },
      design: {
        Spacing: { Spacing, multi_screen: true },
        Background: { Background, multi_screen: true },
        Border: { Border, multi_screen: true },
        Corner: { Corner, multi_screen: true },
        Shadow: { Shadow, multi_screen: true },
        Opacity: { Opacity, multi_screen: true },
      },
      others: {
        Animations: { Animations, actions: actionTypes }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  width: 100%;
  box-sizing: border-box;
  white-space: pre-wrap;
}
</style>
