<template>
  <div 
    class="item"
    :id="`element-item-${node.uuid}`"
  >
    <div v-if="editor?.findNode(node?.uuid)?.children?.length == 0" class="text-center drop-element">
    <span>Add element</span>
    </div>
    <slot />
  </div>
</template>

<script>
import elementStyleMixin from '../../elements/elementStyleMixin';
import ToolBar from '../../elements/utils/ToolBar.vue';

export default {
  components: {ToolBar},
  mixins: [elementStyleMixin],
  inject: [
    'node',
    'editor'
  ],
  props: {
    component: String,
    
    item: Object,
  },
  data() {
    return {
      isHovering: false,
    }
  },
  craft: {
    defaultProps: {
      elementStyle: {

      },
    },
    settings: {
      settings: {
      },
      design: {
      }
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
