<template>
  <div label-position="top" size="mini" :model="elementProps">
    <div class="py-2">
      <span class="setting-title">Label</span>
      <Input
        class="mt-2"
        :value="elementProps.label"
        v-bind:changeValue="($event) => updateProps('label', $event)"
        :primaryColor="primaryColor"
      />
    </div>
  </div>
</template>

<script>
import mixin from '../../../elements/styleSettings/mixin';
import Input from '../../../elements/common/Input.vue';

export default {
  mixins: [mixin],
  components: {
    Input
  },
  props: {
    primaryColor: String,
  },
  methods: {
    updateProps (name, value) {
      if(value ==  this.elementProps[name]) return;
      this.elementPropsSetter({ [name]: value });
    }
  },
};
</script>