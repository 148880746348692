<template>
  <div
    data-label="Blog Content"
    data-key="blog content"
    class="sg-wrap static"
    :id="`element-${node.uuid}`"
    @mouseover="isHovering=true" 
    @mouseout="isHovering=false"
  >
    <div
      class="sg-static"
      :class="extra_class"
    >
      <div class="sg-blog-desc"
        v-html="'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'"
        >
      </div>
    </div>

    <ToolBar ref="blog-content" 
      :toolbar_id="`blog-content`"
      class="container-toolbar" 
      :class="{ 'container-toolbar-show': isHovering, 'is-dragging': dragging == node.uuid }"
      >
      </ToolBar>
      <div v-if="dragging == node.uuid" class="dragging"></div>

    <v-style>
      {{ custom_css }}
      #element-{{ node.uuid }} h1, #element-{{ node.uuid }} h2, #element-{{ node.uuid }} h3, #element-{{ node.uuid }} h4, #element-{{ node.uuid }} h5, #element-{{ node.uuid }} h6 {
        line-height: inherit!important;
        margin: 0px!important;
        padding: 0px!important;
        text-align: inherit!important;
      }

      #element-{{ node.uuid }} .sg-blog-desc { 
        text-decoration: none;
      } 
      #element-{{ node.uuid }} a { 
        border: none;
        text-decoration: none;
      }
      #element-{{ node.uuid }} .sg-blog-desc { 
        z-index: 1!important; 
      } 
      @media (max-width: 767px){ 
        #element-{{ node.uuid }} .sg-blog-desc {
          font-size: {{ getValue('font_size','mobile') }}px;
          line-height: {{ getValue('line_height','mobile') }}em;
          color: {{ getValue('color','mobile') }};
          letter-spacing: {{ getValue('letter_spacing','mobile') }}px;
          font-family: {{ getValue('font_family','mobile') }};
          {{ getDisplayValue('mobile') }}
          {{ getMarginValue('mobile') }}
          {{ getPaddingValue('mobile') }}
          {{ getBackgroundValue('mobile') }}
          {{ getBorderValue('mobile') }}
          {{ getBorderRadiusValue('mobile') }}
          {{ getOpacityValue('mobile') }}
          {{ getShadowValue('mobile') }}
          {{ getOtherStyle('mobile') }}
        }
        #element-{{ node.uuid }} .sg-module {
          text-align: {{ getValue('alignment','mobile') }};
        }
      } 
      @media (max-width: 991px) and (min-width: 768px){ 
        #element-{{ node.uuid }} .sg-blog-desc {
          font-size: {{ getValue('font_size','tablet') }}px;
          line-height: {{ getValue('line_height','tablet') }}em;
          color: {{ getValue('color','tablet') }};
          letter-spacing: {{ getValue('letter_spacing','tablet') }}px;
          font-family: {{ getValue('font_family','tablet') }};
          {{ getDisplayValue('tablet') }}
          {{ getMarginValue('tablet') }}
          {{ getPaddingValue('tablet') }}
          {{ getBackgroundValue('tablet') }}
          {{ getBorderValue('tablet') }}
          {{ getBorderRadiusValue('tablet') }}
          {{ getOpacityValue('tablet') }}
          {{ getShadowValue('tablet') }}
          {{ getOtherStyle('tablet') }}
        }
        #element-{{ node.uuid }} .sg-module {
          text-align: {{ getValue('alignment','tablet') }};
        }
      } 
      @media (max-width: 1199px) and (min-width: 992px){ 
        #element-{{ node.uuid }} .sg-blog-desc {
          font-size: {{ getValue('font_size','laptop') }}px;
          line-height: {{ getValue('line_height','laptop') }}em;
          color: {{ getValue('color','laptop') }};
          letter-spacing: {{ getValue('letter_spacing','laptop') }}px;
          font-family: {{ getValue('font_family','laptop') }};
          {{ getDisplayValue('laptop') }}
          {{ getMarginValue('laptop') }}
          {{ getPaddingValue('laptop') }}
          {{ getBackgroundValue('laptop') }}
          {{ getBorderValue('laptop') }}
          {{ getBorderRadiusValue('laptop') }}
          {{ getOpacityValue('laptop') }}
          {{ getShadowValue('laptop') }}
          {{ getOtherStyle('laptop') }}
        }
        #element-{{ node.uuid }} .sg-module {
          text-align: {{ getValue('alignment','laptop') }};
        }
      } 
      @media (min-width: 1200px) { 
        #element-{{ node.uuid }} .sg-blog-desc {
          font-size: {{ desktop.font_size }}px;
          line-height: {{ desktop.line_height }}em;
          color: {{ desktop.color }};
          letter-spacing: {{ desktop.letter_spacing }}px;
          font-family: {{ desktop.font_family }};
          {{ getDisplayValue('desktop') }}
          {{ getMarginValue('desktop') }}
          {{ getPaddingValue('desktop') }}
          {{ getBackgroundValue('desktop') }}
          {{ getBorderValue('desktop') }}
          {{ getBorderRadiusValue('desktop') }}
          {{ getOpacityValue('desktop') }}
          {{ getShadowValue('desktop') }}
          {{ getOtherStyle('desktop') }}
        }
        #element-{{ node.uuid }} .sg-module {
          text-align: {{ desktop.alignment }};
        }
      }
    </v-style>
  </div>
</template>
  
<script>
import ToolBar from '../../elements/utils/ToolBar.vue';

import elementStyleMixin from "../../elements/elementStyleMixin";
import ExtraClass from "../../elements/otherSettings/ExtraClass.vue";
import AlignmentBase from "../../elements/styleSettings/AlignmentBase.vue";
import Typography from "../../elements/styleSettings/Typography.vue";
import Truncate from "./settings/Truncate.vue";
import MoreText from "./settings/MoreText.vue";

import Visibility from "../../elements/designSettings/Visibility.vue";
import Spacing from "../../elements/designSettings/Spacing.vue";
import Background from "../../elements/designSettings/Background.vue";
import Border from "../../elements/designSettings/Border.vue";
import Corner from '../../elements/designSettings/Corner.vue';
import Shadow from '../../elements/designSettings/Shadow.vue';
import Opacity from '../../elements/designSettings/Opacity.vue';
import descData from './descData';
import { designStyles, otherProps, otherStyles, actionTypes } from '@/constants';
import CustomCSS from '../../elements/styleSettings/CustomCSS.vue';
import Animations from '../../elements/designSettings/Animations.vue';

export default {
  components: {ToolBar},
  data() {
    return {
      isHovering: false,
      descData:descData
    }
  },
  inject: ["node",'editor'],
  mixins: [elementStyleMixin],
  props: {
    ...otherProps,
    extra_class: String,
    component:String,
    content:String,
    parent:String,
    desktop: Object,
    laptop: Object,
    mobile: Object,
    tablet: Object,
    truncate:String,
    more_text:String
  },
  watch: {
  dragging: function (newValue, oldValue) {
    var $iframe = $("#designEditor").contents();
    var $element = $iframe.find(`#element-${this.node.uuid}`);
    var $content = $element.find(`.sg-static`);
    if (newValue && newValue == this.node.uuid) {
      setTimeout(() => {
        $content.hide();
      },100);
    }else if (oldValue == this.node.uuid){
      $content.show();
    }
  },
},
computed: {
  dragging: function () {
    return this.editor?.draggedNode?.uuid
  },
},
  craft: {
    defaultProps: {
      ...otherStyles,
      component:"Blog Content",
      truncate:"20",
      more_text:"...",
      typographyActives: [
        'font-size',
        'line-height',
        'color',
        'font-family',
      ],
      desktop: {
        ...designStyles,
        font_size: 14,
        line_height: 1.5,
        letter_spacing: 0,
        color: 'rgb(61, 66, 70)',
        font_family: 'inherit',
        alignment: 'center'
      },
      laptop: {
        ...designStyles,
      },
      mobile: {
        ...designStyles,
      },
      tablet: {
        ...designStyles,
      },
      elementStyle: {
        
      }
    },
    settings: {
      settings: {
        Typography: {Typography, multi_screen: true},
        "Alignment": {Alignment: AlignmentBase, multi_screen: true},
        "Extra Class": ExtraClass, "Custom CSS": CustomCSS,
      },
      design: {
        Visibility,
        Spacing: { Spacing, multi_screen: true },
        Background: { Background, multi_screen: true },
        Border: { Border, multi_screen: true },
        Corner: { Corner, multi_screen: true },
        Shadow: { Shadow, multi_screen: true },
        Opacity: { Opacity, multi_screen: true },
      },
      others: {
        Animations: { Animations, actions: actionTypes }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
  