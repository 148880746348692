<template>
  <div label-position="top" size="small" :model="elementProps">
    <div class="py-2">
      <span class="setting-title">Spacing with Symbol</span>
      <Slider
        class="mt-2"
        :value="getValue('spacing_symbol',elementProps[screen].spacing_symbol)"
        :primaryColor="primaryColor"
        v-bind:changeValue="($event) => updateProps('spacing_symbol', $event)"
        unit="px"
      />
    </div>
  </div>
</template>

<script>
import mixin from "../../../elements/styleSettings/mixin";
import ToggleButton from "../../../elements/common/ToggleButton.vue";
import Slider from "../../../elements/common/Slider.vue";

export default {
  mixins: [mixin],
  components: { ToggleButton, Slider },
  props: {
    screen: String,
    primaryColor: String,
  },
  methods: {
    updateProps(name, value) {
      const screen = this.$props.screen;
      const data_screen = Object.assign({}, this.elementProps[screen])
      if(value ==  data_screen[name]) return;
      data_screen[name] = value
      this.elementPropsSetter({ [screen]: data_screen });
    },
  },
};
</script>

<style scoped>
</style>
