<template>
  <div label-position="top" size="small" :model="elementProps">
    <div class="d-flex flex-column py-2">
      <span class="setting-title">Remaining Quantity Source</span>
      <ToggleButton
        class="mt-2"
        :value="elementProps.quantity_source"
        v-bind:changeValue="($event) => updateProps('quantity_source', $event)"
        :options="[
          {
            label: 'Auto',
            value: 'auto',
          },
          {
            label: 'Manual',
            value: 'manual',
          },
        ]"
      />
    </div>
    <div class="d-flex flex-column py-2" v-if="elementProps.quantity_source == 'manual'">
      <span class="setting-title">Remaining Quantity</span>
      <el-input
        class="mt-2"
        :value="elementProps.remaining_quantity"
        @input="($event) => updateProps('remaining_quantity', $event)"
      />
    </div>
    <div class="d-flex flex-column py-2">
      <span class="setting-title">Remaining Percentage (%)</span>
      <Slider
        class="mt-2"
        :value="elementProps.remaining_percentage"
        :primaryColor="primaryColor"
        v-bind:changeValue="($event) => updateProps('remaining_percentage', $event)"
        unit="%"
      />
    </div>
  </div>
</template>

<script>
import mixin from "../../../elements/styleSettings/mixin";
import ToggleButton from "../../../elements/common/ToggleButton.vue";
import Slider from "../../../elements/common/Slider.vue";

export default {
  mixins: [mixin],
  components: { ToggleButton, Slider },
  methods: {
    updateProps(name, value) {
      if(value ==  this.elementProps[name]) return;

      this.elementPropsSetter({ [name]: value });
    },
  },
  props: {
    primaryColor: String
  }
};
</script>

<style scoped>
</style>
