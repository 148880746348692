<template>
  <div
    data-label="Header Button" 
    data-key="header_button"
    :id="`h-${node.uuid}`"
    @mouseover="isHovering=true" 
    @mouseout="isHovering=false"
    :class="`${editor?.findNode(node.uuid)?.children?.length == 0 ? 'no-element' : ''}`"
  >
    <div v-if="editor?.findNode(node.uuid)?.children?.length == 0" class="text-center drop-element">
      <span>Add element</span>
    </div>
    <slot />
  </div>
</template>

<script>
import elementStyleMixin from "../../elements/elementStyleMixin";
import ToolBar from "../../elements/utils/ToolBar.vue";

export default {
  mixins: [elementStyleMixin],
  components: { ToolBar },
  props: {
    component: String,
    parent_node: String 
  },
  inject: ['node', 'editor'],
  methods: {
    
  },
  data() {
    return {
    }
  },
};
</script>
