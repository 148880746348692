<template>
  <el-form label-position="top" size="medium" :model="elementProps">
    <div class="d-flex align-items-center justify-content-between py-2">
      <span class="sgSubTitle">Randomize</span>
      <el-switch
        :value="elementProps.randomize"
        :active-color="primaryColor"
        inactive-color="#949494"
        @change="updateProps('randomize', $event)"
      />
    </div>

    <div class="sgDesc mt-0">
      Enable to display the products from your collection in random order
    </div>
  </el-form>
</template>

<script>
import mixin from "../../../elements/styleSettings/mixin";

export default {
  mixins: [mixin],
  components: {},
  props: {
    primaryColor: String
  },
  methods: {
    updateProps(name, value) {
      if(value ==  this.elementProps[name]) return;
      this.elementPropsSetter({ [name]: value });
    },
  },
};
</script>

<style scoped>
</style>
