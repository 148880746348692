<template>
  <div label-position="top" size="mini" :model="elementProps">
    <div class="d-flex flex-column py-2">
      <span class="setting-title">Border Radius</span>
      <Dimension
        :units="['px', '%']"
        :unit="elementProps[screen].border_radius_unit || 'px'"
        :primaryColor="primaryColor"
        :data="elementProps[screen]"
        :isPositive="true"
        :onlyInteger="true"
        :items="[
          {
            key: 'border_top_left_radius',
            title: 'Top Left',
          },
          {
            key: 'border_top_right_radius',
            title: 'Top Right'
          },
          {
            key: 'border_bottom_right_radius',
            title: 'Bottom Right'
          },
          {
            key: 'border_bottom_left_radius',
            title: 'Bottom Left'
          }
        ]"
        v-bind:changeValue="(name, $event) => updateProps(screen, name, $event)"
        v-bind:changeUnit="($event) => updateProps(screen, 'border_radius_unit', $event)"
      />
    </div>
  </div>
</template>

<script>
import mixin from '../styleSettings/mixin';
import { allIcons } from '../../../../../constants';
import Dimension from '../common/Dimension.vue';
export default {
  components: {
    Dimension
  },
  mixins: [mixin],
  data() {
    return {
      allIcons,
      typeConner:1
    }
  },
  props: {
    screen: String,
    primaryColor: String
  },
  methods: {
    updateProps(screen, name, value) {
      const data_screen = Object.assign({}, this.elementProps[screen])
      if (value ==  (data_screen[name] || 0)) return;
      data_screen[name] = value
      this.elementPropsSetter({ [screen]: data_screen });
    },
  }
};
</script>

<style>
  .spacing-input {
    width: 30%;
  }
  .spacing-input input {
    padding: 0 10px;
  }
  .cursor_pointer{
    cursor: pointer;
  }
  .spacing-input .el-input-group__append {
    padding: 0 4px;
    border: 1px solid #494949;
    border-left: none;
    background-color: #222222;
  }
</style>
