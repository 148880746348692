<template>
  <el-form label-position="top" size="medium" :model="elementProps">
    <div class="d-flex align-items-center justify-content-between py-2">
      <span class="setting-title">Display Label</span>
      <el-switch
        :value="elementProps.show_label"
        :active-color="primaryColor"
        inactive-color="#949494"
        @change="updateCommonProps('show_label', $event)"
      />
    </div>
    <div class="d-flex flex-column py-2" v-if="elementProps.show_label && elementProps.label != undefined">
      <span class="setting-title">Label</span>
      <el-input 
        :value="elementProps.label"
        class="input-with-select mt-2"
        @input="updateCommonProps('label', $event)"
        size="medium"
      />
    </div>
  </el-form>
</template>

<script> 
import mixin from "../../../elements/styleSettings/mixin";
import ColorPicker from "../../../elements/common/ColorPicker.vue";
import Slider from "../../../elements/common/Slider.vue";
import ToggleButton from "../../../elements/common/ToggleButton.vue";
import Select from "../../../elements/common/Select.vue";
import Node from "@/core/Node";
import { makeId } from "@/constants";

export default {
  mixins: [mixin],
  components: {ColorPicker, Slider, ToggleButton, Select},
  props: {
    node: Node,
    primaryColor: String,
    screen: String,
    activeFonts: Array,
  },
  data() {
    return {
      fontKey: makeId(20)
    }
  },
  watch: {
    activeFonts: function (newValue, oldValue) {
      this.fontKey = makeId(20)
    }
  },
  methods: {
    updateProps(name, value) {
      const screen = this.$props.screen
      const data_screen = Object.assign({}, this.elementProps[screen])
      if(value ==  data_screen[name]) return;
      data_screen[name] = value
      if (name == 'width' && (value == 'auto' && isNaN(parseInt(value)))) {
        data_screen['width_type'] = ''
      }
      this.elementPropsSetter({ [screen]: data_screen });
    },
    updateCommonProps(name, value) {
      if(value ==  this.elementProps[name]) return;
      this.elementPropsSetter({ [name]: value });
    },
    handleShowModal() {
      this.$bus.emit("showFontManager", this.node);
    },
    getListFont() {
      const listFonts = [
        {
          value: 'inherit',
          label: 'Default',
        }
      ]
      this.node?.editor?.fonts?.map(e => {
        listFonts.push({ value: e, label: e })
      })
      return listFonts;
    },
  },
};
</script>

<style scoped>
</style>
