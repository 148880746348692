  <template>
  <div
    data-label="Pricing"
    data-key="pricing"
    class="sg-wrap module sg-pricing"
    @mouseover="isHovering=true" 
    @mouseout="isHovering=false"
    :id="`element-${ node.uuid }`"
    :style="elementCSS"
  >
    <div class="row sg-pricing-wrapper" :class="extra_class">
      <slot />
    </div>
    <ToolBar ref="pricing_toolbar" :toolbar_id="`pricing`" class="container-toolbar" 
    :class="{ 'container-toolbar-show': isHovering, 'is-dragging': dragging == node.uuid }"
    ></ToolBar>
    <div v-if="dragging == node.uuid" class="dragging"></div>
    <v-style>
      {{ custom_css }}
      
      @media (max-width: 767px) {
        #element-{{ node.uuid }}.sg-pricing {
          {{ getDisplayValue('mobile') }}
          {{ getMarginValue('mobile') }}
          {{ getPaddingValue('mobile') }}
          {{ getBackgroundValue('mobile') }}
          {{ getBorderValue('mobile') }}
          {{ getBorderRadiusValue('mobile') }}
          {{ getOpacityValue('mobile') }}
          {{ getShadowValue('mobile') }}
          {{ getOtherStyle('mobile') }}
        }
      }
      @media (max-width: 991px) and (min-width: 768px) {
        #element-{{ node.uuid }}.sg-pricing {
          {{ getDisplayValue('tablet') }}
          {{ getMarginValue('tablet') }}
          {{ getPaddingValue('tablet') }}
          {{ getBackgroundValue('tablet') }}
          {{ getBorderValue('tablet') }}
          {{ getBorderRadiusValue('tablet') }}
          {{ getOpacityValue('tablet') }}
          {{ getShadowValue('tablet') }}
          {{ getOtherStyle('tablet') }}
        }
      }
      @media (max-width: 1199px) and (min-width: 992px) {
        #element-{{ node.uuid }} .sg-pricing-wrapper .sg-pricing-item {
          flex: 0 0 {{ 100/parseInt(number_of_items) }}%;
          width: {{ 100 / parseInt(number_of_items) }}%;
        }
        #element-{{ node.uuid }}.sg-pricing {
          {{ getDisplayValue('laptop') }}
          {{ getMarginValue('laptop') }}
          {{ getPaddingValue('laptop') }}
          {{ getBackgroundValue('laptop') }}
          {{ getBorderValue('laptop') }}
          {{ getBorderRadiusValue('laptop') }}
          {{ getOpacityValue('laptop') }}
          {{ getShadowValue('laptop') }}
          {{ getOtherStyle('laptop') }}
        }
      }
      @media (min-width: 1200px) {
        #element-{{ node.uuid }} .sg-pricing-wrapper .sg-pricing-item {
          flex: 0 0 {{ 100/parseInt(number_of_items) }}%;
          width: {{ 100 / parseInt(number_of_items) }}%;
        }
        #element-{{ node.uuid }}.sg-pricing {
          {{ getDisplayValue('desktop') }}
          {{ getMarginValue('desktop') }}
          {{ getPaddingValue('desktop') }}
          {{ getBackgroundValue('desktop') }}
          {{ getBorderValue('desktop') }}
          {{ getBorderRadiusValue('desktop') }}
          {{ getOpacityValue('desktop') }}
          {{ getShadowValue('desktop') }}
          {{ getOtherStyle('desktop') }}
        }
      }
    </v-style>
  </div>
  
</template>

<script>
import elementStyleMixin from '../../elements/elementStyleMixin';
import ToolBar from '../../elements/utils/ToolBar.vue';
import Visibility from '../../elements/designSettings/Visibility.vue';
import Spacing from '../../elements/designSettings/Spacing.vue';
import BackgroundDesign from '../../elements/designSettings/Background.vue';
import Border from '../../elements/designSettings/Border.vue';
import Corner from '../../elements/designSettings/Corner.vue';
import Shadow from '../../elements/designSettings/Shadow.vue';
import Opacity from '../../elements/designSettings/Opacity.vue';
import ExtraClass from '../../elements/otherSettings/ExtraClass.vue';
import PricingItemNumber from '../../elements/styleSettings/PricingItemNumber.vue';
import Currency from '../../elements/otherSettings/Currency.vue';
import PricingSettingItems from '../../elements/styleSettings/PricingSettingItems.vue';
import PricingItem from './PricingItem.vue';
import { designStyles, otherProps, otherStyles, actionTypes } from '@/constants';
import CustomCSS from '../../elements/styleSettings/CustomCSS.vue';
import Animations from '../../elements/designSettings/Animations.vue';


export default {
  components: { ToolBar, PricingItem },
  mixins: [elementStyleMixin],
  inject: [
    'node',
    'editor'
  ],
  computed: {
    dragging: function () {
      return this.editor?.draggedNode?.uuid
    },
  },
  props: {
    ...otherProps,
    screen: String,
    component:String,
    
    currency: String,
    items: Array,
    extra_class: String,
    number_of_items: String,
    desktop: Object,
    laptop: Object,
    tablet: Object,
    mobile: Object,
  },
  watch: {
    dragging: function (newValue, oldValue) {
      var $iframe = $("#designEditor").contents();
      var $element = $iframe.find(`#element-${this.node.uuid}`);
      var $content = $element.find(`.sg-pricing-wrapper`);
      if (newValue && newValue == this.node.uuid) {
        setTimeout(() => {
          $element.css("padding","0px 0px 0px 0px");
          $element.css("margin","0px 0px 0px 0px");
          $content.hide();
        },100);
      }else if (oldValue == this.node.uuid){
        $element.css("padding","");
        $element.css("margin","");
        $content.show();
      }
    },
  },
  data() {
    return {
      isHovering: false
    }
  },
  craft: {
    defaultProps: {
      ...otherStyles,
      extra_class: "",
      number_of_items: "3",
      desktop: {
        ...designStyles,
        padding_top: 30,
      },
      laptop: {
        ...designStyles,
      },
      tablet: {
        ...designStyles,
      },
      mobile: {
        ...designStyles,
      },
      items: [
        {
          id: 1,
          heading: 'Beginner',
          price: '49',
          plan: 'yr'
        },
        {
          id: 2,
          heading: 'Intermediate',
          price: '99',
          plan: 'yr'
        },
        {
          id: 3,
          heading: 'Advanced',
          price: '149',
          plan: 'yr'
        },
        {
          id: 4,
          heading: 'Professional',
          price: '199',
          plan: 'yr'
        },
      ],
      currency: '$',
      elementStyle: {},
    },
    settings: {
      settings: {
        "Item Number": PricingItemNumber,
        "Items": PricingSettingItems,
        Currency,
        "Extra Class": ExtraClass, "Custom CSS": CustomCSS,
        
      },
      design: {
        Visibility,
        Spacing: { Spacing, multi_screen: true },
        Background: { "Background": BackgroundDesign, multi_screen: true },
        Border: { Border, multi_screen: true },
        Corner: { Corner, multi_screen: true },
        Shadow: { Shadow, multi_screen: true },
        Opacity: { Opacity, multi_screen: true },
      },
      others: {
        Animations: { Animations, actions: actionTypes.filter(e => ['scroll_into_view', 'after_page_finish_loading'].includes(e.value)) }
      }
    },
  },
};
</script>

<style lang="scss" scoped>

.dragging{
  position:absolute;
  top:0;
  bottom: 0;
  left: 0;
  right:0;
  background: greenyellow;
  z-index: 10;
}

</style>
