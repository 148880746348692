import { render, staticRenderFns } from "./OpenTab.vue?vue&type=template&id=070d70ff&scoped=true&"
import script from "./OpenTab.vue?vue&type=script&lang=js&"
export * from "./OpenTab.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "070d70ff",
  null
  
)

export default component.exports