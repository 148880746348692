<template>
  <div label-position="top" size="small" :model="elementProps">
    <div class="d-flex flex-column py-2">
      <span class="setting-title">Alt Tag</span>
      <el-input
        class="mt-2"
        :value="elementProps.alt"
        @input="updateProps('alt', $event)"
        size="medium"
        placeholder="Your alt tags go here"
      />
    </div>
    <span class="mt-2 text-description">
      Each image should have an alt text. Not just for SEO purposes but also because blind and visually impaired people otherwise wont know what the image is for.
    </span>
  </div>
</template>

<script>
import mixin from '../styleSettings/mixin';
export default {
  mixins: [mixin],
  methods: {
    updateProps(name, value) {
      if(value ==  this.elementProps[name]) return;
      this.elementPropsSetter({ [name]: value });
    },
  },
};
</script>

<style scoped>
.text-description {
  font-style: italic;
  font-size: 12px;
  color: #D6D6D6;
}
</style>
