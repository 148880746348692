<template>
  <div label-position="top" size="mini" :model="elementProps">
    <div class="d-flex align-items-center justify-content-between py-2">
      <span class="setting-title">Show button</span>
      <el-switch
        :value="getValue('show_button', elementProps[screen].show_button)"
        :active-color="primaryColor"
        inactive-color="#949494"
        :active-value="true"
        :inactive-value="false"
        @change="updateProps('show_button', $event)"
      />
    </div>
    <div class="d-flex flex-column py-2" v-if="getValue('show_button', elementProps[screen].show_button)">
      <span class="setting-title">Width</span>
      <Slider 
        class="mt-2"
        :primaryColor="primaryColor"
        :value="Number.parseInt(getValue('button_width', elementProps[screen].button_width))"
        :min="0"
        :max="400"
        :step="1"
        v-bind:changeValue="($event) => updateProps('button_width', $event)"
        unit="px"
        :onlyInteger="true"
      />
    </div>
  </div>
</template>

<script>
import mixin from '../../../elements/styleSettings/mixin';
import Slider from '../../../elements/common/Slider.vue';
export default {
  mixins: [mixin],
  props: {
    screen: String,
    primaryColor: String
  },
  components: {
    Slider
  },
  methods: {
    updateProps(name, value) {
      const screen = this.$props.screen
      const data_screen = Object.assign({}, this.elementProps[screen])
      if(value ==  data_screen[name]) return;
      data_screen[name] = value
      this.elementPropsSetter({ [screen]: data_screen });
    },
  }
};
</script>
