<template>
  <div label-position="top" size="medium" :model="elementProps">
    <div class="d-flex flex-column py-2">
      <span class="setting-title">Font Weight</span>
      <Select
        class="mt-2"
        :primaryColor="primaryColor"
        :value="getValue('cp_font_weight',elementProps[screen].cp_font_weight)"
        v-bind:changeValue="($event) => updateProps('cp_font_weight', $event)"
        :options="[
          {
            value: '100',
            label: 'Thin 100',
          },
          {
            value: '200',
            label: 'Semi Thin 200',
          },
          {
            value: '300',
            label: 'Light 300',
          },
          {
            value: '400',
            label: 'Regular 400',
          },
          {
            value: '500',
            label: 'Medium 500',
          },
          {
            value: '600',
            label: 'Semi Bold 600',
          },
          {
            value: '700',
            label: 'Bold 700',
          },
          {
            value: '800',
            label: 'Super Bold 800',
          },
          {
            value: '900',
            label: 'Boldest 900',
          },
          {
            value: 'lighter',
            label: 'Lighter',
          },
          {
            value: 'bolder',
            label: 'Bolder',
          },
        ]"
      />
    </div>

    <div class="d-flex flex-column py-2">
      <span class="setting-title">Font Size</span>
      <Slider
        class="mt-2"
        :primaryColor="primaryColor"
        :value="getValue('cp_font_size',elementProps[screen].cp_font_size)"
        v-bind:changeValue="($event) => updateProps('cp_font_size', $event)"
        unit="px"
      />
    </div>
    <div class="d-flex flex-column py-2">
      <span class="setting-title">Text Color</span>
      <ColorPicker
        class="mt-2"
        :value="getValue('cp_color',elementProps[screen].cp_color)"
        v-bind:changeColor="($event) => updateProps('cp_color', $event)"
        :primaryColor="primaryColor"
      />
    </div>

  </div>
</template>

<script>
import mixin from "../../../elements/styleSettings/mixin";
import ColorPicker from "../../../elements/common/ColorPicker.vue";
import Slider from "../../../elements/common/Slider.vue";
import Select from "../../../elements/common/Select.vue";

export default {
  mixins: [mixin],
  components: {ColorPicker, Slider, Select},
  props: {
    screen: String,
    primaryColor: String,
  },
  methods: {
    updateProps(name, value) {
      const screen = this.$props.screen;
      const data_screen = Object.assign({}, this.elementProps[screen])
      if(value ==  data_screen[name]) return;

      data_screen[name] = value
      this.elementPropsSetter({ [screen]: data_screen });
    },
  },
};
</script>

<style scoped>
</style>
