<template>
  <div class="d-flex align-items-center custom-slider" :style="cssVars">
    <div class="flex-grow-1">
      <el-slider
        :class="type || ''"
        :show-tooltip="false"
        :min="min || 0"
        :max="max || 100"
        :step="step || 1"
        :value="value"
        @input="onChange($event)"
        @change="($event) => {
          if (changeSlider) {
            changeSlider($event)
          }
        }"
      />
    </div>
    <el-input
      class="slider-value d-flex align-items-center justify-content-center" 
      size="small"
      type="number"
      :value="inputValue"
      @input="changeInput(parseFloat($event || 0))"
      v-on:keypress.prevent="blockSpecialChar"
      :min="min || 0"
      :max="max || 100"
      :step="step || 1"

    >
      <span slot="suffix" class="text-suffix" v-if="unit">{{ unit }}</span>
    </el-input>
  </div>
</template>

<script>

export default {
  components: {
    name: 'Slider'
  },
  data() {
    return {
      inputValue: this.$props.value || 0,
      timer: null
    }
  },
  props: {
    value: Number,
    min: Number,
    max: Number,
    step: Number,
    unit: String,
    changeValue: Function,
    changeSlider: Function,
    primaryColor: String,
    type: String,
    onlyInteger: Boolean
  },
  methods:{
    onEnter(){
      // console.log('enter');
    },
    onChange($event) {
      this.inputValue = $event;
      this.changeValue($event);
    },
    changeInput($event) {
      var value = $event;
      if (this.$props.onlyInteger) {
        value = Math.floor($event)
      }
      this.inputValue = value;
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        if (value < (this.min || 0)){
          this.inputValue = Number(this.min || 0);
          this.changeValue(Number(this.min || 0))
        } else if (value > (this.max || 100)){
          this.inputValue = Number(this.max || 100);
          this.changeValue(Number(this.max || 100))
        } else if (isNaN(value)){
          this.inputValue = Number(this.min || 0);
          this.changeValue(Number(this.min || 0))
        } else {
          this.inputValue = Number(value);
          this.changeValue(Number(value))
        }
      }, 1000);
    },
    blockSpecialChar(e) {
      // console.log(e.keyCode);
      e.preventDefault();
      
    }
  },
  computed: {
    cssVars() {
      return {
        '--slider-color': this.primaryColor || '#0060e5'
      }
    }
  }
};
</script>

<style>
  .slider-value {
    width: 50px; 
    /* text-align: center; 
    height: 30px; */
    background-color: transparent;
    color: #F5F5F5;
    /* border: 1px solid #616569; */
  }

  .slider-value .el-input__suffix {
    display: flex;
    align-items: center;
  }
  .slider-value.el-input--suffix .el-input__inner {
    padding-right: 0px !important;
  }
  .slider-value .el-input__inner {
    padding: 0 6px !important;
  }

  .slider-value input::-webkit-outer-spin-button, .slider-value input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .slider-value input[type=number] {
    appearance: textfield;
    background-color: #222222;
    color: #FFF;
    border: 1px solid #717171;
  }

  .slider-value .el-input__inner:focus {
    border-color: var(--slider-color) !important;
  }

  .text-suffix {
    font-size: 12px;
    color: #8A8A8B;
  }

  .custom-slider .el-slider__bar {
    background-color: var(--slider-color, #04AA6D);
    height: 2px;
  }

  .custom-slider .el-slider__runway {
    width: 90%;
    margin: 16px 10px;
    height: 2px;
  }

  .custom-slider .el-slider__button {
    border: none;
    background: var(--slider-color, #04AA6D);
  }

  .custom-slider .el-slider__button-wrapper {
    top: -17px;
    cursor: default;
    z-index: 1;
  }
  .custom-slider .el-slider__button-wrapper .el-slider__button {
    cursor: move;
  }

</style>
<style lang="scss">
  .custom-slider {
    .slider-blur, .slider-brightness, .slider-contrast, .slider-saturation, .slider-hue {
      .el-slider__runway {
        height: 6px;
        .el-slider__bar {
          display: none;
        }
      }
      .el-slider__button-wrapper {
        top: -15px;
      }
    }
    .slider-blur .el-slider__runway {
      background-image: url('~@/assets/images/blur.png');
      background-size: cover;
      background-position: 50%;
      box-shadow: inset 0 0 1px 1px rgb(0 0 0 / 20%);
    }

    .slider-contrast .el-slider__runway {
      background-image: url('~@/assets/images/contrast.png');
      background-size: 100% 100%;
      box-shadow: inset 0 0 1px 1px rgb(0 0 0 / 20%);
    }

    .slider-brightness .el-slider__runway {
      background-image: linear-gradient(to right, #000, #CCC, #FFF);
      box-shadow: inset 0 0 1px 1px rgb(0 0 0 / 20%);
    }
    .slider-saturation .el-slider__runway {
      background-image: linear-gradient(90deg,grey,red);
    }

    .slider-hue .el-slider__runway {
      background-image: linear-gradient(90deg,red,orange,#ff0,#adff2f,#32cd32,#00bfff,#00f,#9400d3 95%);
    }
    
  }
</style>