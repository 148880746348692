<template>
  <div label-position="top" size="mini" :model="elementProps">
    <div class="d-flex align-items-center justify-content-between py-2">
      <span class="sgSubTitle">Enable or disable read more</span>
      <el-switch
        :value="elementProps.enable_read_more"
        :active-color="primaryColor"
        inactive-color="#949494"
        @change="updateProps('enable_read_more', $event)"
      />
    </div>

    <div class="d-flex flex-column py-2" v-if="elementProps.enable_read_more">
      <span class="setting-title">Visible Height*</span>
      <Slider
        class="mt-2"
        :value="elementProps.visible_height"
        :primaryColor="primaryColor"
        v-bind:changeValue="($event) => updateProps('visible_height', Number.parseInt($event))"
        unit="px"
        :min="50"
        :max="500"
        :onlyInteger="true"
      />
    </div>
    <div class="sgDesc" v-if="elementProps.enable_read_more">
      *If the content is short than Visible Height, read more will not be displayed.
    </div>
    <div class="d-flex flex-column py-2" v-if="elementProps.enable_read_more">
      <span class="setting-title">Text Read More</span>
      <el-input 
        class="mt-2"
        placeholder="Text Read More" 
        :value="elementProps.text_read_more" 
        @input="updateProps('text_read_more', $event)"
        size="medium"
      />
    </div>
    <div class="d-flex flex-column py-2" v-if="elementProps.enable_read_more">
      <span class="setting-title">Text Less</span>
      <el-input 
        class="mt-2"
        placeholder="Text Less" 
        :value="elementProps.text_less" 
        @input="updateProps('text_less', $event)"
        size="medium"
      />
    </div>
  </div>
</template>

<script>
import mixin from "../../../elements/styleSettings/mixin";
import ColorPicker from "../../../elements/common/ColorPicker.vue";
import Slider from "../../../elements/common/Slider.vue";
import ToggleButton from "../../../elements/common/ToggleButton.vue";

export default {
  mixins: [mixin],
  props: {
    primaryColor: String
  },
  components: {ColorPicker, Slider, ToggleButton},
  methods: {
    updateProps(name, value) {
      if(value ==  this.elementProps[name]) return;
      this.elementPropsSetter({ [name]: value });
    }
  },
};
</script>

<style scoped>
</style>
