<template>
  <div label-position="top" size="small" :model="elementProps">
    <div class="d-flex flex-column py-2">
      <span class="setting-title">Number of Products</span>
      <Slider
        class="mt-2"
        :value="elementProps.number_of_products"
        :primaryColor="primaryColor"
        v-bind:changeValue="($event) => updateProps('number_of_products', $event)"
        :max="30"
        :min="1"
      />
      <div class="sgDesc mt-2">
        This is the number of products displayed on one page. We recommend less than 20 products for the optimal load time.
      </div>
    </div>
  </div>
</template>

<script>
import mixin from "../../../elements/styleSettings/mixin";
import Slider from "../../../elements/common/Slider.vue";

export default {
  mixins: [mixin],
  components: {  Slider },
  props: {
    screen: String,
    primaryColor: String,
  },
  methods: {
    // updateProps(name, value) {
    //   this.elementPropsSetter({ [name]: value });
    // },
    updateProps(name, value) {
      if(value ==  this.elementProps[name]) return;
      this.elementPropsSetter({ [name]: value });
    },
  },
};
</script>

<style scoped>
</style>
