<template>
  <el-form label-position="top" size="small" :model="elementProps">
    <div class="d-flex flex-column py-2">
      <el-input
        :disabled="true"
        :value="elementProps.product_name"
      />
      <div v-if="elementProps.type == 'related'" class="text-description mt-2">
        If auto, this will displays related products of current dynamic product (in product page) or latest product.
      </div>
      <el-button
        icon="el-icon-aim"
        class="pick-btn mt-2"
        @click="() => openModalProduct()"
      >
        Pick a Product
      </el-button>
    </div>
  </el-form>
</template>

<script>
import mixin from "../../../elements/styleSettings/mixin";

export default {
  mixins: [mixin],
  components: {  },
  props: {
    product: Object,
    type: String,
  },
  watch: {
    product: function (newValue, oldValue) {
      this.elementPropsSetter({
        product_name: newValue.title,
        product_id: String(newValue.product_id)
      })
    }
  },
  methods: {
    updateProps(name, value) {
      if(value ==  this.elementProps[name]) return;
      this.elementPropsSetter({ [name]: value });
    },
    openModalProduct() {
      this.$bus.emit("showProductPicker", this.node);
    }
  },
};
</script>

<style lang="scss" scoped>
  .pick-btn {
    background-color: #494949;
    color: #F4F4F4;
    border-color: #494949;
    &:hover {
      background-color: #555555;
    }
  }
  .text-description {
    color: #D6D6D6;
    font-size: 12px;
    font-style: italic;
  }
</style>
