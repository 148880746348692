<template>
  <div label-position="top" size="" :model="elementProps">
    <div class="d-flex flex-column py-2">
      <span class="setting-title">{{ `${node?.componentName|| ''} Link` }}</span>
      <Input
        class="mt-2"
        :value="elementProps.href"
        v-bind:changeValue="($event) => updateProps('href', $event)"
        :primaryColor="primaryColor"
        placeholder="https://, #, ..."
      />
    </div>
    <div class="d-flex gap-2 align-items-center button-pick-link" @click="pickLink">
      <svg width="16" height="16" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.59413 10.4727L5.09706 10.9698V10.9698C4.1598 11.9071 2.6402 11.9071 1.70294 10.9698C0.765685 10.0325 0.765685 8.51295 1.70294 7.57569L4.24853 5.02957V5.02957C5.18472 4.09125 6.70431 4.08954 7.64263 5.02573C8.47934 5.86056 8.58367 7.1803 7.88852 8.13622" stroke="#d6d6d6" stroke-linecap="round" stroke-linejoin="round"></path>
        <path d="M8.50324 3.57511L8.90324 3.17511V3.17511C9.8405 2.23785 11.3601 2.23785 12.2974 3.17511C13.2346 4.11237 13.2346 5.63197 12.2974 6.56923L9.75177 9.11482V9.11482C8.81528 10.0528 7.29569 10.0541 6.35767 9.11758C5.52143 8.28269 5.41734 6.96332 6.11232 6.00763" stroke="#d6d6d6" stroke-linecap="round" stroke-linejoin="round"></path>
      </svg>
      Pick Link
    </div>
    <!-- <div class="d-flex flex-column py-2" v-if="elementProps.action_type == 'link' || !elementProps.action_type"> -->
      <div class="d-flex flex-column py-2">
      <span class="setting-title">Open link in</span>
      <Select
        class="mt-2"
        :primaryColor="primaryColor"
        :value="elementProps.target"
        v-bind:changeValue="($event) => updateProps('target', $event)"
        :options="[
          {
            value: '_self',
            label: 'Current Tab',
          },
          {
            value: '_blank',
            label: 'New Tab',
          },
        ]"
      />
    </div>
  </div>
</template>

<script>
import mixin from "../styleSettings/mixin";
import Select from "../common/Select.vue";
import Input from "../common/Input.vue";
import Node from "@/core/Node";

export default {
  mixins: [mixin],
  components: {
    Select, Input
  },
  props: {
    primaryColor: String,
    node: Node,
  },
  methods: {
    updateProps(name, value) {
      if (name == 'href') {
        if (!value.startsWith('#')) {
          this.elementPropsSetter({'action_type': 'product'})
        }
      }
      if(value ==  this.elementProps[name]) return;
      this.elementPropsSetter({ [name]: value });
    },
    pickLink() {
      this.$bus.emit("showButtonModal", this.node);
    },
  },
};
</script>

<style scoped>
.button-pick-link {
  background-color: #494949;
  width: fit-content;
  padding: 8px 14px;
  font-size: 14px;
  border-radius: 8px;
  transition: all 0.3s ease;
  &:hover {
    background-color: #555555;
    transition: all 0.3s ease;
  }
}
</style>
