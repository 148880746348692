<template>
  <div
    data-label="Shipping Address"
    data-key="shipping_address"
    class="sg-wrap module"
    :id="`element-${node.uuid}`"
    @mouseover="isHovering=true" 
    @mouseout="isHovering=false"
    :class="{ 'dragging': dragging == node.uuid }"
  >
    <div 
      class="bl"
      :class="extra_class"
    >
      <div class="section-title" :data-spacing="`${spacing}px`">
        <span v-if="icon && icon !== '' && icon.startsWith('fa')" class="icon-wrapper">
          <i :class="`sg_icon fa ${icon}`"></i>
        </span>
        <span v-if="icon && icon !== '' && !icon.startsWith('fa')" v-html="icon" class="icon-wrapper" />
        <span v-if="label && label !== ''" class="section-text">{{ label }}</span>
      </div>
      <form class="shipping-info">
        <slot />
      </form>
    </div>
    <ToolBar
      ref="shipping_address"
      :toolbar_id="`shipping_address`"
      class="container-toolbar"
      :class="{ 'container-toolbar-show': isHovering, 'is-dragging': dragging == node.uuid }"
    ></ToolBar>

    <v-style>
      {{ custom_css }}

      #element-{{ node.uuid }} .section-title {
        gap: {{ spacing }}px;
      }

      @media (max-width: 767px){
        #element-{{ node.uuid }} .section-text {
          {{ getValue('font_weight','mobile') ? `font-weight: ${getValue('font_weight','mobile')};` : '' }}
          {{ getValue('font_size', 'mobile') > 0 ? `font-size: ${getValue('font_size','mobile')}px;` : '' }}
          font-family: {{ getValue('font_family','mobile') }};
          {{ getValue('text_transform','mobile') ? `text-transform: ${getValue('text_transform','mobile')};` : '' }}
          {{ getValue('color','mobile') ? `color: ${getValue('color','mobile')};` : '' }}
        }
        #element-{{ node.uuid }} {
          {{ getDisplayValue('mobile') }}
          {{ getMarginValue('mobile') }}
          {{ getPaddingValue('mobile') }}
          {{ getBackgroundValue('mobile') }}
          {{ getBorderValue('mobile') }}
          {{ getBorderRadiusValue('mobile') }}
          {{ getOpacityValue('mobile') }}
          {{ getShadowValue('mobile') }}
          {{ getOtherStyle('mobile') }}
        }
      } 
      @media (max-width: 991px) and (min-width: 768px){
        #element-{{ node.uuid }} .section-text {
          {{ getValue('font_weight','tablet') ? `font-weight: ${getValue('font_weight','tablet')};` : '' }}
          {{ getValue('font_size', 'tablet') > 0 ? `font-size: ${getValue('font_size','tablet')}px;` : '' }}
          font-family: {{ getValue('font_family','tablet') }};
          {{ getValue('text_transform','tablet') ? `text-transform: ${getValue('text_transform','tablet')};` : '' }}
          {{ getValue('color','tablet') ? `color: ${getValue('color','tablet')};` : '' }}
        }
        #element-{{ node.uuid }} {
          {{ getDisplayValue('tablet') }}
          {{ getMarginValue('tablet') }}
          {{ getPaddingValue('tablet') }}
          {{ getBackgroundValue('tablet') }}
          {{ getBorderValue('tablet') }}
          {{ getBorderRadiusValue('tablet') }}
          {{ getOpacityValue('tablet') }}
          {{ getShadowValue('tablet') }}
          {{ getOtherStyle('tablet') }}
        }
      } 
      @media (max-width: 1199px) and (min-width: 992px){
        #element-{{ node.uuid }} .section-text {
          {{ getValue('font_weight','laptop') ? `font-weight: ${getValue('font_weight','laptop')};` : '' }}
          {{ getValue('font_size', 'laptop') > 0 ? `font-size: ${getValue('font_size','laptop')}px;` : '' }}
          font-family: {{ getValue('font_family','laptop') }};
          {{ getValue('text_transform','laptop') ? `text-transform: ${getValue('text_transform','laptop')};` : '' }}
          {{ getValue('color','laptop') ? `color: ${getValue('color','laptop')};` : '' }}
        }
        #element-{{ node.uuid }} {
          {{ getDisplayValue('laptop') }}
          {{ getMarginValue('laptop') }}
          {{ getPaddingValue('laptop') }}
          {{ getBackgroundValue('laptop') }}
          {{ getBorderValue('laptop') }}
          {{ getBorderRadiusValue('laptop') }}
          {{ getOpacityValue('laptop') }}
          {{ getShadowValue('laptop') }}
          {{ getOtherStyle('laptop') }}
        }
      } 
      @media (min-width: 1200px){
        #element-{{ node.uuid }} .section-text {
          {{ getValue('font_weight','desktop') ? `font-weight: ${getValue('font_weight','desktop')};` : '' }}
          {{ getValue('font_size', 'desktop') > 0 ? `font-size: ${getValue('font_size','desktop')}px;` : '' }}
          font-family: {{ getValue('font_family','desktop') }};
          {{ getValue('text_transform','desktop') ? `text-transform: ${getValue('text_transform','desktop')};` : '' }}
          {{ getValue('color','desktop') ? `color: ${getValue('color','desktop')};` : '' }}
        }
        #element-{{ node.uuid }} {
          {{ getDisplayValue('desktop') }}
          {{ getMarginValue('desktop') }}
          {{ getPaddingValue('desktop') }}
          {{ getBackgroundValue('desktop') }}
          {{ getBorderValue('desktop') }}
          {{ getBorderRadiusValue('desktop') }}
          {{ getOpacityValue('desktop') }}
          {{ getShadowValue('desktop') }}
          {{ getOtherStyle('desktop') }}
        }
      }

    </v-style>
  </div>

</template>

<script>
import ExtraClass from "../otherSettings/ExtraClass.vue";
import LabelIcon from "../otherSettings/LabelIcon.vue";
import elementStyleMixin from "../elementStyleMixin";
import ToolBar from "../utils/ToolBar.vue";
import { designStyles, otherProps, otherStyles, actionTypes } from "@/constants";
import CustomCSS from "../styleSettings/CustomCSS.vue";
import Typography from "../styleSettings/Typography.vue";
import Animations from "../designSettings/Animations.vue";
import Spacing from "../designSettings/Spacing.vue";
import Background from "../designSettings/Background.vue";
import Border from "../designSettings/Border.vue";
import Corner from "../designSettings/Corner.vue";
import Shadow from "../designSettings/Shadow.vue";
import Opacity from "../designSettings/Opacity.vue";

export default {
  mixins: [elementStyleMixin],
  components: { ToolBar },
  props: {
    ...otherProps,
    label: String,
    icon: String,
    spacing: Number,
    component: String,
    extra_class:String,
    desktop: Object,
    laptop: Object,
    mobile: Object,
    tablet: Object,
    disabled: Boolean,
  },
  data() {
    return {
      isHovering: false,
    };
  },
  inject: ["node",'editor'],
  computed: {
    dragging: function () {
      return this.editor?.draggedNode?.uuid
    },
  },
  craft: {
    defaultProps: {
      ...otherStyles,
      disabled: true,
      extra_class: "",
      spacing: 10,
      label: 'Shipping Address',
      icon: 'fa-map-marker',
      desktop: {
        ...designStyles,
        font_weight: '500',
        font_size: 16,
        font_family: 'inherit',
        color: 'rgb(0, 0, 0)',
        alignment: 'left',
        padding_top: 30,
        padding_bottom: 10,
        padding_left: 16,
        padding_right: 16
      },
      laptop: {
        ...designStyles,
      },
      mobile: {
        ...designStyles,
      },
      tablet: {
        ...designStyles,
      },
      typographyActives: ['font-weight', 'font-size', 'font-family', 'color'],
      elementStyle: {
        
      },
    },
    settings: {
      settings: {
        "Label & Icon": LabelIcon,
        "Typography": Typography,
        "Extra Class": ExtraClass,
        "Custom CSS": CustomCSS,
      },
      design: {
        Spacing: { Spacing, multi_screen: true },
        Background: { Background, multi_screen: true },
        Border: { Border, multi_screen: true },
        Corner: { Corner, multi_screen: true },
        Shadow: { Shadow, multi_screen: true },
        Opacity: { Opacity, multi_screen: true },
      },
      others: {
        Animations: { Animations, actions: actionTypes }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  width: 100%;
  box-sizing: border-box;
  white-space: pre-wrap;
}
</style>
