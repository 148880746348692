<template>
  <div label-position="top" size="mini" :model="elementProps">
    <div class="d-flex flex-column py-2">
      <span class="setting-title">Default Image</span>
      <Upload
        :value="
          multi_screen ? elementProps.imgUrl : elementProps.imgUrl
        "
        v-bind:changeValue="($event) => updateProps('imgUrl', $event)"
      />
    </div>
    <div class="d-flex flex-column py-2">
      <span class="setting-title">Image Src</span>
      <el-input
        class="mt-2"
        placeholder="Link"
        :value="
          multi_screen ? elementProps.imgUrl : elementProps.imgUrl
        "
        @input="updateProps('imgUrl', $event)"
        size="medium"
      />
    </div>
    <div class="d-flex flex-column py-2">
      <span class="setting-title">Image Quality</span>
      <Select
        class="mt-2"
        :primaryColor="primaryColor"
        :value="elementProps.quality"
        v-bind:changeValue="($event) => updateProps('quality', $event)"
        :options="[
          {
            value: 'lighter',
            label: 'Lighter',
          },
          {
            value: 'lightest',
            label: 'Lightest',
          },
        ]"
      />
      <span class="sgSubTitle mt-2">Only support for UCare CDN</span>
    </div>
    <div class="d-flex flex-column py-2">
      <span class="setting-title">Hover Effect</span>
      <Select
        class="mt-2"
        :primaryColor="primaryColor"
        :value="elementProps.hover_effect"
        v-bind:changeValue="($event) => updateProps('hover_effect', $event)"
        :options="[
          {
            value: 'none',
            label: 'None',
          },
          {
            value: 'effect-zoom',
            label: 'Zoom',
          },
          {
            value: 'effect-parallax',
            label: 'Parallax',
          }
        ]"
      />
    </div>
    <div class="d-flex flex-column py-2" v-if="elementProps.hover_effect!='none'">
      <span class="setting-title">Hover Effect Time</span>
      <Slider
        class="mt-2"
        :value="elementProps.hover_effect_time"
        :primaryColor="primaryColor"
        v-bind:changeValue="($event) => updateProps('hover_effect_time', $event)"
        :max="3"
        :min="0"
        :step="0.1"
      />
      <span class="mt-2 sgSubTitle">Transition time in if custom effect is applied. Eg: 0.5s</span>
    </div>

    <div class="d-flex flex-column py-2">
      <span class="setting-title">Image Size</span>
      <Select
        class="mt-2"
        :primaryColor="primaryColor"
        :value="elementProps.image_size"
        v-bind:changeValue="($event) => updateProps('image_size', $event)"
        :options="[
          {
            value: '100x100',
            label: 'Small (100x100)',
          },
          {
            value: '240x240',
            label: 'Medium (240x240)',
          },
          {
            value: '480x480',
            label: 'Large (480x480)',
          },
          {
            value: '600x600',
            label: 'Grande (600x600)',
          },
          {
            value: '1024x1024',
            label: '1024x1024',
          },
          {
            value: '2048x2048',
            label: '2048x2048',
          },
          {
            value: 'master',
            label: 'Original',
          }
        ]"
      />
    </div>
  </div>
</template>
  
  <script>
import mixin from "../../../elements/styleSettings/mixin";
import Upload from "../../../elements/common/Upload.vue";
import Select from "../../../elements/common/Select.vue";
import Slider from "../../../elements/common/Slider.vue";

export default {
  mixins: [mixin],
  props: {
    screen: String,
    multi_screen: Boolean,
  },
  components: {
    Upload,
    Select,
    Slider
  },
  methods: {
    updateProps(name, value) {
        this.elementPropsSetter({ [name]: value });

    },
    // updateProps(name, value) {
    //   if (this.$props.multi_screen) {
    //     const screen = this.$props.screen;
    //     const { desktop, laptop, tablet, mobile } = this.elementProps;
    //     const data_screen = Object.assign({}, this.elementProps);
    //     if (value == data_screen[name]) return;

    //     data_screen[name] = value;
    //     var obj = {
    //       : data_screen,
    //     };
    //     if (screen == "desktop") {
    //       if (!Object.keys(laptop).includes(name)) {
    //         laptop[name] = value;
    //         obj["laptop"] = laptop;
    //       }
    //       if (!Object.keys(tablet).includes(name)) {
    //         tablet[name] = value;
    //         obj["laptop"] = tablet;
    //       }
    //       if (!Object.keys(mobile).includes(name)) {
    //         mobile[name] = value;
    //         obj["laptop"] = mobile;
    //       }
    //     }
    //     this.elementPropsSetter(obj);
    //   } else {
    //     this.elementPropsSetter({ [name]: value });
    //   }
    // },
  },
};
</script>
  