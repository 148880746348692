<template>
  <div
    data-key="icon-list"
    data-label="Icon List"
    class="sg-wrap module sg-icon-list"
    @mouseover="isHovering=true" 
    @mouseout="isHovering=false"
    :id="`element-${node.uuid}`"
  >
    <div 
      class="icon-list-wrapper" 
      :class="extra_class"
    >
      <slot />
    </div>
    <ToolBar ref="icon_list_toolbar" :toolbar_id="`icon_list`" class="container-toolbar" 
    :class="{ 'container-toolbar-show': isHovering, 'is-dragging': dragging == node.uuid }"
    ></ToolBar>
    <div v-if="dragging == node.uuid" class="dragging"></div>

    <v-style>
      {{ custom_css }}
      #element-{{ node.uuid }} .icon-list-wrapper .sg-icon-list-item [data-key="icon"] {
        width: {{ icon_width }}px;
      }
      #element-{{ node.uuid }} .icon-list-wrapper .sg-icon-list-item [data-key="paragraph"] {
        margin-left: {{ icon_width }}px;
        max-width: calc(100% - {{ icon_width }}px);
      }
      #element-{{ node.uuid }} .sg-icon-list-item {
        margin-bottom: {{ line_spacing }}px;
      }
      @media (max-width: 767px) {
        #element-{{ node.uuid }} {
          text-align: {{ getValue('alignment','mobile') }};
          {{ getDisplayValue('mobile') }}
          {{ getMarginValue('mobile') }}
          {{ getPaddingValue('mobile') }}
          {{ getBackgroundValue('mobile') }}
          {{ getBorderValue('mobile') }}
          {{ getBorderRadiusValue('mobile') }}
          {{ getOpacityValue('mobile') }}
          {{ getShadowValue('mobile') }}
          {{ getOtherStyle('mobile') }}
        }
      }
      @media (max-width: 991px) and (min-width: 768px) {
        #element-{{ node.uuid }} {
          text-align: {{ getValue('alignment','tablet') }};
          {{ getDisplayValue('tablet') }}
          {{ getMarginValue('tablet') }}
          {{ getPaddingValue('tablet') }}
          {{ getBackgroundValue('tablet') }}
          {{ getBorderValue('tablet') }}
          {{ getBorderRadiusValue('tablet') }}
          {{ getOpacityValue('tablet') }}
          {{ getShadowValue('tablet') }}
          {{ getOtherStyle('tablet') }}
        }
      }
      @media (max-width: 1199px) and (min-width: 992px) {
        #element-{{ node.uuid }} {
          text-align: {{ getValue('alignment','laptop') }};
          {{ getDisplayValue('laptop') }}
          {{ getMarginValue('laptop') }}
          {{ getPaddingValue('laptop') }}
          {{ getBackgroundValue('laptop') }}
          {{ getBorderValue('laptop') }}
          {{ getBorderRadiusValue('laptop') }}
          {{ getOpacityValue('laptop') }}
          {{ getShadowValue('laptop') }}
          {{ getOtherStyle('laptop') }}
        }
      }
      @media (min-width: 1200px) {
        #element-{{ node.uuid }} {
          text-align: {{ desktop.alignment }};
          {{ getDisplayValue('desktop') }}
          {{ getMarginValue('desktop') }}
          {{ getPaddingValue('desktop') }}
          {{ getBackgroundValue('desktop') }}
          {{ getBorderValue('desktop') }}
          {{ getBorderRadiusValue('desktop') }}
          {{ getOpacityValue('desktop') }}
          {{ getShadowValue('desktop') }}
          {{ getOtherStyle('desktop') }}
        }
      }
    </v-style>
  </div>
  
</template>

<script>
import elementStyleMixin from '../../elements/elementStyleMixin';
import ToolBar from '../../elements/utils/ToolBar.vue';
import Visibility from '../../elements/designSettings/Visibility.vue';
import Spacing from '../../elements/designSettings/Spacing.vue';
import BackgroundDesign from '../../elements/designSettings/Background.vue';
import Border from '../../elements/designSettings/Border.vue';
import Corner from '../../elements/designSettings/Corner.vue';
import Shadow from '../../elements/designSettings/Shadow.vue';
import Opacity from '../../elements/designSettings/Opacity.vue';
import ExtraClass from '../../elements/otherSettings/ExtraClass.vue';
import Items from '../IconListHoz/settings/Items.vue';
import AlignmentBase from '../../elements/styleSettings/AlignmentBase.vue';
import { designStyles, makeId, otherProps, otherStyles, actionTypes } from '@/constants';
import LineSpacing from './settings/LineSpacing.vue';
import IconWidth from './settings/IconWidth.vue';
import CustomCSS from '../../elements/styleSettings/CustomCSS.vue';
import Animations from '../../elements/designSettings/Animations.vue';

export default {
  components: { ToolBar },
  mixins: [elementStyleMixin],
  inject: [
    'node',
    'editor'
  ],
  computed: {
    dragging: function () {
      return this.editor?.draggedNode?.uuid
    },
  },
  props: {
    ...otherProps,
    component: String,
    
    extra_class: String,
    items: Array,
    icon_width: Number,
    line_spacing: Number,
    desktop: Object,
    laptop: Object,
    tablet: Object,
    mobile: Object,
    disabled: Boolean,
  },
  watch: {
    dragging: function (newValue, oldValue) {
      var $iframe = $("#designEditor").contents();
      var $element = $iframe.find(`#element-${this.node.uuid}`);
      var $content = $element.find(`.icon-list-wrapper`);
      if (newValue && newValue == this.node.uuid) {
        setTimeout(() => {
          $element.css("padding","0px 0px 0px 0px");
          $element.css("margin","0px 0px 0px 0px");
          $content.hide();
        },100);
      }else if (oldValue == this.node.uuid){
        $element.css("padding","");
        $element.css("margin","");
        $content.show();
      }
    },
  },
  data() {
    return {
      isHovering: false,
    }
  },
  craft: {
    defaultProps: {
      ...otherStyles,
      extra_class: "",
      items: Array.from(Array(3).keys()).map(e => ({id: makeId(20), name: `Item ${e + 1}`})),
      icon_width: 50,
      desktop: {
        ...designStyles,
        padding_top: 30,
        alignment: 'center'
      },
      laptop: {
        ...designStyles,
      },
      mobile: {
        ...designStyles,
      },
      tablet: {
        ...designStyles,
      },
      elementStyle: {
        
      },
    },
    settings: {
      settings: {
        Items,
        "Icon Width": IconWidth,
        "Spacing": LineSpacing,
        Alignment: { Alignment: AlignmentBase, multi_screen: true },
        "Extra Class": ExtraClass, "Custom CSS": CustomCSS,
      },
      design: {
        Visibility,
        Spacing: { Spacing, multi_screen: true },
        Background: { "Background": BackgroundDesign, multi_screen: true },
        Border: { Border, multi_screen: true },
        Corner: { Corner, multi_screen: true },
        Shadow: { Shadow, multi_screen: true },
        Opacity: { Opacity, multi_screen: true },
      },
      others: {
        Animations: { Animations, actions: actionTypes.filter(e => ['scroll_into_view', 'after_page_finish_loading'].includes(e.value)) }
      }
    },
  },
};
</script>

<style lang="scss" scoped>

.dragging{
  position:absolute;
  top:0;
  bottom: 0;
  left: 0;
  right:0;
  background: greenyellow;
  z-index: 10;
}

</style>
