<template>
  <div label-position="top" size="mini" :model="elementProps">
    <div class="d-flex flex-column py-2">
      <span class="setting-title">Alignment</span>
      <TabIcon
        class="mt-2"
        :value="elementProps.alignment"
        v-bind:changeValue="($event) => updateProps('alignment', $event)"
        :options="[
          { value: 'left', icon: 'align-left',tooltip: 'Left' },
          { value: 'center', icon: 'align-center',tooltip: 'Center' },
          { value: 'right', icon: 'align-right', tooltip: 'Right' },
        ]"
        :primaryColor="primaryColor"
      />
    </div>
  </div>
</template>

<script>
import mixin from "../../../elements/styleSettings/mixin";
import TabIcon from "../../../elements/common/TabIcon.vue";
export default {
  name: "Alignment",
  mixins: [mixin],
  components: {
    TabIcon
  },
  props: {
    primaryColor: String,
  },
  methods: {
    updateProps(name, value) {
      this.elementPropsSetter({ [name]: value });
    },
  }
};
</script>
