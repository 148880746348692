<template>
  <div label-position="top" size="mini" :model="elementProps">
    <div class="py-2">
      <span class="setting-title">Views</span>
      <ToggleButton
        :value="elementProps.view_mode"
        v-bind:changeValue="($event) => updateProps('view_mode', $event)"
        :options="[
          { value: 'map', label: 'Map' },
          { value: 'satellite', label: 'Satellite' },
        ]"
        :primaryColor="primaryColor"
      />
    </div>
  </div>
</template>

<script>

import mixin from '../../../elements/styleSettings/mixin';
import ToggleButton from '../../../elements/common/ToggleButton.vue';

export default {
  mixins: [mixin],
  props: {
    screen: String,
    primaryColor: String,
  },
  components: {
    ToggleButton,
  },
  methods: {
    updateProps(name, value) {
      this.elementPropsSetter({ [name]: value });
    },
  },
};
</script>

<style>
</style>
