<template>
  <el-form label-position="top" size="" :model="elementProps">
    <div class="d-flex py-2 align-items-center justify-content-between">
      <div class="setting-title mr-2">Height</div>
      <el-input
        class="margin-input-1"
        :value="elementProps[screen]?.height"
        @input="($event) => updateProps(screen, 'height', $event)"
        size="medium"
      >
        <template slot="append">px</template>
      </el-input>
    </div>
    <div class="py-2">
      <span class="setting-title">Content Align</span>
      <ToggleButton
        :primaryColor="primaryColor"
        :value="getValueLinked('content_align', elementProps[screen]?.content_align, 'center')"
        v-bind:changeValue="
          ($event) => updateProps(screen, 'content_align', $event)
        "
        :options="[
          { value: 'start', label: 'Start' },
          { value: 'center', label: 'Center' },
          { value: 'end', label: 'End' },
        ]"
      />
    </div>
  </el-form>
</template>
    
    <script>
import mixin from "../../../elements/styleSettings/mixin";
import ToggleButton from "../../../elements/common/ToggleButton.vue";
import ColorPicker from "../../../elements/common/ColorPicker.vue";
import Upload from "../../../elements/common/Upload.vue";
import Select from "../../../elements/common/Select.vue";
import Slider from "../../../elements/common/Slider.vue";

export default {
  mixins: [mixin],
  components: {
    ToggleButton,
    ColorPicker,
    Upload,
    Select,
    Slider,
  },
  props: {
    screen: String,
    primaryColor: String,
  },
  methods: {
    updateProps(screen, name, value) {
      const { desktop, laptop, tablet, mobile } = this.elementProps;
      const data_screen = Object.assign({}, this.elementProps[screen]);
      if (value == data_screen[name]) return;

      data_screen[name] = value;
      var obj = {
        [screen]: data_screen,
      };
      this.elementPropsSetter(obj);
    },
  },
};
</script>
    
    <style lang="scss">
.margin-input-1 {
  width: 50%;
}
.margin-input-1 .el-input-group__append {
  border: 1px solid #494949;
  border-left: none;
  background-color: #222222;
  color: rgb(117 117 117);
}
.margin-input-1 .el-input__inner {
  border-right: none !important;
}
</style>
    