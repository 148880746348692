<template>
  <div 
    class="tab"
    :id="`element-item-${node.uuid}`"
    :data-tabindex="item.index"
  >
    <input class="accordion-input" :checked="node.parent?.props.tab_index == item.index ? true : false" :type="node.parent?.props.accordion_type == 'single' ? 'radio' : 'checkbox'" :id="`input-${item.id}-${node.parent.uuid}`" :name="`accordion_item-${node.parent.uuid}`">
    <label :for="`input-${item.id}-${node.parent.uuid}`" class="tab-label">{{ item.name }}</label>
    <label :for="`input-${item.id}-${node.parent.uuid}`" class="icon chevron" :class="node.parent?.props.icon_position" v-if="node.parent?.props.icon_type == 'chevron'">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" :stroke="node.parent?.props.icon_color" fill="none" stroke-linecap="round" stroke-linejoin="round">
        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
        <polyline points="6 9 12 15 18 9"></polyline>
      </svg>
    </label>
    <label :for="`input-${item.id}-${node.parent.uuid}`" class="icon plus" :class="node.parent?.props.icon_position" v-else-if="node.parent?.props.icon_type == 'plus'">
      <div class="icon-wrapper">
        <div class="one"></div>
        <div class="two"></div>
      </div>
    </label>
    <div class="tab-content" :style="{background: item.color || 'transparent'}">
      <div v-if="editor?.findNode(node?.uuid)?.children?.length == 0" class="text-center drop-element">
        <span>Add element</span>
      </div>
      <slot />
    </div>
  </div>
</template>

<script>
import elementStyleMixin from '../../elements/elementStyleMixin';
import ToolBar from '../../elements/utils/ToolBar.vue';

export default {
  components: {ToolBar},
  mixins: [elementStyleMixin],
  inject: [
    'node',
    'editor'
  ],
  props: {
    component: String,
    
    item: Object,
  },
  data() {
    return {
      isHovering: false,
    }
  },
  craft: {
    defaultProps: {
      elementStyle: {

      },
    },
    settings: {
      settings: {
      },
      design: {
      }
    },
  },
};
</script>

<style lang="scss" scoped>

.dragging{
  position:absolute;
  top:0;
  bottom: 0;
  left: 0;
  right:0;
  background: greenyellow;
  z-index: 10;
}

</style>
