<template>
  <div label-position="top" size="mini" :model="elementProps">
    <div class="py-2 input-number">
      <span class="setting-title">Number</span>
      <Input
        class="mt-2"
        :value="elementProps.number"
        v-bind:changeValue="($event) => updateProps('number', $event || '')"
        type="number"
        size="medium"
        :primaryColor="primaryColor"
      />
    </div>
    <div class="py-2">
      <span class="setting-title">Pre Text</span>
      <Input
        class="mt-2"
        :value="elementProps.prefix"
        v-bind:changeValue="($event) => updateProps('prefix', $event || '')"
        type="text"
        size="medium"
        :primaryColor="primaryColor"
      />
    </div>
    <div class="py-2">
      <span class="setting-title">After Text</span>
      <Input
        class="mt-2"
        :value="elementProps.suffix"
        v-bind:changeValue="($event) => updateProps('suffix', $event || '')"
        type="text"
        size="medium"
        :primaryColor="primaryColor"
      />
    </div>
  </div>
</template>

<script>
import mixin from '../../../elements/styleSettings/mixin';
import Slider from '../../../elements/common/Slider.vue';
import Input from '../../../elements/common/Input.vue';

export default {
  mixins: [mixin],
  components: {
    Slider, Input
  },
  props: {
    primaryColor: String
  },
  methods: {
    updateProps (name, value) {
      if(value ==  this.elementProps[name]) return;
      this.elementPropsSetter({ [name]: value });
    }
  },
};
</script>

<style>
.input-number input::-webkit-outer-spin-button, .input-number input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
