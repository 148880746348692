<template>
  <div label-position="top" size="" :model="elementProps">
    <draggable
      tag="el-collapse"
      :list="elementProps.items"
      :component-data="collapseComponentData()"
      @end="listMoved"
      class="list-items"
    >
      <el-collapse-item
        v-for="item in elementProps.items"
        :key="item.id"
        :title="`Item ${item.id}`"
        :name="item.id"
      >
        <div class="d-flex flex-column py-2">
          <span class="setting-title">Title</span>
          <Input
            class="mt-2"
            :value="getItemData('heading', item.id)"
            v-bind:changeValue="($event) => setItemData('heading', item.id, $event)"
            :primaryColor="primaryColor"
          />
        </div>
        <div class="d-flex flex-column py-2">
          <span class="setting-title">Price</span>
          <Input
            class="mt-2"
            :value="getItemData('price', item.id)"
            v-bind:changeValue="($event) => setItemData('price', item.id, $event)"
            :primaryColor="primaryColor"
            type="number"
          />
        </div>
        <div class="d-flex flex-column py-2">
          <span class="setting-title">Plan</span>
          <Input
            class="mt-2"
            :value="getItemData('plan', item.id)"
            v-bind:changeValue="($event) => setItemData('plan', item.id, $event)"
            :primaryColor="primaryColor"
          />
        </div>
      </el-collapse-item>
    </draggable>
  </div>
</template>

<script>
import mixin from "../styleSettings/mixin";
import draggable from "vuedraggable";
import Input from "../common/Input.vue";
export default {
  mixins: [mixin],
  components: {
    draggable, Input
  },
  props: {
    primaryColor: String
  },
  data() {
    return {
      list: [
        {
          id: 1,
          heading: 'Beginner',
          price: '49',
          plan: 'yr'
        },
        {
          id: 2,
          heading: 'Intermediate',
          price: '99',
          plan: 'yr'
        },
        {
          id: 3,
          heading: 'Advanced',
          price: '149',
          plan: 'yr'
        },
        {
          id: 4,
          heading: 'Professional',
          price: '199',
          plan: 'yr'
        },
      ],
      // node: this.node,
      activeNames: [],
      
    }
  },
  methods: {
    getItemData(key, id) {
      const child = this.node.children.find(e => e.props.item.id == id)
      if (!child) {
        const item = JSON.parse(JSON.stringify(this.elementProps.items)).find(e => e.id == id)
        return item[key]
      }
      return child.props.item[key]
    },
    setItemData(key, id, value) {
      const child = this.node.children.find(e => e.props.item.id == id)
      if (child) {
        const item = Object.assign(JSON.parse(JSON.stringify(child.props.item)))
        item[key] = value
        child.setProps({ item })
      } else {
        var items = JSON.parse(JSON.stringify(this.elementProps.items))
        const item = items.find(e => e.id == id)
        item[key] = value
        this.node.setProps({ items })
      }
    },
    collapseComponentData() {
      return {
        on: {
          input: this.inputChanged,
        },
        props: {
          value: this.activeNames
        }
      }
    },
    inputChanged(val) {
      this.activeNames = val;
    },
    updateProps(name, value) {
      if(value ==  this.elementProps[name]) return;
      this.elementPropsSetter({ [name]: value });
    },
    listMoved() {
      const ids = this.elementProps.items.map(e => e.id)
      const children = []
      ids.slice(0, Number.parseInt(this.elementProps.number_of_items)).map(id => {
        const item_child = this.node.children.find(e => e.props.item.id == id)
        if (item_child) {
          children.push(item_child)
        } else {
          const item = JSON.parse(JSON.stringify(this.elementProps.items)).find(e => e.id == id)
          const new_child = this.node.children[0].duplicate()
          new_child.setProps({item})
          new_child.parent = this.node
          new_child.children = []
          children.push(new_child)
        }
      })
      this.node.children = children
    }
  },
};
</script>

<style lang="scss" scoped>
.list-items {
  .el-collapse-item  {
    background-color: #444444 !important;
    margin-bottom: 8px;
  }
}
</style>
