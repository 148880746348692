<template>
  <div
    data-label="Progress"
    data-key="progress"
    class="sg-wrap module"
    :id="`element-${node.uuid}`"
    @mouseover="isHovering = true"
    @mouseout="isHovering = false"
    :class="{ 'dragging': dragging == node.uuid }"
  >
    <div
      class="sg-module"
      :class="extra_class"
    >
      <div class="progress-container" :class="progress < 50 ? 'less' : ''">
        <div class="text">
          <h3>{{ progress }}<span v-if="unit">{{ unit }}</span></h3>
          <h4 v-if="label">{{ label }}</h4>
        </div>
      </div>
    </div>

    <ToolBar
      ref="progress"
      :toolbar_id="`progress`"
      class="container-toolbar"
      :class="{ 'container-toolbar-show': isHovering, 'is-dragging': dragging == node.uuid }"
    >
    </ToolBar>

    <v-style>
      {{ custom_css }}

      #element-{{ node.uuid }} .progress-container {
        background: {{ border_inactive_color }} linear-gradient(to right, transparent 50%, {{ border_active_color }} 0);
      }

      #element-{{ node.uuid }} .progress-container::before {
        background: {{ border_active_color }};
        transform: rotate(calc(({{ progress }} - 50) * 0.01turn));
      }

      #element-{{ node.uuid }} .progress-container::after {
        inset: {{ border_width }}px;
        background-color: {{ progress_background }};
      }

      #element-{{ node.uuid }} .progress-container.less::before {
        background: {{ border_inactive_color }};
        transform: rotate(calc(({{ progress }} - 0) * 0.01turn));
      }
 
      @media (max-width: 767px) {
        #element-{{ node.uuid }} .progress-container {
          width: {{ getValue('size', 'mobile') }}px;
          height: {{ getValue('size', 'mobile') }}px;
          color: {{ getValue('color', 'mobile') }};
        }

        #element-{{ node.uuid }} .progress-container h3 {
          font-size: {{ getValue('font_size', 'mobile') }}px;
          font-weight: {{ getValue('font_weight', 'mobile') }};
          font-family: {{ getValue('font_family', 'mobile') }};
        }

        #element-{{ node.uuid }} .progress-container h3 span {
          font-size: {{ getValue('unit_font_size', 'mobile') }}px;
          font-weight: {{ getValue('unit_font_weight', 'mobile') }};
          font-family: {{ getValue('unit_font_family', 'mobile') }};
        }

        #element-{{ node.uuid }} .progress-container h4 {
          font-weight: {{ getValue('label_font_weight', 'mobile') }};
          color: {{ getValue('label_color', 'mobile') }};
          font-size: {{ getValue('label_font_size', 'mobile') }};
          font-family: {{ getValue('label_font_family', 'mobile') }};
          text-transform: {{ getValue('label_text_transform', 'mobile') }};
        }

        #element-{{ node.uuid }} {
          {{ getDisplayValue('mobile') }}
          {{ getMarginValue('mobile') }}
          {{ getPaddingValue('mobile') }}
          {{ getBackgroundValue('mobile') }}
          {{ getBorderValue('mobile') }}
          {{ getBorderRadiusValue('mobile') }}
          {{ getOpacityValue('mobile') }}
          {{ getShadowValue('mobile') }}
          {{ getOtherStyle('mobile') }}
        }
      } 
      @media (max-width: 991px) and (min-width: 768px) {
        #element-{{ node.uuid }} .progress-container {
          width: {{ getValue('size', 'tablet') }}px;
          height: {{ getValue('size', 'tablet') }}px;
          color: {{ getValue('color', 'tablet') }};
        }

        #element-{{ node.uuid }} .progress-container h3 {
          font-size: {{ getValue('font_size', 'tablet') }}px;
          font-weight: {{ getValue('font_weight', 'tablet') }};
          font-family: {{ getValue('font_family', 'tablet') }};
        }

        #element-{{ node.uuid }} .progress-container h3 span {
          font-size: {{ getValue('unit_font_size', 'tablet') }}px;
          font-weight: {{ getValue('unit_font_weight', 'tablet') }};
          font-family: {{ getValue('unit_font_family', 'tablet') }};
        }

        #element-{{ node.uuid }} .progress-container h4 {
          font-weight: {{ getValue('label_font_weight', 'tablet') }};
          color: {{ getValue('label_color', 'tablet') }};
          font-size: {{ getValue('label_font_size', 'tablet') }};
          font-family: {{ getValue('label_font_family', 'tablet') }};
          text-transform: {{ getValue('label_text_transform', 'tablet') }};
        }

        #element-{{ node.uuid }} {
          {{ getDisplayValue('tablet') }}
          {{ getMarginValue('tablet') }}
          {{ getPaddingValue('tablet') }}
          {{ getBackgroundValue('tablet') }}
          {{ getBorderValue('tablet') }}
          {{ getBorderRadiusValue('tablet') }}
          {{ getOpacityValue('tablet') }}
          {{ getShadowValue('tablet') }}
          {{ getOtherStyle('tablet') }}
        }
      } 
      @media (max-width: 1199px) and (min-width: 992px) {
        #element-{{ node.uuid }} .progress-container {
          width: {{ getValue('size', 'laptop') }}px;
          height: {{ getValue('size', 'laptop') }}px;
          color: {{ getValue('color', 'laptop') }};
        }

        #element-{{ node.uuid }} .progress-container h3 {
          font-size: {{ getValue('font_size', 'laptop') }}px;
          font-weight: {{ getValue('font_weight', 'laptop') }};
          font-family: {{ getValue('font_family', 'laptop') }};
        }

        #element-{{ node.uuid }} .progress-container h3 span {
          font-size: {{ getValue('unit_font_size', 'laptop') }}px;
          font-weight: {{ getValue('unit_font_weight', 'laptop') }};
          font-family: {{ getValue('unit_font_family', 'laptop') }};
        }

        #element-{{ node.uuid }} .progress-container h4 {
          font-weight: {{ getValue('label_font_weight', 'laptop') }};
          color: {{ getValue('label_color', 'laptop') }};
          font-size: {{ getValue('label_font_size', 'laptop') }};
          font-family: {{ getValue('label_font_family', 'laptop') }};
          text-transform: {{ getValue('label_text_transform', 'laptop') }};
        }

        #element-{{ node.uuid }} {
          {{ getDisplayValue('laptop') }}
          {{ getMarginValue('laptop') }}
          {{ getPaddingValue('laptop') }}
          {{ getBackgroundValue('laptop') }}
          {{ getBorderValue('laptop') }}
          {{ getBorderRadiusValue('laptop') }}
          {{ getOpacityValue('laptop') }}
          {{ getShadowValue('laptop') }}
          {{ getOtherStyle('laptop') }}
        }
      } 
      @media (min-width: 1200px) { 
        #element-{{ node.uuid }} .progress-container {
          width: {{ getValue('size', 'desktop') }}px;
          height: {{ getValue('size', 'desktop') }}px;
          color: {{ getValue('color', 'desktop') }};
        }

        #element-{{ node.uuid }} .progress-container h3 {
          font-size: {{ getValue('font_size', 'desktop') }}px;
          font-weight: {{ getValue('font_weight', 'desktop') }};
          font-family: {{ getValue('font_family', 'desktop') }};
        }

        #element-{{ node.uuid }} .progress-container h3 span {
          font-size: {{ getValue('unit_font_size', 'desktop') }}px;
          font-weight: {{ getValue('unit_font_weight', 'desktop') }};
          font-family: {{ getValue('unit_font_family', 'desktop') }};
        }

        #element-{{ node.uuid }} .progress-container h4 {
          font-weight: {{ getValue('label_font_weight', 'desktop') }};
          color: {{ getValue('label_color', 'desktop') }};
          font-size: {{ getValue('label_font_size', 'desktop') }};
          font-family: {{ getValue('label_font_family', 'desktop') }};
          text-transform: {{ getValue('label_text_transform', 'desktop') }};
        }

        #element-{{ node.uuid }} {
          {{ getDisplayValue('desktop') }}
          {{ getMarginValue('desktop') }}
          {{ getPaddingValue('desktop') }}
          {{ getBackgroundValue('desktop') }}
          {{ getBorderValue('desktop') }}
          {{ getBorderRadiusValue('desktop') }}
          {{ getOpacityValue('desktop') }}
          {{ getShadowValue('desktop') }}
          {{ getOtherStyle('desktop') }}
        }
      }
    </v-style>
  </div>
</template>

<script>
import ToolBar from "../../elements/utils/ToolBar.vue";
import elementStyleMixin from "../../elements/elementStyleMixin";
import ExtraClass from "../../elements/otherSettings/ExtraClass.vue";

// import Number from "./settings/Number.vue";
// import Typography from "./settings/Typography.vue";
import Progress from "./settings/Progress.vue";
import Unit from "./settings/Unit.vue";
import Label from "./settings/Label.vue";
import BorderSetting from './settings/Border.vue';
import ProgressStyle from './settings/ProgressStyle.vue';
import LabelStyle from './settings/LabelStyle.vue';
import UnitStyle from './settings/UnitStyle.vue';

import Visibility from "../../elements/designSettings/Visibility.vue";
import Spacing from "../../elements/designSettings/Spacing.vue";
import Background from "../../elements/designSettings/Background.vue";
import Border from "../../elements/designSettings/Border.vue";
import Corner from "../../elements/designSettings/Corner.vue";
import Shadow from "../../elements/designSettings/Shadow.vue";
import Opacity from "../../elements/designSettings/Opacity.vue";

import { designStyles, otherProps, otherStyles, actionTypes } from '@/constants';
// import AlignmentBase from '../../elements/styleSettings/AlignmentBase.vue';
import CustomCSS from '../../elements/styleSettings/CustomCSS.vue';
import Animations from '../../elements/designSettings/Animations.vue';

export default {
  components: { ToolBar },
  computed: {
    dragging: function () {
      return this.editor?.draggedNode?.uuid
    },
  },
  data() {
    return {
      isHovering: false,
    };
  },
  mixins: [elementStyleMixin],
  props: {
    ...otherProps,
    extra_class: String,
    component: String,
    progress: Number,
    unit: String,
    label: String,
    progress_background: String,
    border_active_color: String,
    border_inactive_color: String,
    border_width: Number,
    desktop: Object,
    laptop: Object,
    tablet: Object,
    mobile: Object,
  },
  inject: ["node","editor"],
  mounted() {
    
  },
  watch:{
    
  },
  craft: {
    defaultProps: {
      ...otherStyles,
      extra_class: "",
      progress: 85,
      unit: "%",
      label: "Orders",
      progress_background: 'rgb(255, 255, 255)',
      border_active_color: 'rgb(251, 41, 123)',
      border_inactive_color: 'rgb(226, 226, 226)',
      border_width: 10,
      desktop: {
        ...designStyles,
        color: 'rgb(0, 0, 0)',
        font_family: 'inherit',
        font_size: 40,
        font_weight: '500',
        size: 180,
        unit_font_size: 16,
        unit_font_weight: '400',
        unit_font_family: 'inherit',
        label_font_size: 20,
        label_font_weight: '500',
        label_color: 'rgb(251, 41, 123)',
        label_font_family: 'inherit',
        label_text_transform: 'uppercase',
      },
      laptop: {
        ...designStyles,
      },
      tablet: {
        ...designStyles,
      },
      mobile: {
        ...designStyles,
      },
    },
    settings: {
      settings: {
        Progress,
        "Progress Style": {"Progress Style": ProgressStyle, multi_screen: true },
        Unit,
        "Unit Style": {"Unit Style": UnitStyle, multi_screen: true},
        Label,
        "Label Style": {"Label Style": LabelStyle, multi_screen: true},
        "Border": BorderSetting,
        "Extra Class": ExtraClass,
        "Custom CSS": CustomCSS,
      },
      design: {
        Visibility,
        Spacing: { Spacing, multi_screen: true },
        Background: { Background, multi_screen: true },
        Border: { Border, multi_screen: true },
        Corner: { Corner, multi_screen: true },
        Shadow: { Shadow, multi_screen: true },
        Opacity: { Opacity, multi_screen: true },
      },
      others: {
        Animations: { Animations, actions: actionTypes }
      }
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
