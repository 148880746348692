<template>
  <div label-position="top" size="medium" :model="elementProps">
    <div class="d-flex align-items-center justify-content-between py-2">
      <span class="sgSubTitle">Dots</span>
      <el-switch
        :value="getValue('show_dots',elementProps[screen].show_dots)"
        :active-color="primaryColor"
        inactive-color="#949494"
        @change="updateProps('show_dots', $event)"
      />
    </div>

    <div class="d-flex align-items-center justify-content-between py-2">
      <span class="sgSubTitle">Next/Prev Buttons</span>
      <el-switch
        :value="getValue('show_pre_next',elementProps[screen].show_pre_next)"
        :active-color="primaryColor"
        inactive-color="#949494"
        @change="updateProps('show_pre_next', $event)"
      />
    </div>
    <div class="d-flex flex-column">
      <span class="setting-title">
        Nav Speed
      </span>
      <Input 
        class="mt-2"
        :value="String(elementProps.navSpeed)"
        v-bind:changeValue="($event) => updateProps2('navSpeed', parseInt($event))"
        type="number"
        :min="0"
        :primaryColor="primaryColor"
      />
      <div class="sgDesc mt-2">
        Transition speed. in miliseconds. Eg: 300
      </div>
    </div>
  </div>
</template>

<script>
import mixin from "../../../elements/styleSettings/mixin";
import Slider from "../../../elements/common/Slider.vue";
import Input from "../../../elements/common/Input.vue";
export default {
  mixins: [mixin],
  components: { Slider, Input },
  props: {
    screen: String,
    primaryColor: String
  },
  methods: {
    updateProps2(name, value) {
      if(value ==  this.elementProps[name]) return;

      this.elementPropsSetter({[name]: value});
    },
    updateProps(name, value) {
      const screen = this.$props.screen;
      const screen_data = Object.assign({}, this.elementProps[screen])
      if(value ==  screen_data[name]) return;

      screen_data[name] = value
      this.elementPropsSetter({[screen]: screen_data});
    },
  },
};
</script>

<style scoped>
</style>
