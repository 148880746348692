var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"label-position":"top","size":"mini","model":_vm.elementProps}},[_c('div',{staticClass:"d-flex flex-column py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Style")]),_c('Select',{staticClass:"mt-2",attrs:{"primaryColor":_vm.primaryColor,"value":_vm.elementProps.display_style,"changeValue":($event) => _vm.updateProps('display_style', $event),"options":[
        {
          value: 'default',
          label: 'Default',
        },
        {
          value: 'inline',
          label: 'Inline',
        },
        {
          value: 'simple',
          label: 'Simple',
        },
      ]}})],1),(_vm.elementProps.display_style=='inline')?_c('div',{staticClass:"d-flex flex-column py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Label Width")]),_c('Slider',{staticClass:"mt-2",attrs:{"primaryColor":_vm.primaryColor,"value":_vm.elementProps.label_width,"changeValue":($event) => _vm.updateProps('label_width', $event),"unit":"px","max":300,"step":1}})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }