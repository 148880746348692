<template>
  <div class="row custom-tab-icon" :style="cssVars">
    <div 
      v-for="item in options"
      class="item"
      :key="item.value"
      :style="{width: getWidth(), flex: `0 0 ${getWidth()}`}"
      :class="{ active: item.value == value }"
      @click="() => updateValue(item.value)"
    >
      <el-tooltip effect="dark" :content="item.tooltip" placement="top">
        <span v-html="allIcons[item.icon]" />
      </el-tooltip>
    </div>
  </div>
</template>

<script>
import { allIcons } from '../../../../../constants';
export default {
  components: {
    name: 'TabIcon'
  },
  data() {
    return {
      allIcons
    }
  },
  computed: {
    cssVars() {
      return {
        '--primary-color': this.primaryColor || '#0060e5'
      }
    }
  },
  methods: {
    getWidth() {
      return 100/this.$props.options.length + '%'
    },
    updateValue(value) {
      this.$props.changeValue(value)
      if (this.$props.canRemove) {
        if (value == this.$props.value) {
          this.$props.changeValue(null)
        } else {
          this.$props.changeValue(value)
        }
      }
    }
  },
  props: {
    value: String,
    options: Array,
    changeValue: Function,
    canRemove: Boolean,
    primaryColor: String
  }
};
</script>

<style lang="scss">
  .custom-tab-icon {
    margin-left: 0px !important;
    margin-right: 0px !important;
    .item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border: 1px solid var(--primary-color);
      background-color: transparent;
      padding: 4px 0px;
      &:not(:first-child) {
        border-left: none;
      }
      &:first-child {
        border-radius: 4px 0px 0px 4px;
      }
      &:last-child {
        border-radius: 0px 4px 4px 0px;
      }
    }
    .item.active {
      background-color: var(--primary-color);
    }
  }
  .el-tooltip__popper.is-dark {
    background-color: #000;
  }
</style>