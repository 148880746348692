<template>
  <el-form label-position="top" size="mini" :model="elementProps" >
    <div class="d-flex align-items-center justify-content-between py-1" v-if="show">
      <span class="el-form-item__label">Dynamic</span>
      <el-switch
        :value="elementProps.dynamic"
        active-color="#9900FF"
        inactive-color="#949494"
        @change="updateProps('dynamic', $event)"
      />
    </div>
    <div class="sgDesc mt-1" v-if="show">
        If the Dynamic feature turns on, the Salegate data of assigned products will be synced with the corresponding product elements in the product pages.
    </div>
    <div class="sgDesc mt-1" v-if="!show">
        Dynamic option is available for product page template only.
    </div>
  </el-form>
</template>
  
  <script>
import mixin from "../../../elements/styleSettings/mixin";
import Node from "@/core/Node";

export default {
  mixins: [mixin],
  components: {},
  data() {
    return {
      show:true,
    };
  },
  created() {
    let urlParams = new URLSearchParams(window.location.search);
    const type = urlParams.get("type");
    if (type == "productpages") {
      this.show = true;
    }
  },
  props: {
    primaryColor: String,
    node: Node,
  },
  methods: {
    updateProps(name, value) {
      if (value == this.elementProps[name]) return;
      this.elementPropsSetter({ [name]: value });
    },
  },
};
</script>
  
  <style scoped>
.banner-description {
  font-size: 12px;
  font-style: italic;
  color: #d6d6d6;
  display: flex;
  flex-direction: column;
  padding: 8px 0px;
}
.highlight {
  color: #fdb913;
}
</style>