export const designStyles = {
  display: 'block',
  // margin_top: 0,
  // margin_left: 0,
  // margin_bottom: 0,
  // margin_right: 0,
  // padding_top: 0,
  // padding_left: 0,
  // padding_bottom: 0,
  // padding_right: 0,
}