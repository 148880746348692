var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-form',{attrs:{"label-position":"top","size":"","model":_vm.elementProps}},[(_vm.getValueLinked(
        'column_width',
        _vm.elementProps[_vm.screen]?.column_width,
        'fit_to_content'
      ) == 'fit_to_content')?_c('div',{staticClass:"py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Horizontal")]),_c('ToggleButton',{attrs:{"primaryColor":_vm.primaryColor,"value":_vm.getValueLinked('place_content', _vm.elementProps[_vm.screen]?.place_content, 'center'),"changeValue":($event) => _vm.updateProps(_vm.screen, 'place_content', $event),"options":[
        { value: 'start', label: 'Start' },
        { value: 'center', label: 'Center' },
        { value: 'end', label: 'End' },
      ]}})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }