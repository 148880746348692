<template>
  <div label-position="top" size="medium" :model="elementProps">
    <div class="d-flex align-items-center justify-content-between py-2">
      <span class="sgSubTitle">Overlay</span>
      <el-switch
        :value="elementProps.overlay"
        :active-color="primaryColor"
        inactive-color="#949494"
        @change="updateProps('overlay', $event)"
      />
    </div>
    <div class="d-flex flex-column py-2" v-if="elementProps.overlay">
      <span class="setting-title">Color</span>
      <ColorPicker
        class="mt-2"
        :value="elementProps.overlay_color"
        v-bind:changeColor="($event) => updateProps('overlay_color', $event)"
        :primaryColor="primaryColor"
      />
    </div>
    <div class="d-flex flex-column py-2" v-if="elementProps.overlay">
      <span class="setting-title">Opacity</span>
      <Slider
        :value="elementProps.overlay_opacity"
        :primaryColor="primaryColor"
        v-bind:changeValue="($event) => updateProps('overlay_opacity', $event)"
        :min="0"
        :max="1"
        :step="0.1"
      />
    </div>
    <div class="d-flex align-items-center justify-content-between py-2">
      <span class="sgSubTitle">Click overlay to close popup</span>
      <el-switch
        :value="elementProps.overlay_close"
        :active-color="primaryColor"
        inactive-color="#949494"
        @change="updateProps('overlay_close', $event)"
      />
    </div>
  </div>
</template>

<script>
import mixin from "../../../elements/styleSettings/mixin";
import ColorPicker from "../../../elements/common/ColorPicker.vue";
import Slider from "../../../elements/common/Slider.vue";
import ToggleButton from "../../../elements/common/ToggleButton.vue";

export default {
  mixins: [mixin],
  components: {ColorPicker, Slider, ToggleButton},
  props: {
    primaryColor: String
  },
  methods: {
    updateProps(name, value) {
      if(value ==  this.elementProps[name]) return;

      this.elementPropsSetter({ [name]: value });
    }
  },
};
</script>

<style scoped>
</style>
