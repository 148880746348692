<template>
  <el-form label-position="top" size="mini" :model="elementProps">
    <div class="d-flex flex-column py-2">
      <span class="setting-title">Position</span>
      <Select
        class="mt-2"
        :primaryColor="primaryColor"
        :value="elementProps.position"
        v-bind:changeValue="($event) => updateProps('position', $event)"
        :options="[
          {
            value: 'center-center',
            label: 'Center Center',
          },
          {
            value: 'top-left',
            label: 'Top Left',
          },
          {
            value: 'top-right',
            label: 'Top Right',
          },
          {
            value: 'bottom-left',
            label: 'Bottom Left',
          },
          {
            value: 'bottom-right',
            label: 'Bottom Right',
          },
          {
            value: 'top-center',
            label: 'Top Center',
          },
          {
            value: 'bottom-center',
            label: 'Bottom Center',
          },
          {
            value: 'center-left',
            label: 'Center Left',
          },
          {
            value: 'center-right',
            label: 'Center Right',
          },
        ]"
      />
    </div>
  </el-form>
</template>

<script>
import mixin from "../../../elements/styleSettings/mixin";
import Select from "../../../elements/common/Select.vue";

export default {
  mixins: [mixin],
  components: {
    Select,
  },
  props: {
    primaryColor: String
  },
  methods: {
    updateProps(name, value) {
      if(value ==  this.elementProps[name]) return;

      this.elementPropsSetter({ [name]: value });
    },
  },
};
</script>
