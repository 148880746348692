var render = function render(){var _vm=this,_c=_vm._self._c;return _c('aside',{staticClass:"sidebar trial-plan",attrs:{"data-v-801b7630":""}},[_c('section',{staticClass:"sidenav mt-1"},[_c('div',{staticClass:"scrollBody ps ps--active-y"},[_c('article',{staticClass:"pages d-none d-lg-flex flex-column"},[_vm._m(0),_c('a',{class:`nav-link ${
            _vm.currentRouteName == 'LandingPage' ? 'rl-eactive rl-active' : ''
          }`,attrs:{"href":"/landingpages/","aria-current":"page"}},[_c('span',{staticClass:"icon material-symbols-outlined"},[_vm._v(" description ")]),_c('span',{staticClass:"text-item"},[_vm._v("Sale Pages")]),_vm._m(1)]),_c('a',{class:`nav-link ${
            _vm.currentRouteName == 'HomePage' ? 'rl-eactive rl-active' : ''
          }`,attrs:{"href":"/homepages/"}},[_c('span',{staticClass:"icon material-symbols-outlined"},[_vm._v(" home ")]),_c('span',{staticClass:"text-item"},[_vm._v("Home Pages")]),_vm._m(2)]),_c('a',{class:`nav-link ${
            _vm.currentRouteName == 'CheckoutPage' ? 'rl-eactive rl-active' : ''
          }`,attrs:{"href":"/checkoutpages/"}},[_c('span',{staticClass:"icon material-symbols-outlined"},[_vm._v(" shopping_cart ")]),_c('span',{staticClass:"text-item"},[_vm._v("Checkout Pages")]),_vm._m(3)])]),_c('article',{staticClass:"pages d-none flex-column"},[_vm._m(4),_c('a',{staticClass:"nav-link",class:`nav-link ${_vm.currentRouteName == 'Settings' ? 'rl-eactive rl-active' : ''}`,attrs:{"href":"/account/settings","aria-current":"page"}},[_c('span',{staticClass:"icon material-symbols-outlined"},[_vm._v(" settings ")]),_c('span',{staticClass:"text-item"},[_vm._v("Settings")])])]),_vm._m(5),_vm._m(6)])]),_c('div',{staticClass:"v-portal",staticStyle:{"display":"none"}})])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nav-link subtitle"},[_c('span',[_vm._v("PAGES")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"btn nav-link__quick-add",attrs:{"href":"/landingpages/create"}},[_c('div',{staticStyle:{"width":"16px","height":"16px"}},[_c('span',{staticClass:"icon material-symbols-outlined"},[_vm._v(" add ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"btn nav-link__quick-add",attrs:{"href":"/editor?type=homepages"}},[_c('div',{staticStyle:{"width":"16px","height":"16px"}},[_c('span',{staticClass:"icon material-symbols-outlined"},[_vm._v(" add ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"btn nav-link__quick-add",attrs:{"href":"/editor?type=checkoutpages"}},[_c('div',{staticStyle:{"width":"16px","height":"16px"}},[_c('span',{staticClass:"icon material-symbols-outlined"},[_vm._v(" add ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nav-link subtitle mt-2"},[_c('span',[_vm._v("STORE SETTINGS")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ps__rail-x",staticStyle:{"left":"0px","bottom":"-130px"}},[_c('div',{staticClass:"ps__thumb-x",staticStyle:{"left":"0px","width":"0px"},attrs:{"tabindex":"0"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ps__rail-y",staticStyle:{"top":"130px","height":"528px","right":"0px"}},[_c('div',{staticClass:"ps__thumb-y",staticStyle:{"top":"105px","height":"423px"},attrs:{"tabindex":"0"}})])
}]

export { render, staticRenderFns }