var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"label-position":"top","size":"small","model":_vm.elementProps}},[_c('div',{staticClass:"d-flex align-items-center justify-content-between py-2"},[_c('span',[_vm._v("Fixed Mode")]),_c('el-switch',{attrs:{"value":_vm.elementProps.fixed,"active-color":_vm.primaryColor,"inactive-color":"#949494","active-value":true,"inactive-value":false},on:{"change":function($event){return _vm.updateProps('fixed', $event)}}})],1),_vm._m(0),(_vm.elementProps.fixed)?_c('div',[_c('div',{staticClass:"py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Content Position")]),_c('ToggleButton',{attrs:{"primaryColor":_vm.primaryColor,"value":_vm.elementProps.content_position,"changeValue":($event) => _vm.updateProps('content_position', $event),"options":[
          { value: 'top', label: 'Top' },
          { value: 'middle', label: 'Middle' },
          { value: 'bottom', label: 'Bottom' },
        ]}})],1),_c('div',{staticClass:"py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Alt Tag")]),_c('Input',{staticClass:"mt-2",attrs:{"value":_vm.elementProps.alt_tag,"changeValue":($event) => _vm.updateProps('alt_tag', $event),"primaryColor":_vm.primaryColor}})],1),_vm._m(1)]):_c('div',[_c('div',{staticClass:"py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v(" Height (px) ")]),_c('Input',{staticClass:"mt-2",attrs:{"value":_vm.getValue('height',_vm.elementProps[_vm.screen].height),"changeValue":($event) => _vm.updatePropsScreen('height', $event),"primaryColor":_vm.primaryColor}})],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('span',{staticClass:"text-description"},[_vm._v(" Display Hero Banner box based on background image dimension. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('span',{staticClass:"text-description"},[_vm._v(" Each image should have an alt text. Not just for SEO purposes but also because blind and visually impaired people otherwise wont know what the image is for. ")])])
}]

export { render, staticRenderFns }