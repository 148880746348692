<template>
  <div label-position="top" size="mini" :model="elementProps">
    <div class="add-icon-box" @click="handleShowModal">
      <a href="#" class="selected-icon">
        <i :class="`sg_icon fa ${elementProps.icon}`"></i>
      </a>
    </div>
  </div>
</template>

<script>
import mixin from "./mixin";
import ColorPicker from "../common/ColorPicker.vue";
import Slider from "../common/Slider.vue";
import Select from "../common/Select.vue";
import ToggleButton from "../common/ToggleButton.vue";
import Node from "../../../../../core/Node";

export default {
  mixins: [mixin],
  props:{
    node: Node
  },
  data() {
    return {
      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,
    };
  },
  methods: {
    handleShowModal() {
      this.$bus.emit("showColorPicker", this.node);
    },
  },
};
</script>

<style scoped>
.add-icon-box {
  border-radius: 3px;
  background: #252525;
  display: inline-block;
  width: 100%;
}
.add-icon-box a {
  text-align: center;
  width: 100%;
  color: #d6d6d6;
  text-decoration: none;
}
.add-icon-box a.selected-icon {
  float: left;
  display: block;
  text-align: center;
  font-size: 32px;
  padding: 32px 0px;
}
.add-icon-box .sgIconPicker {
  padding: 4px 0 32px;
  font-size: 11px;
}

.button-sg,
button-sg {
  display: inline-block;
  line-height: 1;
  padding: 1em 1.3em;
  text-align: center;
  cursor: pointer;
  transition: all 0.45s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
</style>
