<template>
    <el-form label-position="top" size="mini" :model="elementProps">
      <div class="d-flex flex-column py-2" >
        <span class="setting-title">Previous</span>
        <el-input
          :value="elementProps.previous_text"
          class="input-with-select mt-2"
          @input="updateProps('previous_text', $event)"
          size="medium"
        />
      </div>
      <div class="d-flex flex-column py-2" >
        <span class="setting-title">Next</span>
        <el-input
          :value="elementProps.next_text"
          class="input-with-select mt-2"
          @input="updateProps('next_text', $event)"
          size="medium"
        />
      </div>

    </el-form>
  </template>
  
  <script>
  import mixin from "../../../elements/styleSettings/mixin";
  import Select from "../../../elements/common/Select.vue";
  import Slider from "../../../elements/common/Slider.vue";
  
  export default {
    mixins: [mixin],
    components: {Select, Slider },
    props: {
      primaryColor: String
    },
    methods: {
      updateProps(name, value) {
        if(value ==  this.elementProps[name]) return;
        this.elementPropsSetter({ [name]: value });
      }
    },
  };
  </script>
  
  <style scoped>
  </style>
  