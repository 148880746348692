<template>
  <div class="sg-font-list">
    <link
      v-for="(font, index) in [...editor?.fonts, 'Work Sans']"
      :key="index"
      type="text/css"
      rel="stylesheet"
      :href="`https://fonts.googleapis.com/css2?family=${font.replace(' ', '+')}:wght@100;200;300;400;500;600;700;800;900&display=swap`"
      className="sg_fonts"
      :data-font="font.font"
    />
  </div>
</template>
<script>
export default {
  name: "FontsActive",
  props: {
    editor: Object,
  },
  watch: {},
  data() {
    return {
      
    }
  },
  methods: {},
};
</script>
  
  