export const toolbarType = {
    'container': {
        'title': 'Container',
        'color': '#0060e5',
        'size': 1,
        'edit': false,
        'top': '0',
        'left': '0',
    },
    'row': {
        'title': 'Row',
        'color': '#0060e5',
        'size': 1,
        'edit': false,
        'top': '0',
        'left': '0',
    },
    'heading': {
        'title': 'Heading',
        'color': '#04aa6d',
        'size': 1,
        'edit': true,
        'top': '0',
        'left': '50%',
        'tranform-top': '0'
    },
    'liquid': {
        'title': 'Liquid',
        'color': '#e82b71',
        'size': 0.8,
        'edit': true,
        'top': '-4',
        'left': '50%',
        'tranform-top': '0'
    },
    'header': {
        'title': 'Header',
        'color': '#04aa6d',
        'size': 1,
        'edit': false,
        'top': '0',
        'left': '50%',
        'tranform-top': '0'
    },
    'image': {
        'title': 'Image',
        'color': '#04aa6d',
        'size': 1,
        'edit': false,
        'top': '0',
        'left': '50%',
        'tranform-top': '0'
    },
    'youtube': {
        'title': 'Youtube',
        'color': '#e82b71',
        'size': 1,
        'edit': false,
        'top': '0',
        'left': '50%',
        'tranform-top': '0'
    },
    'icon': {
        'title': 'Icon',
        'color': '#04aa6d',
        'size': 0.8,
        'edit': false,
        'top': '50%',
        'left': '50%',
        'tranform-top': '-50%'
    },
    'paragraph': {
        'title': 'Text Block',
        'color': '#04aa6d',
        'size': 1,
        'edit': true,
        'top': '0',
        'left': '50%',
        'tranform-top': '0'
    },
    'button': {
        'title': 'Button',
        'color': '#04aa6d',
        'size': 0.8,
        'edit': false,
        'top': '50%',
        'left': '50%',
        'tranform-top': '-50%'
    },
    'separator': {
        'title': 'Separator',
        'color': '#04aa6d',
        'size': 1,
        'edit': false,
        'top': '0',
        'left': '50%',
        'tranform-top': '-50%'
    },
    'product': {
        'title': 'Product',
        'color': '#e82b71',
        'size': 1,
        'edit': false,
        'top': '0',
        'left': '50%',
        'tranform-top': '0'
    },
    '(p)image': {
        'title': '(P) Image',
        'color': '#e82b71',
        'size': 1,
        'edit': false,
        'top': '0',
        'left': '50%',
        'tranform-top': '0'
    },
    '(p)title': {
        'title': '(P) Title',
        'color': '#e82b71',
        'size': 1,
        'edit': false,
        'top': '50%',
        'left': '50%',
        'tranform-top': '-50%'
    },
    '(p)price': {
        'title': '(P) Price',
        'color': '#e82b71',
        'size': 1,
        'edit': false,
        'top': '50%',
        'left': '50%',
        'tranform-top': '-50%'
    },
    '(p)cartbutton': {
        'title': '(P) Cart Button',
        'color': '#e82b71',
        'size': 1,
        'edit': false,
        'top': '50%',
        'left': '50%',
        'tranform-top': '-50%'
    },
    '(p)sku': {
        'title': '(P) SKU',
        'color': '#e82b71',
        'size': 1,
        'edit': false,
        'top': '50%',
        'left': '50%',
        'tranform-top': '-50%'
    },
    '(p)vendor': {
        'title': '(P) Vendor',
        'color': '#e82b71',
        'size': 1,
        'edit': false,
        'top': '50%',
        'left': '50%',
        'tranform-top': '-50%'
    },
    'hero_banner': {
        'title': 'Hero Banner',
        'color': '#e82b71',
        'size': 1,
        'edit': false,
        'top': '0',
        'left': '50%',
        'tranform-top': '0'
    },
    'shipping_address': {
        'title': 'Shipping Address',
        'color': '#e82b71',
        'size': 1,
        'edit': false,
        'top': '0',
        'left': '50%',
        'tranform-top': '0'
    },
    'billing_address': {
        'title': 'Billing Address',
        'color': '#e82b71',
        'size': 1,
        'edit': false,
        'top': '0',
        'left': '50%',
        'tranform-top': '0'
    },
    'payment_button': {
        'title': 'Payment Button',
        'color': '#e82b71',
        'size': 1,
        'edit': false,
        'top': '0',
        'left': '50%',
        'tranform-top': '0'
    },
    'payment': {
        'title': 'Payment',
        'color': '#e82b71',
        'size': 1,
        'edit': false,
        'top': '0',
        'left': '50%',
        'tranform-top': '0'
    },
    'order_summary': {
        'title': 'Order Summary',
        'color': '#e82b71',
        'size': 1,
        'edit': false,
        'top': '0',
        'left': '50%',
        'tranform-top': '0'
    },
    'banner': {
        'title': 'Banner',
        'color': '#e82b71',
        'size': 1,
        'edit': false,
        'top': '0',
        'left': '50%',
        'tranform-top': '0'
    },
    'parallax': {
        'title': 'Parallax',
        'color': '#e82b71',
        'size': 1,
        'edit': false,
        'top': '0',
        'left': '50%',
        'tranform-top': '0'
    }, 
    '(p)description': {
        'title': '(P) Description',
        'color': '#e82b71',
        'size': 1,
        'edit': false,
        'top': '50%',
        'left': '50%',
        'tranform-top': '-50%'
    },
    '(p)viewmore': {
        'title': '(P) View More',
        'color': '#e82b71',
        'size': 1,
        'edit': false,
        'top': '50%',
        'left': '50%',
        'tranform-top': '-50%'
    },
    '(p)variants': {
        'title': '(P) Variants',
        'color': '#e82b71',
        'size': 1,
        'edit': false,
        'top': '50%',
        'left': '50%',
        'tranform-top': '-50%'
    },
    'tabs': {
      'title': 'Tabs',
      'color': '#e82b71',
      'size': 1,
      'edit': false,
      'top': '0',
      'left': '50%',
      'tranform-top': '0'
    },
    'pricing': {
        'title': 'Pricing',
        'color': '#e82b71',
        'size': 1,
        'edit': false,
        'top': '0',
        'left': '50%',
        'tranform-top': '0'
    },
    'accordion': {
        'title': 'Accordion',
        'color': '#e82b71',
        'size': 1,
        'edit': false,
        'top': '0',
        'left': '50%',
        'tranform-top': '0'
    },
    '(p)stock_counter': {
        'title': '(P) Stock Counter',
        'color': '#e82b71',
        'size': 1,
        'edit': false,
        'top': '0',
        'left': '50%',
        'tranform-top': '0'
    },
    '(p)quantity': {
        'title': '(P) Quantity',
        'color': '#e82b71',
        'size': 1,
        'edit': false,
        'top': '50%',
        'left': '50%',
        'tranform-top': '-50%'
    },
    '(p)paypal': {
        'title': '(P) Paypal',
        'color': '#e82b71',
        'size': 1,
        'edit': false,
        'top': '50%',
        'left': '50%',
        'tranform-top': '-50%'
    },
    '(p)imagelist': {
        'title': '(P) Image List',
        'color': '#e82b71',
        'size': 1,
        'edit': false,
        'top': '50%',
        'left': '50%',
        'tranform-top': '-50%'
    },
    'textfield': {
        'title': 'Text Field',
        'color': '#04aa6d',
        'size': 0.8,
        'edit': true,
        'top': '50%',
        'left': '50%',
        'tranform-top': '-50%'
    },
    'selectfield': {
        'title': 'Select Field',
        'color': '#04aa6d',
        'size': 0.8,
        'edit': true,
        'top': '50%',
        'left': '50%',
        'tranform-top': '-50%'
    },
    'checkboxfield': {
        'title': 'Checkbox Field',
        'color': '#04aa6d',
        'size': 0.8,
        'edit': true,
        'top': '50%',
        'left': '50%',
        'tranform-top': '-50%'
    },
    'emailfield': {
        'title': 'Email Field',
        'color': '#04aa6d',
        'size': 0.8,
        'edit': true,
        'top': '50%',
        'left': '50%',
        'tranform-top': '-50%'
    },
    'newsletter': {
        'title': 'Newsletter',
        'color': '#e82b71',
        'size': 1,
        'edit': false,
        'top': '0',
        'left': '50%',
        'tranform-top': '0'
    },
    'submit_button': {
        'title': 'Submit Button',
        'color': '#04aa6d',
        'size': 0.8,
        'edit': true,
        'top': '50%',
        'left': '50%',
        'tranform-top': '-50%'
    },
    'contact_form': {
        'title': 'Contact Form',
        'color': '#e82b71',
        'size': 1,
        'edit': false,
        'top': '0',
        'left': '50%',
        'tranform-top': '0'
    },
    'textarea': {
        'title': 'Text Area',
        'color': '#04aa6d',
        'size': 1,
        'edit': true,
        'top': '0',
        'left': '50%',
        'tranform-top': '0'
    },
    "countdown_timer": {
        'title': 'Countdown Timer',
        'color': '#e82b71',
        'size': 1,
        'edit': false,
        'top': '0',
        'left': '50%',
        'tranform-top': '0'
    },
    "counter": {
        'title': 'Counter',
        'color': '#e82b71',
        'size': 1,
        'edit': false,
        'top': '0',
        'left': '50%',
        'tranform-top': '0'
    },
    "progress": {
        'title': 'Progress',
        'color': '#e82b71',
        'size': 1,
        'edit': false,
        'top': '0',
        'left': '50%',
        'tranform-top': '0'
    },
    "google_map": {
        'title': 'Google Maps',
        'color': '#e82b71',
        'size': 1,
        'edit': false,
        'top': '0',
        'left': '50%',
        'tranform-top': '0'
    },
    "icon_list_hoz": {
        'title': 'Icon List Hoz',
        'color': '#e82b71',
        'size': 1,
        'edit': false,
        'top': '0',
        'left': '50%',
        'tranform-top': '0'
    },
    "icon_list": {
        'title': 'Icon List',
        'color': '#e82b71',
        'size': 1,
        'edit': false,
        'top': '0',
        'left': '50%',
        'tranform-top': '0'
    },
    "popup": {
        'title': 'Popup',
        'color': '#e82b71',
        'size': 1,
        'edit': false,
        'top': '0',
        'left': '50%',
        'tranform-top': '-100%'
    },
    'breadcrumbs': {
        'title': 'Breadcrumbs',
        'color': '#e82b71',
        'size': 1,
        'edit': false,
        'top': '0',
        'left': '50%',
        'tranform-top': '0'
    },
    'product_list': {
        'title': 'Product List',
        'color': '#e82b71',
        'size': 1,
        'edit': false,
        'top': '0',
        'left': '50%',
        'tranform-top': '0'
    },
    'related_product': {
        'title': 'Related Product',
        'color': '#e82b71',
        'size': 1,
        'edit': false,
        'top': '0',
        'left': '50%',
        'tranform-top': '0'
    },
    "carousel":{
        'title': 'Carousel',
        'color': '#e82b71',
        'size': 1,
        'edit': false,
        'top': '0',
        'left': '50%',
        'tranform-top': '0'
    },
    "article_list":{
        'title': 'Article List',
        'color': '#e82b71',
        'size': 1,
        'edit': false,
        'top': '0',
        'left': '50%',
        'tranform-top': '0'
    },
    '(a)image': {
        'title': '(A) Image',
        'color': '#e82b71',
        'size': 1,
        'edit': false,
        'top': '0',
        'left': '50%',
        'tranform-top': '0'
    },
    '(a)title': {
        'title': '(A) Title',
        'color': '#e82b71',
        'size': 1,
        'edit': false,
        'top': '50%',
        'left': '50%',
        'tranform-top': '-50%'
    },
    '(a)content': {
        'title': '(A) Content',
        'color': '#e82b71',
        'size': 1,
        'edit': false,
        'top': '0',
        'left': '50%',
        'tranform-top': '0'
    },
    '(a)date': {
        'title': '(A) Date',
        'color': '#e82b71',
        'size': 1,
        'edit': false,
        'top': '0',
        'left': '50%',
        'tranform-top': '0'
    },
    'blog-title': {
        'title': 'Blog Title',
        'color': '#f9c302',
        'size': 1,
        'edit': false,
        'top': '0',
        'left': '50%',
        'tranform-top': '0'
    },
    'blog-content': {
        'title': 'Blog Content',
        'color': '#f9c302',
        'size': 1,
        'edit': false,
        'top': '0',
        'left': '50%',
        'tranform-top': '0'
    },
    'contact_qr_form': {
        'title': 'QR Form',
        'color': '#e82b71',
        'size': 1,
        'edit': false,
        'top': '0',
        'left': '50%',
        'tranform-top': '0'
    },
    '(qr)text': {
        'title': '(QR) Text',
        'color': '#e82b71',
        'size': 1,
        'edit': false,
        'top': '0',
        'left': '50%',
        'tranform-top': '0'
    },
    '(qr)image': {
        'title': '(QR) Image',
        'color': '#e82b71',
        'size': 1,
        'edit': false,
        'top': '0',
        'left': '50%',
        'tranform-top': '0'
    },
    '(c)banner': {
        'title': '(C) Banner',
        'color': '#e82b71',
        'size': 1,
        'edit': false,
        'top': '0',
        'left': '50%',
        'tranform-top': '0'
    },
    '(c)title': {
        'title': 'Collection Title',
        'color': '#e82b71',
        'size': 1,
        'edit': false,
        'top': '0',
        'left': '50%',
        'tranform-top': '0'
    },
    '(c)desc': {
        'title': 'Collection Desc',
        'color': '#e82b71',
        'size': 1,
        'edit': false,
        'top': '0',
        'left': '50%',
        'tranform-top': '0'
    },
    '(c)toolbar': {
        'title': 'Collection Toolbar',
        'color': '#e82b71',
        'size': 1,
        'edit': false,
        'top': '0',
        'left': '50%',
        'tranform-top': '0'
    },
    'paginator': {
        'title': 'Paginator',
        'color': '#e82b71',
        'size': 1,
        'edit': false,
        'top': '0',
        'left': '50%',
        'tranform-top': '0'
    },"floating-button": {
        'title': 'Floating Button',
        'color': '#e82b71',
        'size': 1,
        'edit': false,
        'top': '0',
        'left': '50%',
        'tranform-top': '-100%'
    },

}

export const editToolbarId = [
    'youtube',
    'heading',
    'liquid',
    'header',
    'icon',
    'button',
    'image',
    'paragraph',
    'separator',
    'product',
    '(p)image',
    '(p)title',
    '(p)paypal',
    '(p)price',
    '(p)cartbutton',
    '(p)sku',
    '(p)vendor',
    'shipping_address',
    'billing_address',
    'payment_button',
    'payment',
    'order_summary',
    'hero_banner',
    'parallax',
    'pricing',
    'tabs',
    'accordion',
    '(p)description',
    '(p)viewmore',
    '(p)variants',
    '(p)stock_counter',
    '(p)quantity',
    '(p)imagelist',
    'textfield',
    'selectfield',
    'checkboxfield',
    'emailfield',
    'newsletter',
    'submit_button',
    "contact_form",
    "textarea",
    "countdown_timer",
    "counter",
    "google_map",
    "icon_list_hoz",
    "icon_list",
    "popup",
    "breadcrumbs",
    "product_list",
    "related_product",
    "carousel",
    "progress",
    "article_list",
    "(a)image",
    "(a)title",
    "(a)content",
    "(a)date",
    "blog-title",
    "blog-content",
    "(qr)image",
    "(qr)text",
    "contact_qr_form",
    "(c)banner",
    "(c)title",
    '(c)desc',
    '(c)toolbar',
    'paginator',
    'floating-button'
]
