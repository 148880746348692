<template>
  <div label-position="top" size="medium" :model="elementProps">
    <div class="d-flex align-items-center justify-content-between py-2">
      <span class="setting-title">Show border</span>
      <el-switch
        :value="getValue('show_border_selected_swatch',elementProps[screen].show_border_selected_swatch)"
        :active-color="primaryColor"
        inactive-color="#949494"
        @change="updateProps('show_border_selected_swatch', $event)"
      />
    </div>
    <div class="d-flex flex-column py-2" v-if="getValue('show_border_selected_swatch',elementProps[screen].show_border_selected_swatch)">
      <span class="setting-title">Border Size</span>
      <Slider 
        class="mt-2"
        :primaryColor="primaryColor"
        :value="Number.parseInt(getValue('selected_swatch_border_width',elementProps[screen].selected_swatch_border_width))"
        :min="0"
        :max="10"
        :step="1"
        v-bind:changeValue="($event) => updateProps('selected_swatch_border_width', $event)"
        unit="px"
        :onlyInteger="true"
      />
    </div>
    <div class="d-flex flex-column py-2" v-if="getValue('show_border_selected_swatch',elementProps[screen].show_border_selected_swatch)">
      <span class="setting-title">Border Color</span>
      <ColorPicker
        class="mt-2"
        :value="getValue('selected_swatch_border_color',elementProps[screen].selected_swatch_border_color)"
        v-bind:changeColor="($event) => updateProps('selected_swatch_border_color', $event)"
        :primaryColor="primaryColor"
      />
    </div>
  </div>
</template>

<script>
import mixin from "../../../elements/styleSettings/mixin";
import ColorPicker from "../../../elements/common/ColorPicker.vue";
import Slider from "../../../elements/common/Slider.vue";

export default {
  mixins: [mixin],
  components: {ColorPicker, Slider},
  props: {
    screen: String,
    primaryColor: String,
  },
  methods: {
    updateProps(name, value) {
      const screen = this.$props.screen
      const data_screen = Object.assign({}, this.elementProps[screen])
      if(value ==  data_screen[name]) return;
      data_screen[name] = value
      if (name == 'width' && (value == 'auto' && isNaN(parseInt(value)))) {
        data_screen['width_type'] = ''
      }
      this.elementPropsSetter({ [screen]: data_screen });
    },
  
  },
};
</script>
