var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"label-position":"top","size":"mini","model":_vm.elementProps}},[_c('div',{staticClass:"d-flex flex-column py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Default Image")]),_c('Upload',{attrs:{"value":_vm.multi_screen ? _vm.elementProps.imgUrl : _vm.elementProps.imgUrl,"changeValue":($event) => _vm.updateProps('imgUrl', $event)}})],1),_c('div',{staticClass:"d-flex flex-column py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Image Src")]),_c('el-input',{staticClass:"mt-2",attrs:{"placeholder":"Link","value":_vm.multi_screen ? _vm.elementProps.imgUrl : _vm.elementProps.imgUrl,"size":"medium"},on:{"input":function($event){return _vm.updateProps('imgUrl', $event)}}})],1),_c('div',{staticClass:"d-flex flex-column py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Image Quality")]),_c('Select',{staticClass:"mt-2",attrs:{"primaryColor":_vm.primaryColor,"value":_vm.elementProps.quality,"changeValue":($event) => _vm.updateProps('quality', $event),"options":[
        {
          value: 'lighter',
          label: 'Lighter',
        },
        {
          value: 'lightest',
          label: 'Lightest',
        },
      ]}}),_c('span',{staticClass:"sgSubTitle mt-2"},[_vm._v("Only support for UCare CDN")])],1),_c('div',{staticClass:"d-flex flex-column py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Hover Effect")]),_c('Select',{staticClass:"mt-2",attrs:{"primaryColor":_vm.primaryColor,"value":_vm.elementProps.hover_effect,"changeValue":($event) => _vm.updateProps('hover_effect', $event),"options":[
        {
          value: 'none',
          label: 'None',
        },
        {
          value: 'effect-zoom',
          label: 'Zoom',
        },
        {
          value: 'effect-parallax',
          label: 'Parallax',
        }
      ]}})],1),(_vm.elementProps.hover_effect!='none')?_c('div',{staticClass:"d-flex flex-column py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Hover Effect Time")]),_c('Slider',{staticClass:"mt-2",attrs:{"value":_vm.elementProps.hover_effect_time,"primaryColor":_vm.primaryColor,"changeValue":($event) => _vm.updateProps('hover_effect_time', $event),"max":3,"min":0,"step":0.1}}),_c('span',{staticClass:"mt-2 sgSubTitle"},[_vm._v("Transition time in if custom effect is applied. Eg: 0.5s")])],1):_vm._e(),_c('div',{staticClass:"d-flex flex-column py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Image Size")]),_c('Select',{staticClass:"mt-2",attrs:{"primaryColor":_vm.primaryColor,"value":_vm.elementProps.image_size,"changeValue":($event) => _vm.updateProps('image_size', $event),"options":[
        {
          value: '100x100',
          label: 'Small (100x100)',
        },
        {
          value: '240x240',
          label: 'Medium (240x240)',
        },
        {
          value: '480x480',
          label: 'Large (480x480)',
        },
        {
          value: '600x600',
          label: 'Grande (600x600)',
        },
        {
          value: '1024x1024',
          label: '1024x1024',
        },
        {
          value: '2048x2048',
          label: '2048x2048',
        },
        {
          value: 'master',
          label: 'Original',
        }
      ]}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }