<template>
  <div label-position="top" size="" :model="elementProps">
    <div class="d-flex align-items-center justify-content-between py-2">
      <span style="color: #AAAAAA; font-size: 12px;">Open Tab</span>
      <el-switch
        :value="elementProps.open_tab"
        :active-color="primaryColor"
        inactive-color="#949494"
        :active-value="true"
        :inactive-value="false"
        @change="updateProps('open_tab', $event)"
      />
    </div>
    <div style="color: #999999; font-size: 12px; font-style: italic;">Open one tab as default</div>
    <div class="d-flex flex-column py-2" v-if="elementProps.open_tab">
      <span style="color: #AAAAAA; font-size: 12px;">Tab Index</span>
      <el-input
        class="mt-2"
        :value="elementProps.tab_index"
        @input="updateProps('tab_index', $event)"
      />
      <div class="mt-2" style="color: #999999; font-size: 12px; font-style: italic;">Enter index of tab to be set as default.</div>
    </div>
    
  </div>
</template>

<script>
import mixin from "../../../elements/styleSettings/mixin";
import Select from "../../../elements/common/Select.vue";
import TabIcon from "../../../elements/common/TabIcon.vue";
import ColorPicker from "../../../elements/common/ColorPicker.vue";
export default {
  mixins: [mixin],
  components: { Select, TabIcon, ColorPicker },
  methods: {
    updateProps(name, value) {
      if(value ==  this.elementProps[name]) return;
      this.elementPropsSetter({ [name]: value });
    },
  },
  props: {
    primaryColor: String
  }
};
</script>

<style scoped>
</style>
