<template>
  <div label-position="top" size="medium" :model="elementProps">
    <div class="d-flex flex-column py-2">
      <span class="setting-title">Shape</span>
      <ToggleButton 
        :primaryColor="primaryColor"
        :value="elementProps.shape"
        v-bind:changeValue="($event) => updateProps('shape', $event)"
        :options="[
          { value: 'rect', label: 'Rect' },
          { value: 'pill', label: 'Pill' },
        ]"
      />
    </div>
    <div class="d-flex flex-column py-2">
      <span class="setting-title">Color</span>
      <Select
        class="mt-2"
        :primaryColor="primaryColor"
        :value="elementProps.color"
        v-bind:changeValue="($event) => updateProps('color', $event)"
        :options="[
          {
            value: 'gold',
            label: 'Gold',
          },
          {
            value: 'blue',
            label: 'Blue',
          },
          {
            value: 'silver',
            label: 'Silver',
          },
          {
            value: 'white',
            label: 'White',
          },
          {
            value: 'black',
            label: 'Black',
          },
        ]"
      />
    </div>
    <div class="d-flex flex-column py-2">
      <span class="setting-title">Label</span>
      <Select
        class="mt-2"
        :primaryColor="primaryColor"
        :value="elementProps.label"
        v-bind:changeValue="($event) => updateProps('label', $event)"
        :options="[
          {
            value: 'paypal',
            label: 'Paypal',
          },
          {
            value: 'checkout',
            label: 'Checkout',
          },
          {
            value: 'buynow',
            label: 'Buy Now',
          },
          {
            value: 'pay',
            label: 'Pay',
          },
        ]"
      />
    </div>
    <div class="d-flex flex-column py-2">
      <span class="setting-title">Height</span>
      <Slider 
        class="mt-2"
        :primaryColor="primaryColor"
        :value="elementProps.height"
        :min="25"
        :max="55"
        :step="1"
        v-bind:changeValue="($event) => updateProps('height', $event)"
        unit="px"
        :onlyInteger="true"
      />
    </div>
    
  </div>
</template>

<script>
import mixin from "../../../elements/styleSettings/mixin";
import ColorPicker from "../../../elements/common/ColorPicker.vue";
import Slider from "../../../elements/common/Slider.vue";
import ToggleButton from "../../../elements/common/ToggleButton.vue";
import TabIcon from "../../../elements/common/TabIcon.vue";
import Select from "../../../elements/common/Select.vue";

export default {
  mixins: [mixin],
  components: {
    ColorPicker, Slider, ToggleButton, Select, TabIcon
  },
  props: {
    primaryColor: String,
  },
  methods: {
    updateProps(name, value) {
      if(value ==  this.elementProps[name]) return;
      this.elementPropsSetter({ [name]: value });
    },
  },
};
</script>

<style scoped>
</style>
