<template>
  <div label-position="top" size="mini" :model="elementProps">
    <div class="d-flex flex-column py-2">
      <span class="setting-title">Text</span>
      <el-input 
        class="mt-2" 
        placeholder="Text" 
        :value="elementProps.text"
        @input="updateProps('text', $event)"
        size="medium"
      />
    </div>
    <div class="d-flex align-items-center justify-content-between py-2">
      <span class="setting-title">Icon</span>
      <div class="d-flex list-actions">
        <el-tooltip class="item" :visible-arrow="true" content="Select icon" placement="top">
          <div class="icon-select" @click="handleShowModal">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" stroke-width="1.5" stroke="#DDDDDD" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path d="M8 13v-8.5a1.5 1.5 0 0 1 3 0v7.5"></path>
              <path d="M11 11.5v-2a1.5 1.5 0 1 1 3 0v2.5"></path>
              <path d="M14 10.5a1.5 1.5 0 0 1 3 0v1.5"></path>
              <path d="M17 11.5a1.5 1.5 0 0 1 3 0v4.5a6 6 0 0 1 -6 6h-2h.208a6 6 0 0 1 -5.012 -2.7a69.74 69.74 0 0 1 -.196 -.3c-.312 -.479 -1.407 -2.388 -3.286 -5.728a1.5 1.5 0 0 1 .536 -2.022a1.867 1.867 0 0 1 2.28 .28l1.47 1.47"></path>
            </svg>
          </div>
        </el-tooltip>
        <el-tooltip class="item" content="Upload svg" placement="top">
          <el-upload 
            class="icon-upload-svg"
            accept="image/svg+xml"
            action="https://api-onepage.salegate.io/admin/v1/upload_file"
            :headers="{'x-access-token': user.token, 'x-shop-domain': user.store?.domain}"
            :on-success="onSuccess"
            :before-upload="() => {
              this.loading = true;
            }"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" stroke-width="1.5" stroke="#DDDDDD" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2"></path>
              <polyline points="7 9 12 4 17 9"></polyline>
              <line x1="12" y1="4" x2="12" y2="16"></line>
            </svg>
          </el-upload>
        </el-tooltip>
      </div>
    </div>
    <div class="py-2 position-relative">
      <div class="icon-box" v-if="elementProps.icon && elementProps.icon.startsWith('fa')">
        <i :class="`sg_icon fa ${elementProps.icon}`"></i>
      </div>
      <div class="icon-box" v-if="elementProps.icon && !elementProps.icon.startsWith('fa')">
        <span v-if="loading" class="fw-6">Uploading...</span>
        <span v-else v-html="elementProps.icon"></span>
      </div>
      <div class="clear-icon" v-if="elementProps.icon" @click="() => updateProps('icon', '')">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" stroke-width="1.5" stroke="#ffffff" fill="none" stroke-linecap="round" stroke-linejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <path d="M20 6a1 1 0 0 1 .117 1.993l-.117 .007h-.081l-.919 11a3 3 0 0 1 -2.824 2.995l-.176 .005h-8c-1.598 0 -2.904 -1.249 -2.992 -2.75l-.005 -.167l-.923 -11.083h-.08a1 1 0 0 1 -.117 -1.993l.117 -.007h16z" stroke-width="0" fill="#fff"></path>
          <path d="M14 2a2 2 0 0 1 2 2a1 1 0 0 1 -1.993 .117l-.007 -.117h-4l-.007 .117a1 1 0 0 1 -1.993 -.117a2 2 0 0 1 1.85 -1.995l.15 -.005h4z" stroke-width="0" fill="#fff"></path>
        </svg>
      </div>
    </div>
    <div class="d-flex flex-column py-2">
      <span class="setting-title">Spacing</span>
      <Slider 
        class="mt-2"
        :primaryColor="primaryColor"
        :value="elementProps.spacing"
        :min="0"
        :max="20"
        :step="1"
        v-bind:changeValue="($event) => updateProps('spacing', parseInt($event))"
        unit="px"
        :onlyInteger="true"
      />
    </div>
    <div class="d-flex flex-column py-2" v-if="elementProps.icon">
      <span class="setting-title">Icon Position</span>
      <ToggleButton
        :primaryColor="primaryColor"
        :value="elementProps.icon_position"
        v-bind:changeValue="($event) => updateProps('icon_position', $event)"
        :options="[
          {
            label: 'Start',
            value: 'row',
          },
          {
            label: 'End',
            value: 'row-reverse',
          },
        ]"
      />
    </div>
  </div>
</template>

<script>
import mixin from "./mixin";
import Node from "@/core/Node";
import Slider from "../common/Slider.vue";
import ToggleButton from "../common/ToggleButton.vue";
import Cookies from 'js-cookie';
export default {
  mixins: [mixin],
  components: {
    Slider, ToggleButton
  },
  data() {
    return {
      user: JSON.parse(Cookies.get('user_onepage') || '{}'),
      loading: false
    }
  },
  props: {
    screen: String,
    primaryColor: String,
    node: Node,
  },
  methods: {
    updateProps(name, value) {
      this.elementPropsSetter({ [name]: value });
    },
    handleShowModal() {
      this.$bus.emit("showColorPicker", this.node);
    },
    handlePreview(file) {
      // console.log(file, 'preview')
    },
    onSuccess(response) {
      this.loading = false;
      var request = new XMLHttpRequest();
      request.open('GET', response.data.url, false);
      request.send(null);
      // var value = new DOMParser().parseFromString(request.responseText, "text/xml");
      this.elementPropsSetter({ icon: request.responseText });
    }
  },
};
</script>

<style lang="scss">
  .list-actions {
    display: flex;
    gap: 1px;
    div {
      background-color: #555555;
      border: 1px solid #555555;
      border-radius: 4px;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      &:first-child {
        border-right: none;
      }
    }
  }
  .clear-icon {
    position: absolute;
    top: 8px;
    right: 0px;
    height: 24px;
    width: 24px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .icon-box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 30px;
    background-color: #222222;
    border-radius: 6px;
    font-size: 32px;
  }

  .icon-upload-svg {
    .el-upload-list {
      display: none !important;
    }
  }
</style>
