<template>
  <div label-position="top" size="mini" :model="elementProps">
    <div class="d-flex flex-column py-2">
      <span class="setting-title">Position</span>
      <ToggleButton
        class="mt-2"
        :primaryColor="primaryColor"
        :value="elementProps.position"
        v-bind:changeValue="($event) => updateProps('position', $event)"
        :options="[
          {
            label: 'Sticky',
            value: 'sticky',
          },
          {
            label: 'Normal',
            value: 'relative',
          },
        ]"
      />
    </div>
    <div class="sgDesc">Position sticky will work on preview or live page.</div>
  </div>
</template>

<script>
import mixin from '../../../elements/styleSettings/mixin';
import Slider from '../../../elements/common/Slider.vue';
import ToggleButton from '../../../elements/common/ToggleButton.vue';

export default {
  mixins: [mixin],
  props: {
    screen: String,
    primaryColor: String
  },
  components: {
    Slider, ToggleButton
  },
  methods: {
    updateProps(name, value) {
      this.elementPropsSetter({ [name]: value });
    },
  }
};
</script>
