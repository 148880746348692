<template>
  <div label-position="top" size="mini" :model="elementProps">
    <div class="d-flex flex-column py-2">
      <span class="setting-title">Style</span>
      <Select
        class="mt-2"
        :primaryColor="primaryColor"
        :value="elementProps.display_style"
        v-bind:changeValue="($event) => updateProps('display_style', $event)"
        :options="[
          {
            value: 'default',
            label: 'Default',
          },
          {
            value: 'inline',
            label: 'Inline',
          },
          {
            value: 'simple',
            label: 'Simple',
          },
        ]"
      />
    </div>
    <div class="d-flex flex-column py-2" v-if="elementProps.display_style=='inline'">
      <span class="setting-title">Label Width</span>
      <Slider
        class="mt-2"
        :primaryColor="primaryColor"
        :value="elementProps.label_width"
        v-bind:changeValue="($event) => updateProps('label_width', $event)"
        unit="px"
        :max=300
        :step="1"
      />
    </div>
  </div>
</template>

<script>
import mixin from "../../../elements/styleSettings/mixin";
import Select from "../../../elements/common/Select.vue";
import Slider from "../../../elements/common/Slider.vue";

export default {
  mixins: [mixin],
  components: { Select, Slider },
  props: {
    screen: String,
    primaryColor: String,
  },
  
  methods: {
    updateProps(name, value) {
      if(value ==  this.elementProps[name]) return;
      this.elementPropsSetter({ [name]: value });
    },
    updateMixStyle(key, value, object) {
      const style = this.getStyle(object);
      this.setStyle(object, {
        ...style,
        [key]: value,
      });
    },
  },
};
</script>

<style scoped>
</style>
