<template>
  <div label-position="top" size="small" :model="elementProps">
    <div class="d-flex flex-column py-2">
      <span class="setting-title">Number of Products</span>
    </div>
    <Slider
      :value="elementProps.number_of_articles"
      :primaryColor="primaryColor"
      v-bind:changeValue="($event) => updateProps('number_of_articles', $event)"
      :max="20"
      :min="1"
      class="mt-2"
      :onlyInteger="true"
    />
  </div>
</template>

<script>
import mixin from "../../../elements/styleSettings/mixin";
import Slider from "../../../elements/common/Slider.vue";

export default {
  mixins: [mixin],
  components: {  Slider },
  props: {
    screen: String,
    primaryColor: String,
  },
  methods: {
    updateProps(name, value) {
      if(value ==  this.elementProps[name]) return;

      this.elementPropsSetter({ [name]: value });
    },
  },
};
</script>

<style scoped>
</style>
