var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"label-position":"top","size":"mini","model":_vm.elementProps}},[(!_vm.multi_screen)?_c('div',{staticClass:"py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Alignment")]),_c('ToggleButton',{staticClass:"mt-2",attrs:{"primaryColor":_vm.primaryColor,"value":_vm.getStyle('text-align'),"changeValue":($event) => _vm.setStyle('text-align', $event),"options":[
        {
          label: 'Left',
          value: 'left',
        },
        {
          label: 'Center',
          value: 'center',
        },
        {
          label: 'Right',
          value: 'right',
        },
      ]}})],1):_c('div',{staticClass:"py-2"},[_c('span',{staticClass:"setting-title"},[_vm._v("Alignment")]),_c('TabIcon',{staticClass:"mt-2",attrs:{"primaryColor":_vm.primaryColor,"value":_vm.getValue('alignment',_vm.elementProps[_vm.screen].alignment),"changeValue":($event) => _vm.updateProps('alignment', $event),"options":[
        { value: 'left', icon: 'align-left',tooltip: 'Left' },
        { value: 'center', icon: 'align-center',tooltip: 'Center' },
        { value: 'right', icon: 'align-right', tooltip: 'Right' },
      ]}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }