<template>
  <div
    data-label="Separator"
    data-key="separator"
    class="sg-wrap block"
    :id="`element-${node.uuid}`"
    @mouseover="isHovering=true" @mouseout="isHovering=false"
    :class="{ 'dragging': dragging == node.uuid }"
  >
    <div
      class="bl d-flex sg_separator_parent"
      :class="extra_class"
    >
      <hr class="sg_separator" />
    </div>
    <ToolBar ref="separator_toolbar"  :toolbar_id="`separator`" class="container-toolbar" 
    :class="{ 'container-toolbar-show': isHovering, 'is-dragging': dragging == node.uuid }"
    >
  </ToolBar>
    <v-style>
      {{ custom_css }}
      @media (max-width: 767px){ 
        #element-{{ node.uuid }} {
          {{ getDisplayValue('mobile') }}
          {{ getMarginValue('mobile') }}
        }
        #element-{{ node.uuid }} .bl {
          justify-content: {{ getValue('alignment','mobile') }};
        }
        #element-{{ node.uuid }} .sg_separator { 
          width: {{ getValue('width','mobile') }}{{ getValue('width_type','mobile') }};
          border-top: {{ getHeight(getValue('border_width','mobile')) }} {{ getValue('border_style','mobile') }} {{ getValue('border_color','mobile') }};
          {{ getOpacityValue('mobile') }}
          {{ getShadowValue('mobile') }}
          {{ getOtherStyle('mobile') }}
        }

        #element-{{ node.uuid }} .sg_separator_parent { 
          {{ getPaddingValue('mobile') }}
        }
      } 
      @media (max-width: 991px) and (min-width: 768px){ 
        #element-{{ node.uuid }} {
          {{ getDisplayValue('tablet') }}
          {{ getMarginValue('tablet') }}
        }
        #element-{{ node.uuid }} .bl {
          justify-content: {{ getValue('alignment','tablet') }};
        }
        #element-{{ node.uuid }} .sg_separator { 
          width: {{ getValue('width','tablet') }}{{ getValue('width_type','tablet') }};
          border-top: {{ getHeight(getValue('border_width','tablet')) }} {{ getValue('border_style','tablet') }} {{ getValue('border_color','tablet') }};
          {{ getOpacityValue('tablet') }}
          {{ getShadowValue('tablet') }}
          {{ getOtherStyle('tablet') }}
        }
        #element-{{ node.uuid }} .sg_separator_parent { 
          {{ getPaddingValue('tablet') }}
        }
      } 
      @media (max-width: 1199px) and (min-width: 992px){ 
        #element-{{ node.uuid }} {
          {{ getDisplayValue('laptop') }}
          {{ getMarginValue('laptop') }}
        }
        #element-{{ node.uuid }} .bl {
          justify-content: {{ getValue('alignment','laptop') }};
        }
        #element-{{ node.uuid }} .sg_separator { 
          width: {{ getValue('width','laptop') }}{{ getValue('width_type','laptop') }};
          border-top: {{ getHeight(getValue('border_width','laptop')) }} {{ getValue('border_style','laptop') }} {{ getValue('border_color','laptop') }};
          {{ getOpacityValue('laptop') }}
          {{ getShadowValue('laptop') }}
          {{ getOtherStyle('laptop') }}
        }
        #element-{{ node.uuid }} .sg_separator_parent { 
          {{ getPaddingValue('laptop') }}
        }
      } 
      @media (min-width: 1200px){
        #element-{{ node.uuid }} {
          {{ getDisplayValue('desktop') }}
          {{ getMarginValue('desktop') }}
        }
        #element-{{ node.uuid }} .bl {
          justify-content: {{ desktop.alignment }};
        }
        #element-{{ node.uuid }} .sg_separator { 
          width: {{ desktop.width }}{{ desktop.width_type }};
          border-top: {{ getHeight(desktop.border_width) }} {{ desktop.border_style }} {{ desktop.border_color }};
          {{ getOpacityValue('desktop') }}
          {{ getShadowValue('desktop') }}
          {{ getOtherStyle('desktop') }}
        }

        #element-{{ node.uuid }} .sg_separator_parent { 
          {{ getPaddingValue('desktop') }}
        }
      }
    </v-style>
  </div>
</template>

<script>
import BorderColor from "./styleSettings/BorderColor.vue";
import elementStyleMixin from "./elementStyleMixin";
import Dimensions from "./styleSettings/Dimensions.vue";
import AlignmentBase from "./styleSettings/AlignmentBase.vue";
import BorderStyle from "./styleSettings/BorderStyle.vue";

import ExtraClass from "./otherSettings/ExtraClass.vue";
import Visibility from "./designSettings/Visibility.vue";
import Spacing from "./designSettings/Spacing.vue";
import Background from "./designSettings/Background.vue";
import Border from "./designSettings/Border.vue";
import Corner from "./designSettings/Corner.vue";
import Shadow from "./designSettings/Shadow.vue";
import Opacity from "./designSettings/Opacity.vue";
import ToolBar from './utils/ToolBar.vue';
import { designStyles, otherProps, otherStyles, actionTypes } from "@/constants";
import CustomCSS from "./styleSettings/CustomCSS.vue";
import Animations from "./designSettings/Animations.vue";

export default {
  mixins: [elementStyleMixin],
  components: {  ToolBar },
  props: {
    ...otherProps,
    extra_class: String,
    
    desktop: Object,
    laptop: Object,
    tablet: Object,
    mobile: Object,
  },
  data() {
    return {
      isHovering: false,
    }
  },
  methods: {
    getHeight(height) {
      return `${height}px`
    }
  },
  inject: ["node",'editor'],
  computed: {
    dragging: function () {
      return this.editor?.draggedNode?.uuid
    },
  },
  craft: {
    defaultProps: {
      ...otherStyles,
      extra_class: "",
      typographyActives: [
        "font-size",
        "font-weight",
        "text-transform",
        "font-family",
      ],
      dimensionActives: [
        "width",
        "border-width",
      ],
      desktop: {
        ...designStyles,
        border_color: 'rgb(0, 0, 0)',
        border_style: "solid",
        border_width: 1,
        width: '100',
        width_type: '%',
        alignment: "center",
      },
      laptop: {
        ...designStyles,
      },
      tablet: {
        ...designStyles,
      },
      mobile: {
        ...designStyles,
      },
      elementStyle: {
      },
    },
    settings: {
      settings: {
        Color: {Color: BorderColor, multi_screen: true},
        Type: {Type: BorderStyle, multi_screen: true},
        "Dimension": {"Dimension": Dimensions, multi_screen: true},
        Alignment: {Alignment: AlignmentBase, multi_screen: true},
        "Extra Class": ExtraClass, "Custom CSS": CustomCSS,
      },
      design: {
        Visibility,
        Spacing: { Spacing, multi_screen: true },
        // Background: { Background, multi_screen: true },
        // Border: { Border, multi_screen: true },
        // Corner: { Corner, multi_screen: true },
        Shadow: { Shadow, multi_screen: true },
        Opacity: { Opacity, multi_screen: true },
      },
      others: {
        Animations: { Animations, actions: actionTypes }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
