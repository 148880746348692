<template>
  <div
    id="md-text-editor"
    :class="isOpen ? `show` : ''"
    :style="elementCSS"
    ref="myDiv"
    v-click-outside="onClickOutside"
  >
    <ul v-if="number >= 0">
      <li>
        <a
          hhref="javascript:void(0)"
          :class="`gfButton ${
            getArrayTag()?.indexOf('B') > -1 ? 'active' : ''
          }`"
          v-on:click.prevent="stylize('bold')"
          v-html="icons.bold"
          d-tooltips="'Bold'"
        >
        </a>
      </li>
      <li>
        <a
        href="javascript:void(0)"
          :class="`gfButton ${
            getArrayTag()?.indexOf('I') > -1 ? 'active' : ''
          }`"
          data-action="formatStyle"
          d-tooltips="'Italic'"
          v-on:click.prevent="stylize('italic')"
          v-html="icons.italic"
        >
        </a>
      </li>
      <li>
        <a
        href="javascript:void(0)"
          :class="`gfButton ${
            getArrayTag()?.indexOf('U') > -1 ? 'active' : ''
          }`"
          data-action="formatStyle"
          d-tooltips="'Underline'"
          v-on:click.prevent="stylize('underline')"
          v-html="icons.underline"
        >
        </a>
      </li>
      <li>
        <a
        href="javascript:void(0)"
          d-tooltips="'Pick link'"
          class=""
          v-html="icons.link"
          v-on:click.prevent="onClickShowToolbarLink()"
        >
        </a>
      </li>
      <li>
        <a
          slot="reference"
          href="javascript:void(0)"
          d-tooltips="'Font color'"
          id="edit_color"
          @click="showPopper"
          ><svg class="need_a_name" height="24px" viewBox="0 0 24 24">
            <path
              d="M11.58,4h1.67l4.58,11.67h-1.88l-0.93-2.5H9.81l-0.93,2.5H7L11.58,4z M10.43,11.5h3.97l-1.98-5.28 L10.43,11.5z"
            ></path>
            <path
              d="M3,18h18v3H3V18z"
              class="colorActive"
              :style="{
                fill: `${
                  getArrayTag()?.indexOf('FONT') > -1
                    ? getArrayTag()[getArrayTag()?.indexOf('FONT') + 1]
                    : colorSelect
                }`,
              }"
            ></path>
          </svg>
        </a>
        <PopupColorPicker
          :show="show"
          :handleOutSite="hidePopper"
          :foreColor="stylize"
          :textColor="
            getArrayTag()?.indexOf('FONT') > -1
              ? getArrayTag()[getArrayTag()?.indexOf('FONT') + 1]
              : colorSelect
          "
          :highLight="
            getArrayTag()?.indexOf('BG') > -1
              ? getArrayTag()[getArrayTag()?.indexOf('BG') + 1]
              : 'rgba(0,0,0,1)'
          "
          :toolbar="$el"
          :iframe="iframe"
        />
      </li>
      <li>
        <a
        href="javascript:void(0)"
          v-on:click.prevent="fonSizeStyle(2)"
          v-html="icons.size_plus"
          d-tooltips="'Increase size'"
        >
        </a>
      </li>
      <li>
        <a
        href="javascript:void(0)"
          v-on:click.prevent="fonSizeStyle(-2)"
          v-html="icons.size_minus"
          d-tooltips="'Decrease size'"
        >
        </a>
      </li>
      <li>
        <a
          href="javascript:void(0)"
          class="formatList"
          data-cmd="insertOrderedList"
          v-on:click.prevent="stylize('insertOrderedList')"
          v-html="icons.list_number"
          d-tooltips="'Numbers'"
          :class="`gfButton ${
            getArrayTag()?.indexOf('OL') > -1 ? 'active' : ''
          }`"
        >
        </a>
      </li>
      <li>
        <a
          href="javascript:void(0)"
          class="formatList"
          d-tooltips="'UI'"
          v-on:click.prevent="stylize('insertUnorderedList')"
          v-html="icons.list_dot"
          :class="`gfButton ${
            getArrayTag()?.indexOf('UL') > -1 ? 'active' : ''
          }`"
        >
        </a>
      </li>
    </ul>
  </div>
</template>
<script>
import Vue from "vue";
import Node from "../core/Node";
import { toolbarIcons } from "../constants";
import PopupColorPicker from "./PopupColorPicker.vue";
import ToolbarLink from "./ToolbarLink.vue";

Vue.directive("click-outside", {
  bind(el, binding, vnode) {
    el.clickOutsideEvent = (event) => {
      if (
        !(
          el === event?.target ||
          el?.contains(event?.target) ||
          vnode.context.$options.propsData.refLink?.contains(event?.target)
        )
      ) {
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener("click", el.clickOutsideEvent);
  },
  unbind(el) {
    document.body.removeEventListener("click", el.clickOutsideEvent);
  },
});

export default {
  components: {
    PopupColorPicker,
  },
  props: {
    editor: HTMLElement,
    isOpen: Boolean,
    node: Node,
    refLink: HTMLElement,
    iframe: Object,
    screen: String
  },
  watch: {
    isOpen() {
      if (!this.isOpen && this.show) {
        this.show = false;
      }
    },
    node() {
      this.colorSelect = this.node?.props.elementStyle.color;
    },
    iframe() {
      if (this.iframe) {
        this.iframe.$el.contentWindow.document.addEventListener(
          "selectionchange",
          () => {}
        );
        this.iframe.$el.contentWindow.document.onselectionchange = () => {
          this.number += 1;
        };
      } else {
        this.iframe.$el.contentWindow.document.removeEventListener(
          "selectionchange",
          () => {}
        );
      }
    },
  },
  data() {
    return {
      topEditor: 0,
      icons: toolbarIcons,
      show: false,
      arrSelect: [],
      number: 0,
      colorSelect: "#000",
    };
  },
  mounted() {},
  unmounted() {
    this.iframe.$el.contentWindow.document.removeEventListener(
      "selectionchange",
      () => {}
    );
  },
  created() {
    this.$bus.on("scrollPreview", (currentRef) => {
      this.topEditor = this.editor?.getBoundingClientRect().top;
      this.$bus.emit("showToolbarLink", false);
    });
  },
  provide() {},
  computed: {
    elementCSS: function () {
      const style = {};
      const left = this.editor?.getBoundingClientRect().left;
      const top = this.editor?.getBoundingClientRect().top;
      const width = this.editor?.clientWidth;
      const width2 = this.$refs.myDiv?.clientWidth;
      const leftIframe = this.iframe?.$el?.getBoundingClientRect().left;

      style["left"] = `${left + leftIframe + (width - width2) / 2}px`;
      style["top"] = `${top + 20}px`;
      style["top-editor"] = this.topEditor;
      return style;
    },
  },
  methods: {
    getArrayTag: function () {
      var arr = [];
      if (!this.iframe) return arr;
      const selection = this.iframe.$el.contentWindow.getSelection();
      if (!selection.rangeCount) return;

      var range = selection.getRangeAt(0);
      var html = range.commonAncestorContainer;

      if (html?.nodeName) {
        arr.push(html.nodeName);
      }
      try {
        while (html?.parentNode?.className != "block editing") {
          arr.push(html?.parentNode?.nodeName);
          if (html?.parentNode?.nodeName == "FONT") {
            arr.push(html.parentNode.getAttribute("color"));
          } else if (html?.parentNode?.nodeName == "SPAN") {
            var styles = html?.parentNode?.getAttribute("style").split(";");
            var astyle;
            var background = null;
            for (var i = 0; i < styles.length; i++) {
              astyle = styles[i].split(":");
              if (astyle[0] == "background-color") {
                background = astyle[1];
                break;
              }
            }
            arr.push("BG");
            arr.push(background);
          }
          if (!html?.parentNode) break;
          html = html?.parentNode;
        }
      } catch (error) {
        // console.log(error);
      }
      return arr;
    },
    onClickOutside($event) {
      if(!this.refLink?.contains(event?.target)){
        this.$bus.emit("showToolbar", this.$refs, false);
      }
    },
    onClickShowToolbarLink() {
      const sText = this.iframe.$el.contentWindow.getSelection();
      const doc = this.iframe.$el.contentWindow.document;
      if (!sText.rangeCount) return;
      var range = sText.getRangeAt(0);
      var html1 = range.commonAncestorContainer;
      if (
        !(
          html1?.parentNode?.nodeName == "A" &&
          html1?.parentNode?.getAttribute("data-href")
        )
      ) {
        doc.execCommand(
          "insertHTML",
          false,
          '<a href="javascript:void(0)" data-href="#" data-target="_blank" class="editor_link_selection">' +
            sText +
            "</a>"
        );
        let html = doc.getElementsByClassName("editor_link_selection");
        let selection = this.iframe.$el.contentWindow.getSelection();
        selection.removeAllRanges();
        for (let i = 0; i < html.length; i++) {
          let range = doc.createRange();
          range.selectNode(html[i]);
          selection.addRange(range);
        }
      }
      this.$bus.emit("showToolbarLink", true);
      this.hidePopper();
    },
    showPopper() {
      this.show = !this.show;
      this.$bus.emit("showToolbarLink", false);
      // document.execCommand("styleWithCSS", false, true);
    },
    hidePopper() {
      this.show = false;
    },
    stylize: function (style, ui, value) {
      const doc = this.iframe.$el.contentWindow.document;
      var inui = false;
      var ivalue = null;
      if (arguments[1]) {
        inui = ui;
      }
      if (arguments[2]) {
        ivalue = value;
      }
      doc.execCommand(style, inui, ivalue);
      this.node.setProps({ content: this.editor?.innerHTML });
      if (["foreColor", "hiliteColor", "backColor"].indexOf(style) == -1) {
        this.hidePopper();
      }
      this.number = this.number + 1;
      this.$bus.emit("showToolbarLink", false);
    },
    fonSizeStyle: function (value) {
      const {screen} = this
      const data_screen = Object.assign({}, this.node.props[screen])
      let fontSize = data_screen["font_size"];
      if (fontSize > 6 || (fontSize == 6 && parseInt(value) > 0)) {
        fontSize += value;
      }
      if(fontSize ==  data_screen["font_size"]) return;
      data_screen["font_size"] = fontSize
      this.node.setProps({ [screen]: data_screen });
      this.hidePopper();
      this.number = this.number + 1;
      this.$bus.emit("showToolbarLink", false);
    },
  },
};
</script>
<style scoped>
body {
  background-color: #30263d;
  font-family: -apple-system, Helvetica Neue, Segoe UI, Roboto, Oxygen, Ubuntu,
    Cantarell, Open Sans, sans-serif;
  text-transform: uppercase;
}

#container {
  position: relative;
  width: 300px;
  height: 300px;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  overflow-y: scroll;
  overscroll-behavior: contain;
  border: 2px dashed #ff6b81;
  background-color: #281e36;
}

#popcorn {
  display: inline-block;
  width: 134px;
  height: 120px;
  background-image: url("https://popper.js.org/images/popcorn-box.svg");
  margin-left: 80px;
}

#container::before,
#container::after {
  content: "";
  display: block;
  width: 1px;
  height: 600px;
}

#tooltip {
  display: inline-block;
  background: #ffffff;
  color: #643045;
  font-weight: bold;
  padding: 5px 10px;
  font-size: 13px;
  border-radius: 4px;
}

#arrow,
#arrow::before {
  position: absolute;
  width: 8px;
  height: 8px;
  background: inherit;
}

#arrow {
  visibility: hidden;
}

#arrow::before {
  visibility: visible;
  content: "";
  transform: rotate(45deg);
}

#tooltip[data-popper-placement^="top"] > #arrow {
  bottom: -4px;
}

#tooltip[data-popper-placement^="bottom"] > #arrow {
  top: -4px;
}

#tooltip[data-popper-placement^="left"] > #arrow {
  right: -4px;
}

#tooltip[data-popper-placement^="right"] > #arrow {
  left: -4px;
}
</style>
