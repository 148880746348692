<template>
  <el-form label-position="top" size="" :model="elementProps">
    <div class="d-flex align-items-center justify-content-between py-2">
      <span class="sgSubTitle">Show Youtube Controls</span>
      <el-switch
        :value="elementProps.show_youtube_controls"
        :active-color="primaryColor"
        inactive-color="#949494"
        @change="updateProps('show_youtube_controls', $event)"
      />
    </div>
    <div class="sgDesc mt-2">Hide or show youtube video controls</div>
  </el-form>
</template>

<script>
import mixin from "../../../elements/styleSettings/mixin";

export default {
  mixins: [mixin],
  components: {},
  methods: {
    updateProps(name, value) {
      if(value ==  this.elementProps[name]) return;
      this.elementPropsSetter({ [name]: value });
    },
  },
  props: {
    primaryColor: String
  }
};
</script>

<style scoped>
</style>
