<template>
  <div label-position="top" size="mini" :model="elementProps">
    <div class="d-flex flex-column py-2">
      <ToggleButton
        :value="link_type"
        v-bind:changeValue="($event) => updateType($event)"
        :options="[
          { label: 'Normal', value: 'normal' },
          { label: 'Hover', value: 'hover' }
        ]"
        primaryColor="#0060e5"
      />
    </div>
    <div v-if="link_type == 'normal'">
      <div class="d-flex flex-column py-2">
        <span class="setting-title">Text Color</span>
        <ColorPicker 
          class="mt-2"
          :value="elementProps.link_text_color"
          v-bind:changeColor="($event) => updateCommonProps('link_text_color', $event)"
          primaryColor="#0060e5"
        />
      </div>
      <div class="d-flex flex-column py-2">
        <span class="setting-title">Font Family</span>
        <Select
          class="mt-2"
          :value="elementProps.link_font_family"
          v-bind:changeValue="($event) => updateCommonProps('link_font_family', $event)"
          :hasAction="true"
          v-bind:onAction="handleShowModal"
          actionLabel="Open Font Manager"
          :options="getListFont()"
          :key="fontKey"
          primaryColor="#0060e5"
        />
      </div>
      <div class="d-flex flex-column py-2">
        <span class="setting-title">Font Size</span>
        <Slider
          class="mt-2"
          :step="1"
          :value="elementProps.link_font_size"
          v-bind:changeValue="($event) => updateCommonProps('link_font_size', parseInt($event))"
          unit="px"
          :max="999"
          :min="1"
          primaryColor="#0060e5"
          :onlyInteger="true"
        />
      </div>
      <div class="d-flex flex-column py-2">
        <span class="setting-title">Font Weight</span>
        <Select
          class="mt-2"
          :value="elementProps.link_font_weight"
          primaryColor="#0060e5"
          v-bind:changeValue="($event) => updateCommonProps('link_font_weight', $event)"
          :options="[
            {
              value: '100',
              label: 'Thin 100',
            },
            {
              value: '200',
              label: 'Semi Thin 200',
            },
            {
              value: '300',
              label: 'Light 300',
            },
            {
              value: '400',
              label: 'Regular 400',
            },
            {
              value: '500',
              label: 'Medium 500',
            },
            {
              value: '600',
              label: 'Semi Bold 600',
            },
            {
              value: '700',
              label: 'Bold 700',
            },
            {
              value: '800',
              label: 'Super Bold 800',
            },
            {
              value: '900',
              label: 'Boldest 900',
            }
          ]"
        />
      </div>
      <div class="d-flex flex-column py-2">
        <span class="setting-title">Text Decoration</span>
        <Select
          class="mt-2"
          primaryColor="#0060e5"
          :value="elementProps.link_text_decoration"
          v-bind:changeValue="($event) => updateCommonProps('link_text_decoration', $event)"
          :options="[
            { value: 'none', label: 'None' },
            { value: 'underline', label: 'Underline' },
            { value: 'overline', label: 'Overline' },
            { value: 'line-through', label: 'Line Through' },
          ]"
        />
      </div>
    </div>
    <div v-if="link_type == 'hover'">
      <div class="d-flex flex-column py-2">
        <span class="setting-title">Text Color</span>
        <ColorPicker 
          class="mt-2"
          :value="elementProps.link_hover_text_color"
          v-bind:changeColor="($event) => updateCommonProps('link_hover_text_color', $event)"
          primaryColor="#0060e5"
        />
      </div>
      <div class="d-flex flex-column py-2">
        <span class="setting-title">Font Family</span>
        <Select
          class="mt-2"
          :value="elementProps.link_hover_font_family"
          v-bind:changeValue="($event) => updateCommonProps('link_hover_font_family', $event)"
          :hasAction="true"
          v-bind:onAction="handleShowModal"
          actionLabel="Open Font Manager"
          :options="getListFont()"
          :key="fontKey"
          primaryColor="#0060e5"
        />
      </div>
      <div class="d-flex flex-column py-2">
        <span class="setting-title">Font Size</span>
        <Slider
          class="mt-2"
          :step="1"
          :value="elementProps.link_hover_font_size"
          v-bind:changeValue="($event) => updateCommonProps('link_hover_font_size', parseInt($event))"
          unit="px"
          :max="999"
          :min="1"
          primaryColor="#0060e5"
          :onlyInteger="true"
        />
      </div>
      <div class="d-flex flex-column py-2">
        <span class="setting-title">Font Weight</span>
        <Select
          class="mt-2"
          :value="elementProps.link_hover_font_weight"
          v-bind:changeValue="($event) => updateCommonProps('link_hover_font_weight', $event)"
          :options="[
            {
              value: '100',
              label: 'Thin 100',
            },
            {
              value: '200',
              label: 'Semi Thin 200',
            },
            {
              value: '300',
              label: 'Light 300',
            },
            {
              value: '400',
              label: 'Regular 400',
            },
            {
              value: '500',
              label: 'Medium 500',
            },
            {
              value: '600',
              label: 'Semi Bold 600',
            },
            {
              value: '700',
              label: 'Bold 700',
            },
            {
              value: '800',
              label: 'Super Bold 800',
            },
            {
              value: '900',
              label: 'Boldest 900',
            }
          ]"
          primaryColor="#0060e5"
        />
      </div>
      <div class="d-flex flex-column py-2">
        <span class="setting-title">Text Decoration</span>
        <Select
          class="mt-2"
          :value="elementProps.link_hover_text_decoration"
          v-bind:changeValue="($event) => updateCommonProps('link_hover_text_decoration', $event)"
          :options="[
            { value: 'none', label: 'None' },
            { value: 'underline', label: 'Underline' },
            { value: 'overline', label: 'Overline' },
            { value: 'line-through', label: 'Line Through' },
          ]"
          primaryColor="#0060e5"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Node from '@/core/Node';
import { makeId, icons } from "@/constants";
import mixin from '../styleSettings/mixin';
import Slider from '../common/Slider.vue';
import ColorPicker from '../common/ColorPicker.vue';
import Select from '../common/Select.vue';
import ToggleButton from '../common/ToggleButton.vue';
export default {
  mixins: [mixin],
  components: {
    ColorPicker, Slider, Select, ToggleButton
  },
  props: {
    screen: String,
    node: Node,
    activeFonts: Array,
  },
  data() {
    return {
      fontKey: makeId(20),
      icons,
      link_type: 'normal',
      box_index: 0,
    };
  },
  watch: {
    activeFonts: function (newValue, oldValue) {
      this.fontKey = makeId(20);
    },
  },
  methods: {
    setBoxIndex(value) {
      this.box_index = value
    },
    updateType(value) {
      this.link_type = value
    },
    updateCommonProps(name, value) {
      this.elementPropsSetter({ [name]: value });
    },
    updateProps(name, value) {
      const screen = this.$props.screen
      const data_screen = Object.assign({}, this.elementProps[screen])
      data_screen[name] = value
      this.elementPropsSetter({ [screen]: data_screen });
    },
    handleShowModal() {
      this.$bus.emit("showFontManager", this.node);
    },
    getListFont() {
      const listFonts = [
        {
          value: "inherit",
          label: "Default",
        },
      ];
      this.node?.editor?.fonts?.map((e) => {
        listFonts.push({ value: e, label: e });
      });
      return listFonts;
    },
  }
};
</script>
